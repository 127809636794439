import { FC, useMemo, useState } from 'react'

import { Button, Input, Instruction } from 'components'
import { PermissionGroupsQueryResponse } from 'services/auth'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useFormContext } from 'react-hook-form'
import { UserFormData } from 'domains/user/types'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import {
  useDebounce,
  useGetAttendanceProfiles,
  useGetPermissionGroups,
  useGetTags,
} from 'shared/hooks'
import { CoverageQueryResponse } from 'services/coverages'

import { ReactComponent as PlusSign } from 'assets/svg/plusSign.svg'
import { ReactComponent as RemoveIcon } from 'assets/svg/remove.svg'

import styles from './styles.module.scss'

interface AttendanceProfileProps {
  readonly?: boolean
}

export const AttendanceProfile: FC<AttendanceProfileProps> = ({ readonly }) => {
  const { watch, setValue, register } = useFormContext<UserFormData>()

  const [isOpenTransboardOptions, setIsOpenTransboardOptions] = useState(
    !!watch('userCoverages')?.secondary?.length,
  )

  const [filters, setFilters] = useState({
    permissionGroups: '',
    tag: '',
    attendanceProfile: '',
    transboardAttendanceProfile: '',
  })

  const {
    isError: isPermissionGroupsError,
    isFetching: isPermissionGroupsFetching,
    permissionGroups: permissionGroupsData,
    fetchNextPermissionGroupsPage,
  } = useGetPermissionGroups(filters.permissionGroups)

  const {
    isError: isTagsError,
    isFetching: isTagsFetching,
    tags: tagsResponse,
    fetchNextTagsPage,
  } = useGetTags(filters.tag)

  const {
    isError: isAttendanceProfilesError,
    isFetching: isAttendanceProfileFetching,
    attendanceProfiles: attendanceProfilesData,
    fetchNextAttendanceProfilesPage,
  } = useGetAttendanceProfiles(filters.attendanceProfile)

  const {
    isError: isTransboardAttendanceProfileError,
    isFetching: isTransboardAttendanceProfileFetching,
    attendanceProfiles: transboardAttendanceProfilesData,
    fetchNextAttendanceProfilesPage: fetchNextTransboardAttendanceProfilesPage,
  } = useGetAttendanceProfiles(filters.transboardAttendanceProfile)

  const filteredTransboardAttendanceProfiles = useMemo(() => {
    const userCoverages = watch('userCoverages')

    if (
      !userCoverages ||
      !userCoverages.primary ||
      !transboardAttendanceProfilesData?.length
    )
      return

    return transboardAttendanceProfilesData?.filter(
      (coverageResponse) =>
        !userCoverages.primary.find(
          (coverage) => coverageResponse.name === coverage.name,
        ),
    )
  }, [transboardAttendanceProfilesData, watch('userCoverages')])

  const handleFilter = useDebounce((newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }))
  }, 700)

  return (
    <div className={styles.fields}>
      <div className={styles.sectionTitle}>
        <h3>Perfil de atendimento</h3>
        <small>
          Seleção das credenciais do usuário de acordo com seu papel na
          operação.
        </small>
      </div>
      <Input
        disabled={readonly}
        label="Ramal"
        placeholder="Ramal"
        autoComplete="off"
        maxLength={4}
        {...register('phoneExtension')}
        value={watch('phoneExtension')}
        onChange={(e) =>
          setValue('phoneExtension', e.target.value, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
      />
      <Combobox
        disabled={readonly}
        id="permissionGroups"
        label={{
          text: 'Permissões',
        }}
        placeholder="Selecione um ou mais tipos"
        multiple
        value={watch('permissionGroups')?.map((permissionGroup) => ({
          label: 'name',
          value: permissionGroup,
        }))}
        items={parseDataToComboboxV2(permissionGroupsData || [], 'name')}
        {...register('permissionGroups')}
        onChange={(selecteds) => {
          const permissionGroups =
            selecteds as ComboboxItem<PermissionGroupsQueryResponse>[]
          setValue(
            'permissionGroups',
            permissionGroups?.map((permissionGroup) => permissionGroup.value),
            {
              shouldDirty: true,
              shouldValidate: true,
            },
          )
        }}
        isLoading={isPermissionGroupsFetching}
        isError={isPermissionGroupsError}
        onEndReached={fetchNextPermissionGroupsPage}
        onSearch={(filter) => handleFilter({ permissionGroups: filter })}
      />

      <Combobox
        multiple
        disabled={readonly}
        id="tags"
        label={{
          text: 'Tags',
        }}
        placeholder="Selecione um ou mais tipos"
        {...register('tags')}
        value={watch('tags')}
        items={tagsResponse.map((tag) => tag.name)}
        onChange={(selecteds) => {
          const tagsSelected = selecteds as string[]

          setValue('tags', tagsSelected, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }}
        isLoading={isTagsFetching}
        isError={isTagsError}
        onEndReached={fetchNextTagsPage}
        onSearch={(filter) => handleFilter({ tag: filter })}
      />

      <Combobox
        disabled={readonly}
        id="userCoverage"
        label={{
          text: 'Perfis de atendimento',
        }}
        placeholder="Selecione um ou mais tipos"
        className=""
        multiple
        {...register('userCoverages')}
        value={watch('userCoverages')?.primary?.map((userCoverage) => ({
          label: 'name',
          value: userCoverage,
        }))}
        items={parseDataToComboboxV2(attendanceProfilesData, 'name')}
        onChange={(selecteds) => {
          const userCoverageSelected =
            selecteds as ComboboxItem<CoverageQueryResponse>[]
          setValue(
            'userCoverages',
            {
              ...watch('userCoverages'),
              primary:
                userCoverageSelected?.map(
                  (userCoverage) => userCoverage.value,
                ) || [],
            },
            {
              shouldDirty: true,
              shouldValidate: true,
            },
          )
        }}
        isLoading={isAttendanceProfileFetching}
        isError={isAttendanceProfilesError}
        onEndReached={fetchNextAttendanceProfilesPage}
        onSearch={(filter) => handleFilter({ attendanceProfile: filter })}
      />

      <Button
        disabled={readonly}
        buttonTitle={
          isOpenTransboardOptions
            ? 'Remover opções de perfis de cobertura'
            : 'Adicionar opções de perfis de cobertura'
        }
        type="tertiary"
        icon={isOpenTransboardOptions ? RemoveIcon : PlusSign}
        className={styles.buttonTertiary}
        onClick={() => {
          if (isOpenTransboardOptions) {
            setValue(
              'userCoverages',
              {
                primary: watch('userCoverages')?.primary || [],
                secondary: [],
              },
              {
                shouldDirty: true,
                shouldValidate: true,
              },
            )
          }
          setIsOpenTransboardOptions(!isOpenTransboardOptions)
        }}
      />

      {isOpenTransboardOptions && (
        <div className={styles.transboardContainer}>
          <Combobox
            disabled={!watch('userCoverages')?.primary?.length || readonly}
            id="transboard"
            label={{
              text: 'Perfis de cobertura',
            }}
            placeholder="Selecione um ou mais tipos"
            multiple
            {...register('userCoverages')}
            value={watch('userCoverages')?.secondary?.map((userCoverage) => ({
              label: 'name',
              value: userCoverage,
            }))}
            items={parseDataToComboboxV2(
              filteredTransboardAttendanceProfiles || [],
              'name',
            )}
            onChange={(selecteds) => {
              const userCoveragesSelected =
                selecteds as ComboboxItem<CoverageQueryResponse>[]
              setValue(
                'userCoverages',
                {
                  ...watch('userCoverages'),
                  secondary: userCoveragesSelected.map(
                    (userCoverage) => userCoverage.value,
                  ),
                },
                {
                  shouldDirty: true,
                  shouldValidate: true,
                },
              )
            }}
            isLoading={isTransboardAttendanceProfileFetching}
            isError={isTransboardAttendanceProfileError}
            onEndReached={fetchNextTransboardAttendanceProfilesPage}
            className={styles.transboard}
            onSearch={(filter) =>
              handleFilter({ transboardAttendanceProfile: filter })
            }
          />
          <Instruction
            text="Perfis de cobertura permite ao usuário atender
  contas de outros perfis de atendimento caso
  não existam operadores logados com esta
  configuração."
          />
        </div>
      )}
    </div>
  )
}
