import { JSX } from 'react'

import { ReactComponent as LowBattery } from 'utilities/central/svg/battery/lowBattery.svg'
import { ReactComponent as FullBattery } from 'utilities/central/svg/battery/colorfullFullBattery.svg'

import { ReactComponent as NoCommunicationLevel } from '../assets/communication/noCommunicationLevel.svg'
import { ReactComponent as TwentyPercentCommunicationLevel } from '../assets/communication/twentyPercentCommunicationLevel.svg'
import { ReactComponent as FortyPercentCommunicationLevel } from '../assets/communication/fortyPercentCommunicationLevel.svg'
import { ReactComponent as SixtyPercentCommunicationLevel } from '../assets/communication/sixtyPercentCommunicationLevel.svg'
import { ReactComponent as FullCommunicationLevel } from '../assets/communication/fullCommunicationLevel.svg'

import { ReactComponent as EmptyBar } from 'domains/customer/screens/Equipments/assets/signalBar/emptyBar.svg'
import { ReactComponent as OneFifthBar } from 'domains/customer/screens/Equipments/assets/signalBar/oneFifthBar.svg'
import { ReactComponent as TwoFifthBar } from 'domains/customer/screens/Equipments/assets/signalBar/twoFifthBar.svg'
import { ReactComponent as ThreeFifthBar } from 'domains/customer/screens/Equipments/assets/signalBar/threeFifthBar.svg'
import { ReactComponent as FourFifthBar } from 'domains/customer/screens/Equipments/assets/signalBar/fourFifthBar.svg'
import { ReactComponent as FullBar } from 'domains/customer/screens/Equipments/assets/signalBar/fullBar.svg'

export const getEquipmentBatteryIcon = (lowBattery: boolean): JSX.Element =>
  lowBattery ? (
    <LowBattery aria-label="lowBattery" />
  ) : (
    <FullBattery aria-label="fullBattery" />
  )

export const getCommunicationLevelIcon = (
  communicationLevel: number,
  communicationStatus?: boolean,
): JSX.Element => {
  if (!communicationStatus || communicationLevel === 0)
    return <NoCommunicationLevel aria-label="no-communication-level" />

  if (communicationLevel > 0 && communicationLevel <= 25)
    return (
      <TwentyPercentCommunicationLevel aria-label="twenty-percent-communication-level" />
    )

  if (communicationLevel > 25 && communicationLevel <= 50)
    return (
      <FortyPercentCommunicationLevel aria-label="forty-percent-communication-level" />
    )

  if (communicationLevel > 50 && communicationLevel <= 75)
    return (
      <SixtyPercentCommunicationLevel aria-label="sixty-percent-communication-level" />
    )

  return <FullCommunicationLevel aria-label="full-communication-level" />
}

export const getSignalLevelBarIcon = (level: number): JSX.Element => {
  if (level === 0) return <EmptyBar aria-label="zero-signal" />

  if (level > 0 && level <= 20)
    return <OneFifthBar aria-label="twenty-percent-signal" />

  if (level > 20 && level <= 40)
    return <TwoFifthBar aria-label="forty-percent-signal" />

  if (level > 40 && level <= 60)
    return <ThreeFifthBar aria-label="sixty-percent-signal" />

  if (level > 60 && level <= 80)
    return <FourFifthBar aria-label="eighty-percent-signal" />

  return <FullBar aria-label="full-signal" />
}
