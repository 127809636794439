import * as Joi from '@hapi/joi'

import { QueryTechniciansResult } from 'services/serviceOrder/hooks/useGetTechnicians'
import { tagSchema } from 'shared/schemas/tag/tagSchema'

const fragmentSchema: Joi.SchemaMap = {
  id: Joi.string().required(),
  name: Joi.string().required(),
}

export const technicianSchema = Joi.object<QueryTechniciansResult>({
  active: Joi.boolean(),
  createdAt: Joi.number(),
  email: Joi.string().allow('', null),
  id: Joi.string().uuid(),
  name: Joi.string(),
  phone: Joi.number(),
  updatedAt: Joi.number(),
  username: Joi.string().allow('', null),
  document: Joi.string().optional().allow('', null),
  permissionGroups: Joi.array().items(fragmentSchema),
  profiles: Joi.array().items(fragmentSchema),
  tags: Joi.array().items(tagSchema).allow('', null),
  phoneExtension: Joi.number().integer().optional().allow('', null),
  isAccountTechnician: Joi.boolean().optional(),
})
