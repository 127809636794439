import { useInfiniteQuery } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'
import { Result } from 'services/types'

import HttpClient from '../../../services/httpClient'

import {
  PatrimonyQuery,
  PatrimonyResponse,
} from 'shared/hooks/patrimonies/types'
import { useMemo } from 'react'
import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { endpoints } from './endpoints'

export async function getPatrimonies(offset = 0, query?: PatrimonyQuery) {
  const response = await HttpClient.get<Result<PatrimonyResponse>>(
    buildUrl({
      route: endpoints.query,
      queryParams: { ...query, recordsPerPage: 15, offset },
    }),
  )

  return response.data
}

export const useInfinitePatrimonies = (filter?: PatrimonyQuery) => {
  const result = useInfiniteQuery({
    queryKey: ['patrimony-query', filter || ''],
    queryFn: ({ pageParam }) => getPatrimonies(pageParam, filter),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<PatrimonyResponse>(lastPage, allPages),
  })

  const patrimonyFragment = useMemo(
    () => flattenPages<PatrimonyResponse>(result.data),
    [result],
  )

  return { ...result, data: patrimonyFragment }
}
