import React, { useState } from 'react'

import { Tab } from './components'

import { TabData } from './types'

import styles from './styles.module.scss'

interface TabsProps {
  tabs: TabData[]
  onTabChange?: (tabId: string) => void
}

export const Tabs: React.FC<TabsProps> = ({ tabs, onTabChange }) => {
  const [activeTabId, setActiveTabId] = useState(tabs[0].id)

  const handleTabActivation = (tabId: string) => {
    const tab = tabs.find((tab) => tab.id === tabId)
    if (tab) {
      setActiveTabId(tabId)
    }
    if (onTabChange) {
      onTabChange(tabId)
    }
  }

  return (
    <div>
      <ul className={styles.tabList} role="tablist">
        {tabs.map((tab) => (
          <Tab
            id={tab.id}
            key={tab.id}
            icon={tab.icon}
            label={tab.label}
            isActive={tab.id === activeTabId}
            onActivate={() => handleTabActivation(tab.id)}
          />
        ))}
      </ul>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          role="tabpanel"
          hidden={tab.id !== activeTabId}
          className={styles.content}
        >
          {tab.content}
        </div>
      ))}
    </div>
  )
}
