import * as Joi from '@hapi/joi'
import { Form } from './types'

export const FormSchema = Joi.object<Form>({
  customer: Joi.object({
    id: Joi.string().uuid().required(),
    name: Joi.string().required(),
  }).optional(),
  account: Joi.object({
    id: Joi.string().uuid().required(),
    aggregatedAccountName: Joi.string().required(),
  }).required(),
  tag: Joi.string().required(),
  note: Joi.string().required(),
})
