import {
  createMasker,
  MaskArgs,
} from 'utilities/masks/createMasker/createMasker'

export const deviceCodeMaskerArgs: MaskArgs[] = [
  { mask: '', regex: /:/g },
  {
    mask: '$1-$2',
    regex: /^([a-zA-Z0-9]{2})([a-zA-Z0-9])/g,
  },
  {
    mask: '$1-$2-$3',
    regex: /^([a-zA-Z0-9]{2})-([a-zA-Z0-9]{14})([a-zA-Z0-9])/g,
  },
]
export const deviceCodeMasker = createMasker(deviceCodeMaskerArgs)

export const centralCodeMaskArgs: MaskArgs[] = [
  {
    mask: '$1:$2',
    regex: /^([a-zA-Z0-9]{2})([a-zA-Z0-9])/g,
  },
  {
    mask: '$1:$2:$3',
    regex: /^([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2})([a-zA-Z0-9])/g,
  },
  {
    mask: '$1:$2:$3:$4',
    regex: /^([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2})([a-zA-Z0-9])/g,
  },
  {
    mask: '$1:$2:$3:$4:$5',
    regex:
      /^([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2})([a-zA-Z0-9])/g,
  },
  {
    mask: '$1:$2:$3:$4:$5:$6',
    regex:
      /^([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2}):([a-zA-Z0-9]{2})([a-zA-Z0-9])/g,
  },
]
export const centralCodeMasker = createMasker(centralCodeMaskArgs)

export const batteryVoltageMaskerArgs: MaskArgs[] = [
  { mask: '', regex: /:/g },
  {
    mask: '$1.$2',
    regex: /^([0-9]{1})([0-9])/g,
  },
]
export const batteryVoltageMasker = createMasker(batteryVoltageMaskerArgs)
