import { ChangeEvent, useEffect, useState } from 'react'
import {
  Button,
  Input,
  Loader,
  ProtectedImage,
  Tags,
  Toast,
  AvatarChangerModal,
} from 'components'

import { ReactComponent as KeyIcon } from 'assets/svg/userInfoKeyIcon.svg'
import { ReactComponent as Avatar } from 'assets/svg/avatar.svg'
import { ReactComponent as Camera } from 'assets/svg/cameraIcon.svg'
import { useUserInfo } from 'shared/hooks/userInfo/useUserInfo'
import { CreateUserRequest } from 'services/auth'
import { SubmitHandler, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { UserSchema } from 'shared/schemas/user/createUserSchema'
import { useNavigate } from 'react-router'

import { useToggle } from 'shared/hooks'
import { dynamicSort } from '../../utilities/sort'

import styles from './Profile.module.scss'
import { ToastType } from 'components/Toast/types'

export const Profile = () => {
  const { userInfo, updateUserInfo, updateUserProfileImage, fetchUserInfo } =
    useUserInfo()

  const navigate = useNavigate()
  const loader = useToggle()
  const toast = useToggle()
  const photoChangeModal = useToggle()

  const [toastProps, setToastProps] = useState<{
    message: string
    type?: ToastType
  }>({
    message: '',
  })

  const [newPhotoSrc, setNewPhotoSrc] = useState('')

  const {
    setValue,
    getValues,
    formState: { errors: updateUserErrors, isValid, isDirty },
    register: userUpdate,
    handleSubmit: handleUserUpdateSubmit,
  } = useForm<CreateUserRequest>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: joiResolver(UserSchema),
  })

  useEffect(() => {
    userUpdate('email')
    setValue('email', userInfo?.email)
    setValue('name', userInfo?.name)
    setValue('phoneExtension', userInfo?.phoneExtension)
  }, [userInfo, setValue, userUpdate])

  const handlePhotoSelect = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (event.target.files?.length) {
      const photoUrl = URL.createObjectURL(event.target.files[0])
      setNewPhotoSrc(photoUrl)
      photoChangeModal.show()

      // necessary to trigger onChange again if the user selects the same file
      event.target.value = ''
    }
  }

  const handleUserUpdate: SubmitHandler<CreateUserRequest> = async (form) => {
    try {
      loader.show()
      await updateUserInfo({
        name: form.name,
        email: form.email,
        phoneExtension: form.phoneExtension,
      })
      setToastProps({
        message: 'Dados atualizados com sucesso.',
      })
    } catch (error) {
      setToastProps({
        message: 'Erro ao atualizar os dados. Tente novamente.',
        type: 'alert',
      })
    } finally {
      loader.hide()
      toast.show()
    }
  }

  const handlePhotoUpload = async (imageBlob: Blob | MediaSource) => {
    if (imageBlob) {
      try {
        loader.show()
        await updateUserProfileImage(imageBlob as File)
        setToastProps({
          message: 'Foto alterada com sucesso.',
        })
        photoChangeModal.hide()

        fetchUserInfo()
      } catch (error) {
        setToastProps({
          message: 'Erro ao alterar a foto.',
          type: 'alert',
        })
      } finally {
        loader.hide()
        toast.show()
      }
    }
  }

  return (
    <>
      <Loader isVisible={loader.isVisible} />

      <Toast
        type={toastProps.type}
        isVisible={toast.isVisible}
        onClose={() => toast.hide()}
      >
        {toastProps.message}
      </Toast>

      <AvatarChangerModal
        isVisible={photoChangeModal.isVisible}
        onClose={() => {
          setNewPhotoSrc('')
          photoChangeModal.hide()
        }}
        onSave={(image) => handlePhotoUpload(image)}
        avatarUrl={newPhotoSrc}
      />
      <div className={styles.container}>
        <span className={styles.title}>Meus dados</span>
        <div className={styles.divider} />
        <form className={styles.userInfoForm}>
          <div>
            <div className={styles.userInfoPhotoWrapper}>
              {userInfo?.imageId ? (
                <ProtectedImage
                  className={styles.userInfoPhoto}
                  imageId={userInfo.imageId}
                />
              ) : (
                <Avatar className={styles.userInfoPhoto} />
              )}
              <label htmlFor="avatar-edit">
                <Camera className={styles.avatarEditCameraIcon} />
              </label>
              <input
                type="file"
                id="avatar-edit"
                aria-label="avatar-edit-input"
                accept="image/*"
                onChange={handlePhotoSelect}
              />
            </div>
            <Button
              type="tertiary"
              buttonTitle="Redefinir senha"
              icon={KeyIcon}
              onClick={() => navigate('/change-password')}
            />
          </div>
          <div className={styles.userInfoData}>
            <Input
              id="name"
              defaultValue={getValues('name')}
              label="Nome completo"
              placeholder="Seu nome"
              {...userUpdate('name')}
              errorMessage={updateUserErrors.name?.message}
            />
            <Input
              id="phoneExtension"
              defaultValue={getValues('phoneExtension')}
              label="Ramal"
              placeholder="Ramal do usuário"
              {...userUpdate('phoneExtension')}
              maxLength={4}
              errorMessage={updateUserErrors.phoneExtension?.message}
            />
            {userInfo?.userCoverages?.primary && (
              <div>
                <label className={styles.userDataLabel}>
                  Perfil de atendimento
                </label>
                <Tags
                  expandTags
                  keyId={'userCoverages'}
                  keyLabel="userCoverages"
                  data={
                    userInfo?.userCoverages.primary.sort(dynamicSort('name')) ||
                    []
                  }
                  className={styles.permissionsTags}
                />
              </div>
            )}
            <div>
              <label className={styles.userDataLabel}>Permissões</label>
              <Tags
                expandTags
                keyId={'permissions'}
                keyLabel="permissions"
                data={
                  userInfo?.permissionGroups.sort(dynamicSort('name')) || []
                }
                className={styles.permissionsTags}
              />
            </div>
          </div>
        </form>
      </div>
      <div className={styles.userInfoBottomButtonsContainer}>
        <div className={styles.userInfoButtonsWrapper}>
          <Button
            buttonTitle="Voltar"
            id="user-info-back"
            type="secondary"
            onClick={() => navigate(-1)}
            width="172px"
          />
          <Button
            width="172px"
            buttonTitle="Salvar"
            id="user-info-save"
            className={styles.userInfoSaveBtn}
            type="primary"
            htmlType="submit"
            onClick={handleUserUpdateSubmit(handleUserUpdate)}
            disabled={!isValid && !isDirty}
          />
        </div>
      </div>
    </>
  )
}
