import React, { useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useToggle } from 'shared/hooks'

import {
  Account,
  PatrimonyWithCustomerAndAccountPayload,
} from 'services/account/types'
import { useGetPatrimony } from 'services/patrimony/hooks/useGetPatrimony'
import { usePutPatrimony } from 'services/patrimony/hooks/usePutPatrimony'
import { PatrimonyWithAccountRequest } from 'services/patrimony/types'
import { usePostCustomer } from 'services/customer/hooks'
import ListAccounts from '../ListAccounts'
import AccountModal from '../AccountModal'

import styles from './AccountAccordionGroup.module.scss'
import { Loader } from 'domains/occurrence/components'

export const AccountAccordionGroup: React.FC = () => {
  const {
    watch,
    setValue,
    register,
    formState: { isValid },
  } = useFormContext<
    PatrimonyWithCustomerAndAccountPayload | PatrimonyWithAccountRequest
  >()

  const { mutate: mutateUpdatePatrimony } = usePutPatrimony()
  const { mutate: mutateCreateCustomer } = usePostCustomer()

  const accountModal = useToggle()
  const [selectedAccount, setSelectedAccount] = useState<Account>()
  const [readOnly, setReadOnly] = useState(false)
  const patrimonyId = watch('patrimony')?.id

  const {
    data: patrimony,
    isLoading,
    isFetching,
  } = useGetPatrimony(patrimonyId)

  const initialPatrimony = useMemo(() => {
    if (!patrimonyId) {
      return undefined
    }
    return patrimony
  }, [patrimony, patrimonyId])

  const handleSuccess = useCallback(
    (
      data:
        | PatrimonyWithCustomerAndAccountPayload
        | PatrimonyWithAccountRequest,
    ) => {
      register('patrimony.id')
      setValue('patrimony.id', data.patrimony.id)
      setSelectedAccount(undefined)
      accountModal.show()
    },
    [accountModal, register, setValue],
  )

  const handleAutoSave = useCallback(
    (
      payload:
        | PatrimonyWithCustomerAndAccountPayload
        | PatrimonyWithAccountRequest,
    ) => {
      const formattedPayload = {
        ...payload,
        patrimony: {
          ...payload.patrimony,
          postalCode: String(payload.patrimony.postalCode).replace(/\D/g, ''),
        },
      }

      'customer' in payload && payload.customer
        ? mutateCreateCustomer(
            {
              patrimony: {
                ...formattedPayload.patrimony,
                postalCode: Number(formattedPayload.patrimony.postalCode),
              },
              customer: {
                ...payload?.customer,
                document: payload?.customer?.document?.replace(/[.\-/]/g, ''),
              },
            },
            {
              onSuccess: (data) => {
                register('patrimony.customerId')
                setValue('patrimony.customerId', data.patrimony.customerId)

                handleSuccess(data)
              },
            },
          )
        : mutateUpdatePatrimony(
            {
              patrimony: {
                ...formattedPayload.patrimony,
                id: watch('patrimony')?.id,
                customerId: watch('patrimony')?.customerId,
                postalCode: Number(formattedPayload.patrimony.postalCode),
              },
              accounts: formattedPayload.accounts,
              customerId: watch('patrimony')?.customerId,
            },
            {
              onSuccess: handleSuccess,
            },
          )
    },
    [
      handleSuccess,
      mutateCreateCustomer,
      mutateUpdatePatrimony,
      register,
      setValue,
    ],
  )

  const handleView = useCallback(
    (readOnly: boolean, account: Account) => {
      setReadOnly(readOnly)
      setSelectedAccount(account)
      accountModal.show()
    },
    [accountModal],
  )

  const handleRender = (): string => {
    if (isLoading || isFetching) return 'loading'
    return 'view'
  }

  return (
    <div className={styles.container}>
      {accountModal.isVisible && (
        <AccountModal
          readOnly={readOnly}
          account={selectedAccount}
          onClose={accountModal.hide}
          patrimonyId={watch('patrimony.id')}
          setReadOnly={() => setReadOnly(false)}
        />
      )}
      {
        {
          view: (
            <>
              <div className={styles.header}>
                <h1>Tipo de conta</h1>
                <p>
                  A conta define o tipo de serviço que é oferecido ao cliente
                </p>
              </div>
              <ListAccounts
                isEnabled={!!initialPatrimony || isValid}
                onCreate={() => {
                  if (initialPatrimony) {
                    setSelectedAccount(undefined)
                    accountModal.show()
                  } else handleAutoSave(watch())
                }}
                onView={(account: Account) => handleView(true, account)}
                onEdit={(account: Account) => handleView(false, account)}
                accounts={initialPatrimony?.accounts}
              />
            </>
          ),
          loading: <Loader />,
        }[handleRender()]
      }
    </div>
  )
}
