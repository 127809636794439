import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Modal } from 'components'
import { usePostIntervention } from 'services/attendance'
import { RouteParams } from 'domains/occurrence/screens/Attendance/types'
import { useModal } from 'domains/occurrence/screens/Attendance/context'
import { useUserInfo } from 'shared/hooks'

const IntrusionConfirmed: FC = () => {
  const { occurrenceId } = useParams<RouteParams>()
  const { handleCloseModal } = useModal()
  const { userInfo } = useUserInfo()
  const { mutate, isPending } = usePostIntervention(occurrenceId)

  const handleConfirm = useCallback(() => {
    const userId = userInfo.id

    if (userId) {
      mutate(
        {
          userId,
          attributes: {
            agent: {
              // TODO Objeto passado apenas por que "attributes" não pode estar vazio
              id: '',
              name: '',
            },
          },
          typeName: 'CONFIRM_INTRUSION',
          tags: [{ name: 'intrusaoConfirmada' }],
          title: 'Registro de intrusão confirmada',
        },
        {
          onSuccess: handleCloseModal,
        },
      )
    }
  }, [handleCloseModal, mutate])

  return (
    <Modal
      isVisible
      onClose={handleCloseModal}
      title="Registrar intrusão confirmada"
      size="sm"
      simple
    >
      <p>
        Tem certeza de que deseja registrar uma intrusão confirmada neste
        patrimônio?
      </p>
      <Modal.Footer>
        <Button
          width="186px"
          buttonTitle="Cancelar"
          onClick={handleCloseModal}
          type="secondary"
        />
        <Button
          width="186px"
          disabled={isPending}
          buttonTitle="Continuar"
          onClick={handleConfirm}
          type="primary"
        />
      </Modal.Footer>
    </Modal>
  )
}

export default IntrusionConfirmed
