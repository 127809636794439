import { useEffect, useState } from 'react'
import { Button, DragAndDrop, Modal, Combobox, ComboboxItem } from 'components'

import { Partitions } from 'services/serviceOrder'
import { PartitionContact } from 'services/partition/types'

import classes from './ContactsModal.module.scss'

interface ContactsModalProps {
  onClose: () => void
  selectedPartition?: Partitions
  partitions: Partitions[]
}

export function ContactsModal({
  onClose,
  selectedPartition,
  partitions,
}: ContactsModalProps): JSX.Element {
  const [partitionContacts, setPartitionContacts] =
    useState<PartitionContact[]>()
  const [activePartition, setActivePartition] = useState<string>('')

  const getContactType = (type: string) => {
    switch (type) {
      case 'ADMIN':
        return 'Administrador'
      case 'GUEST':
        return 'Convidado'
      case 'CONTACT':
        return 'Emergência'
      default:
        return ''
    }
  }

  useEffect(() => {
    if (selectedPartition) {
      setPartitionContacts(selectedPartition.contacts)
      setActivePartition(selectedPartition.name)
    }
  }, [selectedPartition])

  return (
    <Modal
      title="Lista de contatos"
      isVisible
      onClose={onClose}
      className={classes.contactsModal}
      size="sm"
    >
      <Combobox
        label="Partição"
        value={activePartition}
        items={partitions.map((partition) => ({
          label: 'name',
          value: partition,
        }))}
        getSelected={(selected) => {
          const partition = selected as ComboboxItem<Partitions>
          setPartitionContacts(partition.value.contacts)
        }}
      />
      <div className={classes.dndWrapper}>
        {partitionContacts && (
          <DragAndDrop
            items={partitionContacts}
            draggable={false}
            getItem={(contact) => ({
              id: contact?.id || '',
              title: contact?.name || '',
              subtitle: getContactType(contact?.type),
              draggable: false,
            })}
            onChange={setPartitionContacts}
          />
        )}
      </div>
      <Modal.Footer>
        <Button
          width="87px"
          buttonTitle={'Voltar'}
          onClick={onClose}
          type="secondary"
        />
      </Modal.Footer>
    </Modal>
  )
}
