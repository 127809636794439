import Joi from '@hapi/joi'
import { coverageHoliday } from '../types'

import { HolidayPayload } from 'services/holiday/types'

export const holidaySchema = Joi.object<HolidayPayload>({
  name: Joi.string().max(50).min(5).required(),
  repeatable: Joi.boolean().required(),
  day: Joi.number().positive().min(1).max(31).required(),
  month: Joi.number().positive().min(1).max(12).required(),
  year: Joi.when('repeatable', {
    is: false,
    then: Joi.number().positive().min(new Date().getFullYear()).required(),
    otherwise: Joi.number().allow(0).optional(),
  }),
  coverageType: Joi.string()
    .valid(...Object.keys(coverageHoliday))
    .required(),
  coverage: Joi.when('coverageType', {
    is: coverageHoliday.REGIONAL,
    then: Joi.custom((value, helpers) => {
      const { states, cities } = value

      if (!states?.length && !cities?.length) {
        return helpers.error('any.required')
      }
      return value
    }, 'custom validation').required(),
    otherwise: Joi.object().optional(),
  }),
})
