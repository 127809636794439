import Joi from '@hapi/joi'

import { Account, Form } from './types'

export const accountSchema = Joi.object<Account>({
  id: Joi.string().required(),
  canEdit: Joi.boolean().optional(),
  serviceType: Joi.string().required(),
  admin: Joi.boolean().optional(),
  hasControl: Joi.boolean().optional(),
  actionable: Joi.boolean().optional(),
  aggregatedAccountName: Joi.string().required(),
})

export const formSchema = Joi.object<Form>({
  name: Joi.string().required(),
  email: Joi.string()
    .allow('', null)
    .email({ tlds: { allow: false } })
    .optional(),
  ubiAppUser: Joi.boolean(),
  allPartitions: Joi.boolean(),
  allActionPlans: Joi.boolean(),
  account: accountSchema.optional(),
  accounts: Joi.array().items(accountSchema).optional(),
  role: Joi.string().max(45).allow('', null).optional(),
  phone: Joi.string().min(14).max(15).allow('', null).optional(),
})
