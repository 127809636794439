import { useGetOccurrence } from 'services/displacementMap'
import { useMap, useDisplacementModal } from '../../../../contexts'
import { Button, Modal } from 'components'
import { useUpdateCoordinates } from 'services/patrimony'

const UpdateCoordinates: React.FC = () => {
  const { handleCloseModal } = useDisplacementModal()
  const { selectedRoute } = useMap()
  const { mutate } = useUpdateCoordinates()
  const { data } = useGetOccurrence(selectedRoute.occurrence?.id)

  return (
    <Modal
      simple
      title="Atualizar coordenadas"
      isVisible
      onClose={handleCloseModal}
    >
      <p>
        `Você gostaria de atualizar as coordenadas cadastradas para este
        patrimônio? Esta ação não poderá ser desfeita.`
      </p>
      <Modal.Footer>
        <Button
          autofocus
          buttonTitle="Rejeitar"
          onClick={handleCloseModal}
          type="secondary"
          width="172px"
        />
        <Button
          autofocus
          buttonTitle="Aceitar"
          disabled={!data}
          onClick={() => {
            if (data && !!selectedRoute) {
              mutate(
                {
                  patrimonyId: data?.patrimony.id,
                  latitude: selectedRoute.tactical?.position.latitude,
                  longitude: selectedRoute.tactical?.position.longitude,
                },
                {
                  onSuccess: handleCloseModal,
                },
              )
            }
          }}
          type="primary"
          width="172px"
        />
      </Modal.Footer>
    </Modal>
  )
}

export { UpdateCoordinates }
