import { useMutation, useQueryClient } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { InterventionPayload } from './types'

interface CreateIntervention {
  serviceOrderId?: string
  data: InterventionPayload
}

export const createIntervention = async ({
  serviceOrderId,
  data,
}: CreateIntervention) => {
  const { note, ...payload } = data
  const formData = new FormData()

  if (note) {
    const { images, ...rest } = note

    images?.map((image) => formData.append('imageFile', image, image.name))

    formData.append('payload', JSON.stringify({ ...payload, note: rest }))
  } else {
    formData.append('payload', JSON.stringify(payload))
  }

  const response = await HttpClient.post(
    buildUrl({
      route: endpoints.createIntervention,
      params: { serviceOrderId },
    }),
    formData,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': `multipart/form-data`,
      },
    },
  )

  return response.data
}

export const useCreateIntervention = (serviceOrderId?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: InterventionPayload) =>
      await createIntervention({ serviceOrderId, data }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['service-order-intervention', serviceOrderId],
      })
      queryClient.invalidateQueries({
        queryKey: ['service-order', serviceOrderId],
      })
      queryClient.invalidateQueries({
        queryKey: [
          'get-image',
          {
            owners: `serviceOrder_${serviceOrderId}`,
            sortType: 'desc',
          },
        ],
      })
    },
  })
}
