import * as Joi from '@hapi/joi'

import { CustomerPayload } from 'services/customer/types'
import { patrimonyPayloadSchema } from 'shared/schemas'
import { PatrimonyWithCustomerAndAccountPayload } from 'services/account/types'

const personTypeSchema = Joi.string().valid('F', 'J')

const customerSchema = Joi.object<CustomerPayload>({
  document: Joi.string().required(),
  name: Joi.string().required(),
  personType: personTypeSchema.required(),
  secondName: Joi.string().when('personType', {
    is: 'J',
    then: Joi.string().required(),
    otherwise: Joi.string(),
  }),
  costCenter: Joi.string().max(45).optional().allow('', null),
})

export const createAccountSchema =
  Joi.object<PatrimonyWithCustomerAndAccountPayload>({
    customer: customerSchema.required(),
    patrimony: patrimonyPayloadSchema.required(),
    hasAccount: Joi.boolean().optional(),
  })
