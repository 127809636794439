import { TagsType, Comment } from '../types'

const interventionTags = {
  NAO_ENVIA_COMUNICADO: 'naoEnviaComunicado',
  CLOSED_PET_DETECTED: 'encerradoDetectadoPET',
  UNABLE_TO_CONTACT: 'contatoSemExito',
  UNAVAILABLE_NUMBER: 'numeroIndisponivel',
  LOW_BATTERY_CUSTOMER: 'cienteBateriaBaixa',
  NO_ELECTRICITY: 'semEnergia',
  ALL_FINE_ON_PLACE: 'tudoBemNoLocal',
  CONFIRMED_KEYWORD: 'confirmouPalavraChave',
  NOT_CONFIRM_KEYWORD: 'naoConfirmouPalavraChave',
  NOT_AT_LOCATION: 'naoEstaLocal',
  NEXT_CONTACT: 'proximoContato',
  REQUESTED_POLICE: 'solicitouPolicia',
  REPORTED_INTRUSION: 'informouIntrusao',
  REQUESTED_CONTACT_BACK: 'solicitouRetornoContato',
  NOT_ANSWER: 'naoAtende',
  BUSY: 'ocupado',
  VOICEMAIL: 'caixaPostal',
  NON_EXISTENT_PHONE: 'telefoneNaoExiste',
  INCORRECT_PHONE: 'telefoneIncorreto',
  WITH_MOVEMENT: 'comDeslocamento',
  WITHOUT_MOVEMENT: 'semDeslocamento',
  PERSON_DETECTED: 'pessoaDetectada',
  PET_DETECTED: 'petDetectado',
  NO_ABNORMALITY: 'semAnormalidade',
  IMAGE_PROBLEM: 'problemaImagem',
  ABNORMALITY_ON_PLACE: 'anormalidadeNoLocal',
  NO_ABNORMALITY_ON_PLACE: 'semAnormalidadeNoLocal',
  NO_MODULE_RESPONSE: 'semRespostaModulo',
  OS_VALIDATION: 'validacaoOS',
  HAS_OPEN_OS: 'possuiOSAberta',
  OPEN_OS: 'abertoOS',
  FALSE_ALARM: 'disparoFalso',
  SPECIALIST_CASE: 'maletaEspecialista',
  COMMUNICATION_FAILURE: 'falhaComunicacao',
  SHOCK_ONLY: 'somenteShock',
  ALARM_WITHOUT_PHOTO: 'disparoSemFoto',
  SPECIALIST_REQUESTED_DISREGARD: 'especialistaPediuDesconsiderar',
  SUITCASE_DEMO: 'demonstracaoMaleta',
  DEMO_SUCCESS: 'demonstracaoSucesso',
  DEMO_FAILURE: 'demonstracaoFalha',
  SPECIALIST_INTERRUPTED_DEMO: 'especialistaInterrompeuDemonstracao',
  SPECIALIST_NOT_IDENTIFY_CUSTOMER: 'especialistaNaoIdentificouCliente',
  SPECIALIST_IGNORED_DEMO: 'especialistaIgnorouDemonstracao',
}

export const interventionPredefinedComments: Record<TagsType, Comment[]> = {
  CONTACT_CALL: [
    {
      message:
        'Feito contato com responsável [NOME], que informou estar tudo bem no local. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.ALL_FINE_ON_PLACE },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que informou não estar no local e solicitou contato para o próximo contato. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.NOT_AT_LOCATION },
        { name: interventionTags.NEXT_CONTACT },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que informou não estar no local e solicitou o acionamento da PM. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.NOT_AT_LOCATION },
        { name: interventionTags.REQUESTED_POLICE },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que informou ter havido uma intrusão. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.REPORTED_INTRUSION },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que informou não estar no local e irá averiguar solicitando que retornemos posteriormente. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.NOT_AT_LOCATION },
        { name: interventionTags.REQUESTED_CONTACT_BACK },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que ficou ciente da Bateria Baixa e foi orientado a verificar a conexão do módulo com a tomada. Confirmada a palavra-chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.CONFIRMED_KEYWORD },
        { name: interventionTags.LOW_BATTERY_CUSTOMER },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que ficou ciente da Bateria baixa e informou que está sem energia elétrica no seu patrimônio. Confirmada a palavra-chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.CONFIRMED_KEYWORD },
        { name: interventionTags.LOW_BATTERY_CUSTOMER },
        { name: interventionTags.NO_ELECTRICITY },
      ],
    },
    {
      message: 'Feito contato com [NOME] porém não confirmou a palavra chave.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.NOT_CONFIRM_KEYWORD }],
    },
    {
      message: 'Não atende.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.NOT_ANSWER }],
    },
    {
      message: 'Ocupado.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.BUSY }],
    },
    {
      message: 'Caixa postal.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.VOICEMAIL }],
    },
    {
      message: 'Telefone não existe.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.NON_EXISTENT_PHONE }],
    },
    {
      message: 'Telefone incorreto.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.INCORRECT_PHONE }],
    },
  ],
  SAFE_CALL: [
    {
      message:
        'Feito contato com responsável [NOME], que informou estar tudo bem no local. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.ALL_FINE_ON_PLACE },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que informou ter havido uma intrusão. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.REPORTED_INTRUSION },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com o módulo e verificado anormalidade no local. Será acionada a PM.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.ABNORMALITY_ON_PLACE }],
    },
    {
      message:
        'Feito contato com o módulo, não obtivemos resposta, porém não foi percebida anormalidade no local.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.NO_MODULE_RESPONSE },
        { name: interventionTags.NO_ABNORMALITY_ON_PLACE },
      ],
    },
    {
      message: 'Feito contato com [NOME] porém não confirmou a palavra chave.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.NOT_CONFIRM_KEYWORD }],
    },
    {
      message: 'Especialista solicitando validação de OS.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.OS_VALIDATION }],
    },
    {
      message: 'Especialista pediu para desconsiderar.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.SPECIALIST_REQUESTED_DISREGARD }],
    },
    {
      message: 'Especialista solicitou demonstração com a central na maleta.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.SUITCASE_DEMO }],
    },
    {
      message: 'Especialista interrompeu a demonstração.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.SPECIALIST_INTERRUPTED_DEMO }],
    },
    {
      message: 'Especialista não identificou o cliente.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.SPECIALIST_NOT_IDENTIFY_CUSTOMER }],
    },
    {
      message:
        'Especialista solicitou demonstração e continuou dando explicação ao cliente.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.SUITCASE_DEMO },
        { name: interventionTags.SPECIALIST_IGNORED_DEMO },
      ],
    },
    {
      message: 'Demonstração realizada com sucesso.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.DEMO_SUCCESS }],
    },
    {
      message: 'Não atende.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.NOT_ANSWER }],
    },
    {
      message: 'Ocupado.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.BUSY }],
    },
    {
      message: 'Caixa postal.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.VOICEMAIL }],
    },
    {
      message: 'Telefone não existe.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.NON_EXISTENT_PHONE }],
    },
    {
      message: 'Telefone incorreto.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.INCORRECT_PHONE }],
    },
    {
      message: 'Número indisponível.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.UNAVAILABLE_NUMBER }],
    },
  ],
  POLICE_CALL: [
    {
      message:
        'Feito contato com agente [NOME], que informou que irá deslocar viatura para o local.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.WITH_MOVEMENT }],
    },
    {
      message:
        'Feito contato com agente [NOME], que informou que NÃO irá deslocar viatura.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.WITHOUT_MOVEMENT }],
    },
    {
      message: 'Não atende.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.NOT_ANSWER }],
    },
    {
      message: 'Ocupado.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.BUSY }],
    },
    {
      message: 'Caixa postal.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.VOICEMAIL }],
    },
    {
      message: 'Telefone não existe.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.NON_EXISTENT_PHONE }],
    },
    {
      message: 'Telefone incorreto.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.INCORRECT_PHONE }],
    },
  ],
  MANUAL_REGISTER: [
    {
      message:
        'Feito contato com agente [NOME], que informou que irá deslocar viatura para o local.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.WITH_MOVEMENT }],
    },
  ],
  SEND_MESSAGE: [
    {
      message:
        'Feito contato com responsável [NOME], que informou estar tudo bem no local. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.ALL_FINE_ON_PLACE },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que informou não estar no local e solicitou contato para o próximo contato. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.NOT_AT_LOCATION },
        { name: interventionTags.NEXT_CONTACT },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que informou não estar no local e solicitou o acionamento da PM. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.NOT_AT_LOCATION },
        { name: interventionTags.REQUESTED_POLICE },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que informou ter havido uma intrusão. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.REPORTED_INTRUSION },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message:
        'Feito contato com responsável [NOME], que informou não estar no local e irá averiguar solicitando que retornemos posteriormente. Confirmada a palavra chave.',
      status: 'SUCCESS',
      tags: [
        { name: interventionTags.NOT_AT_LOCATION },
        { name: interventionTags.REQUESTED_CONTACT_BACK },
        { name: interventionTags.CONFIRMED_KEYWORD },
      ],
    },
    {
      message: 'Feito contato com [NOME] porém não confirmou a palavra chave.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.NOT_CONFIRM_KEYWORD }],
    },
    {
      message: 'Não responde.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.NOT_ANSWER }],
    },
    {
      message: 'Telefone incorreto.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.INCORRECT_PHONE }],
    },
  ],
  IMAGE_VERIFICATION: [
    {
      message: 'Detectada pessoa.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.PERSON_DETECTED }],
    },
    {
      message: 'Detectado PET.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.PET_DETECTED }],
    },
    {
      message: 'Sem anormalidade.',
      status: 'SUCCESS',
      tags: [{ name: interventionTags.NO_ABNORMALITY }],
    },
    {
      message: 'Imagem com problemas.',
      status: 'FAILURE',
      tags: [{ name: interventionTags.IMAGE_PROBLEM }],
    },
  ],
  FINISHED: [
    {
      message: '#naoEnviaComunicado',
      tags: [{ name: interventionTags.NAO_ENVIA_COMUNICADO }],
      status: 'SUCCESS',
    },
    {
      message:
        'Atendimento encerrado por disparo em falso. Cliente já possui OS aberta.',
      tags: [
        { name: interventionTags.FALSE_ALARM },
        { name: interventionTags.HAS_OPEN_OS },
      ],
      status: 'SUCCESS',
    },
    {
      message:
        'Atendimento encerrado por disparo em falso. Aberta OS nº[NUMERO] para verificação do sistema.',
      tags: [
        { name: interventionTags.FALSE_ALARM },
        { name: interventionTags.OPEN_OS },
      ],
      status: 'SUCCESS',
    },
    {
      message: 'Trata-se de especialista Orsegups e maleta de demonstração.',
      tags: [{ name: interventionTags.SPECIALIST_CASE }],
      status: 'SUCCESS',
    },
    {
      message: 'Fechamento após falha de comunicação.',
      tags: [{ name: interventionTags.COMMUNICATION_FAILURE }],
      status: 'SUCCESS',
    },
    {
      message: 'Apenas evento de shock.',
      tags: [{ name: interventionTags.SHOCK_ONLY }],
      status: 'SUCCESS',
    },
    {
      message: 'Atendimento encerrado, disparo sem foto.',
      tags: [{ name: interventionTags.ALARM_WITHOUT_PHOTO }],
      status: 'SUCCESS',
    },
    {
      message: 'Demonstração realizada com sucesso.',
      tags: [{ name: interventionTags.DEMO_SUCCESS }],
      status: 'SUCCESS',
    },
    {
      message: 'Trata-se de detecção de PET.',
      tags: [{ name: interventionTags.CLOSED_PET_DETECTED }],
      status: 'SUCCESS',
    },
    {
      message: 'Contato sem êxito.',
      tags: [{ name: interventionTags.UNABLE_TO_CONTACT }],
      status: 'SUCCESS',
    },
    {
      message: 'Demonstração não realizada.',
      tags: [{ name: interventionTags.DEMO_FAILURE }],
      status: 'FAILURE',
    },
  ],
  QUEUED: [],
  TRAVEL: [],
  AVAILABLE: [],
  SEND_IMAGE: [],
  INTRUSION_CONFIRMED: [],
}
