import { SERVICE_ORDER_STATUS_ENUM } from 'services/serviceOrder'

export const handleStatusLabel = (status: SERVICE_ORDER_STATUS_ENUM) => {
  const statuses: Record<SERVICE_ORDER_STATUS_ENUM, string> = {
    OPEN: 'Aberta',
    IN_PROGRESS: 'Em execução',
    PAUSED: 'Em pausa',
    FINISHED: 'Finalizada',
    CANCELED: 'Cancelada',
    SCHEDULED: 'Agendada',
    IN_VALIDATION: 'Em validação',
    DISAPPROVED: 'Reprovada',
    RESCHEDULED: 'Reagendada',
  }

  return statuses[status]
}
