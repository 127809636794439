import { FC } from 'react'
import { Button, Modal } from 'components'
import { useGetTacticalDetails } from 'services/displacementMap'

import { BackDrop } from '../../../../components'
import { useDisplacementModal, useMap } from '../../../../contexts'

export type ResumeDutyTactical = {
  userId: string
}

type ResumeDutyProps = {
  isSuccess?: boolean
}

export const ResumeDuty: FC<ResumeDutyProps> = ({ isSuccess }) => {
  const { handleCloseModal } = useDisplacementModal()

  const { selectedRoute } = useMap()

  const { data: tactical } = useGetTacticalDetails(selectedRoute.tactical?.id)

  const SUCCESS_MESSAGE = `${
    tactical?.agent.name || ''
  } está apto a atender ocorrências enviadas para deslocamento novamente.`

  const ERROR_MESSAGE = `Não foi possível retomar o plantão do agente ${
    tactical?.agent.name || ''
  }`

  return (
    <BackDrop onClose={handleCloseModal}>
      <Modal
        isVisible
        onClose={handleCloseModal}
        size={'sm'}
        simple
        title={
          isSuccess
            ? 'Plantão tático retomado com sucesso'
            : 'Erro ao retomar plantão tático'
        }
      >
        <section>
          <p>{isSuccess ? SUCCESS_MESSAGE : ERROR_MESSAGE}</p>
          <Modal.Footer>
            <Button
              width="172px"
              type="secondary"
              buttonTitle="Fechar"
              onClick={handleCloseModal}
            />
          </Modal.Footer>
        </section>
      </Modal>
    </BackDrop>
  )
}
