import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Droplist, Icon } from 'components'
import { useUserInfo } from 'shared/hooks'
import { RouteParams } from 'domains/occurrence/screens/Attendance/types'
import {
  handleFormatPhone,
  interventionTagLabel,
} from 'domains/occurrence/screens/Attendance/utils'
import { ActionPlan, ContactType, Phone } from 'services/attendance/types'
import { useMakeCall, usePostIntervention } from 'services/attendance'

import styles from './styles.module.scss'

type PhoneCall = {
  phone: Phone
  contact: {
    id: string
    name: string
  }
}

interface ActionPlanItemProps {
  actionPlan: ActionPlan
}

const handleWhatsapp = (phoneNumber: string): Window | null => {
  return window.open(`https://wa.me/+55${phoneNumber}`, '_blank')
}

const handlePhoneType = (type: ContactType) => {
  const options: Record<ContactType, string> = {
    ADMIN: 'Administrador',
    CONTACT: 'Contato',
    GUEST: 'Convidado',
  }

  return options[type]
}

const ActionPlanItem = ({ actionPlan }: ActionPlanItemProps) => {
  const { userInfo } = useUserInfo()
  const { occurrenceId } = useParams<RouteParams>()

  const { mutate: call } = useMakeCall()
  const { mutate: createIntervention } = usePostIntervention(occurrenceId)

  const handlePhoneCall = useCallback(
    ({ phone, contact }: PhoneCall) => {
      const userId = userInfo.id

      if (userId && userInfo) {
        const phoneFormatted = `${phone.provinceCode || ''}${phone.phone}`

        createIntervention(
          {
            userId,
            typeName: 'PHONE_CALL',
            attributes: {
              number: phoneFormatted,
              status: 'PENDING',
              contactName: contact.name,
            },
            title: `Ligação para contato ${contact.name}`,
            tags: [{ name: 'ligacaoContato' }],
          },
          {
            onSuccess: () => {
              call({
                phoneNumber: phoneFormatted,
                phoneExtension: userInfo?.phoneExtension,
              })
            },
          },
        )
      }
    },
    [call, createIntervention, userInfo],
  )

  const handleSendMessage = useCallback(
    ({ phone, contact }: PhoneCall) => {
      const userId = userInfo.id

      if (userId) {
        const phoneFormatted = `${phone.provinceCode || ''}${phone.phone}`

        createIntervention(
          {
            userId,
            typeName: 'SEND_MESSAGE',
            attributes: {
              number: phoneFormatted,
              status: 'PENDING',
              contactName: contact.name,
            },
            title: `Mensagem de WhatsApp para ${contact.name}`,
            tags: [{ name: interventionTagLabel.SEND_MESSAGE }],
          },
          {
            onSuccess: () => handleWhatsapp(phoneFormatted),
          },
        )
      }
    },
    [createIntervention],
  )

  return (
    <li className={styles.actionPlanItem}>
      <div>
        <span>{actionPlan.name}</span>
        <span>
          {handlePhoneType(actionPlan.type)}
          {actionPlan.hasRemoteControl && <Icon name="control" width={10} />}
        </span>
      </div>
      <Droplist
        trigger={
          <span className={styles.phone}>
            <Icon name="phone" />
            {`(${actionPlan.phone.provinceCode}) ${handleFormatPhone(
              actionPlan.phone.number.toString(),
            )}`}
          </span>
        }
      >
        <button
          className={styles.dropDownItem}
          onClick={() =>
            handlePhoneCall({
              phone: {
                phone: String(actionPlan.phone.number),
                provinceCode: String(actionPlan.phone.provinceCode),
              },
              contact: { id: actionPlan.id, name: actionPlan.name },
            })
          }
        >
          Ligar para o número
        </button>
        <button
          className={styles.dropDownItem}
          onClick={() =>
            handleSendMessage({
              phone: {
                phone: String(actionPlan.phone.number),
                provinceCode: String(actionPlan.phone.provinceCode),
              },
              contact: { id: actionPlan.id, name: actionPlan.name },
            })
          }
        >
          Chat via Whatsapp
        </button>
      </Droplist>
    </li>
  )
}

export default ActionPlanItem
