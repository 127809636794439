import { useCallback, useMemo } from 'react'

import {
  convertTolerance,
  dayOfWorkKeys,
  workingDaysOfTheWeek,
  translatedDayOfWork,
  translatedTolerance,
  dayOfWorkIndexPosition,
} from '../../../../../../utilities/dayOfWork'

import {
  OFFICE_HOURS_CONFIG,
  officeHoursConfigLabel,
  parametersKeys,
} from 'domains/customer/screens/OfficeHours/data'

import { ReactComponent as Exclamation } from '../../../../assets/exclamation.svg'

import { Checkbox, IconWithTooltip } from 'components'

import { Combobox } from 'components/ComboboxV2/Combobox'

import { ScaleTimeConfig } from 'domains/customer/screens/OfficeHours/components/OfficeHoursForm/components'

import { OfficeHoursFormData } from 'domains/customer/screens/OfficeHours/types'
import { handleDaySelectedOption } from 'domains/customer/screens/OfficeHours/utilities/utilities'

import styles from './BusinessHours.module.scss'
import { useFormContext } from 'react-hook-form'

export const BusinessHours = () => {
  const { watch, setValue, register } = useFormContext<OfficeHoursFormData>()

  const scales = watch('scales')
  const tolerance = watch('tolerance')

  const dayOfWork = useMemo(() => {
    return scales.map((scale) => scale.dayOfWork)
  }, [scales])

  const isWorkDaySelected = useMemo(
    () => dayOfWork.includes('WORKDAYS'),
    [dayOfWork],
  )

  const selectedAllDaysOfTheWeek = useMemo(() => {
    const allDaysPresent = workingDaysOfTheWeek.every((day) =>
      dayOfWork.includes(day),
    )

    return allDaysPresent
  }, [dayOfWork])

  const checkSelectedDay = useCallback(
    (dayOption: string) => {
      const selectedDay = dayOfWorkKeys[dayOption]

      return dayOfWork.includes(selectedDay)
    },
    [dayOfWork],
  )

  const handleDisableCheckBox = useCallback(
    (day: string) => {
      if (selectedAllDaysOfTheWeek && dayOfWorkKeys[day] === 'WORKDAYS') {
        return true
      }

      if (!isWorkDaySelected) return false

      return workingDaysOfTheWeek.includes(dayOfWorkKeys[day])
    },
    [selectedAllDaysOfTheWeek, isWorkDaySelected],
  )

  const checkParameter = useCallback(
    (parameter: OFFICE_HOURS_CONFIG) => {
      if (!watch('parameters')) {
        return false
      }

      const parameterKey = parametersKeys[parameter]
      const isSelected = watch('parameters')[parameterKey]

      return isSelected
    },
    [watch('parameters')],
  )

  return (
    <div className={styles.container}>
      <section className={styles.leftSection}>
        <h2>Detalhes da tabela</h2>
        <div>
          <h3>Escala semanal</h3>
          <div className={styles.dayOfWorkOptions}>
            {Object.values(translatedDayOfWork).map((day) => {
              return (
                <div key={day} className={styles.checkBoxContainer}>
                  <Checkbox
                    id={day}
                    disabled={handleDisableCheckBox(day)}
                    checked={checkSelectedDay(day)}
                    small
                    label={day}
                    onChange={() => {
                      const selectedDay = dayOfWorkKeys[day]

                      const selectedDays = handleDaySelectedOption(
                        selectedDay,
                        scales,
                      )

                      setValue('scales', selectedDays, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }}
                  />

                  {dayOfWorkKeys[day] === 'HOLIDAY' && (
                    <div className={styles.iconInfo}>
                      <IconWithTooltip
                        text="Feriados cadastrados via sistema"
                        Icon={Exclamation}
                      />
                    </div>
                  )}
                </div>
              )
            })}

            <div>
              <Combobox
                id="tolerance"
                {...register('tolerance')}
                items={Object.values(translatedTolerance)}
                value={translatedTolerance[tolerance]}
                onChange={(value) => {
                  const selectedTolerance = value as string

                  setValue('tolerance', convertTolerance[selectedTolerance], {
                    shouldValidate: true,
                  })
                }}
                label={{
                  text: 'Tolerância',
                  hint: {
                    text: 'Período de transição entre arme e desarme',
                    icon: Exclamation,
                  },
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.checkboxItems}>
          {Object.values(officeHoursConfigLabel).map((parameter) => {
            const parameterKey = parametersKeys[parameter]

            return (
              <Checkbox
                {...register(`parameters.${parameterKey}`)}
                key={parameter}
                id={parameter}
                checked={checkParameter(parameter as OFFICE_HOURS_CONFIG)}
                label={parameter}
                small
                onChange={(event) => {
                  const checked = event.target.checked

                  setValue(
                    'parameters',
                    {
                      ...watch('parameters'),
                      [parameterKey]: checked,
                    },
                    { shouldValidate: true },
                  )
                }}
              />
            )
          })}
        </div>
      </section>

      <section className={styles.rightSection} {...register('scales')}>
        {scales
          .sort(
            (a, b) =>
              dayOfWorkIndexPosition[a.dayOfWork] -
              dayOfWorkIndexPosition[b.dayOfWork],
          )
          .map((scale, index) => {
            return (
              <ScaleTimeConfig
                key={index}
                dayOfWork={scale.dayOfWork}
                periods={scale.periods}
                onChangeScale={(changedScale) => {
                  const filteredScales = scales.filter(
                    (scale) => scale.dayOfWork !== changedScale.dayOfWork,
                  )

                  setValue('scales', [...filteredScales, changedScale], {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }}
              />
            )
          })}
      </section>
    </div>
  )
}
