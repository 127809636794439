import { useCallback, useContext, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'

import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'

import { CreateManualOccurrenceModal } from 'domains/occurrence/components/CreateOccurrenceModal/CreateManualOccurrenceModal'
import { EVENT_CODES_CAN_DISABLE_HABIT_DEVIATION } from '../Attendance/containers/Modals/components/FinishOccurrence/utils'
import { travelStates } from 'domains/occurrence/screens/OccurrenceManagement/data/occurrenceStates'
import { ChangeTactical } from 'domains/occurrence/components/ChangeTactical/ChangeTactical'
import { InterventionTag } from 'domains/occurrence/data/interventionTag'
import { Filters } from 'domains/occurrence/components/Filters/Filters'
import { Button, Input, Loader } from 'components'
import { History } from './components'

import { useGetAllOccurrencesByCoverage } from 'domains/occurrence/hooks/services/occurrence/useGetAllOccurrencesByCoverage'
import usePostAndHandleIntervention from 'domains/occurrence/hooks/services/occurrence/usePostAndHandleIntervention'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { useGetOccurrence } from 'shared/hooks/services/occurrence/useGetOccurrence'
import { getInterventionTitle } from 'domains/occurrence/utilities/intervention'
import { usePostAndHandleAttendance } from 'domains/occurrence/hooks'
import { UserInfoContext, useToast, useToggle, useUserInfo } from 'shared/hooks'
import { handleChangeState } from './components/History/utils'

import {
  OccurrenceFilters,
  occurrenceQuerySchema,
} from 'domains/occurrence/schemas/searchOccurrenceSchema'

import {
  useChangeState,
  useFinishOccurrence,
} from 'domains/occurrence/hooks/services/occurrence'

import {
  AttendanceCompletionModal,
  InfoModal,
  OccurrenceManagementColumn,
  InterventionJustification,
  ChangeState,
} from 'domains/occurrence/components'

import {
  InterventionAction,
  InterventionNoteSubmitData,
  OccurrenceActionLabel,
  OccurrenceManagementColumnProps,
} from 'domains/occurrence/types'

import {
  InterventionPayload,
  InterventionStateEnum,
  InterventionTypeEnum,
} from 'services/occurrence/types'

import {
  useDisableHabitDeviation,
  useGetOccurrenceHistory,
  useGetOccurrenceOnAttendance,
} from 'services/attendance'

const OccurrenceManagement: React.FC = () => {
  const [selectedOccurrenceCardId, setSelectedOccurrenceCardId] = useState('')
  const [interventionJustification, setInterventionJustification] =
    useState<InterventionStateEnum | null>(null)
  const [occurrenceId, setOccurrenceId] = useState('')

  const { getFilters, setFilters } =
    usePersistentFilters<OccurrenceFilters>('occurrence')

  const userContextInfo = useContext(UserInfoContext)

  const finishAttendanceModal = useToggle()
  const manualOccurrence = useToggle()
  const changeTactical = useToggle()
  const { addToast } = useToast()
  const filterProps = useToggle()
  const infoModal = useToggle()

  const [changeStateModal, setChangeState] = useState<
    'QUEUED' | 'FINISHED' | null
  >(null)

  const filters = getFilters()
  const navigate = useNavigate()

  const handleUpdateTactical = useCallback(
    (occurrenceId: string) => {
      setOccurrenceId(occurrenceId)
      changeTactical.show()
    },
    [changeTactical],
  )

  const handleFinalize = useCallback(
    (occurrenceId: string): void => {
      const currentOccurrence = travel.find(
        (occurrence) => occurrence.id === occurrenceId,
      )

      if (
        currentOccurrence &&
        ['TRAVEL_SENT', 'TRAVEL_VIEWED', 'TRAVEL_STARTED'].includes(
          currentOccurrence?.stateName,
        )
      ) {
        setChangeState('FINISHED')
      } else {
        finishAttendanceModal.show()
      }

      setOccurrenceId(occurrenceId)
    },
    [finishAttendanceModal],
  )

  const handleView = useCallback(
    (occurrenceId: string): void => {
      navigate(`/occurrence/attendance/${occurrenceId}`, {
        state: {
          readonly: true,
          from: 'management-occurrence',
        },
      })
    },
    [history],
  )

  const handleViewMap = useCallback(
    (occurrenceId: string) => {
      navigate('/occurrence/displacementMap', {
        state: {
          occurrenceId,
        },
      })
    },
    [history],
  )

  const form = useForm<OccurrenceFilters>({
    mode: 'onSubmit',
    resolver: joiResolver(occurrenceQuerySchema),
    defaultValues: {
      occurrenceType: filters?.occurrenceType || undefined,
      account: filters?.account || undefined,
      number: filters?.number || '',
    },
  })

  const { register, watch, setValue } = form

  const queryParams = {
    ...(filters?.account && { accountId: filters?.account?.id }),
    ...(filters?.number && { number: filters?.number }),
    ...(filters?.occurrenceType?.originName && {
      occurrenceTypes: [filters?.occurrenceType?.originName],
    }),
  }

  const { mutate: changeState } = useChangeState(occurrenceId, queryParams)
  const { data: occurrenceHistory } = useGetOccurrenceHistory(occurrenceId)

  const { data: occurrenceOnAttendence } =
    useGetOccurrenceOnAttendance(occurrenceId)

  const { mutate: finishOccurrence } = useFinishOccurrence(
    occurrenceId,
    queryParams,
  )

  const { data: attendance } = useGetOccurrence(
    selectedOccurrenceCardId,
    Boolean(selectedOccurrenceCardId),
  )

  const { userInfo } = useUserInfo()

  const { mutateCreateIntervention, statusCreateIntervention } =
    usePostAndHandleIntervention(occurrenceId)

  const { data: occurrence, isLoading: initialLoading } =
    useGetAllOccurrencesByCoverage(queryParams)

  const { mutate: mutateCreateAttendance, status: createAttendanceStatus } =
    usePostAndHandleAttendance()

  const { mutate: disableHabitDeviation, isPending: disabledHabitIsLoading } =
    useDisableHabitDeviation()

  const handleCreateIntervention = useCallback(
    (data: InterventionAction) => {
      const { interventionTag, ...rest } = data
      const tags = data.tags || []
      tags.push({
        name: data.interventionTag,
      })

      const intervention: InterventionPayload = {
        userId: userInfo.id,
        title: getInterventionTitle(data, interventionTag),
        tags,
        ...rest,
      }

      changeState(intervention)
    },
    [changeState],
  )

  const { available, inProgress, travel, queued } = useMemo(() => {
    if (!occurrence)
      return {
        available: [],
        inProgress: [],
        travel: [],
        queued: [],
      }
    return {
      available: occurrence.data.filter(
        (occurrence) => occurrence.stateName === 'AVAILABLE',
      ),
      inProgress: occurrence.data.filter(
        (occurrence) => occurrence.stateName === 'IN_PROGRESS',
      ),
      travel: occurrence.data.filter((occurrence) =>
        travelStates.includes(occurrence.stateName),
      ),
      queued: occurrence.data.filter(
        (occurrence) => occurrence.stateName === 'QUEUED',
      ),
    }
  }, [occurrence])

  const isUserInAttendance = useMemo(() => {
    if (!userContextInfo.userInfo) return false
    return inProgress.some(
      (occurrence) => occurrence.operator?.id === userContextInfo.userInfo?.id,
    )
  }, [inProgress, userContextInfo.userInfo])

  const isUserUnderAttendanceOccurrence = useCallback(
    (occurrenceId: string) => {
      if (!userContextInfo.userInfo) return false
      const occurrence = inProgress.find(
        (occurrence) => occurrence.id === occurrenceId,
      )
      if (occurrence?.operator?.id !== userContextInfo.userInfo?.id)
        return false
      return true
    },
    [inProgress, userContextInfo.userInfo],
  )

  const shouldEnableChangeTactical = useCallback(
    (occurrenceId: string) => {
      const occurrence = travel.find(
        (occurrence) => occurrence.id === occurrenceId,
      )

      if (!occurrence) return false

      const enableOptionStates = [
        'TRAVEL_SENT',
        'TRAVEL_VIEWED',
        'TRAVEL_STARTED',
      ]

      return enableOptionStates.includes(occurrence?.stateName)
    },
    [travel],
  )

  const shouldEnableAttendOccurrence = useCallback(
    (occurrenceId: string) =>
      !travel.find((travelOccurrence) => travelOccurrence.id === occurrenceId)
        ?.tactical?.agent,
    [travel],
  )

  const shouldEnableSetTactical = useCallback(
    (occurrenceId: string) => {
      const occurrence = travel.find(
        (occurrence) => occurrence.id === occurrenceId,
      )

      if (!occurrence) return false

      return occurrence.stateName === 'TRAVEL_WAITING'
    },
    [travel],
  )

  const handleAttend = useCallback(
    (occurrenceId: string) => {
      if (!userContextInfo.userInfo) return false
      if (!isUserInAttendance) {
        mutateCreateAttendance(
          {
            occurrenceId,
            userId: userContextInfo.userInfo?.id || '',
          },
          {
            onSuccess: (data) => {
              navigate(`/occurrence/attendance/${data.id}`, {
                state: {
                  shouldRedirectToOccurrenceManagement: true,
                },
              })
            },
            onError: () => {
              addToast({
                message: 'Erro ao criar atendimento.',
                error: true,
                type: 'alert',
              })
            },
          },
        )
      } else {
        infoModal.show()
      }
    },
    [
      addToast,
      history,
      infoModal,
      isUserInAttendance,
      mutateCreateAttendance,
      userContextInfo.userInfo,
    ],
  )

  const handleContinueAttendance = useCallback(
    (occurrenceId: string) => {
      navigate(`/occurrence/attendance/${occurrenceId}`)
    },
    [history],
  )

  const handleDisableAction = useCallback(
    (occurrenceId: string) => {
      if (occurrence) {
        const occurrenceSelected = occurrence.data?.find(
          (occurrence) => occurrence.id === occurrenceId,
        )

        if (!occurrenceSelected) return true

        return !occurrenceSelected.parameters?.vehicleTravelEnabled
      }
    },
    [occurrence],
  )

  const columns: OccurrenceManagementColumnProps[] = useMemo(() => {
    return [
      {
        title: 'Disponível',
        actions: [
          {
            label: OccurrenceActionLabel.ATENDER,
            handler: (occurrenceId) => handleAttend(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.VISUALIZAR_OCORRENCIA,
            handler: (occurrenceId) => handleView(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.ENVIAR_PARA_DESLOCAMENTO,
            handler: (occurrenceId) => {
              setOccurrenceId(occurrenceId)
              setInterventionJustification(InterventionStateEnum.TRAVEL_WAITING)
            },
            isDisabled: (occurrenceId) => handleDisableAction(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.COLOCAR_EM_OBSERVACAO,
            handler: (occurrenceId) => {
              setOccurrenceId(occurrenceId)
              setInterventionJustification(InterventionStateEnum.QUEUED)
            },
          },
          {
            label: OccurrenceActionLabel.FINALIZAR,
            handler: (occurrenceId) => handleFinalize(occurrenceId),
          },
        ],
        occurrences: available,
      },
      {
        title: 'Em atendimento',
        actions: [
          {
            label: OccurrenceActionLabel.VISUALIZAR_OCORRENCIA,
            handler: (occurrenceId) => handleView(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.ENVIAR_PARA_DESLOCAMENTO,
            handler: (occurrenceId) => {
              setOccurrenceId(occurrenceId)
              setInterventionJustification(InterventionStateEnum.TRAVEL_WAITING)
            },
            isDisabled: (occurrenceId) => handleDisableAction(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.COLOCAR_EM_OBSERVACAO,
            handler: (occurrenceId) => {
              setOccurrenceId(occurrenceId)
              setInterventionJustification(InterventionStateEnum.QUEUED)
            },
          },
          {
            label: OccurrenceActionLabel.ATENDER,
            handler: (occurrenceId) => handleContinueAttendance(occurrenceId),
            isVisible: (occurrenceId) =>
              isUserUnderAttendanceOccurrence(occurrenceId),
          },
        ],
        occurrences: inProgress,
      },
      {
        title: 'Deslocamento',
        actions: [
          {
            label: OccurrenceActionLabel.ATENDER,
            handler: (occurrenceId) => handleAttend(occurrenceId),
            isVisible: (occurrenceId) =>
              shouldEnableAttendOccurrence(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.VISUALIZAR_OCORRENCIA,
            handler: (occurrenceId) => handleView(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.VISUALIZAR_NO_MAPA,
            handler: (occurrenceId) => handleViewMap(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.COLOCAR_EM_OBSERVACAO,
            handler: (occurrenceId) => {
              setOccurrenceId(occurrenceId)

              const currentOccurrence = travel.find(
                (occurrence) => occurrence.id === occurrenceId,
              )

              if (
                currentOccurrence &&
                ['TRAVEL_SENT', 'TRAVEL_VIEWED', 'TRAVEL_STARTED'].includes(
                  currentOccurrence?.stateName,
                )
              ) {
                setChangeState('QUEUED')
              } else {
                setInterventionJustification(InterventionStateEnum.QUEUED)
              }
            },
          },
          {
            label: OccurrenceActionLabel.FINALIZAR,
            handler: (occurrenceId) => handleFinalize(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.ALTERAR_TATICO,
            handler: (occurrenceId) => handleUpdateTactical(occurrenceId),
            isVisible: (occurrenceId) =>
              shouldEnableChangeTactical(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.ATRIBUIR_TATICO,
            handler: (occurrenceId) => handleUpdateTactical(occurrenceId),
            isVisible: (occurrenceId) => shouldEnableSetTactical(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.DEFINIR_COMO,
            isVisible: (occurrenceId) =>
              shouldEnableChangeTactical(occurrenceId),
            subActions: [
              {
                label: OccurrenceActionLabel.SOLICITACAO_VISUALIZADA,
                handler: (occurrenceId) => {
                  setOccurrenceId(occurrenceId)
                  setInterventionJustification(
                    InterventionStateEnum.TRAVEL_VIEWED,
                  )
                },
              },
              {
                label: OccurrenceActionLabel.TRANSITO_INICIADO,
                handler: (occurrenceId) => {
                  setOccurrenceId(occurrenceId)
                  setInterventionJustification(
                    InterventionStateEnum.TRAVEL_STARTED,
                  )
                },
              },
              {
                label: OccurrenceActionLabel.CHEGADA_NO_LOCAL,
                handler: (occurrenceId) => {
                  setOccurrenceId(occurrenceId)
                  setInterventionJustification(
                    InterventionStateEnum.TRAVEL_FINISHED,
                  )
                },
              },
            ],
          },
        ],
        occurrences: travel,
      },
      {
        title: 'Em observação',
        actions: [
          {
            label: OccurrenceActionLabel.ATENDER,
            handler: (occurrenceId) => handleAttend(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.VISUALIZAR_OCORRENCIA,
            handler: (occurrenceId) => handleView(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.TORNAR_DISPONIVEL,
            handler: (occurrenceId) => {
              setOccurrenceId(occurrenceId)
              setInterventionJustification(InterventionStateEnum.AVAILABLE)
            },
          },
          {
            label: OccurrenceActionLabel.ENVIAR_PARA_DESLOCAMENTO,
            handler: (occurrenceId) => {
              setOccurrenceId(occurrenceId)
              setInterventionJustification(InterventionStateEnum.TRAVEL_WAITING)
            },
            isDisabled: (occurrenceId) => handleDisableAction(occurrenceId),
          },
          {
            label: OccurrenceActionLabel.FINALIZAR,
            handler: (occurrenceId) => handleFinalize(occurrenceId),
          },
        ],
        occurrences: queued,
      },
    ]
  }, [
    available,
    handleAttend,
    handleContinueAttendance,
    handleFinalize,
    handleView,
    handleViewMap,
    setInterventionJustification,
    shouldEnableChangeTactical,
    shouldEnableSetTactical,
    shouldEnableAttendOccurrence,
    handleUpdateTactical,
    inProgress,
    isUserUnderAttendanceOccurrence,
    queued,
    travel,
    handleDisableAction,
  ])

  const canDisableHabitDeviation = useMemo(() => {
    return occurrenceHistory?.events?.some((event) =>
      EVENT_CODES_CAN_DISABLE_HABIT_DEVIATION.includes(event.details.code),
    )
  }, [history]) // eslint-disable-line

  const handleFinishOccurrence = useCallback(
    (data: InterventionNoteSubmitData) => {
      finishAttendanceModal.hide()

      const dataFormatted = {
        typeName: InterventionTypeEnum.STATE_CHANGED,
        attributes: {
          stateName: InterventionStateEnum.FINISHED,
        },
        interventionTag: InterventionTag.FINALIZADA,
        note: data.note,
      }

      const { interventionTag, ...rest } = dataFormatted

      const tags = data.interventionNoteTags || []
      tags.push({
        name: data.interventionTag,
      })

      const intervention: InterventionPayload = {
        userId: userInfo.id,
        title: getInterventionTitle(dataFormatted, interventionTag),
        tags: tags.filter((tags) => tags.name),
        ...rest,
      }

      finishOccurrence(intervention)
    },
    [finishAttendanceModal, finishOccurrence],
  )

  const occurrenceWithAggregatedInfos = travel.find(
    (occurrence) => occurrence.id === occurrenceId,
  )

  const occurrenceInfoForHistory = occurrence?.data.find(
    (occurrence) => occurrence.id === selectedOccurrenceCardId,
  )

  const onSubmitFinishOccurrence = useCallback(
    async (data: InterventionNoteSubmitData) => {
      if (data.postponeUntil) {
        const partitions = occurrenceOnAttendence?.partitions.filter(
          (partition) => partition.isInOccurrence,
        )

        if (partitions) {
          const date = new Date(data.date as number)
          const [hour, minutes] =
            (data.hour && data.hour.split(':').map(Number)) || []

          date.setHours(hour)
          date.setMinutes(minutes)

          await Promise.all(
            partitions?.map((partition) => {
              disableHabitDeviation({
                partitionId: partition.id,
                scheduledDate: date.getTime(),
              })
            }),
          )
            .then(() => handleFinishOccurrence(data))
            .catch(() => {
              addToast({
                message: 'Erro ao adiar atendimento',
                type: 'alert',
              })
            })
        }
      } else
        handleFinishOccurrence({
          note: data.note,
          interventionNoteTags: data.interventionNoteTags,
          interventionTag: data.interventionTag,
        })
    },
    [
      addToast,
      disableHabitDeviation,
      handleFinishOccurrence,
      occurrenceOnAttendence,
    ],
  )

  if (
    !occurrence ||
    statusCreateIntervention === 'pending' ||
    initialLoading ||
    createAttendanceStatus === 'pending'
  )
    return <Loader isVisible />

  return (
    <>
      <InfoModal
        isVisible={infoModal.isVisible}
        onClose={infoModal.hide}
        title="Ocorrência em atendimento"
        text="Você está atualmente atendendo a uma ocorrência. Antes de prosseguir com outra, por favor, conclua a atual."
      />

      <InterventionJustification
        isVisible={!!interventionJustification}
        onClose={() => setInterventionJustification(null)}
        onContinue={(note) => {
          if (interventionJustification) {
            const payload = handleChangeState(note, interventionJustification, {
              ...attendance,
              operator: occurrenceWithAggregatedInfos?.operator,
              tactical: occurrenceWithAggregatedInfos?.tactical,
              partitions: occurrenceOnAttendence?.partitions,
              coordinates:
                occurrenceWithAggregatedInfos?.patrimony?.coordinates,
            })

            handleCreateIntervention(payload)
            setInterventionJustification(null)
          }
        }}
      />
      <ChangeState
        isVisible={!!changeStateModal}
        onClose={() => setChangeState(null)}
        onContinue={() => {
          if (changeStateModal === 'FINISHED') {
            finishAttendanceModal.show()
          } else {
            setInterventionJustification(InterventionStateEnum.QUEUED)
          }

          setChangeState(null)
        }}
      />
      {changeTactical.isVisible && (
        <ChangeTactical
          title={
            shouldEnableSetTactical(selectedOccurrenceCardId)
              ? 'Atribuir agente tático'
              : 'Alterar agente tático'
          }
          isVisible
          onClose={changeTactical.hide}
          occurrenceList={occurrence.data}
          onContinue={(data) => {
            changeTactical.hide()
            mutateCreateIntervention(
              {
                title: `Solicitação enviada ao agente tático ${data.tactical.name}`,
                typeName: 'STATE_CHANGE',
                note: data.comment,
                userId: userInfo.id,
                tags: [
                  {
                    name: 'deslocamentoEnviado',
                  },
                ],
                attributes: {
                  stateName: 'TRAVEL_SENT',
                  agent: {
                    id: data.tactical.id,
                    name: data.tactical.name,
                  },
                  vehicle: {
                    id: data.tactical.vehicleId,
                    licensePlate: data.tactical.licensePlate,
                  },
                  agentCoordinates: data.tactical.coordinates,
                },
              },
              {
                onError: () => {
                  addToast({
                    message: 'Erro ao realizar intervenção',
                    type: 'alert',
                  })
                },
              },
            )
          }}
        />
      )}
      <AttendanceCompletionModal
        isVisible={finishAttendanceModal.isVisible}
        onClose={finishAttendanceModal.hide}
        onSave={onSubmitFinishOccurrence}
        isDisabledSave={disabledHabitIsLoading}
        canDisableHabitDeviation={canDisableHabitDeviation ?? false}
        occurrenceTypeId={
          occurrence.data.find((occurrence) => occurrence.id === occurrenceId)
            ?.type?.id
        }
        serviceTypeId={
          occurrence.data.find((occurrence) => occurrence.id === occurrenceId)
            ?.patrimony?.serviceType?.id
        }
      />
      {manualOccurrence.isVisible && (
        <CreateManualOccurrenceModal
          isVisible
          onClose={manualOccurrence.hide}
        />
      )}

      <FormProvider {...form}>
        <div
          className={[
            styles.container,
            filterProps.isVisible && styles.noClickable,
          ].join(' ')}
        >
          <span className={styles.title}>Gestão de ocorrências</span>
          <div className={styles.divider} />
          <div className={styles.contentContainer}>
            <section className={styles.leftSection}>
              <form
                onSubmit={(event) => {
                  event.preventDefault()
                  setFilters(watch())
                  filterProps.isVisible && filterProps.hide()
                  selectedOccurrenceCardId && setSelectedOccurrenceCardId('')
                }}
              >
                <div className={styles.filterWrapper}>
                  <Input
                    id="number"
                    placeholder="Digite o número da ocorrência"
                    label="Ocorrência"
                    autoComplete="off"
                    {...register('number')}
                    onChange={(event) =>
                      setValue(
                        'number',
                        event.target.value?.replace(/\D/g, ''),
                        {
                          shouldValidate: true,
                        },
                      )
                    }
                    disabled={filterProps.isVisible}
                  />

                  <div className={styles.buttonWrapper}>
                    <Button
                      buttonTitle="Pesquisar"
                      type="primary"
                      htmlType="submit"
                      disabled={filterProps.isVisible}
                    />
                    <Button
                      buttonTitle="Nova ocorrência"
                      type="secondary"
                      icon={PlusIcon}
                      width="174px"
                      onClick={() => {
                        manualOccurrence.show()
                      }}
                    />
                  </div>
                  <Button
                    buttonTitle="Adicionar filtros"
                    type="tertiary"
                    icon={FiltersIcon}
                    onClick={filterProps.show}
                  />
                </div>
              </form>
              <div className={[styles.columnsWrapper].join(' ')}>
                {columns.map(({ title, actions, occurrences }) => (
                  <OccurrenceManagementColumn
                    key={title}
                    title={title}
                    actions={actions}
                    occurrences={occurrences}
                    selectedOccurrenceId={selectedOccurrenceCardId}
                    onSelected={(id) => {
                      if (id === selectedOccurrenceCardId)
                        return setSelectedOccurrenceCardId('')
                      setSelectedOccurrenceCardId(id)
                    }}
                  />
                ))}
              </div>
            </section>
            {!!selectedOccurrenceCardId && (
              <History
                isVisible
                isUserInAttendance={isUserInAttendance}
                canDisableHabitDeviation={canDisableHabitDeviation || false}
                occurrence={{
                  ...attendance,
                  operator: occurrenceInfoForHistory?.operator,
                  tactical: occurrenceInfoForHistory?.tactical,
                  partitions: occurrenceOnAttendence?.partitions,
                  coordinates: occurrenceInfoForHistory?.patrimony?.coordinates,
                }}
                onClose={() => setSelectedOccurrenceCardId('')}
              />
            )}
          </div>
        </div>
        {filterProps.isVisible && (
          <Filters
            isVisible
            onClose={filterProps.hide}
            onReset={() => {
              setFilters({})
            }}
            onSubmit={() => {
              setFilters(watch())
              filterProps.isVisible && filterProps.hide()
              selectedOccurrenceCardId && setSelectedOccurrenceCardId('')
            }}
          />
        )}
      </FormProvider>
    </>
  )
}

export default OccurrenceManagement
