import { ChangeEvent } from 'react'

import './checkbox.styles.scss'

export interface CheckboxProps {
  id: string
  label: string
  checked: boolean
  small?: boolean
  disabled?: boolean
  onChange(e: ChangeEvent<HTMLInputElement>): void
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    id,
    label,
    checked,
    onChange,
    disabled = false,
    small = false,
  } = props

  return (
    <label className="checkbox-wrapper">
      <input
        hidden
        id={`checkbox-input-${id}`}
        data-testid="checkbox-input"
        type="checkbox"
        onChange={(e) => onChange(e)}
        disabled={disabled}
        checked={checked}
        value={checked ? '' : id}
      />
      <div
        className={[
          'checkbox-check-wrapper',
          checked ? '--checked' : '',
          small ? '--small' : '',
          disabled ? '--disabled-checkbox' : '',
        ].join(' ')}
      >
        <svg
          className="checkbox-check"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 9.889 7.064"
        >
          <path
            d="M165.532 40.064a.7.7 0 0 1-.5-.207l-2.825-2.825a.706.706 0 0 1 1-1l2.326 2.326 5.152-5.152a.706.706 0 0 1 1 1l-5.651 5.651a.7.7 0 0 1-.502.207z"
            transform="translate(-162 -33)"
          />
        </svg>
      </div>
      <p className="checkbox-label">{label}</p>
    </label>
  )
}

export default Checkbox
