import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'

import {
  ServiceOrderAggregatedQueryResponse,
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_TYPE_ENUM,
  SERVICE_ORDER_TYPE_EN_PT,
  useGetServiceOrderHistory,
  ServiceOrderDriver,
} from 'services/serviceOrder'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'

import { CancelServiceOrder } from '../../../components/CancelServiceOrder/CancelServiceOrder'
import {
  Loader,
  Input,
  Button,
  ServiceOrderFilters,
  Popover,
  TableV2,
  Icon,
  PaginationV2 as Pagination,
  Droplist,
  LoaderV2,
  EmptyState,
} from 'components'

import { usePutServiceOrder } from 'shared/hooks/serviceOrder/usePutServiceOrder'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useToast, useToggle, useUserInfo } from 'shared/hooks'

import {
  useOSFilter,
  handleStatusLabel,
  isServiceOrderCancelable,
  isServiceOrderSchedulable,
  isServiceOrderVisible,
  isServiceOrderReschedulable,
} from 'domains/serviceOrders/utilities'

import styles from './SearchServiceOrders.module.scss'

import { ScheduleServiceOrderModal } from '../../../components/ScheduleServiceOrderModal'

import {
  filterSchema,
  ServiceOrderFiltersSchema,
} from 'components/ServiceOrderFilters/schemas/searchServiceOrderSchema'

import { scheduleServiceOrderSchema } from 'domains/serviceOrders/schemas/scheduleServiceOrderSchema'
import {
  ServiceOrderPayload,
  ServiceOrderSchedule,
  ServiceOrderStatusArray,
  ServiceOrderTypeArray,
} from 'domains/serviceOrders/types'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { RichTooltip } from '../../components'
import { UbideskPermissions } from 'routes/types'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { maskedDateTime } from 'utilities/date'

const emptyServiceOrder: ServiceOrderAggregatedQueryResponse = {
  id: '',
  number: 0,
  account: { id: '', code: '' },
  customer: { id: '', name: '' },
  technician: { id: undefined, name: '' },
  contact: { id: '', name: '', countryCode: 0, provinceCode: 0, number: 0 },
  patrimony: { id: '', name: '' },
  status: SERVICE_ORDER_STATUS_ENUM.OPEN,
  scheduleDate: 0,
  schedulePeriod: 'AFTERNOON',
  type: SERVICE_ORDER_TYPE_ENUM.INSTALL,
  createdAt: 0,
  updatedAt: 0,
  labels: [],
}

const columnHelper = createColumnHelper<ServiceOrderAggregatedQueryResponse>()

export const SearchServiceOrders = () => {
  const [selectedServiceOrder, setSelectedServiceOrder] =
    useState<ServiceOrderAggregatedQueryResponse>(emptyServiceOrder)
  const [serviceOrderSchedule, setServiceOrderSchedule] =
    useState<ServiceOrderSchedule>()

  const filterComponent = useToggle()
  const cancelServiceOrderComponent = useToggle()
  const scheduleServiceOrderComponent = useToggle()
  const navigate = useNavigate()
  const { userInfo } = useUserInfo()

  const { filters, handleResetFilter } = useOSFilter()

  const [searchParams, setSearchParams] = useSearchParams()

  const form = useForm<ServiceOrderFiltersSchema>({
    resolver: joiResolver(filterSchema),
    mode: 'onChange',
    defaultValues: { applyAttendanceExclusivity: true, ...filters },
  })
  const { getValues, register, reset, watch } = form

  const handleResetPagination = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '1',
    })
  }

  const [serviceOrderId, setServiceOrderId] = useState('')

  useEffect(() => {
    register('customer')
    register('account')
    register('patrimony')
    register('technicians')
    register('type')
    register('status')
    register('tag')
    register('createdAtFrom')
    register('createdAtTo')
    register('scheduleDateFrom')
    register('scheduleDateTo')
    register('closedAtFrom')
    register('closedAtTo')
  }, [register])

  const reScheduleOSForm = useForm<ServiceOrderPayload>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver: joiResolver(scheduleServiceOrderSchema),
  })

  const { control } = reScheduleOSForm

  const { addToast } = useToast()

  const { mutatePutServiceOrder, updateServiceOrderStatus } =
    usePutServiceOrder()

  const handleScheduleServiceOrder = (data: ServiceOrderPayload) => {
    const {
      id,
      status,
      note,
      technician,
      contact,
      scheduleDate,
      schedulePeriod,
      tags,
      account,
      type,
    } = data

    const userId = userInfo?.id

    mutatePutServiceOrder(
      {
        id,
        status,
        note,
        technicianId: technician?.id,
        userId,
        contact,
        scheduleDate,
        accountId: account?.id || '',
        serviceOrderType: type,
        ...(schedulePeriod && { schedulePeriod }),
        ...(tags?.length && {
          tags: tags.map((tag) => ({ name: tag.name })),
        }),
      },
      {
        onSuccess: () => {
          reScheduleOSForm.reset()
          fetchServiceOrders()
          addToast({
            message: 'Ordem de serviço reagendada com sucesso.',
          })
        },
        onError: () => {
          addToast({
            message:
              'Não foi possível reagendar a ordem de serviço. Tente novamente.',
            type: 'alert',
          })
        },
      },
    )
  }

  const { fields: typeFields } = useFieldArray<ServiceOrderTypeArray>({
    control,
    name: 'serviceOrderType',
  })

  const { fields: statusFields } = useFieldArray<ServiceOrderStatusArray>({
    control,
    name: 'serviceOrderStatus',
  })

  const validateFilters = () => {
    let filterSum = 0
    const excludedKeys = [
      'number',
      'createdAtTo',
      'scheduleDateTo',
      'closedAtTo',
    ]
    const formKeys = Object.keys(getValues())
    formKeys
      .filter((key) => !excludedKeys.includes(key))
      .forEach((key) => {
        const formValue = getValues(key)
        if (Array.isArray(formValue)) {
          filterSum += formValue.length
        } else if (formValue) {
          filterSum++
        }
      })
    if (typeFields) {
      filterSum += typeFields.length
    }

    if (statusFields) {
      filterSum += statusFields.length
    }

    if (!filterSum) return true
    if (createdAtFrom && !createdAtTo) return true
    if (scheduleDateFrom && !scheduleDateTo) return true
    if (closedAtFrom && !closedAtTo) return true

    return false
  }

  const { data: serviceOrderHistory } =
    useGetServiceOrderHistory(serviceOrderId)

  const hasInProgress = serviceOrderHistory?.some(
    (historyEntry) =>
      historyEntry.status === SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
  )

  const {
    number,
    customer,
    patrimony,
    account,
    type: serviceOrderTypes,
    technicians,
    status,
    createdAtFrom,
    createdAtTo,
    scheduleDateFrom,
    scheduleDateTo,
    closedAtFrom,
    closedAtTo,
    tag,
    applyAttendanceExclusivity,
  } = watch()

  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState({ data: [], totalElements: 0 })

  const fetchServiceOrders = useCallback(async () => {
    const number = getValues('number')

    if (number) {
      reset({
        number: watch('number'),
        applyAttendanceExclusivity: watch('applyAttendanceExclusivity'),
      })
    }

    const origin = watch('origin')
    const reason = watch('reason')
    const pauseReason = watch('pauseReason')
    const issue = watch('issue')
    const resolution = watch('resolution')

    const labels = [origin, reason, pauseReason, issue, resolution].reduce(
      (acc, array) => [...acc, ...(array || [])],
      [],
    )

    setIsFetching(true)

    const page = Number(searchParams.get('page')) || 1
    const offset = (page - 1) * 15

    try {
      const serviceOrders = await ServiceOrderDriver.queryPost({
        offset,
        recordsPerPage: 15,
        ...(number && { number }),
        ...(account && { accountIds: [account.id] }),
        ...(technicians && {
          technicianIds: technicians.map((technician) => technician.id),
        }),
        ...(createdAtFrom && { createdAtFrom }),
        ...(createdAtTo && { createdAtTo }),
        ...(scheduleDateFrom && { scheduleDateFrom }),
        ...(scheduleDateTo && {
          scheduleDateTo: new Date(
            new Date(scheduleDateTo).setHours(23),
          ).setMinutes(59),
        }),
        ...(closedAtFrom && { closedAtFrom }),
        ...(closedAtTo && { closedAtTo }),
        ...(status && { status }),
        ...(tag && { tagId: tag.id }),
        ...(serviceOrderTypes && { serviceOrderTypes }),
        ...(labels?.length && { labelIds: labels.map((label) => label.id) }),
        ...(patrimony && !account ? { accountIds: [patrimony.accountId] } : {}),
        ...(customer && !account && !patrimony
          ? {
              accountIds: customer.accounts.length
                ? customer.accounts.map((account) => account.id)
                : [customer.id],
            }
          : {}),
        hasTechnician: watch('hasTechnician'),
        ...(!!watch('serviceType') && {
          serviceTypeId: watch('serviceType').id,
        }),
        ...(!!watch('accountTag') && {
          accountTagId: watch('accountTag').id,
        }),
      })

      setData(serviceOrders)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [
    getValues,
    reset,
    watch,
    account,
    technicians,
    createdAtFrom,
    createdAtTo,
    scheduleDateFrom,
    scheduleDateTo,
    closedAtFrom,
    closedAtTo,
    status,
    serviceOrderTypes,
    patrimony,
    searchParams,
    customer,
    tag,
  ])

  const page = searchParams.get('page')
  const filter = searchParams.get('filter')

  useEffect(() => {
    if (page || filter) {
      fetchServiceOrders()
    }
  }, [page, filter])

  const actions = useMemo(
    () => [
      {
        label: 'Visualizar',
        Icon: 'view',
        handler: (serviceOrder) => {
          setServiceOrderId(serviceOrder.id)
          navigate(`/so/info/${serviceOrder.id}`)
        },
      },
      {
        label: 'VisualizarV2',
        Icon: 'view',
        handler: (serviceOrder) => {
          setServiceOrderId(serviceOrder.id)
          navigate(`/soV2/${serviceOrder.id}`)
        },
      },
      {
        label: 'Editar',
        Icon: 'edit',
        handler: (item) => {
          navigate(`/so/edit/${item.id}`)
        },
        isVisible: (item) => isServiceOrderVisible(item.status),
      },
      {
        label: 'EditarV2',
        Icon: 'edit',
        handler: (item) => {
          navigate(`/sov2/update/${item.id}`)
        },
        isVisible: (item) => isServiceOrderVisible(item.status),
      },
      {
        label: 'Agendar',
        Icon: 'calendar',
        handler: (serviceOrder) => {
          setServiceOrderSchedule(serviceOrder)
          scheduleServiceOrderComponent.show()
        },
        isVisible: (item) => isServiceOrderSchedulable(item.status),
      },
      {
        label: 'Reagendar',
        Icon: 'calendar',
        handler: (serviceOrder) => {
          setServiceOrderSchedule(serviceOrder)
          scheduleServiceOrderComponent.show()
        },
        isVisible: (item) => isServiceOrderReschedulable(item.status),
      },
      {
        label: 'Cancelar',
        Icon: 'close-xlg',
        handler: (item) => {
          setSelectedServiceOrder(item)
          cancelServiceOrderComponent.show()
        },
        isVisible: (item) => isServiceOrderCancelable(item.status),
        permission: {
          name: hasInProgress
            ? UbideskPermissions.CANCEL_EXECUTED_SO
            : undefined,
          message: 'Não é possível cancelar esta OS, pois já possui execuções.',
        },
      },
    ],
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'id',
        header: 'Número',
        size: 115,
        cell: (info) => (
          <div className={styles.statusBox}>
            <span>{info.getValue().number}</span>
            <Popover.Root>
              <Popover.Content>
                <RichTooltip serviceOrderId={info.getValue().id} />
              </Popover.Content>
              <Popover.Trigger className={styles[info.getValue().status]}>
                {handleStatusLabel(info.getValue().status)}
                <InfoIcon />
              </Popover.Trigger>
            </Popover.Root>
          </div>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: 'account',
        header: 'Conta',
        cell: (info) =>
          `${info.getValue().account?.code} - ${info.getValue().customer?.name}`,
      }),
      columnHelper.accessor('labels', {
        header: 'Motivo',
        cell: (info) => {
          const value = info.getValue()

          const reasonLabel = value?.find((label) => label.type === 'REASON')

          return reasonLabel?.description || ''
        },
      }),
      columnHelper.accessor('type', {
        size: 68,
        header: 'Tipo',
        cell: (info) => (
          <>
            {
              SERVICE_ORDER_TYPE_EN_PT[
                info.getValue() as SERVICE_ORDER_TYPE_ENUM
              ]
            }
          </>
        ),
      }),
      columnHelper.accessor('technician', {
        header: 'Técnico',
        cell: (info) => info.getValue()?.name,
      }),
      columnHelper.accessor('createdAt', {
        size: 73,
        header: 'Abertura',
        cell: (info) => <>{maskedDateTime(info.getValue())}</>,
      }),
      columnHelper.accessor((row) => row, {
        id: 'action',
        header: '',
        size: 42,
        cell: (info) => {
          const showDropList = actions.some((action) =>
            action.isVisible?.(info.getValue()),
          )

          const value = info.getValue()
          return showDropList ? (
            <Droplist
              trigger={<Icon name="menu-kebab" style={{ cursor: 'pointer' }} />}
            >
              <ul className={styles.dropListItem}>
                {actions.map((action) => {
                  const shouldShow = action.isVisible?.(value) ?? true

                  return (
                    shouldShow && (
                      <li
                        key={action.label}
                        onClick={() => action.handler(value)}
                      >
                        <Icon
                          name={action.Icon}
                          style={{ cursor: 'pointer' }}
                        />
                        {action.label}
                      </li>
                    )
                  )
                })}
              </ul>
            </Droplist>
          ) : (
            <Droplist
              trigger={<Icon name="menu-kebab" style={{ cursor: 'pointer' }} />}
            >
              <ul className={styles.dropListItem}>
                {actions.slice(0, 2).map((action) => {
                  return (
                    <li
                      key={action.label}
                      onClick={() => action.handler(value)}
                    >
                      <Icon name={action.Icon} style={{ cursor: 'pointer' }} />
                      {action.label}
                    </li>
                  )
                })}
              </ul>
            </Droplist>
          )
        },
      }),
    ],
    [columnHelper, actions],
  )

  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }

    if (isFetching) {
      return 'loading'
    }

    if (isError) {
      return 'error'
    }

    return 'empty'
  }

  return (
    <>
      <Loader isVisible={updateServiceOrderStatus === 'pending'} />

      {scheduleServiceOrderComponent.isVisible && (
        <ScheduleServiceOrderModal
          isVisible={scheduleServiceOrderComponent.isVisible}
          onClose={() => {
            scheduleServiceOrderComponent.hide()
          }}
          serviceOrder={serviceOrderSchedule}
          onSave={(data) => {
            handleScheduleServiceOrder(data)
            scheduleServiceOrderComponent.hide()
          }}
        />
      )}

      <FormProvider {...form}>
        <CancelServiceOrder
          isVisible={cancelServiceOrderComponent.isVisible}
          onClose={() => {
            setSelectedServiceOrder(emptyServiceOrder)
            cancelServiceOrderComponent.hide()
          }}
          serviceOrder={{
            id: selectedServiceOrder?.id || '',
            status: selectedServiceOrder?.status,
            number: selectedServiceOrder?.number || 0,
            account: selectedServiceOrder?.account,
            inProgressAt: selectedServiceOrder?.inProgressAt,
            technicianId: selectedServiceOrder?.technician?.id,
          }}
        />

        <div
          className={[
            styles.container,
            filterComponent.isVisible && styles.noClickable,
          ].join(' ')}
        >
          <span className={styles.title}>Ordens de serviço</span>
          <div className={styles.divider} />
          <form
            className={styles.inputsWrapper}
            onSubmit={(event) => {
              event.preventDefault()
              handleResetPagination()

              handleResetFilter()
              reset({
                number,
                applyAttendanceExclusivity,
              })
              fetchServiceOrders()
            }}
          >
            <div className={styles.innerWrapper}>
              <div className={styles.filtersButtons}>
                <Input
                  id="number"
                  placeholder="Digite o número identificador da OS"
                  label="Número da OS"
                  autoComplete="off"
                  type="number"
                  {...register('number')}
                  disabled={filterComponent.isVisible}
                />
                <Button
                  buttonTitle="Pesquisar"
                  type="primary"
                  disabled={filterComponent.isVisible}
                  htmlType="submit"
                />
                <Button
                  buttonTitle={
                    Object.values(watch())
                      .slice(2, 7)
                      .some((value) => value)
                      ? 'Editar filtros'
                      : 'Adicionar filtros'
                  }
                  type="tertiary"
                  icon={FiltersIcon}
                  onClick={filterComponent.show}
                />
              </div>
            </div>
          </form>

          {
            {
              view: (
                <TableV2.Root>
                  <TableV2.Header>
                    {table?.getHeaderGroups().map((headerGroup) => (
                      <TableV2.Row key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <TableV2.ResizableColumn
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: header.column.getSize() }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </TableV2.ResizableColumn>
                        ))}
                      </TableV2.Row>
                    ))}
                  </TableV2.Header>
                  <TableV2.Body>
                    {table?.getRowModel().rows.map((row) => (
                      <Fragment key={row.id}>
                        <TableV2.Row>
                          {row.getVisibleCells().map((cell) => (
                            <TableV2.Cell
                              key={cell.id}
                              className={styles[cell.column.id]}
                              style={{ width: cell.column.getSize() }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </TableV2.Cell>
                          ))}
                        </TableV2.Row>
                      </Fragment>
                    ))}
                  </TableV2.Body>
                </TableV2.Root>
              ),
              empty: <EmptyState type="EmptyDataFromBFF" />,
              error: <EmptyState type="ErrorToLoadInfos" />,
              loading: (
                <div className={styles.loader}>
                  <LoaderV2 />
                </div>
              ),
            }[handleRender(data?.data, isError, isFetching)]
          }
          <Pagination
            className={styles.pagination}
            totalElements={data?.totalElements}
          />
          <ServiceOrderFilters
            isVisible={filterComponent.isVisible}
            onClose={filterComponent.hide}
            onApplyFilters={() => {
              if (number) {
                form.setValue('number', undefined)
              }

              filterComponent.hide()
              handleResetPagination()
            }}
            onValidateFilters={validateFilters}
          />
        </div>
      </FormProvider>
    </>
  )
}
