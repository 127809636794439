import { useLocation, useParams } from 'react-router-dom'

import {
  useGetOccurrenceHistory,
  useGetOccurrenceOnAttendance,
  usePostIntervention,
} from 'services/attendance'
import { RouteParams } from 'domains/occurrence/screens/Attendance/types'
import { useToast, useUserInfo } from 'shared/hooks'
import { dateNowWithSeconds } from 'utilities/date'
import { ProtectedImage } from 'components'
import { HistoryEvent } from 'services/attendance/types'

import styles from './styles.module.scss'
import { colors, occurrenceTypesLabel } from './utils'
import { FC } from 'react'

interface EventCardProps {
  event: HistoryEvent
  onOpenGallery: (imageId: string) => void
}

const EventCard: FC<EventCardProps> = ({ event, onOpenGallery }) => {
  const location = useLocation()
  const { userInfo } = useUserInfo()
  const { occurrenceId } = useParams<RouteParams>()

  const { addToast } = useToast()
  const { mutate, isPending } = usePostIntervention(occurrenceId)
  const { data } = useGetOccurrenceHistory(occurrenceId)
  const { data: attendance } = useGetOccurrenceOnAttendance(occurrenceId)

  const readOnly = !!location.state?.readonly
  const canEdit = !readOnly && attendance?.occurrence.stateName !== 'FINISHED'

  const handleIntervention = () => {
    const userId = userInfo.id

    if (userId && event.imageId) {
      mutate(
        {
          typeName: 'IMAGE_VERIFICATION',
          userId,
          title: 'Verificação de imagem',
          attributes: {
            status: 'PENDING',
            imageId: event.imageId,
          },
          tags: [{ name: 'verificacaoImagem' }],
        },
        {
          onSuccess: () => {
            addToast({
              message: 'Intervenção realizada com sucesso',
              type: 'success',
            })
          },
          onError: () => {
            addToast({
              message: 'Erro ao realizar intervenção na ocorrência',
              type: 'alert',
            })
          },
        },
      )
    }
  }

  const hasVerificationIntervention = data?.interventions.some(
    (intervention) => intervention.attributes?.imageId === event.imageId,
  )

  return (
    <li className={styles.container}>
      <div
        className={styles.eventBorder}
        style={{
          backgroundColor: colors[event.occurrenceType],
        }}
      />
      <div className={styles.eventContent}>
        <div>
          <div className={styles.title}>
            <span>
              {`
            ${event.details.code} 
            - 
            ${event.details.name || ''} 
            ${event.details.sensorName}
            ${event.details?.device ? ` via ${event.details.device}` : ''}
          `}
            </span>
            <div className={styles[event.occurrenceType]}>
              {occurrenceTypesLabel[event.occurrenceType]}
            </div>
          </div>
          <div className={styles.details}>
            {event.partition?.code && (
              <div>Partição - {event.partition.code}</div>
            )}
            {(event.details.auxiliary ||
              event.deviceName ||
              event.details.originName) && (
              <div>
                {event.details.auxiliary || ''}
                {event.details.auxiliary && event.deviceName && ' - '}
                {event.deviceName || ''}
                {event.deviceName && event.details.originName && ' / '}
                {event.details.originName || ''}
              </div>
            )}
            <span>{dateNowWithSeconds(event.createdAt)}</span>
          </div>
        </div>

        {event.imageId && (
          <ProtectedImage
            imageId={event.imageId}
            onClick={() => {
              onOpenGallery(event.imageId || '')

              if (!hasVerificationIntervention && canEdit && !isPending) {
                handleIntervention()
              }
            }}
          />
        )}
      </div>
    </li>
  )
}

export default EventCard
