import { useQuery } from '@tanstack/react-query'
import {
  ServiceOrderDriver,
  ServiceOrderAggregatedQuery,
  ServiceOrderQuery,
} from 'services/serviceOrder'
import { Result } from 'services/types'

const fetchServiceOrders = async (query: ServiceOrderQuery) => {
  return await ServiceOrderDriver.query({ ...query })
}

export const useGetServiceOrders = (
  query: ServiceOrderQuery,
  enabled: boolean,
) =>
  useQuery<Result<ServiceOrderAggregatedQuery>>({
    queryKey: ['service-order-info', query],
    queryFn: () => fetchServiceOrders(query),
    enabled,
  })
