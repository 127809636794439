import { useCallback } from 'react'

import { EVENT } from 'domains/attendancePolicy/types'

import { handleMitigationEventForm } from 'domains/attendancePolicy/utilities/Accordion/Accordion'

import { RadioGroup } from 'components'
import { RadioButton } from 'components/RadioButton/RadioButton'

import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import { ConditionType } from 'services/attendancePolicy/types'

export const EventMitigationForm = ({
  onChange,
  data,
}: ConditionOptionForm) => {
  const comboboxLabel = 'Evento'

  const { facts } = data

  const handleEventMitigationValue = useCallback(
    (value: boolean | undefined): EVENT | null => {
      if (value === undefined) return null

      return value ? EVENT.MITIGATED : EVENT.NOT_MITIGATED
    },
    [],
  )

  const selectedEvent = handleEventMitigationValue(facts?.allEventsMitigated)

  return (
    <RadioGroup title={comboboxLabel}>
      {Object.values(EVENT).map((option, key) => (
        <RadioButton
          value={option}
          onChange={() => {
            const status = handleMitigationEventForm(option)

            onChange({
              type: ConditionType.OCCURRENCE_EVENTS_MITIGATION,
              facts: {
                allEventsMitigated: status.label === EVENT.MITIGATED,
              },
            })
          }}
          checked={selectedEvent === option}
          name={`eventMitigation-${data.id}`}
          key={key}
        />
      ))}
    </RadioGroup>
  )
}
