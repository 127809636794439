import * as Joi from '@hapi/joi'
import { Profile } from 'domains/customer/screens/Equipments/types'

export type UpdatePhotosensorProfilesSchema = {
  deviceId: string
  profiles: {
    pirProfile?: Profile
    cameraProfile?: Profile
  }
}

const profileSchema = Joi.object<Profile>({
  id: Joi.string().uuid().required(),
  name: Joi.string().required(),
})

export const updatePhotosensorProfilesSchema =
  Joi.object<UpdatePhotosensorProfilesSchema>({
    deviceId: Joi.string().uuid().required(),
    profiles: Joi.object({
      pirProfile: profileSchema.optional(),
      cameraProfile: profileSchema.optional(),
    }).required(),
  })
