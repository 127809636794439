import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { AuthDriver, PermissionGroupsQueryResponse } from 'services/auth'
import {
  flattenPages,
  getNextPageOffSet,
} from '../services/utilities/pagination'
import { useMemo } from 'react'

type UseGetPermissionGroupsReturn = {
  isError: boolean
  isFetching: boolean
  permissionGroups: PermissionGroupsQueryResponse[]
  fetchNextPermissionGroupsPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const getPermissionGroups = async (offset = 0, filter?: string) => {
  const response = await AuthDriver.queryPermissionGroups({
    recordsPerPage: 15,
    offset,
    ...(filter && { name: filter }),
  })
  return response
}

export function useGetPermissionGroups(
  filter: string,
): UseGetPermissionGroupsReturn {
  const {
    isError,
    isFetching,
    data,
    fetchNextPage: fetchNextPermissionGroupsPage,
  } = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['permissionGroups', filter],
    queryFn: ({ pageParam }) => getPermissionGroups(pageParam, filter),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<PermissionGroupsQueryResponse>(lastPage, allPages),
  })

  const permissionGroups = useMemo(
    () => flattenPages<PermissionGroupsQueryResponse>(data),
    [data],
  )

  return {
    isError,
    isFetching,
    permissionGroups,
    fetchNextPermissionGroupsPage,
  }
}
