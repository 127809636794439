import {
  OccurrenceTypes,
  StateName,
  TacticalStatus,
  VehicleIgnition,
} from 'services/displacementMap/types'

type TacticalStatusFn = {
  speed: number
  lastPositionDate: number
  ignitionStatus: VehicleIgnition
}

export const handleTacticalStatus = ({
  speed,
  lastPositionDate,
  ignitionStatus,
}: TacticalStatusFn): TacticalStatus => {
  const limitedDate = new Date()
  limitedDate.setMinutes(limitedDate.getMinutes() + 3)

  const isCommunicating = lastPositionDate <= limitedDate.getTime()

  if (isCommunicating && speed > 0) {
    return 'DISPLACEMENT'
  }

  if (isCommunicating && ignitionStatus === 'OFF') {
    return 'COMMUNICATION_OFF'
  }

  if (isCommunicating && speed === 0 && ignitionStatus === 'ON') {
    return 'COMMUNICATION_ON'
  }

  return 'WITHOUT_COMMUNICATION'
}

type StatusType = {
  label: string
  value: TacticalStatus
}

export const statusOptions: StatusType[] = [
  {
    label: 'Sem comunicação',
    value: 'WITHOUT_COMMUNICATION',
  },
  {
    label: 'Ligada',
    value: 'COMMUNICATION_ON',
  },
  {
    label: 'Desligada',
    value: 'COMMUNICATION_OFF',
  },
  {
    label: 'Em movimento',
    value: 'DISPLACEMENT',
  },
]

export const occurrenceTypesOptions = [
  'Alarme',
  'Socorro',
  'Falha',
  'Finalizado',
  'Iminência',
  'Emergência',
  'Comercial',
  'Manual',
  'Periódica',
]

export const occurrenceTypesConverted: Record<OccurrenceTypes, string> = {
  ALARM: 'Alarme',
  RESCUE: 'Socorro',
  FAILURE: 'Falha',
  FINISHED: 'Finalizado',
  IMMINENT: 'Iminência',
  EMERGENCY: 'Emergência',
  COMMERCIAL: 'Comercial',
  MANUAL: 'Manual',
  SCHEDULED: 'Periódica',
}

export const occurrenceStateNameConverted: Record<StateName, string> = {
  TRAVEL_SENT: '',
  TRAVEL_VIEWED: 'Visualizado',
  TRAVEL_STARTED: 'Iniciado',
  TRAVEL_FINISHED: 'No local',
  TRAVEL_WAITING: 'Em espera',
  FINISHED: '',
  QUEUED: '',
  AVAILABLE: '',
  IN_PROGRESS: '',
}
