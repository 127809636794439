import { useEffect, useMemo, useState } from 'react'
import { Button, Modal } from 'components'
import styles from './ServiceOrderNoteModal.module.scss'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { useInfiniteServiceOrderLabel } from 'services/serviceOrderLabel'
import { ServiceOrderLabelInput } from 'services/serviceOrderLabel/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'

type ServiceOrderNoteModalProps = {
  isVisible: boolean
  isFinishServiceOrder: boolean
  onClose: () => void
  onSave(note: string, labels?: ServiceOrderLabelInput[]): void
  modalProps: {
    title: string
    submitButtonTitle: string
    placeholder: string
  }
}

type Labels = {
  pause?: ServiceOrderLabelInput
  issue?: ServiceOrderLabelInput
  resolution?: ServiceOrderLabelInput
}

export const ServiceOrderNoteModal = ({
  onClose,
  onSave,
  isVisible,
  modalProps,
  isFinishServiceOrder,
}: ServiceOrderNoteModalProps) => {
  const [serviceOrderNote, setServiceOrderNote] = useState('')
  const [labelsFilters, setLabelsFilters] = useState({
    pause: '',
    issue: '',
    resolution: '',
  })
  const [labels, setLabels] = useState<Labels>()

  const { title, submitButtonTitle, placeholder } = modalProps

  const isDisableSaveButton = useMemo(() => {
    if (!labels) return false
    if (isFinishServiceOrder)
      return Boolean(serviceOrderNote && labels?.issue && labels.resolution)
    return Boolean(serviceOrderNote && labels?.pause)
  }, [isFinishServiceOrder, labels, serviceOrderNote])

  const {
    isError: isPauseReasonError,
    isFetching: isPauseReasonFetching,
    serviceOrderLabels: pauseReasonLabels,
    fetchNextPage: fetchNextPauseReasonPage,
  } = useInfiniteServiceOrderLabel(
    {
      type: 'PAUSE_REASON',
      description: labelsFilters.pause,
      active: true,
    },
    !isFinishServiceOrder,
  )

  const {
    isError: isIssueError,
    isFetching: isIssueFetching,
    serviceOrderLabels: issueLabels,
    fetchNextPage: fetchNextIssuePage,
  } = useInfiniteServiceOrderLabel(
    {
      type: 'ISSUE',
      description: labelsFilters.issue,
      active: true,
    },
    isFinishServiceOrder,
  )

  const {
    isError: isResolutionError,
    isFetching: isResolutionFetching,
    serviceOrderLabels: resolutionLabels,
    fetchNextPage: fetchNextResolutionPage,
  } = useInfiniteServiceOrderLabel(
    {
      type: 'RESOLUTION',
      description: labelsFilters.resolution,
      active: true,
    },
    isFinishServiceOrder,
  )

  useEffect(() => {
    setServiceOrderNote('')
  }, [isVisible])

  return (
    <Modal
      title={title}
      isVisible={isVisible}
      onClose={onClose}
      className={styles.titleContainer}
      size="sm"
      simple
    >
      {isFinishServiceOrder ? (
        <>
          <Combobox
            id="issue-input"
            className={[styles.issueInput, styles.comboboxes].join(' ')}
            label={{
              text: 'Problema encontrado',
            }}
            onSearch={(search) =>
              setLabelsFilters((prevState) => ({
                ...prevState,
                issue: search,
              }))
            }
            items={parseDataToComboboxV2(
              issueLabels.map((label) => ({
                id: label.id,
                description: label.description,
                type: label.type,
                active: label.active,
              })),
              'description',
            )}
            value={
              labels?.issue
                ? { label: 'description', value: labels.issue }
                : undefined
            }
            onChange={(selected) => {
              const issueLabel =
                selected as ComboboxItem<ServiceOrderLabelInput>
              setLabels((prevState) => ({
                ...prevState,
                issue: issueLabel.value,
              }))
            }}
            isLoading={isIssueFetching}
            isError={isIssueError}
            onEndReached={fetchNextIssuePage}
          />
          <Combobox
            id="resolution-input"
            className={styles.comboboxes}
            label={{
              text: 'Solução do problema',
            }}
            onSearch={(search) =>
              setLabelsFilters((prevState) => ({
                ...prevState,
                resolution: search,
              }))
            }
            items={parseDataToComboboxV2(
              resolutionLabels.map((label) => ({
                id: label.id,
                description: label.description,
                type: label.type,
                active: label.active,
              })),
              'description',
            )}
            value={
              labels?.resolution
                ? { label: 'description', value: labels.resolution }
                : undefined
            }
            onChange={(selected) => {
              const resolutionLabel =
                selected as ComboboxItem<ServiceOrderLabelInput>
              setLabels((prevState) => ({
                ...prevState,
                resolution: resolutionLabel.value,
              }))
            }}
            isLoading={isResolutionFetching}
            isError={isResolutionError}
            onEndReached={fetchNextResolutionPage}
          />
        </>
      ) : (
        <Combobox
          id="pause-reason-input"
          className={styles.comboboxes}
          label={{
            text: 'Motivo',
          }}
          onSearch={(search) =>
            setLabelsFilters((prevState) => ({
              ...prevState,
              pause: search,
            }))
          }
          items={parseDataToComboboxV2(
            pauseReasonLabels.map((label) => ({
              id: label.id,
              description: label.description,
              type: label.type,
              active: label.active,
            })),
            'description',
          )}
          value={
            labels?.pause
              ? { label: 'description', value: labels.pause }
              : undefined
          }
          onChange={(selected) => {
            const pauseLabel = selected as ComboboxItem<ServiceOrderLabelInput>
            setLabels((prevState) => ({
              ...prevState,
              pause: pauseLabel.value,
            }))
          }}
          isLoading={isPauseReasonFetching}
          isError={isPauseReasonError}
          onEndReached={fetchNextPauseReasonPage}
        />
      )}
      <textarea
        className={styles.textArea}
        id="service-order-note"
        title="Observações"
        rows={6}
        maxLength={5000}
        placeholder={placeholder}
        value={serviceOrderNote}
        onChange={(e) => setServiceOrderNote(e.target.value)}
      />
      <Modal.Footer className={styles.footer}>
        <Button
          id="service-order-note-close-button"
          width="172px"
          buttonTitle="Cancelar"
          onClick={onClose}
          type="secondary"
        />
        <Button
          id="service-order-note-submit-button"
          width="172px"
          disabled={!isDisableSaveButton}
          buttonTitle={submitButtonTitle}
          onClick={() => {
            const newLabels: ServiceOrderLabelInput[] = []
            labels?.pause && newLabels.push(labels.pause)
            labels?.issue && newLabels.push(labels.issue)
            labels?.resolution && newLabels.push(labels.resolution)
            onSave(serviceOrderNote, newLabels)
          }}
          type="primary"
        />
      </Modal.Footer>
    </Modal>
  )
}
