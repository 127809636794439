import md5 from 'md5'
import { useNavigate, useLocation } from 'react-router-dom'

export const useOSFilter = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleOSFilter = (value: unknown) => {
    const hash = md5(JSON.stringify(value))
    const currentHash = localStorage.getItem(hash)

    if (!currentHash) {
      localStorage.setItem(hash, JSON.stringify(value))
    }

    navigate({ search: `filter=${hash}&page=1` }, { replace: true })
  }

  const handleResetFilter = () => {
    navigate({ search: '' }, { replace: true })
  }

  const params = new URLSearchParams(location.search)
  const filter = localStorage.getItem(params.get('filter') || '')

  return {
    filters: {
      ...JSON.parse(filter),
      page: 1,
    },
    handleOSFilter,
    handleResetFilter,
  }
}
