import Button from 'components/Button'
import { useNavigate } from 'react-router-dom'
import './PageNotFound.scss'

export const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <div className="container-page">
      <div className="card">
        <div className="circle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
            viewBox="0 0 125 125"
          >
            <path
              d="M17841.244 19956.244l.006-15.467h-12.291c.166-.094-22.09-.3-21.92 0h-4.539a15.5 15.5 0 0 1-15.5-15.506v-62.018a15.5 15.5 0 0 1 15.5-15.5h38.75a7.751 7.751 0 1 1 15.5 0h38.75a15.5 15.5 0 0 1 15.5 15.5v62.018a15.5 15.5 0 0 1-15.5 15.506h-4.545c.115-.2-21.936 0-21.916 0h-12.289l-.01 15.467a7.749 7.749 0 1 1-15.5 0zm-38.744-30.973h93v-62.018h-93zm56.1-9.83l-9.658-9.711-9.656 9.711a7.446 7.446 0 0 1-10.566 0 7.543 7.543 0 0 1 0-10.623l9.66-9.709-9.66-9.711a7.541 7.541 0 0 1 0-10.621 7.447 7.447 0 0 1 10.566 0l9.656 9.709 9.658-9.709a7.442 7.442 0 0 1 10.563 0 7.53 7.53 0 0 1 0 10.621l-9.654 9.711 9.654 9.709a7.533 7.533 0 0 1 0 10.623 7.441 7.441 0 0 1-10.562 0z"
              transform="translate(-17786.498 -19839.5)"
            />
          </svg>
        </div>
        <h2 className="text title">Página indisponível</h2>
        <p className="text subtitle">
          Houve um erro ao tentar acessar esta página
        </p>
        <div className="button-group">
          <Button
            width="172px"
            id="button-00"
            buttonTitle="Tela inicial"
            type="primary"
            onClick={() => navigate('/')}
          />
          <Button
            width="172px"
            id="button-01"
            type="secondary"
            buttonTitle="Voltar"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
