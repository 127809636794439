import {
  OccurrenceCategoryName,
  OCCURRENCE_CATEGORY,
} from 'services/occurrence/types'
import { SERVICE_ORDER_TYPE_ENUM } from 'services/serviceOrder'
import { Paging, Timestamps } from 'services/types'

export enum COVERAGE_TYPE_ENUM {
  COMMERCIAL = 'COMMERCIAL',
  TECHNICAL = 'TECHNICAL',
  TACTICAL = 'TACTICAL',
}

export enum ATTENDANCE_ROLE {
  OCCURRENCE_ATTENDANT = 'OCCURRENCE_ATTENDANT',
  CUSTOMER_ATTENDANT = 'CUSTOMER_ATTENDANT',
  OCCURRENCE_MANAGEMENT = 'OCCURRENCE_MANAGEMENT',
  SERVICE_ORDER_ATTENDANT = 'SERVICE_ORDER_ATTENDANT',
  TACTICAL_AGENT = 'TACTICAL_AGENT',
  TACTICAL_DISPLACEMENT_OPERATOR = 'TACTICAL_DISPLACEMENT_OPERATOR',
  TECHNICAL_AGENT = 'TECHNICAL_AGENT',
  TECHNICAL_DISPLACEMENT_OPERATOR = 'TECHNICAL_DISPLACEMENT_OPERATOR',
}

export enum ATTENDANCE_ROLE_EN_PT {
  OCCURRENCE_ATTENDANT = 'Atendente de ocorrências',
  CUSTOMER_ATTENDANT = 'Atendimento ao cliente',
  OCCURRENCE_MANAGEMENT = 'Gestor de ocorrências',
  SERVICE_ORDER_ATTENDANT = 'Atendente de ordens de serviço',
  TACTICAL_AGENT = 'Agente tático',
  TACTICAL_DISPLACEMENT_OPERATOR = 'Operador de deslocamento tático',
  TECHNICAL_AGENT = 'Agente técnico',
  TECHNICAL_DISPLACEMENT_OPERATOR = 'Operador de deslocamento técnico',
}

export enum ATTENDANCE_ROLE_PT_EN {
  'Atendente de ocorrências' = ATTENDANCE_ROLE.OCCURRENCE_ATTENDANT,
  'Atendimento ao cliente' = ATTENDANCE_ROLE.CUSTOMER_ATTENDANT,
  'Gestor de ocorrências' = ATTENDANCE_ROLE.OCCURRENCE_MANAGEMENT,
  'Atendente de ordens de serviço' = ATTENDANCE_ROLE.SERVICE_ORDER_ATTENDANT,
  'Agente tático' = ATTENDANCE_ROLE.TACTICAL_AGENT,
  'Operador de deslocamento tático' = ATTENDANCE_ROLE.TACTICAL_DISPLACEMENT_OPERATOR,
  'Agente técnico' = ATTENDANCE_ROLE.TECHNICAL_AGENT,
  'Operador de deslocamento técnico' = ATTENDANCE_ROLE.TECHNICAL_DISPLACEMENT_OPERATOR,
}

export enum ATTENDANCE_TYPES_ENUM {
  ALARM = 'ALARM',
  HABIT_DEVIATION = 'HABIT_DEVIATION',
  CFTV = 'CFTV',
}

export type Coverage = {
  accountIds: string[]
  accountTagIds: string[]
  cityIds: string[]
  coverageType: ATTENDANCE_ROLE
  districtIds: string[]
  id: string
  name: string
  occurrenceCategories: OCCURRENCE_CATEGORY[]
  serviceOrderTypes: SERVICE_ORDER_TYPE_ENUM[]
  stateIds: string[]
  totalUsers?: number
  serviceTypes?: {
    id: string
    name: string
  }[]
  serviceTypeIds?: string[]
} & Timestamps

export type EntityResponse = {
  id: string
  name: string
}

type EntityWithAggregatedName = EntityResponse & {
  aggregatedName: string
}

export type AccountTags = {
  id: string
  name: string
}

export type CoverageFindResponse = Pick<
  Coverage,
  'id' | 'name' | 'coverageType' | 'serviceOrderTypes' | 'occurrenceCategories'
> & {
  cities: EntityWithAggregatedName[]
  districts: EntityWithAggregatedName[]
  states: EntityResponse[]
  accounts: EntityResponse[]
  serviceTypes?: {
    id: string
    name: string
  }[]
  accountTags: AccountTags[]
}

export type CoverageQueryResponse = Pick<
  Coverage,
  | 'id'
  | 'name'
  | 'coverageType'
  | 'createdAt'
  | 'totalUsers'
  | 'serviceTypes'
  | 'serviceTypeIds'
>

export declare type QueryCoverageRequest = {
  name?: string
  coverageType?: ATTENDANCE_ROLE
  stateId?: string
  cityId?: string
  districtId?: string
  accountId?: string
  accountTags?: string[]
  serviceOrderType?: SERVICE_ORDER_TYPE_ENUM
  occurrenceCategory?: OCCURRENCE_CATEGORY
  createdFrom?: number
  createdTo?: number
  updatedFrom?: number
  updatedTo?: number
  sortType?: 'ASC' | 'DESC'
  serviceTypeId?: string
} & Partial<Timestamps & Paging>

export type Technician = {
  id: string
  name: string
}

type District = {
  id: string
  name: string
}

type City = {
  id: string
  name: string
}

type CoverageAggregatedAccount = {
  id: string
  code: string
  customer: { id: string; name: string }
  aggregatedAccountName: string
}

export type CoverageCity = City & {
  available: boolean
}

export type CoverageDistrict = District & {
  available: boolean
}

export type CoverageAccount = CoverageAggregatedAccount & {
  available: boolean
}

export type CoverageTechnician = Technician & {
  available: boolean
}

export type CoverageInputRequest = {
  id?: string
  name: string
  serviceTypeIds: string[]
  serviceTypes?: {
    id: string
    name: string
  }[]
  coverageType: ATTENDANCE_ROLE
  stateIds?: string[]
  cityIds?: string[]
  districtIds?: string[]
  accountIds?: string[]
  accountTagIds?: string[]
  occurrenceCategories?: OccurrenceCategoryName[]
  serviceOrderTypes?: SERVICE_ORDER_TYPE_ENUM[]
}

export type AttendanceProfileFiltersQuery = {
  name?: string
  coverageType?: ATTENDANCE_ROLE
  stateId?: string
  cityId?: string
  districtId?: string
  accountId?: string
  accountTag?: string
  serviceOrderTypes?: SERVICE_ORDER_TYPE_ENUM
  occurrenceCategory?: OCCURRENCE_CATEGORY
}
