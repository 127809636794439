import * as Joi from '@hapi/joi'

import { CoverageInputRequest, ATTENDANCE_ROLE } from 'services/coverages'
import { OCCURRENCE_CATEGORY } from 'services/occurrence/types'
import { SERVICE_ORDER_TYPE_ENUM } from 'services/serviceOrder'

export const createAndUpdateAttendanceProfileSchema =
  Joi.object<CoverageInputRequest>({
    name: Joi.string().required(),
    serviceTypeIds: Joi.array().items(Joi.string().uuid()).optional(),
    serviceTypes: Joi.array()
      .items(
        Joi.object({
          id: Joi.string().uuid(),
          name: Joi.string(),
        }),
      )
      .optional(),
    accountIds: Joi.array().items(Joi.string().uuid()).optional(),
    stateIds: Joi.array().items(Joi.string().uuid()).optional(),
    cityIds: Joi.array().items(Joi.string().uuid()).optional(),
    districtIds: Joi.array().items(Joi.string().uuid()).optional(),
    accountTagIds: Joi.array().items(Joi.string()).optional(),
    serviceOrderTypes: Joi.array()
      .items(Joi.string().valid(...Object.values(SERVICE_ORDER_TYPE_ENUM)))
      .optional(),
    occurrenceCategories: Joi.array()
      .items(Joi.string().valid(...Object.values(OCCURRENCE_CATEGORY)))
      .optional(),
    coverageType: Joi.string()
      .valid(...Object.values(ATTENDANCE_ROLE))
      .optional(),
  })
