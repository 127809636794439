import { AxiosInstance } from 'axios'
import HttpClient from '../httpClient'
import { Pagination, Paging, Result } from '../types'
import FormData from 'form-data'

import {
  CreateUserRequest,
  LoginRequest,
  LoginResponse,
  ChangePasswordRequest,
  SavePasswordRequest,
  ValidateTokenRequest,
  UpdateUserRequest,
  ProfileListResponse,
  PermissionGroupsListResponse,
  UserInfoResponse,
  UserInfoRequest,
  UserProfileImageResponse,
  UserPermissions,
  TagQueryResponse,
  CreateUserSessionRequest,
  UserQueryResult,
  TagQueryRequest,
  AggregatedUserQueryResponse,
  UserResponse,
} from './types'
import { UserQuery } from '.'
import { parseCookies } from 'nookies'

interface AuthenticationDriver {
  login(login: LoginRequest): Promise<LoginResponse>
  resetPassword(email: string): Promise<unknown>
  createUser(user: CreateUserRequest): Promise<UserResponse>
  updateUser(userId: string, user: UpdateUserRequest): Promise<UserResponse>
  savePassword(user: SavePasswordRequest): Promise<unknown>
  changePassword(user: ChangePasswordRequest): Promise<unknown>
  validateToken(validateToken: ValidateTokenRequest): Promise<unknown>
  queryUsers(query: Partial<Paging>): Promise<unknown>
  queryUsersWithCoverage(query: Partial<Paging>): Promise<unknown>
  queryTags(query?: TagQueryRequest): Promise<Result<TagQueryResponse>>
  queryPermissionGroups(
    queryParams?: Partial<Permissions> & Partial<Pagination>,
  ): Promise<PermissionGroupsListResponse>
  getUserPermissions(userId: string): Promise<UserPermissions>
  getProfileList(): Promise<ProfileListResponse>
  getProfileList(): Promise<ProfileListResponse>
  createUserSession(
    payload: CreateUserSessionRequest,
  ): Promise<ProfileListResponse>
}

class AuthenticationDriverImpl implements AuthenticationDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  async createUserSession(
    payload: CreateUserSessionRequest,
  ): Promise<ProfileListResponse> {
    const { userId, status } = payload
    return await this.httpClient.post(`/user/${userId}/userSession`, {
      status,
    })
  }

  async login(login: LoginRequest): Promise<LoginResponse> {
    const response = await this.httpClient.post<LoginResponse>('/login', login)
    return response.data
  }

  async resetPassword(email: string): Promise<unknown> {
    return await this.httpClient.post('/resetPassword', { email })
  }

  async createUser(user: CreateUserRequest): Promise<UserResponse> {
    return await this.httpClient.post('auth/user/create', user)
  }

  async updateUser(
    userId: string,
    user: UpdateUserRequest,
  ): Promise<UserResponse> {
    const response = await this.httpClient.put<UserResponse>(
      `auth/user/update/${userId}`,
      user,
    )
    return response.data
  }

  async savePassword(savePassword: SavePasswordRequest): Promise<unknown> {
    return await this.httpClient.post('auth/user/savePassword', savePassword)
  }

  async changePassword(
    changePassword: ChangePasswordRequest,
  ): Promise<unknown> {
    const { userId } = parseCookies()

    if (userId) {
      return await this.httpClient.put(
        `/user/${userId}/changePassword`,
        changePassword,
      )
    }
  }

  async validateToken(validateToken: ValidateTokenRequest): Promise<unknown> {
    return await this.httpClient.post(
      'auth/user/validatePasswordToken',
      validateToken,
    )
  }

  public async queryUsers(
    queryParams: UserQuery,
  ): Promise<Result<UserQueryResult>> {
    const response = await this.httpClient.get<Result<UserQueryResult>>(
      '/auth/user/query',
      { params: queryParams },
    )
    return response.data
  }

  public async queryUsersWithCoverage(
    queryParams: UserQuery,
  ): Promise<Result<AggregatedUserQueryResponse>> {
    const response = await this.httpClient.get<
      Result<AggregatedUserQueryResponse>
    >('/auth/userWithCoverage/query', { params: queryParams })
    return response.data
  }

  public async queryTags(
    queryParams?: TagQueryRequest,
  ): Promise<Result<TagQueryResponse>> {
    const response = await this.httpClient.get<Result<TagQueryResponse>>(
      '/auth/user/tag/list',
      { params: queryParams || '' },
    )
    return response.data
  }

  public async getProfileList(): Promise<ProfileListResponse> {
    const result =
      await this.httpClient.get<ProfileListResponse>('/auth/profile/list')
    return result.data
  }

  public async queryPermissionGroups(
    queryParams?: Partial<Permissions> & Partial<Pagination>,
  ): Promise<PermissionGroupsListResponse> {
    const result = await this.httpClient.get<PermissionGroupsListResponse>(
      '/auth/permissionGroup/list',
      { params: queryParams },
    )
    return result.data
  }

  public async getUserInfo(userId: string): Promise<UserInfoResponse> {
    const result = await this.httpClient.get<UserInfoResponse>(
      `/user/${userId}`,
    )
    return result.data
  }

  public async updateUserInfo(info: UserInfoRequest): Promise<unknown> {
    const { userId } = parseCookies()

    return await this.httpClient.put<UserInfoResponse>(`/user/${userId}`, info)
  }

  public async getUserPermissions(userId: string): Promise<UserPermissions> {
    const result = await this.httpClient.get<UserPermissions>(
      `/user/${userId}/permissions`,
    )
    return result.data
  }

  public async updateUserProfileImage(
    image: File,
  ): Promise<UserProfileImageResponse> {
    const { userId } = parseCookies()

    const data = new FormData()
    data.append('imageFile', image)
    data.append('userId', userId)
    const result = await this.httpClient.post<UserProfileImageResponse>(
      `/user/image`,
      data,
      {
        headers: {
          accept: 'application/json',
          'Content-Type': `multipart/form-data`,
        },
      },
    )
    return result.data
  }
}

export const AuthDriver = new AuthenticationDriverImpl()
