import { useRef, FC, useMemo } from 'react'
import { Tags, Tooltip } from 'components'
import { useToggle } from 'shared/hooks'

import { ReactComponent as Info } from 'assets/svg/info.svg'

import { ReactComponent as ShareIcon } from 'assets/svg/shareBox.svg'

import styles from './styles.module.scss'
import { Technician } from 'services/serviceOrder'
import { formatDocument, formatPhone, getPhoneEnumValue } from 'utilities/masks'

type Tag = {
  id: string
  name: string
}

interface TooltipInfoProps {
  technician?: Technician
  tags?: Tag[]
  onInspectTechnician: () => void
}

const TooltipInfo: FC<TooltipInfoProps> = ({
  technician,
  tags,
  onInspectTechnician,
}) => {
  const tooltip = useToggle()
  const iconRef = useRef<HTMLDivElement>(null)

  const technicianInfo = useMemo(() => {
    const buildAddress = (address) => {
      if (!address) return ''

      const {
        address: street,
        number,
        district,
        city,
        state,
        postalCode,
      } = address
      const parts = [
        street ? `${street}` : null,
        number ? `, ${number}` : null,
        district ? ` - ${district}` : null,
        city ? `, ${city}` : null,
        state ? ` - ${state}` : null,
        postalCode ? `, ${postalCode}` : null,
      ]

      return parts.filter((part) => part).join('')
    }
    const phone = technician?.phone.toString()

    const info = [
      {
        label: 'Documento',
        value: technician?.document ? formatDocument(technician?.document) : '',
      },
      {
        label: 'Telefone',
        value: phone ? formatPhone(getPhoneEnumValue(phone), phone) : '',
      },
      { label: 'Endereço', value: buildAddress(technician?.address) },
      { label: 'Complemento', value: technician?.address?.adjunct },
      { label: 'Referência', value: technician?.address?.reference },
    ]

    return info.filter((item) => (item.value ? item.value !== '' : null))
  }, [technician])

  return (
    <div onMouseEnter={tooltip.show} onMouseLeave={tooltip.hide}>
      <div className={styles.infoIconWrapper} ref={iconRef}>
        <Info />
      </div>
      <Tooltip
        parentRef={iconRef}
        type="informative"
        isVisible={tooltip.isVisible}
      >
        <div className={styles.tooltipWrapper}>
          <h3>Informações do usuário</h3>
          {technicianInfo.map((info, index) =>
            info.value ? (
              <div key={index}>
                <h4>{info.label}</h4>
                <p>{info.value}</p>
              </div>
            ) : null,
          )}
          {technician?.coverages?.length ? (
            <h4>Perfis de atendimento</h4>
          ) : null}
          <Tags
            className={styles.tagWrapper}
            size="sm"
            keyId="#"
            keyLabel="#"
            expandTags
            data={
              technician?.coverages?.map((coverage) => ({
                id: coverage.id,
                name: coverage.name,
              })) || []
            }
          />
          {tags?.length ? <h4>Tags</h4> : null}
          <Tags
            className={styles.tagWrapper}
            size="sm"
            keyId="#"
            keyLabel="#"
            expandTags
            data={
              tags?.map((tag) => ({
                id: tag.id,
                name: tag.name,
              })) || []
            }
          />

          <span className={styles.line} />
          <button className={styles.learnMore} onClick={onInspectTechnician}>
            <ShareIcon />
            Ver mais
          </button>
        </div>
      </Tooltip>
    </div>
  )
}

export default TooltipInfo
