import { RefObject, useEffect } from 'react'

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent) => void,
  dropdownUsage?: boolean,
) {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        handler(e)
      }
    }

    const handleMouseDown = (event: MouseEvent | TouchEvent) => {
      const el = ref?.current
      if (!el || el.contains(event.target as Node)) {
        return
      }
      handler(event)
    }

    if (dropdownUsage) {
      document.addEventListener(`click`, handleClick)
    } else {
      document.addEventListener(`mousedown`, handleMouseDown)
      document.addEventListener(`touchstart`, handleMouseDown)
    }

    return () => {
      if (dropdownUsage) {
        document.removeEventListener(`click`, handleClick)
      } else {
        document.removeEventListener(`mousedown`, handleMouseDown)
        document.removeEventListener(`touchstart`, handleMouseDown)
      }
    }
  }, [ref, handler])
}
