import { ButtonV2, Icon, ModalV2 } from 'components'
import { useDisplacementModal } from 'domains/occurrence/screens/DisplacementMap/contexts'

import styles from './styles.module.scss'

interface ChangeStateProps {
  onSubmit: () => void
}

const ChangeState = ({ onSubmit }: ChangeStateProps) => {
  const { handleCloseModal } = useDisplacementModal()

  return (
    <ModalV2.Root isOpen onClose={handleCloseModal}>
      <ModalV2.Content>
        <ModalV2.Title className={styles.title}>
          <Icon name="warning" width={20} height={20} />
          Mudar status de uma ocorrência com deslocamento
        </ModalV2.Title>
        <p>
          {`Esta ocorrência recebeu um deslocamento, porém seu status não foi
          atualizado para "No local". Tem certeza de que deseja alterar?`}
        </p>
        <ModalV2.Footer>
          <ModalV2.Close asChild>
            <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
          </ModalV2.Close>
          <ButtonV2 onClick={() => onSubmit()}>Confirmar</ButtonV2>
        </ModalV2.Footer>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default ChangeState
