import { useEffect, useState } from 'react'
import { Button, Modal } from 'components'
import { Combobox } from 'components/ComboboxV2/Combobox'
import styles from './ServiceOrderValidationModal.module.scss'
import { useGetServiceOrderTags } from 'shared/hooks/serviceOrder/useGetServiceOrderTags'
import { EntityTag, SERVICE_ORDER_STATUS_ENUM } from 'services/serviceOrder'

type ServiceOrderValidationModalProps = {
  isVisible: boolean
  onClose: () => void
  onSave(note: string, tags: string[]): void
  modalProps: {
    title: string
    submitButtonTitle: string
    placeholder: string
    serviceOrderTags: EntityTag[]
    serviceOrderStatus: SERVICE_ORDER_STATUS_ENUM
  }
}

export const ServiceOrderValidationModal = ({
  onClose,
  onSave,
  isVisible,
  modalProps,
}: ServiceOrderValidationModalProps) => {
  const {
    title,
    submitButtonTitle,
    placeholder,
    serviceOrderTags = [],
    serviceOrderStatus,
  } = modalProps

  const [serviceOrderNote, setServiceOrderNote] = useState('')
  const [selectedServiceOrderTags, setSelectedServiceOrderTags] = useState<
    string[]
  >([])

  const [tagFilter, setTagFilter] = useState('')

  const {
    isError: isErrorServiceOrderTags,
    isFetching: isFetchingServiceOrderTags,
    serviceOrderTags: tags,
    fetchNextTagsPage,
  } = useGetServiceOrderTags(tagFilter)

  useEffect(() => {
    setServiceOrderNote('')
    setSelectedServiceOrderTags(serviceOrderTags?.map((tag) => tag.name))
  }, [isVisible, serviceOrderTags])

  return (
    <Modal
      title={title}
      isVisible={isVisible}
      onClose={onClose}
      className={styles.titleContainer}
      size="md"
    >
      <div>
        <Combobox
          label={{
            text: 'Tags (opcional)',
          }}
          multiple
          onSearch={(search) => setTagFilter(search)}
          items={tags?.map((tag) => tag.name)}
          value={selectedServiceOrderTags?.map((tag) => tag)}
          onChange={(selecteds) => {
            const tagsSelected = selecteds as string[]
            setSelectedServiceOrderTags(tagsSelected)
          }}
          isLoading={isFetchingServiceOrderTags}
          isError={isErrorServiceOrderTags}
          onEndReached={fetchNextTagsPage}
        />
      </div>
      <div className={styles.textArea}>
        <label htmlFor="Observações">
          Observações{' '}
          {serviceOrderStatus === SERVICE_ORDER_STATUS_ENUM.FINISHED &&
            '(opcional)'}
        </label>
        <textarea
          id="service-order-note"
          title="Observações"
          rows={6}
          maxLength={5000}
          placeholder={placeholder}
          value={serviceOrderNote}
          onChange={(e) => setServiceOrderNote(e.target.value)}
        />
      </div>
      <Modal.Footer className={styles.footer}>
        <Button
          id="service-order-note-close-button"
          width="172px"
          buttonTitle="Voltar"
          onClick={onClose}
          type="secondary"
        />
        <Button
          id="service-order-note-submit-button"
          width="172px"
          disabled={
            serviceOrderStatus === SERVICE_ORDER_STATUS_ENUM.REPROVED
              ? !serviceOrderNote.length
              : false
          }
          buttonTitle={submitButtonTitle}
          onClick={() => onSave(serviceOrderNote, selectedServiceOrderTags)}
          type="primary"
        />
      </Modal.Footer>
    </Modal>
  )
}
