import { getOccurrenceTag } from 'shared/hooks/services'

import { ComboBoxV3, FormItem, FormLabel, Icon, Tag } from 'components'
import { ActionOptionForm } from '../../RuleForm/types'

export const OccurrenceTags = ({ onChange, data }: ActionOptionForm) => {
  const { index, parameters } = data

  return (
    <FormItem>
      <FormLabel>Adicionar tag</FormLabel>
      <ComboBoxV3.Root findOptions={getOccurrenceTag} valueKey="name">
        {({ unSelectedOptions }) => (
          <>
            <ComboBoxV3.Field
              value={data?.parameters?.tags}
              placeholder="Selecione uma ou mais tags"
            />
            {!!parameters?.tags?.length && (
              <>
                <ComboBoxV3.Options>
                  {parameters?.tags?.map((tag) => (
                    <Tag key={tag.id}>
                      <span>{tag.name}</span>

                      <Icon
                        name="close-xlg"
                        width={8}
                        height={8}
                        onClick={() =>
                          onChange({
                            index,
                            parameters: {
                              tags: parameters.tags?.filter(
                                (parameterTag) => parameterTag.id !== tag.id,
                              ),
                            },
                          })
                        }
                      />
                    </Tag>
                  ))}
                </ComboBoxV3.Options>
              </>
            )}

            <ComboBoxV3.Group>
              {unSelectedOptions?.map((option) => (
                <ComboBoxV3.Option
                  key={option.name}
                  shouldCloseGroup={false}
                  onClick={() => {
                    onChange({
                      index,
                      parameters: {
                        tags: parameters?.tags?.length
                          ? [
                              ...parameters.tags,
                              { id: option.id, name: option.name },
                            ]
                          : [{ id: option.id, name: option.name }],
                      },
                    })
                  }}
                >
                  {option.name}
                </ComboBoxV3.Option>
              ))}
            </ComboBoxV3.Group>
          </>
        )}
      </ComboBoxV3.Root>
    </FormItem>
  )
}
