import { OccurrenceTypeName } from 'services/occurrence/types'

type OccurrenceTitle = {
  [key in OccurrenceTypeName]: string
}

export const OccurrenceTitle: OccurrenceTitle = {
  [OccurrenceTypeName.ALARM]: 'Ocorrência de Alarme',
  [OccurrenceTypeName.RESCUE]: 'Ocorrência de Socorro',
  [OccurrenceTypeName.EMERGENCY]: 'Ocorrência de Emergência',
  [OccurrenceTypeName.IMMINENT]: 'Ocorrência de Iminência',
  [OccurrenceTypeName.FAILURE]: 'Ocorrência de Falha',
  [OccurrenceTypeName.COMMERCIAL]: 'Ocorrência Comercial',
  [OccurrenceTypeName.MANUAL]: 'Ocorrência Manual',
  [OccurrenceTypeName.SCHEDULED]: 'Ocorrência Periódica',
}

export const OccurrenceTypeTitle: OccurrenceTitle = {
  [OccurrenceTypeName.ALARM]: 'Alarme',
  [OccurrenceTypeName.RESCUE]: 'Socorro',
  [OccurrenceTypeName.EMERGENCY]: 'Emergência',
  [OccurrenceTypeName.IMMINENT]: 'Iminência',
  [OccurrenceTypeName.FAILURE]: 'Falha',
  [OccurrenceTypeName.COMMERCIAL]: 'Comercial',
  [OccurrenceTypeName.MANUAL]: 'Manual',
  [OccurrenceTypeName.SCHEDULED]: 'Periódica',
}
