import { useForm } from 'react-hook-form'

import styles from './CentralPartitionStatus.module.scss'

import { Combobox } from 'components/ComboboxV2/Combobox'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { RadioGroup } from 'components'

import { ConditionType, PartitionStatus } from 'services/attendancePolicy/types'

import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import {
  PartitionQuantityLabel,
  convertPartitionCondition,
  convertPartitionStatus,
  handleAllPartitionArmed,
  partitionStatusLabel,
  translatedPartitionQuantityLabel,
} from 'domains/attendancePolicy/components/CondititonForms/CentralPartitionStatus/data'

export const CentralPartitionStatus = ({
  onChange,
  data,
}: ConditionOptionForm) => {
  const { facts } = data

  const { register, watch, setValue } = useForm<{
    allPartitions: boolean
    partitionStatus: PartitionStatus
  }>({
    defaultValues: {
      allPartitions: facts?.allPartitions,
      partitionStatus: facts?.partitionStatus,
    },
  })

  return (
    <div>
      <div className={styles.wrapper}>
        <Combobox
          id={`partition-${data.id}`}
          label={{
            text: 'Partições',
          }}
          {...register('allPartitions')}
          value={handleAllPartitionArmed(facts?.allPartitions)}
          items={Object.values(translatedPartitionQuantityLabel)}
          onChange={(selected) => {
            const selectedValue = selected as PartitionQuantityLabel

            setValue('allPartitions', convertPartitionCondition[selectedValue])

            onChange({
              type: ConditionType.CENTRAL_PARTITIONS_STATUS,
              facts: {
                partitionStatus: watch('partitionStatus'),
                allPartitions: convertPartitionCondition[selectedValue],
              },
            })
          }}
          placeholder="Selecione uma opção"
        />
      </div>

      <RadioGroup
        title={'Status'}
        customStyle={styles.customRadioGroup}
        {...register('partitionStatus')}
      >
        {Object.values(partitionStatusLabel).map((option, key) => (
          <RadioButton
            value={option}
            onChange={(e) => {
              const selectedValue = e.target.value

              setValue('partitionStatus', convertPartitionStatus[selectedValue])

              onChange({
                type: ConditionType.CENTRAL_PARTITIONS_STATUS,
                facts: {
                  allPartitions: watch('allPartitions'),
                  partitionStatus: convertPartitionStatus[selectedValue],
                },
              })
            }}
            checked={facts?.partitionStatus === convertPartitionStatus[option]}
            name={`partition-status-${data.id}`}
            key={key}
          />
        ))}
      </RadioGroup>
    </div>
  )
}
