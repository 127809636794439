import { AggregatedFacts } from 'services/attendancePolicy/types'

export const handleDefaultQuantityType = (
  facts: AggregatedFacts | undefined,
): 'NONE' | 'SINGLE' | 'INTERVAL' => {
  if (facts?.minDevices === undefined) return 'NONE'

  if (facts.minDevices >= 0 && !facts.maxDevices) {
    return 'SINGLE'
  }

  return 'INTERVAL'
}
