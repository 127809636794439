import { Icon } from 'components'
import styles from './styles.module.scss'

const EmptyState = () => (
  <div className={styles.emptyState}>
    <Icon name="empty-state" width={32} height={34} />
    <h3>Selecione um cliente e uma conta</h3>
    <p>Os resultados serão mostrados aqui</p>
  </div>
)

export default EmptyState
