import { useMutation } from '@tanstack/react-query'
import { CoverageInputRequest, CoverageDriver } from 'services/coverages'

function updateCoverage(data: CoverageInputRequest): Promise<void> {
  const { id, ...payload } = data
  return CoverageDriver.update(id || '', payload)
}

export const usePutCoverage = () => {
  const { status, mutate } = useMutation({
    mutationFn: async (data: CoverageInputRequest) => {
      await updateCoverage(data)
    },
  })

  return {
    mutateUpdateCoverage: mutate,
    statusUpdateCoverage: status,
  }
}
