import { createContext, Dispatch, SetStateAction } from 'react'
import { AggregatedAccount } from 'services/account/types'

export const AccountContext = createContext<AggregatedAccount | undefined>(
  undefined,
)

export interface TabContextProps {
  setUnsaveds: Dispatch<SetStateAction<string[]>>
}
export const TabContext = createContext<TabContextProps | null>(null)
