import { useMemo } from 'react'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { getNextPageOffSet, flattenPages } from '../utilities/pagination'

import { AuthDriver, UserQuery, UserQueryResult } from 'services/auth'

interface UseGetUsersReturn {
  isError: boolean
  isFetching: boolean
  users: UserQueryResult[]
  fetchNextUsersPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

async function getUsers(offset = 0, query?: UserQuery) {
  const response = await AuthDriver.queryUsers({
    recordsPerPage: 15,
    offset,
    active: true,
    sort: 'name',
    sortType: 'ASC',
    ...query,
  })

  return response
}

export const useGetUsers = (query?: UserQuery): UseGetUsersReturn => {
  const {
    isError,
    data,
    fetchNextPage: fetchNextUsersPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['users', query],
    queryFn: ({ pageParam }) => getUsers(pageParam, query),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<UserQueryResult>(lastPage, allPages),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const users = useMemo(() => {
    return flattenPages<UserQueryResult>(data)
  }, [data])

  return { isError, isFetching, users, fetchNextUsersPage }
}
