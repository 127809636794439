import { Button } from 'components'

import { UserInfoResponse } from 'services/auth'

import { FormProvider, useForm } from 'react-hook-form'

import { UserFormData } from 'domains/user/types'

import {
  GeneralInformation,
  AccessInfo,
  AttendanceProfile,
  Address,
} from 'domains/user/components/UserForm/components'

import styles from './UserForm.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import { userSchema } from 'domains/user/schemas/userDataSchema'
import { formatPhone } from 'utilities/masks'
import { PhoneType } from 'services/contact/types'

type UserFormProps = {
  onSubmit: (user: UserFormData) => void
  onGoBack: () => void
  user?: UserInfoResponse
  readOnly?: boolean
}

export const UserForm = ({
  onSubmit,
  onGoBack,
  user,
  readOnly,
}: UserFormProps) => {
  const form = useForm<UserFormData>({
    mode: 'all',
    defaultValues: {
      id: user?.id,
      document: user?.document || '',
      name: user?.name,
      currentEmail: user?.email,
      username: user?.username !== user?.email ? user?.username : '',

      phoneExtension: user?.phoneExtension,
      phone: formatPhone(
        PhoneType.CellPhone,
        `${String(user?.phoneProvinceCode || '')}${String(user?.phone || '')}`,
      ),
      permissionGroups: user?.permissionGroups,
      userCoverages: user?.userCoverages,
      avatarId: user?.imageId,

      state: user?.state,
      city: user?.city,
      district: user?.district,

      tags: user?.tags.map((tag) => tag.name),
      postalCode: user?.address?.postalCode
        ? String(user?.address?.postalCode)
        : '',
      address: user?.address?.address,
      reference: user?.address?.reference,
      adjunct: user?.address?.adjunct,
      number: user?.address?.number,
      active: user?.active ? 'Ativo' : 'Inativo',
      activeDateTime: user?.activeDateTime,

      loginWithEmail: !!user?.email,
      fillPassword: !user?.username,

      currentPassword: '',
      confirmPassword: '',
    },
    resolver: joiResolver(userSchema),
  })

  const { handleSubmit, formState, register } = form

  return (
    <>
      <div className={styles.container}>
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="false"
            autoCorrect="false"
          >
            <div className={styles.formContainer}>
              <GeneralInformation readonly={readOnly} />

              <AccessInfo
                readonly={readOnly}
                {...register('loginWithEmail')}
                {...register('fillPassword')}
              />

              <AttendanceProfile readonly={readOnly} />

              <Address readonly={readOnly} />
            </div>
            <div className={styles.footer}>
              <Button
                buttonTitle="Voltar"
                type="secondary"
                width="172px"
                onClick={onGoBack}
              />
              <Button
                buttonTitle="Salvar"
                type="primary"
                width="172px"
                disabled={!formState.isValid || !formState.isDirty || readOnly}
                htmlType="submit"
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  )
}
