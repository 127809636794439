import { ChangeEvent, useCallback, useState } from 'react'
import ReactDOM from 'react-dom'
import { useFormContext } from 'react-hook-form'

import { Button, Datepicker } from 'components'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { ReactComponent as CloseButton } from 'assets/svg/close.svg'
import { RadioGroup } from 'components/RadioGroup/RadioGroup'
import { RadioButton } from 'components/RadioButton/RadioButton'

import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import { parseDataToComboboxV2 } from 'utilities/combobox'

import { AggregatedAccount } from 'services/account/types'
import { SortedCustomer } from 'services/customer/types'

import {
  AttendancePolicyFilter,
  ATTENDANCE_POLICY_STATUS,
  COVERAGE_TYPE,
} from '../../types'
import { handleAttendancePolicyStatus, handleCoverageType } from './utilities'

import { useGetCustomers } from 'shared/hooks/customers/useGetCustomers'

import { useGetAccounts } from 'shared/hooks/accounts/useGetAccounts'

import { useGetServiceType } from 'shared/hooks/services/serviceType/useGetServiceType'
import { ServiceType } from 'services/serviceType/types'
import { useGetOccurrenceType } from 'shared/hooks/services/occurrence/useGetOccurrenceType'
import { OccurrenceTypeQueryResponse } from 'services/occurrence/types'

import styles from './Filters.module.scss'

export type FiltersProps = {
  isVisible?: boolean
  onClose?: () => void
  onApplyFilters?: () => void
}

export const Filters = ({
  isVisible,
  onApplyFilters,
  onClose,
}: FiltersProps) => {
  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const { setValue, watch, reset, formState } =
    useFormContext<AttendancePolicyFilter>()

  const [attendancePolicyStatus, setAttendancePolicyStatus] =
    useState<ATTENDANCE_POLICY_STATUS | null>(null)

  const [coverageType, setCoverageType] = useState<COVERAGE_TYPE | null>(null)

  const serviceType = watch('serviceType')
  const occurrenceType = watch('occurrenceType')
  const customer = watch('customer')
  const account = watch('account')
  const createdFrom = watch('createdFrom')
  const createdTo = watch('createdTo')

  const [serviceTypeFilter, setServiceTypeFilter] = useState('')

  const {
    serviceTypes,
    isFetching: isFetchingServiceTypes,
    fetchNextServiceTypesPage,
  } = useGetServiceType(serviceTypeFilter)

  const [occurrenceTypeFilter, setOccurrenceTypeFilter] = useState('')

  const {
    occurrenceTypes,
    isFetching: isFetchingOccurrenceTypes,
    fetchNextOccurrenceTypesPage,
  } = useGetOccurrenceType(occurrenceTypeFilter)

  const [customerFilter, setCustomerFilter] = useState('')

  const {
    customers,
    isFetching: isFetchingCustomers,
    fetchNextCustomersPage,
  } = useGetCustomers(customerFilter)

  const [accountsFilter, setAccountFilter] = useState('')

  const {
    accounts,
    isFetching: isFetchingAccounts,
    fetchNextAccountsPage,
  } = useGetAccounts(true, customer?.id, { name: accountsFilter })

  const handleChangeScheduleDateInput = useCallback(
    (formKey: string, event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        const date = convertStringToDate(value)
        setValue(formKey, date.getTime())
        return value
      } else {
        return value
      }
    },
    [setValue],
  )

  return ReactDOM.createPortal(
    <div
      className={[styles.container, isVisible ? styles.show : styles.hide].join(
        ' ',
      )}
      data-testid="filters-component"
    >
      <div className={styles.innerWrapper}>
        <div className={styles.header}>
          <span>Filtros</span>
          <CloseButton
            height={16}
            width={16}
            onClick={onClose}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.contentWrapper}>
          <Combobox
            id="serviceType"
            label={{ text: 'Tipo de serviço' }}
            value={
              serviceType ? { label: 'name', value: serviceType } : undefined
            }
            items={parseDataToComboboxV2(serviceTypes || [], 'name')}
            onChange={(selected) => {
              const selectedServiceType = selected as ComboboxItem<ServiceType>
              if (selectedServiceType) {
                setValue('serviceType', selectedServiceType?.value, {
                  shouldDirty: true,
                })
              }
            }}
            isLoading={isFetchingServiceTypes}
            onEndReached={fetchNextServiceTypesPage}
            onSearch={(search) => setServiceTypeFilter(search)}
          />

          <Combobox
            id="occurrenceType"
            label={{ text: 'Tipo de ocorrência' }}
            value={
              occurrenceType
                ? { label: 'name', value: occurrenceType }
                : undefined
            }
            items={parseDataToComboboxV2(occurrenceTypes || [], 'name')}
            onChange={(selected) => {
              const selectedOccurrenceType =
                selected as ComboboxItem<OccurrenceTypeQueryResponse>
              setValue('occurrenceType', selectedOccurrenceType?.value, {
                shouldDirty: true,
              })
            }}
            isLoading={isFetchingOccurrenceTypes}
            onEndReached={fetchNextOccurrenceTypesPage}
            onSearch={(search) => setOccurrenceTypeFilter(search)}
          />
          <div>
            <RadioGroup title="Situação">
              {Object.values(ATTENDANCE_POLICY_STATUS).map((option, key) => (
                <RadioButton
                  value={option}
                  onChange={(e) => {
                    const status = handleAttendancePolicyStatus(e.target.value)

                    setAttendancePolicyStatus(status.label)
                    setValue('active', status.value, {
                      shouldDirty: true,
                    })
                  }}
                  checked={attendancePolicyStatus === option}
                  name={'active'}
                  key={key}
                />
              ))}
            </RadioGroup>
          </div>
          <RadioGroup title="Tipo de abrangência">
            {Object.values(COVERAGE_TYPE).map((option, key) => (
              <RadioButton
                value={option}
                onChange={(e) => {
                  const status = handleCoverageType(e.target.value)
                  setValue('hasSelectors', status.value, {
                    shouldDirty: true,
                  })
                  setCoverageType(status.label)
                }}
                checked={coverageType === option}
                name={'hasSelectors'}
                key={key}
              />
            ))}
          </RadioGroup>
          {coverageType === COVERAGE_TYPE.PERSONALIZED && (
            <>
              <Combobox
                id="customer"
                label={{ text: 'Cliente' }}
                value={
                  customer ? { label: 'name', value: customer } : undefined
                }
                items={parseDataToComboboxV2(customers || [], 'name')}
                onChange={(selected) => {
                  const selectedCustomer =
                    selected as ComboboxItem<SortedCustomer>
                  setValue('customer', selectedCustomer?.value, {
                    shouldDirty: true,
                  })
                  setValue('account', undefined)
                  setValue('partition', undefined)
                }}
                isLoading={isFetchingCustomers}
                onEndReached={fetchNextCustomersPage}
                onSearch={(search) => setCustomerFilter(search)}
              />

              <Combobox
                id="accounts"
                label={{ text: 'Conta' }}
                disabled={!customer}
                value={
                  account
                    ? { label: 'aggregatedAccountName', value: account }
                    : undefined
                }
                items={parseDataToComboboxV2(
                  accounts || [],
                  'aggregatedAccountName',
                )}
                onChange={(selected) => {
                  const selectedAccount =
                    selected as ComboboxItem<AggregatedAccount>
                  setValue('account', selectedAccount?.value, {
                    shouldDirty: true,
                  })
                  setValue('partition', undefined)
                }}
                isLoading={isFetchingAccounts}
                onEndReached={fetchNextAccountsPage}
                onSearch={(search) => setAccountFilter(search)}
              />
            </>
          )}
          <div className={styles.datePickerWrapper}>
            <p className={styles.sectionLabel}>Cadastro</p>
            <div className={styles.datePickerInnerWrapper}>
              <span className={styles.dateLabel}>De</span>
              <Datepicker
                id="createdFrom"
                initialValueInput={
                  createdFrom
                    ? convertDateToString(new Date(createdFrom))
                    : undefined
                }
                initialDate={createdFrom ? new Date(createdFrom) : undefined}
                onChangeInput={(event) =>
                  handleChangeScheduleDateInput('createdFrom', event)
                }
                onChangeDate={(date) => {
                  setValue('createdFrom', date?.getTime(), {
                    shouldDirty: true,
                  })
                }}
              />
              <span className={styles.dateLabel}>até</span>
              <Datepicker
                id="createdTo"
                disabled={!createdFrom}
                selectEnd
                initialValueInput={
                  createdTo
                    ? convertDateToString(new Date(createdTo))
                    : undefined
                }
                initialDate={createdFrom ? new Date(createdFrom) : undefined}
                onChangeInput={(event) =>
                  handleChangeScheduleDateInput('createdTo', event)
                }
                onChangeDate={(date) =>
                  setValue('createdTo', date?.getTime(), { shouldDirty: true })
                }
                errorMessage={
                  createdFrom && !createdTo ? 'Campo obrigatório.' : ''
                }
              />
            </div>
          </div>

          <div className={styles.gradient} />
          <div className={styles.fadeout} />
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              clearAllLocalStorageFilters()
              reset({})
              setCoverageType(null)
              setAttendancePolicyStatus(null)
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            disabled={!formState?.isValid}
            onClick={onApplyFilters}
            width="162px"
          />
        </div>
      </div>
    </div>,
    document.getElementById('main-container') || document.body,
  )
}
