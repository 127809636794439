import { ComponentProps } from 'react'

import { ProtectedImage } from './../ProtectedImage/ProtectedImage'
import styles from './styles.module.scss'

const handleNameInitial = (name: string) => {
  const nameParts = name.trim().split(' ')

  if (nameParts.length > 1) {
    return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase()
  }

  return nameParts[0].slice(0, 2).toUpperCase()
}
interface AvatarProps extends ComponentProps<'img'> {
  name?: string
  imageId?: string
}

const Avatar = ({
  imageId,
  src,
  alt,
  name = 'Ubisafe',
  ...props
}: AvatarProps) => {
  const handleRender = () => {
    if (imageId) {
      return <ProtectedImage {...props} alt={alt} imageId={imageId} />
    }

    if (src) {
      return <img src={src} alt={alt} {...props} />
    }

    return handleNameInitial(name)
  }

  return <div className={styles.container}>{handleRender()}</div>
}

export default Avatar
