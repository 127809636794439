import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { parseDataToComboboxV2 } from 'utilities/combobox'
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'

import { BackDrop } from '../../../../components'
import { Button, Checkbox, ComboboxItem } from 'components'
import { Combobox } from 'components/ComboboxV2/Combobox'

import AccountDriver from 'services/account'
import { AggregatedAccount } from 'services/account/types'
import { TacticalStatus } from 'services/displacementMap/types'

import { DisplacementStep } from '../../../../types'

import styles from './Filters.module.scss'
import { occurrenceTypesOptions, statusOptions } from './utils'
import { useFilters, useDisplacementModal } from '../../../../contexts'
import { displacementOptions } from './options'
import {
  useFetchTacticals,
  useGetOccurrencesByUserCoverageType,
} from 'services/displacementMap'
import { useUserInfo } from 'shared/hooks'

interface FormProps {
  account?: string
  agentName?: string
  occurrenceType?: string[]
  status?: TacticalStatus[]
  displacementStatus?: DisplacementStep[]
}

export const Filters = () => {
  const { userInfo } = useUserInfo()

  const userId = userInfo.id

  const { filters, handleFilter, handleClearFilter } = useFilters()
  const { handleCloseModal } = useDisplacementModal()

  const { data: tacticals } = useFetchTacticals(userId, 10000)
  const { data: occurrences } = useGetOccurrencesByUserCoverageType(userId)

  const { setValue, watch, register, handleSubmit } = useForm<FormProps>({
    defaultValues: filters,
  })

  const [searchAccount, setSearchAccount] = useState('')

  const {
    data: accountsOptions,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['fetch-account', searchAccount],
    queryFn: async () => {
      const response = await AccountDriver.queryAggregatedAccounts({
        code: searchAccount,
      })

      return response.data
    },
  })

  useEffect(() => {
    Object.entries(filters).map(([key, value]) => setValue(key, value))
  }, [filters, setValue])

  return (
    <BackDrop onClose={handleCloseModal}>
      <main className={styles.container}>
        <header>
          <h1>Filtros</h1>
          <button type="button" onClick={handleCloseModal}>
            <CloseIcon />
          </button>
        </header>
        <div className={styles.content}>
          <form
            onSubmit={handleSubmit((data) => {
              handleFilter(data)
              handleCloseModal()
            })}
          >
            <div>
              <Combobox
                label={{
                  text: 'Tipo de ocorrência',
                }}
                multiple
                items={occurrenceTypesOptions}
                {...register('occurrenceType')}
                value={watch('occurrenceType')}
                onChange={(selected) => {
                  setValue('occurrenceType', selected)
                }}
              />
              {!!occurrences && (
                <Combobox
                  label={{
                    text: 'Status do deslocamento',
                  }}
                  multiple
                  items={displacementOptions}
                  {...register('displacementStatus')}
                  value={watch('displacementStatus')}
                  onChange={(selected) => {
                    setValue('displacementStatus', selected)
                  }}
                />
              )}
              {!!tacticals && (
                <Combobox
                  label={{
                    text: 'Nome do agente tático',
                  }}
                  multiple
                  items={tacticals?.map((tactical) => tactical.agentName)}
                  {...register('agentName')}
                  value={watch('agentName')}
                  onChange={(selected) => {
                    setValue('agentName', selected)
                  }}
                />
              )}
              <div className={styles.checkboxGroup}>
                <span>Status de comunicação da viatura</span>
                {statusOptions.map((option) => {
                  const isChecked = watch('status')?.includes(option.value)

                  return (
                    <Checkbox
                      checked={!!isChecked}
                      key={option.value}
                      id={option.value}
                      {...register('status')}
                      label={option.label}
                      onChange={() => {
                        if (isChecked) {
                          const newValue = watch('status')?.filter(
                            (status) => status !== option.value,
                          )

                          setValue('status', newValue)
                        } else {
                          const newValue = [...watch('status'), option.value]

                          setValue('status', newValue)
                        }
                      }}
                    />
                  )
                })}
              </div>
              <Combobox
                label={{
                  text: 'Conta',
                }}
                onSearch={setSearchAccount}
                isLoading={isLoading}
                isError={isError}
                {...register('account')}
                items={parseDataToComboboxV2(
                  accountsOptions || [],
                  'aggregatedAccountName',
                )}
                onChange={(selected) => {
                  const selectedAccount =
                    selected as ComboboxItem<AggregatedAccount>

                  setValue('account', selectedAccount?.value.id)
                }}
              />
            </div>
            <div className={styles.actions}>
              <Button
                type="secondary"
                buttonTitle="Limpar filtros"
                onClick={handleClearFilter}
                width="172px"
              />
              <Button
                type="primary"
                buttonTitle="Aplicar Filtros"
                htmlType="submit"
                width="172px"
              />
            </div>
          </form>
        </div>
      </main>
    </BackDrop>
  )
}
