import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
  FC,
} from 'react'

import { ModalData, ModalProps, Modals } from '../types'

interface Provider {
  children: ReactNode
}

interface ModalsContextProps {
  modal: Modals | null
  data: ModalProps
  handleCloseModal: () => void
  handleOpenModal: (modal: Modals, data?: ModalData[Modals]) => void
}

const defaultValue: ModalsContextProps = {
  data: {},
  modal: null,
  handleOpenModal: () => null,
  handleCloseModal: () => null,
}

export const ModalsContext = createContext<ModalsContextProps>(defaultValue)

export function useModal(): ModalsContextProps {
  const context = useContext(ModalsContext)

  if (!context)
    throw new Error(
      'You need to use AttendanceModalsContext within a AttendanceModalsProvider',
    )

  return context
}

export const ModalsProvider: FC<Provider> = ({ children }) => {
  const [modal, setModal] = useState<Modals | null>(null)
  const [data, setData] = useState<ModalProps>({})

  const handleOpenModal = useCallback(
    (modal: Modals, data?: ModalData[Modals]) => {
      setModal(modal)
      setData((prev) => ({ ...prev, [modal]: data }))
    },
    [],
  )

  const handleCloseModal = useCallback(() => {
    setModal(null)
    setData({})
  }, [])

  return (
    <ModalsContext.Provider
      value={{
        data,
        modal,
        handleOpenModal,
        handleCloseModal,
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}
