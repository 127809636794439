import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Combobox } from 'components/ComboboxV2/Combobox'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { Button, Datepicker, Loader, Modal, RadioGroup } from 'components'
import { usePostAndHandleSentAnnulDeviceCommand } from '../../hooks/services/commands'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import {
  aggregatesDateTimeAndReturnMilliseconds,
  generateHoursAndMinutesForHiddenZoneTo,
} from '../../utilities/time/time'

import styles from './DeviceEnabled.module.scss'
import { maskedInputDate } from 'utilities/date'
import { useUserInfo } from 'shared/hooks'

type DeviceEnabledProps = {
  isVisible: boolean
  onClose: () => void
  device: {
    id: string
    hiddenZone: boolean
    hiddenZoneTo?: number
    hasCommandSupport: boolean
  }
  centralId: string
}

export const DeviceEnabled = ({
  isVisible,
  device,
  onClose,
  centralId,
}: DeviceEnabledProps) => {
  const { userInfo } = useUserInfo()
  const isAnnuled = useMemo(
    () => device.hiddenZone || Boolean(device.hiddenZoneTo),
    [device.hiddenZone, device.hiddenZoneTo],
  )

  const onNextDesarm = 'No próximo desarme.'
  const [checked, setIsChecked] = useState('hiddenZoneTo')

  const [dateForHiddenZoneTo, setDateForHiddenZoneTo] = useState<
    Date | undefined
  >(new Date())
  const [dateForHiddenZoneToInput, setDateForHiddenZoneToInput] =
    useState<string>()

  const [hoursAndMinutesForHiddenZoneTo, setHoursAndMinutesForHiddenZoneTo] =
    useState<string>()

  const { annulDeviceCommandMutationStatus, mutateAnnulDeviceCommand } =
    usePostAndHandleSentAnnulDeviceCommand(isAnnuled)

  const onHandleCentralAnnulDevice = useCallback(() => {
    const hiddenZoneTo = aggregatesDateTimeAndReturnMilliseconds(
      dateForHiddenZoneTo || new Date(),
      hoursAndMinutesForHiddenZoneTo || '',
    )

    const payload = {
      centralId,
      userId: userInfo.id,
      deviceId: device.id,
      annuled: !isAnnuled,
      hiddenZoneTo: checked === 'hiddenZoneTo' ? hiddenZoneTo : undefined,
      hasCommandSupport: device.hasCommandSupport,
    }

    mutateAnnulDeviceCommand(payload)
    onClose()
  }, [
    dateForHiddenZoneTo,
    hoursAndMinutesForHiddenZoneTo,
    centralId,
    device.id,
    device.hasCommandSupport,
    isAnnuled,
    checked,
    mutateAnnulDeviceCommand,
    onClose,
  ])

  const handleChangeDateInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        setDateForHiddenZoneTo(convertStringToDate(value))
      } else {
        setDateForHiddenZoneTo(undefined)
      }
      setDateForHiddenZoneToInput(maskedInputDate(value))
    },
    [],
  )

  const handleChangeDate = useCallback((value: Date) => {
    setDateForHiddenZoneTo(value)
    setDateForHiddenZoneToInput(convertDateToString(value))
  }, [])

  useEffect(() => {
    return () => {
      setDateForHiddenZoneTo(undefined)
      setDateForHiddenZoneToInput('')
      setHoursAndMinutesForHiddenZoneTo('')
    }
  }, [isVisible])

  return (
    <div>
      <Loader isVisible={annulDeviceCommandMutationStatus === 'pending'} />
      <Modal
        title={isAnnuled ? 'Reativar equipamento' : 'Anular equipamento'}
        isVisible={isVisible}
        onClose={onClose}
        simple={isAnnuled}
      >
        {isAnnuled ? (
          <p className={styles.isAnnuled}>
            Você tem certeza que deseja reativar este equipamento?
          </p>
        ) : (
          <div className={styles.isEnabled}>
            <RadioGroup
              title="Defina quando o equipamento será reativado:"
              flexDirection="column"
            >
              <div className={styles.inputsRadio}>
                <RadioButton
                  checked={checked === 'hiddenZoneTo'}
                  onChange={() => setIsChecked('hiddenZoneTo')}
                  value={'Em'}
                  name="hiddenTo"
                />
                <div className={styles.datepickerWrapper}>
                  <Datepicker
                    id="hiddenZoneDate"
                    disabled={checked === onNextDesarm}
                    initialDate={new Date()}
                    initialValueInput={dateForHiddenZoneToInput}
                    onChangeInput={handleChangeDateInput}
                    onChangeDate={handleChangeDate}
                    selectEnd
                  />
                </div>
                <p>às</p>
                <div className={styles.hourWrapper}>
                  <Combobox
                    id="hour-input"
                    items={
                      dateForHiddenZoneTo
                        ? generateHoursAndMinutesForHiddenZoneTo(
                            dateForHiddenZoneTo,
                          )
                        : []
                    }
                    value={hoursAndMinutesForHiddenZoneTo}
                    onChange={(e) =>
                      e ? setHoursAndMinutesForHiddenZoneTo(e.toString()) : null
                    }
                    disabled={checked === onNextDesarm}
                    placeholder="--:--"
                  />
                </div>
              </div>
              <RadioButton
                checked={checked === onNextDesarm}
                onChange={(e) => setIsChecked(e.target.value)}
                value={onNextDesarm}
                name="hiddenTo"
              />
            </RadioGroup>
          </div>
        )}
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle={isAnnuled ? 'Cancelar' : 'Voltar'}
            type="secondary"
            onClick={onClose}
          />
          <Button
            width="172px"
            buttonTitle={isAnnuled ? 'Continuar' : 'Salvar'}
            type="primary"
            disabled={
              isAnnuled
                ? false
                : (!dateForHiddenZoneTo || !hoursAndMinutesForHiddenZoneTo) &&
                  checked !== onNextDesarm
            }
            onClick={onHandleCentralAnnulDevice}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
