import { useMutation } from '@tanstack/react-query'

import CommandService from 'services/command'
import { ConfigureSirenCommandPayload } from 'services/command/types'

async function sendConfigureSirenCommand(
  centralId: string,
  payload: ConfigureSirenCommandPayload,
) {
  await CommandService.configureSiren(centralId, payload)
}

export const usePostConfigureSirenCommand = (
  centralId: string,
  payload: ConfigureSirenCommandPayload,
) => {
  const {
    mutate: mutateConfigureSirenCommand,
    status: configureSirenCommandMutationStatus,
  } = useMutation({
    mutationFn: () => sendConfigureSirenCommand(centralId, payload),
  })

  return {
    mutateConfigureSirenCommand,
    configureSirenCommandMutationStatus,
  }
}
