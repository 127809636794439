import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { DeviceModelOutput, DeviceModelQuery } from './types'

import { useInfiniteQuery } from '@tanstack/react-query'

import { Result } from 'services/types'

interface useInfiniteDeviceModel {
  filter?: { name?: string }
  query?: DeviceModelQuery
}

const getDeviceModels = async (
  offset = 0,
  query?: DeviceModelQuery,
  filter?: { name?: string },
) => {
  const response = await HttpClient.get<Result<DeviceModelOutput>>(
    endpoints.query,
    {
      params: {
        recordsPerPage: 15,
        offset,
        ...(filter && filter),
        ...query,
      },
    },
  )

  return response.data
}

export const useInfiniteDeviceModel = ({
  filter,
  query,
}: useInfiniteDeviceModel) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['models', query, filter],
    queryFn: async ({ pageParam }) =>
      await getDeviceModels(pageParam, query, filter),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<DeviceModelOutput>(lastPage, allPages),

    enabled: !!query?.manufacturerId,
  })

  return { ...infiniteQuery, data: flattenPages(infiniteQuery.data) }
}
