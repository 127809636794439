import { Combobox } from 'components/ComboboxV2/Combobox'
import { ActionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'

import {
  TranslatedPhoneCallTarget,
  convertPhoneCallTarget,
  translatedPhoneCallTarget,
} from 'domains/attendancePolicy/components/RuleForm/utilities/action/utilities'

import styles from './RequiredPhoneCall.module.scss'
import { ActionType } from 'services/attendancePolicy/types'

export const RequiredPhoneCall = ({ onChange, data }: ActionOptionForm) => {
  const { parameters } = data

  return (
    <div className={styles.container}>
      <Combobox
        id={`phoneCallTarget-${data.index}`}
        value={
          parameters?.phoneCallTarget
            ? translatedPhoneCallTarget[parameters.phoneCallTarget]
            : undefined
        }
        label={{
          text: 'Ligar para',
        }}
        items={Object.values(translatedPhoneCallTarget)}
        onChange={(selected) => {
          const selectedCallTarget = selected as TranslatedPhoneCallTarget

          onChange({
            type: ActionType.REQUIRE_PHONE_CALL,
            parameters: {
              phoneCallTarget: convertPhoneCallTarget[selectedCallTarget],
            },
          })
        }}
        placeholder="Selecione um tipo de contato"
      />
    </div>
  )
}
