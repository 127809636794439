import { Tag } from 'components/Tags/types'
import { ViewEntityContent } from 'components/ViewEntities/types'

import {
  CoverageHolidayEntities,
  HolidayCoverageType,
  HolidayQueryResponse,
} from 'services/holiday/types'
import { formatDecimal } from 'utilities/date'

import { v4 as uuid } from 'uuid'

export const getHolidayCoverageTags = (
  holiday: HolidayQueryResponse,
): Tag[] => {
  const { coverageType, coverage } = holiday

  if (coverageType === 'NATIONAL') {
    return [{ id: uuid(), name: 'Nacional' }]
  }

  return [
    ...((coverage?.states.length && [{ id: uuid(), name: 'Estadual' }]) || []),
    ...((coverage?.cities.length && [{ id: uuid(), name: 'Municipal' }]) || []),
  ]
}

export const convertHolidayCoverageType: Record<string, HolidayCoverageType> = {
  Nacional: 'NATIONAL',
  Regional: 'REGIONAL',
}

export const translatedHolidayCoverageType: Record<
  HolidayCoverageType,
  string
> = {
  NATIONAL: 'Nacional',
  REGIONAL: 'Regional',
}

export const getHolidayRepeatableTerm = (
  repeatable: boolean | undefined,
): string => {
  if (repeatable === undefined) return ''

  return repeatable ? 'Móvel' : 'Fixo'
}

export const formatDate = (
  day: number,
  month: number,
  year?: number,
): string => {
  const date = []

  date.push(formatDecimal(day))

  date.push(formatDecimal(month))

  if (year) {
    date.push(year.toString())
  }

  return date.join('/')
}

export const parseCoverageInfoToEntityFormat = (
  coverage?: CoverageHolidayEntities,
): ViewEntityContent[] => {
  if (!coverage)
    return [
      {
        id: '',
        title: '',
        subtitle: '',
      },
    ]

  const entities: ViewEntityContent[] = []

  if (coverage?.states) {
    coverage.states?.map((coverageState) => {
      entities.push({
        id: coverageState?.id || '',
        title: coverageState?.name || '',
        subtitle: 'Estado',
        formKey: 'states',
      })
    })
  }

  if (coverage?.cities) {
    coverage.cities.map((coverageCity) => {
      return entities.push({
        id: coverageCity?.id || '',
        title: `${coverageCity?.name} - ${coverageCity?.acronym}` || '',
        subtitle: 'Cidade',
        formKey: 'cities',
      })
    })
  }

  return entities
}
