import {
  ComponentProps,
  ReactElement,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import styles from './styles.module.scss'
import { Icon } from 'components'

interface Option {
  icon: ReactElement
  label: string
  value: boolean
}

const options: Option[] = [
  {
    label: 'Nota interna',
    value: true,
    icon: <Icon name="hide" />,
  },
  {
    label: 'Nota pública',
    value: false,
    icon: <Icon name="view" />,
  },
]

interface TagComboboxProps
  extends Omit<ComponentProps<'button'>, 'value' | 'onSelect'> {
  value?: Option['value']
  onSelect: (option: Option) => void
}

const TagComboBox = forwardRef<HTMLButtonElement, TagComboboxProps>(
  ({ value, onSelect, ...props }, _ref) => {
    const [isVisible, setIsVisible] = useState(false)

    const handleValue = () => {
      if (value) {
        return options.filter((option) => option.value === value)[0]
      }

      return options[0]
    }

    const [selectedOption, setSelectedOption] = useState<Option>(handleValue())

    const handleSelectOption = (option: Option) => {
      onSelect?.(option)
      setIsVisible(false)
      setSelectedOption(option)
    }

    useEffect(() => {
      if (value !== selectedOption.value) {
        setSelectedOption(handleValue())
      }
    }, [value])

    return (
      <div className={styles.tagComboBox}>
        <button
          {...props}
          type="button"
          onClick={() => setIsVisible((prev) => !prev)}
          className={isVisible ? styles.active : ''}
        >
          {selectedOption.icon}
          {selectedOption.label}
          <Icon name="chevron-sm-down" width={10} color="gray" />
        </button>
        {!!isVisible && (
          <ul>
            {options
              .filter((option) => option.value !== selectedOption.value)
              .map((option) => (
                <li
                  key={option.label}
                  onClick={() => handleSelectOption(option)}
                >
                  {option.icon}
                  {option.label}
                </li>
              ))}
          </ul>
        )}
      </div>
    )
  },
)

TagComboBox.displayName = 'TagComboBox'

export default TagComboBox
