import { Icon } from 'components'
import styles from './styles.module.scss'

const Alert = () => (
  <div className={styles.container}>
    <Icon name="information" />
    <span>
      Não é possível desvincular um contato de uma conta da qual ele possua um
      controle ou faça parte de um plano de ação.
    </span>
  </div>
)

export default Alert
