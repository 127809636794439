export const formatLicensePlate = (value: string): string => {
  const mask = [
    /[A-Z]/,
    /[A-Z]/,
    /[A-Z]/,
    /[A-Z0-9]/,
    /[A-Z0-9]/,
    /[A-Z0-9]/,
    /[A-Z0-9]/,
  ]
  let formattedValue = ''

  for (let i = 0; i < Math.min(value.length, mask.length); i += 1) {
    if (!mask[i].test(value[i])) {
      break
    }
    formattedValue += value[i]
  }

  if (formattedValue.length > 3) {
    return formattedValue.substring(0, 3) + '-' + formattedValue.substring(3)
  }

  return formattedValue
}
