import { Combobox } from 'components/ComboboxV2/Combobox'

import {
  OccurrenceStateName,
  convertConditionOccurrenceState,
  translatedOccurrenceState,
  conditionOccurrenceStateName,
} from '../../RuleForm/data/occurrenceStateName'

import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import { ConditionType } from 'services/attendancePolicy/types'

export const OccurrenceStateForm = ({
  onChange,
  data,
}: ConditionOptionForm) => {
  const comboboxLabel = 'Ocorrência no estado'

  const { facts } = data

  return (
    <Combobox
      value={
        facts?.occurrenceStateNames
          ? facts.occurrenceStateNames.map(
              (state) => translatedOccurrenceState[state],
            )
          : undefined
      }
      label={{
        text: comboboxLabel,
      }}
      items={Object.values(conditionOccurrenceStateName).map(
        (stateName) => translatedOccurrenceState[stateName],
      )}
      onChange={(selected) => {
        const selectedOccurrenceState = selected as OccurrenceStateName[]

        onChange?.({
          type: ConditionType.OCCURRENCE_STATE_NAMES,
          facts: {
            occurrenceStateNames: selectedOccurrenceState.map(
              (state) => convertConditionOccurrenceState[state],
            ),
          },
        })
      }}
      placeholder="Selecione um ou mais estados"
      multiple
    />
  )
}
