import { ReactElement } from 'react'
import { RadioButtonProps } from 'components/RadioButton/RadioButton'
import styles from './RadioGroup.module.scss'

type RadioGroupProps = {
  title: string
  children: ReactElement<RadioButtonProps>[]
  flexDirection?: string
  customStyle?: string
}

export const RadioGroup = ({
  title,
  children,
  flexDirection = 'row',
  customStyle,
}: RadioGroupProps) => {
  return (
    <fieldset
      className={[
        styles.fieldset,
        flexDirection && styles[flexDirection],
        customStyle,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      <legend className={[styles.text, styles.legend].join(' ')}>
        {title}
      </legend>
      {children}
    </fieldset>
  )
}
