import {
  createMasker,
  MaskArgs,
} from 'utilities/masks/createMasker/createMasker'
import { PhoneType } from '../../../services/contact/types'

const formatPhoneTypeComercial = (value: string): string => {
  let comercialWithMask = ''
  const newValue = value.replace(/\D/g, '')
  const inputLength = newValue.length

  switch (true) {
    case inputLength >= 2 && inputLength <= 6:
      comercialWithMask = newValue.replace(/(\d{2})(\d{1,})/g, '($1) $2')
      break
    case inputLength >= 7 && inputLength <= 11:
      comercialWithMask = newValue.replace(
        /(\d{2})(\d{4})(\d{1,})/g,
        '($1) $2-$3',
      )
      break
    default:
      comercialWithMask = newValue.replace(
        /(\d{2})(\d{4})(\d{4})/g,
        '($1) $2-$3',
      )
      break
  }
  return comercialWithMask
}

const formatPhoneTypeCellPhone = (value: string): string => {
  let comercialWithMask = ''
  const newValue = value.replace(/\D/g, '')
  const inputLength = newValue.length

  switch (true) {
    case inputLength >= 2 && inputLength <= 7:
      comercialWithMask = newValue.replace(/(\d{2})(\d{1,})/g, '($1) $2')
      break
    case inputLength >= 8 && inputLength <= 12:
      comercialWithMask = newValue.replace(
        /(\d{2})(\d{5})(\d{1,})/g,
        '($1) $2-$3',
      )
      break
    default:
      comercialWithMask = newValue.replace(
        /(\d{2})(\d{5})(\d{4})/g,
        '($1) $2-$3',
      )
      break
  }
  return comercialWithMask
}

export const formatPhone = (type: PhoneType, value: string): string => {
  if (type === PhoneType.CellPhone) {
    return formatPhoneTypeCellPhone(value)
  }

  return formatPhoneTypeComercial(value)
}

export const getPhoneEnumValue = (value: string): PhoneType => {
  switch (value) {
    case 'Comercial':
      return PhoneType.Comercial
    case 'Residencial':
      return PhoneType.Residential
    default:
      return PhoneType.CellPhone
  }
}

export const phoneMaskerArgs: MaskArgs[] = [
  { mask: '', regex: /\D/g },
  {
    mask: '($1) $2.$3',
    regex: /(\d{2})(\d{4,5})(\d{4})/g,
  },
  {
    mask: '($1) $2',
    regex: /(\d{2})(\d{3})$/g,
  },
  {
    mask: '$1',
    regex: /(^\d{3}$)/g,
  },
]

export const phoneMasker = createMasker(phoneMaskerArgs)
