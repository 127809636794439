import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  ReactNode,
  useLayoutEffect,
} from 'react'

import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { Button, EmptyState, Loader, Modal } from 'components'
import { EquipmentCard } from 'domains/customer/screens/Equipments/components/EquipmentCard/EquipmentCard'

import { EquipmentCardAttributes } from 'domains/customer/screens/Equipments/types'

import styles from './Equipments.module.scss'

import { DeviceType, PartitionResponse } from 'services/partition/types'

import { parseDataToComboboxV2 } from 'utilities/combobox'
import { RemoteControlCard } from 'domains/customer/screens/Equipments/components/RemoteControlCard/RemoteControlCard'

import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'

import { deviceInitialState } from 'domains/customer/screens/Equipments/data/equipmentInitialState'
import { PhotosensorCard } from 'domains/customer/screens/Equipments/components/PhotosensorCard/PhotosensorCard'

import { ReedSwitchCard } from 'domains/customer/screens/Equipments/components/ReedSwitchCard/ReedSwitchCard'
import { SirenCard } from 'domains/customer/screens/Equipments/components/SirenCard/SirenCard'
import { useGetAccounts } from 'shared/hooks/accounts/useGetAccounts'

import { AggregatedAccount } from 'services/account/types'
import { useGetPartitions } from 'shared/hooks/partitions/useGetPartitions'
import { CENTRAL_MODE } from 'services/central/types'
import { useGetEquipments } from 'domains/customer/screens/Equipments/hooks/services/device/useGetEquipments'

import { useGetPatrimonyFragment, useToggle } from 'shared/hooks'

import { PatrimonyFragment } from 'services/patrimony/types'
import { useGetPatrimony } from 'services/patrimony/hooks/useGetPatrimony'
import { PartitionDeviceAggregatedResponse } from 'services/device/types'

import {
  CreateDevice,
  UpdateDevice,
} from 'domains/customer/screens/Equipments/containers'
import { useQueryCentralByAccounts } from 'services/central'
import { DeviceCardHeader } from 'domains/customer/screens/Equipments/components/DeviceCardHeader/DeviceCardHeader'
import { useLocation, useNavigate } from 'react-router-dom'

export const Equipments = () => {
  const [selectedPatrimony, setSelectedPatrimony] =
    useState<PatrimonyFragment>()
  const [selectedAccount, setSelectedAccount] = useState<
    AggregatedAccount | undefined
  >({
    id: localStorage.getItem('accountId') || '',
    aggregatedAccountName: localStorage.getItem('aggregatedAccountName') || '',
  })
  const [selectedPartition, setSelectedPartition] =
    useState<PartitionResponse>()
  const [selectedDevice, setSelectedDevice] =
    useState<PartitionDeviceAggregatedResponse>(deviceInitialState)

  const noCentralModal = useToggle()

  const navigate = useNavigate()
  const location = useLocation()

  const [selectedDeviceType, setSelectedDeviceType] =
    useState<DeviceType | null>(null)

  const customerId = localStorage.getItem('customerId') ?? ''
  const patrimonyId = localStorage.getItem('patrimonyId') ?? ''
  const accountId = localStorage.getItem('accountId') ?? ''

  const MAX_RECORDS_PER_PAGE = 100

  const { data: patrimonyFindById } = useGetPatrimony(
    selectedPatrimony?.id || patrimonyId || '',
  )

  const [patrimonyFragmentName, setPatrimonyFragmentName] = useState('')
  const {
    data: patrimonyFragment,
    isError: isErrorPatrimonyFragment,
    isFetching: isFetchingPatrimonyFragment,
    fetchNextPage: fetchNextPatrimonyPage,
  } = useGetPatrimonyFragment(patrimonyFragmentName)

  const [accountFilter, setAccountFilter] = useState('')
  const {
    accounts,
    fetchNextAccountsPage,
    isError: isAccountsError,
    isFetching: isAccountsFetching,
  } = useGetAccounts(
    !!selectedPatrimony?.id || !!patrimonyId,
    customerId,
    {
      name: accountFilter,
    },
    MAX_RECORDS_PER_PAGE,
    selectedPatrimony?.id || patrimonyId,
  )

  const viewAllPartitions = {
    id: '',
    code: 'Todas',
  }

  const [partitionFilter, setPartitionFilter] = useState('')
  const { partitions: partitionList } = useGetPartitions(
    selectedAccount?.id || accountId,
    partitionFilter,
    !!selectedAccount || !!accountId,
  )

  const { data: centrals } = useQueryCentralByAccounts([accountId])

  const {
    data: equipments,
    isLoading,
    refetch,
  } = useGetEquipments(
    selectedPartition?.id || '',
    selectedAccount?.central?.id || centrals?.data[0]?.id || '',
    patrimonyFindById?.id || '',
    !!selectedAccount?.central?.id || !!centrals?.data[0]?.id,
  )

  const handleEquipmentSelection = (equipment: EquipmentCardAttributes) => {
    if (equipment.deviceTypeCode !== DeviceType.Central) {
      const selected = equipments?.devices.find(
        (device) => equipment.id === device.id,
      )
      if (selected) {
        setSelectedDevice(selected)

        navigate(`equipments?deviceId=${selected.id}`)
      }
    }
    setSelectedDeviceType(equipment.deviceTypeCode)
  }

  const hasAnyCentral = useMemo(
    () => accounts?.some((accounts) => !!accounts.central?.id),
    [accounts],
  )

  const normalizedEquipments = useMemo(() => {
    if (!equipments) return []

    let normalizedEquipments: EquipmentCardAttributes[] = []

    if (equipments.devices.length) {
      normalizedEquipments = equipments.devices
        .sort((prev, next) => Number(prev.code) - Number(next.code))
        .map((device) => ({
          id: device?.id,
          deviceTypeCode: device?.typeCode,
          hasCommandSupport: device.hasCommandSupport,
          manufacturer: device.manufacturer,
          name: device?.name,
          code: device?.code,
          contact: device?.contact,
          deviceCommunicationLevel: device?.communication?.level,
          partitions: device?.partitions,
          batteryAlarmed: device?.battery?.isLow,
          deviceCommunicationStatus: device?.communication?.status,
          tamper: device?.isTamperOpened,
          lastConnectionTimestamp: device?.lastConnectionTimestamp,
          hiddenZone: device?.hiddenZone,
          hiddenZoneTo: device?.hiddenZoneTo || undefined,
          deviceType: device?.deviceType,
          profiles: device?.profiles,
        }))
    }
    return normalizedEquipments
  }, [equipments])

  const handleRenderList = useCallback(() => {
    if (equipments?.devices.length) return 'view'

    return 'empty'
  }, [equipments])

  const handleRightSection = () => {
    const searchParams = new URLSearchParams(location.search)

    const deviceId = searchParams.get('deviceId')
    const createDevice = searchParams.get('create')

    if (!!deviceId && !selectedDevice.hasCommandSupport) return 'editDevice'

    if (!!deviceId && selectedDevice.hasCommandSupport) return 'equipmentCard'

    if (createDevice) return 'createDevice'

    return 'empty'
  }

  const handleCards = () => {
    if (equipments) {
      const options: Record<DeviceType, ReactNode> = {
        [DeviceType.RemoteControl]: (
          <RemoteControlCard
            remoteControl={selectedDevice}
            central={{
              id: equipments.central.id,
              mode: equipments.central.mode || CENTRAL_MODE.DEFAULT,
            }}
          />
        ),
        [DeviceType.PassiveInfraPhotoRedSensor]: (
          <PhotosensorCard
            photosensor={selectedDevice}
            central={{
              id: equipments.central.id,
              mode: equipments.central.mode || CENTRAL_MODE.DEFAULT,
            }}
            partitionId={selectedPartition?.id || ''}
          />
        ),
        [DeviceType.ReedSwitch]: (
          <ReedSwitchCard
            reedswitch={selectedDevice}
            central={{
              id: equipments.central.id,
              mode: equipments.central.mode || CENTRAL_MODE.DEFAULT,
            }}
          />
        ),
        [DeviceType.WirelessSiren]: (
          <SirenCard
            siren={selectedDevice}
            central={{
              id: equipments.central.id,
              mode: equipments.central.mode || CENTRAL_MODE.DEFAULT,
            }}
          />
        ),
        [DeviceType.Central]: null,
        [DeviceType.PassiveInfraRedSensor]: null,
      }

      return (
        selectedDeviceType && <section>{options[selectedDeviceType]}</section>
      )
    }
  }

  useEffect(() => {
    const selectedDeviceUpdated = equipments?.devices.find(
      (device) => device.id === selectedDevice.id,
    )

    if (selectedDeviceUpdated) setSelectedDevice(selectedDeviceUpdated)
  }, [equipments, selectedDevice.id])

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const deviceId = searchParams.get('deviceId')

    if (deviceId && !selectedDevice?.id) {
      const pathDevice = equipments?.devices.find(
        (device) => device.id === deviceId,
      )

      if (pathDevice) setSelectedDevice(pathDevice)
    }
  }, [equipments, selectedDevice]) //eslint-disable-line

  return (
    <>
      <Loader isVisible={isLoading} />
      <Modal
        simple
        title="Você não possui centrais cadastradas"
        isVisible={noCentralModal.isVisible}
        onClose={() => noCentralModal.hide()}
      >
        <p className={styles.errorModalText}>
          Não é possível adicionar um novo equipamento sem ter uma central
          cadastrada.
        </p>
        <Modal.Footer>
          <Button
            autofocus
            width="172px"
            buttonTitle="Voltar"
            onClick={() => noCentralModal.hide()}
            type="primary"
          />
        </Modal.Footer>
      </Modal>

      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.comboboxWrapper}>
            <Combobox
              label={{
                text: 'Patrimônio',
              }}
              value={
                selectedPatrimony
                  ? {
                      label: 'name',
                      value: selectedPatrimony,
                    }
                  : patrimonyFindById?.name || undefined
              }
              isError={isErrorPatrimonyFragment}
              onEndReached={fetchNextPatrimonyPage}
              onSearch={(search) => setPatrimonyFragmentName(search)}
              isLoading={isFetchingPatrimonyFragment}
              onChange={(selected) => {
                const fragment =
                  selected as unknown as ComboboxItem<PatrimonyFragment>
                setSelectedPatrimony(fragment?.value)

                setSelectedAccount(undefined)
                setSelectedPartition(undefined)

                localStorage.removeItem('accountId')
                localStorage.removeItem('aggregatedAccountName')

                navigate({ search: '' })
              }}
              items={parseDataToComboboxV2(patrimonyFragment || [], 'name')}
            />
          </div>
          <div className={styles.comboboxWrapper}>
            <Combobox
              id="account"
              label={{
                text: 'Conta',
              }}
              value={
                selectedAccount
                  ? {
                      label: 'aggregatedAccountName',
                      value: selectedAccount,
                    }
                  : localStorage.getItem('aggregatedAccountName') || ''
              }
              onSearch={(text) => {
                if (text === '') {
                  setSelectedAccount(undefined)
                }

                setAccountFilter(text)
              }}
              items={parseDataToComboboxV2(
                accounts || [],
                'aggregatedAccountName',
              )}
              onChange={(selected) => {
                const accountSelected =
                  selected as ComboboxItem<AggregatedAccount>

                setSelectedAccount(accountSelected.value)
                setSelectedPartition(undefined)
                navigate({ search: '' })
              }}
              isError={isAccountsError}
              isLoading={isAccountsFetching}
              onEndReached={fetchNextAccountsPage}
            />
          </div>

          <div className={styles.comboboxWrapper}>
            <Combobox
              id="partition"
              label={{
                text: 'Partição',
              }}
              value={
                selectedPartition
                  ? {
                      label: 'code',
                      value: selectedPartition,
                    }
                  : ''
              }
              onSearch={(text) => {
                if (text === '') {
                  setSelectedPartition(undefined)
                }

                setPartitionFilter(text)
              }}
              items={parseDataToComboboxV2(
                [viewAllPartitions, ...(partitionList || [])] || [],
                'code',
              )}
              onChange={(selected) => {
                const partition = selected as ComboboxItem<
                  Partial<PartitionResponse>
                >

                setSelectedPartition(partition.value)
                navigate({ search: '' })
              }}
              onEndReached={fetchNextAccountsPage}
              disabled={!selectedAccount}
            />
          </div>

          <Button
            buttonTitle="Pesquisar"
            type="primary"
            disabled={!selectedAccount}
            onClick={() => {
              refetch()
              navigate({ search: '' })
            }}
          />

          <Button
            buttonTitle="Novo Equipamento"
            type="secondary"
            icon={PlusIcon}
            onClick={() => {
              if (!hasAnyCentral) {
                noCentralModal.show()
                return
              }
              setSelectedDeviceType(null)
              navigate(`equipments?create=true`)
            }}
          />
        </div>
        <div className={styles.contentWrapper}>
          {
            {
              view: !!equipments && (
                <ul className={styles.content}>
                  {normalizedEquipments.map((equipment) => (
                    <EquipmentCard
                      key={equipment.id}
                      onPress={handleEquipmentSelection}
                      equipment={equipment}
                    />
                  ))}
                </ul>
              ),
              empty: (
                <section>
                  <div className={styles.emptyStateWrapper}>
                    <EmptyState type="EmptyDataFromBFF" />
                  </div>
                </section>
              ),
            }[handleRenderList()]
          }
          {
            {
              createDevice: (
                <section className={styles.formSection}>
                  <div className={styles.headerWrapper}>
                    <h2>NOVO EQUIPAMENTO</h2>
                  </div>
                  <CreateDevice />
                </section>
              ),
              editDevice: (
                <section className={styles.formSection}>
                  <div className={styles.headerWrapper}>
                    <DeviceCardHeader
                      deviceId={selectedDevice.id}
                      centralId={
                        selectedAccount?.central?.id || centrals?.data[0]?.id
                      }
                      title={`${selectedDevice.code} - ${selectedDevice.name}`}
                      hiddenZone={selectedDevice.hiddenZone}
                      hiddenZoneTo={selectedDevice.hiddenZoneTo}
                      tamper={false}
                      hasCommandSupport={false}
                    />
                  </div>
                  <UpdateDevice />
                </section>
              ),
              equipmentCard: handleCards(),
              empty: <></>,
            }[handleRightSection()]
          }
        </div>
      </div>
    </>
  )
}
