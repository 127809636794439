import { FormattedInterventionTag } from 'domains/occurrence/data/interventionTag'
import { CustomerPaths } from 'routes/constants/paths/customer'
import { OccurrenceHistory } from '../../../types'
import {
  DisplacementStep,
  HistoryEvent,
  HistoryIntervention,
  InterventionTypeNames,
  OccurrenceOnAttendanceHistory,
  OccurrenceTypes,
} from 'services/attendance/types'
import {
  AggregatedOccurrenceResponse,
  InterventionStateEnum,
  InterventionTypeEnum,
  OccurrenceStateName,
} from 'services/occurrence/types'

export type HistoryItems =
  | { type: 'EVENT'; value: HistoryEvent }
  | {
      type: 'INTERVENTION' | 'STATE_CHANGE' | 'AUTOMATIC_RULE'
      value: HistoryIntervention
    }

export const handleHistoryItems = (
  history: OccurrenceOnAttendanceHistory,
): HistoryItems[] => {
  const items: HistoryItems[] = []

  history.events?.forEach((event) => {
    items.push({
      type: 'EVENT',
      value: event,
    })
  })

  history.interventions?.forEach((intervention) => {
    const automaticRules: InterventionTypeNames[] = [
      'REQUIRE_PHONE_CALL',
      'PRIORITY_CHANGE',
      'TITLE_CHANGE',
      'SEND_OCCURRENCE_CUSTOM_EVENT',
      'SEND_SOUND_NOTIFICATION',
      'TAG_INCLUSION',
    ]

    if (automaticRules.includes(intervention.typeName)) {
      return items.push({
        type: 'AUTOMATIC_RULE',
        value: intervention,
      })
    }

    if (intervention.title.includes('Mudança')) {
      return items.push({
        type: 'STATE_CHANGE',
        value: intervention,
      })
    }

    items.push({
      type: 'INTERVENTION',
      value: intervention,
    })
  })

  return items.sort((a, b) => b.value.createdAt - a.value.createdAt)
}

export type DisplacementStepWithAggregatedOptions =
  | DisplacementStep
  | 'VIEW'
  | 'ATTENDANCE'
  | 'VIEW_ON_MAP'
  | 'CHANGE_TACTICAL'
  | 'SET_TACTICAL'
  | 'SUB_ACTIONS'

export type ModalItems = {
  label: string
  displacementStep: DisplacementStepWithAggregatedOptions
}

const modalAllOptions: Record<
  DisplacementStepWithAggregatedOptions,
  ModalItems
> = {
  AVAILABLE: {
    label: 'Tornar disponível',
    displacementStep: 'AVAILABLE',
  },
  QUEUED: {
    label: 'Colocar em observação',
    displacementStep: 'QUEUED',
  },
  TRAVEL_FINISHED: {
    label: 'Chegada no local',
    displacementStep: 'TRAVEL_FINISHED',
  },
  TRAVEL_VIEWED: {
    label: 'Solicitação visualizada',
    displacementStep: 'TRAVEL_VIEWED',
  },
  TRAVEL_WAITING: {
    label: 'Enviar para deslocamento',
    displacementStep: 'TRAVEL_WAITING',
  },
  TRAVEL_STARTED: {
    label: 'Trânsito iniciado',
    displacementStep: 'TRAVEL_STARTED',
  },
  FINISHED: {
    label: 'Finalizar',
    displacementStep: 'FINISHED',
  },
  ATTENDANCE: {
    label: 'Atender',
    displacementStep: 'ATTENDANCE',
  },
  VIEW: {
    label: 'Visualizar ocorrência',
    displacementStep: 'VIEW',
  },
  SUB_ACTIONS: {
    label: 'Definir como...',
    displacementStep: 'SUB_ACTIONS',
  },
  VIEW_ON_MAP: {
    label: 'Visualizar no mapa',
    displacementStep: 'VIEW_ON_MAP',
  },
  CHANGE_TACTICAL: {
    label: 'Alterar tático',
    displacementStep: 'CHANGE_TACTICAL',
  },
  SET_TACTICAL: {
    label: 'Atribuir tático',
    displacementStep: 'SET_TACTICAL',
  },
}

export const handleModalOptions = (
  stateName: OccurrenceStateName,
  isUserInAttendance?: boolean,
) => {
  const {
    VIEW,
    QUEUED,
    FINISHED,
    AVAILABLE,
    ATTENDANCE,
    VIEW_ON_MAP,
    SUB_ACTIONS,
    SET_TACTICAL,
    TRAVEL_WAITING,
    CHANGE_TACTICAL,
  } = modalAllOptions

  const options: Record<OccurrenceStateName, ModalItems[]> = {
    HIDDEN: [],
    CREATED: [],
    FINISHED: [],
    QUEUED: [ATTENDANCE, VIEW, AVAILABLE, TRAVEL_WAITING, FINISHED],
    TRAVEL_WAITING: [
      ATTENDANCE,
      VIEW,
      VIEW_ON_MAP,
      QUEUED,
      FINISHED,
      SET_TACTICAL,
    ],
    TRAVEL_FINISHED: [VIEW, VIEW_ON_MAP, QUEUED, FINISHED],
    AVAILABLE: [ATTENDANCE, VIEW, TRAVEL_WAITING, QUEUED, FINISHED],
    IN_PROGRESS: [
      VIEW,
      TRAVEL_WAITING,
      QUEUED,
      ...(isUserInAttendance ? [ATTENDANCE] : []),
    ],
    TRAVEL_STARTED: [
      VIEW,
      VIEW_ON_MAP,
      QUEUED,
      FINISHED,
      CHANGE_TACTICAL,
      SUB_ACTIONS,
    ],
    TRAVEL_VIEWED: [
      VIEW,
      VIEW_ON_MAP,
      QUEUED,
      FINISHED,
      CHANGE_TACTICAL,
      SUB_ACTIONS,
    ],
    TRAVEL_SENT: [
      VIEW,
      VIEW_ON_MAP,
      QUEUED,
      FINISHED,
      CHANGE_TACTICAL,
      SUB_ACTIONS,
    ],
  }

  return options[stateName]
}

export const handleChangeState = (
  note: string,
  state: InterventionStateEnum,
  occurrence: OccurrenceHistory,
) => {
  const travelStates = [
    InterventionStateEnum.TRAVEL_VIEWED,
    InterventionStateEnum.TRAVEL_STARTED,
    InterventionStateEnum.TRAVEL_FINISHED,
  ]

  if (travelStates.includes(state)) {
    if (
      occurrence &&
      occurrence.tactical?.agent &&
      occurrence.tactical.vehicle
    ) {
      return {
        typeName: InterventionTypeEnum.STATE_CHANGED,
        stateName: state,
        attributes: {
          stateName: state,
          agent: occurrence.tactical.agent,
          vehicle: {
            id: occurrence.tactical.vehicle.id,
            licensePlate: occurrence.tactical.vehicle.licensePlate,
          },
          agentCoordinates: {
            latitude: occurrence.tactical.agent.latitude,
            longitude: occurrence.tactical.agent.longitude,
          },
          ...(state === InterventionStateEnum.TRAVEL_FINISHED && {
            patrimonyCoordinates: occurrence?.coordinates,
          }),
        },
        note,
        interventionTag: FormattedInterventionTag[state],
      }
    }
  }

  return {
    typeName: InterventionTypeEnum.STATE_CHANGED,
    attributes: {
      stateName: state,
    },
    interventionTag: FormattedInterventionTag[state],
    note,
  }
}

export const getOccurrenceStateName = (state: OccurrenceStateName): string => {
  const options: Record<OccurrenceStateName, string> = {
    HIDDEN: 'Escondida',
    CREATED: 'Disponível',
    AVAILABLE: 'Disponível',
    QUEUED: 'Em observação',
    IN_PROGRESS: 'Em atendimento',
    TRAVEL_WAITING: 'Deslocamento em espera',
    TRAVEL_SENT: 'Deslocamento enviado',
    TRAVEL_VIEWED: 'Deslocamento visualizado',
    TRAVEL_STARTED: 'Deslocamento iniciado',
    TRAVEL_FINISHED: 'No local',
    FINISHED: 'Finalizada',
  }

  return options[state]
}

export const occurrenceTypesLabel: Record<OccurrenceTypes, string> = {
  ALARM: 'Alarme',
  RESCUE: 'Socorro',
  FAILURE: 'Falha',
  MANUAL: 'Manual',
  SCHEDULED: 'Periódica',
  FINISHED: 'Finalizado',
  IMMINENT: 'Iminência',
  EMERGENCY: 'Emergência',
  COMMERCIAL: 'Comercial',
}

export const onInspectAccount = (
  occurrence: AggregatedOccurrenceResponse,
  redirect: (path: string) => void,
) => {
  localStorage.setItem('accountId', occurrence?.account?.id || '')
  localStorage.setItem('accountCode', occurrence?.account?.code || '')

  if (occurrence?.account && occurrence.patrimony) {
    localStorage.setItem(
      'aggregatedAccountName',
      `${occurrence.account.code} - ${occurrence.patrimony.name}`,
    )
  }

  localStorage.setItem('patrimonyId', occurrence?.patrimony?.id || '')
  localStorage.setItem('customerId', occurrence?.customer?.id || '')
  localStorage.setItem('customerName', occurrence?.customer?.name || '')

  redirect(`${CustomerPaths.Account}`)
}
