import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

type CallBack = (page: number) => void

export const useQueryParams = (callback: CallBack) => {
  const { search } = useLocation()

  useEffect(() => {
    const page = new URLSearchParams(search).get('page')
    if (page) callback(Number(page))
  }, [search])
}
