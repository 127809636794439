import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
  FC,
} from 'react'

import { DisplacementStep } from '../types'
import { TacticalStatus } from 'services/displacementMap/types'

interface Provider {
  children: ReactNode
}

export type Filters = {
  account?: string
  agentName?: string
  occurrenceType?: string[]
  status?: TacticalStatus[]
  displacementStatus?: DisplacementStep[]
}

interface FilterContextProps {
  filters: Filters
  handleClearFilter: () => void
  handleFilter: (filters: Filters) => void
}

const defaultFilter: Filters = {
  status: [],
  account: '',
  agentName: '',
  occurrenceType: [],
  displacementStatus: [],
}

const defaultValue: FilterContextProps = {
  filters: defaultFilter,
  handleFilter: () => null,
  handleClearFilter: () => null,
}

export const FilterContext = createContext<FilterContextProps>(defaultValue)

export function useFilters(): FilterContextProps {
  const context = useContext(FilterContext)

  if (!context) {
    throw new Error('You need to use FilterContext within a FilterProvider')
  }

  return context
}

const defaultValues: Filters = {
  status: [],
  account: '',
  agentName: '',
  occurrenceType: [],
}

export const FilterProvider: FC<Provider> = ({ children }) => {
  const [filters, setFilters] = useState<Filters>(defaultValues)

  const handleFilter = useCallback((filters: Filters) => {
    setFilters(filters)
  }, [])

  const handleClearFilter = useCallback(() => {
    setFilters(defaultFilter)
  }, [])

  return (
    <FilterContext.Provider
      value={{
        filters,
        handleFilter,
        handleClearFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
