import { useParams } from 'react-router-dom'

import { useUserInfo } from 'shared/hooks'
import { DropDown, Icon } from 'components'
import { formatPhone } from 'utilities/masks'
import { useMakeCall } from 'services/phone'
import {
  Contact,
  contactTypes,
  useCreateIntervention,
} from 'services/serviceOrderV2'

import styles from './styles.module.scss'

interface ContactProps {
  contact: Contact
}

const ContactItem = ({ contact }: ContactProps) => {
  const { userInfo } = useUserInfo()
  const { serviceOrderId } = useParams()
  const { mutate } = useCreateIntervention(serviceOrderId)

  const { mutate: call } = useMakeCall()

  const userId = userInfo.id
  const phoneFormatted = `${contact.phone.provinceCode || ''}${contact.phone.number}`

  const handlePhoneCall = () => {
    mutate(
      {
        userId,
        typeName: 'PHONE_CALL',
        attributes: {
          number: phoneFormatted,
          contactId: contact.id,
        },
        title: `Ligação para contato ${contact.name}`,
      },
      {
        onSuccess: () => {
          call({
            phoneNumber: phoneFormatted,
            phoneExtension: userInfo.phoneExtension,
          })
        },
      },
    )
  }

  const handleSendMessage = () => {
    mutate(
      {
        userId,
        typeName: 'SEND_MESSAGE',
        attributes: {
          number: phoneFormatted,
          contactId: contact.id,
        },
        title: `Mensagem de WhatsApp para ${contact.name}`,
      },
      {
        onSuccess: () =>
          window.open(`https://wa.me/+55${phoneFormatted}`, '_blank'),
      },
    )
  }

  return (
    <li key={contact.id} className={styles.container}>
      <div>
        <span>{contact.name}</span>
        <span>
          {contactTypes[contact.type]}
          {contact.hasRemoteControl && <Icon name="control" width={10} />}
        </span>
      </div>
      {contact.phone && (
        <DropDown.Root>
          <DropDown.Trigger>
            <span className={styles.phone}>
              <Icon name="phone" />
              {formatPhone(contact.phone.type, `${phoneFormatted}`)}
            </span>
          </DropDown.Trigger>
          <DropDown.Content position="right">
            <DropDown.Item onClick={handlePhoneCall}>
              Ligar para o número
            </DropDown.Item>
            <DropDown.Item onClick={handleSendMessage}>
              Chat via Whatsapp
            </DropDown.Item>
          </DropDown.Content>
        </DropDown.Root>
      )}
    </li>
  )
}

export default ContactItem
