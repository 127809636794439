import * as Joi from '@hapi/joi'
import { Form } from '../../types'

const commonSchema = Joi.object({
  id: Joi.string().required(),
  name: Joi.string().required(),
})

const validateDeviceSchema = Joi.object({
  id: Joi.string().required(),
  name: Joi.string().required(),
  code: Joi.string().valid('0').required(),
}).required()

export const schema = Joi.object<Form>({
  id: Joi.string().optional(),
  code: Joi.string().required(),
  model: commonSchema.required(),
  account: commonSchema.required(),
  manufacturer: commonSchema.required(),
  centralId: Joi.string().required(),
  partitions: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().uuid().required(),
        aggregatedName: Joi.string().allow('').optional(),
      }),
    )
    .required(),
  tags: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().uuid().required(),
        name: Joi.string().required(),
      }),
    )
    .optional(),
  device: Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
    code: Joi.string().required(),
  }).required(),
  contact: Joi.when('device', {
    is: validateDeviceSchema,
    then: commonSchema.required(),
    otherwise: commonSchema.optional(),
  }),
  name: Joi.when('device', {
    is: validateDeviceSchema,
    then: Joi.string().optional(),
    otherwise: Joi.string().required(),
  }),
  image: Joi.any().optional(),
})
