import { useEffect } from 'react'
import { UserSessionStatus } from 'services/auth'
import { useUserInfo, useUserSession } from 'shared/hooks'

export const useHandleCloseApp = () => {
  const { userInfo } = useUserInfo()
  const { mutateCreateUserSession } = useUserSession()

  useEffect(() => {
    const handleCloseApp = () => {
      mutateCreateUserSession({
        status: UserSessionStatus.OFFLINE,
        userId: userInfo?.id,
      })
    }

    window.addEventListener('beforeunload', handleCloseApp)

    return () => window.removeEventListener('beforeunload', handleCloseApp)
  }, [])
}
