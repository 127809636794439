import * as Joi from '@hapi/joi'
import { UserQueryResult } from 'services/auth'
import {
  ServiceOrderContact,
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_SCHEDULE_PERIOD_ENUM,
  ServiceOrderStatus,
  TagInput,
  SERVICE_ORDER_TYPE_ENUM,
  ServiceOrderWithStatusUpdateRequest,
} from 'services/serviceOrder'
import {
  ServiceOrderLabelInput,
  serviceOrderLabelType,
} from 'services/serviceOrderLabel/types'
import { technicianSchema } from 'shared/schemas'

import {
  NumberSchemeErrorsEnum,
  StringSchemeErrorsEnum,
} from 'utilities/validation'

export type ServiceOrderUpdateStatus = {
  id: string
  status: SERVICE_ORDER_STATUS_ENUM
  userId: string
  technician: UserQueryResult
  note: string
  scheduleDate?: number
  schedulePeriod?: string
  contact?: ServiceOrderContact
  tags?: TagInput[]
  type?: SERVICE_ORDER_TYPE_ENUM
  accountId?: string
}

const contactSchema = Joi.object<ServiceOrderContact>({
  id: Joi.string().uuid().optional().allow('', null),
  name: Joi.string()
    .required()
    .messages({ [StringSchemeErrorsEnum.EMPTY]: 'Campo obrigatório' }),
  provinceCode: Joi.number().required().min(10),
  countryCode: Joi.number().required(),
  number: Joi.number()
    .required()
    .min(10000000)
    .messages({
      [StringSchemeErrorsEnum.EMPTY]: 'Campo obrigatório',
      [NumberSchemeErrorsEnum.NUMBER]: 'Campo obrigatório',
      [NumberSchemeErrorsEnum.MIN]: 'Campo obrigatório',
    }),
})

export type UpdateServiceOrderForm = ServiceOrderStatus & {
  id: string
}

export type ServiceOrderUpdateForm = Omit<
  ServiceOrderWithStatusUpdateRequest,
  'technicianId'
> & {
  technician: UserQueryResult
}

export const updateServiceOrderSchema = Joi.object<ServiceOrderUpdateForm>({
  status: Joi.string()
    .valid(...Object.values(SERVICE_ORDER_STATUS_ENUM))
    .required(),
  userId: Joi.string().uuid().required(),
  note: Joi.string().optional(),
  scheduleDate: Joi.when('status', {
    is: Joi.string().valid(SERVICE_ORDER_STATUS_ENUM.SCHEDULED).required(),
    then: Joi.number().required(),
    otherwise: Joi.number().optional(),
  }),
  schedulePeriod: Joi.string()
    .valid(...Object.values(SERVICE_ORDER_SCHEDULE_PERIOD_ENUM))
    .optional(),
  contact: Joi.when('status', {
    is: Joi.string().valid(SERVICE_ORDER_STATUS_ENUM.SCHEDULED).required(),
    then: contactSchema.required(),
    otherwise: Joi.object().optional(),
  }),
  serviceOrderType: Joi.string()
    .valid(...Object.values(SERVICE_ORDER_TYPE_ENUM))
    .optional(),
  technician: technicianSchema.required(),
  accountId: Joi.string().uuid().required(),
  tags: Joi.array()
    .items(Joi.object({ name: Joi.string().required() }))
    .optional(),
  labels: Joi.array()
    .items(
      Joi.object<ServiceOrderLabelInput>({
        id: Joi.string().uuid().required(),
        active: Joi.boolean().required(),
        description: Joi.string().required(),
        type: Joi.string()
          .valid(
            serviceOrderLabelType.REASON,
            serviceOrderLabelType.RESOLUTION,
            serviceOrderLabelType.ISSUE,
          )
          .required(),
      }),
    )
    .length(1)
    .optional(),
})
