import { Info, Skeleton } from 'components'

const Loading = () =>
  Array.from({ length: 4 }).map((_, index) => (
    <Info.Root key={index}>
      <Info.Title>
        <Skeleton width={90} height={22} />
      </Info.Title>
      <Info.Content>
        <Skeleton width={180} height={30} />
        <Skeleton width={150} height={22} />
      </Info.Content>
    </Info.Root>
  ))

export default Loading
