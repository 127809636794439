import { useCallback, useRef, useState } from 'react'

import { CellProps, Column } from 'react-table'
import { Button, Input, Loader, Table, TableRef, Tags } from 'components'

import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'
import { ReactComponent as InspectIcon } from 'assets/svg/listInspectIcon.svg'
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'

import { useToast, useToggle } from 'shared/hooks'

import { Pagination, Result } from 'services/types'

import { OfficeHoursDriver } from 'services/officeHours'
import {
  Coverage,
  OfficeHoursWithAggregations,
} from 'services/officeHours/types'
import { maskedDateTime } from 'utilities/date'

import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { useNavigate } from 'react-router-dom'
import { OfficeHoursDelete } from 'domains/customer/screens/OfficeHours/components/OfficeHoursDelete/OfficeHoursDelete'
import { useDeleteOfficeHours } from 'shared/hooks/services/officeHours'

import styles from './OfficeHoursList.module.scss'
import Joi from '@hapi/joi'

const columns: Column<OfficeHoursWithAggregations>[] = [
  {
    id: 'name',
    Header: 'Nome',
    accessor: (officeHours) => officeHours.name,
  },
  {
    id: 'coverage',
    Header: 'Abrangência',
    accessor: (officeHours) => officeHours,
    Cell: (cell: CellProps<OfficeHoursWithAggregations>) => {
      const officeHours = cell.value as OfficeHoursWithAggregations

      const coverages: Coverage[] = [
        ...(officeHours.coverage.patrimonies || []),
        ...(officeHours.coverage.accounts || []),
        ...(officeHours.coverage.partitions || []),
      ]

      return (
        <div>
          <Tags
            keyId={officeHours.id}
            className={styles.firstTag}
            keyLabel="user-profiles"
            data={coverages}
          />
        </div>
      )
    },
  },
  {
    id: 'createdAt',
    Header: 'Criada em',
    accessor: (officeHour) => maskedDateTime(officeHour.createdAt),
  },
]

export const OfficeHoursList = () => {
  const loader = useToggle()
  const officeHoursDeleteModal = useToggle()
  const navigate = useNavigate()
  const tableRef = useRef<TableRef>(null)
  const { addToast } = useToast()

  const [selectedOfficeHours, setSelectedOfficeHours] = useState({
    id: '',
    name: '',
  })

  const { getFilters, setFilters } = usePersistentFilters<{ name: string }>(
    'officeHours',
  )

  const form = useForm<{ name: string }>({
    resolver: joiResolver({ name: Joi.string().allow('').required() }),
    defaultValues: getFilters(),
  })

  const { mutate, status } = useDeleteOfficeHours()

  const { register, handleSubmit, getValues, watch } = form

  const fetchOfficeHours = useCallback(
    async (pagination: Pagination) => {
      loader.show()
      let result: Result<OfficeHoursWithAggregations> = {
        data: [],
        totalElements: 0,
      }

      const name = getValues('name')

      setFilters(watch())

      const customerId = localStorage.getItem('customerId')

      if (customerId) {
        try {
          const officeHours = await OfficeHoursDriver.query({
            customerId,
            ...pagination,
            ...(name && { name }),
          })

          result = {
            data: officeHours.data,
            totalElements: officeHours.totalElements,
          }
        } finally {
          loader.hide()
        }
      }

      return result
    },
    [loader, getValues, setFilters, watch],
  )

  return (
    <>
      <div className={styles.container}>
        <Loader isVisible={loader.isVisible || status === 'pending'} />

        <OfficeHoursDelete
          officeHoursName={selectedOfficeHours.name}
          isVisible={officeHoursDeleteModal.isVisible}
          onClose={officeHoursDeleteModal.hide}
          onSubmit={() => {
            mutate(selectedOfficeHours.id, {
              onSuccess: () => {
                addToast({
                  message: 'Horário excluído com sucesso.',
                  type: 'success',
                })

                tableRef.current?.handleFetchPage()
              },
              onError: () =>
                addToast({
                  message: 'Erro ao excluir horário. Tente novamente.',
                  type: 'alert',
                }),
            })
            officeHoursDeleteModal.hide()
          }}
        />

        <FormProvider {...form}>
          <div className={styles.actions}>
            <form
              className={styles.inputsWrapper}
              onSubmit={handleSubmit(() => {
                tableRef.current?.handleFetchPage()
              })}
            >
              <div className={styles.innerWrapper}>
                <Input
                  id="name"
                  placeholder="Digite o nome da tabela de horário"
                  label="Nome"
                  autoComplete="off"
                  className={styles.input}
                  {...register('name')}
                />
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    buttonTitle="Pesquisar"
                  />
                </div>
                <Button
                  buttonTitle={'Novo horário'}
                  type="secondary"
                  icon={PlusIcon}
                  onClick={() => navigate('/officeHours/create')}
                />
              </div>
            </form>
          </div>

          <Table
            innerRef={tableRef}
            fetcher={fetchOfficeHours}
            resetPagination={false}
            columns={columns}
            paginated
            actions={[
              {
                Icon: InspectIcon,
                label: 'Visualizar',
                handler: (officeHours) => {
                  navigate('/account/officeHour', {
                    state: {
                      officeHoursId: officeHours.id,
                    },
                  })
                },
              },
              {
                Icon: EditIcon,
                label: 'Editar',
                handler: (officeHours) => {
                  navigate('/officeHours/edit', {
                    state: {
                      officeHoursId: officeHours.id,
                    },
                  })
                },
              },
              {
                label: 'Excluir',
                Icon: TrashIcon,
                handler: ({ id, name }) => {
                  setSelectedOfficeHours({ id, name })
                  officeHoursDeleteModal.show()
                },
              },
            ]}
          />
        </FormProvider>
      </div>
    </>
  )
}
