import { useState } from 'react'

import { AvatarChangerModal, Input, ProtectedImage, Toast } from 'components'
import { useToggle } from 'shared/hooks'

import { ReactComponent as Avatar } from 'assets/svg/avatar.svg'
import { ReactComponent as Pen } from 'assets/svg/listEditIcon.svg'

import { useFormContext } from 'react-hook-form'
import { UserFormData } from 'domains/user/types'
import { formatCnpj, formatCpf } from 'utilities/masks'

import styles from './styles.module.scss'

interface GeneralInformationProps {
  readonly?: boolean
}

export const GeneralInformation: React.FC<GeneralInformationProps> = ({
  readonly,
}) => {
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<UserFormData>()

  const [selectedAvatar, setSelectedAvatar] = useState({
    imported: '',
    cropped: '',
  })

  const avatarChangerModal = useToggle()
  const toast = useToggle()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) {
      toast.show()
    } else {
      setSelectedAvatar({
        imported: URL.createObjectURL(e.currentTarget.files[0]),
        cropped: '',
      })
      avatarChangerModal.show()
    }
  }

  const handleImage = () => {
    if (selectedAvatar.cropped) return 'croppedImage'
    if (watch('avatarId')) return 'persistedImage'

    return 'empty'
  }

  return (
    <>
      <AvatarChangerModal
        isVisible={avatarChangerModal.isVisible}
        avatarUrl={selectedAvatar.imported}
        onClose={() => avatarChangerModal.hide()}
        onSave={(image: Blob | MediaSource) => {
          setSelectedAvatar({
            cropped: URL.createObjectURL(image),
            imported: '',
          })
          setValue('avatarFile', image, {
            shouldValidate: true,
            shouldDirty: true,
          })

          avatarChangerModal.hide()
        }}
        {...register('avatarFile')}
      />

      <Toast
        isVisible={toast.isVisible}
        onClose={() => toast.hide()}
        type={'alert'}
      >
        Erro ao carregar a imagem
      </Toast>

      <div className={styles.generalDataWrapper}>
        <div>
          <h3>Dados gerais</h3>
          <small>Informações básicas e de identificação do usuário.</small>
        </div>

        <div className={styles.generalDataContent}>
          <div className={styles.userInfoPhotoWrapper}>
            {
              {
                croppedImage: (
                  <img
                    className={styles.userInfoPhoto}
                    src={selectedAvatar.cropped}
                    alt="Imagem selecionada da central"
                  />
                ),
                persistedImage: (
                  <ProtectedImage
                    className={styles.userInfoPhoto}
                    imageId={watch('avatarId') || ''}
                  />
                ),
                empty: <Avatar className={styles.userInfoPhoto} />,
              }[handleImage()]
            }

            <label htmlFor="avatar-edit">
              <Pen fill={'#009F87'} className={styles.avatarEditCameraIcon} />
            </label>
            <input
              type="file"
              id="avatar-edit"
              aria-label="avatar-edit-input"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>

          <div className={styles.generalInputs}>
            <Input
              disabled={readonly}
              label="Nome completo"
              placeholder="Digite o nome completo"
              {...register('name')}
              value={watch('name')}
              className={styles.inputName}
              onChange={(e) =>
                setValue('name', e.target.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              errorMessage={errors?.name?.message}
            />

            <Input
              disabled={readonly}
              type="text"
              label="Documento"
              placeholder="CPF/CNPJ"
              maxLength={18}
              {...register('document')}
              value={
                watch('document')?.replace(/\D/g, '')?.length > 11
                  ? formatCnpj(watch('document') || '')
                  : formatCpf(watch('document') || '')
              }
              onChange={(event) => {
                setValue(
                  'document',
                  event.target.value.replace(/[.\-/]/g, ''),
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                )
              }}
              errorMessage={errors?.document?.message}
            />
          </div>
        </div>
      </div>
    </>
  )
}
