import { MouseEvent, FC } from 'react'
import styles from './ButtonGroup.module.scss'

type ButtonProps = {
  id: string
  onClick: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void
  title: string
  selected?: boolean
  disabled?: boolean
}

type ButtonGroupProps = {
  leftButton: ButtonProps
  rightButton: ButtonProps
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
  leftButton,
  rightButton,
}) => {
  return (
    <div className={styles.container}>
      <button
        type="button"
        aria-label={leftButton.title}
        className={[
          styles.button,
          styles.leftButton,
          leftButton.selected && styles.selected,
          leftButton.disabled && styles.disabled,
          leftButton.selected &&
            leftButton.disabled &&
            styles.selectedAndDisabled,
        ]
          .filter(Boolean)
          .join(' ')}
        id={leftButton.id}
        disabled={leftButton.disabled}
        onClick={leftButton.onClick}
      >
        {leftButton.title}
      </button>
      <button
        type="button"
        aria-label={rightButton.title}
        className={[
          styles.button,
          styles.rightButton,
          rightButton.selected && styles.selected,
          rightButton.disabled && styles.disabled,
          rightButton.selected &&
            rightButton.disabled &&
            styles.selectedAndDisabled,
        ]
          .filter(Boolean)
          .join(' ')}
        id={rightButton.id}
        disabled={rightButton.disabled}
        onClick={rightButton.onClick}
      >
        {rightButton.title}
      </button>
    </div>
  )
}
