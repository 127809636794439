import { useEffect } from 'react'
import { UserSessionStatus } from 'services/auth'
import { useUserInfo, useUserSession } from 'shared/hooks'
import { KEEP_ALIVE_PING_TIME_IN_MILLISECONDS } from 'shared/hooks/useKeepAliveUserStatus/constants'

export function useKeepAliveUserStatus() {
  const { userInfo } = useUserInfo()
  const { mutateCreateUserSession } = useUserSession()

  useEffect(() => {
    if (userInfo) {
      const timer = setInterval(() => {
        mutateCreateUserSession({
          userId: userInfo.id,
          status: UserSessionStatus.ONLINE,
        })
      }, KEEP_ALIVE_PING_TIME_IN_MILLISECONDS)

      return () => clearInterval(timer)
    }
  }, [mutateCreateUserSession, userInfo])
}
