import { useState, useEffect } from 'react'

type PersistFilter<T> = {
  setFilters: (filters: T) => void
  getFilters(): T | undefined
  clearAllLocalStorageFilters: () => void
}

type Domains =
  | 'account'
  | 'serviceOrder'
  | 'user'
  | 'vehicle'
  | 'station'
  | 'coverage'
  | 'event-history'
  | 'occurrence'
  | 'attendanceProfile'
  | 'attendancePolicy'
  | 'officeHours'
  | 'habitControl'
  | 'holiday'
  | 'equipments'
  | 'occurrences'
  | 'occurrence-history'
  | 'contacts'
  | 'patrol'

export function usePersistentFilters<T>(domain?: Domains): PersistFilter<T> {
  const prefix = `filters.${domain || ''}`

  const [filters, setLocalFilters] = useState<T | undefined>(() => {
    const filter = localStorage.getItem(prefix)

    return filter ? (JSON.parse(filter) as T) : undefined
  })

  function setFilters(newFilters: T) {
    localStorage.setItem(prefix, JSON.stringify(newFilters))
    setLocalFilters(newFilters)
  }

  function getFilters() {
    return filters
  }

  function clearAllLocalStorageFilters() {
    const prefix = 'filters'

    for (let index = 0; index < localStorage.length; index++) {
      if (localStorage.key(index)?.substring(0, 7) === prefix) {
        const key = localStorage.key(index)
        if (key) localStorage.removeItem(key)
      }
    }
  }

  useEffect(() => {
    const storedFilters = localStorage.getItem(prefix)

    if (storedFilters) {
      setLocalFilters(JSON.parse(storedFilters) as T)
    }
  }, [prefix])

  return { setFilters, getFilters, clearAllLocalStorageFilters }
}
