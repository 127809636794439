import React, { useCallback, useMemo } from 'react'

import { handleOccurrenceFinishForm } from 'domains/attendancePolicy/utilities/Accordion/Accordion'

import { OccurrenceFinishTypes } from 'domains/attendancePolicy/types'

import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import { ConditionType } from 'services/attendancePolicy/types'
import { RadioGroup } from 'components'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { Evaluation } from 'services/serviceOrder'

export const OccurrenceFinishType: React.FC<ConditionOptionForm> = ({
  onChange,
  data,
}) => {
  const checkValue = useCallback(
    (value: Evaluation | undefined): OccurrenceFinishTypes | null => {
      if (value === undefined) return null

      const evaluation = value === 'HAS'

      return evaluation ? 'Manual' : 'Automática'
    },
    [],
  )

  const selectedEvaluation = useMemo(
    () => checkValue(data.evaluation),
    [data, checkValue],
  )

  return (
    <RadioGroup title="Tipo de Finalização">
      {Object.values(['Manual', 'Automática']).map((option, key) => (
        <RadioButton
          value={option}
          onChange={(e) => {
            const value = e.target.value
            const status = handleOccurrenceFinishForm(value)

            onChange({
              evaluation: status,
              type: ConditionType.OCCURRENCE_FINISHED_MANUALLY,
            })
          }}
          checked={selectedEvaluation === option}
          name={`occurrence-finish-type-${data.id}`}
          key={key}
        />
      ))}
    </RadioGroup>
  )
}
