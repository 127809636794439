import React, {
  forwardRef,
  InputHTMLAttributes,
  SVGProps,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { v4 as uuid } from 'uuid'

import { useOnClickOutside } from 'shared/hooks/useOnClickOutside'

import { ReactComponent as Arrow } from '../../assets/svg/arrow.svg'
import { ReactComponent as Search } from '../../assets/svg/comboSearch.svg'
import { ReactComponent as ErrorIcon } from '../../assets/svg/error.svg'
import { ReactComponent as FlatPlusIcon } from '../../assets/svg/flatPlus.svg'
import MultiSelectTags from './components/MultiSelectTags'

import styles from './Combobox.module.scss'
import { ComboboxItemComponent } from './components/ComboboxItemComponent'
import { getItemValue, isItemEquals, Item } from './utilities'
import { IconWithTooltip } from 'components/IconWithTooltip/IconWithTooltip'

export type ComboboxItem<T> = {
  label: keyof T
  value: T
}

type ComboboxProps<T> = {
  id?: string
  className?: string
  placeholder?: string
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  value?: (string | ComboboxItem<T>) | (string | ComboboxItem<T>)[] | undefined
  isDisabled?: (item: string | ComboboxItem<T>) => boolean
  itemLabel?: string | ((item: ComboboxItem<T> | string) => string)
  multiple?: boolean
  Icon?: React.FC<SVGProps<SVGSVGElement>>

  label?: {
    text: string
    hint?: {
      text: string
      icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    }
  }

  items: (string | ComboboxItem<T>)[]
  disabled?: boolean

  onEndReached?: () => void
  onEndReachedThreshold?: number

  isLoading?: boolean
  isError?: boolean
  errorMessage?: string

  onChange?: (
    selected:
      | (string | ComboboxItem<T>)
      | (string | ComboboxItem<T>)[]
      | undefined,
  ) => void

  createable?: boolean
  onSearch?: (text: string) => void
}

/**
 * @deprecated "This components is deprecated and will be removed in a future release. Please use `ComboBoxV3` instead."
 */
export const Combobox = forwardRef<HTMLInputElement, ComboboxProps<Item>>(
  (props, _ref) => {
    const {
      items,
      multiple,
      isDisabled,
      itemLabel,
      Icon,
      label,
      disabled = false,
      onChange,
      errorMessage,
      value,
      id,
      className,
      placeholder,
      inputProps,

      isLoading,
      isError,
      onEndReached,
      onEndReachedThreshold = 5,

      onSearch,
      createable,
    } = props

    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState('')
    const [hasClearFilter, setHasClearFilter] = useState(false)
    // OFFSCREEN CONTENT
    const [offScreen, setOffScreen] = useState(false)
    const [selected, setSelected] = useState<
      string | ComboboxItem<Item> | undefined
    >()
    const [multiSelected, setMultiSelected] = useState<
      (string | ComboboxItem<Item>)[]
    >([])

    // #FILTERING
    const inputSearchRef = useRef<HTMLInputElement>(null)
    // #CLICKING OUTSIDE
    const comboboxRef = useRef<HTMLDivElement>(null)
    // #SCROLLING CONTENT
    const contentRef = useRef<HTMLDivElement>(null)
    const creatableContainerRef = useRef<HTMLDivElement>(null)

    const isMultiple = multiple || createable
    const isSearchable = onSearch || createable

    useEffect(() => {
      if (Array.isArray(value)) {
        setMultiSelected(value)
      } else {
        !hasClearFilter && setSelected(value)
      }
    }, [value]) //eslint-disable-line

    const isAlreadyAnItemSelected = (item: string | ComboboxItem<Item>) => {
      if (!multiSelected.length) return false
      return multiSelected.some((selected) => isItemEquals(selected, item))
    }

    // #UTILITIES
    const onSelectItem = (item: string | ComboboxItem<Item>) => {
      setFilter('')
      onSearch?.('')

      if (isMultiple) {
        const hasItem = isAlreadyAnItemSelected(item)
        if (!hasItem) {
          const newMultipleSelected = [...multiSelected, item]
          setMultiSelected(newMultipleSelected)
          onChange && onChange(newMultipleSelected)
        }
      } else {
        setSelected(item)
        onChange && onChange(item)
      }
    }

    const skipSelectedValue = (item: string | ComboboxItem<Item>) => {
      if (isMultiple) {
        return multiSelected.some((multiItem) => isItemEquals(item, multiItem))
      }

      if (!selected) return false

      return isItemEquals(item, selected)
    }

    const onCreateTag = () => {
      if (filter && createable) onSelectItem(filter)
    }

    useOnClickOutside(
      comboboxRef,
      (e) => {
        const isClickingOnAItem = (e.target as Element).getAttribute(
          'data-combobox-item',
        )
        if ((multiple || createable) && isClickingOnAItem) return
        setOpen(false)
      },
      true,
    )

    useLayoutEffect(() => {
      const currentContentRef = contentRef.current

      if (!onEndReached) return

      const handleScroll = () => {
        if (!currentContentRef || !items.length) return

        if (
          currentContentRef.scrollTop + currentContentRef.clientHeight >=
          currentContentRef.scrollHeight - onEndReachedThreshold
        ) {
          onEndReached()
        }
      }

      currentContentRef?.addEventListener('scroll', handleScroll)

      return () => {
        currentContentRef?.removeEventListener('scroll', handleScroll)
      }
    }, [onEndReached, items, onEndReachedThreshold])

    useLayoutEffect(() => {
      if (!open || !contentRef.current) {
        return
      }

      // Set the content to the original position to retrieve the correct rect
      contentRef.current.style.top = 'auto'
      const rect = contentRef.current.getBoundingClientRect()
      contentRef.current.style.top = ''

      const threshold = 3
      setOffScreen(rect.top + rect.height + threshold > window.innerHeight)
    }, [open, contentRef, items])

    return (
      <>
        <div
          className={[
            styles.container,
            disabled && styles.containerDisabled,
            className,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          {label && (
            <div className={styles.labelWrapper}>
              <label htmlFor={id} className={styles.label}>
                {label.text}
              </label>
              {label.hint && (
                <IconWithTooltip
                  text={label.hint.text}
                  Icon={label.hint.icon}
                />
              )}
            </div>
          )}
          <div
            className={[
              styles.combobox,
              open && styles.comboboxOpened,
              isMultiple &&
                multiSelected.length &&
                styles.comboboxMultipleWithItem,
              Icon && styles.comboboxViewSelector,
              Icon && open && styles.comboboxViewSelectorOpened,
              Boolean(isSearchable) && styles.comboboxSearchable,
              disabled && styles.disabled,
              errorMessage && styles.error,
              offScreen && styles.openedOffScren,
            ]
              .filter(Boolean)
              .join(' ')}
            ref={comboboxRef}
          >
            {Icon && <Icon className={styles.icon} role="img" />}
            {Boolean(isSearchable) && (
              <Search
                className={styles.searchIcon}
                role="img"
                onClick={() => {
                  inputSearchRef.current?.focus()
                  setOpen(true)
                }}
              />
            )}
            <input
              id={id}
              role="combobox"
              aria-controls={`${id || 'combobox'}-items`}
              aria-expanded={open}
              autoComplete="off"
              ref={inputSearchRef}
              disabled={disabled}
              className={[
                styles.input,
                Boolean(isSearchable) && styles.searchableInput,
              ]
                .filter(Boolean)
                .join(' ')}
              type="text"
              value={filter || getItemValue(selected || '')}
              readOnly={!onSearch && !createable}
              onChange={(event) => {
                if (selected) {
                  setSelected(undefined)
                  onChange?.(undefined)
                }

                if (!event.target.value) {
                  setHasClearFilter(true)
                  setSelected(undefined)
                } else {
                  setHasClearFilter(false)
                }

                setFilter(event.target?.value)
                onSearch?.(event.target?.value)
              }}
              onClick={() => {
                setOpen((prev) => Boolean(isSearchable) || !prev)
              }}
              placeholder={placeholder}
              onKeyDown={(event) => [
                event.key === 'Enter' && onCreateTag(),
                event.key === 'Tab' && onCreateTag(),
                event.key === 'Escape' && setOpen(false),
              ]}
              {...inputProps}
            />
            <Arrow
              className={[
                styles.arrow,
                isMultiple && styles.arrowMultiple,
                open && styles.upsideArrow,
                Icon && styles.viewSelectorArrow,
              ]
                .filter(Boolean)
                .join(' ')}
              onClick={() => {
                setOpen((prev) => !prev)
              }}
              data-testid="arrow-icon"
            />
          </div>
          {errorMessage && !createable && (
            <div className={styles.errorWrapper}>
              <ErrorIcon className={styles.errorIcon} />
              <p
                role="textbox"
                aria-label="error-message-text"
                className={styles.errorText}
              >
                {errorMessage}
              </p>
            </div>
          )}
          <div
            data-testid="content-wrapper"
            className={[
              styles.contentWrapper,
              createable && !isError && !isLoading && styles.contentCreatable,
              open && styles.visible,
              offScreen && styles.offScreen,
            ]
              .filter(Boolean)
              .join(' ')}
          >
            <div
              ref={contentRef}
              id={`${id || 'combobox'}-items`}
              className={[
                styles.content,
                createable && styles.contentCreatable,
              ].join(' ')}
            >
              <ul>
                {items.map((item, index) => {
                  const skipItem = skipSelectedValue(item)
                  if (skipItem) return null

                  const itemDisabled = isDisabled ? isDisabled(item) : false
                  return (
                    <ComboboxItemComponent
                      item={item}
                      itemId={uuid()}
                      itemDisabled={itemDisabled}
                      onSelectItem={onSelectItem}
                      itemLabel={itemLabel}
                      key={index}
                    />
                  )
                })}

                {!items.length && !isError && !isLoading && (
                  <p className={styles.noDataPlaceholder}>
                    Não foram encontrados itens.
                  </p>
                )}

                {!items.length && isError && (
                  <p className={styles.errorPlaceholder}>
                    Erro ao carregar as opções.
                  </p>
                )}

                {isLoading && <div className={styles.loader} />}
              </ul>
            </div>
            {createable && open && !isError && (
              <div
                ref={creatableContainerRef}
                aria-label="creatable-container"
                className={[
                  styles.creatableContainer,
                  !label && styles.withoutLabelContainerStyle,
                  open && styles.visible,
                  offScreen && styles.offScreen,
                ].join(' ')}
                onClick={onCreateTag}
              >
                <FlatPlusIcon className={styles.creatableIcon} />
                {filter}
                <span className={styles.creatableHintText}>(Criar nova)</span>
              </div>
            )}
          </div>
          {isMultiple && multiSelected.length ? (
            <div className={styles.tagsContainer}>
              <MultiSelectTags
                items={multiSelected}
                placeholder={placeholder}
                onRemoveItem={(itemToBeDeleted) => {
                  const newMultiSelected = multiSelected.filter(
                    (item) => item !== itemToBeDeleted,
                  )
                  setMultiSelected(newMultiSelected)
                  onChange && onChange(newMultiSelected)
                }}
              />
            </div>
          ) : null}
        </div>
      </>
    )
  },
)

Combobox.displayName = 'Combobox'
