import { useMutation, useQueryClient } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { InterventionNotePayload } from './types'

interface CreateNote {
  serviceOrderId?: string
  interventionId?: string
  data: InterventionNotePayload
}

export const createNote = async ({
  serviceOrderId,
  interventionId,
  data: { images, ...payload },
}: CreateNote) => {
  const formData = new FormData()

  images?.map((image) => formData.append('imageFile', image, image.name))
  formData.append('payload', JSON.stringify(payload))

  const response = await HttpClient.post(
    buildUrl({
      route: endpoints.createNote,
      params: { serviceOrderId, interventionId },
    }),
    formData,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': `multipart/form-data`,
      },
    },
  )

  return response.data
}

export const useCreateNote = (
  serviceOrderId?: string,
  interventionId?: string,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: InterventionNotePayload) =>
      await createNote({ serviceOrderId, interventionId, data }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['service-order-intervention', serviceOrderId],
      })
      queryClient.invalidateQueries({
        queryKey: ['service-order', serviceOrderId],
      })
      queryClient.invalidateQueries({
        queryKey: [
          'get-image',
          {
            owners: `serviceOrder_${serviceOrderId}`,
            sortType: 'desc',
          },
        ],
      })
    },
  })
}
