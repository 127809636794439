export enum ACCOUNT_STATUS {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
  ALL = 'Todos',
}

export type AccountStatus = {
  value: boolean | undefined
  label: ACCOUNT_STATUS
}
