import Joi from '@hapi/joi'
import { StringSchemeErrorsEnum } from 'utilities/validation'

export const cancelServiceOrderSchema = Joi.object({
  password: Joi.string()
    .required()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]:
        'Por favor preencha este campo para continuar',
    }),
  notes: Joi.string()
    .required()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]:
        'Por favor preencha este campo para continuar',
    }),
})
