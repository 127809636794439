import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ComboboxItem, Icon, Input, Modal, Tooltip } from 'components'
import { Combobox } from 'components/ComboboxV2/Combobox'

import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'
import { ReactComponent as NotesIcon } from 'assets/svg/note.svg'
import styles from './ServiceOrderForm.module.scss'

import {
  AccountWithAggregatedName,
  ServiceOrderAggregatedResponse,
  ServiceOrderWithStatusUpdateRequest,
  useGetServiceOrderNotes,
} from '../../../../services/serviceOrder'

import { FieldError, FormProvider, useForm } from 'react-hook-form'

import {
  ServiceOrderContact,
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_TYPE_PT_EN,
  SERVICE_ORDER_TYPE_EN_PT,
} from 'services/serviceOrder'
import { SortedCustomer } from 'services/customer/types'

import { joiResolver } from '@hookform/resolvers/joi'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { serviceOrderSchema } from '../../schemas/serviceOrderSchema'
import { useNavigate, useLocation } from 'react-router'
import { formatGenericPhone } from '../../utilities/mask/phone'
import {
  useConfirmClose,
  useDebounce,
  useToggle,
  useUserInfo,
} from 'shared/hooks'
import { Notes } from 'domains/serviceOrders/components'
import { formatServiceOrderScheduleDate } from '../../utilities/date/date'

import { useGetServiceOrderTags } from 'shared/hooks/serviceOrder/useGetServiceOrderTags'

import { ContactQueryResponse } from 'services/contact/types'

import {
  useGetCustomers,
  useGetAccounts,
  useGetContacts,
} from 'shared/hooks/services'
import { ServiceOrderPayload } from 'domains/serviceOrders/types'
import { useInfiniteServiceOrderLabel } from 'services/serviceOrderLabel'
import {
  ServiceOrderLabelInput,
  ServiceOrderLabelOutput,
  serviceOrderLabelType,
} from 'services/serviceOrderLabel/types'
import { useGetServiceOrders } from 'shared/hooks/serviceOrder/useGetServiceOrders'
import { POSITION } from 'shared/hooks/useElementPosition/types'
import {
  QueryTechniciansResult,
  useInfinityTechnicians,
} from 'services/serviceOrder/hooks/useGetTechnicians'
import { ScheduleServiceOrderModal } from '../ScheduleServiceOrderModal'

type ServiceOrderFormProps = {
  title: string
  serviceOrder?: ServiceOrderAggregatedResponse
  onSave: (serviceOrder: ServiceOrderWithStatusUpdateRequest) => void
}

export const ServiceOrderForm = ({
  title,
  serviceOrder,
  onSave,
}: ServiceOrderFormProps) => {
  const location = useLocation()
  const form = useForm<ServiceOrderPayload>({
    mode: 'onChange',
    resolver: joiResolver(serviceOrderSchema),
    defaultValues: {
      id: serviceOrder?.id,
      customer: serviceOrder?.customer,
      number: serviceOrder?.number,
      account: serviceOrder?.account,
      type: serviceOrder?.serviceOrderType,
      contact: serviceOrder?.contact,
      technician: serviceOrder?.technician,
      tags:
        serviceOrder?.tags?.map((tag) => ({
          name: tag.name,
        })) || [],
      note: serviceOrder?.history?.find(
        ({ status }) => status === SERVICE_ORDER_STATUS_ENUM.OPEN,
      )?.note,
      scheduleDate: serviceOrder?.scheduleDate,
      schedulePeriod: serviceOrder?.schedulePeriod,
      status: serviceOrder?.status || SERVICE_ORDER_STATUS_ENUM.OPEN,
      labels: serviceOrder?.labels,
    },
  })
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = form

  const navigate = useNavigate()

  const customerId = localStorage.getItem('customerId')
  const customerName = localStorage.getItem('customerName')

  const [scheduleModalOpen, setScheduledModalOpen] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [technicianFilter, setTechnicianFilter] = useState('')
  const [tagFilter, setTagFilter] = useState('')
  const [customerFilter, setCustomerFilter] = useState('')
  const [originFilter, setOriginFilter] = useState('')
  const [reasonFilter, setReasonFilter] = useState('')
  const toggleNotes = useToggle()

  const handleFilter = useDebounce(setTechnicianFilter)

  const otherContact = {
    id: '',
    name: 'Outro',
    number: 0,
    countryCode: 0,
    provinceCode: 0,
  }

  const customer = watch('customer')
  const contact = watch('contact')
  const technician = watch('technician')
  const note = watch('note')
  const account = watch('account')
  const serviceOrderType = watch('type')
  const tags = watch('tags')
  const labels = watch('labels')

  const hasServiceOrderOpenModal = useToggle()

  const { triedToClose, tooltipRef, tooltip, handleClose } = useConfirmClose({
    onClose: () => {
      navigate(-1)
    },
    shouldConfirm: isDirty,
  })

  const { data: serviceOrders } = useGetServiceOrders(
    {
      accountIds: [account?.id || ''],
      status: ['OPEN'],
    },
    Boolean(account?.id),
  )

  const serviceOrderId = serviceOrders?.data[0]?.id

  const { data: notes } = useGetServiceOrderNotes(serviceOrderId)

  const originLabel = useMemo(() => {
    return (
      labels &&
      labels.find((label) => label?.type === serviceOrderLabelType.ORIGIN)
    )
  }, [labels])

  const reasonLabel = useMemo(() => {
    return (
      labels &&
      labels.find((label) => label?.type === serviceOrderLabelType.REASON)
    )
  }, [labels])

  const {
    isError: isErrorTechnicians,
    isFetching: isFetchingTechnicians,
    technicians,
    fetchNextTechniciansPage,
  } = useInfinityTechnicians(
    watch('account')?.id,
    watch('type'),
    technicianFilter,
  )

  useEffect(() => {
    if (technicians && !isFetchingTechnicians && !serviceOrder && !technician) {
      const firstTechnician: QueryTechniciansResult = technicians[0]
      setValue('technician', firstTechnician)
    }
  }, [isFetchingTechnicians, serviceOrder, setValue, technician, technicians])

  useEffect(() => {
    if (serviceOrders?.totalElements) {
      hasServiceOrderOpenModal.show()
    }
  }, [hasServiceOrderOpenModal, serviceOrders])

  const accountFromHistory = location.state?.account

  useEffect(() => {
    if (accountFromHistory) {
      setValue('customer', {
        id: customerId,
        name: customerName,
      })
      setValue('account', {
        id: accountFromHistory?.id,
        code: accountFromHistory?.code,
        aggregatedName: accountFromHistory?.aggregatedName,
      })
    }
  }, [setValue, customerId, customerName, accountFromHistory])

  const handleServiceOrderContacts = useCallback(
    (contacts: ContactQueryResponse[]) => {
      const parsedContacts: ServiceOrderContact[] = contacts
        .filter((contact) => !!contact.phones?.length)
        .map((contact) => {
          return {
            id: contact.id,
            name: contact.name,
            countryCode: contact.phones?.[0].countryCode,
            provinceCode: contact.phones?.[0].provinceCode,
            number: contact.phones?.[0].number,
          }
        })

      parsedContacts.unshift(otherContact)

      return parsedContacts
    },
    [otherContact],
  )

  const { userInfo } = useUserInfo()
  const submit = useCallback(() => {
    const {
      status,
      account,
      contact,
      tags,
      scheduleDate,
      schedulePeriod,
      note,
      technician,
      labels,
      type,
    } = form.getValues()

    const serviceOrder: ServiceOrderWithStatusUpdateRequest = {
      accountId: account?.id,
      note,
      technicianId: technician?.id,
      userId: userInfo.id,
      serviceOrderType: type,
      status,
      tags,
      ...(contact && {
        contact: {
          ...contact,
          number: +Number(contact?.number),
          provinceCode: +Number(contact?.provinceCode),
        },
      }),
      ...(scheduleDate && {
        scheduleDate,
      }),
      ...(schedulePeriod && {
        schedulePeriod,
      }),
      labels,
    }

    onSave(serviceOrder)
    reset()
  }, [form, onSave, reset])

  const unscheduledModal = useToggle()

  const {
    isError: isErrorCustomers,
    isFetching: isFetchingCustomers,
    customers,
    fetchNextCustomersPage,
  } = useGetCustomers(customerFilter, undefined, undefined, true)

  const {
    isError: isOriginError,
    isFetching: isFetchingOrigins,
    serviceOrderLabels: origins,
    fetchNextPage: fetchNextOriginPage,
  } = useInfiniteServiceOrderLabel({
    type: 'ORIGIN',
    description: originFilter,
    active: true,
  })

  const {
    isError: isReasonError,
    isFetching: isFetchingReasons,
    serviceOrderLabels: reasons,
    fetchNextPage: fetchNextReasonPage,
  } = useInfiniteServiceOrderLabel({
    type: 'REASON',
    description: reasonFilter,
    active: true,
  })

  const { contacts, isFetchingContacts } = useGetContacts(
    { customerId: customer?.id },
    Boolean(customer?.id),
  )

  const [searchAccount, setSearchAccount] = useState('')
  const { accounts, isLoading } = useGetAccounts(
    { customerId: customer?.id, code: searchAccount, active: true },
    Boolean(customer?.id),
  )

  const {
    isError: isErrorServiceOrderTags,
    isFetching: isFetchingServiceOrderTags,
    serviceOrderTags,
    fetchNextTagsPage,
  } = useGetServiceOrderTags(tagFilter)

  const checkScheduled = () => {
    if (!scheduleDate) {
      unscheduledModal.show()
    } else {
      submit()
    }
  }

  useEffect(() => {
    setDisableSubmit(
      !customer ||
        !account ||
        !serviceOrderType ||
        !contact ||
        !contact.name ||
        !note ||
        !originLabel ||
        !reasonLabel,
    )
  }, [
    customer,
    account,
    serviceOrderType,
    contact,
    note,
    technician,
    labels,
    originLabel,
    reasonLabel,
    form,
  ])

  const scheduleDate = watch('scheduleDate')
  const schedulePeriod = watch('schedulePeriod')

  const formatScheduleDateText = () => {
    if (scheduleDate) {
      return formatServiceOrderScheduleDate({
        scheduleDate,
        schedulePeriod,
      })
    }

    return 'Não há agendamento para esta ordem de serviço'
  }
  return (
    <FormProvider {...form}>
      <div className={styles.container}>
        <Modal
          className={styles.unscheduledModal}
          title="Agendamento da ordem de serviço"
          isVisible={unscheduledModal.isVisible}
          onClose={unscheduledModal.hide}
        >
          <p>
            Você tem certeza que deseja salvar a ordem de serviço sem realizar o
            agendamento?
          </p>

          <Modal.Footer>
            <Button
              buttonTitle="Salvar"
              onClick={submit}
              type="primary"
              width="172px"
            />
            <Button
              buttonTitle="Agendar OS"
              type="secondary"
              width="172px"
              onClick={() => {
                unscheduledModal.hide()
                setScheduledModalOpen(true)
              }}
            />
          </Modal.Footer>
        </Modal>

        {scheduleModalOpen && (
          <ScheduleServiceOrderModal
            isVisible={scheduleModalOpen}
            onClose={() => setScheduledModalOpen(false)}
            onSave={(serviceOrder) => {
              setScheduledModalOpen(false)
              setValue('scheduleDate', serviceOrder.scheduleDate)
              setValue('schedulePeriod', serviceOrder.schedulePeriod)
              setValue('contact', serviceOrder.contact)
              setValue('tags', serviceOrder.tags)
              setValue('technician', serviceOrder.technician)
              setValue('note', serviceOrder.note)
              setValue('status', serviceOrder.status)
            }}
            serviceOrder={{
              account: {
                code: serviceOrder?.account?.code || watch('account')?.code,
                id: serviceOrder?.account?.id || watch('account')?.id,
              },
              contact: serviceOrder?.contact || watch('contact'),
              customer: serviceOrder?.customer || watch('customer'),
              id: serviceOrder?.id,
              note: (serviceOrder?.history?.[0]?.note ?? '') || watch('note'),
              number: serviceOrder?.number || watch('number'),
              status: serviceOrder?.status,
              scheduleDate: serviceOrder?.scheduleDate,
              schedulePeriod: serviceOrder?.schedulePeriod,
              tags: serviceOrder?.tags || watch('tags'),
              technician: serviceOrder?.technician || watch('technician'),
              type: serviceOrder?.serviceOrderType || watch('type'),
            }}
          />
        )}

        {toggleNotes.isVisible && (
          <Notes
            isVisible={toggleNotes.isVisible}
            onClose={toggleNotes.hide}
            serviceOrderId={serviceOrderId}
          />
        )}
        <span className={styles.title}>{title}</span>
        <div className={styles.headerSeparator} />
        <form onSubmit={handleSubmit(submit)} data-testid="form-so">
          <div className={styles.contentWrapper}>
            <div className={styles.formWrapper}>
              <Combobox
                {...register('customer')}
                id="customer-input"
                label={{
                  text: 'Cliente',
                }}
                onSearch={(search) => setCustomerFilter(search)}
                disabled={!!serviceOrder}
                items={parseDataToComboboxV2(customers, 'name')}
                value={
                  customer ? { label: 'name', value: customer } : undefined
                }
                onChange={(selected) => {
                  const customer = selected as ComboboxItem<SortedCustomer>
                  form.setValue('customer', customer?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                  form.setValue('account', undefined)
                  form.setValue('contact', undefined)
                }}
                isLoading={isFetchingCustomers}
                isError={isErrorCustomers}
                onEndReached={fetchNextCustomersPage}
                errorMessage={(errors.customer as FieldError)?.message}
              />

              <Combobox
                {...register('account')}
                id="account-input"
                label={{
                  text: 'Conta',
                }}
                items={parseDataToComboboxV2(
                  accounts?.map((account) => ({
                    id: account?.id,
                    code: account?.code,
                    aggregatedName: account.aggregatedAccountName,
                  })) || [],
                  'aggregatedName',
                )}
                value={
                  account
                    ? {
                        label: 'aggregatedName',
                        value: account,
                      }
                    : undefined
                }
                onChange={(selected) => {
                  const account =
                    selected as ComboboxItem<AccountWithAggregatedName>

                  form.setValue('account', account.value, {
                    shouldDirty: true,
                  })
                }}
                isLoading={isLoading}
                disabled={!!serviceOrder || !customer}
                errorMessage={(errors.account as FieldError)?.message}
                onSearch={(filter) => setSearchAccount(filter)}
              />

              <Combobox
                {...register('type')}
                id="so-input"
                label={{ text: 'Tipo de OS' }}
                items={[...Object.values(SERVICE_ORDER_TYPE_EN_PT)]}
                value={
                  serviceOrderType
                    ? SERVICE_ORDER_TYPE_EN_PT[serviceOrderType]
                    : undefined
                }
                onChange={(selected) => {
                  const type = selected as SERVICE_ORDER_TYPE_EN_PT
                  form.setValue('type', SERVICE_ORDER_TYPE_PT_EN[type], {
                    shouldDirty: true,
                  })
                }}
                errorMessage={errors.type?.message}
              />

              {!!serviceOrders?.data.length && (
                <div className={styles.toast}>
                  <div className={styles.toastContent}>
                    <Icon name="information" className={styles.toastIcon} />
                    <p className={styles.toastContentText}>
                      Existe uma ordem de serviço <br /> aberta para esta conta.
                    </p>
                  </div>
                  <button
                    className={styles.buttonToast}
                    onClick={() => {
                      navigate(`/so/info/${serviceOrders.data[0].id}`)
                    }}
                  >
                    ACESSAR
                  </button>
                </div>
              )}

              <Combobox
                {...register('contact')}
                id="contact-input"
                label={{
                  text: 'Contato Responsável',
                }}
                items={parseDataToComboboxV2(
                  handleServiceOrderContacts(contacts || []),
                  'name',
                )}
                value={
                  contact
                    ? {
                        label: 'name',
                        value: contact.id ? contact : otherContact,
                      }
                    : undefined
                }
                onChange={(selecteds) => {
                  const selectedContact =
                    selecteds as ComboboxItem<ServiceOrderContact>
                  setValue('contact', selectedContact?.value, {
                    shouldDirty: true,
                  })
                }}
                isLoading={isFetchingContacts}
                disabled={!form.getValues('customer')}
              />

              {contact && !contact?.id ? (
                <div className={styles.anotherContactData}>
                  <Input
                    label="Nome"
                    id="contact-name"
                    autoComplete="off"
                    placeholder="Nome completo"
                    value={
                      contact && contact.name !== 'Outro' ? contact.name : ''
                    }
                    onChange={(e) => {
                      form.setValue('contact', {
                        ...contact,
                        name: e.target?.value,
                      })
                      form.trigger('contact')
                    }}
                    errorMessage={(errors.contact?.name as FieldError)?.message}
                  />
                  <Input
                    label="Telefone"
                    errorMessage={
                      (errors.contact?.number as FieldError)?.message
                    }
                    maxLength={15}
                    value={
                      contact
                        ? formatGenericPhone(
                            String(contact.provinceCode || '') +
                              String(contact.number || ''),
                          )
                        : ''
                    }
                    onChange={(e) => {
                      form.setValue('contact', {
                        ...form.getValues('contact'),
                        countryCode: 55,
                        provinceCode: e.target?.value
                          .replace(/\D/g, '')
                          .substring(0, 2),
                        number: e.target?.value.replace(/\D/g, '').substring(2),
                      })
                      form.trigger('contact')
                    }}
                  />
                </div>
              ) : null}

              <Combobox
                {...register('technician')}
                id="technician-input"
                label={{
                  text: 'Técnico responsável',
                }}
                value={technician?.name || ''}
                itemLabel={(item) => {
                  const technicianItem =
                    item as ComboboxItem<QueryTechniciansResult>

                  return technicianItem.value.isAccountTechnician
                    ? 'Responsável'
                    : ''
                }}
                onSearch={(value) => handleFilter(value)}
                items={parseDataToComboboxV2(technicians || [], 'name')}
                onChange={(selected) => {
                  const technician =
                    selected as ComboboxItem<QueryTechniciansResult>

                  if (technician) {
                    form.setValue('technician', technician.value, {
                      shouldDirty: true,
                    })
                  }
                }}
                isLoading={isFetchingTechnicians}
                isError={isErrorTechnicians}
                onEndReached={fetchNextTechniciansPage}
                errorMessage={(errors.technician as FieldError)?.message}
              />
            </div>
            <div className={styles.formWrapper}>
              <Combobox
                {...register('tags')}
                id="service-order-tags"
                label={{
                  text: 'Tags',
                }}
                multiple
                onSearch={(search) => setTagFilter(search)}
                items={serviceOrderTags?.map(
                  (serviceOrderTag) => serviceOrderTag.name,
                )}
                value={tags?.map((serviceOrderTag) => serviceOrderTag.name)}
                onChange={(selecteds) => {
                  const tagsSelected = selecteds as string[]
                  setValue(
                    'tags',
                    tagsSelected.map((tag) => ({ name: tag }), {
                      shouldDirty: true,
                    }),
                  )
                }}
                isLoading={isFetchingServiceOrderTags}
                isError={isErrorServiceOrderTags}
                onEndReached={fetchNextTagsPage}
              />
              <Combobox
                {...register('labels')}
                id="origin-input"
                label={{
                  text: 'Solicitante',
                }}
                onSearch={(search) => setOriginFilter(search)}
                items={parseDataToComboboxV2(
                  origins.map((origin) => ({
                    id: origin.id,
                    description: origin.description,
                    type: origin.type,
                    active: origin.active,
                  })),
                  'description',
                )}
                value={
                  originLabel
                    ? { label: 'description', value: originLabel }
                    : undefined
                }
                onChange={(selected) => {
                  const origin =
                    selected as ComboboxItem<ServiceOrderLabelOutput>
                  const { createdAt, updatedAt, ...originLabel } = origin.value
                  const newLabels: ServiceOrderLabelInput[] = [originLabel]

                  const reasonLabel = labels?.find(
                    (label) => label.type === serviceOrderLabelType.REASON,
                  )
                  reasonLabel && newLabels.push(reasonLabel)

                  form.setValue('labels', newLabels, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }}
                isLoading={isFetchingOrigins}
                isError={isOriginError}
                onEndReached={fetchNextOriginPage}
              />
              <Combobox
                id="reason-input"
                label={{
                  text: 'Motivo',
                }}
                onSearch={(search) => setReasonFilter(search)}
                items={parseDataToComboboxV2(
                  reasons.map((reason) => ({
                    id: reason.id,
                    description: reason.description,
                    type: reason.type,
                    active: reason.active,
                  })),
                  'description',
                )}
                value={
                  reasonLabel
                    ? { label: 'description', value: reasonLabel }
                    : undefined
                }
                onChange={(selected) => {
                  const reason =
                    selected as ComboboxItem<ServiceOrderLabelOutput>
                  const { createdAt, updatedAt, ...reasonLabel } = reason.value

                  const newLabels: ServiceOrderLabelInput[] = [reasonLabel]

                  const originLabel = labels?.find(
                    (label) => label.type === serviceOrderLabelType.ORIGIN,
                  )
                  originLabel && newLabels.push(originLabel)

                  form.setValue('labels', newLabels, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }}
                isLoading={isFetchingReasons}
                isError={isReasonError}
                onEndReached={fetchNextReasonPage}
              />
              <div className={styles.textareaContainer}>
                <label htmlFor="textarea" className={styles.textareaLabel}>
                  Observações
                </label>
                <textarea
                  {...register('note')}
                  id="textarea"
                  className={styles.textarea}
                  rows={6}
                  maxLength={5000}
                  onChange={(e) => {
                    form.setValue('note', e.target?.value, {
                      shouldDirty: true,
                    })
                  }}
                  placeholder="Descreva o serviço a ser realizado"
                />
              </div>
              <div>
                <p className={styles.scheduleLabel}>Agendamento</p>
                <p className={styles.scheduleInfo}>
                  {formatScheduleDateText()}
                </p>
              </div>

              <Button
                type="tertiary"
                buttonTitle={
                  scheduleDate
                    ? 'Editar agendamento da ordem de serviço'
                    : 'Agendar ordem de serviço'
                }
                icon={scheduleDate ? EditIcon : PlusIcon}
                disabled={!customer || !account}
                onClick={() => {
                  setScheduledModalOpen(true)
                }}
              />
            </div>
          </div>
        </form>
        <div className={styles.footer}>
          {serviceOrder && (
            <Button
              buttonTitle={`Notas da OS ${
                notes?.length ? `( ${notes.length} )` : ''
              }`}
              onClick={toggleNotes.show}
              type="secondary"
              width="172px"
              icon={NotesIcon}
            />
          )}
          <div
            className={`${styles.footerRight} ${serviceOrder ? styles.groupedBtns : ''}`}
          >
            <div ref={tooltipRef} onClick={tooltip.show}>
              <Button
                buttonTitle="Voltar"
                onClick={handleClose}
                type={triedToClose ? 'cancel-secondary' : 'secondary'}
                width="172px"
              />
            </div>
            {triedToClose && (
              <Tooltip
                positionAbove={POSITION.TOP}
                parentRef={tooltipRef}
                type="informative"
                isVisible={tooltip.isVisible}
              >
                <span>
                  Existem informações que não foram salvas. Deseja realmente
                  fechar?
                </span>
              </Tooltip>
            )}
          </div>
          <Button
            disabled={disableSubmit}
            buttonTitle="Salvar"
            type="primary"
            width="172px"
            onClick={checkScheduled}
          />
        </div>
      </div>
    </FormProvider>
  )
}
