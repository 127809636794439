export enum ScheduleType {
  BUSINESS_HOURS = 0,
  DAY_SHIFT = 1,
  FIXED_SCHEDULE = 2,
}

export enum DayShift {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
}
