import { useUserInfo } from 'shared/hooks'

export type MenuAccessProps = {
  hierarchyAccess: (permission: string) => boolean
}

export const useHierarchyAccess = (): MenuAccessProps => {
  const { userInfo } = useUserInfo()

  const hierarchyAccess = (permissionName: string) => {
    if (userInfo?.permissions) {
      const userPermissionKeys = Object.keys(userInfo.permissions).filter(
        (permission) => userInfo?.permissions[permission],
      )

      const hasPermissionRequired = userPermissionKeys.some((permission) =>
        permission.includes(permissionName),
      )

      return hasPermissionRequired
    }

    return false
  }

  return {
    hierarchyAccess,
  }
}
