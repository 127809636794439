import { AxiosInstance } from 'axios'
import HttpClient from '../httpClient'

import {
  HolidayPayload,
  AggregatedHolidayResponse,
  HolidayQueryRequest,
  HolidayQueryResponse,
} from 'services/holiday/types'
import { Result } from 'services/types'

export interface HolidayDriver {
  create(holiday: HolidayPayload): Promise<HolidayPayload>
  update(id: string, data: HolidayPayload): Promise<void>
  findById(id: string): Promise<AggregatedHolidayResponse>
  delete(id: string): Promise<void>
  query(query: HolidayQueryRequest): Promise<Result<HolidayQueryResponse>>
}

export class HolidayDriverImpl implements HolidayDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async query(
    query?: HolidayQueryRequest,
  ): Promise<Result<HolidayQueryResponse>> {
    const result = await this.httpClient.get<Result<HolidayQueryResponse>>(
      '/holiday/query',
      { params: query },
    )

    return result.data
  }

  public async create(holiday: HolidayPayload): Promise<HolidayPayload> {
    return await this.httpClient.post('/holiday', holiday)
  }

  public async update(id: string, data: HolidayPayload): Promise<void> {
    return await this.httpClient.put(`/holiday/${id}`, data)
  }

  public async findById(id: string): Promise<AggregatedHolidayResponse> {
    return await this.httpClient
      .get<AggregatedHolidayResponse>(`/holiday/${id}`)
      .then((res) => res.data)
  }

  public async delete(id: string): Promise<void> {
    await this.httpClient.delete(`/holiday/${id}`)
  }
}

export const HolidayDriver = new HolidayDriverImpl()
