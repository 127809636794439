import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Breadcrumbs,
  Wizard,
  Loader,
  EmptyState,
  Gallery,
} from 'components'

import {
  Central,
  PowerStatus,
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_STATUS_EN_PT,
  SERVICE_ORDER_TYPE_ENUM,
  SERVICE_ORDER_TYPE_EN_PT,
  ServiceOrderWithStatusUpdateRequest,
  useGetServiceOrderNotes,
} from 'services/serviceOrder'
import {
  useGetServiceOrderInfo,
  useToast,
  useToggle,
  useUserInfo,
} from 'shared/hooks'

import styles from './ServiceOrderInfo.module.scss'
import { ReactComponent as ShareIcon } from 'assets/svg/shareBox.svg'
import { ReactComponent as NotesIcon } from 'assets/svg/note.svg'

import { FormProvider, useForm } from 'react-hook-form'
import { updateServiceOrderSchema } from '../../schemas'
import { joiResolver } from '@hookform/resolvers/joi'
import { useQueryClient } from '@tanstack/react-query'

import { usePersistentTab } from 'domains/occurrence/hooks'
import { CustomerPaths } from 'routes/constants/paths/customer'
import {
  getDevicesImages,
  getToastFailMessage,
  getToastSuccessMessage,
  shouldShowScheduleOrReschedule,
} from 'domains/serviceOrders/utilities'
import { ServiceOrderValidationModal } from 'domains/serviceOrders/components/ServiceOrderValidationModal/ServiceOrderValidationModal'
import { usePutAndHandleServiceOrder } from 'domains/serviceOrders/hooks/services/usePutAndHandleServiceOrder'

import { ServiceOrderSnapshot } from 'domains/serviceOrders/screens/ServiceOrderInfo/components/ServiceOrderSnapshot/ServiceOrderSnapshot'
import { EquipmentsInfo } from './components/EquipmentsInfo/EquipmentsInfo'
import {
  GeneralInfoCard,
  History,
  Notes,
} from 'domains/serviceOrders/components'
import { dateToMilliseconds } from 'utilities/date'
import { UninstalledEquipmentsStatus } from './components/UninstalledEquipmentsStatus/UninstalledEquipmentsStatus'
import { PartitionDevice } from 'services/partition/types'
import { FilterUniqueImages } from 'domains/serviceOrders/utilities/images/filterUniqueImages'
import { ScheduleServiceOrderModal } from 'domains/serviceOrders/components/ScheduleServiceOrderModal'
import { ServiceOrderPayload } from 'domains/serviceOrders/types'
import { ImageResponse } from 'services/image/types'

enum SubmitButtonTitle {
  'REPPROVE' = 'REPROVAR',
  'APPROVE' = 'APROVAR',
}

export function ServiceOrderInfo(): JSX.Element {
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)

  const [validationModalProps, setValidationModalProps] = useState({
    title: '',
    submitButtonTitle: SubmitButtonTitle.APPROVE,
    placeholder: '',
  })

  const { serviceOrderId } = useParams()
  const [isValidating, setIsValidating] = useState(false)

  const {
    data,
    refetch,
    isLoading,
    status: serviceOrderInfoStatus,
  } = useGetServiceOrderInfo(
    serviceOrderId,
    Boolean(serviceOrderId),
    isValidating,
  )

  const OS_MANAGER_URL = `https://os-manager.orsegups.com.br/ubisafe/${String(
    data?.serviceOrderInfo?.number,
  )}`

  const [selectedEventImage, setSelectedEventImage] = useState<string>()

  const { userInfo } = useUserInfo()
  const userId = userInfo.id

  const form = useForm<ServiceOrderWithStatusUpdateRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(updateServiceOrderSchema),
  })

  const { register, watch, setValue, reset } = form

  const status = watch('status')

  const location = useLocation()
  const navigate = useNavigate()
  const { addToast } = useToast()
  const serviceOrderNoteModal = useToggle()
  const galleryModal = useToggle()
  const toggleNotes = useToggle()
  const toggleScheduleModal = useToggle()

  const centralInfoAfterSnapshot = useMemo(
    () =>
      data?.centralInfo?.currentShadow || data?.centralInfo?.finishedSnapshot,
    [data],
  )

  const hasConnectionFailure = (central: Central): boolean => {
    const hasDeviceConnectionFailure = central.partitions.some((partition) => {
      return partition.devices.some(
        (device) => device.lowBattery || device.signal === 0,
      )
    })

    const hasCentralConnectionFailure =
      !central?.gprs?.connectionStatus ||
      central?.powerStatus === PowerStatus.OFF

    return hasDeviceConnectionFailure || hasCentralConnectionFailure
  }

  const isUninstallServiceOrder = useMemo(
    () => data?.serviceOrderInfo?.type === SERVICE_ORDER_TYPE_ENUM.UNINSTALL,
    [data],
  )

  const isApproveDisabled = useMemo(
    () =>
      data?.centralInfo?.currentShadow
        ? hasConnectionFailure(data?.centralInfo?.currentShadow)
        : true,
    [data],
  )

  const serviceOrderWithoutComparison =
    !data?.centralInfo?.inProgressSnapshot &&
    Boolean(centralInfoAfterSnapshot) &&
    !data?.centralInfo?.devicesRemoved

  const images = useMemo(() => {
    const localImages: ImageResponse[] = []
    if (data?.centralInfo?.hasCommandSupport) {
      if (centralInfoAfterSnapshot) {
        centralInfoAfterSnapshot.centralImage &&
          localImages.push({
            id: centralInfoAfterSnapshot.centralImage.id,
            creation:
              dateToMilliseconds(
                centralInfoAfterSnapshot.centralImage.dateTime,
              ) || 0,
            title: 'Central',
            labels: [],
            hidden: false,
            owners: [],
            url: '',
          })

        centralInfoAfterSnapshot.partitions.map((partition) => {
          partition.devices &&
            localImages.push(...getDevicesImages(partition.devices))
          partition.changedDevices &&
            localImages.push(...getDevicesImages(partition.changedDevices))
          return localImages
        })
      }
    }

    const galleryImages =
      data?.serviceOrderInfo?.gallery?.map((image) => ({
        id: image.id,
        creation: dateToMilliseconds(String(image.creation)) || 0,
        labels: [],
        hidden: false,
        owners: [],
        url: '',
      })) || []

    localImages.push(...galleryImages)

    return FilterUniqueImages(localImages)
  }, [centralInfoAfterSnapshot, data])

  const handleOpenGallery = useCallback(
    () => (images.length ? galleryModal.show() : undefined),
    [images, galleryModal],
  )

  const onCloseGallery = () => {
    setSelectedEventImage(undefined)
    galleryModal.hide()
  }

  const queryClient = useQueryClient()
  const { replaceUrl } = usePersistentTab('service-order-account-tab')

  const { data: notes } = useGetServiceOrderNotes(serviceOrderId)

  const isShowMarketButton = useMemo(() => {
    if (!data) return false
    if (data.serviceOrderInfo.status !== SERVICE_ORDER_STATUS_ENUM.CANCELED)
      return true
    return false
  }, [data])

  const onInspectAccount = useCallback(() => {
    if (data) {
      const { serviceOrderInfo: serviceOrder } = data
      localStorage.setItem('accountId', serviceOrder.account.id || '')
      localStorage.setItem('accountCode', serviceOrder?.account?.code || '')

      if (serviceOrder?.account && serviceOrder.patrimony) {
        localStorage.setItem(
          'aggregatedAccountName',
          `${serviceOrder.account.code} - ${serviceOrder.patrimony.name}`,
        )
      }

      localStorage.setItem('patrimonyId', serviceOrder?.patrimony?.id || '')
      localStorage.setItem('customerId', serviceOrder?.customer?.id || '')
      localStorage.setItem('customerName', serviceOrder?.customer?.name || '')

      replaceUrl(`${CustomerPaths.Account}`)
    }
  }, [replaceUrl, data])

  const handleOnSuccessUpdateServiceOrder = useCallback(() => {
    if (status === SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS) {
      onInspectAccount()
    }

    if (
      status === SERVICE_ORDER_STATUS_ENUM.PAUSED ||
      status === SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS
    ) {
      refetch()
      reset()
    }

    if (
      status === SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION ||
      status === SERVICE_ORDER_STATUS_ENUM.FINISHED ||
      status === SERVICE_ORDER_STATUS_ENUM.REPROVED
    ) {
      addToast(getToastSuccessMessage(status))
      return navigate(-1)
    }
  }, [addToast, status, onInspectAccount, refetch, reset, navigate])

  const handleOnErrorUpdateServiceOrder = useCallback(() => {
    addToast(getToastFailMessage(status))

    if (
      status === SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION ||
      status === SERVICE_ORDER_STATUS_ENUM.FINISHED ||
      status === SERVICE_ORDER_STATUS_ENUM.REPROVED
    ) {
      return navigate(-1)
    }
  }, [status, addToast, navigate])

  const { mutatePutServiceOrder, updateServiceOrderStatus } =
    usePutAndHandleServiceOrder({
      onSuccess: handleOnSuccessUpdateServiceOrder,
      onError: handleOnErrorUpdateServiceOrder,
    })

  const isRemoteMaintenance =
    data?.serviceOrderInfo?.type === SERVICE_ORDER_TYPE_ENUM.REMOTE_MAINTENANCE

  const centralCommandPayload: Record<
    SERVICE_ORDER_STATUS_ENUM,
    'defaultMode' | 'maintenanceMode' | undefined
  > = {
    [SERVICE_ORDER_STATUS_ENUM.PAUSED]: isRemoteMaintenance
      ? undefined
      : 'defaultMode',
    [SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION]: isRemoteMaintenance
      ? undefined
      : 'defaultMode',
    [SERVICE_ORDER_STATUS_ENUM.FINISHED]:
      isValidating || isRemoteMaintenance ? undefined : 'defaultMode',
    [SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS]: isRemoteMaintenance
      ? undefined
      : 'maintenanceMode',
    [SERVICE_ORDER_STATUS_ENUM.REPROVED]: undefined,
    [SERVICE_ORDER_STATUS_ENUM.CANCELED]: undefined,
    [SERVICE_ORDER_STATUS_ENUM.OPEN]: undefined,
    [SERVICE_ORDER_STATUS_ENUM.SCHEDULED]: undefined,
  }

  const handleUpdateServiceOrder = useCallback(
    (inputNote?: string, tags?: string[], technicianId?: string) => {
      let note = ''
      if (status === SERVICE_ORDER_STATUS_ENUM.FINISHED) {
        note = inputNote || 'Ordem de serviço aprovada.'
      } else {
        if (inputNote) note = inputNote
      }

      const centralPayload = centralCommandPayload[watch('status')]

      mutatePutServiceOrder({
        ...watch(),
        ...(technicianId && { technicianId }),
        ...(note && { note }),
        ...(tags?.length && { tags: tags?.map((tag) => ({ name: tag })) }),
        ...(data?.centralInfo?.currentCentralId &&
          centralPayload && {
            central: {
              id: data.centralInfo.currentCentralId,
              mode: centralPayload,
            },
          }),
      })
    },
    [mutatePutServiceOrder, status, watch, data, centralCommandPayload],
  )

  const handleActionOnClick = (device: PartitionDevice) => {
    if (device.installationImage?.id) {
      setSelectedEventImage(device.installationImage.id)
      galleryModal.show()
    }
  }

  useEffect(() => {
    if (serviceOrderInfoStatus === 'error') {
      navigate('/so/search?page=1')

      addToast({
        type: 'alert',
        message:
          'Não foi possível recuperar as informações da ordem de serviço. Tente novamente',
      })
    }
  }, [data, navigate, serviceOrderInfoStatus, addToast])

  useEffect(() => {
    const [path] = location.pathname.split('/').reverse()
    if (path === 'validate') {
      setIsValidating(true)
    }
  }, [location.pathname])

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: ['image'] })
    }
  }, [queryClient])

  const handleScheduleServiceOrder = (data: ServiceOrderPayload) => {
    const {
      id,
      status,
      note,
      technician,
      contact,
      scheduleDate,
      schedulePeriod,
      tags,
      account,
      type,
      labels,
    } = data

    const userId = userInfo?.id

    mutatePutServiceOrder(
      {
        id,
        status,
        note,
        technicianId: technician?.id,
        userId,
        contact,
        scheduleDate,
        accountId: account?.id || '',
        serviceOrderType: type,
        ...(schedulePeriod && { schedulePeriod }),
        ...(tags?.length && {
          tags: tags.map((tag) => ({ name: tag.name })),
        }),
        labels,
      },
      {
        onSuccess: () => {
          addToast({ message: 'Ordem de serviço agendada com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          addToast({
            message: 'Erro ao editar ordem de serviço. Tente novamente.',
            type: 'alert',
          })
        },
      },
    )
  }

  useEffect(() => {
    register('id')
    register('accountId')
    register('note')
    register('status')
    register('serviceOrderType')
    register('tags')
    register('technicianId')
    register('userId')
    register('labels')

    setValue('id', data?.id)
    setValue('userId', userId)
    setValue('technicianId', data?.serviceOrderInfo?.technician?.id || '')
    setValue('accountId', data?.serviceOrderInfo?.account?.id || '')
    setValue('serviceOrderType', data?.serviceOrderInfo?.type)
    setValue(
      'tags',
      data?.serviceOrderInfo?.tags?.map((tag) => ({
        name: tag.name,
      })),
    )
  }, [register, serviceOrderId, setValue, userId, data])

  if (!data || isLoading || updateServiceOrderStatus === 'pending') {
    return <Loader isVisible />
  }

  return (
    <>
      <ServiceOrderValidationModal
        modalProps={{
          ...validationModalProps,
          serviceOrderTags: data.serviceOrderInfo.tags,
          serviceOrderStatus: status,
        }}
        isVisible={serviceOrderNoteModal.isVisible}
        onClose={() => {
          serviceOrderNoteModal.hide()
        }}
        onSave={(note, tags) => handleUpdateServiceOrder(note, tags)}
      />
      <Notes
        isVisible={toggleNotes.isVisible}
        onClose={toggleNotes.hide}
        serviceOrderId={serviceOrderId}
      />

      {toggleScheduleModal.isVisible && (
        <ScheduleServiceOrderModal
          isVisible={toggleScheduleModal.isVisible}
          onClose={() => {
            toggleScheduleModal.hide()
          }}
          serviceOrder={{
            account: data.serviceOrderInfo.account,
            contact: data.serviceOrderInfo?.contact,
            customer: data.serviceOrderInfo.customer,
            id: data.id,
            note: data.serviceOrderInfo.history[0].note,
            number: data.serviceOrderInfo.number,
            scheduleDate: data.serviceOrderInfo.schedule?.date,
            schedulePeriod: data.serviceOrderInfo.schedule?.period,
            status: data.serviceOrderInfo.status,
            technician: data.serviceOrderInfo.technician,
            type: data.serviceOrderInfo.type,
            tags: data.serviceOrderInfo.tags,
          }}
          onSave={(data) => handleScheduleServiceOrder(data)}
        />
      )}

      {galleryModal.isVisible && Boolean(images.length) && (
        <Gallery
          imageId={selectedEventImage || ''}
          onChangeImage={(imageId) => setSelectedEventImage(imageId)}
          images={images}
          onClose={onCloseGallery}
        />
      )}
      <div className={styles.container}>
        <div className={styles.wrapperContentContainer}>
          <Breadcrumbs
            items={[
              {
                title: 'Home',
                href: '/',
              },
              {
                title: 'Ordens de serviço',
                href: '/so/in_validation',
              },
              {
                title: SERVICE_ORDER_STATUS_EN_PT[data.serviceOrderInfo.status],
                href: '',
              },
              {
                title: `${
                  SERVICE_ORDER_TYPE_EN_PT[data.serviceOrderInfo.type]
                } #${data.serviceOrderInfo.number}`,
                href: '',
              },
            ]}
          />
          <div className={styles.headerSeparator} />

          <div className={styles.stepsContainer}>
            <FormProvider {...form}>
              <Wizard activeStepIndex={activeStepIndex}>
                <Wizard.Step title="Informações da OS">
                  <div className={styles.wrapperOSValidation}>
                    <GeneralInfoCard
                      serviceOrderId={data.id}
                      serviceOrder={{
                        ...data.serviceOrderInfo,
                        account: {
                          ...data.serviceOrderInfo?.account,
                          centralId: data.centralInfo?.currentCentralId,
                        },
                      }}
                      onPressGallery={handleOpenGallery}
                      onSubmit={() =>
                        handleUpdateServiceOrder(
                          undefined,
                          undefined,
                          data?.serviceOrderInfo?.technician?.id
                            ? data.serviceOrderInfo.technician.id
                            : String(userId),
                        )
                      }
                    />
                    <section className={styles.serviceOrderHistory}>
                      <h1>Histórico</h1>
                      <div className={styles.serviceOrderHistoryWrapper}>
                        <History
                          serviceOrderHistory={data.serviceOrderInfo.history}
                        />
                      </div>
                    </section>
                  </div>
                </Wizard.Step>

                <Wizard.Step title="Equipamentos">
                  {data.centralInfo?.currentCentralId ? (
                    <div className={styles.containerFade}>
                      {data.centralInfo?.inProgressSnapshot && (
                        <ServiceOrderSnapshot
                          serviceOrderInfo={data}
                          handleSelect={handleActionOnClick}
                          onPressGallery={handleOpenGallery}
                        />
                      )}
                      {serviceOrderWithoutComparison &&
                        centralInfoAfterSnapshot?.partitions.length && (
                          <div className={styles.currentShadow}>
                            <EquipmentsInfo
                              serviceOrder={{
                                serviceOrderId: data?.id,
                                customerId:
                                  data?.serviceOrderInfo?.customer?.id,
                                accountId: data?.serviceOrderInfo?.account?.id,
                              }}
                              centralInfo={centralInfoAfterSnapshot}
                              handleSelect={handleActionOnClick}
                              onPressGallery={handleOpenGallery}
                              model={data.centralInfo?.model}
                            />
                          </div>
                        )}
                      {isUninstallServiceOrder &&
                        centralInfoAfterSnapshot?.partitions.length && (
                          <UninstalledEquipmentsStatus
                            devicesRemoved={data.centralInfo?.devicesRemoved}
                            centralInfo={centralInfoAfterSnapshot}
                          />
                        )}
                    </div>
                  ) : (
                    <div className={styles.emptyEquipments}>
                      <EmptyState type="EmptyEquipment" />
                    </div>
                  )}
                </Wizard.Step>
              </Wizard>
            </FormProvider>
          </div>
        </div>

        <footer
          className={[
            isValidating && activeStepIndex === 1
              ? styles.containerFooterValidating
              : styles.containerFooter,
          ].join(' ')}
        >
          <div>
            <Button
              buttonTitle={`Notas da OS ${
                notes?.length ? `( ${notes.length} )` : ''
              }`}
              onClick={toggleNotes.show}
              type="secondary"
              width="172px"
              icon={NotesIcon}
            />
            {shouldShowScheduleOrReschedule(data.serviceOrderInfo.status) && (
              <Button
                buttonTitle={
                  data.serviceOrderInfo.schedule?.date
                    ? 'Reagendar OS'
                    : 'Agendar OS'
                }
                onClick={toggleScheduleModal.show}
                type="secondary"
                width="172px"
              />
            )}
          </div>
          <div>
            {activeStepIndex === 0 && isShowMarketButton && (
              <Button
                width="170px"
                type="tertiary"
                icon={ShareIcon}
                className={styles.osManager}
                buttonTitle="Compras"
                onClick={() => replaceUrl(OS_MANAGER_URL)}
              />
            )}
            <Button
              width="170px"
              buttonTitle={activeStepIndex === 0 ? 'Voltar à lista' : 'Voltar'}
              onClick={() => {
                if (activeStepIndex === 0) {
                  navigate(-1)
                } else {
                  setActiveStepIndex((previousIndex) => previousIndex - 1)
                }
              }}
              type="secondary"
            />
            {activeStepIndex === 0 && (
              <Button
                width="170px"
                buttonTitle="Próximo"
                onClick={() =>
                  setActiveStepIndex((previousIndex) => previousIndex + 1)
                }
                type="primary"
              />
            )}
            {isValidating && activeStepIndex !== 0 && (
              <>
                <Button
                  id="reprove-button"
                  className={styles.secondStepButton}
                  width="170px"
                  buttonTitle="Reprovar"
                  type="secondary"
                  onClick={() => {
                    setValidationModalProps({
                      title: 'Reprovar ordem de serviço',
                      placeholder:
                        'Digite um comentário sobre a reprovação desta Ordem de Serviço',
                      submitButtonTitle: SubmitButtonTitle.REPPROVE,
                    })
                    setValue('status', SERVICE_ORDER_STATUS_ENUM.REPROVED)
                    serviceOrderNoteModal.show()
                  }}
                />
                <Button
                  width="170px"
                  buttonTitle="Aprovar"
                  type="primary"
                  disabled={
                    isApproveDisabled &&
                    !isUninstallServiceOrder &&
                    data.centralInfo?.hasCommandSupport
                  }
                  onClick={() => {
                    setValidationModalProps({
                      title: 'Aprovar ordem de serviço',
                      placeholder:
                        'Digite um comentário sobre a aprovação desta Ordem de Serviço',
                      submitButtonTitle: SubmitButtonTitle.APPROVE,
                    })
                    setValue('status', SERVICE_ORDER_STATUS_ENUM.FINISHED)
                    serviceOrderNoteModal.show()
                  }}
                />
              </>
            )}
          </div>
        </footer>
      </div>
    </>
  )
}
