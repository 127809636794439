import { useMemo, useState, useCallback, FC, useRef } from 'react'
import ReactDOM from 'react-dom'

import { ReactComponent as CloseIcon } from 'assets/svg/closeIcon.svg'
import { ReactComponent as LeftArrow } from 'assets/svg/left-arrow.svg'
import { ReactComponent as RightArrow } from 'assets/svg/right-arrow.svg'

import { Icon, ProtectedImage } from 'components'
import { maskedDateTime } from 'utilities/date'

import {
  BackDrop,
  UserVerification,
  ExpandableText,
  Loader,
} from './components'

import styles from './styles.module.scss'
import { formatDetectLabels } from 'utilities/image'
import { DetectionName, GalleryData } from 'services/image/types'
import { FormattedDetectLabelInstance } from './types'
import { getDetectionsLabel } from './utils'

import { useToast } from 'shared/hooks'
import { useDeleteImage } from 'services/serviceOrderV2/useDeleteImage'

const Gallery: FC<GalleryData> = ({ imageId, images, onClose }) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const miniatureRef = useRef<HTMLImageElement>(null)

  const [galleryImages, setGalleryImages] = useState(
    images?.length ? images : [],
  )
  const [selectedImageId, setSelectedImage] = useState(imageId)
  const [selectedBoundingBox, setSelectedBoundingBox] =
    useState<FormattedDetectLabelInstance | null>(null)

  const { mutate: mutateDeleteImage } = useDeleteImage(selectedImageId)

  const { addToast } = useToast()

  const slideIndex = useMemo(() => {
    return galleryImages?.findIndex((image) => image.id === selectedImageId)
  }, [galleryImages, selectedImageId])

  const selectedImage = useMemo(() => {
    return (
      galleryImages?.find((image) => image.id === selectedImageId) ||
      galleryImages?.[0]
    )
  }, [galleryImages, selectedImageId])

  const scrollToActiveThumbnail = () => {
    const activeThumbnail = document.getElementById('active')

    activeThumbnail?.scrollIntoView?.({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
  }

  const resetRotate = () => {
    ;[imageRef, miniatureRef].forEach((ref) => {
      if (ref.current) ref.current.style.transform = ''
    })
  }

  const handleNextImage = useCallback(() => {
    if (galleryImages) {
      const imageId =
        galleryImages[
          slideIndex !== galleryImages.length - 1 ? slideIndex + 1 : 0
        ].id
      setSelectedImage(imageId)
      scrollToActiveThumbnail()
    }
    resetRotate()
    setSelectedBoundingBox(null)
  }, [galleryImages, slideIndex])

  const handlePreviousImage = useCallback(() => {
    const imageId =
      galleryImages[
        slideIndex !== 0 ? slideIndex - 1 : galleryImages.length - 1
      ].id
    if (galleryImages) {
      setSelectedImage(imageId)
      scrollToActiveThumbnail()
    }
    resetRotate()
    setSelectedBoundingBox(null)
  }, [galleryImages, slideIndex])

  const instances = useMemo(() => {
    if (!selectedImage || !selectedImage.labels) {
      return []
    }

    const formatedLabels = formatDetectLabels(selectedImage.labels)
    return formatedLabels.reduce(
      (previous: FormattedDetectLabelInstance[], current) => {
        const result: FormattedDetectLabelInstance[] = current.instances.map(
          (instance, index) => ({
            ...instance,
            id: `${index}${instance.confidence}`,
            label: current.name,
          }),
        )

        return [...previous, ...result]
      },
      [],
    )
  }, [selectedImage])

  const detectionsCount = useMemo(
    () => (instances.length ? instances.length : 0),
    [instances],
  )

  const handleRotate = (direction: 'left' | 'right') => {
    ;[imageRef, miniatureRef].forEach((ref) => {
      if (
        ref.current &&
        (ref.current.id === 'active' || ref.current.id === selectedImage.id)
      ) {
        const currentRotation = ref.current.style.transform
          ? parseFloat(
              ref.current.style.transform.replace(
                /rotate\(([-\d]+)deg\)/,
                '$1',
              ),
            )
          : 0
        const newRotation =
          direction === 'left' ? currentRotation - 90 : currentRotation + 90
        ref.current.style.transform = `rotate(${newRotation}deg)`
      }
    })
  }

  const imageTitle = useMemo(() => {
    const note = selectedImage?.note
    const label = selectedBoundingBox?.id
      ? `${selectedBoundingBox?.label === DetectionName.PERSON ? 'Pessoa' : 'Pet'} (${Math.round(
          selectedBoundingBox.confidence,
        )})% de precisão`
      : ''
    const detections =
      !selectedBoundingBox?.id && detectionsCount
        ? `${detectionsCount} ${
            detectionsCount > 1 ? 'detecções' : 'detecção'
          } (${getDetectionsLabel(instances)})`
        : ''
    return [note, label, detections].filter(Boolean).join('\n')
  }, [selectedImage?.note, selectedBoundingBox?.id, detectionsCount])

  const handleFormatDate = () => {
    const date = new Date(selectedImage.creation)

    const dateMinus3Hours = new Date(date.getTime() - 3 * 60 * 60 * 1000)

    return dateMinus3Hours.getTime()
  }

  return (
    <>
      {ReactDOM.createPortal(
        <BackDrop onClose={onClose} className={styles.wrapper}>
          <main className={styles.modal}>
            <button
              className={styles.closeButton}
              type="button"
              onClick={onClose}
            >
              <CloseIcon width={16} height={16} />
            </button>
            <div className={styles.container}>
              {selectedImage ? (
                <>
                  <ProtectedImage
                    id={selectedImage.id}
                    ref={imageRef}
                    imageId={selectedImage.id}
                  />
                  {imageTitle && <ExpandableText text={imageTitle} />}
                  {Boolean(instances && instances.length) &&
                    instances.map((instance) => (
                      <div
                        key={instance.id}
                        aria-label="bounding-box"
                        className={[
                          styles.boundingBox,
                          selectedBoundingBox &&
                          selectedBoundingBox.id !== instance.id
                            ? styles.lessOpaque
                            : '',
                        ].join(' ')}
                        style={{
                          position: 'absolute',
                          width: `${instance.boundingBox.width * 100}%`,
                          height: `${instance.boundingBox.height * 100}%`,
                          top: `${instance.boundingBox.top * 100}%`,
                          left: `${instance.boundingBox.left * 100}%`,
                        }}
                        onClick={() => {
                          if (
                            selectedBoundingBox?.id &&
                            selectedBoundingBox?.id === instance.id
                          ) {
                            setSelectedBoundingBox(null)
                            return
                          }
                          setSelectedBoundingBox(instance)
                        }}
                      />
                    ))}
                  {selectedImage?.creation && (
                    <div className={styles.imageTitle}>
                      <span>
                        {[
                          selectedImage?.title,
                          maskedDateTime(handleFormatDate()),
                        ]
                          .filter(Boolean)
                          .join(' - ')}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <Loader />
              )}
            </div>

            <div className={styles.actions}>
              <UserVerification
                onFinish={() => {
                  mutateDeleteImage(null, {
                    onSuccess: () => {
                      addToast({
                        message: 'Foto excluída com sucesso.',
                      })
                    },
                    onError: () => {
                      addToast({
                        message: 'Erro ao excluir foto. Tente novamente.',
                        type: 'alert',
                      })
                    },
                  })

                  handleNextImage()
                  setGalleryImages((prev) => [
                    ...prev.filter((image) => image.id !== selectedImage.id),
                  ])
                }}
              />
              <div>
                <Icon
                  name="turn-left"
                  width={16}
                  color="neutral"
                  onClick={() => handleRotate('left')}
                />
              </div>

              <div>
                <Icon
                  name="turn-right"
                  width={16}
                  color="neutral"
                  onClick={() => handleRotate('right')}
                />
              </div>
            </div>

            <ul className={styles.imagesList}>
              {galleryImages?.map((image) => (
                <li
                  key={image.id}
                  className={[
                    selectedImage?.id === image.id && styles.imageSelected,
                  ].join(' ')}
                >
                  <ProtectedImage
                    id={image.id === selectedImage.id ? 'active' : ''}
                    ref={image.id === selectedImage.id ? miniatureRef : null}
                    imageId={image.id}
                    onClick={() => {
                      setSelectedImage(image.id)
                      scrollToActiveThumbnail()
                      setSelectedBoundingBox(null)
                      resetRotate()
                    }}
                  />
                </li>
              ))}
            </ul>
            <button
              type="button"
              onClick={handlePreviousImage}
              className={[styles.actionButton, styles.previousButton].join(' ')}
            >
              <LeftArrow />
            </button>
            <button
              type="button"
              onClick={handleNextImage}
              className={[styles.actionButton, styles.nextButton].join(' ')}
            >
              <RightArrow />
            </button>
          </main>
        </BackDrop>,
        document.body,
      )}
    </>
  )
}

export default Gallery
