import {
  createContext,
  useContext,
  useState,
  MouseEvent,
  ComponentProps,
  Children,
  cloneElement,
  isValidElement,
} from 'react'
import joinClassNames from 'utilities/joinClassNames'

import styles from './styles.module.scss'

type TabsContextType = {
  activeIndex: number
  setActiveIndex: (index: number) => void
}

const TabsContext = createContext<TabsContextType | undefined>(undefined)

interface RootProps extends ComponentProps<'div'> {
  index?: number
}

const Root = ({ children, className, index = 0, ...props }: RootProps) => {
  const [activeIndex, setActiveIndex] = useState(index)

  return (
    <TabsContext.Provider value={{ activeIndex, setActiveIndex }}>
      <div className={joinClassNames(styles.tabs, className)} {...props}>
        {children}
      </div>
    </TabsContext.Provider>
  )
}

const List = ({ children, className, ...props }: ComponentProps<'div'>) => (
  <div className={joinClassNames(styles.list, className)} {...props}>
    {Children.map(children, (child, index) => {
      if (isValidElement(child) && child.type === Tab) {
        return cloneElement(child, { index })
      }

      return child
    })}
  </div>
)

interface TabProps extends ComponentProps<'button'> {
  index?: number
}

const Tab = ({ children, className, index, onClick, ...props }: TabProps) => {
  const context = useContext(TabsContext)

  if (!context) {
    throw new Error('Tab must be used within a Tabs provider')
  }

  const { activeIndex, setActiveIndex } = context

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (index !== undefined) {
      setActiveIndex(index)
    }

    onClick?.(event)
  }

  return (
    <button
      className={joinClassNames(
        styles.tab,
        activeIndex === index && styles.active,
        className,
      )}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  )
}

export const Panels = ({ children, ...props }: ComponentProps<'div'>) => (
  <div {...props}>
    {Children.map(children, (child, index) => {
      if (isValidElement(child) && child.type === Panel) {
        return cloneElement(child, { index })
      }

      return child
    })}
  </div>
)

interface PanelProps extends ComponentProps<'div'> {
  index?: number
}

export const Panel = ({ index, ...props }: PanelProps) => {
  const context = useContext(TabsContext)

  if (!context) {
    throw new Error('TabPanel must be used within a Tabs provider')
  }

  const { activeIndex } = context

  return activeIndex === index && <div {...props} />
}

const Tabs = {
  Root,
  List,
  Tab,
  Panels,
  Panel,
}

export default Tabs
