const scheduleType = {
  BUSINESS_HOURS: 0,
  DAY_SHIFT: 1,
  FIXED_SCHEDULE: 2,
}

export type ScheduleType = keyof typeof scheduleType

const dayShift = {
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
}

export type DayShift = keyof typeof dayShift

export const DayShiftLabel: {
  name: string
  value: DayShift
}[] = [
  {
    name: 'Manhã',
    value: 'MORNING',
  },
  {
    name: 'Tarde',
    value: 'AFTERNOON',
  },
]

export const ScheduleTypeLabel: {
  name: string
  value: ScheduleType
}[] = [
  {
    name: 'Horário comercial',
    value: 'BUSINESS_HOURS',
  },
  {
    name: 'Turno',
    value: 'DAY_SHIFT',
  },
  {
    name: 'Horário fixo',
    value: 'FIXED_SCHEDULE',
  },
]
