import Joi from '@hapi/joi'
import { StringSchemeErrorsEnum } from 'utilities/validation'

export enum PasswordRules {
  empty = 'empty',
  atLeastOneNumber = 'atLeastOneNumber',
  atLeastOneLowerCase = 'atLeastOneLowerCase',
  atLeastOneUpperCase = 'atLeastOneUpperCase',
  atLeastOneSpecialCharacter = 'atLeastOneSpecialCharacter',
}

export const newPasswordSchema = Joi.string()
  .required()
  .min(8)
  .pattern(/\d/, { name: 'atLeastOneNumber' })
  .pattern(/.*[a-z]/, { name: 'atLeastOneLowerCase' })
  .pattern(/.*[A-Z]/, { name: 'atLeastOneUpperCase' })
  .pattern(/\W|_/, { name: 'atLeastOneSpecialCharacter' })

export const changePasswordSchema = Joi.object({
  currentPassword: Joi.string()
    .required()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]: 'A senha atual é obrigatória',
    }),
  newPassword: newPasswordSchema,
  confirmNewPassword: Joi.valid(Joi.ref('newPassword')).messages({
    [StringSchemeErrorsEnum.ANY]: 'As senhas não conferem',
  }),
})

export interface PasswordSchema {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}
