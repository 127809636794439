import HttpClient from 'services/httpClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { ActionPlanContact } from '../types'
import { endpoints } from '../endpoints'
import buildUrl from 'utilities/buildUrl'
import { CentralAggregatedResponse } from 'services/central/types'
import { Result } from 'services/types'

type SetPartitionContactsPayload = {
  partitionId: string
  partitionContacts: ActionPlanContact[]
  accountIds?: string[]
  centralId?: string
}

async function setPartitionContacts({
  partitionContacts,
  partitionId,
}: SetPartitionContactsPayload): Promise<AxiosResponse> {
  return await HttpClient.post(
    buildUrl({
      route: endpoints.setPartitionContacts,
      params: { id: partitionId },
    }),
    {
      partitionContacts,
    },
  )
}

export const usePostPartitionContacts = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: SetPartitionContactsPayload) => {
      await setPartitionContacts(payload)
    },
    onSuccess: (_data, variables) => {
      if (variables.accountIds && variables.centralId) {
        queryClient.setQueryData<Result<CentralAggregatedResponse>>(
          ['queryCentralByAccounts', variables.accountIds],
          (previousData) => {
            if (previousData) {
              return {
                data: previousData.data.map((currentCentral) => {
                  if (currentCentral.id === variables.centralId) {
                    return {
                      ...currentCentral,
                      partitions: currentCentral?.partitions?.map(
                        (partition) =>
                          partition.id !== variables.partitionId
                            ? partition
                            : {
                                ...partition,
                                actionPlan: partition.actionPlan?.map(
                                  (contact) => {
                                    const actionPlanItem =
                                      variables.partitionContacts
                                        .filter((contact) => contact.actionable)
                                        .find(
                                          (item) =>
                                            item.contactId === contact.id,
                                        )
                                    return {
                                      ...contact,
                                      priorityOrder: actionPlanItem?.priority,
                                    }
                                  },
                                ),
                                contacts: partition.contacts?.map((contact) => {
                                  const contactItem =
                                    variables.partitionContacts
                                      .filter((contact) => !contact.actionable)
                                      .find(
                                        (item) => item.contactId === contact.id,
                                      )
                                  return {
                                    ...contact,
                                    priorityOrder: contactItem?.priority,
                                  }
                                }),
                              },
                      ),
                    }
                  }
                  return currentCentral
                }),
                totalElements: previousData.totalElements,
              }
            }

            return undefined
          },
        )
      }
    },
  })
}
