import { ConnectionIPMode } from 'services/serviceOrder'

export const getConnectionIpWithMode = (
  ipMode: ConnectionIPMode,
  ip: string,
): string => {
  if (ipMode === ConnectionIPMode.DHCP) return `DHCP ${ip}`

  return `Estático ${ip}`
}
