import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ServiceOrderDriver,
  ServiceOrder,
  CreateSoError,
} from 'services/serviceOrder'
import { useToast } from 'shared/hooks'
import { formatDecimal } from 'utilities/date'
import { ServiceOrderForm } from '../../components/ServiceOrderForm/ServiceOrderForm'

export const CreateServiceOrder = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()

  const onSave = useCallback(
    async (data: ServiceOrder) => {
      try {
        await ServiceOrderDriver.create(data)
        addToast({ message: 'Ordem de serviço criada com sucesso.' })
        navigate(-1)
      } catch (error) {
        const formatterError = (error as CreateSoError).response
        const message =
          formatterError?.status === 412
            ? `Esta conta já possui uma ordem de serviço (${formatDecimal(
                formatterError.data?.number || 0,
              )}).`
            : 'Não foi possível cadastrar a ordem de serviço. Tente novamente'
        addToast({ message, type: 'alert' })
      }
    },
    [addToast, navigate],
  )

  return <ServiceOrderForm title="Nova ordem de serviço" onSave={onSave} />
}
