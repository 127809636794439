import { Combobox } from 'components/ComboboxV2/Combobox'
import { ActionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import {
  ActionOccurrenceStateName,
  actionOccurrenceStateName,
  convertActionOccurrenceState,
  translatedOccurrenceState,
} from 'domains/attendancePolicy/components/RuleForm/data/occurrenceStateName'

import styles from './StateChangeForm.module.scss'

export const StateChangeForm = ({ onChange, data }: ActionOptionForm) => {
  const comboboxLabel = 'Alterar estado para'

  const { parameters } = data

  return (
    <div className={styles.container}>
      <Combobox
        value={
          parameters?.occurrenceStateName
            ? translatedOccurrenceState[parameters?.occurrenceStateName]
            : undefined
        }
        label={{
          text: comboboxLabel,
        }}
        items={Object.values(actionOccurrenceStateName).map(
          (stateName) => translatedOccurrenceState[stateName],
        )}
        onChange={(selected) => {
          const selectedOccurrenceState = selected as ActionOccurrenceStateName

          onChange({
            parameters: {
              occurrenceStateName:
                convertActionOccurrenceState[selectedOccurrenceState],
            },
          })
        }}
        placeholder="Selecione um ou mais estados"
      />
    </div>
  )
}
