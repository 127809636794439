import { OccurrenceEvent } from 'services/event/types'
import { PhoneResponse, PhoneType } from 'services/contact/types'
import { ContactType, PartitionResponse } from 'services/partition/types'
import { Paging } from 'services/types'
import {
  OCCURRENCE_TYPE,
  OCCURRENCE_TYPE_EN_PT,
  SERVICE_TYPE_EN_PT,
  Coverage,
} from 'domains/attendancePolicy/types'

import { ServiceType } from 'services/serviceType/types'

import { AccountTag } from 'services/account/types'
import { Parameters } from 'services/attendancePolicy/types'

export enum InterventionAttributeStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
}

type CreatedAtRange = {
  createdTo?: number
  createdFrom?: number
}

type Updated = {
  updatedTo?: number
  updatedFrom?: number
}

const occurrenceInterventionTypeName = {
  PHONE_CALL: 'PHONE_CALL',
  IMAGE_VERIFICATION: 'IMAGE_VERIFICATION',
  SEND_MESSAGE: 'SEND_MESSAGE',
  STATE_CHANGE: 'STATE_CHANGE',
  SEND_ALERT: 'SEND_ALERT',
  SEND_IMAGE: 'SEND_IMAGE',
  SEND_CHECKLIST: 'SEND_CHECKLIST',
  CONFIRM_INTRUSION: 'CONFIRM_INTRUSION',
  MANUAL_RECORD: 'MANUAL_RECORD',
  REQUIRE_PHONE_CALL: 'REQUIRE_PHONE_CALL',
  REFUSE_PHONE_CALL_REQUIREMENT: 'REFUSE_PHONE_CALL_REQUIREMENT',
  AUTOMATIC: 'AUTOMATIC',
}

type OccurrenceInterventionTypeName =
  keyof typeof occurrenceInterventionTypeName

const attendanceSentenceInterventionSubtype = {
  ACTION_PLAN: 'ACTION_PLAN',
  CENTRAL: 'CENTRAL',
  PATRIMONY: 'PATRIMONY',
  POLICE: 'POLICE',
  TACTICAL_AGENT: 'TACTICAL_AGENT',
}

type AttendanceSentenceInterventionSubtype =
  keyof typeof attendanceSentenceInterventionSubtype

export type AttendanceSentenceQuery = Paging &
  CreatedAtRange &
  Updated &
  Partial<{
    serviceTypeId: string
    occurrenceTypeId: string
    interventionType: OccurrenceInterventionTypeName
    interventionSubtype: AttendanceSentenceInterventionSubtype
    interventionTagId: string
    status: InterventionAttributeStatus
  }>

export interface OccurrenceTag {
  name: string
}

type AttendanceSentenceRequest = {
  name: string
  sentence: string
  status: InterventionAttributeStatus
  serviceTypeIds: string[]
  occurrenceTypeIds: string[]
  interventionTypes: {
    types: OccurrenceInterventionTypeName[]
    subtypes: AttendanceSentenceInterventionSubtype[]
  }
  tags: OccurrenceTag[]
}
export type AttendanceSentenceResponse = AttendanceSentenceRequest & {
  id: string
  createdAt: number
  updatedAt: number
}

export enum SensorTypeEnum {
  MAIN = 1,
  TAMPER = 2,
  ANTI_SABOTAGE = 3,
  ACCELEROMETER = 4,
  OPENING = 5,
}

const occurrenceTypes = {
  ALARM: 'ALARM',
  RESCUE: 'RESCUE',
  FAILURE: 'FAILURE',
  FINISHED: 'FINISHED',
  IMMINENT: 'IMMINENT',
  EMERGENCY: 'EMERGENCY',
  COMMERCIAL: 'COMMERCIAL',
  SCHEDULED: 'SCHEDULED',
  MANUAL: 'MANUAL',
}

export type OccurrenceTypes = keyof typeof occurrenceTypes

export enum OccurrenceTypeName {
  RESCUE = 'RESCUE',
  EMERGENCY = 'EMERGENCY',
  ALARM = 'ALARM',
  IMMINENT = 'IMMINENT',
  FAILURE = 'FAILURE',
  COMMERCIAL = 'COMMERCIAL',
  MANUAL = 'MANUAL',
  SCHEDULED = 'SCHEDULED',
}

export enum InterventionTagEnum {
  CONTACT_CALL = 'ligacaoContato',
  SAFE_CALL = 'safecall',
  POLICE_CALL = 'ligacaoPolicia',
  SEND_MESSAGE = 'envioMensagem',
  FINISHED = 'finalizada',
  UNDER_OBSERVATION = 'emObservacao',
  VERIFY_IMAGE = 'verificacaoImagem',
}

export type EventTypeEnum =
  | 'ALARM'
  | 'RESCUE'
  | 'EMERGENCY'
  | 'IMMINENT'
  | 'FAILURE'
  | 'COMMERCIAL'
  | 'MANUAL'
  | 'SCHEDULED'

export enum InterventionStateEnum {
  AVAILABLE = 'AVAILABLE',
  HIDDEN = 'HIDDEN',
  IN_PROGRESS = 'IN_PROGRESS',
  QUEUED = 'QUEUED',
  TRAVEL_WAITING = 'TRAVEL_WAITING',
  TRAVEL_FINISHED = 'TRAVEL_FINISHED',
  TRAVEL_STARTED = 'TRAVEL_STARTED',
  TRAVEL_VIEWED = 'TRAVEL_VIEWED',
  TRAVEL_SENT = 'TRAVEL_SENT',
  FINISHED = 'FINISHED',
}

export enum InterventionTypeEnum {
  PHONE_CALL = 'PHONE_CALL',
  SEND_MESSAGE = 'SEND_MESSAGE',
  STATE_CHANGED = 'STATE_CHANGE',
  IMAGE_VERIFICATION = 'IMAGE_VERIFICATION',
  CONFIRM_INTRUSION = 'CONFIRM_INTRUSION',
  MANUAL_RECORD = 'MANUAL_RECORD',
  SEND_IMAGE = 'SEND_IMAGE',
  SEND_ALERT = 'SEND_ALERT',
  SEND_CHECKLIST = 'SEND_CHECKLIST',
  AUTOMATIC = 'AUTOMATIC',
}

export enum OccurrenceCategoryName {
  TACTICAL = 'TACTICAL',
  TECHNICAL = 'TECHNICAL',
  COMMERCIAL = 'COMMERCIAL',
}

const occurrenceStateName = {
  CREATED: 'CREATED',
  AVAILABLE: 'AVAILABLE',
  TRAVEL_WAITING: 'TRAVEL_WAITING',
  TRAVEL_SENT: 'TRAVEL_SENT',
  TRAVEL_VIEWED: 'TRAVEL_VIEWED',
  TRAVEL_STARTED: 'TRAVEL_STARTED',
  TRAVEL_FINISHED: 'TRAVEL_FINISHED',
  IN_PROGRESS: 'IN_PROGRESS',
  QUEUED: 'QUEUED',
  FINISHED: 'FINISHED',
  HIDDEN: 'HIDDEN',
}

export type OccurrenceStateName = keyof typeof occurrenceStateName

export type OccurrenceColumnTitle =
  | 'Disponível'
  | 'Em atendimento'
  | 'Deslocamento'
  | 'Em observação'

export const occurrenceTravelKeys = {
  TRAVEL_WAITING: 'TRAVEL_WAITING',
  TRAVEL_SENT: 'TRAVEL_SENT',
  TRAVEL_VIEWED: 'TRAVEL_VIEWED',
  TRAVEL_STARTED: 'TRAVEL_STARTED',
  TRAVEL_FINISHED: 'TRAVEL_FINISHED',
  FINISHED: 'FINISHED',
}

export type OccurrenceTravel = keyof typeof occurrenceTravelKeys

export type OccurrenceTravelIconStatus =
  | 'DEFAULT'
  | 'SUCCESS'
  | 'ALERT'
  | 'CHECKLIST_FAILURE'

export type TravelStatusIcon = Record<OccurrenceTravel, JSX.Element>

export const OccurrenceManagementColumnFilter = {
  SUPPORT_REQUEST: 'Solicitação de apoio',
  REMOTE: 'Portaria remota',
  HIGH_RISK_CUSTOMER: 'Cliente de alto risco',
  HIGH_RISK_EVENT: 'Evento de alto risco',
  CONFIRMED_INTRUSION: 'Intrusão confirmada',
}

export const OccurrenceManagementColumnTravelFilter = {
  AWAIT: 'Espera',
  UNSEEN: 'Não visualizado',
  SEEN: 'Visualizado',
  STARTED: 'Iniciado',
  ON_SITE: 'No local',
  ...OccurrenceManagementColumnFilter,
}

export type OccurrenceManagementColumnFilterKeys =
  | keyof typeof OccurrenceManagementColumnFilter
  | keyof typeof OccurrenceManagementColumnTravelFilter

export type OccurrenceTagResponse = {
  name: string
}

export type Station = {
  id: string
  name: string
}

interface OccurrenceStation {
  id: string
  name: string
}

export type AgentFragment = {
  id: string
  name: string
}

export type VehicleFragment = {
  id: string
  licensePlate: string
}

interface OccurrenceUser {
  id: string
  name?: string
}

export interface InterventionNote {
  description: string
  user: OccurrenceUser
  station: OccurrenceStation
  createdAt: number
}

const alertType = {
  CHECKLIST_SENT: 'CHECKLIST_SENT',
  TRAVEL_WITHOUT_CHANGES: 'TRAVEL_WITHOUT_CHANGES',
  SUPPORT_REQUEST: 'SUPPORT_REQUEST',
  COORDINATES_UPDATE_REQUEST: 'COORDINATES_UPDATE_REQUEST',
}

export type AlertType = keyof typeof alertType

type InterventionAttributesForIntrusionConfirmation = {
  agent?: AgentFragment
  vehicle?: VehicleFragment
}

type InterventionAgent = {
  id: string
  name: string
}

type InterventionVehicle = {
  id: string
  licensePlate: string
}

export type Coordinates = {
  latitude: number
  longitude: number
}

export type OccurrenceInterventionAttributesForTravel = {
  stateName: OccurrenceStateName | null
  agent: InterventionAgent
  vehicle: InterventionVehicle
  agentCoordinates?: Coordinates
  patrimonyCoordinates?: Coordinates
}

type OccurrenceInterventionAttributesForSendImage = {
  imageIds: string[]
  agent?: InterventionAgent
  vehicle?: InterventionVehicle
}

enum InterventionAlertType {
  CHECKLIST_SENT = 'CHECKLIST_SENT',
  TRAVEL_WITHOUT_CHANGES = 'TRAVEL_WITHOUT_CHANGES',
  SUPPORT_REQUEST = 'SUPPORT_REQUEST',
  COORDINATES_UPDATE_REQUEST = 'COORDINATES_UPDATE_REQUEST',
}

enum OccurrenceChecklistStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

type OccurrenceChecklist = {
  question: string
  answer: string
  status: OccurrenceChecklistStatus
}

type OccurrenceInterventionAttributesForSendAlert = {
  alertId: string
  alertType: InterventionAlertType
  checklist?: OccurrenceChecklist[]
  timeWithoutChanges?: number
  agent?: InterventionAgent
  vehicle?: InterventionVehicle
  agentCoordinates?: Coordinates
  patrimonyCoordinates?: Coordinates
  imageId?: string
}

type OccurrenceInterventionAttributesForManualRecord = {
  hidden: boolean
}

type OccurrenceInterventionAttributesForSendChecklist = {
  agent: InterventionAgent
  vehicle: InterventionVehicle
  checklist: OccurrenceChecklist[]
}

type OccurrenceInterventionAttributesForAgentPhoneCall = {
  status: InterventionAttributeStatus | null
  number: string
  agent: InterventionAgent
  vehicle: InterventionVehicle
}

type InterventionAttributesForManualRegister = {
  hidden: boolean
}

type InterventionAttributesForSendImage = {
  agent?: AgentFragment
  vehicle?: VehicleFragment
  imageIds?: string[]
}

type TaticalChecklist = {
  question: string
  answer: string
  status: 'SUCCESS' | 'FAILURE'
}

type InterventionAttributesForSendAlert = {
  alertType: AlertType
  checklist?: TaticalChecklist[]
  agent?: AgentFragment
  vehicle?: VehicleFragment
  alertId?: string
  agentCoordinates?: Coordinates
  patrimonyCoordinates?: Coordinates
  imageId?: string
}

type InterventionAttributesForSendChecklist = {
  agent: AgentFragment
  vehicle: VehicleFragment
  checklist: TaticalChecklist[]
}

type InterventionAttributesForPhoneCall = {
  status: InterventionAttributeStatus
  contactId?: string
  contactName?: string
  number: string
}

type InterventionAttributesForImageVerification = {
  status: InterventionAttributeStatus
  imageId: string
}

type InterventionAttributesForSendMessage = {
  status: InterventionAttributeStatus
  message: string
  number: string
  contactName?: string
}

export type InterventionAttributesForStateChange = {
  stateName: InterventionStateEnum
  agent?: AgentFragment
  vehicle?: VehicleFragment
  agentCoordinates?: Coordinates
  patrimonyCoordinates?: Coordinates
}

interface IntrusionConfirmationIntervention {
  typeName: InterventionTypeEnum.CONFIRM_INTRUSION
  attributes: InterventionAttributesForIntrusionConfirmation
}

interface ManualRecordIntervention {
  typeName: InterventionTypeEnum.MANUAL_RECORD
  attributes: InterventionAttributesForManualRegister
}

interface SendImageIntervention {
  typeName: InterventionTypeEnum.SEND_IMAGE
  attributes: InterventionAttributesForSendImage
}

interface SendAlertIntervention {
  typeName: InterventionTypeEnum.SEND_ALERT
  attributes: InterventionAttributesForSendAlert
}

interface SendChecklistIntervention {
  typeName: InterventionTypeEnum.SEND_CHECKLIST
  attributes: InterventionAttributesForSendChecklist
}

interface PhoneCallIntervention {
  typeName: InterventionTypeEnum.PHONE_CALL
  attributes: InterventionAttributesForPhoneCall
}

interface ImageVerificationIntervention {
  typeName: InterventionTypeEnum.IMAGE_VERIFICATION
  attributes: InterventionAttributesForImageVerification
}

interface SendMessageIntervention {
  typeName: InterventionTypeEnum.SEND_MESSAGE
  attributes: InterventionAttributesForSendMessage
}

interface StateChangeIntervention {
  typeName: InterventionTypeEnum.STATE_CHANGED
  attributes: InterventionAttributesForStateChange
}

interface AutomaticIntervention {
  typeName: InterventionTypeEnum.AUTOMATIC
}

export type InterventionAttributes =
  | PhoneCallIntervention
  | ImageVerificationIntervention
  | SendMessageIntervention
  | IntrusionConfirmationIntervention
  | StateChangeIntervention
  | ManualRecordIntervention
  | SendImageIntervention
  | SendAlertIntervention
  | SendChecklistIntervention
  | OccurrenceInterventionAttributesForTravel
  | AutomaticIntervention

export type Intervention = {
  id: string
  title: string
  stateName: InterventionStateEnum
  user: OccurrenceUser
  station?: OccurrenceStation
  createdAt: number
  notes: InterventionNote[]
  tags: OccurrenceTagResponse[]
} & InterventionAttributes

export type InterventionPayload = {
  title: string
  userId: string
  station?: OccurrenceStation
  note?: string
  tags: OccurrenceTag[]
  typeName: OccurrenceInterventionTypeName
  attributes: InterventionAttributes
}
export interface OccurrenceAccount {
  id: string
  code: string
  securityAnswer: string
  securityCoercionAnswer: string
  securityQuestion: string
  note?: string
}

export interface OccurrencePatrimony {
  id: string
  name: string
}

export interface OccurrenceActionPlanPhone {
  id: string
  type: PhoneType
  countryCode: number
  provinceCode: number
  number: number
  extension?: number
  priorityOrder: number
}

export interface OccurrenceActionPlan {
  id: string
  priorityOrder: number
  actionable: boolean
  name: string
  type: ContactType
  phone: OccurrenceActionPlanPhone
  hasRemoteControl: boolean
}

export interface OccurrenceCustomer {
  id: string
  name: string
  document: string
}

export interface AggregatedOccurrenceResponse {
  id: string
  number: number
  categoryName: OccurrenceCategoryName
  type: {
    id: string
    name: string
  }
  stateName: OccurrenceStateName
  title?: string
  createdAt: number
  partitionId?: string
  updatedAt?: number
  finishedAt?: number
  interventions: Intervention[]
  events: OccurrenceEvent[]
  station?: OccurrenceStation
  priority: number
  agent?: {
    id: string
    phone: number
    phoneExtension: string
  }
  parameters: Parameters
  account: {
    id: string
    code: string
    name: string
    serviceType: {
      id: string
      name: string
    }
  }
  patrimony: {
    id: string
    name: string
    address: string
    imageId: string
    number: string
    district: {
      id: string
      name: string
    }
    city: {
      id: string
      name: string
    }
    state: {
      id: string
      name: string
    }
    serviceType?: {
      id: string
      name: string
      color?: string
    }
  }
  customer: {
    id: string
    document: string
    name: string
    secondName: string
  }
}

export type OccurrenceListResponse = {
  id: string
  number: number
  typeName: OccurrenceTypeName
  stateName: OccurrenceStateName
  partitions?: { id: string; code: string; name: string }[]
  account?: {
    id: string
    code: string
  }
  customer?: {
    id: string
    name: string
  }
  tactical?: {
    agent?: {
      id: string
      name: string
      latitude: number
      longitude: number
    }
    vehicle?: {
      id: string
      licensePlate: string
    }
  }
  patrimony?: {
    highRisk?: boolean
    serviceType?: {
      id: string
      name: string
    }
    coordinates?: {
      latitude: number
      longitude: number
    }
  }
  createdAt: number
  updatedAt?: number
  operator?: {
    id: string
    name: string
  }
  finishedAt?: number
}

export type OccurrenceResponse = {
  id: string
  stateName: OccurrenceStateName
}

export type OccurrenceInterventionAttributes =
  | InterventionAttributesForPhoneCall
  | InterventionAttributesForImageVerification
  | InterventionAttributesForSendMessage
  | InterventionAttributesForStateChange
  | InterventionAttributesForIntrusionConfirmation
  | OccurrenceInterventionAttributesForTravel
  | OccurrenceInterventionAttributesForSendImage
  | OccurrenceInterventionAttributesForSendAlert
  | OccurrenceInterventionAttributesForManualRecord
  | OccurrenceInterventionAttributesForSendChecklist
  | OccurrenceInterventionAttributesForAgentPhoneCall
  | InterventionAttributesForManualRegister
  | InterventionAttributesForSendImage
  | InterventionAttributesForSendAlert

export type OccurrenceInterventionNotePayload = {
  note: string
  tags: OccurrenceTagResponse[]
  userId?: string
  attributes?: OccurrenceInterventionAttributes
  station?: Station
}

export type OccurrenceQuery = {
  number?: number
  accountId?: string
  partitionId?: string
  occurrenceTypeId?: string
  stationId?: string
  stateNames?: OccurrenceStateName[]
  page?: number
  size?: number
  sort?: string
  id: string
  title: string
  categoryName: OccurrenceCategoryName
  typeName: OccurrenceTypeName
  stateName: OccurrenceStateName
  createdAt: number
  updatedAt: number | null
  finishedAt: number | null
  accountCode?: string | null
  customerId?: string | null
  customerName?: string | null
  partitionIds: string[] | null
  stationName?: string
  totalEvents: number
  userId?: string
  agentId?: string
  vehicleId?: string
  coverageId?: string
  allowFinishByDuty?: boolean
}

export type OccurrenceTypeQueryResponse = {
  id: string
  name: string
  severity: number
  priority: number
  categoryName: string
  defaultStateName: string
  temporaryStateName: string | null
  temporaryStatePeriod: number | null
}

export enum OCCURRENCE_CATEGORY {
  COMMERCIAL = 'COMMERCIAL',
  TACTICAL = 'TACTICAL',
  TECHNICAL = 'TECHNICAL',
}

export enum OCCURRENCE_CATEGORY_EN_PT {
  COMMERCIAL = 'Comercial',
  TACTICAL = 'Tático',
  TECHNICAL = 'Técnico',
}

export enum OCCURRENCE_CATEGORY_PT_EN {
  'Comercial' = OCCURRENCE_CATEGORY.COMMERCIAL,
  'Tático' = OCCURRENCE_CATEGORY.TACTICAL,
  'Técnico' = OCCURRENCE_CATEGORY.TECHNICAL,
}

export type OccurrenceTypeQuery = Partial<Paging>

type CustomerFragment = {
  id: string
  name: string
  document: string
}

type AccountFragment = {
  id: string
  code: string
  securityAnswer: string
  securityCoercionAnswer: string
  securityQuestion: string
  note?: string
  tags?: AccountTag[]
}

export type PatrimonyFragment = {
  id: string
  name: string
  addressAggregatedNumber: string
  adjunct: string
  district: {
    name: string
    city: {
      nameAggregatedStateAcronym: string
    }
  }
  note: string
  keyNumber: number
}

type ActionPlan = {
  id: string
  name: string
  type: ContactType
  priorityOrder: number
  actionable: boolean
  phone: PhoneResponse
  hasRemoteControl: boolean
}

export type OccurrenceAccountResponse = {
  account: AccountFragment
  customer: CustomerFragment
  patrimony: PatrimonyFragment
  policeNumber?: string
  moduleNumber?: string
  actionPlan: ActionPlan[]
}

export type AttendancePolicyQueryResponse = {
  id: string
  name: string
  service: SERVICE_TYPE_EN_PT
  occurrence: OCCURRENCE_TYPE_EN_PT
  coverage: string
  createdAt: number
  automations?: string[]
  coverages?: Coverage[]
}

// Apenas exemplo para mock, retificar após BE pronto
export type AttendancePolicyInputRequest = {
  active?: boolean
  id: string
  name: string
  parameters: {
    allowPoliceCall: boolean
    allowVehicleDisplacement: boolean
    allowIncidentCompletionWithoutBindingIntervention: boolean
  }
  serviceType: ServiceType
  occurrenceType: OCCURRENCE_TYPE
  rules: string[]
  coverages: Coverage[]
}

export type AttendancePolicyRouteStateProps = {
  attendancePolicyId?: string
  coverageId?: string
}

export type UpdateAttendanceProfileRouteStateProps = {
  attendancePolicyId?: string
  coverageId?: string
}

export type UploadedImage = {
  url: string
  name: string
}

export enum CoverageType {
  OCCURRENCE_ATTENDANT = 'OCCURRENCE_ATTENDANT',
  SERVICE_ORDER_ATTENDANT = 'SERVICE_ORDER_ATTENDANT',
  TECHNICAL_DISPLACEMENT_OPERATOR = 'TECHNICAL_DISPLACEMENT_OPERATOR',
  TACTICAL_DISPLACEMENT_OPERATOR = 'TACTICAL_DISPLACEMENT_OPERATOR',
  TECHNICAL_AGENT = 'TECHNICAL_AGENT',
  TACTICAL_AGENT = 'TACTICAL_AGENT',
  OCCURRENCE_MANAGEMENT = 'OCCURRENCE_MANAGEMENT',
}
export type OccurrenceByUserCoverageQuery = {
  userId?: string
  stateNames?: string
  accountId?: string
  number?: string
  coverageTypes?: string
  occurrenceTypes?: string[]
} & Partial<Paging>

export type Filter = {
  label: string
  value: boolean
}

export type OccurrenceFilters = {
  highRisk: Filter
  checkList: Filter
  coordinatesUpdateRequest: Filter
  supportRequest: Filter
  tactical: Filter
  serviceType: Filter
  travelStateName: Filter
}

export type OccurrenceListByCoverage = {
  id: string
  number: number
  categoryName: OccurrenceCategoryName
  type: {
    id: string
    name: OccurrenceTypeName
  }
  stateName: OccurrenceStateName
  title?: string
  createdAt: number
  updatedAt?: number
  finishedAt?: number
  partitionId?: string
  alerts: {
    tactical: {
      name: string
      status: boolean
    }
    checkList: {
      name: string
      status: boolean
    }
    supportRequest: {
      name: string
      status: boolean
    }
    coordinatesUpdateRequest: {
      name: string
      status: boolean
    }
  }
  tactical?: {
    agent?: {
      id: string
      name: string
      latitude: number
      longitude: number
    }
    vehicle?: {
      id: string
      licensePlate: string
    }
  }
  operator?: {
    id: string
    name: string
  }
  account?: {
    id: string
    code: string
  }
  patrimony?: {
    highRisk?: boolean
    serviceType?: {
      id: string
      name: string
      color?: string
    }
    coordinates?: {
      latitude: number
      longitude: number
    }
  }
  customer?: {
    id: string
    name: string
  }
  totalEvents: number
  filters: OccurrenceFilters
  parameters?: Parameters
}

export type OccurrenceTagQueryPayload = {
  name?: string
  page?: number
  size?: number
}

const vehicleCategory = {
  CAR: 'CAR',
  MOTORCYCLE: 'MOTORCYCLE',
}

const vehicleIgnition = {
  ON: 'ON',
  OFF: 'OFF',
}

export type VehicleCategory = keyof typeof vehicleCategory
export type VehicleIgnition = keyof typeof vehicleIgnition

export interface AgentDuty {
  id: string
  agentId: string
  agentName: string
  position: {
    latitude: number
    longitude: number
  }
  vehicle: {
    id: string
    vehicleCategory: VehicleCategory
    iconColor: string
    ignitionStatus: VehicleIgnition
    licensePlate: string
  }
  speed: number
  occurrenceId?: string
  lastPositionDate: number
}

export const ManualOccurrenceTag = {
  deslocamentoManual: 'Deslocamento tático manual',
  deslocamentoCliente: 'Deslocamento tático - Solicitação cliente',
  deslocamentoPRO: 'Deslocamento tático - PRO & CA',
  rondaPaga: 'Ronda PAGA - Solicitação cliente',
  atualizacaoCadastral: 'Atualização cadastral',
  vigilanteNoLocal: 'Vigilante no local - EVIG',
  tentativaContato: 'Tentativa de contato',
  cadastroSenha: 'Cadastro de senha remoto',
  colagemPlacas: 'Colagem de placas',
  desarmeMonitorado: 'Desarme monitorado E401',
}

export type ManualOccurrenceTagType = keyof typeof ManualOccurrenceTag

export const translatedOccurrenceTag: Record<string, ManualOccurrenceTagType> =
  {
    'Deslocamento tático manual': 'deslocamentoManual',
    'Deslocamento tático - Solicitação cliente': 'deslocamentoCliente',
    'Deslocamento tático - PRO & CA': 'deslocamentoPRO',
    'Ronda PAGA - Solicitação cliente': 'rondaPaga',
    'Atualização cadastral': 'atualizacaoCadastral',
    'Vigilante no local - EVIG': 'vigilanteNoLocal',
    'Tentativa de contato': 'tentativaContato',
    'Cadastro de senha remoto': 'cadastroSenha',
    'Colagem de placas': 'colagemPlacas',
    'Desarme monitorado E401': 'desarmeMonitorado',
  }

export type ManualOccurrencePayload = {
  accountId: string
  note: string
  tags?: {
    name: ManualOccurrenceTagType
  }[]
  userId: string
}

const camStatus = {
  2: 'LIVE',
}

export type CamStatus = keyof typeof camStatus

type UbiStreamResponse = {
  gid: string
  sid: string
  url: string
  m3u8?: string
  status: CamStatus
  state: number
}

export type GetStreamsResponse = {
  ubistreamToken: string
  streams: UbiStreamResponse[]
}

export type GetOccurrence = {
  id: string
  number: number
  accountId: string
  stateName: OccurrenceStateName
  createdAt: number
}

export type QueryOccurrence = {
  partitions: PartitionResponse[]
  number?: number
  accountId?: string
  partitionId?: string
  occurrenceTypeId?: string
  stationId?: string
  stateNames?: OccurrenceStateName[]
  page?: number
  size?: number
  sort?: string
  id: string
  title: string
  categoryName: OccurrenceCategoryName
  typeName: OccurrenceTypeName
  stateName: OccurrenceStateName
  createdAt: number
  updatedAt: number | null
  finishedAt: number | null
  accountCode?: string | null
  customerId?: string | null
  customerName?: string | null
  partitionIds: string[] | null
  stationName?: string
  totalEvents: number
  userId?: string
  agentId?: string
  vehicleId?: string
  coverageId?: string
  allowFinishByDuty?: boolean
}
