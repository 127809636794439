import { useNavigate } from 'react-router-dom'

import { dateNow } from 'utilities/date'
import { EmptyStateV2, ErrorState, SOTag } from 'components'
import { useQuerySO } from 'services/serviceOrderV2'
import { handleRenderState } from 'utilities/handleRenderState'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import Loading from './Loading'
import styles from './styles.module.scss'

const SOHistory = () => {
  const navigate = useNavigate()
  const { serviceOrder } = useServiceOrderDetails()

  const { data, isLoading, isError } = useQuerySO({
    accountIds: [serviceOrder?.accountId],
    fields: ['id', 'number', 'createdAt', 'status'],
    recordsPerPage: 5,
    page: 1,
  })

  return (
    <ul className={styles.history}>
      {
        {
          view: data?.data.slice(1, data.data.length)?.map((so) => (
            <li
              key={so.id}
              className={styles[so.status]}
              onClick={() => navigate(`/soV2/${so.id}`)}
            >
              <div>
                <SOTag status={so.status} />
              </div>
              <span>Ordem de serviço #{so.number}</span>
              <span>{dateNow(so.createdAt)}</span>
            </li>
          )),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyStateV2 />,
        }[
          handleRenderState({
            data,
            isLoading,
            isError,
            isEmpty: !data?.totalElements,
          })
        ]
      }
    </ul>
  )
}

export default SOHistory
