import { CHANGE_TYPE } from 'services/serviceOrder'
import { ContactResponse } from '../contact/types'
import { Paging, QueryRange, Sort } from '../types'
import { PartitionStatus } from 'services/patrimony/types'

export type EditPartitionRequest = {
  name: string
  partitionId: string
}

export enum ContactType {
  ADMIN = 'ADMIN',
  CONTACT = 'CONTACT',
  GUEST = 'GUEST',
}

export type PartitionPayload = {
  id?: string
  name: string
  code: string
  keyNumber?: string
  status: PartitionStatus
  externalId?: string
}

export type PartitionContact = {
  id: string
  name: string
  type: ContactType
  priorityOrder: number
  hasControlDevice?: boolean
  contactId?: string
}

export enum DeviceType {
  RemoteControl = '0',
  PassiveInfraRedSensor = '1',
  PassiveInfraPhotoRedSensor = '2',
  ReedSwitch = '4',
  WirelessSiren = '5',
  Central = '99',
}

export type PartitionDevice = {
  id: string
  name: string
  type: DeviceType
  status: boolean
  lowBattery: boolean
  signal: number
  contact?: Pick<ContactResponse, 'id' | 'name'>
  installationImage?: {
    id: string
    dateTime: string
  }
  quickViewImage?: {
    id: string
    dateTime: string
  }
  changeType?: CHANGE_TYPE
  eventCount?: number
  model?: {
    id: string
    name: string
  }
}

export type RemoteControl = {
  id: string
  name: string
}

export type PartitionUpdateContactForm = {
  id: string
  name: string
  type: ContactType
  priorityOrder: number
  actionable: boolean
  remoteControls?: RemoteControl[]
}

export type ActionPlanContact = {
  contactId: string
  actionable: boolean
  priority: number
}

export type ActionPlanUpdateForm = {
  partitionContacts: ActionPlanContact[]
}

export type PartitionUpdateContactPayload = {
  id: string
  priorityOrder: number
  remoteControls?: RemoteControl[]
}

export type PartitionUpdatePayload = {
  name: string
  centralId: string
  code: string
  keyNumber?: string
  contacts: PartitionUpdateContactPayload[]
}

export type PartitionFindByIdResponse = {
  id: string
  name: string
  centralId: string
  code: string
  keyNumber?: string
  contacts: PartitionUpdateContactForm[]
}

export type PartitionEquipmentQuery = {
  code?: string
  name?: string
  deviceTypeId?: string
  centralId?: string
  partitionId?: string
  contactId?: string
  linked?: boolean
} & Partial<Paging> &
  Partial<Sort> &
  Partial<QueryRange>

export declare type ConfigurationProfile = {
  deviceTypeId: string
  name: string
  active: boolean
  accelAxis?: number
  accelDuration?: number
  accelForce?: number
  accelDebounce?: number
  pirSensibility?: number
  pirTimeout?: number
  pirSampleRate?: number
  gain?: number
  pCellThreshold?: number
  compression?: number
  flashmv?: number
  pCellLowLightTH?: number
  pCellOutdoorTH?: number
  dryContact?: boolean
  silent?: boolean
}
export declare type ConfigurationProfileResponse = {
  id: string
} & ConfigurationProfile

export type PartitionQueryRequest = {
  code?: string
  name?: string
  centralId?: string
  accountIds?: string
  contactId?: string
  status?: string
  securityQuestion?: string
  securityAnswer?: string
  securityCoercionAnswer?: string
} & Partial<Paging> &
  Partial<Sort> &
  Partial<QueryRange>

export type PartitionResponse = {
  id: string
  centralId: string
  name: string
  code: string
  status: PartitionStatus
  securityQuestion?: string
  securityAnswer?: string
  securityCoercionAnswer?: string
  createdAt: number
  updatedAt: number
  aggregatedName?: string
}
