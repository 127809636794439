import { FC, forwardRef, Ref } from 'react'
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'

import { ReactComponent as ReorderIcon } from '../../assets/svg/reorder.svg'
import { ReactComponent as TrashIcon } from '../../assets/svg/trash.svg'

import classes from './DragAndDrop.module.scss'

interface DragAndDropItem {
  id: string
  title: string
  subtitle: string
  icon?: string
  canDelete?: boolean
  isDragDisabled?: boolean
  cardClassName?: string
  numbered?: boolean
}

interface DragAndDropItemProps extends Omit<DragAndDropItem, 'id'> {
  onDelete?: () => void
  index: number
  [key: string]: unknown
}

const DragAndDropItem: FC<DragAndDropItemProps> = forwardRef(
  (props: DragAndDropItemProps, ref: Ref<HTMLDivElement>) => {
    const {
      title,
      subtitle,
      icon,
      index,
      canDelete,
      onDelete,
      isDragDisabled = false,
      cardClassName,
      numbered = true,
      ...rest
    } = props

    return (
      <div
        ref={ref}
        className={[classes.item, cardClassName].join(' ')}
        data-testid="draggable-item"
        {...rest}
      >
        {isDragDisabled ? (
          <span className={classes.icon} />
        ) : (
          <ReorderIcon className={[classes.icon, classes.leftItem].join(' ')} />
        )}

        <div className={classes.middleItem}>
          <span className={classes.title}>{title}</span>
          <span className={classes.subtitle}>{subtitle}</span>
        </div>

        <div className={classes.rightItem}>
          {icon && (
            <img
              aria-label="draggable-card-icon"
              className={classes.icon}
              src={icon}
              alt={title}
            />
          )}
          <div className={canDelete ? classes.deleteIconContainer : ''}>
            {numbered && <span className={classes.position}>{index + 1}º</span>}
            {canDelete && (
              <>
                <div className={classes.deleteIconSeparator} />
                <TrashIcon
                  data-testid="draggable-item-delete"
                  className={[classes.icon, classes.removeIcon].join(' ')}
                  onClick={onDelete}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  },
)

DragAndDropItem.displayName = 'DragAndDropItem'

interface DragAndDropProps<T> {
  items: T[]
  getItem: (item: T) => DragAndDropItem
  onChange?: (items: T[], itemIndex?: number) => void
  draggable?: boolean
  cardClassName?: string
  numbered?: boolean
}

export function DragAndDrop<T>({
  items,
  getItem,
  onChange,
  draggable = true,
  cardClassName,
  numbered,
}: DragAndDropProps<T>): JSX.Element {
  const handleReorderItems = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems = [...items]
    const [removed] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, removed)

    if (onChange) {
      onChange(newItems)
    }
  }

  const handleDeleteItem = (itemIndex: number) => {
    if (onChange) {
      onChange(items, itemIndex)
    }
  }

  const getVerticalDraggingStyle = (
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => {
    if (!draggableStyle) return

    const { transform } = draggableStyle
    let activeTransform = {}
    if (transform) {
      activeTransform = {
        transform: `translate(0, ${transform.substring(
          transform.indexOf(',') + 1,
          transform.indexOf(')'),
        )})`,
      }
    }

    return {
      userSelect: 'none',
      ...draggableStyle,
      ...activeTransform,
    }
  }

  return (
    <DragDropContext onDragEnd={handleReorderItems}>
      <Droppable droppableId="drag-and-drop">
        {(droppableProvided) => (
          <div className={classes.wrapper}>
            <div
              className={classes.container}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {items.map((rawItem, index) => {
                const item = getItem(rawItem)

                return (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={!draggable}
                  >
                    {(draggableProvided) => (
                      <DragAndDropItem
                        index={index}
                        cardClassName={cardClassName}
                        title={item.title}
                        subtitle={item.subtitle}
                        icon={item.icon}
                        canDelete={item.canDelete}
                        onDelete={() => handleDeleteItem(index)}
                        isDragDisabled={!draggable}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={getVerticalDraggingStyle(
                          draggableProvided.draggableProps.style,
                        )}
                        numbered={numbered}
                      />
                    )}
                  </Draggable>
                )
              })}

              {droppableProvided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
