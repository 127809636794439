import { ReactComponent as IconLogo } from 'assets/svg/logo.svg'
import styles from './Home.module.scss'

export const Home = () => {
  return (
    <div className={styles.homeWrapper}>
      <IconLogo className={styles.homeWelcomeLogo} />
    </div>
  )
}
