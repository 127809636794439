import { OccurrenceTypeName } from 'services/occurrence/types'

type VariationEvent = {
  [key in OccurrenceTypeName]: {
    name: string
    classname: string
  }
}

export const EventVariation: VariationEvent = {
  [OccurrenceTypeName.ALARM]: {
    name: 'Alarme',
    classname: 'alarm',
  },
  [OccurrenceTypeName.RESCUE]: {
    name: 'Socorro',
    classname: 'rescue',
  },
  [OccurrenceTypeName.EMERGENCY]: {
    name: 'Emergência',
    classname: 'emergency',
  },
  [OccurrenceTypeName.IMMINENT]: {
    name: 'Iminência',
    classname: 'imminent',
  },
  [OccurrenceTypeName.FAILURE]: {
    name: 'Falha',
    classname: 'failure',
  },
  [OccurrenceTypeName.COMMERCIAL]: {
    name: 'Comercial',
    classname: 'commercial',
  },
  [OccurrenceTypeName.MANUAL]: {
    name: 'Manual',
    classname: 'manual',
  },
  [OccurrenceTypeName.SCHEDULED]: {
    name: 'Periódica',
    classname: 'scheduled',
  },
}

export const equipmentOptions: Array<string> = [
  'Controle Remoto',
  'Fotossensor',
  'Sensor de abertura',
  'Sirene',
  'App',
  'Central',
]

export const eventOptions: Array<string> = [
  '1120 - Pânico',
  '1302 - Disparo Shock',
]
