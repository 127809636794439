import { useCallback, useEffect, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { useToast } from 'shared/hooks'
import {
  usePostSetMaintenanceModeCommand,
  usePostSetDefaultMaintenanceModeCommand,
} from 'shared/hooks/services/commands'
import { MaintenanceModePayload } from 'services/command/types'

type usePostAndHandleMaintenanceCommand = {
  onHandleCentralMaintenanceMode: (isCentralMaintenanceMode: boolean) => void
  CentralMaintenanceModeIsLoading: boolean
}

export const usePostAndHandleMaintenanceCommand = (
  centralId: string,
  payload: MaintenanceModePayload,
): usePostAndHandleMaintenanceCommand => {
  const {
    mutateSetMaintenanceModeCommand,
    statusSetMaintenanceModeCommandMutation,
  } = usePostSetMaintenanceModeCommand(centralId, payload)

  const {
    mutateSetDefaultModeMaintenanceCommand,
    statusSetDefaultModeMaintenanceCommandMutation,
  } = usePostSetDefaultMaintenanceModeCommand(centralId, payload)

  const queryClient = useQueryClient()

  const { addToast } = useToast()

  const onHandleCentralMaintenanceMode = useCallback(
    (isCentralMaintenanceMode: boolean) => {
      if (isCentralMaintenanceMode) {
        mutateSetDefaultModeMaintenanceCommand()
      } else {
        mutateSetMaintenanceModeCommand()
      }
    },
    [mutateSetMaintenanceModeCommand, mutateSetDefaultModeMaintenanceCommand],
  )

  useEffect(() => {
    if (statusSetMaintenanceModeCommandMutation === 'success') {
      addToast({
        message: 'Manutenção iniciada com sucesso.',
      })
      queryClient.invalidateQueries({ queryKey: ['queryCentralByAccounts'] })
    }
    if (statusSetMaintenanceModeCommandMutation === 'error') {
      addToast({
        type: 'alert',
        message: 'Erro ao colocar em manutenção. Tente novamente.',
      })
    }
  }, [addToast, statusSetMaintenanceModeCommandMutation, queryClient])

  useEffect(() => {
    if (statusSetDefaultModeMaintenanceCommandMutation === 'success') {
      addToast({
        message: 'Manutenção encerrada com sucesso.',
      })
      queryClient.invalidateQueries({ queryKey: ['queryCentralByAccounts'] })
    }

    if (statusSetDefaultModeMaintenanceCommandMutation === 'error') {
      addToast({
        type: 'alert',
        message:
          'Erro ao parar manutenção pois a central está offline. Tente novamente.',
      })
    }
  }, [addToast, statusSetDefaultModeMaintenanceCommandMutation, queryClient])

  const CentralMaintenanceModeIsLoading = useMemo(
    () =>
      statusSetMaintenanceModeCommandMutation === 'pending' ||
      statusSetDefaultModeMaintenanceCommandMutation === 'pending',
    [
      statusSetMaintenanceModeCommandMutation,
      statusSetDefaultModeMaintenanceCommandMutation,
    ],
  )

  return {
    onHandleCentralMaintenanceMode,
    CentralMaintenanceModeIsLoading,
  }
}
