import { SERVICE_ORDER_STATUS_ENUM } from 'services/serviceOrder'

export const getServiceOrderToastMessage = (
  status: SERVICE_ORDER_STATUS_ENUM,
  serviceOrderNumber: number,
): { message: string; buttonTitle: string } => {
  switch (status) {
    case SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS:
      return {
        message: `A Ordem de Serviço #${serviceOrderNumber} está em execução.`,
        buttonTitle: 'Visualizar',
      }
    default:
      return {
        message: `A Ordem de Serviço #${serviceOrderNumber} aguarda execução.`,
        buttonTitle: 'Executar',
      }
  }
}
