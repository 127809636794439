import { useCallback, useEffect, useState } from 'react'

import { UserFormData } from 'domains/user/types'

import { useFormContext } from 'react-hook-form'

import { Input } from 'components'

import { formatPostalCode } from 'domains/customer/utilities/mask/postalCode'
import { useDebounce } from 'shared/hooks'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { State } from 'services/address/state'
import {
  useGetCities,
  useGetDistricts,
  useGetStates,
} from 'shared/hooks/services'

import { City } from 'services/address/city/types'
import { District } from 'services/address/district/types'

import styles from './styles.module.scss'
import { Address as AddressType } from 'services/address/types'
import { useGetAddress } from 'shared/hooks/services/address/useGetAddress'

interface AttendanceProfileProps {
  readonly?: boolean
}

export const Address: React.FC<AttendanceProfileProps> = ({ readonly }) => {
  const { watch, setValue, register } = useFormContext<UserFormData>()

  const [filters, setFilters] = useState({
    city: '',
    state: '',
    district: '',
    postalCode: '',
  })

  const { data } = useGetAddress(filters.postalCode)

  const {
    states,
    isError: isErrorFetchStates,
    isFetching: isFetchingStates,
    fetchNextPage: fetchNextStatesPage,
  } = useGetStates(true, filters.state)

  const {
    cities,
    fetchNextCitiesPage,
    isError: isErrorFetchCities,
    isFetching: isFetchingCities,
  } = useGetCities(watch('state')?.id || '', filters.city)

  const {
    districts,
    fetchNextDistrictsPage,
    isError: isErrorFetchDistricts,
    isFetching: isFetchingDistricts,
  } = useGetDistricts(watch('city')?.id || '', filters.district)

  const handleFillAddress = useCallback(
    (addressData: AddressType) => {
      const { state, city, district, address, number } = addressData

      Object.keys({ state, city, district }).forEach((field) => {
        const fieldValue = addressData[field as 'state' | 'city' | 'district']

        setValue(
          field,
          {
            id: fieldValue?.id,
            name: fieldValue?.name,
          },
          { shouldValidate: true, shouldDirty: true },
        )
      })

      setValue('address', address, { shouldValidate: true, shouldDirty: true })
      setValue('number', number, { shouldValidate: true, shouldDirty: true })
    },
    [setValue],
  )

  const handleFilter = useDebounce((newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }))
  }, 700)

  useEffect(() => {
    if (data) {
      handleFillAddress(data)
    }
  }, [data])

  return (
    <div className={styles.fields}>
      <div className={styles.sectionTitle}>
        <h3>Endereço</h3>
        <small>
          Dados relativos à localização do usuário para entregas e outros fins
          de contato.
        </small>
      </div>

      <div className={styles.inlineFields}>
        <Input
          disabled={readonly}
          label="CEP"
          type="text"
          maxLength={10}
          id="postalCode"
          className={styles.postalCode}
          {...register('postalCode')}
          value={formatPostalCode(String(watch('postalCode')) || '')}
          onChange={(event) => {
            const inputValue = event.target.value
            const inputWithoutMask = inputValue.replace(/\D/g, '')

            setFilters((old) => ({
              ...old,
              postalCode: inputWithoutMask,
            }))

            setValue('postalCode', inputWithoutMask, {
              shouldValidate: true,
            })
          }}
        />

        <Combobox
          disabled={readonly}
          id="state"
          label={{ text: 'Estado' }}
          isError={isErrorFetchStates}
          isLoading={isFetchingStates}
          {...register('state')}
          value={watch('state')?.name}
          onEndReached={fetchNextStatesPage}
          items={parseDataToComboboxV2(states || [], 'name')}
          onChange={(selected) => {
            const selectedState = selected as ComboboxItem<State>

            setValue('city', null, {
              shouldValidate: true,
            })
            setValue('district', null, {
              shouldValidate: true,
            })

            setValue(
              'state',
              {
                id: selectedState.value.id,
                name: selectedState.value.name,
              },
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            )
          }}
          onSearch={(filter) => handleFilter({ state: filter })}
        />
      </div>

      <div className={styles.inlineFields}>
        <Combobox
          disabled={!watch('state') || readonly}
          id="city"
          label={{ text: 'Cidade' }}
          items={parseDataToComboboxV2(cities || [], 'name')}
          {...register('city')}
          value={watch('city')?.name}
          onChange={(selected) => {
            const selectedCity = selected as ComboboxItem<City>
            setValue(
              'city',
              {
                id: selectedCity.value.id,
                name: selectedCity.value.name,
              },
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            )
            setValue('district', undefined, {
              shouldValidate: true,
            })
          }}
          isError={isErrorFetchCities}
          isLoading={isFetchingCities}
          onEndReached={fetchNextCitiesPage}
          onSearch={(filter) => handleFilter({ city: filter })}
        />

        <Combobox
          disabled={!watch('city') || readonly}
          id="district"
          label={{ text: 'Bairro' }}
          items={parseDataToComboboxV2(districts || [], 'name')}
          {...register('district')}
          value={watch('district')?.name || ''}
          onChange={(selected) => {
            const selectedDistrict = selected as ComboboxItem<District>
            setValue(
              'district',
              {
                id: selectedDistrict.value.id,
                name: selectedDistrict.value.name,
              },
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            )
          }}
          isError={isErrorFetchDistricts}
          isLoading={isFetchingDistricts}
          onEndReached={fetchNextDistrictsPage}
          onSearch={(filter) => handleFilter({ district: filter })}
        />
      </div>

      <div className={styles.inlineFields}>
        <Input
          disabled={readonly}
          id="address"
          label="Logradouro"
          className={styles.address}
          {...register('address')}
          value={watch('address') || ''}
          onChange={(event) =>
            setValue('address', event.target.value, {
              shouldValidate: true,
            })
          }
        />

        <Input
          disabled={readonly}
          id="number"
          label="Número"
          {...register('number')}
          className={styles.addressNumber}
          value={watch('number') || ''}
          onChange={(event) =>
            setValue('number', event.target.value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
        />
      </div>

      <Input
        disabled={readonly}
        label="Complemento (opcional)"
        {...register('adjunct')}
        value={watch('adjunct') || ''}
        onChange={(event) =>
          setValue('adjunct', event.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
      />

      <Input
        disabled={readonly}
        label="Referências (opcional)"
        {...register('reference')}
        value={watch('reference') || ''}
        onChange={(event) =>
          setValue('reference', event.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
      />
    </div>
  )
}
