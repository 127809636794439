import { useCallback, useState } from 'react'
import styles from './AccordionGroupForm.module.scss'

import { Accordion, Button, Modal } from 'components'

import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg'
import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'

import { ReactComponent as PlusSign } from 'domains/attendancePolicy/assets/svg/plusSign.svg'

import {
  AggregatedBranch,
  BranchOperation,
} from 'services/attendancePolicy/types'

import { getConditionAccordionTitle } from 'domains/attendancePolicy/components/RuleForm/utilities/condition/condition'

interface AccordionContainerProps {
  branches: AggregatedBranch[]
  onEdit?: (branch: AggregatedBranch) => void
  onDelete?: (branchIndex: number) => void
  onAdd?: () => void
}

export const AccordionGroupForm = ({
  branches,
  onEdit,
  onDelete,
  onAdd,
}: AccordionContainerProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [branchIndexToDelete, setBranchIndexToDelete] = useState<number>(0)

  const handleClose = () => {
    setIsModalVisible(false)
  }

  const handleDeleteBranch = useCallback(
    (branchIndex: number) => {
      setBranchIndexToDelete(branchIndex)
      setIsModalVisible(true)
    },
    [setBranchIndexToDelete, setIsModalVisible],
  )

  return (
    <main className={styles.accordionWrapper}>
      {branches
        .sort((prev, next) => prev.index - next.index)
        .map((branch, index) => (
          <div key={index}>
            <div className={styles.container}>
              <Accordion.Root>
                <Accordion.Item className={styles.item}>
                  {({ isOpen }) => (
                    <>
                      <Accordion.Header>
                        <div className={styles.header}>
                          <div>
                            <Accordion.Trigger>
                              <div className={styles.startHeader}>
                                <h1 className={styles.text}>
                                  {`Grupo de Condições ${branch.index}`}
                                </h1>
                                <Accordion.Icon className={styles.arrow} />
                                {!isOpen && (
                                  <div className={styles.tag}>
                                    {branch.conditions.length}
                                  </div>
                                )}
                              </div>
                            </Accordion.Trigger>
                          </div>
                        </div>
                        <div className={styles.endHeader}>
                          <button
                            type="button"
                            onClick={() => {
                              onEdit?.(branch)
                            }}
                          >
                            <EditIcon className={styles.icon} />
                          </button>

                          <button
                            type="button"
                            onClick={() => handleDeleteBranch(branch.index)}
                          >
                            <TrashIcon className={styles.icon} />
                          </button>
                        </div>
                      </Accordion.Header>
                      <Accordion.Content>
                        <div className={styles.content}>
                          {branch.conditions.map((condition, index) => (
                            <div key={index} className={styles.groupItem}>
                              <div className={styles.header}>
                                <div>
                                  <h1 className={styles.text}>
                                    Condição {index + 1}:
                                    <>
                                      <span className={styles.conditionType}>
                                        {getConditionAccordionTitle({
                                          evaluation:
                                            condition.evaluation || 'HAS',
                                          facts: condition.facts,
                                          type: condition.type,
                                        })}
                                      </span>
                                    </>
                                  </h1>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Accordion.Content>
                    </>
                  )}
                </Accordion.Item>
              </Accordion.Root>
            </div>

            <div key={index}>
              {index !== branches.length - 1 && (
                <div
                  data-text={
                    branches[0].branchOperation === BranchOperation.AND
                      ? 'E'
                      : 'Ou'
                  }
                  className={styles.spacer}
                />
              )}
            </div>
          </div>
        ))}

      <button
        type="button"
        aria-label="Retornar para criar novo grupo"
        onClick={onAdd}
        className={[
          styles.button,
          branches.length ? styles.buttonWrapperDotted : styles.buttonWrapper,
        ].join(' ')}
      >
        <PlusSign />
        {`Adicionar novo grupo de condições`}
      </button>

      <Modal
        simple
        onClose={handleClose}
        title={`Excluir grupo de condições`}
        isVisible={isModalVisible}
      >
        <p>
          Você tem certeza de que deseja remover este item? Esta ação não poderá
          ser desfeita.
        </p>
        <Modal.Footer>
          <Button
            width="180px"
            onClick={handleClose}
            id="btn-secondary"
            buttonTitle="Cancelar"
            type="secondary"
          />
          <Button
            width="180px"
            id="btn-primary"
            buttonTitle="Excluir"
            type="cancel-primary"
            onClick={() => {
              onDelete?.(branchIndexToDelete)
              setIsModalVisible(false)
            }}
          />
        </Modal.Footer>
      </Modal>
    </main>
  )
}
