import { useMutation } from '@tanstack/react-query'
import { WifiConfigurationCommandPayload } from 'services/command/types'
import CommandService from 'services/command'

async function sendWifiConfigurationCommand(
  centralId: string,
  payload: WifiConfigurationCommandPayload,
) {
  await CommandService.wifiConfiguration(centralId, payload)
}

export const usePostWifiConfigurationCommand = (
  centralId: string,
  payload: WifiConfigurationCommandPayload,
) => {
  const {
    mutate: mutateWifiConfigurationRequest,
    status: wifiConfigurationCommandMutationStatus,
  } = useMutation({
    mutationFn: () => sendWifiConfigurationCommand(centralId, payload),
  })

  return {
    mutateWifiConfigurationCommand: mutateWifiConfigurationRequest,
    wifiConfigurationCommandMutationStatus,
  }
}
