import { useMemo } from 'react'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { TagQueryResponse } from 'services/auth'
import AccountDriver from 'services/account'
import {
  flattenPages,
  getNextPageOffSet,
} from '../services/utilities/pagination'
import { AccountTag } from 'services/account/types'

type UseGetAccountTagsReturn = {
  isError: boolean
  isFetching: boolean
  accountTags: AccountTag[]
  fetchNextTagsPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const getAccountTags = async (offset = 0, name?: string) => {
  const response = await AccountDriver.queryTags({
    offset,
    ...(name && { name }),
    recordsPerPage: 15,
  })
  return response
}

export function useGetAccountTags(
  name?: string,
  enabled?: boolean,
): UseGetAccountTagsReturn {
  const {
    isError,
    isFetching,
    data,
    fetchNextPage: fetchNextTagsPage,
  } = useInfiniteQuery({
    queryKey: ['tags', name],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => getAccountTags(pageParam, name),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<TagQueryResponse>(lastPage, allPages),
    enabled,
  })

  const accountTags = useMemo(() => flattenPages<AccountTag>(data), [data])

  return {
    isError,
    isFetching,
    accountTags,
    fetchNextTagsPage,
  }
}
