import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { AxiosError } from 'axios'

import {
  Vehicle,
  VehicleDriver,
  VehicleAggregatedQueryResponse,
} from 'services/vehicle'
import { vehicleSchema } from '../../schemas'
import { useToast } from 'shared/hooks'
import { Breadcrumbs, Loader } from 'components'

import { VehicleForm } from '../../components/VehicleForm/VehicleForm'
import styles from './EditVehicle.module.scss'

type EditVehicleParams = {
  vehicleId: string
}

export const EditVehicle = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()
  const { vehicleId } = useParams<EditVehicleParams>()

  const [vehicle, setVehicle] = useState<VehicleAggregatedQueryResponse>()
  const [loading, setLoading] = useState(true)

  const form = useForm<Vehicle>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(vehicleSchema),
  })

  const onSave = useCallback(
    async (data: Vehicle) => {
      try {
        await VehicleDriver.update(vehicleId, data)
        addToast({ message: 'Viatura editada com sucesso.' })
        navigate(-1)
      } catch (error) {
        const { response } = error as AxiosError
        const message =
          response?.status === 412
            ? 'A placa inserida já pertence à outra viatura.'
            : 'Erro ao atualizar os dados da viatura.'

        addToast({ message, type: 'alert' })
      }
    },
    [addToast, navigate, vehicleId],
  )

  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        setLoading(true)
        setVehicle(await VehicleDriver.findById(vehicleId))
        setLoading(false)
      } catch {
        addToast({
          message:
            'Não foi possível recuperar as informações da viatura. Tente novamente.',
          type: 'alert',
        })
        navigate(-1)
      }
    }

    fetchVehicle()
  }, [vehicleId, addToast, navigate])

  if (loading) {
    return <Loader isVisible />
  }

  return (
    <div className={styles.container}>
      <Breadcrumbs
        items={[
          { title: 'Home', href: '/' },
          { title: 'Viaturas', href: '/config/vehicles' },
          { title: 'EDIÇÃO DE VIATURA', href: '' },
        ]}
      />
      <FormProvider {...form}>
        <VehicleForm vehicle={vehicle} onSubmit={onSave} />
      </FormProvider>
    </div>
  )
}
