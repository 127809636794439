import { ImageDetectionLabel } from 'services/attendancePolicy/types'

export const evaluationLabel = {
  HAS: 'Detectada',
  HASNT: 'Não detectada',
}

export const imageLabelItems: { name: string; value: ImageDetectionLabel }[] = [
  { name: 'Pessoa', value: 'PERSON' },
  { name: 'Pet', value: 'PET' },
]

export const imageDetectionLabel = {
  PERSON: 'Pessoa',
  PET: 'Pet',
}

export const getImageLabels = async (): Promise<typeof imageLabelItems> =>
  imageLabelItems
