import { Button, Modal } from 'components'

type AttendanceModalProps = {
  isVisible: boolean
  onClose: () => void
  onContinue: () => void
}

export const AttendanceModal = ({
  isVisible,
  onClose,
  onContinue,
}: AttendanceModalProps) => {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      title="Iniciar atendimento"
      size="sm"
      simple
    >
      <p>
        Você tem certeza que deseja iniciar o carregamento de ocorrências para
        atendimento?
      </p>
      <Modal.Footer>
        <Button
          id="attendance-modal-close-button"
          width="172px"
          buttonTitle="Cancelar"
          onClick={onClose}
          type="secondary"
        />
        <Button
          id="attendance-modal-continue-button"
          width="172px"
          buttonTitle="Continuar"
          onClick={onContinue}
          type="primary"
        />
      </Modal.Footer>
    </Modal>
  )
}
