import { AxiosInstance } from 'axios'
import HttpClient from '../httpClient'

import { Result } from 'services/types'
import { ServiceType, ServiceTypeQuery } from 'services/serviceType/types'

export interface ServiceTypeDriver {
  query(query: ServiceTypeQuery): Promise<Result<ServiceType>>
}

export class ServiceTypeDriverImpl implements ServiceTypeDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async query(query: ServiceTypeQuery): Promise<Result<ServiceType>> {
    const result = await this.httpClient.get<Result<ServiceType>>(
      '/serviceType/query',
      { params: query },
    )

    return result.data
  }
}

export const ServiceTypeDriver = new ServiceTypeDriverImpl()
