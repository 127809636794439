import Joi from '@hapi/joi'
import { StringSchemeErrorsEnum } from 'utilities/validation'

export const UserSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .optional()
    .allow(null, ''),
  name: Joi.string()
    .required()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]:
        'Por favor preencha este campo para continuar',
    }),
  phoneExtension: Joi.string().optional().max(4).allow(null, ''),
})
