import { ComponentProps } from 'react'

import styles from './styles.module.scss'
import joinClassNames from 'utilities/joinClassNames'

interface SkeletonProps extends ComponentProps<'div'> {
  width?: string | number
  height?: string | number
}

const Skeleton = ({
  width = '100%',
  height = 16,
  className,
  ...props
}: SkeletonProps) => (
  <div
    {...props}
    style={{ width, height }}
    className={joinClassNames(styles.container, className)}
  />
)

export default Skeleton
