import { useParams } from 'react-router-dom'
import { useCallback } from 'react'

import {
  useGetAccount,
  ServiceOrderPayload,
  useCreateIntervention,
} from 'services/serviceOrderV2'
import { useToast, useUserInfo } from 'shared/hooks'
import { ScheduleServiceOrderModal } from 'domains/serviceOrder/components'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

interface ScheduleProps {
  onClose: () => void
}

const Schedule = ({ onClose }: ScheduleProps) => {
  const { serviceOrderId } = useParams()
  const { serviceOrder } = useServiceOrderDetails()
  const { data } = useGetAccount(serviceOrder?.accountId)
  const contact = data?.contacts.find(
    (contact) => contact.id === serviceOrder?.contact?.id,
  )

  const { addToast } = useToast()
  const { mutate } = useCreateIntervention(serviceOrderId)
  const { userInfo } = useUserInfo()

  const handleScheduleServiceOrder = useCallback(
    (data: ServiceOrderPayload) => {
      if (serviceOrderId) {
        mutate(
          {
            typeName: 'STATUS_CHANGE',
            note: {
              note: data.note,
              hidden: false,
              userId: userInfo?.id,
            },
            attributes: {
              status: data.status,
              scheduleDate: data.scheduleDate,
              schedulePeriod: data.schedulePeriod,
              technicianId: data.technician?.id,
              contact: data.contact,
              labelIds: data.labels.map((label) => label.id),
            },
            userId: userInfo?.id,
          },
          {
            onSuccess: () => {
              addToast({ message: 'Ordem de serviço agendada com sucesso.' })
              onClose()
            },
            onError: () => {
              addToast({
                type: 'alert',
                message:
                  'Não foi possível agendar a ordem de serviço. Tente novamente',
              })
            },
          },
        )
      }
    },
    [addToast, mutate],
  )

  const serviceOrderForSchedule = {
    ...serviceOrder,
    ...(contact && {
      contact: {
        id: contact.id,
        name: contact.name,
        number: contact?.phone.number,
        countryCode: contact?.phone.countryCode,
        provinceCode: contact?.phone.provinceCode,
      },
    }),
    ...(data?.customer && { customer: data.customer }),
    ...(data?.account && {
      account: {
        id: data.account.id,
        code: data.account.code,
        aggregatedName: data.account.name,
      },
    }),
    type: serviceOrder?.serviceOrderType,
  }

  return (
    <ScheduleServiceOrderModal
      isVisible
      onClose={onClose}
      onSave={handleScheduleServiceOrder}
      serviceOrder={serviceOrderForSchedule}
    />
  )
}

export default Schedule
