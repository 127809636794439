import { useMutation } from '@tanstack/react-query'
import CommandService from 'services/command'
import { ConfigureSafeCallSchema } from 'domains/customer/screens/Equipments/schemas'
import { useUserInfo } from 'shared/hooks'

async function sendConfigureSafeCallCommand(
  payload: ConfigureSafeCallSchema,
  userId: string,
) {
  const { centralId, ...rest } = payload
  await CommandService.configureSafeCall(centralId, {
    ...rest,
    dial: Boolean(payload.dial),
    userId,
  })
}

export const usePostConfigureSafeCallCommand = () => {
  const { userInfo } = useUserInfo()

  const {
    mutate: mutateConfigureSafeCallCommand,
    status: configureSafeCallCommandMutationStatus,
  } = useMutation({
    mutationFn: (payload: ConfigureSafeCallSchema) =>
      sendConfigureSafeCallCommand(payload, userInfo.id),
  })

  return {
    mutateConfigureSafeCallCommand,
    configureSafeCallCommandMutationStatus,
  }
}
