import { Fragment } from 'react'

import { ReactComponent as WhatsAppIcon } from 'assets/svg/whatsapp.svg'
import {
  ReactComponent as ImageVerificationIcon,
  ReactComponent as ImageIcon,
} from 'assets/svg/image.svg'
import { ReactComponent as SuccessIcon } from 'assets/svg/accept.svg'
import { ReactComponent as FailIcon } from 'assets/svg/fail.svg'
import { ReactComponent as AvailableIcon } from 'assets/svg/exclamationMessage.svg'
import { ReactComponent as WaitingIcon } from 'assets/svg/exclamationTriangle.svg'
import { ReactComponent as FinishedIcon } from 'assets/svg/shield.svg'
import { ReactComponent as HiddenIcon } from 'assets/svg/eyeHidden.svg'
import { ReactComponent as AttendanceIcon } from 'assets/svg/headphones.svg'
import { ReactComponent as IntrusionIcon } from 'assets/svg/intrusion.svg'

import { ReactComponent as PinIcon } from 'assets/svg/location.svg'
import { ReactComponent as CarIcon } from 'assets/svg/car.svg'
import { ReactComponent as TravelWaitingIcon } from 'assets/svg/travelWaiting.svg'
import { ReactComponent as OpenLetterIcon } from 'assets/svg/openLetter.svg'
import { ReactComponent as MessageIcon } from 'assets/svg/message.svg'

import {
  InterventionNoteTag,
  InterventionTag,
} from 'domains/occurrence/data/interventionTag'
import {
  InterventionAttributeStatus,
  InterventionStateEnum,
  InterventionTypeEnum,
} from 'services/occurrence/types'
import { Icon } from 'components'

type InterventionStateIconType = {
  [k in InterventionStateEnum]: JSX.Element
}

export const InterventionStateIcon: InterventionStateIconType = {
  [InterventionStateEnum.QUEUED]: <WaitingIcon />,
  [InterventionStateEnum.FINISHED]: <FinishedIcon color="#32af04" />,
  [InterventionStateEnum.AVAILABLE]: <AvailableIcon />,
  [InterventionStateEnum.HIDDEN]: <HiddenIcon />,
  [InterventionStateEnum.IN_PROGRESS]: <AttendanceIcon />,
  [InterventionStateEnum.TRAVEL_WAITING]: <TravelWaitingIcon />,
  [InterventionStateEnum.TRAVEL_FINISHED]: <PinIcon color="#009F87" />,
  [InterventionStateEnum.TRAVEL_STARTED]: <CarIcon color="#009F87" />,
  [InterventionStateEnum.TRAVEL_VIEWED]: <OpenLetterIcon color="#009F87" />,
  [InterventionStateEnum.TRAVEL_SENT]: <MessageIcon color="#009F87" />,
}

type InterventionTypeIconType = {
  [k in InterventionTypeEnum]?: JSX.Element
}

export const InterventionTypeIcon: InterventionTypeIconType = {
  [InterventionTypeEnum.PHONE_CALL]: (
    <Icon name="phone" aria-label="intervention-card-type-icon" />
  ),
  [InterventionTypeEnum.SEND_MESSAGE]: (
    <WhatsAppIcon aria-label="intervention-card-type-icon" />
  ),
  [InterventionTypeEnum.IMAGE_VERIFICATION]: (
    <ImageVerificationIcon aria-label="intervention-card-type-icon" />
  ),
  [InterventionTypeEnum.CONFIRM_INTRUSION]: (
    <IntrusionIcon aria-label="intervention-card-type-icon" />
  ),
  [InterventionTypeEnum.SEND_IMAGE]: (
    <ImageIcon aria-label="intervention-card-type-icon" />
  ),
}

type InterventionStatusIconType = {
  [k in InterventionAttributeStatus]: {
    icon: JSX.Element
    classname: string
  }
}

export const InterventionStatusIcon: InterventionStatusIconType = {
  [InterventionAttributeStatus.SUCCESS]: {
    icon: <SuccessIcon aria-label="intervention-card-status-icon" />,
    classname: 'iconStateSuccess',
  },
  [InterventionAttributeStatus.FAILURE]: {
    icon: <FailIcon aria-label="intervention-card-status-icon" />,
    classname: 'iconStateFail',
  },
  [InterventionAttributeStatus.PENDING]: {
    icon: <Fragment />,
    classname: '',
  },
}

export interface InterventionPredefinedCommentData {
  message: string
  status?: InterventionAttributeStatus
  tags: {
    name: InterventionNoteTag
  }[]
}

export type InterventionPredefinedCommentType = {
  [k in InterventionTag]: Array<InterventionPredefinedCommentData>
}

export const InterventionPredefinedComments: InterventionPredefinedCommentType =
  {
    [InterventionTag.LIGACAO_CONTATO]: [
      {
        message:
          'Feito contato com responsável [NOME], que informou estar tudo bem no local. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.TUDO_BEM_NO_LOCAL },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que informou não estar no local e solicitou contato para o próximo contato. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.NAO_ESTA_LOCAL },
          { name: InterventionNoteTag.PROXIMO_CONTATO },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que informou não estar no local e solicitou o acionamento da PM. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.NAO_ESTA_LOCAL },
          { name: InterventionNoteTag.SOLICITOU_POLICIA },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que informou ter havido uma intrusão. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.INFORMOU_INTRUSAO },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que informou não estar no local e irá averiguar solicitando que retornemos posteriormente. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.NAO_ESTA_LOCAL },
          { name: InterventionNoteTag.SOLICITOU_RETORNO_CONTATO },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que ficou ciente da Bateria Baixa e foi orientado a verificar a conexão do módulo com a tomada. Confirmada a palavra-chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
          { name: InterventionNoteTag.CIENTE_BATERIA_BAIXA },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que ficou ciente da Bateria baixa e informou que está sem energia elétrica no seu patrimônio. Confirmada a palavra-chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
          { name: InterventionNoteTag.CIENTE_BATERIA_BAIXA },
          { name: InterventionNoteTag.SEM_ENERGIA },
        ],
      },
      {
        message:
          'Feito contato com [NOME] porém não confirmou a palavra chave.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.NAO_CONFIRMOU_PALAVRA_CHAVE }],
      },
      {
        message: 'Não atende.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.NAO_ATENDE }],
      },
      {
        message: 'Ocupado.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.OCUPADO }],
      },
      {
        message: 'Caixa postal.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.CAIXA_POSTAL }],
      },
      {
        message: 'Telefone não existe.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.TELEFONE_NAO_EXISTE }],
      },
      {
        message: 'Telefone incorreto.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.TELEFONE_INCORRETO }],
      },
    ],
    [InterventionTag.SAFE_CALL]: [
      {
        message:
          'Feito contato com responsável [NOME], que informou estar tudo bem no local. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.TUDO_BEM_NO_LOCAL },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que informou ter havido uma intrusão. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.INFORMOU_INTRUSAO },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com o módulo e verificado anormalidade no local. Será acionada a PM.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.ANORMALIDADE_NO_LOCAL }],
      },
      {
        message:
          'Feito contato com o módulo, não obtivemos resposta, porém não foi percebida anormalidade no local.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.SEM_RESPOSTA_MODULO },
          { name: InterventionNoteTag.SEM_ANORMALIDADE_NO_LOCAL },
        ],
      },
      {
        message:
          'Feito contato com [NOME] porém não confirmou a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.NAO_CONFIRMOU_PALAVRA_CHAVE }],
      },
      {
        message: 'Especialista solicitando validação de OS.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.VALIDACAO_OS }],
      },
      {
        message: 'Especialista pediu para desconsiderar.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.ESPECIALISTA_PEDIU_DESCONSIDERAR }],
      },
      {
        message: 'Especialista solicitou demonstração com a central na maleta.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.DEMONSTRACAO_MALETA }],
      },
      {
        message: 'Especialista interrompeu a demonstração.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.ESPECIALISTA_INTERROMPEU_DEMONSTRACAO },
        ],
      },
      {
        message: 'Especialista não identificou o cliente.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.ESPECIALISTA_NAO_IDENTIFICOU_CLIENTE },
        ],
      },
      {
        message:
          'Especialista solicitou demonstração e continuou dando explicação ao cliente.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.DEMONSTRACAO_MALETA },
          { name: InterventionNoteTag.ESPECIALISTA_IGNOROU_DEMONSTRACAO },
        ],
      },
      {
        message: 'Demonstração realizada com sucesso.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.DEMONSTRACAO_SUCESSO }],
      },
      {
        message: 'Não atende.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.NAO_ATENDE }],
      },
      {
        message: 'Ocupado.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.OCUPADO }],
      },
      {
        message: 'Caixa postal.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.CAIXA_POSTAL }],
      },
      {
        message: 'Telefone não existe.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.TELEFONE_NAO_EXISTE }],
      },
      {
        message: 'Telefone incorreto.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.TELEFONE_INCORRETO }],
      },
      {
        message: 'Número indisponível.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.NUMERO_INDISPONÍVEL }],
      },
    ],
    [InterventionTag.LIGACAO_POLICIA]: [
      {
        message:
          'Feito contato com agente [NOME], que informou que irá deslocar viatura para o local.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.COM_DESLOCAMENTO }],
      },
      {
        message:
          'Feito contato com agente [NOME], que informou que NÃO irá deslocar viatura.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.SEM_DESLOCAMENTO }],
      },
      {
        message: 'Não atende.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.NAO_ATENDE }],
      },
      {
        message: 'Ocupado.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.OCUPADO }],
      },
      {
        message: 'Caixa postal.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.CAIXA_POSTAL }],
      },
      {
        message: 'Telefone não existe.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.TELEFONE_NAO_EXISTE }],
      },
      {
        message: 'Telefone incorreto.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.TELEFONE_INCORRETO }],
      },
    ],

    [InterventionTag.REGISTRO_MANUAL]: [
      {
        message:
          'Feito contato com agente [NOME], que informou que irá deslocar viatura para o local.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.COM_DESLOCAMENTO }],
      },
    ],
    [InterventionTag.ENVIO_MENSAGEM]: [
      {
        message:
          'Feito contato com responsável [NOME], que informou estar tudo bem no local. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.TUDO_BEM_NO_LOCAL },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que informou não estar no local e solicitou contato para o próximo contato. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.NAO_ESTA_LOCAL },
          { name: InterventionNoteTag.PROXIMO_CONTATO },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que informou não estar no local e solicitou o acionamento da PM. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.NAO_ESTA_LOCAL },
          { name: InterventionNoteTag.SOLICITOU_POLICIA },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que informou ter havido uma intrusão. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.INFORMOU_INTRUSAO },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com responsável [NOME], que informou não estar no local e irá averiguar solicitando que retornemos posteriormente. Confirmada a palavra chave.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [
          { name: InterventionNoteTag.NAO_ESTA_LOCAL },
          { name: InterventionNoteTag.SOLICITOU_RETORNO_CONTATO },
          { name: InterventionNoteTag.CONFIRMOU_PALAVRA_CHAVE },
        ],
      },
      {
        message:
          'Feito contato com [NOME] porém não confirmou a palavra chave.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.NAO_CONFIRMOU_PALAVRA_CHAVE }],
      },
      {
        message: 'Não responde.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.NAO_ATENDE }],
      },
      {
        message: 'Telefone incorreto.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.TELEFONE_INCORRETO }],
      },
    ],
    [InterventionTag.VERIFICACAO_IMAGEM]: [
      {
        message: 'Detectada pessoa.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.PESSOA_DETECTADA }],
      },
      {
        message: 'Detectado PET.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.PET_DETECTADO }],
      },
      {
        message: 'Sem anormalidade.',
        status: InterventionAttributeStatus.SUCCESS,
        tags: [{ name: InterventionNoteTag.SEM_ANORMALIDADE }],
      },
      {
        message: 'Imagem com problemas.',
        status: InterventionAttributeStatus.FAILURE,
        tags: [{ name: InterventionNoteTag.PROBLEMA_IMAGEM }],
      },
    ],
    [InterventionTag.FINALIZADA]: [
      {
        message: '#naoEnviaComunicado',
        tags: [{ name: InterventionNoteTag.NAO_ENVIA_COMUNICADO }],
      },
      {
        message:
          'Atendimento encerrado por disparo em falso. Cliente já possui OS aberta.',
        tags: [
          { name: InterventionNoteTag.DISPARO_FALSO },
          { name: InterventionNoteTag.POSSUI_OS_ABERTA },
        ],
      },
      {
        message:
          'Atendimento encerrado por disparo em falso. Aberta OS nº[NUMERO] para verificação do sistema.',
        tags: [
          { name: InterventionNoteTag.DISPARO_FALSO },
          { name: InterventionNoteTag.ABERTO_OS },
        ],
      },
      {
        message: 'Trata-se de especialista Orsegups e maleta de demonstração.',
        tags: [{ name: InterventionNoteTag.MALETA_ESPECIALISTA }],
      },
      {
        message: 'Fechamento após falha de comunicação.',
        tags: [{ name: InterventionNoteTag.FALHA_COMUNICACAO }],
      },
      {
        message: 'Apenas evento de shock.',
        tags: [{ name: InterventionNoteTag.SOMENTE_SHOCK }],
      },
      {
        message: 'Atendimento encerrado, disparo sem foto.',
        tags: [{ name: InterventionNoteTag.DISPARO_SEM_FOTO }],
      },
      {
        message: 'Demonstração realizada com sucesso.',
        tags: [{ name: InterventionNoteTag.DEMONSTRACAO_SUCESSO }],
        status: InterventionAttributeStatus.SUCCESS,
      },
      {
        message: 'Trata-se de detecção de PET.',
        tags: [{ name: InterventionNoteTag.ENCERRADO_DETECTADO_PET }],
        status: InterventionAttributeStatus.SUCCESS,
      },
      {
        message: 'Contato sem êxito.',
        tags: [{ name: InterventionNoteTag.CONTACTO_SEM_EXITO }],
        status: InterventionAttributeStatus.SUCCESS,
      },
      {
        message: 'Demonstração não realizada.',
        tags: [{ name: InterventionNoteTag.DEMONSTRACAO_FALHA }],
        status: InterventionAttributeStatus.FAILURE,
      },
    ],
    [InterventionTag.QUEUED]: [],
    [InterventionTag.TRAVEL_WAITING]: [],
    [InterventionTag.AVAILABLE]: [],
    [InterventionTag.INTRUSAO_CONFIRMADA]: [],
    [InterventionTag.SEND_IMAGE]: [],
  }
