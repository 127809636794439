import Axios from 'axios'

const ORSEGUPS_RAMAL_SERVLET_URL =
  'https://intranet.orsegups.com.br:443/fusion/servlet/com.neomind.fusion.custom.orsegups.servlets.RamalServlet'

export function openWhatsApp(phoneNumber: string): Window | null {
  return window.open(`https://wa.me/+55${phoneNumber}`, '_blank')
}

export async function makePhoneExtensionCall(
  phoneNumber: string,
  stationPhoneExtension: string,
): Promise<void> {
  try {
    await Axios.get(
      `${ORSEGUPS_RAMAL_SERVLET_URL}?ramaOrigem=${stationPhoneExtension}&ramalDestino=${phoneNumber}`,
    )
  } catch (error) {
    console.error('Erro ao fazer ligação', error)
  }
}
