import { FormItem, FormLabel, Icon, RadioGroup, Tag } from 'components'

import { RadioButton } from 'components/RadioButton/RadioButton'
import { evaluationLabel, imageDetectionLabel, imageLabelItems } from './data'
import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'

import { ConditionType } from 'services/attendancePolicy/types'

import Select from 'components/Form/Select'

export const ImageDetection = ({ data, onChange }: ConditionOptionForm) => {
  const formData =
    data.facts?.imageDetections?.map((item) => ({
      label: imageDetectionLabel[item],
      value: item,
    })) || []

  return (
    <div>
      <RadioGroup title="Detecção em imagem">
        {['Detectada', 'Não detectada'].map((option, key) => (
          <RadioButton
            value={option}
            onChange={(e) => {
              const value = e.target.value === 'Detectada' ? 'HAS' : 'HASNT'

              onChange({
                type: ConditionType.EVENT_IMAGE_DETECTIONS,
                facts: {
                  imageDetections: data.facts?.imageDetections,
                },
                evaluation: value,
              })
            }}
            checked={
              data.evaluation
                ? option === evaluationLabel[data.evaluation]
                : false
            }
            name={`image-detection-${data.id}`}
            key={key}
          />
        ))}
      </RadioGroup>

      <FormItem>
        <FormLabel>Tipo de identificação</FormLabel>
        <Select.Root data={imageLabelItems} valueKey="value">
          {({ unSelectedOptions }) => (
            <>
              <Select.Field value={formData} />
              {!!formData?.length && (
                <>
                  <Select.Options>
                    {formData.map((detection, index) => (
                      <Tag key={index}>
                        <span>{detection.label}</span>

                        <Icon
                          name="close-xlg"
                          width={8}
                          height={8}
                          onClick={() => {
                            onChange({
                              type: ConditionType.EVENT_IMAGE_DETECTIONS,
                              evaluation: data.evaluation,
                              facts: {
                                imageDetections:
                                  data?.facts?.imageDetections?.filter(
                                    (item) => item !== detection.value,
                                  ),
                              },
                            })
                          }}
                        />
                      </Tag>
                    ))}
                  </Select.Options>
                </>
              )}

              <Select.Group>
                {unSelectedOptions?.map((option) => (
                  <Select.Option
                    key={option.name}
                    shouldCloseGroup={false}
                    onClick={() => {
                      onChange({
                        type: ConditionType.EVENT_IMAGE_DETECTIONS,
                        evaluation: data.evaluation,
                        facts: {
                          imageDetections: [
                            ...formData.map((item) => item.value),
                            option.value,
                          ],
                        },
                      })
                    }}
                  >
                    {option.name}
                  </Select.Option>
                ))}
              </Select.Group>
            </>
          )}
        </Select.Root>
      </FormItem>
    </div>
  )
}
