import * as Joi from '@hapi/joi'

import { TagOutput } from 'services/serviceOrder'

export const tagSchema = Joi.object<TagOutput>({
  id: Joi.string().required(),
  name: Joi.string().required(),
  totalLinks: Joi.number().allow(null).optional(),
  createdAt: Joi.number(),
  updatedAt: Joi.number(),
})
