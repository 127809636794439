import {
  ComponentProps,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useDebounce, useOnClickOutside } from 'shared/hooks'

import styles from './styles.module.scss'
import fieldStyles from '../field.module.scss'
import { FormGroup, Icon, Tooltip, FormAddon } from '../..'

type InputRef = HTMLInputElement & { dataValue?: string | Array<unknown> }

interface handleFilteredOptionsProps<T> {
  options?: T[]
  valueKey?: keyof T
  value?: string | unknown[]
}

const handleFilteredOptions = <T,>({
  options,
  value,
  valueKey,
}: handleFilteredOptionsProps<T>) => {
  return options?.filter((option) => {
    if (value) {
      return Array.isArray(value)
        ? !(value || []).some((item) => item[valueKey] === option[valueKey])
        : option[valueKey] !== value
    }

    return option
  })
}

interface FindOptionsResponse<T> {
  data: T[]
  totalElements?: number
}

interface ComboBoxContextProps<T> {
  valueKey?: keyof T
  rootRef: RefObject<HTMLDivElement>
  inputRef: RefObject<InputRef>
  visible: [boolean, Dispatch<SetStateAction<boolean>>]
  loading: [boolean, Dispatch<SetStateAction<boolean>>]
  findOptions: (
    searchValue: string,
    recordsPerPage?: number,
  ) => Promise<FindOptionsResponse<T>>
  options: [T[] | undefined, Dispatch<SetStateAction<T[] | undefined>>]
  unSelectedOptions: [
    T[] | undefined,
    Dispatch<SetStateAction<T[] | undefined>>,
  ]
}

const ComboBoxContext = createContext<
  ComboBoxContextProps<unknown> | undefined
>(undefined)

const useComboboxContext = <T,>() => {
  const context = useContext(ComboBoxContext) as ComboBoxContextProps<T>

  if (!context) {
    throw new Error('useComboboxContext must be used within a ComboboxProvider')
  }

  return context
}

type ChildrenProps<T> = {
  options: T[] | undefined
  unSelectedOptions: T[] | undefined
}

interface RootProps<T> extends Omit<ComponentProps<'div'>, 'children'> {
  valueKey?: keyof T
  children?: ReactNode | ((props: ChildrenProps<T>) => ReactNode)
  findOptions: (
    searchValue: string,
    offset?: number,
  ) => Promise<FindOptionsResponse<T>>
}

const Root = <T,>({
  valueKey,
  findOptions,
  className,
  children,
  ...props
}: RootProps<T>) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [options, setOptions] = useState<T[] | undefined>([])
  const [unSelectedOptions, setUnSelectedOptions] = useState<T[] | undefined>(
    [],
  )

  const value: ComboBoxContextProps<T> = {
    valueKey,
    rootRef,
    inputRef,
    findOptions,
    options: [options, setOptions],
    loading: [loading, setLoading],
    visible: [visible, setVisible],
    unSelectedOptions: [unSelectedOptions, setUnSelectedOptions],
  }

  const handleRenderContent = () => {
    if (typeof children === 'function') {
      return children({ options, unSelectedOptions })
    }

    return children
  }

  const [hasChildFocused, setHasChildFocused] = useState(false)

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (rootRef.current && rootRef.current.contains(event.target as Node)) {
        setHasChildFocused(true)
      } else {
        setVisible(false)
        setHasChildFocused(false)
      }
    }
    document.addEventListener('click', handleClick)
  }, [setHasChildFocused])

  return (
    <ComboBoxContext.Provider value={value}>
      <div
        {...props}
        ref={rootRef}
        className={[
          styles.container,
          className,
          hasChildFocused && styles.focused,
          visible && styles.visible,
        ].join(' ')}
      >
        {handleRenderContent()}
      </div>
    </ComboBoxContext.Provider>
  )
}

interface FieldProps extends Omit<ComponentProps<'input'>, 'value'> {
  value?: string | Array<unknown>
}

const Field = <T,>({
  value,
  onClick,
  onChange,
  className,
  defaultValue,
  ...props
}: FieldProps) => {
  const {
    rootRef,
    inputRef,
    valueKey,
    findOptions,
    options: [options, setOptions],
    loading: [_loading, setLoading],
    visible: [visible, setVisible],
    unSelectedOptions: [_unselectedOptions, setUnSelectedOptions],
  } = useComboboxContext<T>()

  const handleFilter = useDebounce(async (searchValue: string) => {
    await findOptions(searchValue, 0)
      .then((response) => {
        setOptions(response.data)
        setUnSelectedOptions(() =>
          handleFilteredOptions({ options: response.data, value, valueKey }),
        )
      })
      .finally(() => setLoading(false))
  })

  const handleResetFieldValue = () => {
    if (
      inputRef.current &&
      (typeof value === 'string' || value === undefined)
    ) {
      inputRef.current.value = value || ''
    }
  }

  useOnClickOutside(rootRef, () => {
    setVisible(false)
    handleResetFieldValue()
  })

  useEffect(() => {
    handleResetFieldValue()

    setUnSelectedOptions(() =>
      handleFilteredOptions({
        value,
        valueKey,
        options,
      }),
    )

    if (inputRef.current) {
      inputRef.current.dataValue = value
    }
  }, [value])

  return (
    <FormGroup>
      <FormAddon>
        <Icon color="element" name="search" />
      </FormAddon>
      <input
        {...props}
        ref={inputRef}
        onClick={(event) => {
          setVisible(true)
          onClick?.(event)
        }}
        className={[fieldStyles.field, styles.input, className].join(' ')}
        onChange={(event) => {
          const value = event.target.value

          setLoading(true)
          handleFilter(value)

          onChange?.(event)
        }}
      />
      <FormAddon>
        <Icon
          color="element"
          name="chevron-sm-down"
          className={[visible && styles.rotate].join(' ')}
        />
      </FormAddon>
    </FormGroup>
  )
}

interface OptionProps extends ComponentProps<'li'> {
  shouldCloseGroup?: boolean
  hasLabel?: boolean
}

const Option = ({
  children,
  className,
  onClick,
  'aria-disabled': ariaDisabled,
  hasLabel = false,
  shouldCloseGroup = true,
  ...props
}: OptionProps) => {
  const {
    visible: [_visible, setVisible],
  } = useComboboxContext()

  const ref = useRef<HTMLTableCellElement>(null)
  const [isTooltipVisible, setTooltipVisible] = useState(false)

  const handleMouseEnter = useCallback(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
      setTooltipVisible(true)
    }
  }, [])

  const handleMouseLeave = useCallback(() => {
    setTooltipVisible(false)
  }, [])

  return (
    <li
      {...props}
      className={[styles.option, hasLabel && styles.hasLabel, className].join(
        ' ',
      )}
      onClick={(event) => {
        if (!ariaDisabled) {
          onClick?.(event)
          shouldCloseGroup && setVisible(false)
        }
      }}
      aria-disabled={ariaDisabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isTooltipVisible && (
        <Tooltip
          parentRef={ref}
          type="informative"
          isVisible={isTooltipVisible}
        >
          {children}
        </Tooltip>
      )}
    </li>
  )
}

const Options = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div className={[styles.options, className].join(' ')} {...props} />
}

const Group = ({ className, children, ...props }: ComponentProps<'ul'>) => {
  const {
    valueKey,
    inputRef,
    findOptions,
    visible: [visible],
    loading: [loading, setLoading],
    options: [options, setOptions],
    unSelectedOptions: [unselectedOptions, setUnSelectedOptions],
  } = useComboboxContext()

  const value = inputRef.current?.value || ''
  const dataValue = inputRef.current?.dataValue || ''
  const dropListRef = useRef<HTMLUListElement>(null)

  const handleFindOptions = async () => {
    setLoading(true)

    await findOptions(value, 0)
      .then((response) => {
        setOptions(response.data)

        setUnSelectedOptions(() =>
          handleFilteredOptions({
            options: response.data,
            valueKey,
            value: dataValue,
          }),
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleRenderState = () => {
    if (loading) {
      return <li className={styles.exceptionOption}>Carregando...</li>
    }

    if (!unselectedOptions?.length) {
      return (
        <li className={styles.exceptionOption}>Não foram encontrados itens</li>
      )
    }

    return children
  }

  let hasMore = true

  const handleScroll = useCallback(async () => {
    if (dropListRef.current && hasMore) {
      const { scrollTop, scrollHeight, clientHeight } = dropListRef.current
      const currentOffset = options?.length ?? 0

      if (scrollTop + clientHeight >= scrollHeight && clientHeight > 190) {
        const response = await findOptions(
          inputRef.current?.value || '',
          currentOffset,
        )
        const { data, totalElements } = response

        if (totalElements && currentOffset + data.length >= totalElements) {
          hasMore = false
          return
        }

        setLoading(true)

        setOptions((prevOptions) => (prevOptions || []).concat(data))
        setUnSelectedOptions((options) =>
          handleFilteredOptions({
            options: (options || []).concat(data),
            valueKey,
            value: dataValue,
          }),
        )
        setLoading(false)
      }
    }
  }, [dropListRef, options, setOptions, findOptions])

  useEffect(() => {
    const list = dropListRef.current

    list?.addEventListener('scroll', handleScroll)

    return () => list?.removeEventListener('scroll', handleScroll)
  }, [dropListRef.current, options])

  useEffect(() => {
    if (!inputRef.current?.value.length && visible) {
      handleFindOptions()
    }
  }, [inputRef, visible])

  return (
    <ul
      {...props}
      ref={dropListRef}
      className={[styles.list, visible && styles.visible, className].join(' ')}
    >
      {handleRenderState()}
    </ul>
  )
}

const ComboBox = {
  Root,
  Field,
  Group,
  Option,
  Options,
}

export default ComboBox
