import { FC, ReactElement, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import './Loader.scss'

export interface LoaderProps {
  isVisible: boolean
  children?: ReactNode
}

export const Loader: FC<LoaderProps> = (props): ReactElement => {
  const { isVisible, children } = props

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {isVisible && (
            <div className="loader-container" data-testid="loader-container">
              <div className="loader-overlay" />
              <div className="loader-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="120"
                  height="120"
                  viewBox="0 0 120 120"
                >
                  <g
                    id="prefix__Group_1908"
                    data-name="Group 1908"
                    transform="translate(.185 -.5)"
                  >
                    <path
                      id="prefix__Subtraction_5"
                      d="M60 120a59.72 59.72 0 0 1-33.546-10.247 60.176 60.176 0 0 1-21.738-26.4 59.921 59.921 0 0 1 5.532-56.9 60.176 60.176 0 0 1 26.4-21.738 59.92 59.92 0 0 1 56.9 5.532 60.176 60.176 0 0 1 21.738 26.4 59.921 59.921 0 0 1-5.532 56.9 60.176 60.176 0 0 1-26.4 21.738A59.623 59.623 0 0 1 60 120zm0-109.333A49.333 49.333 0 1 0 109.333 60 49.389 49.389 0 0 0 60 10.667z"
                      data-name="Subtraction 5"
                      transform="translate(-.185 .5)"
                      style={{ fill: '#D7F4F1' }}
                    />
                    <path
                      id="prefix__Intersection_5"
                      d="M49.333 60A49.389 49.389 0 0 0 0 10.667V0a59.627 59.627 0 0 1 23.354 4.715 60.056 60.056 0 0 1 10.192 5.532 60.46 60.46 0 0 1 8.879 7.327 60.389 60.389 0 0 1 7.327 8.88 59.99 59.99 0 0 1 5.532 10.192A59.622 59.622 0 0 1 60 60z"
                      data-name="Intersection 5"
                      transform="translate(59.815 .5)"
                      style={{ fill: '#009F87' }}
                    />
                  </g>
                </svg>
              </div>
              {children}
            </div>
          )}
        </>,
        document.body,
      )}
    </>
  )
}

export default Loader
