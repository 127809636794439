import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ServiceOrderPayload } from 'services/serviceOrderV2'
import HttpClient from 'services/httpClient'
import buildUrl from 'utilities/buildUrl'

import { endpoints } from './endpoints'

function updateServiceOrder(data: ServiceOrderPayload) {
  const { id, ...payload } = data

  return HttpClient.put(buildUrl({ route: endpoints.update, params: { id } }), {
    note: payload.note,
    status: payload.status,
    userId: payload.userId,
    accountId: payload.account.id,
    serviceOrderType: payload.type,
    technicianId: payload.technician?.id,
    contact: payload.contact || undefined,
    scheduleDate: payload.scheduleDate || undefined,
    schedulePeriod: payload.schedulePeriod || undefined,
    tags: payload.tags?.map((tag) => ({ name: tag.name })),
    labels: data.labels?.map((label) => ({
      ...label,
      active: true,
      createdAt: undefined,
      updatedAt: undefined,
    })),
  })
}

export const usePutServiceOrder = (serviceOrderId?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: ServiceOrderPayload) => {
      return await updateServiceOrder(data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['service-order-intervention', serviceOrderId],
      })
      queryClient.invalidateQueries({
        queryKey: ['service-order', serviceOrderId],
      })
    },
  })
}
