import { GROUPED_TRIGGER_TYPE, GROUPED_TRIGGER_TYPE_EN_PT } from '../types'

import { ReactComponent as LocationIcon } from '../assets/svg/location.svg'
import { ReactComponent as ClosedIcon } from 'assets/svg/closed.svg'
import { ReactComponent as TimeIcon } from 'assets/svg/time2.svg'
import { ReactComponent as Exclamation } from '../assets/svg/exclamation.svg'
import { TriggerType } from 'services/attendancePolicy/types'
import { FunctionComponent, SVGProps } from 'react'

type TriggerOptions = {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  title: GROUPED_TRIGGER_TYPE_EN_PT
  description: string
  formType: TriggerType
}

export const triggerOptions: Record<GROUPED_TRIGGER_TYPE, TriggerOptions> = {
  [GROUPED_TRIGGER_TYPE.ARRIVAL]: {
    Icon: LocationIcon,
    title: GROUPED_TRIGGER_TYPE_EN_PT.ARRIVAL,
    description:
      'Momento em que ocorre uma determinada ação para iniciar uma automação',
    formType: TriggerType.OCCURRENCE_ARRIVAL || TriggerType.EVENT_ARRIVAL,
  },
  [GROUPED_TRIGGER_TYPE.CLOSING]: {
    Icon: ClosedIcon,
    title: GROUPED_TRIGGER_TYPE_EN_PT.CLOSING,
    description:
      'Instante em que uma ocorrência é finalizada, seja manualmente ou por uma regra definida',
    formType: TriggerType.OCCURRENCE_CLOSING,
  },
  [GROUPED_TRIGGER_TYPE.INTERVENTION]: {
    Icon: Exclamation,
    title: GROUPED_TRIGGER_TYPE_EN_PT.INTERVENTION,
    description:
      'Ação manual ou automática que incide sobre o processo de evolução de uma ocorrência',
    formType: TriggerType.INTERVENTION_CREATED,
  },
  [GROUPED_TRIGGER_TYPE.TIME]: {
    Icon: TimeIcon,
    title: GROUPED_TRIGGER_TYPE_EN_PT.TIME,
    description:
      'Intervalo em que uma ocorrência permanece no seu estado atual',
    formType: TriggerType.TIME_BASED,
  },
}

export const groupedTriggerType: Record<TriggerType, GROUPED_TRIGGER_TYPE> = {
  [TriggerType.EVENT_ARRIVAL]: GROUPED_TRIGGER_TYPE.ARRIVAL,
  [TriggerType.OCCURRENCE_ARRIVAL]: GROUPED_TRIGGER_TYPE.ARRIVAL,
  [TriggerType.OCCURRENCE_CLOSING]: GROUPED_TRIGGER_TYPE.CLOSING,
  [TriggerType.TIME_BASED]: GROUPED_TRIGGER_TYPE.TIME,
  [TriggerType.INTERVENTION_CREATED]: GROUPED_TRIGGER_TYPE.INTERVENTION,
}
