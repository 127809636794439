import { formatDecimal } from 'utilities/date'

export function getDays(): string[] {
  return Array.from({ length: 31 }, (_, index) => formatDecimal(index + 1))
}

export function getYears(): string[] {
  const initialYear = new Date().getFullYear()
  const finalYear = new Date().getFullYear() + 30

  return Array.from({ length: finalYear - initialYear + 1 }, (_, index) =>
    (initialYear + index).toString(),
  )
}
