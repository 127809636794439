import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'
import { Modal } from './types'

interface Provider {
  children: ReactNode
}

interface ModalsContextProps {
  modal: Modal | null
  handleCloseModal: () => void
  handleOpenModal: (modal: Modal) => void
}

const defaultValue: ModalsContextProps = {
  modal: null,
  handleCloseModal: () => null,
  handleOpenModal: () => null,
}

export const ModalsContext = createContext<ModalsContextProps>(defaultValue)

export function useModal(): ModalsContextProps {
  const context = useContext(ModalsContext)

  if (!context)
    throw new Error(
      'You need to use ServiceOrderModalContext within a ServiceOrderModalProvider',
    )

  return context
}

export const ModalsProvider: FC<Provider> = ({ children }) => {
  const [modal, setModal] = useState<Modal | null>(null)

  const handleOpenModal = useCallback((modal: Modal) => {
    setModal(modal)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModal(null)
  }, [])

  return (
    <ModalsContext.Provider
      value={{
        modal,
        handleOpenModal,
        handleCloseModal,
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}
