import { ButtonV2, Icon, ModalV2 } from 'components'

import styles from './styles.module.scss'

interface ChangeStateProps {
  isVisible: boolean
  onClose: () => void
  onContinue: () => void
}

const ChangeState = ({ isVisible, onClose, onContinue }: ChangeStateProps) => (
  <ModalV2.Root isOpen={isVisible} onClose={onClose}>
    <ModalV2.Content>
      <ModalV2.Title className={styles.title}>
        <Icon name="warning" width={20} height={20} />
        Mudar status de uma ocorrência com deslocamento
      </ModalV2.Title>
      <p>
        {`Esta ocorrência recebeu um deslocamento, porém seu status não foi
          atualizado para "No local". Tem certeza de que deseja alterar?`}
      </p>
      <ModalV2.Footer>
        <ModalV2.Close asChild>
          <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
        </ModalV2.Close>
        <ButtonV2 onClick={onContinue}>Confirmar</ButtonV2>
      </ModalV2.Footer>
    </ModalV2.Content>
  </ModalV2.Root>
)

export default ChangeState
