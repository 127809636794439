import { forwardRef, useRef } from 'react'
import { useToggle } from '../../shared/hooks'
import Tooltip from '../Tooltip/Tooltip'

import { Tag, TagSize } from 'components/Tags/types'
import styles from './Tags.module.scss'
import { AccountTag } from 'services/account/types'

export interface TagsProps {
  data: Tag[] | AccountTag[]
  keyId: string
  keyLabel: string
  expandTags?: boolean
  className?: string
  size?: TagSize
  hideDefaultTooltip?: boolean
}

export const Tags = forwardRef<HTMLParagraphElement, TagsProps>(
  (props, ref) => {
    const {
      data,
      keyId,
      keyLabel,
      expandTags = false,
      className,
      size = 'md',
      hideDefaultTooltip,
    } = props
    const tooltip = useToggle()
    const TAG_ARRAY_INDEX_CONTROL = {
      firstTag: 0,
      extraTags: 1,
      extraTagsArrayInit: 2,
    }

    const tagRef = useRef<HTMLLIElement>(null)

    const renderExtraTags = () => {
      if (expandTags) {
        const dataWithoutFirstElement = data.filter(
          (item) => item.id !== data[TAG_ARRAY_INDEX_CONTROL.firstTag].id,
        )
        return dataWithoutFirstElement.map((_, index) => {
          return (
            <li
              key={dataWithoutFirstElement[index].id}
              id={dataWithoutFirstElement[index].id}
              className={[
                styles.extraTags,
                styles.expandedTags,
                styles[`tag${size}`],
              ].join(' ')}
              aria-label="firstTag"
            >
              <p>{dataWithoutFirstElement[index].name}</p>
            </li>
          )
        })
      } else {
        return (
          <li
            className={[styles.extraTags, styles[`tag${size}`]].join(' ')}
            id={`${keyId}-${keyLabel}`}
            ref={tagRef}
            aria-label="extraTags"
            onMouseEnter={() => tooltip.show()}
            onMouseLeave={() => tooltip.hide()}
          >
            <Tooltip
              parentRef={tagRef}
              type="informative"
              isVisible={!hideDefaultTooltip && tooltip.isVisible}
            >
              {data
                .slice(TAG_ARRAY_INDEX_CONTROL.extraTags)
                .map((item, index) => {
                  const separator =
                    index <
                    data.length - TAG_ARRAY_INDEX_CONTROL.extraTagsArrayInit
                      ? ''
                      : ''
                  return (
                    <p className={styles.tagTooltipContent} key={item.id}>
                      {item.name + separator}
                    </p>
                  )
                })}
            </Tooltip>

            <p ref={ref} className={styles.tagCount}>{`+${data.length - 1}`}</p>
          </li>
        )
      }
    }

    return (
      <>
        {data.length ? (
          <ul
            className={[className, styles.tagWrapper].join(' ')}
            aria-label="tags"
          >
            <li
              id={data[TAG_ARRAY_INDEX_CONTROL.firstTag].id}
              className={[
                expandTags && styles.extraTags,
                styles[`tag${size}`],
              ].join(' ')}
              aria-label="firstTag"
            >
              <p>{data[TAG_ARRAY_INDEX_CONTROL.firstTag].name}</p>
            </li>
            {data.length > 1 && renderExtraTags()}
          </ul>
        ) : null}
      </>
    )
  },
)

Tags.displayName = 'Tags'
