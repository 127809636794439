import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { ButtonV2, ModalV2, Textarea } from 'components'

import styles from './styles.module.scss'

interface FormProps {
  note: string
}

type InterventionJustificationProps = {
  isVisible: boolean
  onClose: () => void
  onContinue: (note: string) => void
}
export const schema = Joi.object<FormProps>({
  note: Joi.string().required(),
})

const InterventionJustification = ({
  isVisible,
  onClose,
  onContinue,
}: InterventionJustificationProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormProps>({
    resolver: joiResolver(schema),
    mode: 'onChange',
  })

  return (
    <ModalV2.Root isOpen={isVisible} onClose={onClose}>
      <ModalV2.Content className={styles.container}>
        <ModalV2.Title>Justificar ação</ModalV2.Title>
        <form onSubmit={handleSubmit((data) => onContinue(data.note))}>
          <Textarea
            rows={5}
            label="Justificativa"
            {...register('note')}
            onChange={(event) =>
              setValue('note', event.target.value, {
                shouldValidate: true,
              })
            }
          />
          <ModalV2.Footer>
            <ModalV2.Close asChild>
              <ButtonV2 appearance="secondary">Cancelar</ButtonV2>
            </ModalV2.Close>
            <ButtonV2 type="submit" disabled={!isValid}>
              Salvar
            </ButtonV2>
          </ModalV2.Footer>
        </form>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default InterventionJustification
