import { useEffect } from 'react'

import { useForm, useFormContext } from 'react-hook-form'
import { AggregatedRule } from 'services/attendancePolicy/types'
import { joiResolver } from '@hookform/resolvers/joi'

import { ReactComponent as ButtonBack } from 'assets/svg/leftArrow.svg'
import { Button } from 'components'

import { AccordionForm } from 'domains/attendancePolicy/components'
import { actionsSchema } from 'domains/attendancePolicy/schema'

import styles from './ActionFormGroup.module.scss'

interface ConditionFormGroupProps {
  onGoBack: () => void
}

export const ActionFormGroup = ({ onGoBack }: ConditionFormGroupProps) => {
  const { setValue: setRuleForm, watch: watchRuleForm } =
    useFormContext<AggregatedRule>()

  const form = useForm<Pick<AggregatedRule, 'actions'>>({
    mode: 'onSubmit',
    resolver: joiResolver(actionsSchema),
    defaultValues: {
      actions: watchRuleForm('actions') || [],
    },
  })

  const { register, watch } = form

  const actions = watch('actions')

  useEffect(() => {
    register('actions')
  }, [register])

  return (
    <>
      <Button
        buttonTitle={'Voltar para automação'}
        type="tertiary"
        icon={ButtonBack}
        className={styles.buttonBack}
        onClick={onGoBack}
      />

      <div className={styles.header}>
        <span>{'Adicionar ação'}</span>
        <p>
          Realiza modificações em uma ocorrência após a execução de um gatilho
        </p>
      </div>

      <div className={styles.container}>
        <AccordionForm
          actionList={actions}
          onSubmit={(actions) => {
            setRuleForm('actions', actions)
            onGoBack()
          }}
        />
      </div>
    </>
  )
}
