import { useNavigate } from 'react-router-dom'

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'
import { ReactComponent as ShareIcon } from 'assets/svg/shareBox.svg'

import {
  ServiceOrderLabelType,
  SERVICE_ORDER_TYPE_EN_PT,
  useGetServiceOrderDetails,
} from 'services/serviceOrder'
import { Button, Popover } from 'components'

import { Loader } from './components'
import styles from './styles.module.scss'
import { handleStatusLabel } from 'domains/serviceOrders/utilities'

export const serviceOrderLabelType: Record<ServiceOrderLabelType, string> = {
  ISSUE: 'Problema',
  ORIGIN: 'Solicitante',
  PAUSE_REASON: 'Motivo da pausa',
  REASON: 'Motivo',
  RESOLUTION: 'Solução',
}

const handleStateRender = (data: unknown, isFetched: boolean) => {
  if (data && isFetched) {
    return 'view'
  }

  if (isFetched && !data) {
    return 'error'
  }

  return 'loading'
}

interface RichTooltipProps {
  serviceOrderId: string
}

const RichTooltip = ({ serviceOrderId }: RichTooltipProps) => {
  const navigate = useNavigate()
  const { data, isFetched } = useGetServiceOrderDetails(serviceOrderId)

  const address = data?.patrimony?.address

  return (
    <div
      className={[styles.container, styles[data?.status || 'SCHEDULED']].join(
        ' ',
      )}
    >
      <Popover.Close>
        <CloseIcon />
      </Popover.Close>
      {
        {
          view: !!data && (
            <>
              <section>
                <div>
                  <div className={styles.principalTag}>
                    {handleStatusLabel(data.status)}
                  </div>
                  <div>{data.number}</div>
                </div>
                <span>{data.account?.aggregatedName}</span>
              </section>
              <div className={styles.scroll}>
                <section>
                  <div>
                    <h4>Motivo</h4>
                    <span>{data.reason || '-'}</span>
                  </div>
                  <div>
                    <h4>Tipo</h4>
                    <span>
                      {SERVICE_ORDER_TYPE_EN_PT[data.serviceOrderType] || '-'}
                    </span>
                  </div>
                  <div>
                    <h4>Endereço</h4>
                    <span>
                      {address
                        ? `${address.address}, ${address.number} - ${address.district}, ${address.city}, ${address.acronym}`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <h4>Complemento</h4>
                    <span>{data.note || '-'}</span>
                  </div>
                  <div>
                    <h4>Tags da conta</h4>
                    <div className={styles.tags}>
                      {data.tags?.map((tag) => (
                        <span key={tag.id}>{tag.name}</span>
                      )) || '-'}
                    </div>
                  </div>
                </section>
              </div>
              <section>
                <Button
                  type="tertiary"
                  icon={ShareIcon}
                  buttonTitle="Ver mais"
                  onClick={() => navigate(`/soV2/${data.id}`)}
                  className={styles.iconButton}
                />
              </section>
            </>
          ),
          loading: <Loader />,
          error: (
            <span className={styles.error}>
              Erro ao carregar detalhes da ordem de serviço. Tente novamente.
            </span>
          ),
        }[handleStateRender(data, isFetched)]
      }
    </div>
  )
}

export default RichTooltip
