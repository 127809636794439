import {
  OccurrenceStateName,
  translatedOccurrenceState,
} from 'domains/attendancePolicy/components/RuleForm/data/occurrenceStateName'
import { translatedInterventionType } from 'domains/attendancePolicy/components/RuleForm/utilities/trigger/data/interventionType'
import {
  Policy,
  PolicyDetail,
  TIME_UNIT_EN_PT,
} from 'domains/attendancePolicy/types'
import {
  AggregatedTrigger,
  SensorType,
  TriggerType,
} from 'services/attendancePolicy/types'
import { SENSOR_TYPE_EN_PT } from 'services/device/types'
import { EventTypeResponse } from 'services/event/types'
import { TimeUnit } from 'services/serviceType/types'

type TriggerTypeMap = {
  title: string
  subtitle?: string
}

const triggerTypeMap: Record<TriggerType, TriggerTypeMap> = {
  [TriggerType.EVENT_ARRIVAL]: {
    title: 'Chegada',
    subtitle: 'Evento',
  },
  [TriggerType.OCCURRENCE_ARRIVAL]: {
    title: 'Chegada',
    subtitle: 'Ocorrência',
  },
  [TriggerType.OCCURRENCE_CLOSING]: {
    title: 'Fechamento',
  },
  [TriggerType.INTERVENTION_CREATED]: {
    title: 'Intervenção',
  },
  [TriggerType.TIME_BASED]: {
    title: 'Tempo',
  },
}

const translatedTimeUnitMap: Record<TimeUnit, string> = {
  [TimeUnit.DAYS]: 'Dia',
  [TimeUnit.HOURS]: 'Hora',
  [TimeUnit.MINUTES]: 'Minuto',
  [TimeUnit.SECONDS]: 'Segundo',
}

export const translatedSensorType: Record<SensorType, string> = {
  [SensorType.MAIN]: 'Principal',
  [SensorType.TAMPER_CASE]: 'Tamper',
  [SensorType.ANTI_SABOTAGE]: 'Anti Sabotagem',
  [SensorType.ACCELEROMETER]: 'Shock',
  [SensorType.DRY_CONTACT]: 'Contato Seco',
  [SensorType.CENTRAL]: 'Central',
  [SensorType.UNKNOWN]: 'Central',
}

const formatEventTypeDetails = (eventType: EventTypeResponse[]): string => {
  return eventType
    .map((type) => `${type.name} - ${type.description}`)
    .join(', \n')
}

const formatSensorTypeDetails = (sensorTypes: SensorType[]): string =>
  sensorTypes.map((type) => translatedSensorType[type]).join(', \n')

const formatTimeInterval = (
  timeInterval: number,
  timeUnit: TimeUnit,
  occurrenceState: OccurrenceStateName,
): string =>
  `${timeInterval} ${translatedTimeUnitMap[timeUnit]}${
    timeInterval > 1 ? 's' : ''
  } - ${translatedOccurrenceState[occurrenceState]}`

export const getTrigger = (trigger?: AggregatedTrigger): Policy | undefined => {
  if (!trigger) return undefined

  const {
    sensorTypes,
    timeInterval,
    timeUnit,
    eventType,
    occurrenceState,
    interventionType,
  } = trigger.parameters

  const triggerType = triggerTypeMap[trigger.type]

  let subtitle = triggerType?.subtitle || ''
  const details: PolicyDetail[] = []

  if (eventType) {
    details.push({
      title: 'Tipo',
      description: formatEventTypeDetails(eventType),
    })

    if (sensorTypes?.length) {
      details.push({
        title: 'Sensor',
        description: formatSensorTypeDetails(sensorTypes),
      })
    }
  }

  if (timeInterval && timeUnit && occurrenceState) {
    subtitle = formatTimeInterval(timeInterval, timeUnit, occurrenceState)
  }

  if (interventionType) {
    subtitle = translatedInterventionType[interventionType]
  }

  return {
    title: triggerType.title,
    subtitle,
    details,
  }
}

export const convertSensorType: Record<SENSOR_TYPE_EN_PT, SensorType> = {
  [SENSOR_TYPE_EN_PT.PRINCIPAL]: SensorType.MAIN,
  [SENSOR_TYPE_EN_PT.TAMPER]: SensorType.TAMPER_CASE,
  [SENSOR_TYPE_EN_PT.ANTI_SABOTAGE]: SensorType.ANTI_SABOTAGE,
  [SENSOR_TYPE_EN_PT.SHOCK]: SensorType.ACCELEROMETER,
  [SENSOR_TYPE_EN_PT.DRY_CONTACT]: SensorType.DRY_CONTACT,
  [SENSOR_TYPE_EN_PT.CENTRAL]: SensorType.CENTRAL,
}

export const convertTimeUnitMap: Record<TIME_UNIT_EN_PT, TimeUnit> = {
  [TIME_UNIT_EN_PT.DAYS]: TimeUnit.DAYS,
  [TIME_UNIT_EN_PT.HOURS]: TimeUnit.HOURS,
  [TIME_UNIT_EN_PT.MINUTES]: TimeUnit.MINUTES,
  [TIME_UNIT_EN_PT.SECONDS]: TimeUnit.SECONDS,
}
