import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { AxiosError } from 'axios'

import { Vehicle, VehicleDriver } from 'services/vehicle'
import { vehicleSchema } from '../../schemas'
import { useToast } from 'shared/hooks'
import { Breadcrumbs } from 'components'

import { VehicleForm } from '../../components/VehicleForm/VehicleForm'
import styles from './CreateVehicle.module.scss'
import { DomainPath } from 'routes/constants/paths/domains'

export const CreateVehicle = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()

  const form = useForm<Vehicle>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(vehicleSchema),
  })

  const onSave = useCallback(
    async (data: Vehicle) => {
      try {
        await VehicleDriver.create(data)
        addToast({ message: 'Viatura cadastrada com sucesso.' })
        navigate(DomainPath.Vehicle)
      } catch (error) {
        const { response } = error as AxiosError
        const message =
          response?.status === 412
            ? 'A placa inserida já pertence à outra viatura.'
            : 'Não foi possível cadastrar o veículo. Tente novamente.'

        addToast({ message, type: 'alert' })
      }
    },
    [addToast, navigate],
  )

  return (
    <div className={styles.container}>
      <Breadcrumbs
        items={[
          { title: 'Home', href: '/' },
          { title: 'Viaturas', href: '/config/vehicles' },
          { title: 'CADASTRO DE VIATURA', href: '' },
        ]}
      />

      <FormProvider {...form}>
        <VehicleForm onSubmit={onSave} />
      </FormProvider>
    </div>
  )
}
