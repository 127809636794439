import {
  useQuery,
  UseQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { PatrimonyDriver } from 'services/patrimony'
import {
  PartitionListResponse,
  PatrimonyFragment,
} from 'services/patrimony/types'

import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { useMemo } from 'react'

const getPatrimonies = async (
  customerId: string,
  offset = 0,
  filter?: string,
) => {
  const response = await PatrimonyDriver.queryPatrimoniesFragmentList(
    customerId,
    {
      recordsPerPage: 15,
      offset,
      ...(filter && { name: filter }),
    },
  )
  return response
}

type GetPartitions = {
  patrimonyId?: string
  queryOptions?: {
    enabled?: boolean
  }
}

export const useGetPartitions = ({
  patrimonyId,
  queryOptions,
}: GetPartitions): UseQueryResult<PartitionListResponse[]> => {
  return useQuery({
    queryKey: ['get-partitions', patrimonyId],
    queryFn: async () => {
      if (patrimonyId) {
        const response = await PatrimonyDriver.getPartitionsList(patrimonyId)

        return response.data
      }
    },
    ...queryOptions,
  })
}

export function useGetPatrimonies(customerId: string, filter?: string) {
  const {
    isError,
    data,
    isFetching,
    fetchNextPage: fetchNextPatrimoniesPage,
  } = useInfiniteQuery({
    queryKey: ['patrimonies', customerId, filter],
    queryFn: ({ pageParam }) => getPatrimonies(customerId, pageParam, filter),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<PatrimonyFragment>(lastPage, allPages),
  })

  const patrimonies = useMemo(
    () => flattenPages<PatrimonyFragment>(data),
    [data],
  )

  return {
    isError,
    patrimonies,
    fetchNextPatrimoniesPage,
    isFetching,
  }
}
