import { useMemo, useState } from 'react'

import { Accordion, Icon, Input } from 'components'
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'

import {
  useDisplacementData,
  useDisplacementModal,
  useMap,
} from 'domains/occurrence/screens/DisplacementMap/contexts'

import { BackDrop } from 'domains/occurrence/screens/DisplacementMap/components'

import TacticalItem from './components/TacticalItem'

import styles from './Tactical.module.scss'

export const Tactical = () => {
  const { handleCloseModal } = useDisplacementModal()
  const {
    tacticals: { data },
  } = useDisplacementData()
  const { handleRoute } = useMap()

  const [filter, setFilter] = useState('')

  const { paused, active } = useMemo(() => {
    const filteredItems = filter
      ? data.filter((item) =>
          item.agent.name.toLowerCase().includes(filter.toLowerCase()),
        )
      : data

    return {
      paused: filteredItems
        .filter((item) => item.status === 'PAUSED')
        .sort((a, b) => a.agent.name.localeCompare(b.agent.name)),
      active: filteredItems
        .filter((item) =>
          [
            'COMMUNICATION_OFF',
            'COMMUNICATION_ON',
            'DISPLACEMENT',
            'WITHOUT_COMMUNICATION',
          ].includes(item.status),
        )
        .sort((a, b) => a.agent.name.localeCompare(b.agent.name)),
    }
  }, [filter, data])

  return (
    <BackDrop onClose={handleCloseModal}>
      <main className={styles.container}>
        <header>
          <h1>Agentes táticos</h1>
          <button type="button" onClick={handleCloseModal}>
            <CloseIcon />
          </button>
        </header>
        <form className={styles.content}>
          <div className={styles.search}>
            <Icon name="search" />
            <Input
              placeholder="Buscar agente tático"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <Accordion.Root>
            {!!paused.length && (
              <Accordion.Item>
                <Accordion.Header>
                  <Accordion.Trigger className={styles.trigger}>
                    <div className={styles.accordionHeader}>
                      <div>Pausados</div>
                      <Accordion.Icon />
                    </div>
                  </Accordion.Trigger>
                </Accordion.Header>

                <Accordion.Content>
                  <div
                    className={[styles.itemsContainer, styles.paused].join(' ')}
                  >
                    {paused.map((tactical) => {
                      return (
                        <TacticalItem
                          key={tactical.id}
                          {...tactical}
                          onViewTactical={() => {
                            handleRoute({
                              marker: tactical,
                              type: 'tactical',
                            })

                            handleCloseModal()
                          }}
                        />
                      )
                    })}
                  </div>
                </Accordion.Content>
                <div className={styles.divider} />
              </Accordion.Item>
            )}

            {!!active.length && (
              <Accordion.Item>
                <Accordion.Header>
                  <Accordion.Trigger className={styles.trigger}>
                    <div className={styles.accordionHeader}>
                      <div>Ativos</div>
                      <Accordion.Icon />
                    </div>
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <div className={styles.itemsContainer}>
                    {active.map((tactical) => {
                      return (
                        <TacticalItem
                          key={tactical.id}
                          {...tactical}
                          onViewTactical={() => {
                            handleRoute({
                              marker: tactical,
                              type: 'tactical',
                            })

                            handleCloseModal()
                          }}
                        />
                      )
                    })}
                  </div>
                </Accordion.Content>
              </Accordion.Item>
            )}
          </Accordion.Root>
        </form>
      </main>
    </BackDrop>
  )
}
