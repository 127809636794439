import { ReactComponent as Copy } from 'assets/svg/copy.svg'
import { ReactComponent as Arrow } from 'assets/svg/arrow.svg'

import { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon, Button } from 'components'

import styles from './styles.module.scss'

interface FallBackErrorProps {
  error: Error | undefined
}

const FallBackError = ({ error }: FallBackErrorProps) => {
  const [open, toggle] = useReducer((old) => !old, false)

  const navigate = useNavigate()

  const transformStackTrace = (stack: string) => {
    const stackLines = stack.split(/\s+at\s+/)
    const firstThreeLines = stackLines.slice(0, 4)

    return firstThreeLines
      .map((line, index) => (index === 0 ? line : '    at ' + line))
      .join('\n')
  }

  const formattedError = `pathname: ${window.location.pathname}\nmessage: ${error?.message}\nstack: ${error?.stack ? transformStackTrace(error.stack) : ''}`

  const handleCopyError = () => {
    navigator.clipboard.writeText(formattedError)
  }

  return (
    <div className={styles.errorContainer}>
      <div className={styles.header}>
        <Icon name="screen-error" width={96} height={80} />
        <h1>Algo deu errado</h1>
        <p>Atualize a página e tente novamente.</p>
      </div>

      <div className={styles.body}>
        <p>
          Se o problema continuar, entre em contato com nosso suporte técnico e
          forneça o código de erro abaixo.
        </p>

        <Button
          onClick={handleCopyError}
          className={styles.copy}
          icon={Copy}
          buttonTitle="Copiar erro"
          type="primary"
        />

        <Button
          icon={Arrow}
          className={[styles.code, open && styles.upsideArrow].join(' ')}
          type="tertiary"
          buttonTitle="Mostrar erro"
          onClick={toggle}
        />

        <div className={styles.codeSection}>
          {open && (
            <textarea
              readOnly
              defaultValue={formattedError}
              spellCheck={false}
              className={styles.errorStack}
            />
          )}
        </div>
      </div>
      <Button
        onClick={() => navigate(-1)}
        buttonTitle="Voltar"
        type="tertiary"
      />
    </div>
  )
}

export default FallBackError
