import { SortedCustomer } from 'services/customer/types'
import * as Joi from '@hapi/joi'

import {
  ServiceOrderContact,
  ServiceOrderPayload,
  serviceOrderStatuses,
  serviceOrderTypes,
} from 'services/serviceOrderV2/types'

import {
  ServiceOrderLabelInput,
  serviceOrderLabelType,
} from 'services/serviceOrderLabel/types'

export const customerSchema = Joi.object<SortedCustomer>({
  id: Joi.string().uuid().required(),
  name: Joi.string().required(),
})

const contactSchema = Joi.object<ServiceOrderContact>({
  id: Joi.string().uuid().optional().allow('', null),
  name: Joi.string().required(),
  provinceCode: Joi.number().optional().allow('', null),
  countryCode: Joi.number().optional().allow('', null),
  number: Joi.number().optional().min(10000000).allow('', null),
})

export const serviceOrderSchema = Joi.object<
  Omit<ServiceOrderPayload, 'userId'>
>({
  account: Joi.object({
    id: Joi.string().uuid().required(),
    code: Joi.string().required(),
    aggregatedName: Joi.string().allow(null, '').optional(),
  }).required(),
  contact: contactSchema.optional().allow('', null),
  customer: customerSchema.required(),
  id: Joi.string().uuid().allow('', null),
  labels: Joi.array()
    .items(
      Joi.object<ServiceOrderLabelInput>({
        id: Joi.string().uuid().required(),
        description: Joi.string().required(),
        active: Joi.boolean().optional().allow('', null),
        type: Joi.string()
          .valid(serviceOrderLabelType.REASON, serviceOrderLabelType.ORIGIN)
          .required(),
      }),
    )
    .length(2),
  note: Joi.string().required(),
  number: Joi.number().optional().allow('', null),
  scheduleDate: Joi.number().optional().allow('', null),
  schedulePeriod: Joi.string().optional().allow('', null),
  status: Joi.string()
    .valid(...Object.keys(serviceOrderStatuses))
    .allow('', null)
    .optional(),
  tags: Joi.array()
    .items(Joi.object({ name: Joi.string().required() }))
    .allow('', null)
    .optional(),
  technician: Joi.object({
    id: Joi.string().uuid().optional(),
    name: Joi.string().required(),
  })
    .allow('', null)
    .optional(),
  type: Joi.string()
    .valid(...Object.keys(serviceOrderTypes))
    .required(),
})
