import { CustomerDriver } from 'services/customer'
import { SortedCustomer } from 'services/customer/types'
import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

type UseGetCustomers = {
  customers: SortedCustomer[] | undefined
  isFetching: boolean
  isError: boolean
  fetchNextCustomersPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const getCustomers = async (offset = 0, filter?: string) => {
  const response = await CustomerDriver.querySortedCustomers({
    recordsPerPage: 15,
    offset,
    ...(filter && { name: filter }),
  })
  return response
}

export function useGetCustomers(filter?: string): UseGetCustomers {
  const {
    isError,
    data,
    isFetching,
    fetchNextPage: fetchNextCustomersPage,
  } = useInfiniteQuery({
    queryKey: ['customers', filter],
    queryFn: ({ pageParam }) => getCustomers(pageParam, filter),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<SortedCustomer>(lastPage, allPages),
  })

  const customers = useMemo(() => flattenPages<SortedCustomer>(data), [data])

  return {
    isError,
    customers,
    fetchNextCustomersPage,
    isFetching,
  }
}
