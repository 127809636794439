import { ReactComponent as ShareIcon } from 'assets/svg/shareBox.svg'
import styles from './CoverageCard.module.scss'
import { Button } from 'components'

type CoverageCardProps = {
  coverage: {
    id: string
    patrimony: {
      id: string
      name: string
    }
    accountCode: string
    centralName: string
    partitionName: string
  }
  onInspect: (coverageId: string) => void
}

export const CoverageCard = ({ coverage, onInspect }: CoverageCardProps) => {
  return (
    <section className={styles.container} data-testid="coverageCard">
      <header>
        <span className={styles.title}>{coverage.patrimony.name}</span>
        <Button
          buttonTitle="Ver mais"
          type="tertiary"
          icon={ShareIcon}
          onClick={() => onInspect(coverage.id)}
        />
      </header>
      <div className={styles.content}>
        <div>
          <p className={styles.label}>Conta</p>
          <p className={styles.value}>{coverage.accountCode}</p>
        </div>
        <div>
          <p className={styles.label}>Central</p>
          <p className={styles.value}>{coverage.centralName}</p>
        </div>
      </div>
      <div>
        <p className={styles.label}>Partição</p>
        <p className={styles.value}>{coverage.partitionName}</p>
      </div>
    </section>
  )
}
