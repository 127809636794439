import { useQuery } from '@tanstack/react-query'
import { OfficeHoursDriver } from 'services/officeHours'
import { AggregatedOfficeHours } from 'services/officeHours/types'

const getOfficeHour = async (id: string): Promise<AggregatedOfficeHours> =>
  await OfficeHoursDriver.find(id)

export function useGetOfficeHours(officeHourId: string, enabled: boolean) {
  return useQuery<AggregatedOfficeHours>({
    queryKey: ['officeHour', officeHourId],
    queryFn: () => getOfficeHour(officeHourId),
    enabled,
  })
}
