import { ComponentProps } from 'react'
import joinClassNames from 'utilities/joinClassNames'

import Icon from '../Icon'
import styles from './styles.module.scss'

const EmptyState = ({ className, ...props }: ComponentProps<'div'>) => {
  return (
    <div className={joinClassNames(styles.container, className)} {...props}>
      <Icon name="screen-empty" width={34} />
      <span>Sem resultados</span>
      <span>Nenhum registro foi encontrado nesta seção</span>
    </div>
  )
}

export default EmptyState
