import { ConditionType } from 'services/attendancePolicy/types'

export const tagBelongs = {
  ACCOUNT_TAGS: 'À conta',
  OCCURRENCE_TAGS: 'À ocorrência',
  DEVICE_TAGS: 'Ao dispositivo',
  EMPTY: '',
}

export type TagBelongs = keyof typeof tagBelongs

export const translatedTagType: Record<string, TagBelongs> = {
  'À conta': 'ACCOUNT_TAGS',
  'À ocorrência': 'OCCURRENCE_TAGS',
  'Ao dispositivo': 'DEVICE_TAGS',
}

export const tagTypeCondition: Record<string, TagBelongs> = {
  [ConditionType.ACCOUNT_TAGS]: 'ACCOUNT_TAGS',
  [ConditionType.OCCURRENCE_TAGS]: 'OCCURRENCE_TAGS',
  [ConditionType.DEVICE_TAGS]: 'DEVICE_TAGS',
}
