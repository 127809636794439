import { Filter, OccurrenceListByCoverage } from 'services/occurrence/types'

export const getColumnFilters = (
  occurrences: OccurrenceListByCoverage[],
): Filter[] => {
  const filters: Filter[] = []

  occurrences.forEach((occurrence) => {
    const occurrenceFilters = Object.values(occurrence.filters).filter(
      (filter) => filter.value,
    )
    filters.push(...occurrenceFilters)
  })

  const uniqueLabels = new Set<string>()
  const removedDuplicatedFilters = filters.filter((filter) => {
    if (!uniqueLabels.has(filter.label)) {
      uniqueLabels.add(filter.label)
      return true
    }
    return false
  })

  return removedDuplicatedFilters
}

export const filterOccurrences = (
  filters: Filter[],
  occurrences: OccurrenceListByCoverage[],
): OccurrenceListByCoverage[] => {
  const filterLabel = filters.map((filter) => filter.label)

  const result = occurrences.filter((occurrence) => {
    return filterLabel.some((filterLabel) => {
      return Object.values(occurrence.filters).some((occurrenceFilter) => {
        return occurrenceFilter.label === filterLabel && occurrenceFilter.value
      })
    })
  })

  return result
}
