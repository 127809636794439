import { useEffect, useMemo, useState } from 'react'
import { GoogleMap, Polyline, TrafficLayer } from '@react-google-maps/api'

import { OccurrenceDetails, TacticalDetails, Marker } from './components'
import { defaultMap, darkMap, traceOptions, mapProps } from './utils'
import {
  OccurrenceMarker,
  Position,
  TacticalMarker,
} from 'services/displacementMap/types'
import {
  useDisplacementData,
  useMap,
  useDisplacementModal,
  useFilters,
  Filters,
} from '../../contexts'
import {
  occurrenceStateNameConverted,
  occurrenceTypesConverted,
} from '../../utils'

type TracedRoute = {
  color?: string
  origin: Position
  destination: Position
}

const handleFilterOccurrence = (data: OccurrenceMarker[], filters: Filters) => {
  return data.filter((data) => {
    const accountFilter = !filters.account || filters.account === data.accountId

    const typeFilter =
      !filters.occurrenceType?.length ||
      filters.occurrenceType.includes(occurrenceTypesConverted[data?.typeName])

    const statusFilter =
      !filters.displacementStatus?.length ||
      filters.displacementStatus.includes(
        occurrenceStateNameConverted[data.stateName],
      )

    return accountFilter && typeFilter && statusFilter
  })
}

const handleFilterTactical = (data: TacticalMarker[], filters: Filters) => {
  return data.filter((data) => {
    const statusFilter =
      !filters?.status?.length || filters?.status.includes(data.status)

    const agentNameFilter =
      !filters?.agentName || filters?.agentName.includes(data.agent.name)

    return statusFilter && agentNameFilter
  })
}

export const Map: React.FC = () => {
  const { handleMap, selectedRoute, handleRoute, handleUnfocusedRoute } =
    useMap()

  const { filters } = useFilters()
  const { occurrences: occurrenceData, tacticals: tacticalData } =
    useDisplacementData()
  const { modal } = useDisplacementModal()
  const [tracedRoutes, setTracedRoutes] = useState<TracedRoute[]>([])

  const tacticals = useMemo(
    () => handleFilterTactical(tacticalData.data ?? [], filters),
    [filters, tacticalData, occurrenceData],
  )

  const occurrences = useMemo(
    () => handleFilterOccurrence(occurrenceData.data ?? [], filters),
    [filters, tacticalData, occurrenceData],
  )

  const hasMarkerFocused = useMemo(
    () =>
      !!selectedRoute?.tactical?.focused ||
      !!selectedRoute?.occurrence?.focused,
    [selectedRoute],
  )

  const tacticalForRender = useMemo(() => {
    const tacticalsWithoutMultipleLinks = tacticals?.filter(
      (tactical) => !tactical.allowMultipleLinks,
    )

    return hasMarkerFocused
      ? [
          tacticalsWithoutMultipleLinks?.find(
            (att) => att.id === selectedRoute?.tactical?.id,
          ),
        ]
      : tacticalsWithoutMultipleLinks
  }, [hasMarkerFocused, selectedRoute, tacticals])

  const occurrencesForRender = useMemo(() => {
    if (hasMarkerFocused) {
      if (selectedRoute.tactical?.id) {
        const currentTactical = tacticals.find(
          (tactical) => tactical.id === selectedRoute.tactical?.id,
        )

        return occurrences?.filter((occ) =>
          currentTactical?.occurrencesLinked?.some(
            (linked) => linked.occurrenceId === occ.id,
          ),
        )
      } else {
        return [
          occurrences?.find((occ) => occ.id === selectedRoute?.occurrence?.id),
        ]
      }
    } else {
      return occurrences
    }
  }, [hasMarkerFocused, selectedRoute, occurrences, tacticals])

  useEffect(() => {
    const routes: TracedRoute[] = []

    tacticals?.forEach((tactical) => {
      if (!tactical.allowMultipleLinks) {
        const occurrence = occurrences?.find(
          (occurrence) => occurrence.tacticalId === tactical.id,
        )

        if (occurrence) {
          routes.push({
            color: tactical.color,
            origin: tactical.position,
            destination: occurrence.position,
          })
        }
      }
    })

    setTracedRoutes(routes)

    if (selectedRoute.occurrence?.focused) {
      const currentOccurrence = occurrences?.filter(
        (occurrence) => occurrence.id === selectedRoute.occurrence?.id,
      )[0]

      currentOccurrence
        ? handleRoute({
            marker: currentOccurrence,
            type: 'occurrence',
          })
        : handleUnfocusedRoute('occurrence')
    }
  }, [tacticals, occurrences, handleRoute, handleUnfocusedRoute])

  return (
    <div>
      {selectedRoute?.tactical?.focused && !modal && (
        <TacticalDetails
          tacticalId={selectedRoute?.tactical.id}
          onClose={() => handleUnfocusedRoute('tactical')}
        />
      )}

      {selectedRoute?.occurrence?.focused && !modal && (
        <OccurrenceDetails
          occurrenceId={selectedRoute?.occurrence.id}
          onClose={() => handleUnfocusedRoute('occurrence')}
        />
      )}
      <GoogleMap
        {...mapProps}
        onLoad={handleMap}
        options={{
          styles: hasMarkerFocused || modal ? darkMap : defaultMap,
          disableDefaultUI: true,
          minZoom: 3,
          maxZoom: 52,
        }}
      >
        <TrafficLayer />

        {tacticalForRender?.map(
          (tactical) =>
            tactical && (
              <Marker
                key={tactical.id}
                {...tactical}
                onAction={() =>
                  handleRoute({ marker: tactical, type: 'tactical' })
                }
              />
            ),
        )}
        {occurrencesForRender?.map(
          (occurrence) =>
            occurrence && (
              <Marker
                key={occurrence.id}
                {...occurrence}
                onAction={() =>
                  handleRoute({ marker: occurrence, type: 'occurrence' })
                }
              />
            ),
        )}
        {tracedRoutes.map((route) => {
          const isSelectedRoute =
            JSON.stringify(route.destination) ===
            JSON.stringify(selectedRoute?.occurrence?.position)
          const isFocusedRoute = isSelectedRoute && hasMarkerFocused

          return (
            (isFocusedRoute || !hasMarkerFocused) && (
              <Polyline
                key={route.origin.latitude}
                path={[
                  {
                    lat: route.origin.latitude,
                    lng: route.origin.longitude,
                  },
                  {
                    lat: route.destination.latitude,
                    lng: route.destination.longitude,
                  },
                ]}
                options={{
                  ...traceOptions,
                  strokeColor: isFocusedRoute ? '#FFF' : route.color,
                }}
              />
            )
          )
        })}
      </GoogleMap>
    </div>
  )
}
