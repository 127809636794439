const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

export interface PixelCrop {
  width: number
  height: number
  x: number
  y: number
}

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: PixelCrop,
  rotation = 0,
): Promise<Blob | MediaSource> {
  const image = await createImage(imageSrc)
  const cropCanvas = document.createElement('canvas')
  const cropCtx = cropCanvas.getContext('2d')

  if (!cropCtx) {
    throw new Error('Image can not be cropped.')
  }

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  cropCanvas.width = safeArea
  cropCanvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  cropCtx.translate(safeArea / 2, safeArea / 2)
  cropCtx.rotate(getRadianAngle(rotation))
  cropCtx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  cropCtx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  )
  const data = cropCtx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  cropCanvas.width = pixelCrop.width
  cropCanvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  cropCtx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg')

  // As a blob
  return new Promise((resolve) => {
    const resizeCanvas = document.createElement('canvas')
    resizeCanvas.height = 500
    resizeCanvas.width = 500

    const resizeCtx = resizeCanvas.getContext('2d')
    resizeCtx!.drawImage(cropCanvas, 0, 0, 500, 500)

    resizeCanvas.toBlob((file) => {
      if (file) resolve(file)
    }, 'image/jpeg')
  })
}
