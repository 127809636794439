import { useCallback, useEffect, useRef, useState } from 'react'

import {
  Button,
  Input,
  ProtectedButton,
  Table,
  TableRef,
  Tags,
} from 'components'
import { AuthDriver } from 'services/auth'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { ReactComponent as InspectIcon } from 'assets/svg/listInspectIcon.svg'
import { ReactComponent as EmptyStateSearchIcon } from 'assets/svg/emptyStateSearchIcon.svg'
import { ReactComponent as EmptyStateNoResultIcon } from 'assets/svg/search.svg'

import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'

import { Pagination, Result } from 'services/types'
import {
  AggregatedUserQueryResponse,
  UserQueryResult,
} from 'services/auth/types'

import { usePermissions, useToggle } from 'shared/hooks'

import { CellProps, Column } from 'react-table'
import { FormProvider, useForm } from 'react-hook-form'

import styles from './UserManagement.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import {
  UserFilters,
  userFiltersSchema,
} from 'domains/user/schemas/searchUserSchema'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { AttendanceProfileTooltip } from 'domains/user/components/AttendanceProfileTooltip/AttendanceProfileTooltip'
import { Filters } from 'domains/user/components/Filters/Filters'
import { useNavigate } from 'react-router-dom'
import { UbideskPermissions } from 'routes/types'

const columns: Column<AggregatedUserQueryResponse>[] = [
  { id: 'name', Header: 'Nome', accessor: (user) => user.name },
  { id: 'email', Header: 'E-mail', accessor: (user) => user.email },
  {
    id: 'phoneExtension',
    Header: 'Ramal',
    accessor: (user) => user.phoneExtension,
  },
  {
    id: 'profiles',
    Header: 'Perfis',
    accessor: (user) => user,
    Cell: (cell: CellProps<AggregatedUserQueryResponse>) => {
      const tooltip = useToggle()
      const tagRef = useRef<HTMLDivElement>(null)
      const user = cell.value as AggregatedUserQueryResponse

      if (!user.userCoverages) return <></>

      const totalCoverages = user.userCoverages.primary.concat(
        user.userCoverages.secondary,
      )

      return (
        <>
          <div
            data-testid="tag-tooltip"
            onMouseEnter={tooltip.show}
            onMouseLeave={tooltip.hide}
          >
            <Tags
              keyId={user.id}
              className={styles.firstTag}
              keyLabel="user-profiles"
              data={totalCoverages || []}
              hideDefaultTooltip
              ref={tagRef}
            />
          </div>

          {totalCoverages.length > 1 ? (
            <AttendanceProfileTooltip
              parentRef={tagRef}
              coverages={user.userCoverages}
              isVisible={tooltip.isVisible}
            />
          ) : null}
        </>
      )
    },
  },
  {
    id: 'permissions',
    Header: 'Permissões',
    accessor: (user) => user,
    Cell: (cell: CellProps<UserQueryResult>) => {
      const user = cell.value as UserQueryResult
      return (
        <Tags
          size="sm"
          className={styles.firstTag}
          keyId={user.id}
          keyLabel={user.name}
          data={user.permissionGroups || []}
        />
      )
    },
  },
  {
    id: 'Tags',
    Header: 'Tags',
    accessor: (user) => user,
    Cell: (cell: CellProps<UserQueryResult>) => {
      const user = cell.value as UserQueryResult
      return (
        <Tags
          className={styles.firstTag}
          keyId={user.id}
          keyLabel="user-tags"
          data={user.tags || []}
        />
      )
    },
  },
]

export const UserManagement = () => {
  const [emptyStateProps, setEmptyStateProps] = useState({
    title: 'Sem resultados',
    message: 'Nenhum registro foi encontrado nesta seção',
    Icon: EmptyStateSearchIcon,
  })

  const navigate = useNavigate()

  const tableRef = useRef<TableRef>(null)
  const { hasAccess } = usePermissions()

  const { getFilters, setFilters } = usePersistentFilters<UserFilters>('user')

  const form = useForm<UserFilters>({
    resolver: joiResolver(userFiltersSchema),
    mode: 'onChange',
    defaultValues: getFilters(),
  })

  const [hasFilter, setHasFilter] = useState(false)

  const { handleSubmit, setValue, watch } = form

  const name = watch('userNameFilter')
  const email = watch('email')
  const phoneExtension = watch('phoneExtension')
  const coverage = watch('coverage')
  const permissionGroup = watch('permissionGroup')
  const active = watch('active')
  const tags = watch('tags')

  const [resetPagination, setResetPagination] = useState<boolean>(false)

  const fetchUsers = useCallback(
    async (pagination: Pagination) => {
      let result: Result<AggregatedUserQueryResponse> = {
        data: [],
        totalElements: 0,
      }

      if (name) {
        form.reset({
          active: undefined,
          coverage: {},
          email: '',
          permissionGroup: {},
          phoneExtension: 0,
          tags: '',
        })
        setFilters(watch())
      }

      try {
        const users = await AuthDriver.queryUsersWithCoverage({
          ...pagination,
          ...(name && { name }),
          ...(email && { username: email }),
          ...(phoneExtension && { phoneExtension }),
          ...(coverage && { coverageId: coverage.id }),
          ...(permissionGroup && { permissionGroupId: permissionGroup.id }),
          ...(tags && { tags }),
          active,
        })

        result = {
          data: users.data,
          totalElements: users.totalElements,
        }
      } finally {
        setResetPagination(false)
      }

      if (!result.data.length) {
        setEmptyStateProps({
          title: 'Sem resultados',
          message:
            'Nenhum registro foi encontrado utilizando os filtros selecionados.',
          Icon: EmptyStateNoResultIcon,
        })
      }

      return result
    },
    [
      active,
      coverage,
      email,
      name,
      permissionGroup,
      phoneExtension,
      tags,
      setFilters,
      setValue,
      watch,
    ],
  )

  const filterComponent = useToggle()

  useEffect(() => {
    setHasFilter(
      Object.values(watch())
        .slice(1, 7)
        .some((value) => value),
    )
  }, [watch, filterComponent.isVisible])

  return (
    <>
      <FormProvider {...form}>
        <div className={styles.container}>
          <span className={styles.title}>Gestão de usuários</span>
          <div className={styles.divider} />
          <form
            className={styles.inputsWrapper}
            onSubmit={(event) => {
              event.preventDefault()
              setResetPagination(true)
              tableRef.current?.handleFetchPage()
            }}
          >
            <div className={styles.innerWrapper}>
              <div className={styles.filtersButtons}>
                <Input
                  id="userNameFilter"
                  label="Nome"
                  placeholder="Digite o nome do usuário"
                  autoComplete="off"
                  {...form.register('userNameFilter')}
                />
                <Button
                  buttonTitle="Pesquisar"
                  type="primary"
                  width="108px"
                  htmlType="submit"
                />

                <ProtectedButton
                  id="add-user-button"
                  buttonTitle="Novo usuário"
                  type="secondary"
                  icon={PlusIcon}
                  width="145px"
                  onClick={() => {
                    navigate('/config/userManagement/create')
                  }}
                  permissionName={UbideskPermissions.CONFIG_USER_WRITE}
                />

                <Button
                  buttonTitle={
                    hasFilter ? 'Editar filtros' : 'Adicionar filtros'
                  }
                  type="tertiary"
                  icon={FiltersIcon}
                  onClick={filterComponent.show}
                />
              </div>
            </div>

            <Filters
              isVisible={filterComponent.isVisible}
              onClose={filterComponent.hide}
              onApplyFilters={handleSubmit(() => {
                setFilters(watch())
                setResetPagination(true)
                filterComponent.hide()
                tableRef.current?.handleFetchPage()
              })}
            />
          </form>

          <Table
            isRowDisabled={(user) => !user.active}
            columns={columns}
            fetcher={fetchUsers}
            resetPagination={resetPagination}
            paginated
            innerRef={tableRef}
            actions={[
              {
                Icon: InspectIcon,
                label: 'Visualizar',
                handler: (user: AggregatedUserQueryResponse) => {
                  navigate(`/config/userManagement/update/${user.id}`, {
                    state: {
                      readOnly: !hasAccess(
                        UbideskPermissions.CONFIG_USER_WRITE,
                      ),
                    },
                  })
                },
              },
            ]}
            emptyState={emptyStateProps}
          />
        </div>
      </FormProvider>
    </>
  )
}

export default UserManagement
