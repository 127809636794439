import React, { useRef, useState } from 'react'

import { Tooltip } from 'components'
import { maskedDateTime } from 'utilities/date'
import { HistoryIntervention } from 'services/attendance/types'
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { POSITION } from 'shared/hooks/useElementPosition/types'

import styles from './styles.module.scss'

interface ChangeStateCardProps {
  intervention: HistoryIntervention
}

const ChangeStateCard: React.FC<ChangeStateCardProps> = ({ intervention }) => {
  const svgRef = useRef(null)
  const [visible, setVisible] = useState(false)

  const hasNote = !!intervention.notes?.length

  return (
    <li className={styles.container}>
      <h4>
        <strong>{intervention.title}</strong>
        {` por ${intervention.user.name ?? ''} •
      ${maskedDateTime(intervention.createdAt)}`}
      </h4>
      {hasNote && (
        <div ref={svgRef}>
          <InfoIcon
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
          />
          <Tooltip
            positionOnEdge
            hasArrow={false}
            isVisible={visible}
            parentRef={svgRef}
            positionAbove={POSITION.BOTTOM}
            type="informative"
          >
            <p>{intervention.notes?.[0]?.note}</p>
          </Tooltip>
        </div>
      )}
    </li>
  )
}

export default ChangeStateCard
