import Joi from '@hapi/joi'
import { CentralFragment, CommunicationType } from 'services/account/types'

export const centralFragmentSchema = Joi.object<CentralFragment>({
  id: Joi.string().uuid(),
  energy: Joi.boolean(),
  battery: Joi.number(),
  communicationType: Joi.string().valid(...Object.values(CommunicationType)),
  connected: Joi.boolean(),
  partitions: Joi.array(),
})
