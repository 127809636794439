import { Paging, Timestamps } from 'services/types'

export const serviceOrderLabelType = {
  ISSUE: 'ISSUE',
  ORIGIN: 'ORIGIN',
  PAUSE_REASON: 'PAUSE_REASON',
  REASON: 'REASON',
  RESOLUTION: 'RESOLUTION',
}

export type ServiceOrderLabelType = keyof typeof serviceOrderLabelType

export const translateServiceOrderLabelType: Record<
  ServiceOrderLabelType,
  string
> = {
  ISSUE: 'Problema',
  ORIGIN: 'Solicitante',
  PAUSE_REASON: 'Motivo da pausa',
  REASON: 'Motivo',
  RESOLUTION: 'Solução',
}

export type ServiceOrderLabelInput = {
  id: string
  description: string
  type: ServiceOrderLabelType
  active?: boolean
}

export type ServiceOrderLabelQuery = {
  createdFrom?: number
  createdTo?: number
  sort?: string
  sortType?: string
  description?: string
  type?: ServiceOrderLabelType
  active?: boolean
  updatedFrom?: number
  updatedTo?: number
} & Partial<Paging>

export type ServiceOrderLabelOutput = ServiceOrderLabelInput & Timestamps
