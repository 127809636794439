import { RefObject, useCallback, useLayoutEffect, useState } from 'react'
import {
  ElementPosition,
  POSITION,
} from 'shared/hooks/useElementPosition/types'

interface ElementPositionProps {
  enabled: boolean
  positionOnEdge?: boolean
  actionTriggerRef: RefObject<HTMLElement>
  elementRef: RefObject<HTMLElement>
  positionAbove?: POSITION
}

export function useElementPosition({
  enabled,
  positionOnEdge,
  actionTriggerRef,
  elementRef,
  positionAbove,
}: ElementPositionProps): ElementPosition {
  const [elementPosition, setElementPosition] = useState<ElementPosition>({
    top: 0,
    left: 0,
    position: POSITION.BOTTOM,
  })

  const getElementPosition = useCallback((): ElementPosition => {
    let position = { top: 0, left: 0, position: POSITION.BOTTOM }

    if (elementRef.current && actionTriggerRef.current) {
      const actionTriggerRect = actionTriggerRef.current.getBoundingClientRect()

      const ELEMENT_POSITION_Y =
        actionTriggerRect.bottom + elementRef.current.offsetHeight

      const ELEMENT_POSITION_X =
        actionTriggerRect.left + elementRef.current.offsetWidth

      const canBePositionedToBottom = positionAbove
        ? positionAbove === POSITION.BOTTOM
        : window.innerHeight > ELEMENT_POSITION_Y

      const canBePositionedToRight = window.innerWidth > ELEMENT_POSITION_X

      const alignCentered =
        actionTriggerRect.right -
        elementRef.current.offsetWidth / 2 -
        actionTriggerRef.current.offsetWidth / 2

      const alignOnEdge = canBePositionedToRight
        ? actionTriggerRect.right - actionTriggerRef.current.offsetWidth
        : actionTriggerRect.right - elementRef.current.offsetWidth

      const alignPosition = positionOnEdge ? alignOnEdge : alignCentered

      position = {
        top: canBePositionedToBottom
          ? actionTriggerRect.bottom
          : actionTriggerRect.top - elementRef.current.offsetHeight,
        left: alignPosition,
        position: canBePositionedToBottom ? POSITION.BOTTOM : POSITION.TOP,
      }
    }

    return position
  }, [elementRef, actionTriggerRef, positionAbove, positionOnEdge])

  useLayoutEffect(() => {
    if (enabled) {
      const { top, left, position } = getElementPosition()

      setElementPosition({
        top,
        left,
        position,
      })
    }
  }, [getElementPosition, enabled])

  return {
    left: elementPosition?.left,
    top: elementPosition?.top,
    position: elementPosition.position,
  }
}
