import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import classes from './ModalImageView.module.scss'
import { useOnClickOutside } from 'shared/hooks/useOnClickOutside'

import { ReactComponent as IconClose } from 'assets/svg/closeIcon.svg'
import { ReactComponent as LeftArrowIcon } from 'assets/svg/leftArrow.svg'
import { ReactComponent as RightArrowIcon } from 'assets/svg/rightArrow.svg'
import { ProtectedImage } from 'components'

type ModalImageViewProps = {
  isVisible: boolean
  onClose: () => void
  imageIds: string[]
}

export const ModalImageView = (props: ModalImageViewProps) => {
  const { isVisible, onClose, imageIds } = props
  const [slideIndex, setSlideIndex] = useState(0)

  const modalRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(modalRef, onClose)

  const nextSlide = () => {
    setSlideIndex((previousIndex) => {
      if (previousIndex !== imageIds.length - 1) {
        return previousIndex + 1
      }

      return 0
    })
  }

  const prevSlide = () => {
    setSlideIndex((previousIndex) => {
      if (previousIndex !== 0) {
        return previousIndex - 1
      }

      return imageIds.length - 1
    })
  }

  useEffect(() => {
    setSlideIndex(0)
  }, [isVisible])

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {isVisible && <div className={classes.backDrop} />}
          <CSSTransition
            in={isVisible}
            timeout={{
              enter: 300,
              exit: 100,
            }}
            classNames="fade"
            unmountOnExit
          >
            <div className={classes.modalWrapper}>
              <div data-testid="modal-image-card" className={classes.modalCard}>
                <div
                  onClick={onClose}
                  aria-label="close"
                  className={classes.modalCloseButton}
                >
                  <IconClose className={classes.modalCloseIcon} />
                </div>
                <div
                  ref={modalRef}
                  className={classes.modalBody}
                  id="modal-body"
                >
                  <div className={classes.containerSlider}>
                    {imageIds.map((imageId, index) => {
                      return (
                        <div
                          key={imageId}
                          className={
                            slideIndex === index
                              ? [classes.slide, classes.active].join(' ')
                              : classes.slide
                          }
                        >
                          <ProtectedImage imageId={imageId} alt="carrosel" />
                        </div>
                      )
                    })}

                    {imageIds.length > 1 ? (
                      <>
                        <button
                          onClick={nextSlide}
                          data-testid="button-dots"
                          aria-label="next"
                          className={classes.btnSlideNext}
                        >
                          <RightArrowIcon />
                        </button>

                        <button
                          onClick={prevSlide}
                          aria-label="previous"
                          className={classes.btnSlidePrev}
                        >
                          <LeftArrowIcon />
                        </button>

                        <div className={classes.containerDots}>
                          {imageIds.map((_item, index) => (
                            <div
                              key={_item}
                              onClick={() => setSlideIndex(index)}
                              role="switch"
                              aria-checked
                              data-testid={
                                slideIndex === index ? 'dot-active' : 'dot'
                              }
                              className={
                                slideIndex === index
                                  ? [classes.dot, classes.active].join(' ')
                                  : classes.dot
                              }
                            />
                          ))}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        </>,
        document.body,
      )}
    </>
  )
}
