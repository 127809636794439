import {
  InterventionAttributes,
  InterventionAttributeStatus,
  OccurrenceTag,
  OccurrenceColumnTitle,
  OccurrenceListByCoverage,
} from 'services/occurrence/types'
import { InterventionTag } from './data/interventionTag'

export type station = {
  id: string | null
  name: string | null
  operator: string | null
}

export interface InterventionNoteSubmitData {
  note: string
  status?: InterventionAttributeStatus
  interventionNoteTags: OccurrenceTag[]
  interventionTag: InterventionTag | string
  hour?: string
  date?: number
  postponeUntil?: string
}

export const OccurrenceActionLabel = {
  ATENDER: 'Atender',
  VISUALIZAR_OCORRENCIA: 'Visualizar ocorrência',
  ENVIAR_PARA_DESLOCAMENTO: 'Enviar para deslocamento',
  COLOCAR_EM_OBSERVACAO: 'Colocar em observação',
  FINALIZAR: 'Finalizar',
  VISUALIZAR_NO_MAPA: 'Visualizar no mapa',
  ALTERAR_TATICO: 'Alterar tático',
  ATRIBUIR_TATICO: 'Atribuir tático',
  DEFINIR_COMO: 'Definir como...',
  SOLICITACAO_VISUALIZADA: 'Solicitação visualizada',
  TRANSITO_INICIADO: 'Trânsito iniciado',
  CHEGADA_NO_LOCAL: 'Chegada no local',
  TORNAR_DISPONIVEL: 'Tornar disponível',
} as const

export type OccurrenceActionValue =
  (typeof OccurrenceActionLabel)[keyof typeof OccurrenceActionLabel]

export type OccurrenceCardActions = {
  label: OccurrenceActionValue
  handler?: (id: string) => void
  isVisible?: (id: string) => boolean
  isDisabled?: (id: string) => boolean
  subActions?: OccurrenceCardActions[]
}

export type OccurrenceManagementColumnProps = {
  title: OccurrenceColumnTitle
  occurrences: OccurrenceListByCoverage[]
  actions: OccurrenceCardActions[]
}

export type ActionBorderStyle = 'borderBoth' | 'borderTop' | 'borderBottom' | ''

export type ActionBorder = {
  [key in OccurrenceActionValue]?: ActionBorderStyle
}

export type InterventionAction = InterventionAttributes & {
  tags?: OccurrenceTag[]
  interventionTag: InterventionTag
  note?: string
}
