import { useMutation, useQueryClient } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import buildUrl from '../utils/buildUrl'

type AccountNote = {
  accountId: string
  note: string
}

const patchAccountNote = ({ accountId, note }: AccountNote) => {
  return HttpClient.patch(
    buildUrl({
      route: endpoints.patchAccountNote,
      params: { accountId },
    }),
    {
      note,
    },
  )
}

export const usePatchAccountNote = (occurrenceId = '') => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: AccountNote) => await patchAccountNote(payload),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['occurrence-attendance', occurrenceId],
      })
    },
  })
}
