import Joi from '@hapi/joi'
import { CustomerFragment } from 'services/account/types'
import { SortedCustomer } from 'services/customer/types'

export const customerFragmentSchema = Joi.object<CustomerFragment>({
  id: Joi.string().uuid(),
  name: Joi.string(),
  document: Joi.string(),
})

export const customerSchema = Joi.object<SortedCustomer>({
  id: Joi.string().uuid(),
  name: Joi.string(),
  aggregatedName: Joi.string(),
  accounts: Joi.array().optional(),
})
