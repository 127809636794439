import { useRef } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  ButtonV2,
  ComboBoxV3,
  FormItem,
  FormLabel,
  FormMessage,
  Icon,
  ModalV2,
  TextareaV2,
} from 'components'
import {
  useCreateIntervention,
  useDefaultMode,
  useMaintenanceMode,
} from 'services'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'
import { fetchServiceOrderLabel } from 'services/serviceOrderLabel'
import { useToast, useUserInfo } from 'shared/hooks'

import styles from './styles.module.scss'

interface FormProps {
  reason: { id: string; description: string }
  note: string
}

const schema = Joi.object({
  reason: Joi.object({
    id: Joi.string(),
    description: Joi.string(),
  }).required(),
  note: Joi.string().required(),
})

const Pause = () => {
  const { addToast } = useToast()
  const { userInfo } = useUserInfo()
  const closeRef = useRef<HTMLButtonElement>(null)
  const { serviceOrder } = useServiceOrderDetails()
  const {
    register,
    setValue,
    watch,
    reset,
    formState: { isValid },
  } = useForm<FormProps>({
    resolver: joiResolver(schema),
  })

  const { reason } = watch()

  const { mutate: mutateDefaultCommand, isPending: defaultIsPending } =
    useDefaultMode(serviceOrder?.centralId)

  const { mutate: mutateMaintenanceCommand, isPending: maintenanceIsPending } =
    useMaintenanceMode(serviceOrder?.centralId)

  const { mutate: mutateIntervention, isPending } = useCreateIntervention(
    serviceOrder?.id,
  )

  const handleGetReasonOptions = async (name?: string, offset = 0) => {
    const response = await fetchServiceOrderLabel({
      description: name,
      offset,
      type: 'PAUSE_REASON',
    })

    return response
  }

  const handleMutateIntervention = () =>
    mutateIntervention(
      {
        typeName: 'STATUS_CHANGE',
        userId: userInfo.id,
        note: {
          note: watch('note'),
          hidden: false,
          userId: userInfo.id,
        },
        attributes: {
          status: 'PAUSED',
          labelIds: [reason.id],
          technicianId: serviceOrder?.technician?.id || userInfo.id,
        },
      },
      {
        onSuccess: () => {
          addToast({
            message: 'Ordem de serviço pausada com sucesso.',
            type: 'success',
          })

          if (closeRef.current) {
            closeRef.current.click()
          }

          reset()
        },
        onError: () => {
          addToast({
            message: 'Erro ao pausar ordem de serviço. Tente novamente.',
            error: true,
            type: 'alert',
          })

          if (serviceOrder?.centralId) {
            mutateMaintenanceCommand({ userId: userInfo.id })
          }
        },
      },
    )

  const onSubmit = () => {
    if (serviceOrder?.centralId) {
      mutateDefaultCommand(
        { userId: userInfo.id },
        {
          onSuccess: handleMutateIntervention,
          onError: () =>
            addToast({
              message:
                'Erro ao pausar ordem de serviço e retirar a central do modo manutenção. Tente novamente.',
              error: true,
              type: 'alert',
            }),
        },
      )
    } else {
      handleMutateIntervention()
    }
  }

  return (
    <ModalV2.Content className={styles.container}>
      <ModalV2.Title>
        <Icon name="warning" color="yellow" width={20} />
        Pausar execução de ordem de serviço?
      </ModalV2.Title>
      <form>
        <FormItem>
          <FormLabel>Motivo</FormLabel>
          <ComboBoxV3.Root
            findOptions={handleGetReasonOptions}
            valueKey="description"
          >
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field value={reason?.description} />
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map(({ id, description }) => (
                    <ComboBoxV3.Option
                      key={id}
                      onClick={() => {
                        setValue(
                          'reason',
                          { id, description },
                          { shouldValidate: true },
                        )
                      }}
                    >
                      {description}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
        </FormItem>

        <FormItem>
          <FormLabel>Observação</FormLabel>
          <TextareaV2
            {...register('note')}
            maxLength={5000}
            rows={5}
            placeholder="Descreva o serviço encontrado"
          />
          <FormMessage>
            {5000 - (watch('note')?.length || 0)} caracteres restantes
          </FormMessage>
        </FormItem>

        <ModalV2.Footer>
          <ModalV2.Close asChild>
            <ButtonV2 appearance="tertiary" ref={closeRef}>
              Cancelar
            </ButtonV2>
          </ModalV2.Close>
          <ButtonV2
            onClick={onSubmit}
            disabled={
              !isValid || isPending || defaultIsPending || maintenanceIsPending
            }
          >
            Pausar
          </ButtonV2>
        </ModalV2.Footer>
      </form>
    </ModalV2.Content>
  )
}

export default Pause
