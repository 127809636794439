import { FC, useCallback, useState } from 'react'
import { Button, Modal, Toast } from '../../components'
import Cropper from 'react-easy-crop'
import './AvatarChangerModal.scss'
import { PixelCrop, getCroppedImg } from './utilities/crop'

import Slider from '../Slider/Slider'
import { useToggle } from '../../shared/hooks'
import { ToastType } from 'components/Toast/types'

export interface AvatarChangerModalProps {
  isVisible: boolean
  onSave(image: Blob | MediaSource): void
  onClose(): void
  avatarUrl: string
}

export const AvatarChangerModal: FC<AvatarChangerModalProps> = (props) => {
  const { isVisible, onSave, onClose, avatarUrl } = props
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  })
  const toast = useToggle()
  const [toastProps, setToastProps] = useState<{
    message: string
    type?: ToastType
  }>({
    message: '',
  })

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleCropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(avatarUrl, croppedAreaPixels)
      onSave(croppedImage)
    } catch (e) {
      setToastProps({
        message: 'Erro ao alterar a foto do perfil. Tente novamente.',
        type: 'alert',
      })
      toast.show()
    }
  }, [avatarUrl, croppedAreaPixels, onSave, toast])

  return (
    <>
      <Toast
        onClose={() => toast.hide()}
        isVisible={toast.isVisible}
        type={toastProps.type}
      >
        {toastProps.message}
      </Toast>

      <Modal
        size="sm"
        title="Editar foto"
        className="avatar-changer-modal"
        isVisible={isVisible}
        onClose={() => {
          onClose()
          setZoom(1)
        }}
      >
        <div className="modal-content">
          <div className="image-cropper-container">
            <Cropper
              image={avatarUrl}
              crop={crop}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              cropSize={{ width: 232, height: 232 }}
              zoom={zoom}
              zoomWithScroll={false}
              onZoomChange={setZoom}
              cropShape="round"
              classes={{
                containerClassName: 'crop-area-container',
                cropAreaClassName: 'crop-area',
              }}
            />
          </div>

          <Slider
            value={zoom}
            setValue={setZoom}
            inputMinValue={1}
            inputMaxValue={25}
          />
        </div>
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Salvar"
            onClick={handleCropImage}
            type="primary"
            id="confirm-avatar-changing"
          />
          <Button
            width="172px"
            buttonTitle="Cancelar"
            onClick={() => {
              onClose()
              setZoom(1)
            }}
            type="secondary"
            id="cancel-avatar-changing"
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AvatarChangerModal
