import Joi from '@hapi/joi'

import { CreateCentralPayload } from 'services/central/types'

export const ALLOWED_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif']

export const createCentralSchema = Joi.object<CreateCentralPayload>({
  account: Joi.object({
    id: Joi.string().uuid().required(),
    name: Joi.string().optional(),
    aggregatedAccountName: Joi.string().optional().allow('', null),
  }).required(),
  central: Joi.object({
    code: Joi.string().allow(null, '').optional(),
    model: {
      hasCommandSupport: Joi.boolean().optional(),
      name: Joi.string().allow(null, '').optional(),
      id: Joi.string().uuid().optional(),
    },
    manufacturer: {
      id: Joi.string().uuid().optional(),
      name: Joi.string().allow(null, '').optional(),
    },
    externalId: Joi.string().allow(null, '').optional(),
    topicGroup: Joi.string().allow(null, '').optional(),
  }).required(),
  image: Joi.object().optional(), // TODO: ajustar schema de imagem
  partitions: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().uuid().optional(),
        name: Joi.string().required(),
        code: Joi.string().required(),
        keyNumber: Joi.string().allow('', null).optional(),
        status: Joi.string().optional(),
        externalId: Joi.string().allow('', null).optional(),
      }),
    )
    .required(),
  contacts: Joi.array().items(
    Joi.object({
      code: Joi.string().required(),
      id: Joi.string().required(),
      name: Joi.string().required(),
    }),
  ),
})
