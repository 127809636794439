import Joi from '@hapi/joi'
import { OccurrenceTypeQueryResponse } from 'services/occurrence/types'
import { DeviceTypeCodeComboboxItem } from 'utilities/occurrence/data'

export type EventFormType = {
  id: string
  code: string
  name: string
}
export interface EventHistoryFiltersSchema {
  event?: EventFormType
  type?: OccurrenceTypeQueryResponse
  equipment?: DeviceTypeCodeComboboxItem
  createdFrom: number
  createdTo: number
}

export const eventFiltersSchema = Joi.object<EventHistoryFiltersSchema>({
  event: Joi.object({
    id: Joi.string().uuid().required(),
    code: Joi.string().required(),
    name: Joi.string().required(),
  }).optional(),
  type: Joi.object({
    id: Joi.string().uuid().required(),
    name: Joi.string().required(),
    severity: Joi.number().required(),
    priority: Joi.number().required(),
    categoryName: Joi.string().required(),
    defaultStateName: Joi.string().optional(),
    temporaryStateName: Joi.string().allow('', null).optional(),
    temporaryStatePeriod: Joi.number().allow('', null).optional(),
  }).optional(),
  equipment: Joi.object({
    id: Joi.string().uuid().required(),
    code: Joi.string().required(),
    name: Joi.string().required(),
  }).optional(),
  createdFrom: Joi.number().optional(),
  createdTo: Joi.number().optional(),
})
