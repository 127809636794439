import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { maskedDateTime } from 'utilities/date'
import { useQueryEvent, QueryEvent } from 'services/event'
import {
  EmptyStateV2,
  ErrorState,
  Icon,
  IconButton,
  PaginationV2,
  TableV2,
  TooltipV2,
} from 'components'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import styles from './styles.module.scss'
import Loading from './Loading'
import { handleRenderState } from 'utilities/handleRenderState'
import { getEventDevice } from 'utilities/event'
import { getNormalizedDeviceTypeCode } from 'utilities/devices'

const columnHelper = createColumnHelper<QueryEvent>()

const EventTable = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { serviceOrder } = useServiceOrderDetails()

  const page = searchParams.get('page')

  const { data, isLoading, isError } = useQueryEvent({
    accountId: serviceOrder?.accountId,
    fields: [
      'id',
      'device',
      'datetime',
      'partition',
      'auxiliary',
      'eventType',
      'originName',
      'occurrenceId',
      'ignoredReason',
    ],
    size: 15,
    page: Number(page) || 1,
  })

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'event',
        header: 'Evento',
        size: 200,
        cell: (info) => {
          const { eventType, ignoredReason } = info.getValue()

          return (
            <div className={styles.cell}>
              {ignoredReason === 'MAINTENANCE_MODE' && (
                <>
                  <TooltipV2.Root>
                    <TooltipV2.Trigger>
                      <Icon
                        name="system-maintenance"
                        color="medium-gray"
                        className={styles.icon}
                        width={12}
                        height={12}
                      />
                    </TooltipV2.Trigger>
                    <TooltipV2.Content position="bottom" size="lg">
                      <span>Central em manutenção</span>
                    </TooltipV2.Content>
                  </TooltipV2.Root>
                </>
              )}
              {eventType?.code} - {eventType?.description}
            </div>
          )
        },
      }),
      columnHelper.accessor((row) => row, {
        header: 'Origem',
        cell: ({ row }) => {
          const { auxiliary, originName, ...event } = row.original

          return (
            <div className={styles.cell}>
              {event.ignoredReason === 'DISABLE_DEVICE' && (
                <>
                  <TooltipV2.Root>
                    <TooltipV2.Trigger>
                      <Icon
                        name="blocked"
                        color="medium-gray"
                        className={styles.icon}
                        width={12}
                        height={12}
                      />
                    </TooltipV2.Trigger>
                    <TooltipV2.Content position="bottom" size="lg">
                      <span> Dispositivo anulado</span>
                    </TooltipV2.Content>
                  </TooltipV2.Root>
                </>
              )}

              <div>
                {`
          ${auxiliary?.code || ''} 
          ${originName && auxiliary?.code ? ' - ' : ' '} 
          ${originName || '--'}
        `}
              </div>
            </div>
          )
        },
      }),
      columnHelper.accessor('device', {
        header: 'Equipamento',
        cell: (info) => {
          const device = info.getValue()
          const command = info.row.original.command

          return getEventDevice(
            getNormalizedDeviceTypeCode(device?.deviceTypeCode),
            command?.id,
          )
        },
      }),
      columnHelper.accessor('partition', {
        header: 'Partição',
        cell: (info) => info.getValue()?.code || '--',
      }),
      columnHelper.accessor('datetime', {
        header: 'Data e hora',
        cell: (info) => <>{maskedDateTime(info.getValue())}</>,
      }),
      columnHelper.accessor('occurrenceId', {
        id: 'action',
        header: '',
        size: 38,
        cell: (info) => (
          <IconButton
            onClick={() => {
              if (info.getValue()) {
                navigate(`/occurrence/attendance/${info.getValue()}`, {
                  state: {
                    readonly: true,
                  },
                })
              }
            }}
          >
            <Icon name="view" />
          </IconButton>
        ),
      }),
    ],
    [columnHelper],
  )

  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      {
        {
          view: (
            <>
              <TableV2.Root className={styles.container}>
                <TableV2.Header>
                  {table?.getHeaderGroups().map((headerGroup) => (
                    <TableV2.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableV2.Column
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: header.column.getSize() }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </TableV2.Column>
                      ))}
                    </TableV2.Row>
                  ))}
                </TableV2.Header>
                <TableV2.Body>
                  {table?.getRowModel().rows.map((row) => (
                    <TableV2.Row key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TableV2.Cell
                          key={cell.id}
                          className={styles[cell.column.id]}
                          style={{ width: cell.column.getSize() }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableV2.Cell>
                      ))}
                    </TableV2.Row>
                  ))}
                </TableV2.Body>
              </TableV2.Root>
              <PaginationV2 totalElements={data?.totalElements} />
            </>
          ),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyStateV2 />,
        }[
          handleRenderState({
            data,
            isLoading,
            isError,
            isEmpty: !data?.totalElements,
          })
        ]
      }
    </>
  )
}

export default EventTable
