import { useEffect, useRef, useState } from 'react'
import * as Joi from '@hapi/joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { FormProvider, useForm } from 'react-hook-form'

import { formatFileSize } from 'utilities/masks'
import joinClassNames from 'utilities/joinClassNames'
import { ButtonV2, Icon, IconButton, ModalV2, Tag } from 'components'

import { handleUrlsToFile } from './utils'
import { TagComboBox, AttachModal, DiscardModal, TagModal } from './components'

import styles from './styles.module.scss'

const noteType = {
  PUBLIC_NOTE: 'PUBLIC_NOTE',
  PRIVATE_NOTE: 'PRIVATE_NOTE',
}

type NoteTypes = keyof typeof noteType

const noteLabel: Record<NoteTypes, string> = {
  PUBLIC_NOTE: 'Esta nota poderá ser visualizada pelo cliente',
  PRIVATE_NOTE: 'Esta nota ficará oculta para o cliente',
}

export interface Form {
  note: string
  hidden: boolean
  images?: File[]
  tags?: {
    id: string
    name: string
  }[]
}

interface FieldProps {
  onCancel?: () => void
  onSubmit: (values: Form) => void
  visibility?: 'public' | 'private'
  defaultValues?: Omit<Form, 'images'> & { images?: string[] }
}

const initialValues = {
  note: '',
  tags: [],
  images: [],
  hidden: true,
}

export const noteSchema = Joi.object({
  note: Joi.string().required(),
  tags: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().required(),
        name: Joi.string().required(),
      }),
    )
    .optional(),
  images: Joi.array().optional(),
  hidden: Joi.boolean().required(),
})

const Field = ({
  onSubmit,
  onCancel,
  visibility,
  defaultValues = initialValues,
}: FieldProps) => {
  const fieldRef = useRef<HTMLFormElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [hasChildFocused, setHasChildFocused] = useState(false)

  const form = useForm<Form>({
    defaultValues: async () => ({
      tags: defaultValues.tags || [],
      hidden: visibility === 'private' || !!defaultValues.hidden,
      note: defaultValues.note || '',
      images: defaultValues.images
        ? await handleUrlsToFile(defaultValues.images)
        : [],
    }),
    resolver: joiResolver(noteSchema),
  })

  const {
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = form

  const { note, hidden, tags, images } = watch()

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (fieldRef.current && fieldRef.current.contains(event.target as Node)) {
        textareaRef.current?.focus()

        setHasChildFocused(true)
      } else {
        setHasChildFocused(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [setHasChildFocused])

  const handleOnSubmit = (data: Form) => {
    onSubmit(data)
    reset({
      hidden: false,
      note: '',
      tags: [],
      images: [],
    })
  }

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit'

      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px'
    }
  }, [note])

  return (
    <FormProvider {...form}>
      <form
        ref={fieldRef}
        className={joinClassNames(
          styles.container,
          hasChildFocused && styles.focused,
        )}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <div>
          <div className={styles.type}>
            <TagComboBox
              value={hidden}
              {...register('hidden')}
              disabled={!!visibility}
              onSelect={(option) =>
                setValue('hidden', option.value, { shouldValidate: true })
              }
            />
            <span>{noteLabel[hidden ? 'PRIVATE_NOTE' : 'PUBLIC_NOTE']}</span>
          </div>
          <textarea
            rows={1}
            value={note}
            ref={textareaRef}
            maxLength={5000}
            onChange={(event) => {
              setValue('note', event?.target.value, { shouldValidate: true })
            }}
          />
        </div>
        {!!tags?.length && (
          <div className={styles.tags}>
            {tags.map((tag) => (
              <Tag key={tag.id} size="md">
                {tag.name}
                <Icon
                  name="close-bold"
                  width={8}
                  onClick={() =>
                    setValue(
                      'tags',
                      tags.filter((currentTag) => currentTag.id !== tag.id),
                      { shouldValidate: true },
                    )
                  }
                />
              </Tag>
            ))}
          </div>
        )}
        {!!images?.length && (
          <div className={styles.images}>
            {images.map((image, index) => (
              <div key={index}>
                <strong>{image.name}</strong>
                {`(${formatFileSize(image.size)})`}
                <Icon
                  name="close-bold"
                  color="element"
                  width={8}
                  onClick={() => {
                    setValue(
                      'images',
                      images.filter((_, i) => i !== index),
                      { shouldValidate: true },
                    )
                  }}
                />
              </div>
            ))}
          </div>
        )}
        <div className={styles.actions}>
          <div>
            <IconButton>
              <ModalV2.Root>
                <ModalV2.Trigger>
                  <Icon
                    name="attach"
                    color="element-light"
                    width={20}
                    aria-label="Adicionar arquivos"
                  />
                </ModalV2.Trigger>
                <ModalV2.Content size="md">
                  <AttachModal />
                </ModalV2.Content>
              </ModalV2.Root>
            </IconButton>
            <ModalV2.Root>
              <ModalV2.Trigger>
                <IconButton>
                  <Icon
                    name="tag"
                    color="element-light"
                    width={20}
                    aria-label="Adicionar tags"
                  />
                </IconButton>
              </ModalV2.Trigger>
              <ModalV2.Content>
                <TagModal />
              </ModalV2.Content>
            </ModalV2.Root>
            <ModalV2.Root>
              <ModalV2.Trigger>
                <IconButton>
                  <Icon
                    name="delete"
                    color="element-light"
                    width={20}
                    aria-label="Excluir dados preenchidos no formulário"
                  />
                </IconButton>
              </ModalV2.Trigger>
              <ModalV2.Content>
                <DiscardModal />
              </ModalV2.Content>
            </ModalV2.Root>
          </div>
          <div>
            {!!onCancel && (
              <ButtonV2 appearance="secondary" onClick={() => onCancel()}>
                Cancelar
              </ButtonV2>
            )}
            <ButtonV2 type="submit" disabled={!isValid}>
              Enviar
            </ButtonV2>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export default Field
