import { ComboboxItem } from 'components'
import { v4 as uuid } from 'uuid'

export enum DeviceTypeCodeOptions {
  RemoteControl = '0',
  PassiveInfraPhotoRedSensor = '2',
  ReedSwitch = '4',
  WirelessSiren = '5',
}

export interface DeviceTypeCodeComboboxItem {
  id: string
  code: DeviceTypeCodeOptions
  name: string
}

type DeviceTypeCodeInfoType = {
  [key in DeviceTypeCodeOptions]: {
    name: string
  }
}

export const DeviceTypeCodeInfo: DeviceTypeCodeInfoType = {
  [DeviceTypeCodeOptions.RemoteControl]: {
    name: 'Controle Remoto',
  },
  [DeviceTypeCodeOptions.PassiveInfraPhotoRedSensor]: {
    name: 'Fotossensor',
  },
  [DeviceTypeCodeOptions.ReedSwitch]: {
    name: 'Sensor de abertura',
  },
  [DeviceTypeCodeOptions.WirelessSiren]: {
    name: 'Sirene',
  },
}

export const equipmentOptions: Array<ComboboxItem<DeviceTypeCodeComboboxItem>> =
  Object.values(DeviceTypeCodeOptions).map((deviceTypeCode) => ({
    label: 'name',
    value: {
      id: uuid(),
      code: deviceTypeCode,
      name: DeviceTypeCodeInfo[deviceTypeCode].name,
    },
  }))
