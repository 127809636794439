import { ReactComponent as EventIcon } from 'assets/svg/messageEvent.svg'
import { ReactComponent as OccurrenceIcon } from 'assets/svg/occurrence.svg'
import { ReactComponent as ServiceOrderIcon } from 'assets/svg/report.svg'

export type HistoryTabLabels = 'Ordens de serviço' | 'Eventos' | 'Ocorrências'

export type HistoryOption = {
  title: HistoryTabLabels
  icon: JSX.Element
}

export const historyOption: Record<HistoryTabLabels, HistoryOption> = {
  'Ordens de serviço': {
    title: 'Ordens de serviço',
    icon: <ServiceOrderIcon />,
  },
  Eventos: { title: 'Eventos', icon: <EventIcon /> },
  Ocorrências: { title: 'Ocorrências', icon: <OccurrenceIcon /> },
}

export const isHistoryTabLabel = (
  label: string | null,
): label is HistoryTabLabels => {
  if (label) {
    return ['Ordens de serviço', 'Eventos', 'Ocorrências'].includes(label)
  }
  return false
}
