import { AxiosInstance } from 'axios'
import { useMutation } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { ImageRequest } from './types'
import { endpoints } from './endpoints'

interface ImageDriver {
  getImage(imageId: string): Promise<string>
  persist(image: File, payload: ImageRequest): Promise<string>
  delete(imageId: string): Promise<void>
}

export class ImageDriverImpl implements ImageDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async delete(imageId: string): Promise<void> {
    return await this.httpClient.delete(`/image/${imageId}`)
  }

  public async persist(image: File, payload: ImageRequest): Promise<string> {
    const formData = new FormData()

    formData.append('imageFile', image, image.name)
    formData.append('payload', JSON.stringify(payload))

    const response = await this.httpClient.post(
      endpoints.createImage,
      formData,
      {
        headers: {
          accept: 'application/json',
          'Content-Type': `multipart/form-data`,
        },
      },
    )
    return String(response.data)
  }

  public async getImage(imageId: string): Promise<string> {
    const response = await this.httpClient.get(`/image/${imageId}/file`, {
      responseType: 'blob',
    })
    return URL.createObjectURL(response.data)
  }
}

export default new ImageDriverImpl()

type CreateImagePayload = {
  image: File
  payload: ImageRequest
}

const createImage = async ({ image, payload }: CreateImagePayload) => {
  const formData = new FormData()

  formData.append('imageFile', image, image.name)
  formData.append('payload', JSON.stringify(payload))

  const response = await HttpClient.post(endpoints.createImage, formData, {
    headers: {
      accept: 'application/json',
      'Content-Type': `multipart/form-data`,
    },
  })

  return String(response.data)
}

export const useCreateImage = () =>
  useMutation({
    mutationFn: (payload: CreateImagePayload) => createImage(payload),
  })

export * from './types'
export * from './useGetImage'
export * from './useGetImageFile'
export * from './useDeleteImage'
