import { Info, Skeleton } from 'components'

import styles from './styles.module.scss'

const Loading = () => (
  <>
    <section>
      <h3>Cliente</h3>
      {Array.from({ length: 3 }).map((_, index) => (
        <Info.Root key={index}>
          <Info.Title>
            <Skeleton height={18} width={116} />
          </Info.Title>
          <Info.Content>
            <Skeleton height={34} />
          </Info.Content>
        </Info.Root>
      ))}
    </section>
    <section>
      <h3>Contatos</h3>
      <ul className={styles.contacts}>
        {Array.from({ length: 3 }).map((_, index) => (
          <li key={index}>
            <div>
              <Skeleton height={18} width={116} />
              <Skeleton height={18} width={76} />
            </div>
            <Skeleton height={36} />
          </li>
        ))}
      </ul>
    </section>
  </>
)

export default Loading
