import { ReactComponent as BlockedIcon } from 'assets/svg/blocked.svg'
import { ReactComponent as sound } from 'assets/svg/sound.svg'

import { Button, ProtectedButton } from 'components'
import { DeviceEnabled } from 'domains/customer/screens/Equipments/components/DeviceEnabled/DeviceEnabled'

import { EquipmentPhoto } from 'domains/customer/screens/Equipments/components/EquipmentPhoto/EquipmentPhoto'

import styles from './SirenCard.module.scss'
import { DeviceUpdate } from 'domains/customer/screens/Equipments/components/DeviceUpdate/DeviceUpdate'
import { useToggle } from 'shared/hooks'
import { DeviceCardFooter } from 'domains/customer/screens/Equipments/components/DeviceCardFooter/DeviceCardFooter'
import { CentralFragment } from 'domains/customer/screens/Equipments/types'
import { SirenVolumeAdjustment } from 'domains/customer/screens/Equipments/components/SirenVolumeAdjustment/SirenVolumeAdjustment'
import { DeviceCardHeader } from 'domains/customer/screens/Equipments/components/DeviceCardHeader/DeviceCardHeader'
import { UbideskPermissions } from 'routes/types'
import { CENTRAL_MODE } from 'services/central/types'
import { PartitionDeviceAggregatedResponse } from 'services/device/types'

interface SirenCardProps {
  siren: PartitionDeviceAggregatedResponse
  central: CentralFragment
}

export const SirenCard = (props: SirenCardProps) => {
  const { siren, central } = props

  const editModal = useToggle()
  const sirenVolumeModal = useToggle()
  const deviceAnnulModal = useToggle()

  return (
    <div className={styles.cardContainer} aria-label="equipment-card-details">
      <DeviceUpdate
        device={{
          id: siren.id,
          name: siren.name,
          partitions: siren.partitions,
        }}
        central={central}
        onClose={editModal.hide}
        isVisible={editModal.isVisible}
      />
      <SirenVolumeAdjustment
        centralId={central.id}
        isVisible={sirenVolumeModal.isVisible}
        onClose={sirenVolumeModal.hide}
        siren={{ id: siren.id, gain: siren.sirenVolume }}
      />
      <DeviceEnabled
        isVisible={deviceAnnulModal.isVisible}
        onClose={deviceAnnulModal.hide}
        device={{
          id: siren.id,
          hiddenZone: siren.hiddenZone,
          hiddenZoneTo: siren.hiddenZoneTo,
          hasCommandSupport: siren.hasCommandSupport,
        }}
        centralId={central.id}
      />
      <div className={styles.header}>
        <DeviceCardHeader
          deviceId={siren.id}
          title={siren.name}
          type={siren.typeCode}
          hiddenZone={siren.hiddenZone}
          hiddenZoneTo={siren.hiddenZoneTo}
          tamper={siren.isTamperOpened}
          onClick={editModal.show}
          hasCommandSupport={siren.hasCommandSupport}
        />
        <div className={styles.cardButtonOptions}>
          <ProtectedButton
            permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
            id="block-equipment-button"
            buttonTitle={
              siren.hiddenZone || siren.hiddenZoneTo
                ? 'Reativar equipamento'
                : 'Anular equipamento'
            }
            type="secondary"
            icon={BlockedIcon}
            width="184px"
            onClick={deviceAnnulModal.show}
          />

          <Button
            id="config-volume-button"
            buttonTitle="Ajustar volume"
            type="secondary"
            icon={sound}
            className={styles.iconButton}
            width="184px"
            onClick={sirenVolumeModal.show}
            disabled={central.mode !== CENTRAL_MODE.MAINTENANCE}
          />
        </div>
      </div>
      <EquipmentPhoto imageId={siren.installationImageId} alt="sirene" />
      <div className={styles.footer}>
        <DeviceCardFooter device={siren} />
      </div>
    </div>
  )
}
