import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Combobox } from 'components/ComboboxV2/Combobox'
import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import { useGetEventTypes } from 'shared/hooks/services/events'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { ComboboxItem, Input } from 'components'
import { EventTypeResponse } from 'services/event/types'

import styles from './EventTypeForm.module.scss'
import {
  convertEventDeviceOption,
  handleEventDeviceOption,
  translatedEventDeviceOption,
} from 'domains/attendancePolicy/components/RuleForm/utilities'
import { ConditionType } from 'services/attendancePolicy/types'
import {
  evaluationEventLabel,
  evaluationOption,
} from 'domains/attendancePolicy/utilities/constants'
import { Evaluation } from 'services/serviceOrder'

export const EventTypeForm = ({ onChange, data }: ConditionOptionForm) => {
  const comboboxLabel = 'Tipo de evento'
  const { facts } = data
  const { register, watch, setValue } = useForm<{
    evaluation: Evaluation
    eventType: EventTypeResponse
    numberOfEventsFromEventType: number
    eventsFromEventTypeHasSameDevices: boolean
  }>({
    defaultValues: {
      evaluation: data?.evaluation || 'HAS',
      eventType: facts?.eventType,
      numberOfEventsFromEventType: facts?.numberOfEventsFromEventType,
      eventsFromEventTypeHasSameDevices:
        facts?.eventsFromEventTypeHasSameDevices,
    },
  })

  const selectedEventType = watch('eventType')
  const numberOfEventsFromEventType = watch('numberOfEventsFromEventType')
  const eventsFromEventTypeHasSameDevices = watch(
    'eventsFromEventTypeHasSameDevices',
  )

  const [eventTypeFilter, setEventTypeFilter] = useState('')
  const {
    isError: isErrorEventType,
    isFetching: isFetchingEventType,
    eventTypes,
    fetchNextEventTypesPage,
  } = useGetEventTypes(eventTypeFilter)

  useEffect(() => {
    register('eventType')
    register('numberOfEventsFromEventType')
    register('eventsFromEventTypeHasSameDevices')
  }, [register])

  return (
    <div className={styles.container}>
      <Combobox
        label={{
          text: 'Validar se',
        }}
        {...register('evaluation')}
        value={evaluationEventLabel[watch('evaluation')]}
        items={parseDataToComboboxV2(
          evaluationOption.map((option) => ({
            ...option,
            label: evaluationEventLabel[option.value],
          })),
          'label',
        )}
        onChange={(selected) => {
          setValue('evaluation', selected.value.value)

          onChange?.({
            type: watch('type'),
            facts: {
              eventType: selectedEventType,
              eventsFromEventTypeHasSameDevices,
              numberOfEventsFromEventType,
            },
            evaluation: selected.value.value,
          })
        }}
        placeholder="Selecione uma opção"
      />
      <Combobox
        label={{
          text: comboboxLabel,
        }}
        onSearch={(search) => setEventTypeFilter(search)}
        items={parseDataToComboboxV2(
          eventTypes?.map((eventType) => eventType) || [],
          'aggregatedCodeDescription',
        )}
        value={
          selectedEventType
            ? { label: 'description', value: selectedEventType }
            : undefined
        }
        onChange={(selected) => {
          const selectedType = selected as ComboboxItem<EventTypeResponse>

          setValue('eventType', selectedType.value, { shouldValidate: true })

          onChange({
            type: ConditionType.OCCURRENCE_HAS_EVENTS,
            evaluation: watch('evaluation'),
            facts: {
              eventType: selectedType.value,
              numberOfEventsFromEventType,
              eventsFromEventTypeHasSameDevices,
            },
          })
        }}
        isLoading={isFetchingEventType}
        isError={isErrorEventType}
        onEndReached={fetchNextEventTypesPage}
        placeholder="Selecione um tipo"
      />

      <Input
        type="number"
        id={`number-of-events-${data.id}`}
        value={watch('numberOfEventsFromEventType')}
        min={1}
        max={100000}
        onChange={(input) => {
          setValue('numberOfEventsFromEventType', Number(input.target.value), {
            shouldValidate: true,
          })

          onChange({
            type: ConditionType.OCCURRENCE_HAS_EVENTS,
            evaluation: watch('evaluation'),
            facts: {
              numberOfEventsFromEventType: Number(input.target.value),
              eventType: selectedEventType,
              eventsFromEventTypeHasSameDevices,
            },
          })
        }}
        placeholder="Digite um número de 1 a 100000"
        label="Quantidade (a partir de)"
      />

      <Combobox
        label={{ text: 'De dispositivos' }}
        items={Object.values(translatedEventDeviceOption).map(
          (eventDevice) => eventDevice.label,
        )}
        value={handleEventDeviceOption(eventsFromEventTypeHasSameDevices)}
        onChange={(selected) => {
          const selectedOption = convertEventDeviceOption[selected as string]

          const { value } = translatedEventDeviceOption[selectedOption]

          setValue('eventsFromEventTypeHasSameDevices', value, {
            shouldValidate: true,
          })

          onChange({
            type: ConditionType.OCCURRENCE_HAS_EVENTS,
            evaluation: watch('evaluation'),
            facts: {
              eventType: selectedEventType,
              numberOfEventsFromEventType,
              eventsFromEventTypeHasSameDevices: value,
            },
          })
        }}
      />
    </div>
  )
}
