import { useCallback, useState } from 'react'
import styles from './styles.module.scss'

import { Icon, ProtectedImage } from 'components'
import { StationDriver } from 'services/station'
import { UserSessionStatus } from 'services/auth'
import { handleLogoutAllTabs } from 'utilities/auth'
import { useUserInfo, useUserSession, usePersistentFilters } from 'shared/hooks'

import { useNavigate } from 'react-router-dom'

const User = () => {
  const navigate = useNavigate()
  const { userInfo } = useUserInfo()
  const [isVisible, setIsVisible] = useState(false)
  const { mutateCreateUserSession } = useUserSession()
  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const handleLogoff = useCallback(async () => {
    const userId = userInfo.id

    if (userInfo?.station) {
      await StationDriver.logoffOperator(userInfo.station.id, {
        operatorId: userId,
      })
    }

    mutateCreateUserSession(
      {
        status: UserSessionStatus.OFFLINE,
        userId,
      },
      {
        onSuccess: () => {
          handleLogoutAllTabs()
          clearAllLocalStorageFilters()
        },
      },
    )
  }, [userInfo, mutateCreateUserSession])

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <div className={styles.ONLINE}>
        <div>
          {userInfo?.imageId ? (
            <ProtectedImage imageId={userInfo.imageId} />
          ) : (
            <Icon name="avatar" width={40} height={40} />
          )}
        </div>
        <div className={styles.principalInfos}>
          <span>{userInfo?.name}</span>
          {userInfo?.phoneExtension && (
            <span>Ramal {userInfo?.phoneExtension}</span>
          )}
        </div>
      </div>
      <div
        className={[styles.areaExpanded, isVisible && styles.visible].join(' ')}
      >
        <button
          onClick={() => {
            navigate('/user-info')
            setIsVisible(false)
          }}
        >
          <Icon name="customer" />
          Meus Dados
        </button>
        <hr />
        <button type="button" onClick={handleLogoff}>
          <Icon name="logout" />
          Sair
        </button>
      </div>
    </div>
  )
}

export default User
