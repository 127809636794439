import { ErrorState as ErrorComponent } from 'components'

import styles from './styles.module.scss'

const ErrorState = () => {
  return (
    <div className={styles.container}>
      <ErrorComponent />
    </div>
  )
}

export default ErrorState
