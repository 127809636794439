import Joi from '@hapi/joi'
import { PartitionPayload } from 'services/partition/types'
import { PartitionStatus } from 'services/patrimony/types'

export const partitionSchema = Joi.object<PartitionPayload>({
  code: Joi.string().required(),
  name: Joi.string().required(),
  id: Joi.string().uuid().optional().allow('', null),
  keyNumber: Joi.string().optional().allow('', null),
  status: Joi.string()
    .valid(...Object.values(PartitionStatus))
    .required(),
  externalId: Joi.string().optional().allow('', null),
})
