import { ReactComponent as BlockedIcon } from 'assets/svg/blocked.svg'
import { ReactComponent as aimIcon } from 'assets/svg/aim.svg'

import { Button, ProtectedButton } from 'components'

import { EquipmentPhoto } from 'domains/customer/screens/Equipments/components/EquipmentPhoto/EquipmentPhoto'
import { DeviceEnabled } from 'domains/customer/screens/Equipments/components/DeviceEnabled/DeviceEnabled'

import styles from './PhotosensorCard.module.scss'
import { useToggle } from 'shared/hooks'
import { DeviceUpdate } from 'domains/customer/screens/Equipments/components/DeviceUpdate/DeviceUpdate'
import { DeviceCardFooter } from 'domains/customer/screens/Equipments/components/DeviceCardFooter/DeviceCardFooter'
import { CentralFragment } from 'domains/customer/screens/Equipments/types'
import { DeviceCardHeader } from 'domains/customer/screens/Equipments/components/DeviceCardHeader/DeviceCardHeader'
import { PhotosensorConfig } from 'domains/customer/screens/Equipments/components/PhotosensorConfig/PhotosensorConfig'
import { UbideskPermissions } from 'routes/types'
import { CENTRAL_MODE } from 'services/central/types'
import { PartitionDeviceAggregatedResponse } from 'services/device/types'

interface PhotosensorCardProps {
  photosensor: PartitionDeviceAggregatedResponse
  central: CentralFragment
  partitionId?: string
}

export const PhotosensorCard = (props: PhotosensorCardProps) => {
  const { photosensor, central, partitionId } = props

  const editModal = useToggle()
  const deviceAnnulModal = useToggle()
  const photoSensorConfigModal = useToggle()

  return (
    <div className={styles.cardContainer} aria-label="equipment-card-details">
      <DeviceUpdate
        device={{
          id: photosensor.id,
          name: photosensor.name,
          partitions: photosensor.partitions,
        }}
        central={central}
        onClose={editModal.hide}
        isVisible={editModal.isVisible}
      />
      <DeviceEnabled
        isVisible={deviceAnnulModal.isVisible}
        onClose={deviceAnnulModal.hide}
        device={{
          id: photosensor.id,
          hiddenZone: photosensor.hiddenZone,
          hiddenZoneTo: photosensor.hiddenZoneTo,
          hasCommandSupport: photosensor.hasCommandSupport,
        }}
        centralId={central.id}
      />
      {photoSensorConfigModal.isVisible && (
        <PhotosensorConfig
          isVisible
          centralId={central.id}
          partitionId={partitionId}
          photosensor={{
            id: photosensor.id,
            profiles: photosensor.profiles,
          }}
          onClose={photoSensorConfigModal.hide}
        />
      )}

      <div className={styles.header}>
        <DeviceCardHeader
          deviceId={photosensor.id}
          title={photosensor.name}
          type={photosensor.typeCode}
          hiddenZone={photosensor.hiddenZone}
          hiddenZoneTo={photosensor.hiddenZoneTo}
          tamper={photosensor.isTamperOpened}
          onClick={editModal.show}
          hasCommandSupport={photosensor.hasCommandSupport}
        />
        <div className={styles.cardButtonOptions}>
          <ProtectedButton
            permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
            id="block-equipment-button"
            buttonTitle={
              photosensor.hiddenZone || photosensor.hiddenZoneTo
                ? 'Reativar equipamento'
                : 'Anular equipamento'
            }
            type="secondary"
            icon={BlockedIcon}
            width="184px"
            onClick={deviceAnnulModal.show}
          />
          <Button
            id="config-button"
            buttonTitle="Configurar fotossensor"
            type="secondary"
            className={styles.iconButton}
            icon={aimIcon}
            width="184px"
            onClick={photoSensorConfigModal.show}
            disabled={central.mode !== CENTRAL_MODE.MAINTENANCE}
          />
        </div>
      </div>
      <EquipmentPhoto
        imageId={photosensor.installationImageId}
        alt="fotosensor"
      />
      <div className={styles.footer}>
        <DeviceCardFooter device={photosensor} />
      </div>
    </div>
  )
}
