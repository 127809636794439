import { useMemo } from 'react'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import DistrictDriver from 'services/address/district'
import { District } from 'services/address/district/types'
import { getNextPageOffSet, flattenPages } from '../utilities/pagination'

interface UseGetDistrictsReturn {
  isError: boolean
  isFetching: boolean
  districts: District[]
  fetchNextDistrictsPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

async function getDistricts(offset = 0, cityId: string, filter?: string) {
  const result = await DistrictDriver.queryDistricts({
    cityId,
    recordsPerPage: 15,
    offset,
    ...(filter && { name: filter }),
  })

  return result
}

export const useGetDistricts = (
  cityId = '',
  filter: string,
): UseGetDistrictsReturn => {
  const {
    isError,
    data,
    fetchNextPage: fetchNextDistrictsPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['districts', cityId, filter],
    queryFn: ({ pageParam }) => getDistricts(pageParam, cityId, filter),
    initialPageParam: 0,
    enabled: Boolean(cityId),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<District>(lastPage, allPages),
  })

  const districts = useMemo(() => {
    return flattenPages<District>(data)
  }, [data])

  return { isError, isFetching, districts, fetchNextDistrictsPage }
}
