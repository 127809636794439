import React, { useMemo, useState } from 'react'

import { HistoryIntervention } from 'services/attendance/types'
import { ProtectedImage } from 'components'
import { maskedDateTime } from 'utilities/date'
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow.svg'

import styles from './styles.module.scss'
import { handleInterventionIcon, handleStatusIcon } from './utils'

interface InterventionCardProps {
  intervention: HistoryIntervention
  onImage: (imageId: string) => void
}

const InterventionCard: React.FC<InterventionCardProps> = ({
  onImage,
  intervention,
}) => {
  const [expanded, setExpanded] = useState(false)

  const isInterventionExpandable = useMemo(() => {
    return !!intervention.notes.length
  }, [intervention])

  const image: string | undefined =
    intervention.attributes?.imageId || intervention.attributes?.imageIds?.[0]

  const handleExpansion = () => {
    setExpanded((old) => !old)
  }

  return (
    <li className={styles.container}>
      <div onClick={() => isInterventionExpandable && handleExpansion()}>
        <div className={styles[intervention.attributes?.status]}>
          {handleInterventionIcon(intervention)}
          <div>
            <h4>
              {intervention.title} {handleStatusIcon(intervention)}
            </h4>
            <span>
              <strong>{intervention.user?.name}</strong>
              {maskedDateTime(intervention.createdAt)}
            </span>
          </div>
        </div>
        <div className={styles.iconBox}>
          {image && (
            <ProtectedImage imageId={image} onClick={() => onImage(image)} />
          )}
          {isInterventionExpandable && (
            <button
              type="button"
              className={[styles.arrowDown, expanded && styles.arrowUp]
                .filter(Boolean)
                .join(' ')}
            >
              <ArrowDownIcon />
            </button>
          )}
        </div>
      </div>
      {expanded && (
        <div className={styles.expanded}>
          <ul className={styles.notes}>
            {intervention.notes
              .sort((a, b) => a.createdAt - b.createdAt)
              .map((note) => (
                <li key={note.createdAt}>
                  <span className={styles.title}>
                    <strong>{note.user.name}</strong>
                    <span>{maskedDateTime(note.createdAt)}</span>
                  </span>
                  <p
                    className={[
                      styles.note,
                      note.note.length > 450 &&
                        note.note.includes('\n') &&
                        styles.notesExpanded,
                    ].join(' ')}
                  >
                    {note.note.split(/(\n)/g).map((part, index) =>
                      part === '\n' ? (
                        <React.Fragment key={index}>
                          {part}
                          <br />
                        </React.Fragment>
                      ) : (
                        part
                      ),
                    )}
                  </p>
                </li>
              ))}
          </ul>
        </div>
      )}
    </li>
  )
}

export default InterventionCard
