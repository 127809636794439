import { TagType } from './account/types'

export type Pagination = {
  offset: number
  recordsPerPage: number
}

export type Result<T> = {
  data: T[]
  totalElements: number
}

export declare type Timestamps = {
  createdAt: number
  updatedAt: number
  closedAt?: number
}
export declare type Paging = {
  recordsPerPage: number
  offset: number
}
export declare type QueryRange = {
  updatedFrom: number
  updatedTo: number
}
export declare type Active = {
  active: boolean
  activeDateTime: number
}

export declare type Sort = {
  sort: string
  sortType: string
}

export type ReactQueryRequestStatus = 'error' | 'idle' | 'loading' | 'success'

export enum DAY_OF_WEEK {
  'SUNDAY' = 0,
  'MONDAY' = 1,
  'TUESDAY' = 2,
  'WEDNESDAY' = 3,
  'THURSDAY' = 4,
  'FRIDAY' = 5,
  'SATURDAY' = 6,
  'HOLIDAY' = 7,
}

export const dayOfWork = {
  HOLIDAY: 'HOLIDAY',
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
}

export type DayOfWork = keyof typeof dayOfWork

export const dayOfWorkTranslated: Record<DayOfWork, string> = {
  HOLIDAY: 'Feriados',
  SUNDAY: 'Domingo',
  MONDAY: 'Segunda',
  TUESDAY: 'Terça',
  WEDNESDAY: 'Quarta',
  THURSDAY: 'Quinta',
  FRIDAY: 'Sexta',
  SATURDAY: 'Sábado',
}

export type TagInput = {
  id: string
  name: string
  type?: TagType
}

export type TagOutput = {
  id: string
  totalLinks: number
} & TagInput &
  Timestamps
