import { useMemo } from 'react'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'

import {
  flattenPages,
  getNextPageOffSet,
} from '../services/utilities/pagination'

import { ServiceOrderDriver, TagOutput } from 'services/serviceOrder'

type UseGetAccountTagsReturn = {
  isError: boolean
  isFetching: boolean
  serviceOrderTags: TagOutput[]
  fetchNextTagsPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const getServiceOrderTags = async (offset = 0, name?: string) => {
  const response = await ServiceOrderDriver.queryTag({
    offset,
    ...(name && { name }),
    recordsPerPage: 15,
  })
  return response
}

export function useGetServiceOrderTags(name: string): UseGetAccountTagsReturn {
  const {
    isError,
    isFetching,
    data,
    fetchNextPage: fetchNextTagsPage,
  } = useInfiniteQuery({
    queryKey: ['serviceOrderTag', name],
    queryFn: ({ pageParam }) => getServiceOrderTags(pageParam, name),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<TagOutput>(lastPage, allPages),

    refetchOnMount: false,
  })

  const serviceOrderTags = useMemo(() => flattenPages<TagOutput>(data), [data])

  return {
    isError,
    isFetching,
    serviceOrderTags,
    fetchNextTagsPage,
  }
}
