import * as Joi from '@hapi/joi'

import { City } from 'services/address/city/types'
import { State } from 'services/address/state/types'
import { District } from 'services/address/district/types'

import { AccountTag, AggregatedAccount, TagType } from 'services/account/types'
import { SERVICE_ORDER_TYPE_ENUM } from 'services/serviceOrder/types'
import { OCCURRENCE_CATEGORY } from 'services/occurrence/types'

import {
  accountSchema,
  citySchema,
  districtSchema,
  stateSchema,
} from 'shared/schemas'
import { ATTENDANCE_ROLE } from 'services/coverages'

export type AttendanceProfileFilters = {
  name: string
  coverageType: ATTENDANCE_ROLE
  state: State
  city: City
  district: District
  account: AggregatedAccount
  tag: AccountTag
  serviceOrderType: SERVICE_ORDER_TYPE_ENUM
  occurrenceCategory: OCCURRENCE_CATEGORY
  serviceTypes: {
    id: string
    name: string
  }[]
}

export const tagSchema = Joi.object<AccountTag>({
  id: Joi.string().uuid(),
  name: Joi.string(),
  type: Joi.string().valid(...Object.values(TagType)),
})

export const attendanceProfileFiltersSchema =
  Joi.object<AttendanceProfileFilters>({
    name: Joi.string().optional().allow(null, ''),
    coverageType: Joi.string()
      .valid(...Object.values(ATTENDANCE_ROLE))
      .optional(),
    state: stateSchema.optional(),
    city: citySchema.optional(),
    district: districtSchema.optional(),
    account: accountSchema.optional(),
    tag: tagSchema.optional(),
    serviceOrderType: Joi.string()
      .valid(...Object.values(SERVICE_ORDER_TYPE_ENUM))
      .optional(),
    occurrenceCategory: Joi.string()
      .valid(...Object.values(OCCURRENCE_CATEGORY))
      .optional(),
    serviceTypes: Joi.array()
      .items(
        Joi.object({
          id: Joi.string().uuid().optional(),
          name: Joi.string().optional(),
        }),
      )
      .optional(),
  })
