import { useState, useEffect, useCallback, useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import {
  Input,
  Toast,
  Loader,
  Button,
  Modal,
  Checkbox,
  ButtonGroup,
} from 'components'

import { maskedDate } from 'utilities/date'

import { formatCpf, formatCnpj } from 'utilities/masks/document'

import {
  validateNaturalPersonDocumentNumber,
  validateJuridicalPersonDocumentNumber,
} from '../../utilities/validation'

import { NaturalPersonConstants, JuridicalPersonConstants } from '../../types'

import { useErrorHandler, useToggle } from 'shared/hooks'

import { CustomerDriver } from 'services/customer'
import { AccountContext, TabContext } from 'domains/customer/context'

import styles from './GeneralInfo.module.scss'
import { CustomerResponse } from 'services/customer/types'

export const GeneralInfo = () => {
  const [isPersonF, setPersonF] = useState(true)
  const [toastVisible, setToastVisible] = useState(false)
  const [loaderVisible, setLoaderVisible] = useState(false)
  const [inputErrorMessage, setInputErrorMessage] = useState('')
  const { handleError } = useErrorHandler()
  const [customer, setCustomer] = useState<CustomerResponse>({
    id: '',
    name: '',
    createdAt: 0,
    deviceId: '',
    document: '',
    updatedAt: 0,
    active: false,
    personType: '',
    secondName: '',
    activeDateTime: 0,
    costCenter: '',
  })
  const [customerEdit, setCustomerEdit] = useState<CustomerResponse>({
    id: '',
    name: '',
    createdAt: 0,
    document: '',
    deviceId: '',
    updatedAt: 0,
    active: false,
    secondName: '',
    personType: '',
    activeDateTime: 0,
    costCenter: '',
  })

  const currentAccount = useContext(AccountContext)
  const tabContext = useContext(TabContext)
  const navigate = useNavigate()
  const serviceError = useToggle()
  const invalidCustomerData = useToggle()

  useEffect(() => {
    setPersonF(customer.personType === NaturalPersonConstants.personType)
  }, [customer])

  useEffect(() => {
    const documentWithoutMask = customerEdit.document.replace(/\D/g, '')
    const isEdited = !(
      JSON.stringify({ ...customer }) ===
      JSON.stringify({ ...customerEdit, document: documentWithoutMask })
    )
    if (isEdited) {
      tabContext?.setUnsaveds((previous) => [...previous, 'tab-00'])
    } else {
      tabContext?.setUnsaveds((previous) =>
        previous.filter((tab) => tab !== 'tab-00'),
      )
    }
  }, [customerEdit])

  useEffect(() => {
    fetchCustomer()
  }, [currentAccount])

  const fetchCustomer = useCallback(async () => {
    setLoaderVisible(true)
    const customerId = localStorage.getItem('customerId')
    try {
      if (customerId) {
        const response = await CustomerDriver.fetchCustomer(customerId)
        setCustomer({ ...response })
        setCustomerEdit({ ...response })
      }
    } catch (error) {
      if (error instanceof Error) {
        handleError(error)
      }
      setToastVisible(true)
    } finally {
      setLoaderVisible(false)
    }
  }, [currentAccount])

  const handleUpdateCustomerData = async () => {
    const inputErrorMessage = validateInputValues()
    if (inputErrorMessage) {
      setInputErrorMessage(inputErrorMessage)
    } else {
      setLoaderVisible(true)
      try {
        await CustomerDriver.updateCustomer({
          ...customerEdit,
          document: customerEdit.document.replace(/\D/g, ''),
        })
        await fetchCustomer()
      } catch (error) {
        if (error instanceof Error) {
          if (error?.message === 'Request failed with status code 400') {
            invalidCustomerData.show()
          } else {
            serviceError.show()
          }
        }
      } finally {
        setLoaderVisible(false)
      }
    }
  }

  const clearDocumentOnChangingPersonType = (legalEntity: string) => {
    if (legalEntity !== customer.personType) {
      return ''
    } else {
      return customer.document
    }
  }

  const togglePersonType = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setInputErrorMessage('')

    const legalEntity =
      event.currentTarget.getAttribute('id') === 'natural-person' ? 'F' : 'J'
    if (legalEntity !== null) {
      const newDocument = clearDocumentOnChangingPersonType(legalEntity)
      if (legalEntity === NaturalPersonConstants.personType) {
        setPersonF(true)
        setCustomerEdit({
          ...customerEdit,
          personType: NaturalPersonConstants.personType,
          document: newDocument,
        })
      } else {
        setPersonF(false)
        setCustomerEdit({
          ...customerEdit,
          personType: JuridicalPersonConstants.personType,
          document: newDocument,
        })
      }
    }
  }

  const undoChanges = () => {
    setInputErrorMessage('')
    setCustomerEdit({ ...customer })
    setPersonF(customer?.personType === NaturalPersonConstants.personType)
  }

  const validateUserInputCpfCnpj = (userInput: string, legalEntity: string) => {
    setInputErrorMessage('')
    const onlyNumbersRegex = /^-?[0-9]*$/
    const userInputWithoutMask = userInput.replace(/\D/g, '')
    if (onlyNumbersRegex.test(userInputWithoutMask)) {
      setCustomerEdit({
        ...customerEdit,
        document:
          legalEntity === JuridicalPersonConstants.personType
            ? formatCnpj(userInputWithoutMask)
            : formatCpf(userInputWithoutMask),
      })
    }
  }
  const validateInputFills = () => {
    if (isPersonF) {
      return !customerEdit.name || !customerEdit.document
    } else {
      return (
        !customerEdit.name || !customerEdit.secondName || !customerEdit.document
      )
    }
  }

  const validateInputValues = (): string => {
    let inputErrorMessage = ''
    const documentWithoutMask = customerEdit.document.replace(/\D/g, '')
    if (isPersonF) {
      if (!validateNaturalPersonDocumentNumber(customerEdit.document)) {
        inputErrorMessage = `CPF inválido. Verifique se o código digitado está correto`
      }
      if (documentWithoutMask.length < 11) {
        inputErrorMessage = 'Um CPF válido segue o formato: 000.000.000-00'
      }
    } else {
      if (!validateJuridicalPersonDocumentNumber(customerEdit.document)) {
        inputErrorMessage = `CNPJ inválido. Verifique se o código digitado está correto`
      }

      if (documentWithoutMask.length < 14) {
        inputErrorMessage = 'Um CNPJ válido segue o formato: 00.000.000/0000-00'
      }
    }
    return inputErrorMessage
  }

  return (
    <>
      <Loader isVisible={loaderVisible} />
      <Toast
        type="alert"
        isVisible={toastVisible}
        onClose={() => setToastVisible(false)}
      >
        Erro ao carregar os dados. Por favor, tente novamente.
      </Toast>
      <Modal
        simple
        title="Erros no formulário"
        isVisible={invalidCustomerData.isVisible}
        onClose={() => {
          invalidCustomerData.hide()
        }}
      >
        <p className={styles.errorModalText}>
          Não foi possível salvar as informações. Por gentileza, preencha os
          campos corretamente.
        </p>
        <Modal.Footer>
          <Button
            buttonTitle="Fechar"
            type="primary"
            id="btn-close"
            onClick={() => {
              invalidCustomerData.hide()
            }}
          />
        </Modal.Footer>
      </Modal>
      <Modal
        simple
        title="Erro no servidor"
        isVisible={serviceError.isVisible}
        onClose={() => {
          serviceError.hide()
        }}
      >
        <p className={styles.errorModalText}>
          Não foi possível realizar a ação devido a um erro em nossos
          servidores.
        </p>
        <p className={styles.errorModalText}>
          Tente novamente mais tarde ou entre em contato com o suporte de TI.
        </p>
        <Modal.Footer>
          <Button
            buttonTitle="Fechar"
            type="primary"
            id="btn-close"
            onClick={() => {
              serviceError.hide()
            }}
          />
        </Modal.Footer>
      </Modal>
      <div className={styles.customerWrapper}>
        <div className={styles.customerContent}>
          <h3 className={styles.customerTitleContent}>Gerenciamento</h3>
          <section className={styles.customerActivity}>
            <Checkbox
              label="Cliente ativo"
              id="check-box"
              disabled
              small
              checked={customerEdit.active}
              onChange={(value) => {
                const inputValue = value.target.checked
                setCustomerEdit((previous) => ({
                  ...previous,
                  active: inputValue,
                }))
              }}
            />
            <div>
              {customer?.active ? (
                <p className={styles.subtitle}>Ativo desde</p>
              ) : (
                <p className={styles.subtitle}>Inativo desde</p>
              )}

              <p className={styles.basicText}>
                {maskedDate(customer?.activeDateTime || 0)}
              </p>
            </div>
            <div>
              <p className={styles.subtitle}>Cadastro em</p>
              <p className={styles.basicText}>
                {maskedDate(customer?.createdAt || 0)}
              </p>
            </div>
          </section>
          <div className={styles.middleComponent}>
            <ButtonGroup
              leftButton={{
                id: 'natural-person',
                onClick: togglePersonType,
                title: 'Pessoa Física',
                selected: isPersonF,
              }}
              rightButton={{
                id: 'juridical-person',
                onClick: togglePersonType,
                title: 'Pessoa Jurídica',
                selected: !isPersonF,
              }}
            />
          </div>
          {isPersonF ? (
            <section>
              <div className={styles.inputStyle}>
                <Input
                  label="Nome Completo"
                  id="input-full-name"
                  onChange={(event) => {
                    const inputValue = event.target.value
                    setCustomerEdit((previousCustomerEdit) => ({
                      ...previousCustomerEdit,
                      name: inputValue,
                    }))
                  }}
                  placeholder="Nome do cliente"
                  value={customerEdit.name}
                />
              </div>
              <div className={styles.inputStyle}>
                <Input
                  errorMessage={inputErrorMessage}
                  label="CPF"
                  maxLength={14}
                  id="input-cpf"
                  onChange={(event) => {
                    const inputValue = event.target.value
                    validateUserInputCpfCnpj(
                      inputValue,
                      NaturalPersonConstants.personType,
                    )
                  }}
                  placeholder="000.000.000-00"
                  value={formatCpf(customerEdit.document || '')}
                />
              </div>
            </section>
          ) : (
            <section>
              <div className={styles.inputStyle}>
                <Input
                  label="Razão Social"
                  id="input-name"
                  onChange={(event) => {
                    const inputValue = event.target.value
                    setCustomerEdit((previousCustomerEdit) => ({
                      ...previousCustomerEdit,
                      name: inputValue,
                    }))
                  }}
                  placeholder="Razão social"
                  value={customerEdit?.name}
                />
              </div>
              <div className={styles.inputStyle}>
                <Input
                  label="Nome Fantasia"
                  id="input-second-name"
                  onChange={(event) => {
                    const inputValue = event.target.value
                    setCustomerEdit((previousCustomerEdit) => ({
                      ...previousCustomerEdit,
                      secondName: inputValue,
                    }))
                  }}
                  placeholder="Nome fantasia"
                  value={customerEdit.secondName}
                />
              </div>
              <div className={styles.inputStyle}>
                <Input
                  errorMessage={inputErrorMessage}
                  label="CNPJ"
                  maxLength={18}
                  id="input-cnpj"
                  onChange={(event) => {
                    const inputValue = event.target.value
                    validateUserInputCpfCnpj(
                      inputValue,
                      JuridicalPersonConstants.personType,
                    )
                  }}
                  placeholder="00.000.000/0000-00"
                  value={formatCnpj(customerEdit.document || '')}
                />
              </div>
            </section>
          )}
          <div className={styles.inputStyle}>
            <Input
              type="text"
              label="Centro de custo"
              maxLength={45}
              onChange={(event) =>
                setCustomerEdit((previousCustomerEdit) => ({
                  ...previousCustomerEdit,
                  costCenter: event.target.value,
                }))
              }
              value={customerEdit.costCenter}
            />
          </div>
        </div>
        <section className={styles.bottomButtons}>
          <div className={styles.leftButtonsGroup}>
            <Button
              width="172px"
              id="btn-save"
              type="primary"
              buttonTitle="Salvar"
              disabled={validateInputFills()}
              onClick={handleUpdateCustomerData}
            />
            <Button
              id="btn-undo"
              type="secondary"
              buttonTitle="Desfazer alterações"
              onClick={undoChanges}
            />
          </div>
          <div>
            <Button
              id="btn-back"
              type="secondary"
              buttonTitle="Voltar à pesquisa"
              onClick={() => navigate(-1)}
            />
          </div>
        </section>
      </div>
    </>
  )
}

export default GeneralInfo
