import { useMutation } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { LoginRequest } from './types'

export const login = async (data: LoginRequest) => {
  const response = await HttpClient.post(endpoints.login, data)

  return response.data
}

export const useLogin = () => {
  return useMutation({
    mutationFn: async (data: LoginRequest) => await login(data),
  })
}
