import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { getServiceOrderTags } from 'services/serviceOrderV2'
import {
  ButtonV2,
  ComboBoxV3,
  FormItem,
  FormLabel,
  Icon,
  ModalV2,
  Tag,
} from 'components'

const TagModal = () => {
  const closeRef = useRef<HTMLButtonElement>(null)
  const { watch, setValue } = useFormContext()

  const { tags: formTags } = watch()

  const [tags, setTags] = useState<{ id: string; name: string }[]>(formTags)

  useEffect(() => {
    setTags(formTags)
  }, [setTags, formTags])

  return (
    <>
      <ModalV2.Title>Adicionar tag</ModalV2.Title>

      <FormItem>
        <FormLabel>Tags (opcional)</FormLabel>
        <ComboBoxV3.Root findOptions={getServiceOrderTags} valueKey="name">
          {({ unSelectedOptions }) => (
            <>
              <ComboBoxV3.Field value={tags} />
              {!!tags?.length && (
                <ComboBoxV3.Options>
                  {tags.map((type) => (
                    <Tag key={type.id}>
                      <span>{type.name}</span>

                      <Icon
                        name="close-xlg"
                        width={8}
                        height={8}
                        onClick={() =>
                          setTags((old) =>
                            old.filter((item) => item.id !== type.id),
                          )
                        }
                      />
                    </Tag>
                  ))}
                </ComboBoxV3.Options>
              )}
              <ComboBoxV3.Group>
                {unSelectedOptions?.map(({ id, name }) => (
                  <ComboBoxV3.Option
                    key={id}
                    shouldCloseGroup={false}
                    onClick={() => {
                      setTags((old) => [...(old || []), { id, name }])
                    }}
                  >
                    {name}
                  </ComboBoxV3.Option>
                ))}
              </ComboBoxV3.Group>
            </>
          )}
        </ComboBoxV3.Root>
      </FormItem>

      <ModalV2.Footer>
        <ModalV2.Close asChild>
          <ButtonV2 appearance="tertiary" ref={closeRef}>
            Cancelar
          </ButtonV2>
        </ModalV2.Close>
        <ButtonV2
          onClick={() => {
            if (closeRef.current) {
              closeRef.current.click()
            }

            setValue('tags', tags, { shouldValidate: true })
          }}
        >
          Adicionar
        </ButtonV2>
      </ModalV2.Footer>
    </>
  )
}

export default TagModal
