import { useQuery } from '@tanstack/react-query'

import { Result } from 'services/types'
import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { Query, QueryEvent } from './types'

export const useQueryEvent = ({
  accountId,
  account,
  page = 1,
  size = 15,
  fields,
}: Query) => {
  return useQuery({
    queryKey: ['query-event', accountId, page, size, fields, account],
    queryFn: async () => {
      const response = await HttpClient.get<Result<QueryEvent>>(
        buildUrl({
          route: endpoints.query,
          queryParams: {
            accountId,
            ...(account && { account }),
            fields,
            page: page - 1,
            size,
          },
        }),
      )

      return response.data
    },
  })
}
