import { useEffect } from 'react'
import { useToast } from 'shared/hooks'
import { usePutServiceOrder } from 'shared/hooks/serviceOrder/usePutServiceOrder'

type Messages = {
  success: string
  error: string
}

interface PutAndHandleServiceOrderProps {
  messages?: Messages
  onSuccess?: () => void
  onError?: () => void
}

export const usePutAndHandleServiceOrder = ({
  messages,
  onSuccess,
  onError,
}: PutAndHandleServiceOrderProps) => {
  const { addToast } = useToast()

  const { mutatePutServiceOrder, updateServiceOrderStatus } =
    usePutServiceOrder()

  useEffect(() => {
    if (updateServiceOrderStatus === 'success') {
      onSuccess?.()
      if (messages) {
        addToast({
          message: messages.success,
        })
      }
    }

    if (updateServiceOrderStatus === 'error') {
      onError?.()
      if (messages) {
        addToast({
          message: messages.error,
          type: 'alert',
        })
      }
    }
  }, [addToast, updateServiceOrderStatus])

  return {
    mutatePutServiceOrder,
    updateServiceOrderStatus,
  }
}
