import { Breadcrumbs, ContainerScreen, Loader } from 'components'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  useGetCoverage,
  usePutCoverage,
} from 'domains/attendanceProfile/hooks/services/coverages'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { createAndUpdateAttendanceProfileSchema } from 'domains/attendanceProfile/schemas'
import { CoverageInputRequest } from 'services/coverages'
import { AttendanceProfileForm } from 'domains/attendanceProfile/components/AttendanceProfileForm/AttendanceProfileForm'
import { parseCoverageInfoToEntityFormat } from 'domains/attendanceProfile/utilities/coverages'
import { useToast } from 'shared/hooks'

export const UpdateAttendanceProfile = () => {
  const { addToast } = useToast()

  const navigate = useNavigate()
  const location = useLocation()

  const memoizedAttendanceProfileId = useMemo(() => {
    if (!location.state) return undefined
    return location.state.attendanceProfileId
  }, [location])

  const { coverage, isFetching } = useGetCoverage(
    memoizedAttendanceProfileId || '',
    Boolean(memoizedAttendanceProfileId),
  )

  const { mutateUpdateCoverage, statusUpdateCoverage } = usePutCoverage()

  const form = useForm<CoverageInputRequest>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(createAndUpdateAttendanceProfileSchema),
  })

  const onSubmit = (data: CoverageInputRequest) => {
    const { serviceTypes, ...rest } = data
    mutateUpdateCoverage(
      {
        id: coverage?.id || '',
        ...rest,
        ...(serviceTypes?.length && {
          serviceTypeIds: serviceTypes.map((serviceType) => serviceType.id),
        }),
      },
      {
        onSuccess: () => {
          addToast({ message: 'Perfil de atendimento editado com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          addToast({
            message: 'Erro ao editar perfil de atendimento. Tente novamente.',
            type: 'alert',
          })
        },
      },
    )
  }

  if (!coverage || isFetching || statusUpdateCoverage === 'pending')
    return <Loader isVisible />

  return (
    <>
      <ContainerScreen
        clickable
        divider={false}
        renderBreadcrumbs={
          <Breadcrumbs
            items={[
              { title: 'Home', href: '/' },
              {
                title: 'Perfis de atendimento',
                href: '/config/attendanceProfile?page=1',
              },
              { title: coverage.name, href: '#' },
            ]}
          />
        }
      >
        <FormProvider {...form}>
          <AttendanceProfileForm
            onGoBack={() => navigate(-1)}
            onSubmit={onSubmit}
            attendanceProfile={{
              name: coverage.name,
              coverageType: coverage.coverageType,
              coverages: parseCoverageInfoToEntityFormat(coverage),
              serviceTypes: coverage.serviceTypes,
            }}
          />
        </FormProvider>
      </ContainerScreen>
    </>
  )
}
