import * as Joi from '@hapi/joi'
import { ServiceOrderPayload } from 'domains/serviceOrders/types'

import { SortedCustomer } from 'services/customer/types'

import {
  AccountWithAggregatedName,
  ServiceOrderContact,
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_TYPE_ENUM,
} from 'services/serviceOrder'
import {
  ServiceOrderLabelInput,
  serviceOrderLabelType,
} from 'services/serviceOrderLabel/types'
import { technicianSchema } from 'shared/schemas'
import {
  NumberSchemeErrorsEnum,
  StringSchemeErrorsEnum,
} from 'utilities/validation'

export const customerSchema = Joi.object<SortedCustomer>({
  id: Joi.string().uuid(),
  name: Joi.string(),
  aggregatedName: Joi.string(),
  accounts: Joi.array().optional(),
})

const accountSchema = Joi.object<AccountWithAggregatedName>({
  id: Joi.string().uuid().required(),
  code: Joi.string().required(),
  aggregatedName: Joi.string().required(),
})

const contactSchema = Joi.object<ServiceOrderContact>({
  id: Joi.string().uuid().optional().allow('', null),
  name: Joi.string().required(),
  provinceCode: Joi.number().optional().min(10).allow('', null),
  countryCode: Joi.number().optional().allow('', null),
  number: Joi.number()
    .optional()
    .min(10000000)
    .allow('', null)
    .messages({
      [NumberSchemeErrorsEnum.MIN]: 'O campo telefone precisa ser preenchido.',
    }),
})

export const serviceOrderSchema = Joi.object<ServiceOrderPayload>({
  customer: customerSchema.required().messages({
    [StringSchemeErrorsEnum.REQUIRED]:
      'Por favor preencha este campo para continuar',
  }),
  account: accountSchema.required(),
  serviceOrderType: Joi.string()
    .valid(...Object.values(SERVICE_ORDER_TYPE_ENUM))
    .required()
    .messages({
      [StringSchemeErrorsEnum.REQUIRED]:
        'Por favor preencha este campo para continuar',
    }),
  contact: contactSchema.required().messages({
    [StringSchemeErrorsEnum.REQUIRED]:
      'Por favor preencha este campo para continuar',
  }),
  note: Joi.string().required(),
  technician: technicianSchema.optional().messages({
    [StringSchemeErrorsEnum.REQUIRED]:
      'Por favor preencha este campo para continuar',
  }),
  status: Joi.string()
    .valid(...Object.values(SERVICE_ORDER_STATUS_ENUM))
    .required(),
  scheduleDate: Joi.date().optional().allow('', null),
  schedulePeriod: Joi.string().optional().allow('', null),
  tags: Joi.array()
    .items(Joi.object({ name: Joi.string().required() }))
    .optional(),
  labels: Joi.array()
    .items(
      Joi.object<ServiceOrderLabelInput>({
        id: Joi.string().uuid().required(),
        description: Joi.string().required(),
        active: Joi.boolean().required(),
        type: Joi.string()
          .valid(serviceOrderLabelType.REASON, serviceOrderLabelType.ORIGIN)
          .required(),
      }),
    )
    .length(2),
})
