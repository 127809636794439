import { Tags } from 'components'
import { translatedOccurrenceState } from 'domains/attendancePolicy/components/RuleForm/data/occurrenceStateName'
import { formattedPartitionStatusTerms } from 'domains/attendancePolicy/components/RuleForm/utilities/condition/condition'
import { DISLOCATION_LIMIT, EVENT } from 'domains/attendancePolicy/types'
import { ReactNode } from 'react'

import {
  AggregatedCondition,
  ConditionType,
} from 'services/attendancePolicy/types'
import { SERVICE_ORDER_TYPE_EN_PT } from 'services/serviceOrder'

const transformOccurrenceStates = ({ facts }: AggregatedCondition) => {
  const occurrenceStates =
    facts?.occurrenceStateNames?.map(
      (occurrenceStateName) => translatedOccurrenceState[occurrenceStateName],
    ) || []

  occurrenceStates.splice(0, 1, `${occurrenceStates[0]}`)

  const states =
    occurrenceStates.map((state, index) => ({
      id: String(index),
      name: state.toLowerCase(),
    })) || []

  const accordion = (
    <>
      Ocorrência no estado <Tags data={states} keyId="" keyLabel="" size="sm" />
    </>
  )

  const resume = (
    <>
      Ocorrência no estado <span>{occurrenceStates.join(', ')}</span>
    </>
  )

  return {
    resume,
    accordion,
  }
}

const transformEventsMitigated = ({ facts }: AggregatedCondition) => {
  const message = `${facts.allEventsMitigated ? EVENT.MITIGATED : EVENT.NOT_MITIGATED}`

  const resume = (
    <>
      Evento
      <span>{message}</span>
    </>
  )

  const accordion = (
    <>
      Evento
      <span>{message.toLowerCase()}</span>
    </>
  )

  return {
    resume,
    accordion,
  }
}

const transformAccountsTags = ({ facts, evaluation }: AggregatedCondition) => {
  const normalizedTags =
    facts?.accountTags?.map((accountTag) => accountTag.name) || []

  const tags =
    normalizedTags.map((tag, index) => ({
      id: String(index),
      name: tag,
    })) || []

  const evaluationLabel = evaluation === 'HASNT' ? 'não ' : ''

  const message = `Conta ${evaluationLabel}contem tag`

  const accordion = (
    <>
      {message}
      <div>
        <Tags data={tags} keyId="" keyLabel="" size="sm" />
      </div>
    </>
  )

  const resume = (
    <>
      Conta {evaluationLabel}contem tag <span>{normalizedTags.join(', ')}</span>
    </>
  )

  return {
    resume,
    accordion,
  }
}

const transformOccurrenceTags = ({
  facts,
  evaluation,
}: AggregatedCondition) => {
  const normalizedTags =
    facts?.occurrenceTags?.map((occurrence) => occurrence.name) || []

  const tags =
    normalizedTags.map((tag, index) => ({
      id: String(index),
      name: tag,
    })) || []

  const evaluationLabel = evaluation === 'HASNT' ? 'não ' : ''

  const message = `Occorrência ${evaluationLabel}contém tag`

  const accordion = (
    <>
      {message}
      <div>
        <Tags data={tags} keyId="" keyLabel="" size="sm" />
      </div>
    </>
  )

  const resume = (
    <>
      {message} <span>{normalizedTags.join(', ')}</span>
    </>
  )

  return {
    resume,
    accordion,
  }
}

const transformDeviceTags = ({ facts, evaluation }: AggregatedCondition) => {
  const normalizedTags = facts?.deviceTags?.map((device) => device.name) || []

  const tags =
    normalizedTags.map((tag, index) => ({
      id: String(index),
      name: tag,
    })) || []

  const evaluationLabel = evaluation === 'HASNT' ? 'não ' : ''
  const message = `Dispositivo${evaluationLabel}contém tag`

  const resume = (
    <>
      {message} <span>{normalizedTags.join(', ')}</span>
    </>
  )

  const accordion = (
    <>
      {message}
      <div>
        <Tags data={tags} keyId="" keyLabel="" size="sm" />
      </div>
    </>
  )

  return {
    resume,
    accordion,
  }
}

const transformServiceOrderTypes = ({ facts }: AggregatedCondition) => {
  const serviceOrderTypes: string[] =
    facts?.serviceOrderTypes?.map(
      (serviceOrderType) => SERVICE_ORDER_TYPE_EN_PT[serviceOrderType],
    ) || []

  const types =
    serviceOrderTypes.map((type, index) => ({
      id: String(index),
      name: type.toLowerCase(),
    })) || []

  const message = `Ordem de serviço do tipo`

  const accordion = (
    <>
      {message}
      <div>
        <Tags data={types} keyId="" keyLabel="" size="sm" />
      </div>
    </>
  )

  const resume = (
    <>
      Ordem de serviço do tipo <span>{serviceOrderTypes.join(', ')}</span>
    </>
  )

  return {
    resume,
    accordion,
  }
}

const transformServiceOrderTags = ({
  facts,
  evaluation,
}: AggregatedCondition) => {
  const serviceOrderTags =
    facts?.serviceOrderTags?.map((serviceOrderTag) => serviceOrderTag.name) ||
    []

  serviceOrderTags.splice(0, 1, `${serviceOrderTags[0]}`)

  const evaluationLabel = evaluation === 'HASNT' ? 'não ' : ''

  const message = (
    <>
      Ordem de serviço <span>{evaluationLabel} contém tag</span>
    </>
  )

  const tags =
    serviceOrderTags.map((sub, index) => ({
      id: String(index),
      name: sub,
    })) || []

  const accordion = (
    <>
      {message}
      <div>
        <Tags data={tags} keyId="" keyLabel="" size="sm" />
      </div>
    </>
  )

  const resume = (
    <>
      {message} <span>{serviceOrderTags.join(', ')}</span>
    </>
  )

  return {
    resume,
    accordion,
  }
}

const transformEventTypes = ({ facts, evaluation }: AggregatedCondition) => {
  const evaluationLabel = evaluation === 'HASNT' ? 'Não' : ''

  const pluralSuffix = facts.numberOfEventsFromEventType || 0 > 1 ? 's' : ''

  let eventOptionFragmentPhrase = <></>

  if (facts.eventsFromEventTypeHasSameDevices !== undefined) {
    eventOptionFragmentPhrase = (
      <span>
        {facts.eventsFromEventTypeHasSameDevices ? (
          pluralSuffix ? (
            'iguais'
          ) : (
            'igual'
          )
        ) : (
          <>diferente{pluralSuffix}</>
        )}
      </span>
    )
  }

  const eventTypeDescriptionFragmentPhrase = `${facts.eventType?.aggregatedCodeDescription}`

  const accordion = (
    <>
      <strong>
        {evaluationLabel} possui {facts.numberOfEventsFromEventType} ou mais
      </strong>{' '}
      eventos {eventOptionFragmentPhrase}
      de
      <strong>{eventTypeDescriptionFragmentPhrase}</strong>
    </>
  )

  const resume = (
    <>
      Tipo de evento
      <span>
        {evaluationLabel} possui {facts.numberOfEventsFromEventType} ou mais
        eventos <span>{eventOptionFragmentPhrase}</span> de{' '}
        {eventTypeDescriptionFragmentPhrase}
      </span>
    </>
  )

  return {
    accordion,
    resume,
  }
}

const transformTravelLimitReached = ({ facts }: AggregatedCondition) => {
  const message = `${
    facts.travelLimitReached
      ? DISLOCATION_LIMIT.REACHED
      : DISLOCATION_LIMIT.NOT_REACHED
  }`

  const resume = (
    <>
      Limite de deslocamento <span>{message}</span>
    </>
  )

  const accordion = (
    <>
      Limite de deslocamento <strong>{message.toLowerCase()}</strong>
    </>
  )

  return {
    resume,
    accordion,
  }
}

const transformPartitionStatus = ({ facts }: AggregatedCondition) => {
  const firstWord = facts.allPartitions ? 'todas' : 'ao menos uma'
  const secondWord = facts?.partitionStatus
    ? formattedPartitionStatusTerms[facts?.partitionStatus]
    : ''

  const fragment = (
    <>
      Status da partição
      <span>
        {firstWord} {secondWord}
      </span>
    </>
  )

  return {
    resume: fragment,
    accordion: fragment,
  }
}

const transformDevicesQuantity = ({ facts }: AggregatedCondition) => {
  let message = ''

  if (facts.minDevices && !facts.maxDevices) {
    message = `${facts.minDevices}`
  }

  if (facts.minDevices && facts.maxDevices) {
    message = `de ${facts.minDevices} até ${facts.maxDevices}`
  }

  const fragment = (
    <>
      Numero de dispositivos <span>{message}</span>
    </>
  )

  return {
    resume: fragment,
    accordion: fragment,
  }
}

const transformServiceType = ({ facts }: AggregatedCondition) => {
  const fragment = (
    <>
      Tipo de serviço <span>{facts.serviceType?.name}</span>
    </>
  )

  return {
    resume: fragment,
    accordion: fragment,
  }
}

const transformOccurrenceFinishType = ({ evaluation }: AggregatedCondition) => {
  const message = evaluation === 'HAS' ? 'Manual' : 'Automática'

  const resume = (
    <>
      Finalização de ocorrência <span>{message}</span>
    </>
  )

  const accordion = (
    <>
      Finalização de ocorrência <span>{message.toLowerCase()}</span>
    </>
  )

  return {
    resume,
    accordion,
  }
}

const transformEventImageDetections = ({
  evaluation,
  facts,
}: AggregatedCondition) => {
  const detectionType = {
    PERSON: 'pessoa',
    PET: 'pet',
  }

  const evaluationType = evaluation === 'HASNT' ? 'não' : ''

  const detections = facts?.imageDetections?.map(
    (detection) => detectionType[detection],
  )

  const message = `${detections?.join(', ')} ${evaluationType} detectada`

  const accordion = (
    <>
      Idenficiação de <span>{message}</span>
    </>
  )
  const resume = (
    <>
      Detecção em imagem
      <span>{message.charAt(0).toUpperCase() + message.slice(1)}</span>
    </>
  )

  return {
    resume,
    accordion,
  }
}

type ConditionTypeLabel = { resume: ReactNode; accordion: ReactNode }

export const conditionTypeLabel: Record<
  ConditionType,
  (condition: AggregatedCondition) => ConditionTypeLabel
> = {
  [ConditionType.OCCURRENCE_STATE_NAMES]: transformOccurrenceStates,
  [ConditionType.OCCURRENCE_EVENTS_MITIGATION]: transformEventsMitigated,
  [ConditionType.ACCOUNT_TAGS]: transformAccountsTags,
  [ConditionType.OCCURRENCE_TAGS]: transformOccurrenceTags,
  [ConditionType.DEVICE_TAGS]: transformDeviceTags,
  [ConditionType.SERVICE_ORDER_TYPES]: transformServiceOrderTypes,
  [ConditionType.OCCURRENCE_HAS_EVENTS]: transformEventTypes,
  [ConditionType.ACCOUNT_TRAVEL_LIMIT]: transformTravelLimitReached,
  [ConditionType.SERVICE_ORDER_ACCOUNT_TAGS]: transformServiceOrderTags,
  [ConditionType.CENTRAL_PARTITIONS_STATUS]: transformPartitionStatus,
  [ConditionType.DEVICE_QUANTITY]: transformDevicesQuantity,
  [ConditionType.SERVICE_TYPE]: transformServiceType,
  [ConditionType.OCCURRENCE_FINISHED_MANUALLY]: transformOccurrenceFinishType,
  [ConditionType.EVENT_IMAGE_DETECTIONS]: transformEventImageDetections,
}
