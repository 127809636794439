import { useCallback, useRef, ImgHTMLAttributes } from 'react'

import { ReactComponent as Play } from 'domains/attendancePolicy/assets/svg/play.svg'
import { useGetSoundFile } from 'shared/hooks/services/sound/useGetSoundFile'
import Button from 'components/Button'

type ProtectedImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  soundId?: string
}

export const PlaySound = ({ soundId = '' }: ProtectedImageProps) => {
  const audioRef = useRef<HTMLAudioElement>(null)

  const { data, isFetching } = useGetSoundFile(soundId)

  const playSound = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.play()
    }
  }, [audioRef])

  return (
    <>
      <audio ref={audioRef} src={data} key={soundId} />

      <Button
        disabled={isFetching || !soundId}
        icon={Play}
        buttonTitle="Ouvir"
        type="secondary"
        onClick={playSound}
      />
    </>
  )
}
