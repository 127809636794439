import { TriggerOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'

import { Combobox } from 'components/ComboboxV2/Combobox'

import { useForm } from 'react-hook-form'

import { TriggerType } from 'services/attendancePolicy/types'
import { OccurrenceInterventionTypeName } from 'services/displacementMap/types'
import {
  convertInterventionType,
  translatedInterventionType,
} from 'domains/attendancePolicy/components/RuleForm/utilities/trigger/data/interventionType'

import styles from './Intervention.module.scss'

export const Intervention = ({ onChange, data }: TriggerOptionForm) => {
  const { parameters } = data

  const form = useForm<{
    interventionType: OccurrenceInterventionTypeName
  }>({
    defaultValues: {
      interventionType: parameters?.interventionType,
    },
  })

  const { register, setValue } = form

  return (
    <div className={styles.container}>
      <Combobox
        id="interventionType"
        {...register('interventionType')}
        value={
          parameters?.interventionType
            ? translatedInterventionType[parameters?.interventionType]
            : undefined
        }
        label={{
          text: 'Tipo da intervenção',
        }}
        items={Object.values(translatedInterventionType).map(
          (interventionType) => interventionType,
        )}
        onChange={(selected) => {
          const selectedInterventionType = selected as string

          setValue(
            'interventionType',
            convertInterventionType[selectedInterventionType],
          )

          onChange({
            type: TriggerType.INTERVENTION_CREATED,
            parameters: {
              interventionType:
                convertInterventionType[selectedInterventionType],
            },
          })
        }}
        placeholder="Selecione um ou mais tipos"
      />
    </div>
  )
}
