import { ReactNode } from 'react'

import {
  Options,
  Filters,
  ChangeCar,
  TerminateDuty,
  QuickAlerts,
  ChangeTactical,
  StartTacticalDuty,
  ChangeDisplacementStep,
  PutUnderObservation,
  EndOccurrence,
  UpdateCoordinates,
  LogHistory,
  PauseDuty,
  ResumeDuty,
  Tactical,
  ChangeState,
} from './components'

import { ModalData, Modals } from '../../types'
import { useDisplacementModal } from '../../contexts'
import { Gallery } from 'components'

const handleRenderModal = (modal: Modals, data?: ModalData | null) => {
  const modals: Record<Modals, ReactNode> = {
    FILTERS: <Filters />,
    OPTIONS: <Options />,
    CHANGE_CAR: <ChangeCar />,
    TERMINATE_DUTY: <TerminateDuty />,
    START_DUTY: <StartTacticalDuty />,
    END_OCCURRENCE: <EndOccurrence />,
    CHANGE_TACTICAL: <ChangeTactical />,
    QUICK_ALERT: <QuickAlerts />,
    CHANGE_DISPLACEMENT_STEP: <ChangeDisplacementStep />,
    PUT_UNDER_OBSERVATION: <PutUnderObservation />,
    UPDATE_COORDINATES: <UpdateCoordinates />,
    LOG_HISTORY: <LogHistory />,
    PAUSE_DUTY: <PauseDuty />,
    RESUME_SUCCESS_DUTY: <ResumeDuty isSuccess />,
    RESUME_ERROR_DUTY: <ResumeDuty />,
    GALLERY: data && <Gallery {...data} />,
    TACTICAL: <Tactical />,
    CHANGE_STATE: data && <ChangeState onSubmit={data.onSubmit} />,
  }

  return modals[modal]
}

export const ModalsContainer = (): React.ReactNode => {
  const { modal, data } = useDisplacementModal()

  return modal && handleRenderModal(modal, data)
}
