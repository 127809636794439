import { ActionRuleOption } from '../types'
import { ReactComponent as ChangeState } from 'domains/attendancePolicy/assets/svg/changeState.svg'
import { ReactComponent as Priority } from 'domains/attendancePolicy/assets/svg/priority.svg'
import { ReactComponent as Notification } from 'domains/attendancePolicy/assets/svg/notification.svg'
import { ReactComponent as Phone } from 'domains/attendancePolicy/assets/svg/phone.svg'
import { ReactComponent as Upload } from 'domains/attendancePolicy/assets/svg/upload.svg'
import { ReactComponent as Title } from 'domains/attendancePolicy/assets/svg/title.svg'
import { ReactComponent as FinishOccurrence } from 'domains/attendancePolicy/assets/svg/finishOccurrence.svg'

import { ActionType } from 'services/attendancePolicy/types'
import { Icon } from 'components'

export const actionOptions: ActionRuleOption[] = [
  {
    id: '1',
    title: 'Alterar estado',
    description:
      'Modifica a etapa do fluxo de atendimento em que uma ocorrência está',
    icon: ChangeState,
    formType: ActionType.CHANGE_OCCURRENCE_STATE,
  },
  {
    id: '2',
    title: 'Alterar prioridade',
    description:
      'Determina quais ocorrências devem receber mais foco no atendimento',
    icon: Priority,
    formType: ActionType.CHANGE_OCCURRENCE_PRIORITY,
  },
  {
    id: '3',
    title: 'Notificação',
    description:
      'Determina quais ocorrências devem receber mais foco no atendimento',
    icon: Notification,
    formType: ActionType.SEND_NOTIFICATION,
  },
  {
    id: '4',
    title: 'Ligar',
    description:
      'Informa ao operador de uma ocorrência que ele precisa efetuar uma ligação',
    icon: Phone,
    formType: ActionType.REQUIRE_PHONE_CALL,
  },
  {
    id: '5',
    title: 'Envio externo',
    description:
      'Transmissão de dados para um serviço externo pré-configurado.',
    icon: Upload,
    formType: ActionType.SEND_OCCURRENCE_CUSTOM_EVENT,
  },
  {
    id: '6',
    title: 'Alterar título',
    description:
      'Determina um título para a ocorrência a fim de facilitar sua identificação',
    icon: Title,
    formType: ActionType.CHANGE_OCCURRENCE_TITLE,
  },
  {
    id: '7',
    title: 'Alterar finalização',
    description:
      'Muda a permissão de finalização da ocorrência pelo agente tático',
    icon: FinishOccurrence,
    formType: ActionType.CHANGE_FINISH_RESTRICTION,
  },
  {
    id: '8',
    title: 'Adicionar tag',
    description: 'Acrescenta uma ou mais tags para categorizar uma ocorrência',
    icon: (props) => <Icon name={'tag-create'} {...props} />,
    formType: ActionType.CREATE_INTERVENTION_TAG_INCLUSION,
  },
]
