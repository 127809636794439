import { PatrimonyWithAccountRequest } from 'services/patrimony/types'
import HttpClient from 'services/httpClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { endpoints } from './endpoints'

const updatePatrimony = async (payload: PatrimonyWithAccountRequest) => {
  const customerId = localStorage.getItem('customerId')
  const response = await HttpClient.put<PatrimonyWithAccountRequest>(
    endpoints.updatePatrimony,
    {
      ...payload,
      patrimony: {
        ...payload.patrimony,
        district: undefined,
        state: undefined,
        city: undefined,
        districtName: undefined,
        cityName: undefined,
        stateName: undefined,
        imageId: undefined,
        phone: {
          countryCode: 55,
          provinceCode: payload.patrimony.phone?.provinceCode,
          phoneNumber: payload.patrimony.phone?.phoneNumber,
        },
        customerId: payload.patrimony.customerId ?? customerId,
      },
      customerId: payload.patrimony.customerId ?? customerId,
      hasAccount: undefined,
    },
  )

  return response.data
}

export const usePutPatrimony = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: PatrimonyWithAccountRequest) => {
      return await updatePatrimony(payload)
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get-patrimony'] })
    },
  })
}
