import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'shared/hooks'
import { usePostConfigurePhotosensorCommand } from 'shared/hooks/services/commands'

export const usePostAndHandleConfigurePhotosensorCommand = () => {
  const { addToast } = useToast()

  const queryClient = useQueryClient()

  const {
    configurePhotosensorCommandMutationStatus,
    mutateConfigurePhotosensorCommand,
  } = usePostConfigurePhotosensorCommand()

  useEffect(() => {
    if (configurePhotosensorCommandMutationStatus === 'success') {
      addToast({
        message: 'Fotosensor configurado com sucesso.',
      })

      queryClient.invalidateQueries({ queryKey: ['equipments'] })
    }
    if (configurePhotosensorCommandMutationStatus === 'error') {
      addToast({
        message: 'Erro ao configurar um ou mais dados. Tente novamente.',
        type: 'alert',
      })
    }
  }, [addToast, configurePhotosensorCommandMutationStatus, queryClient])

  return {
    configurePhotosensorCommandMutationStatus,
    mutateConfigurePhotosensorCommand,
  }
}
