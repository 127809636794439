import { joiResolver } from '@hookform/resolvers/joi'
import { Breadcrumbs, ContainerScreen, Loader } from 'components'
import { AttendanceProfileForm } from 'domains/attendanceProfile/components/AttendanceProfileForm/AttendanceProfileForm'
import { usePostCoverage } from 'domains/attendanceProfile/hooks/services/coverages'
import { createAndUpdateAttendanceProfileSchema } from 'domains/attendanceProfile/schemas'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CoverageInputRequest } from 'services/coverages'
import { useToast } from 'shared/hooks'

export const CreateAttendanceProfile = () => {
  const navigate = useNavigate()
  const { addToast } = useToast()

  const { mutateCreateCoverage, statusCreateCoverage } = usePostCoverage()

  const form = useForm<CoverageInputRequest>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(createAndUpdateAttendanceProfileSchema),
  })

  const onSubmit = useCallback(
    (data: CoverageInputRequest) => {
      const { serviceTypes, ...rest } = data
      mutateCreateCoverage({
        ...rest,
        ...(serviceTypes?.length && {
          serviceTypeIds: serviceTypes.map((serviceType) => serviceType.id),
        }),
      })
    },
    [mutateCreateCoverage],
  )

  useEffect(() => {
    if (statusCreateCoverage === 'success') {
      addToast({ message: 'Perfil de atendimento cadastrado com sucesso.' })
      navigate(-1)
    }

    if (statusCreateCoverage === 'error') {
      addToast({
        message: 'Erro ao cadastrar perfil de atendimento. Tente novamente.',
        type: 'alert',
      })
    }
  }, [addToast, navigate, statusCreateCoverage])

  return (
    <>
      <Loader isVisible={statusCreateCoverage === 'pending'} />
      <ContainerScreen
        clickable
        divider={false}
        renderBreadcrumbs={
          <Breadcrumbs
            items={[
              { title: 'Home', href: '/' },
              {
                title: 'Perfis de atendimento',
                href: '/config/attendanceProfile?page=1',
              },
              { title: 'Novo perfil de atendimento', href: '#' },
            ]}
          />
        }
      >
        <FormProvider {...form}>
          <AttendanceProfileForm
            onGoBack={() => navigate(-1)}
            onSubmit={onSubmit}
          />
        </FormProvider>
      </ContainerScreen>
    </>
  )
}
