import { FC } from 'react'
import styles from './styles.module.scss'
import { OccurrenceDetails, Modals, History } from './containers'
import { FilterProvider, ModalsProvider } from './context'

const Attendance: FC = () => (
  <ModalsProvider>
    <FilterProvider>
      <main className={styles.container}>
        <Modals />
        <OccurrenceDetails />
        <History />
      </main>
    </FilterProvider>
  </ModalsProvider>
)

export default Attendance
