import { UseMutationResult, useMutation } from '@tanstack/react-query'

import { OfficeHoursDriver } from 'services/officeHours'
import { OfficeHoursPayload } from 'services/officeHours/types'

export type UpdateOfficeHoursRequest = {
  id: string
  payload: OfficeHoursPayload
}

function updateOfficeHours(request: UpdateOfficeHoursRequest): Promise<void> {
  const { id, payload } = request
  return OfficeHoursDriver.update(id, payload)
}

export type UsePutOfficeHours = UseMutationResult<
  void,
  unknown,
  UpdateOfficeHoursRequest,
  unknown
>

export const usePutOfficeHours = (): UsePutOfficeHours => {
  const mutation = useMutation({
    mutationFn: async (request: UpdateOfficeHoursRequest) => {
      await updateOfficeHours(request)
    },
  })

  return mutation
}
