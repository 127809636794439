import Joi from '@hapi/joi'

import { District } from 'services/address/district/types'

export const districtSchema = Joi.object<District>({
  id: Joi.string().uuid().allow('', null),
  cityId: Joi.string().uuid().allow('', null),
  name: Joi.string().allow('', null),
  createdAt: Joi.number().allow('', null),
  updatedAt: Joi.number().allow('', null),
}).unknown(true)
