import { useCallback, useMemo } from 'react'

import { handleDislocationLimitForm } from 'domains/attendancePolicy/utilities/Accordion/Accordion'

import { DISLOCATION_LIMIT } from 'domains/attendancePolicy/types'

import { RadioGroup } from 'components'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import { ConditionType } from 'services/attendancePolicy/types'

export const DislocationLimitForm = ({
  onChange,
  data,
}: ConditionOptionForm) => {
  const comboboxLabel = 'Limite de deslocamento'

  const { facts } = data

  const checkValue = useCallback(
    (value: boolean | undefined): DISLOCATION_LIMIT | null => {
      if (value === undefined) return null

      return value ? DISLOCATION_LIMIT.REACHED : DISLOCATION_LIMIT.NOT_REACHED
    },
    [],
  )

  const selectedDislocation = useMemo(
    () => checkValue(facts?.travelLimitReached),
    [facts, checkValue],
  )

  return (
    <RadioGroup title={comboboxLabel}>
      {Object.values(DISLOCATION_LIMIT).map((option, key) => (
        <RadioButton
          value={option}
          onChange={(e) => {
            const value = e.target.value as DISLOCATION_LIMIT
            const status = handleDislocationLimitForm(value)

            onChange({
              type: ConditionType.ACCOUNT_TRAVEL_LIMIT,
              facts: {
                travelLimitReached: status.label === DISLOCATION_LIMIT.REACHED,
              },
            })
          }}
          checked={selectedDislocation === option}
          name={`dislocationLimitStatus-${data.id}`}
          key={key}
        />
      ))}
    </RadioGroup>
  )
}
