import { PARAMETERS_POLICY_EN_PT } from 'domains/attendancePolicy/types'
import { Parameters } from 'services/attendancePolicy/types'

export const parametersKeys: Record<string, keyof Parameters> = {
  [PARAMETERS_POLICY_EN_PT.ALLOW_POLICE_CALL]: 'phoneCallToPoliceEnabled',

  [PARAMETERS_POLICY_EN_PT.ALLOW_VEHICLE_DISPLACEMENT]: 'vehicleTravelEnabled',

  [PARAMETERS_POLICY_EN_PT.ALLOW_INCIDENT_COMPLETION_WITHOUT_BINDING_INTERVENTION]:
    'finishOccurrenceWithoutPhoneCallEnabled',
  [PARAMETERS_POLICY_EN_PT.FINISH_OCCURRENCE_BY_DUTY_ENABLED]:
    'finishOccurrenceByDutyEnabled',
}

export function removeParentheses(inputString: string): string {
  const regex = / \(\d+\)/

  const result = inputString.replace(regex, '')

  return result
}
