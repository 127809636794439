import { useEffect, useRef, useState } from 'react'

import classes from './Droplist.module.scss'

import { useOnClickOutside } from '../../../shared/hooks/useOnClickOutside'
import { RowAction } from './RowActions'

import ReactDOM from 'react-dom'

interface DroplistProps<T> {
  item: T
  isVisible: boolean
  onClose(): void
  actions: RowAction<T>[]
  actionPosition: {
    bottom: number
    top: number
    right: number
  }
}

interface DropListPosition {
  top: number
  left: number
}

export function Droplist<T>(props: DroplistProps<T>): JSX.Element {
  const { item, isVisible, onClose, actions, actionPosition } = props
  const dropListRef = useRef<HTMLDivElement>(null)
  const [droplistPosition, setDroplistPosition] = useState<DropListPosition>()

  useOnClickOutside(dropListRef, onClose)

  // calculates droplist's position based in action's position
  useEffect(() => {
    if (isVisible && dropListRef.current) {
      const dropListWidth = dropListRef.current.clientWidth
      const dropListHeight = dropListRef.current.clientHeight
      const canBeAtBottom =
        window.innerHeight > actionPosition.bottom + dropListHeight

      setDroplistPosition({
        top: canBeAtBottom
          ? actionPosition.bottom
          : actionPosition.top - dropListHeight,
        left: actionPosition.right - dropListWidth,
      })
    }

    return () => {
      setDroplistPosition(undefined)
    }
  }, [isVisible, actionPosition])

  const content = (
    <>
      {isVisible && (
        <div
          data-testid="droplist"
          className={classes.droplistContent}
          ref={dropListRef}
          style={{
            opacity: droplistPosition ? 1 : 0,
            top: droplistPosition?.top || 0,
            left: droplistPosition?.left || 0,
          }}
        >
          <RowAction item={item} actions={actions} hasMoreThanOneAction />
        </div>
      )}
    </>
  )

  const root = document.getElementById('root') as HTMLElement
  return root ? ReactDOM.createPortal(content, root) : content
}
