import { Calendar, Icon } from 'components'

import styles from './styles.module.scss'

const EmptyState = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.info}>
          <Icon name="information" color="yellow" />
          <p>
            Este técnico ainda não possui nenhuma ordem de serviço agendada.
          </p>
        </div>
        <Calendar height="calc(100vh - 331px)" events={[]} />
      </div>
    </div>
  )
}

export default EmptyState
