import { TacticalStatus } from 'services/displacementMap/types'

type Option = {
  label: string
  value: TacticalStatus
}

export const statusOptions: Option[] = [
  {
    label: 'Sem comunicação',
    value: 'WITHOUT_COMMUNICATION',
  },
  {
    label: 'Ligada',
    value: 'COMMUNICATION_ON',
  },
  {
    label: 'Desligada',
    value: 'COMMUNICATION_OFF',
  },
  {
    label: 'Em movimento',
    value: 'DISPLACEMENT',
  },
]

export const displacementOptions = [
  'Iniciado',
  'Visualizado',
  'No local',
  'Em espera',
]

export const occurrenceTypesOptions = [
  'Alarme',
  'Resgate',
  'Falha',
  'Concluído',
  'Iminente',
  'Emergência',
  'Comercial',
  'Manual',
  'Periódica',
]
