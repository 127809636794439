import { ReactNode } from 'react'

import { UbideskPermissions } from 'routes/types'
import { Icon } from 'components'

export interface NavItem {
  title: string
  path: string
  icon?: ReactNode
  subItems?: NavItem[]
  permission: UbideskPermissions[]
}

export const sidebarData: NavItem[] = [
  {
    title: 'Contas',
    path: '/account/list',
    permission: [UbideskPermissions.ACCOUNT],
    icon: <Icon name="customer" color="element" width={20} height={20} />,
  },
  {
    title: 'Ocorrências',
    path: '/occurrence',
    permission: [UbideskPermissions.ACCOUNT],
    icon: <Icon name="occurrence" color="element" width={20} height={20} />,
    subItems: [
      {
        title: 'Atendimento',
        path: '/occurrence/search',
        permission: [UbideskPermissions.ACCOUNT],
      },
      {
        title: 'Gestão de ocorrências',
        path: '/occurrence/management',
        permission: [UbideskPermissions.ACCOUNT],
      },
      {
        title: 'Mapa de deslocamento',
        path: '/occurrence/displacementMap',
        permission: [UbideskPermissions.ACCOUNT],
      },
      {
        title: 'Listagem geral',
        path: '/occurrence/list',
        permission: [UbideskPermissions.ACCOUNT],
      },
    ],
  },
  {
    title: 'Ordens de Serviço',
    path: '/so',
    permission: [UbideskPermissions.SERVICE_ORDER],
    icon: <Icon name="service-order" color="element" width={20} height={20} />,
    subItems: [
      {
        title: 'Cadastro',
        path: '/so/new',
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'CadastroV2',
        path: '/soV2/create',
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Listagem geral',
        path: '/so/search',
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Por técnico',
        path: '/so/inProgressServiceOrders',
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Aguardando agendamento',
        path: '/so/unscheduled',
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Aguardando validação',
        path: '/so/in_validation',
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Bloqueio de agenda',
        path: '/so/calendarBlocking?page=1',
        permission: [
          UbideskPermissions.SERVICE_ORDER,
          UbideskPermissions.SO_TECHNICIAN_SCHEDULE_PAUSE,
        ],
      },
    ],
  },
  {
    title: 'Configurações',
    path: '/config',
    permission: [UbideskPermissions.CONFIG],
    icon: <Icon name="settings" color="element" width={20} height={20} />,
    subItems: [
      {
        title: 'Gestão de usuários',
        path: '/config/userManagement',
        permission: [
          UbideskPermissions.CONFIG_USER,
          UbideskPermissions.CONFIG_USER_WRITE,
        ],
      },
      {
        title: 'Viaturas',
        path: '/config/vehicles?page=1',
        permission: [UbideskPermissions.CONFIG_VEHICLE],
      },
      {
        title: 'Perfis de atendimento',
        path: '/config/attendanceProfiles?page=1',
        permission: [UbideskPermissions.CONFIG_ATTENDANCE_PROFILE],
      },
      {
        title: 'Regras de atendimento',
        path: '/config/attendancePolicy?page=1',
        permission: [UbideskPermissions.CONFIG_ATTENDANCE_POLICY],
      },
      {
        title: 'Feriados',
        path: '/config/holiday?page=1',
        permission: [UbideskPermissions.CONFIG_HOLIDAY],
      },
    ],
  },
]
