import { ReactComponent as IconAlert } from 'assets/svg/exclamation.svg'

import styles from './ToastPatrimonyError.module.scss'

const typeError = {
  NAME: 'NAME',
  ADDRESS: 'ADDRESS',
  ALL: 'ALL',
}

export type TypeError = keyof typeof typeError

const typeErrorLabel: Record<TypeError, string> = {
  NAME: 'nome',
  ADDRESS: 'endereço',
  ALL: 'nome e endereço',
}

type ToastPatrimonyError = {
  type: TypeError
}

export const ToastPatrimonyError = ({ type }: ToastPatrimonyError) => {
  return (
    <div className={styles.toast}>
      <IconAlert />
      <p>Já existe um patrimônio cadastrado com este {typeErrorLabel[type]}.</p>
    </div>
  )
}
