import { CityWithAcronym } from 'services/address/city/types'
import { State } from 'services/address/state'

export const holidayCoverageType = {
  REGIONAL: 'REGIONAL',
  NATIONAL: 'NATIONAL',
}

export type HolidayCoverageType = keyof typeof holidayCoverageType

export type holidayCoverage = {
  states: string[]
  cities: string[]
}

export type HolidayQueryResponse = {
  id: string
  name: string
  repeatable: boolean
  day: number
  month: number
  year?: number
  coverageType: HolidayCoverageType
  coverage?: {
    states: string[]
    cities: string[]
  }
}

export type HolidayQueryRequest = {
  name?: string
  coverageType?: HolidayCoverageType
  repeatable?: boolean
  createdFrom?: number
  createdTo?: number
  sort?: string
  sortType?: string
  offset: number
  recordsPerPage: number
}

export type CoverageHolidayEntities = {
  states: State[]
  cities: CityWithAcronym[]
}

export type coverageHolidayEntitiesKeys = keyof CoverageHolidayEntities

export type Coverage = {
  states?: string[]
  cities?: string[]
}

export type HolidayPayload = {
  name: string
  repeatable: boolean
  day: number
  month: number
  year?: number
  coverageType: HolidayCoverageType
  coverage?: Coverage
}

export type AggregatedHolidayResponse = {
  id: string
  name: string
  repeatable: boolean
  day: number
  month: number
  year?: number
  coverageType: HolidayCoverageType
  coverage?: {
    states: State[]
    cities: CityWithAcronym[]
  }
  createdAt: number
  updatedAt: number
}
