import { Result } from 'services/types'

const DEFAULT_RECORDS_PER_PAGE = 15

export function getNextPageOffSet<T>(
  lastPage: Result<T>,
  allPages: Result<T>[],
): number | undefined {
  const totalElementsFromFetchedPages = allPages
    .map((page) => page.data.length)
    .reduce((prev, cur) => prev + cur)

  const pages = Math.ceil(
    totalElementsFromFetchedPages / DEFAULT_RECORDS_PER_PAGE,
  )

  if (totalElementsFromFetchedPages < lastPage.totalElements)
    return pages * DEFAULT_RECORDS_PER_PAGE

  return undefined
}
