import { ReactComponent as ToastFailIcon } from 'assets/svg/toastFail.svg'
import { ReactComponent as ToastSuccessIcon } from 'assets/svg/toastSuccess.svg'
import { ReactComponent as ToastInformationIcon } from 'assets/svg/toastInformative.svg'
import { ToastType } from 'components/Toast/types'

export type ToastStyleProps = {
  [k in ToastType]: {
    icon: JSX.Element
    style: string
  }
}

export const ToastStyleProps: ToastStyleProps = {
  success: {
    icon: <ToastSuccessIcon />,
    style: 'success',
  },
  alert: {
    icon: <ToastFailIcon />,
    style: 'alert',
  },
  informative: {
    icon: <ToastInformationIcon />,
    style: 'informative',
  },
}
