import Joi from '@hapi/joi'
import { PatrimonyFragment, PatrimonyPayload } from 'services/patrimony/types'

export const patrimonySchema = Joi.object<PatrimonyFragment>({
  id: Joi.string().uuid(),
  name: Joi.string(),
  accountId: Joi.string().uuid(),
  accountCode: Joi.string(),
})

export const patrimonyPayloadSchema = Joi.object<PatrimonyPayload>({
  name: Joi.string().required(),
  number: Joi.string().required(),
  address: Joi.string().required(),
  postalCode: Joi.string().required(),
  districtId: Joi.string().uuid().required(),
  note: Joi.string().optional().allow(null, ''),
  state: Joi.string().optional().allow(null, ''),
  adjunct: Joi.string().optional().allow(null, ''),
  cityName: Joi.string().optional().allow(null, ''),
  id: Joi.string().uuid().optional().allow(null, ''),
  reference: Joi.string().max(100).optional().allow(null, ''),
  stateName: Joi.string().optional().allow(null, ''),
  city: Joi.string().uuid().optional().allow(null, ''),
  districtName: Joi.string().optional().allow(null, ''),
  customerId: Joi.string().uuid().optional().allow(null, ''),
  responsible: Joi.string().optional().allow(null, ''),
  imageId: Joi.string().uuid().optional().allow(null, ''),
  phone: Joi.object({
    countryCode: Joi.number().optional().allow(null, ''),
    provinceCode: Joi.string().optional().allow(null, ''),
    phoneNumber: Joi.string().optional().allow(null, ''),
  }).optional(),
})
