import { FC, ReactNode } from 'react'

import styles from './ContainerScreen.module.scss'

type ContainerScreenProps = {
  clickable: boolean
  title?: string
  subtitle?: string
  renderFilters?: ReactNode
  renderBreadcrumbs?: ReactNode
  divider?: boolean
  children: ReactNode
}

export const ContainerScreen: FC<ContainerScreenProps> = ({
  title,
  subtitle,
  clickable,
  renderFilters,
  renderBreadcrumbs,
  divider = true,
  children,
}) => {
  return (
    <div
      className={[styles.container, !clickable && styles.noClickable].join(' ')}
    >
      {renderBreadcrumbs ? (
        <div className={styles.breadcrumbsCointainer}>{renderBreadcrumbs}</div>
      ) : (
        <h1 className={styles.title}>{title}</h1>
      )}
      {divider && (
        <div
          className={[
            styles.divider,
            !subtitle && styles.dividerMarginBottom,
          ].join(' ')}
        />
      )}
      {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
      {renderFilters && (
        <div className={styles.filtersWrapper}>{renderFilters}</div>
      )}
      {children}
    </div>
  )
}
