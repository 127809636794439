import { ReactNode, useCallback, useEffect, ComponentProps } from 'react'

interface BackDropProps extends ComponentProps<'div'> {
  onClose: () => void
  children: ReactNode
}

export const BackDrop = ({ onClose, ...props }: BackDropProps) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return <div {...props} />
}
