import * as Joi from '@hapi/joi'
import { FormPayload } from './types'
import { InterventionStatus } from 'services/attendance/types'
import { TagsType } from 'domains/occurrence/screens/Attendance/types'

export const interventionStatusLabel: Record<InterventionStatus, string> = {
  FAILURE: 'Falha',
  SUCCESS: 'Sucesso',
  PENDING: 'Pendente',
}

const interventionTypeTags: Record<string, TagsType> = {
  safecall: 'SAFE_CALL',
  emObservacao: 'QUEUED',
  deslocamento: 'TRAVEL',
  finalizada: 'FINISHED',
  disponivel: 'AVAILABLE',
  sendImage: 'SEND_IMAGE',
  envioMensagem: 'SEND_MESSAGE',
  ligacaoPolicia: 'POLICE_CALL',
  ligacaoContato: 'CONTACT_CALL',
  registroManual: 'MANUAL_REGISTER',
  verificacaoImagem: 'IMAGE_VERIFICATION',
  intrusaoConfirmada: 'INTRUSION_CONFIRMED',
}

export const handleInterventionTag = (tags?: { name: string }[]): TagsType => {
  const tag = tags?.find((tag) =>
    Object.keys(interventionTypeTags).includes(tag.name),
  )?.name

  return tag ? interventionTypeTags[tag] : 'QUEUED'
}

export const schema = Joi.object<FormPayload>({
  tags: Joi.array(),
  attributes: Joi.object(),
  note: Joi.string().required(),
  predefinedNote: Joi.string().optional(),
})
