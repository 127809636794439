import { useMutation } from '@tanstack/react-query'
import { HolidayDriver } from 'services/holiday/holiday.driver'

async function deleteHoliday(id: string): Promise<void> {
  await HolidayDriver.delete(id)
}

export const useDeleteHoliday = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      await deleteHoliday(id)
    },
  })
}
