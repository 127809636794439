import { useCallback, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { OwnerName, useGetImage } from 'services/image'
import { ErrorState, Icon, ProtectedImage } from 'components'
import { handleRenderState } from 'utilities/handleRenderState'

import { useModal } from '../../../../../../context'
import Loading from './Loading'
import styles from './styles.module.scss'

const Gallery = () => {
  const { handleOpenModal, handleCloseModal } = useModal()
  const { serviceOrderId } = useParams()

  const { data, isLoading, isError } = useGetImage({
    query: {
      owners: `${OwnerName.ServiceOrder}_${serviceOrderId}`,
      sortType: 'desc',
    },
  })

  const [showScrollButtons, setShowScrollButtons] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleScroll = (direction: 'left' | 'right') => {
    if (ref.current) {
      const SCROLL_AMOUNT = 300
      ref.current.scrollBy({
        left: direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT,
        behavior: 'smooth',
      })
    }
  }

  const checkScrollButtonsVisibility = useCallback(() => {
    if (ref.current) {
      const containerWidth = ref.current.offsetWidth
      const contentWidth = ref.current.scrollWidth
      setShowScrollButtons(contentWidth > containerWidth)
    }
  }, [ref])

  return (
    <section className={styles.gallery}>
      {
        {
          view: !!data?.data.length && (
            <>
              <h3>Galeria de fotos</h3>
              <div ref={ref} onMouseEnter={checkScrollButtonsVisibility}>
                {data?.data.map((image) => (
                  <ProtectedImage
                    key={image.id}
                    imageId={image.id}
                    onClick={() =>
                      handleOpenModal({
                        type: 'GALLERY',
                        data: {
                          imageId: image.id,
                          onClose: handleCloseModal,
                          images: data?.data || [],
                        },
                      })
                    }
                  />
                ))}
              </div>

              {showScrollButtons && (
                <>
                  <button onClick={() => handleScroll('left')}>
                    <Icon name="chevron-sm-left" color="gray" />
                  </button>
                  <button onClick={() => handleScroll('right')}>
                    <Icon name="chevron-sm-right" color="gray" />
                  </button>
                </>
              )}
            </>
          ),
          loading: <Loading />,
          error: <ErrorState />,
        }[handleRenderState({ data, isLoading, isError })]
      }
    </section>
  )
}

export default Gallery
