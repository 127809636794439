import {
  createContext,
  MouseEvent,
  ComponentProps,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  useLayoutEffect,
} from 'react'
import joinClassNames from 'utilities/joinClassNames'

import styles from './styles.module.scss'

type WizardContextType = {
  active: string
  setActive: (index: string) => void
}

const WizardContext = createContext<WizardContextType | undefined>(undefined)

interface RootProps extends ComponentProps<'div'> {
  value?: string
  defaultValue: string
  setValue?: Dispatch<SetStateAction<string>>
}

const Root = ({
  children,
  className,
  value,
  setValue,
  defaultValue,
  ...props
}: RootProps) => {
  const [active, setActive] = useState(defaultValue)

  return (
    <WizardContext.Provider
      value={{
        active: value || active,
        setActive: (value) => {
          if (setValue) {
            setValue?.(value)
          } else {
            setActive(value)
          }
        },
      }}
    >
      <div className={joinClassNames(styles.container, className)} {...props}>
        {children}
      </div>
    </WizardContext.Provider>
  )
}

const List = ({ className, ...props }: ComponentProps<'div'>) => (
  <div className={joinClassNames(styles.list, className)} {...props} />
)

interface TriggerProps extends ComponentProps<'button'> {
  value: string
}

const Trigger = ({
  children,
  className,
  value,
  onClick,
  ...props
}: TriggerProps) => {
  const ref = useRef<HTMLButtonElement>(null)
  const context = useContext(WizardContext)

  if (!context) {
    throw new Error('Trigger must be used within a Wizard provider')
  }

  const { active, setActive } = context
  const [index, setIndex] = useState<number>(0)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setActive(value)
    onClick?.(event)
  }

  useLayoutEffect(() => {
    if (ref.current) {
      const parentElements = ref.current.parentElement?.children
      const siblings = Array.from(parentElements || [])
      const currentIndex = siblings.indexOf(ref.current)

      setIndex(currentIndex)
    }
  }, [])

  return (
    <button
      ref={ref}
      className={joinClassNames(
        styles.wizard,
        active === value && styles.active,
        className,
      )}
      onClick={handleClick}
      {...props}
    >
      {`${index + 1}. ${children}`}
    </button>
  )
}

interface ContentProps extends ComponentProps<'div'> {
  value: string
}

export const Content = ({ value, ...props }: ContentProps) => {
  const context = useContext(WizardContext)

  if (!context) {
    throw new Error('WizardPanel must be used within a Wizard provider')
  }

  const { active } = context

  return active === value && <div {...props} />
}

const WizardComponent = {
  Root,
  List,
  Trigger,
  Content,
}

export default WizardComponent
