import { AxiosInstance, AxiosResponse } from 'axios'
import HttpClient from '../httpClient'

import {
  CreateStationRequest,
  StationAggregatedQueryResponse,
  StationQuery,
  StationTechnician,
  StationTechnicianQuery,
  UpdateStationRequest,
  AggregatedStationResponse,
} from './types'
import { Result } from '../types'
import { LoginLogoutOperator, Station } from '.'

export interface StationDriver {
  create(station: CreateStationRequest): Promise<void>
  update(id: string, station: UpdateStationRequest): Promise<void>
  findById(id: string): Promise<AggregatedStationResponse>
  queryTechnicians(
    query: StationTechnicianQuery,
  ): Promise<Result<StationTechnician>>
  logonOperator(
    stationId: string,
    payload: LoginLogoutOperator,
  ): Promise<Station>
  logoffOperator(stationId: string, payload: LoginLogoutOperator): Promise<void>
}

export class StationDriverImpl implements StationDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async create(data: CreateStationRequest): Promise<void> {
    return this.httpClient.post('/station/create', data)
  }

  public async update(id: string, data: CreateStationRequest): Promise<void> {
    return this.httpClient.put(`/station/${id}/update`, data)
  }

  public async findById(id: string): Promise<AggregatedStationResponse> {
    return this.httpClient
      .get<AggregatedStationResponse>(`/station/${id}/find`)
      .then((res) => res.data)
  }

  public async query(
    query: StationQuery,
  ): Promise<Result<StationAggregatedQueryResponse>> {
    const result = await this.httpClient.get<
      Result<StationAggregatedQueryResponse>
    >(`/station/query`, {
      params: query,
    })

    return result.data
  }

  public async queryTechnicians(
    query: StationTechnicianQuery,
  ): Promise<Result<StationTechnician>> {
    return this.httpClient
      .get<Result<StationTechnician>>(
        `/station/aggregation/technicians/query`,
        {
          params: query,
        },
      )
      .then((res) => res.data)
  }

  public async delete(stationId: string): Promise<AxiosResponse<void>> {
    return this.httpClient.delete(`/station/${stationId}`)
  }

  public async logonOperator(
    stationId: string,
    payload: LoginLogoutOperator,
  ): Promise<Station> {
    const result = await this.httpClient.post<Station>(
      `/station/${stationId}/logon`,
      payload,
    )
    return result.data
  }

  public async logoffOperator(
    stationId: string,
    payload: LoginLogoutOperator,
  ): Promise<void> {
    return this.httpClient.post(`/station/${stationId}/logoff`, payload)
  }
}

export const StationDriver = new StationDriverImpl()
