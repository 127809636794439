import { AggregatedAccount } from 'services/account/types'

import { SortedCustomer } from 'services/customer/types'
import { OccurrenceTypeQueryResponse } from 'services/occurrence/types'

import { ServiceType } from 'services/serviceType/types'
import { Tag } from 'components/Tags/types'
import { ActionType } from 'services/attendancePolicy/types'
import {
  ActionOptionForm,
  ConditionOptionForm,
  TriggerOptionForm,
} from 'domains/attendancePolicy/components/RuleForm/types'
import { FunctionComponent, SVGProps, FC, ReactNode } from 'react'

export type AttendancePolicyFilter = {
  name: string
  serviceType: ServiceType
  occurrenceType: OccurrenceTypeQueryResponse
  active: boolean
  hasSelectors: boolean
  customer: SortedCustomer
  account: AggregatedAccount
  createdFrom: number
  createdTo: number
}

export type Coverage = {
  id?: string
  name: string
  type: 'Cliente' | 'Conta'
  precedence: {
    customer?: {
      id: string
      name: string
    }
    patrimony?: {
      id: string
      name: string
    }
    account?: {
      id: string
      name: string
    }
  }
}

export enum PARAMETERS_POLICY_EN_PT {
  ALLOW_POLICE_CALL = 'Permitir ligação para a polícia',
  ALLOW_VEHICLE_DISPLACEMENT = 'Permitir deslocamento de viatura',
  ALLOW_INCIDENT_COMPLETION_WITHOUT_BINDING_INTERVENTION = 'Permitir finalização de ocorrência sem intervenção de ligação',
  FINISH_OCCURRENCE_BY_DUTY_ENABLED = 'Permitir finalização de ocorrência pelo agente tático',
}

export enum ATTENDANCE_POLICY_STATUS {
  ACTIVE = 'Ativa',
  INACTIVE = 'Inativa',
}

export enum COVERAGE_TYPE {
  ALL = 'Geral',
  PERSONALIZED = 'Personalizada',
}

export type CoverageType = {
  value: boolean | undefined
  label: COVERAGE_TYPE
}

export enum SERVICE_TYPE {
  ALARM = 'ALARM',
  CCTV = 'CCTV',
  VEHICLE_MONITORING = 'VEHICLE_MONITORING',
}

export enum SERVICE_TYPE_EN_PT {
  ALARM = 'Alarme',
  CCTV = 'CFTV',
  VEHICLE_MONITORING = 'Monitoramento veicular',
}

export enum SERVICE_TYPE_PT_EN {
  'Alarme' = SERVICE_TYPE.ALARM,
  'CFTV' = SERVICE_TYPE.CCTV,
  'Monitoramento veicular' = SERVICE_TYPE.VEHICLE_MONITORING,
}

export type AttendancePolicyStatus = {
  value: boolean | undefined
  label: ATTENDANCE_POLICY_STATUS
}

export enum OCCURRENCE_TYPE {
  RESCUE = 'RESCUE',
  EMERGENCY = 'EMERGENCY',
  ALARM = 'ALARM',
  IMMINENT = 'IMMINENT',
  FAILURE = 'FAILURE',
  COMMERCIAL = 'COMMERCIAL',
  MANUAL = 'MANUAL',
  SCHEDULED = 'SCHEDULED',
}

export enum OCCURRENCE_TYPE_EN_PT {
  RESCUE = 'Resgate',
  EMERGENCY = 'Emergência',
  ALARM = 'Alarme',
  IMMINENT = 'Iminência',
  FAILURE = 'Falha',
  COMMERCIAL = 'Comercial',
  MANUAL = 'Manual',
  SCHEDULED = 'Periódica',
}

export enum OCCURRENCE_TYPE_PT_EN {
  'Resgate' = OCCURRENCE_TYPE.RESCUE,
  'Emergência' = OCCURRENCE_TYPE.EMERGENCY,
  'Alarme' = OCCURRENCE_TYPE.ALARM,
  'Iminência' = OCCURRENCE_TYPE.IMMINENT,
  'Falha' = OCCURRENCE_TYPE.FAILURE,
  'Comercial' = OCCURRENCE_TYPE.COMMERCIAL,
  'Manual' = OCCURRENCE_TYPE.MANUAL,
  'Periódica' = OCCURRENCE_TYPE.SCHEDULED,
}

export type AttendancePolicyRouteStateProps = {
  attendancePolicyId?: string
}

export enum GROUPED_TRIGGER_TYPE {
  ARRIVAL = 'ARRIVAL',
  TIME = 'TIME',
  CLOSING = 'CLOSING',
  INTERVENTION = 'INTERVENTION',
}

export enum GROUPED_TRIGGER_TYPE_EN_PT {
  'ARRIVAL' = 'Chegada',
  'CLOSING' = 'Fechamento',
  'TIME' = 'Tempo',
  'INTERVENTION' = 'Intervenção',
}

export enum ARRIVED_TYPE {
  OCCURRENCE = 'OCCURRENCE',
  EVENT = 'EVENT',
}

export enum ARRIVED_TYPE_EN_PT {
  'OCCURRENCE' = 'Ocorrência',
  'EVENT' = 'Evento',
}

export enum TIME_UNIT_EN_PT {
  SECONDS = 'Segundos',
  MINUTES = 'Minutos',
  HOURS = 'Horas',
  DAYS = 'Dias',
}

export type PolicyDetail = {
  title: string
  description: string
}

export type Policy = {
  index?: number
  title: string | ReactNode
  subtitle?: string | ReactNode
  details?: PolicyDetail[]
}

export enum ConditionOption {
  OCCURRENCE_STATE_NAMES = 'OCCURRENCE_STATE_NAMES',
  OCCURRENCE_HAS_EVENTS = 'OCCURRENCE_HAS_EVENTS',
  OCCURRENCE_EVENTS_MITIGATION = 'OCCURRENCE_EVENTS_MITIGATION',
  TAGS = 'TAGS',
  ACCOUNT_TRAVEL_LIMIT = 'ACCOUNT_TRAVEL_LIMIT',
  SERVICE_ORDER = 'SERVICE_ORDER',
  CENTRAL_PARTITIONS_STATUS = 'CENTRAL_PARTITIONS_STATUS',
  DEVICE_QUANTITY = 'DEVICE_QUANTITY',
  SERVICE_TYPE = 'SERVICE_TYPE',
  OCCURRENCE_FINISHED_MANUALLY = 'OCCURRENCE_FINISHED_MANUALLY',
  EVENT_IMAGE_DETECTIONS = 'EVENT_IMAGE_DETECTIONS',
}

export type ConditionRuleOption = {
  id: string
  title: string
  description: string
  formType: ConditionOption
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
}

export type ActionRuleOption = {
  id: string
  title: string
  description: string
  formType?: ActionType
  icon: FunctionComponent<SVGProps<SVGSVGElement>> | Element
}

export type PolicyGroup = {
  allSubPolicies: boolean
  policies: Policy[]
}

export enum SERVICE_ORDER {
  TYPE_OF = 'Do tipo',
  HAS_TAG = 'Contém tag',
}

export type ServiceOrder = {
  value: string | undefined
  label: SERVICE_ORDER
}

export enum DISLOCATION_LIMIT {
  REACHED = 'Atingido',
  NOT_REACHED = 'Não atingido',
}

export type DislocationLimit = {
  value: string | undefined
  label: DISLOCATION_LIMIT
}

export enum EVENT {
  MITIGATED = 'Mitigado',
  NOT_MITIGATED = 'Não mitigado',
}

export type Event = {
  value: string | undefined
  label: EVENT
}

export type accordionForm = {
  title?: string
  subtitle?: string
  tags?: Tag[]
  accordionTitle?: string
}

export type RuleConditionFormComponent = {
  [key: string]: FC<ConditionOptionForm>
}

export type RuleActionFormComponent = {
  [key: string]: FC<ActionOptionForm>
}

export type RuleTriggerFormComponent = {
  [key: string]: FC<TriggerOptionForm>
}

export enum AccordionType {
  Action = 'Ação',
  Condition = 'Condição',
}

export enum NOTIFICATION_TYPE {
  SOUND = 'SOUND',
}

export enum NOTIFICATION_TYPE_EN_PT {
  SOUND = 'Som',
}

export enum NOTIFICATION_TYPE_PT_EN {
  'Som' = NOTIFICATION_TYPE.SOUND,
}

export enum NOTIFICATION_STYLE {
  DEFAULT = 'DEFAULT',
}

export enum NOTIFICATION_STYLE_EN_PT {
  DEFAULT = 'Padrão',
}

export enum NOTIFICATION_STYLE_PT_EN {
  'Padrão' = NOTIFICATION_STYLE.DEFAULT,
}

export type Fragment = {
  id: string
  name: string
}

export type OccurrenceFinishTypes = 'Manual' | 'Automática'
