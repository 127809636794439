import Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  useCreateAgentDutyRecords,
  useGetAgentDutyRecords,
} from 'services/displacementMap'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { Button, Modal } from 'components'
import { BackDrop } from '../../../../components'
import { dateNow } from 'utilities/date'
import { useToast, useUserInfo } from 'shared/hooks'

import styles from './LogHistory.module.scss'
import { useMap, useDisplacementModal } from '../../../../contexts'

interface FormProps {
  note: string
}

export const schema = Joi.object<FormProps>({
  note: Joi.string().required(),
})

export const LogHistory = () => {
  const { selectedRoute } = useMap()
  const { userInfo } = useUserInfo()
  const { handleCloseModal } = useDisplacementModal()

  const { data } = useGetAgentDutyRecords(selectedRoute.tactical?.id)
  const { mutate } = useCreateAgentDutyRecords(selectedRoute.tactical?.id)
  const { addToast } = useToast()

  const {
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormProps>({
    mode: 'onChange',
    resolver: joiResolver(schema),
  })

  const onSubmit = (data: FormProps) => {
    const userId = userInfo.id
    const occurrenceId = selectedRoute.occurrence?.id

    if (userId && occurrenceId) {
      mutate(
        {
          userId,
          occurrenceId,
          description: data.note,
        },
        {
          onSuccess: handleCloseModal,
          onError: () =>
            addToast({
              message: 'Erro ao salvar log.',
              type: 'alert',
            }),
        },
      )
    }
  }

  return (
    <BackDrop onClose={handleCloseModal}>
      <Modal
        title="Histórico"
        isVisible
        className={styles.container}
        onClose={handleCloseModal}
      >
        <ul>
          {data ? (
            data
              .sort((prev, next) => prev.date + next.date)
              .map((record) => (
                <li key={record.date}>
                  <div>
                    <span>{dateNow(record.date)}</span> -{' '}
                    <span>{record.user || 'Sistema'}</span>
                  </div>
                  <span>{record.description}</span>
                </li>
              ))
          ) : (
            <span>Nenhum log registrado</span>
          )}
        </ul>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Combobox
            label={{ text: 'Observações' }}
            items={[]}
            placeholder="Selecione uma tratativa"
          />
          <textarea
            {...register('note')}
            onChange={(event) =>
              setValue('note', event?.target.value, { shouldValidate: true })
            }
          />
          <Modal.Footer>
            <Button
              type="secondary"
              buttonTitle="Cancelar"
              onClick={handleCloseModal}
            />
            <Button
              type="primary"
              htmlType="submit"
              buttonTitle="SALVAR LOG"
              disabled={!isValid}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </BackDrop>
  )
}
