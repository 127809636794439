import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import { useToast } from 'shared/hooks'

import { Loader } from 'components'

import { AttendPolicyFormGroup } from 'domains/attendancePolicy/components/AttendPolicyFormGroup/AttendPolicyFormGroup'
import { AttendancePolicyPayload } from 'services/attendancePolicy/types'
import { usePostAttendancePolicy } from 'shared/hooks/services/attendancePolicy/usePostAttendancePolicy'

export const CreateAttendancePolicy = () => {
  const navigate = useNavigate()
  const { addToast } = useToast()

  const { mutateCreateAttendancePolicy, statusCreateAttendancePolicy } =
    usePostAttendancePolicy()

  const onSubmit = useCallback(
    (payload: AttendancePolicyPayload) => {
      mutateCreateAttendancePolicy(payload, {
        onSuccess: () => {
          addToast({ message: 'Regra adicionada com sucesso.' })
          navigate(-1)
        },
        onError: () =>
          addToast({
            message: 'Erro ao adicionar regra. Tente novamente.',
            type: 'alert',
          }),
      })
    },
    [mutateCreateAttendancePolicy],
  )

  return (
    <>
      <Loader isVisible={statusCreateAttendancePolicy === 'pending'} />

      <AttendPolicyFormGroup onSubmit={onSubmit} />
    </>
  )
}
