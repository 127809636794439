import { ComponentProps } from 'react'
import joinClassNames from 'utilities/joinClassNames'

import Icon from '../Icon'
import styles from './styles.module.scss'

const ErrorState = ({ className, ...props }: ComponentProps<'div'>) => {
  return (
    <div className={joinClassNames(styles.container, className)} {...props}>
      <Icon name="screen-error" width={34} />
      <span>Algo deu errado</span>
      <span>Atualize a página e tente novamente.</span>
    </div>
  )
}

export default ErrorState
