import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { EventInput } from '@fullcalendar/core'
import { useForm } from 'react-hook-form'

import { BlockedSchedule, ContactItem } from './components'
import Select from 'components/Form/Select'
import {
  Datepicker,
  ComboBoxV3,
  FormLabel,
  TextField,
  Calendar,
  FormItem,
  Textarea,
  ButtonV2,
  Popover,
  Tooltip,
  Modal,
  Icon,
  Tag,
  RadioGroup,
} from 'components'

import { fetchTechnicians } from 'services/serviceOrder/hooks/useGetTechnicians'
import { TechnicianScheduleResponse } from 'services/technicianSchedule/types'
import { useValidateTechnicianSchedules } from 'services/technicianSchedule'
import {
  getServiceOrderTags,
  ServiceOrderPayload,
  useGetAccount,
} from 'services/serviceOrderV2'
import {
  DayShift,
  DayShiftLabel,
  ScheduleType,
  ScheduleTypeLabel,
} from './types'
import { formatTime } from 'domains/serviceOrders/utilities/mask/date'
import { ServiceOrderSchedule } from 'domains/serviceOrders/types'
import { POSITION } from 'shared/hooks/useElementPosition/types'
import { maskedInputDate, formatDecimal } from 'utilities/date'
import { useConfirmClose, useUserInfo } from 'shared/hooks'
import { WeekInterval } from 'components/Calendar/types'
import { ATTENDANCE_ROLE } from 'services/coverages'
import {
  ServiceOrderAggregatedQueryResponse,
  SERVICE_ORDER_STATUS_ENUM,
  useGetServiceOrders,
} from 'services/serviceOrder'
import { isServiceOrderReschedulable } from 'domains/serviceOrders/utilities'
import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'

import { ReactComponent as WarningIcon } from 'assets/svg/blockedWarning.svg'

import styles from './styles.module.scss'
import './ScheduleServiceOrderModal.css'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { checkScheduleOverlap } from './utils'

type ScheduleServiceOrderModalProps = {
  onClose: () => void
  onSave: (data: ServiceOrderPayload) => void
  serviceOrder: ServiceOrderSchedule
  readonly?: boolean
}

const MAX_EVENTS_PER_DATE = 6

function ScheduleServiceOrderModal({
  serviceOrder,
  readonly,
  onClose,
  onSave,
}: ScheduleServiceOrderModalProps): JSX.Element | null {
  const {
    formState: { isValid },
    handleSubmit,
    setValue,
    register,
    watch,
    reset,
  } = useForm<ServiceOrderPayload>({
    defaultValues: serviceOrder,
  })

  const { data: account } = useGetAccount(watch('account.id'))

  const { userInfo } = useUserInfo()

  const actualScheduleDate = watch('scheduleDate')
  const actualSchedulePeriod = watch('schedulePeriod')

  const [scheduleDateInput, setScheduleDateInput] = useState(
    actualSchedulePeriod ? maskedInputDate(actualSchedulePeriod) : '',
  )
  const [scheduleType, setScheduleType] =
    useState<ScheduleType>('BUSINESS_HOURS')
  const [scheduleDate, setScheduleDate] = useState<Date>()
  const [calendarWeekInterval, setCalendarWeekInterval] =
    useState<WeekInterval>()

  const [dayShift, setDayShift] = useState<DayShift>('MORNING')
  const [time, setTime] = useState('')

  const [newServiceOrder, setNewServiceOrder] = useState<EventInput | null>(
    null,
  )

  const technicianId = useMemo(
    () => watch('technician')?.id ?? serviceOrder?.technician?.id,
    [watch('technician'), serviceOrder],
  )

  const { data: serviceOrders } = useGetServiceOrders({
    ...(technicianId && { technicianIds: [technicianId] }),
    scheduleDateFrom:
      scheduleDate &&
      calendarWeekInterval?.startDate &&
      Math.min(scheduleDate.getTime(), calendarWeekInterval.startDate),
    scheduleDateTo:
      scheduleDate &&
      calendarWeekInterval?.endDate &&
      Math.max(scheduleDate.getTime(), calendarWeekInterval.endDate),
    status: [
      SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
      SERVICE_ORDER_STATUS_ENUM.RESCHEDULED,
      SERVICE_ORDER_STATUS_ENUM.FINISHED,
      SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
      SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION,
      SERVICE_ORDER_STATUS_ENUM.REPROVED,
      SERVICE_ORDER_STATUS_ENUM.PAUSED,
      SERVICE_ORDER_STATUS_ENUM.OPEN,
    ],
  })

  const currentServiceOrders: EventInput[] = useMemo(
    () =>
      serviceOrders?.data.map(
        (serviceOrder: ServiceOrderAggregatedQueryResponse) => ({
          id: serviceOrder?.id,
          title: serviceOrder.customer?.name || '',
          tags: serviceOrder.tags,
          allDay: !!serviceOrder?.schedulePeriod,
          start: new Date(serviceOrder?.scheduleDate),
          end:
            !serviceOrder?.schedulePeriod && serviceOrder?.scheduleDate
              ? new Date(serviceOrder?.scheduleDate).setHours(
                  new Date(serviceOrder?.scheduleDate).getHours() + 2,
                )
              : '',
          extendedProps: {
            schedulePeriod: serviceOrder?.schedulePeriod ?? undefined,
            status: serviceOrder?.status,
            number: serviceOrder?.number,
            accountCode: serviceOrder?.account?.code,
            customer: serviceOrder?.customer?.name,
          },
        }),
      ) || [],
    [serviceOrders],
  )

  const shouldConfirmClose =
    serviceOrder?.status === SERVICE_ORDER_STATUS_ENUM.OPEN &&
    (!!newServiceOrder?.extendedProps?.schedulePeriod ||
      (!!newServiceOrder?.start && !!newServiceOrder?.end))

  const { triedToClose, tooltipRef, tooltip } = useConfirmClose({
    onClose: () => {
      reset()
      onClose()
    },
    shouldConfirm: shouldConfirmClose,
  })

  const { data: technicianSchedule } = useValidateTechnicianSchedules(
    watch('technician')?.id || serviceOrder?.technician?.id,
  )

  const hasServiceOrderAttendantRole = useMemo(
    () =>
      userInfo &&
      userInfo.userCoverages?.primary?.some(
        (coverage) =>
          coverage.coverageType === ATTENDANCE_ROLE.SERVICE_ORDER_ATTENDANT,
      ),
    [userInfo],
  )

  const hasReachedMaxEvents = useMemo(() => {
    if (!scheduleDate) return false

    const selectedDateEvents = currentServiceOrders.filter(
      (event) =>
        event.start instanceof Date &&
        event.start.toDateString() === scheduleDate.toDateString(),
    )

    return selectedDateEvents.length >= MAX_EVENTS_PER_DATE
  }, [currentServiceOrders, scheduleDate])

  const isTechnicianScheduleBlocked = useMemo(() => {
    if (technicianSchedule && scheduleDate) {
      const [hours, minutes] = time.split(':')
      const chosenStartTime = new Date(scheduleDate)
      chosenStartTime.setHours(Number(hours))
      chosenStartTime.setMinutes(Number(minutes))
      const chosenEndTime = new Date(chosenStartTime)
      chosenEndTime.setHours(chosenEndTime.getHours() + 2)

      return technicianSchedule.some((block: TechnicianScheduleResponse) => {
        const blockStartDate = new Date(block.startDate)
        const blockEndDate = new Date(block.endDate)
        const isOverlap = checkScheduleOverlap(
          blockStartDate,
          blockEndDate,
          scheduleDate,
          scheduleType,
          chosenStartTime,
          chosenEndTime,
        )

        return isOverlap
      })
    }

    return false
  }, [technicianSchedule, scheduleDate, scheduleType, time])

  const tags = watch('tags')

  const allServiceOrders = useMemo(() => {
    if (newServiceOrder) {
      const filteredServiceOrders = currentServiceOrders.filter(
        (order) => order.id !== newServiceOrder.id,
      )
      return filteredServiceOrders.concat(newServiceOrder)
    }

    return currentServiceOrders
  }, [currentServiceOrders, newServiceOrder])

  useEffect(() => {
    setNewServiceOrder(null)
    if (!serviceOrder?.customer || !scheduleDate) return

    const currentStatus =
      serviceOrder?.status || SERVICE_ORDER_STATUS_ENUM.SCHEDULED

    const commonExtendedProps = {
      status: currentStatus,
      number: serviceOrder?.number,
      accountCode: serviceOrder?.account?.code,
      customer: serviceOrder?.customer?.name,
    }

    const scheduleTypes = {
      BUSINESS_HOURS: () => ({
        id: serviceOrder?.id,
        title: serviceOrder?.customer?.name,
        allDay: true,
        start: new Date(scheduleDate.toDateString()),
        extendedProps: {
          schedulePeriod: 'BUSINESS_HOURS',
          ...commonExtendedProps,
        },
      }),
      DAY_SHIFT: () => ({
        id: serviceOrder?.id,
        title: serviceOrder?.customer?.name,
        allDay: true,
        start: new Date(scheduleDate.toDateString()),
        extendedProps: {
          schedulePeriod: dayShift,
          ...commonExtendedProps,
        },
      }),
      FIXED_SCHEDULE: () => {
        const [hours, minutes] = time.split(':')
        const startDate = new Date(scheduleDate)
        startDate.setHours(Number(hours))
        startDate.setMinutes(Number(minutes))

        const endDate = new Date(startDate)
        endDate.setHours(endDate.getHours() + 2)

        return {
          id: serviceOrder?.id,
          title: serviceOrder?.customer?.name,
          start: startDate,
          end: endDate,
          extendedProps: {
            schedulePeriod: undefined,
            ...commonExtendedProps,
          },
        }
      },
    }

    const newEvent = scheduleTypes[scheduleType]?.()

    if (newEvent) {
      setNewServiceOrder(newEvent)
    }
  }, [watch, scheduleType, scheduleDate, time, dayShift])

  const handleChangeScheduleDate = useCallback((value: Date) => {
    setScheduleDate(value)
    setScheduleDateInput(convertDateToString(value))
  }, [])

  const handleChangeScheduleDateInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        const currentDate = new Date()

        const isGreaterThanTheCurrentDate =
          convertStringToDate(value) > currentDate

        isGreaterThanTheCurrentDate
          ? setScheduleDate(convertStringToDate(value))
          : setScheduleDate(undefined)
      } else {
        setScheduleDate(undefined)
      }
      setScheduleDateInput(maskedInputDate(value))
    },
    [],
  )

  const handleChangeScheduleType = useCallback((value: ScheduleType) => {
    setTime('')
    setDayShift('MORNING')

    setScheduleType(value)
  }, [])

  useEffect(() => {
    if (actualScheduleDate) {
      const scheduleDate = new Date(actualScheduleDate)
      handleChangeScheduleDate(scheduleDate)
      const scheduleHours = scheduleDate.getHours()
      const scheduleMinutes = scheduleDate.getMinutes()

      if (scheduleHours) {
        handleChangeScheduleType('FIXED_SCHEDULE')
        setTime(
          formatTime(
            `${formatDecimal(scheduleHours)}:${formatDecimal(scheduleMinutes)}`,
          ),
        )
      }

      const types = {
        BUSINESS_HOURS: () => handleChangeScheduleType('BUSINESS_HOURS'),
        MORNING: () => {
          handleChangeScheduleType('DAY_SHIFT')
          setDayShift('MORNING')
        },
        AFTERNOON: () => {
          handleChangeScheduleType('DAY_SHIFT')
          setDayShift('AFTERNOON')
        },
      }

      if (actualSchedulePeriod) {
        types[actualSchedulePeriod]?.()
      }
    }
  }, [
    actualScheduleDate,
    actualSchedulePeriod,
    handleChangeScheduleType,
    handleChangeScheduleDate,
  ])

  const onSubmit = useCallback(
    (data: ServiceOrderPayload) => {
      if (newServiceOrder && newServiceOrder?.extendedProps) {
        const { schedulePeriod } =
          newServiceOrder.extendedProps as ServiceOrderPayload
        setValue('schedulePeriod', schedulePeriod)
      }

      const scheduleDate = newServiceOrder?.start as Date
      scheduleDate?.setSeconds(0)
      scheduleDate?.setMilliseconds(0)

      setValue('scheduleDate', scheduleDate?.getTime())

      const isReschedulable =
        serviceOrder?.status &&
        !!serviceOrder.id &&
        isServiceOrderReschedulable(serviceOrder?.status)

      onSave({
        ...data,
        note: watch('note'),
        scheduleDate: Number(scheduleDate),
        schedulePeriod: watch('schedulePeriod'),
        status: isReschedulable
          ? SERVICE_ORDER_STATUS_ENUM.RESCHEDULED
          : SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
        contact: watch('contact'),
      })
    },
    [newServiceOrder, setValue, userInfo, watch],
  )

  const { type } = watch()

  const handleGetTechnicians = useCallback(
    async (searchFilter: string, offset = 0) =>
      await fetchTechnicians(
        serviceOrder.account.id,
        type,
        offset,
        searchFilter,
      ),
    [type, serviceOrder],
  )

  return (
    <Modal
      size="lg"
      isVisible
      title={
        readonly
          ? 'Agenda'
          : `Agendamento da OS ${
              serviceOrder?.number ? `#${serviceOrder?.number}` : ''
            }`
      }
      className="schedule-service-order-modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          {!readonly && (
            <div className={styles.left}>
              <FormItem>
                <FormLabel>Técnico responsável</FormLabel>
                <ComboBoxV3.Root
                  valueKey="name"
                  {...register('technician')}
                  findOptions={handleGetTechnicians}
                >
                  {({ unSelectedOptions }) => (
                    <>
                      <ComboBoxV3.Field value={watch('technician.name')} />
                      <ComboBoxV3.Group>
                        {unSelectedOptions?.map((option) => (
                          <ComboBoxV3.Option
                            key={option.id}
                            hasLabel={false}
                            onClick={() => {
                              setValue(
                                'technician',
                                {
                                  id: option.id,
                                  name: option.name,
                                },
                                {
                                  shouldValidate: true,
                                },
                              )
                            }}
                          >
                            {option.name}
                          </ComboBoxV3.Option>
                        ))}
                      </ComboBoxV3.Group>
                    </>
                  )}
                </ComboBoxV3.Root>
              </FormItem>

              {isTechnicianScheduleBlocked && (
                <Popover.Root>
                  <Popover.Trigger asChild>
                    <div className={styles.blockWarning}>
                      <WarningIcon />
                      <span>
                        A agenda do técnico possui bloqueios na semana
                      </span>
                    </div>
                  </Popover.Trigger>
                  <Popover.Content>
                    <BlockedSchedule
                      technician={serviceOrder?.technician?.name || ''}
                      technicianSchedule={technicianSchedule || []}
                    />
                  </Popover.Content>
                </Popover.Root>
              )}

              <FormItem>
                <FormLabel>Data do agendamento</FormLabel>
                <div className={styles.datepickerWrapper}>
                  <Datepicker
                    selectEnd
                    id="scheduleDateModal"
                    initialDate={new Date()}
                    {...register('scheduleDate')}
                    initialValueInput={scheduleDateInput}
                    onChangeDate={handleChangeScheduleDate}
                    onChangeInput={handleChangeScheduleDateInput}
                  />
                </div>
              </FormItem>

              <FormItem>
                <RadioGroup
                  title="Agendar em:"
                  customStyle={styles.scheduleTypes}
                >
                  {...Object.values(ScheduleTypeLabel).map((option, key) => (
                    <RadioButton
                      key={key}
                      name={'active'}
                      value={option.name}
                      checked={scheduleType === option.value}
                      onChange={() => {
                        handleChangeScheduleType(
                          ScheduleTypeLabel.find(
                            (label) => label.name === option.name,
                          )?.value || 'BUSINESS_HOURS',
                        )
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormItem>

              {scheduleType === 'DAY_SHIFT' && (
                <FormItem>
                  <FormLabel>Turno</FormLabel>
                  <Select.Root valueKey="name" data={DayShiftLabel}>
                    {({ unSelectedOptions }) => (
                      <>
                        <Select.Field
                          value={
                            DayShiftLabel.find(
                              (label) => label.value === dayShift,
                            )?.name
                          }
                        />
                        <Select.Group>
                          {unSelectedOptions?.map((option) => (
                            <Select.Option
                              key={option.name}
                              shouldCloseGroup={false}
                              onClick={() => setDayShift(option.value)}
                            >
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select.Group>
                      </>
                    )}
                  </Select.Root>
                </FormItem>
              )}

              {scheduleType === 'FIXED_SCHEDULE' && (
                <FormItem>
                  <FormLabel>Horário</FormLabel>
                  <TextField
                    value={time}
                    maxLength={5}
                    placeholder="00:00"
                    onChange={(event) =>
                      setTime(formatTime(event.target.value))
                    }
                  />
                </FormItem>
              )}

              <FormItem>
                <FormLabel>Observações</FormLabel>
                <Textarea
                  rows={10}
                  maxLength={5000}
                  className={styles.note}
                  value={watch('note') || ''}
                  onChange={(note) =>
                    setValue('note', note.target.value, {
                      shouldValidate: true,
                    })
                  }
                />
              </FormItem>

              <FormItem>
                <FormLabel>Tags</FormLabel>
                <ComboBoxV3.Root
                  valueKey="name"
                  {...register('tags')}
                  findOptions={getServiceOrderTags}
                >
                  {({ unSelectedOptions }) => (
                    <>
                      <ComboBoxV3.Field value={tags} />
                      {!!watch('tags')?.length && (
                        <>
                          <ComboBoxV3.Options className={styles.options}>
                            {watch('tags')?.map((tag) => (
                              <Tag key={tag.name}>
                                <span>{`${tag.name}`}</span>
                                <Icon
                                  name="close-xlg"
                                  width={8}
                                  height={8}
                                  onClick={() =>
                                    setValue(
                                      'tags',
                                      watch('tags')?.filter(
                                        (item) => item.name !== tag.name,
                                      ),
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }
                                />
                              </Tag>
                            ))}
                          </ComboBoxV3.Options>
                          <ButtonV2
                            size="md"
                            appearance="tertiary"
                            onClick={() =>
                              setValue('tags', [], {
                                shouldValidate: true,
                              })
                            }
                          >
                            <Icon name="delete" width={12} height={12} />
                            Remover tudo
                          </ButtonV2>
                        </>
                      )}
                      <ComboBoxV3.Group>
                        {unSelectedOptions?.map((option) => (
                          <ComboBoxV3.Option
                            key={option.id}
                            hasLabel={false}
                            onClick={() => {
                              setValue(
                                'tags',
                                [
                                  ...(watch('tags') || []),
                                  {
                                    name: option.name,
                                  },
                                ],
                                {
                                  shouldValidate: true,
                                },
                              )
                            }}
                          >
                            {option.name}
                          </ComboBoxV3.Option>
                        ))}
                      </ComboBoxV3.Group>
                    </>
                  )}
                </ComboBoxV3.Root>
              </FormItem>

              {!!account?.contacts.length && (
                <FormItem>
                  <FormLabel>Contatos</FormLabel>
                  <ul className={styles.contacts}>
                    {account.contacts.map((contact) => (
                      <ContactItem key={contact.id} contact={contact} />
                    ))}
                  </ul>
                </FormItem>
              )}
            </div>
          )}

          <div className={styles.right}>
            <Calendar
              height="calc(80vh - 130px)"
              events={allServiceOrders}
              onChangeWeekInterval={(newInterval) => {
                if (
                  calendarWeekInterval?.startDate !== newInterval.startDate ||
                  calendarWeekInterval?.endDate !== newInterval.endDate
                ) {
                  setCalendarWeekInterval(newInterval)
                }
              }}
            />
          </div>
        </div>
        <Modal.Footer className={styles.modalFooter}>
          <div ref={tooltipRef} onClick={tooltip.show}>
            <ButtonV2
              appearance="tertiary"
              className={styles.buttons}
              onClick={onClose}
            >
              Cancelar
            </ButtonV2>
          </div>
          {triedToClose && (
            <Tooltip
              positionAbove={POSITION.TOP}
              parentRef={tooltipRef}
              type="informative"
              isVisible={tooltip.isVisible}
            >
              <span>
                Existem informações que não foram salvas. Deseja realmente
                fechar?
              </span>
            </Tooltip>
          )}
          {!readonly && (
            <ButtonV2
              type="submit"
              className={styles.buttons}
              disabled={
                !isValid ||
                ((isTechnicianScheduleBlocked || hasReachedMaxEvents) &&
                  !hasServiceOrderAttendantRole)
              }
            >
              Salvar
            </ButtonV2>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ScheduleServiceOrderModal
