import {
  cloneElement,
  MouseEvent,
  ReactElement,
  useCallback,
  useRef,
  Children,
  useState,
} from 'react'
import ReactDOM from 'react-dom'

import { useOnClickOutside } from 'shared/hooks/useOnClickOutside'

import styles from './Droplist.module.scss'
import { useElementPosition } from 'shared/hooks/useElementPosition'

type DroplistProps = {
  children: ReactElement | ReactElement[]
  trigger: ReactElement
}

export const Droplist = ({
  children,
  trigger,
}: DroplistProps): ReactElement => {
  const [isDroplistOpen, setIsDroplistOpen] = useState(false)

  const parentRef = useRef<HTMLDivElement>(null)
  const droplistRef = useRef<HTMLDivElement>(null)

  const { top, left } = useElementPosition({
    enabled: isDroplistOpen,
    positionOnEdge: true,
    actionTriggerRef: parentRef,
    elementRef: droplistRef,
  })

  const handleOpen = useCallback(() => {
    setIsDroplistOpen((state) => !state)
  }, [])

  useOnClickOutside(droplistRef, handleOpen)

  return (
    <div>
      <div ref={parentRef}>
        {cloneElement(trigger, {
          onClick: (e: MouseEvent) => {
            handleOpen()
            e.stopPropagation()
          },
        })}
      </div>
      {ReactDOM.createPortal(
        <>
          {isDroplistOpen && (
            <div
              aria-label="actions-container"
              style={{
                top,
                left,
              }}
              className={styles.dropList}
              ref={droplistRef}
            >
              <ul>
                {Children.map(children, (child, index) => (
                  <li className={styles.dropListItem} key={index}>
                    {cloneElement(child)}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>,
        document.body,
      )}
    </div>
  )
}
