import React, { useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ReactComponent as CameraIcon } from 'assets/svg/cameraIcon.svg'

import { useDebounce } from 'shared/hooks'
import { joiResolver } from '@hookform/resolvers/joi'

import { FormLoader } from '../../components'
import { Button, Input, ProtectedImage } from 'components'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'

import { parseDataToComboboxV2 } from 'utilities/combobox'

import { useGetContacts } from 'services/contact'
import { useGetAccounts } from 'services/account'
import { AggregatedAccount } from 'services/account/types'
import { useInfiniteDeviceModel } from 'services/deviceModel'
import { ContactQueryResponse } from 'services/contact/types'
import { DeviceModelOutput } from 'services/deviceModel/types'
import { ManufacturerOutput } from 'services/manufacturer/types'
import { useInfiniteManufacturers } from 'services/manufacturer'
import { DevicesOutput, TagsResponse } from 'services/device/types'
import { useInfiniteDevices, useInfiniteTags } from 'services/device'

import { useGetPartitions } from 'services/partition'
import { PartitionResponse } from 'services/partition/types'

import { schema } from './utils'
import { Form } from '../../types'

import { Filter, DeviceFormProps } from './types'

import styles from './styles.module.scss'

const DeviceForm: React.FC<DeviceFormProps> = ({
  defaultValues,
  onSubmit,
  isLoading,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const codeInputRef = useRef<HTMLInputElement>(null)

  const currentImage = defaultValues?.image
  const itsEditing = location.pathname.includes('update')
  const customerId = localStorage.getItem('customerId') || ''
  const centralId = localStorage.getItem('centralId') || ''

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<Form>({
    mode: 'all',
    resolver: joiResolver(schema),
    defaultValues: {
      tags: [],
      partitions: [],
      ...defaultValues,
      contact: defaultValues?.contact.id ? defaultValues.contact : undefined,
      centralId,
      account: {
        id: localStorage.getItem('accountId') || '',
        name: localStorage.getItem('aggregatedAccountName') || '',
      },
      image: 'defaultValue',
    },
  })

  const account = watch('account')

  const [filter, setFilter] = useState<Filter>({
    tag: '',
    model: '',
    device: '',
    contact: '',
    account: '',
    partition: '',
    manufacturer: '',
  })

  const handleFilter = useDebounce(setFilter)

  const {
    data: accounts,
    isError: accountsError,
    isLoading: accountsLoading,
    fetchNextPage: accountFetchNextPage,
  } = useGetAccounts(customerId, {
    name: filter.account,
  })

  const {
    data: deviceModels,
    isError: deviceModelsError,
    isLoading: deviceModelsLoading,
    fetchNextPage: deviceModelsFetchNextPage,
  } = useInfiniteDeviceModel({
    filter: {
      name: filter.model,
    },
    query: {
      hasCommandSupport: false,
      deviceTypeId: watch('device')?.id,
      manufacturerId: watch('manufacturer')?.id,
    },
  })

  const {
    data: manufacturers,
    isError: manufacturersError,
    isLoading: manufacturesLoading,
    fetchNextPage: manufacturesFetchNextPage,
  } = useInfiniteManufacturers({
    name: filter.manufacturer,
  })

  const {
    data: devices,
    isError: devicesError,
    isLoading: devicesLoading,
    fetchNextPage: deviceFetchNextPage,
  } = useInfiniteDevices({
    name: filter.device,
  })

  const {
    data: partitions,
    isError: partitionsError,
    isLoading: partitionsLoading,
    fetchNextPage: partitionsFetchNextPage,
  } = useGetPartitions({ accountIds: account?.id, code: filter.partition })

  const {
    data: contacts,
    isError: contactError,
    isLoading: contactLoading,
  } = useGetContacts({
    payload: {
      customerId,
      name: filter.contact,
      partitionId: watch('partitions')?.[0]?.id,
    },
  })

  const {
    data: tags,
    isError: tagsError,
    isLoading: tagsLoading,
    fetchNextPage: tagsFetchNextPage,
  } = useInfiniteTags({ name: filter.tag })

  const hasRemoteControl = watch('device')?.code === '0'
  const firstStageCompleted = !!watch('account') && !!watch('device')

  const accountWithCentral = useMemo(
    () => accounts?.filter((account) => account.central?.id),
    [accounts],
  )

  const hasNewImage =
    typeof watch('image') !== 'string' && watch('image') !== undefined

  return (
    <form
      className={styles.container}
      onSubmit={handleSubmit((data) => {
        if (!defaultValues) {
          Object.entries({ name: '', code: '' }).map(([key, value]) =>
            setValue(key, value),
          )
        }

        onSubmit(data)

        codeInputRef.current?.focus()
      })}
    >
      <div className={styles.fieldWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <FormLoader />
          </div>
        )}

        <div>
          <Combobox
            label={{ text: 'Conta' }}
            items={parseDataToComboboxV2(
              accountWithCentral || [],
              'aggregatedAccountName',
            )}
            {...register('account')}
            {...register('centralId')}
            value={watch('account')?.name ?? undefined}
            onChange={(selected) => {
              const selectedAccount =
                selected as ComboboxItem<AggregatedAccount>

              setValue(
                'account',
                {
                  id: selectedAccount.value?.id,
                  name: selectedAccount.value?.aggregatedAccountName,
                },
                {
                  shouldValidate: true,
                },
              )

              setValue('centralId', selectedAccount.value?.central?.id)
              setValue('device', undefined, { shouldValidate: true })
              setValue('manufacturer', undefined, { shouldValidate: true })
              setValue('model', undefined, { shouldValidate: true })
            }}
            isError={accountsError}
            isLoading={accountsLoading}
            onEndReached={accountFetchNextPage}
            onSearch={(value) => handleFilter({ account: value })}
            disabled={itsEditing}
          />
        </div>
        <div>
          <Combobox<PartitionResponse>
            label={{
              text: 'Partição',
            }}
            multiple
            {...register('partitions')}
            value={
              watch('partitions')?.map((item) => ({
                label: 'aggregatedName',
                value: item,
              })) || []
            }
            items={parseDataToComboboxV2(partitions || [], 'aggregatedName')}
            onChange={(selected) => {
              const partitions = selected as ComboboxItem<PartitionResponse>[]

              setValue(
                'partitions',
                partitions.map((partition) => ({
                  id: partition.value.id,
                  aggregatedName: partition?.value.aggregatedName,
                })),
                {
                  shouldValidate: true,
                },
              )
            }}
            isError={partitionsError}
            isLoading={partitionsLoading}
            onEndReached={partitionsFetchNextPage}
            onSearch={(value) => handleFilter({ partition: value })}
          />
        </div>
        <div>
          <Combobox<DevicesOutput>
            label={{
              text: 'Tipo de equipamento',
            }}
            {...register('device')}
            value={watch('device')?.name ?? undefined}
            items={parseDataToComboboxV2(
              devices.filter((device) => device?.code !== '6') || [],
              'name',
            )}
            onChange={(selected) => {
              const device = selected as ComboboxItem<DevicesOutput>

              if (device) {
                setValue(
                  'device',
                  {
                    id: device.value?.id,
                    name: device.value?.name,
                    code: device.value?.code,
                  },
                  { shouldValidate: true },
                )
              }

              setValue('manufacturer', undefined, { shouldValidate: true })
              setValue('model', undefined, { shouldValidate: true })
            }}
            isError={devicesError}
            isLoading={devicesLoading}
            onEndReached={deviceFetchNextPage}
            onSearch={(value) => handleFilter({ device: value })}
          />
        </div>
        {firstStageCompleted && (
          <>
            <Combobox<ManufacturerOutput>
              label={{
                text: 'Fabricante',
              }}
              {...register('manufacturer')}
              value={watch('manufacturer')?.name ?? undefined}
              items={parseDataToComboboxV2(manufacturers || [], 'name')}
              onChange={(selected) => {
                const manufacturer =
                  selected as ComboboxItem<ManufacturerOutput>

                if (manufacturer) {
                  setValue(
                    'manufacturer',
                    {
                      id: manufacturer.value?.id,
                      name: manufacturer.value?.name,
                    },
                    {
                      shouldValidate: true,
                    },
                  )
                }

                setValue('model', undefined, { shouldValidate: true })
              }}
              isError={manufacturersError}
              isLoading={manufacturesLoading}
              onEndReached={manufacturesFetchNextPage}
              onSearch={(value) => handleFilter({ manufacturer: value })}
            />
            <Combobox<DeviceModelOutput>
              label={{
                text: 'Modelo',
              }}
              {...register('model')}
              value={watch('model')?.name ?? undefined}
              disabled={!watch('device') || !watch('manufacturer')}
              items={parseDataToComboboxV2(deviceModels || [], 'name')}
              onChange={(selected) => {
                const model = selected as ComboboxItem<DeviceModelOutput>

                setValue(
                  'model',
                  {
                    id: model?.value?.id,
                    name: model?.value?.name,
                  },
                  { shouldValidate: true },
                )
              }}
              isError={deviceModelsError}
              isLoading={deviceModelsLoading}
              onEndReached={deviceModelsFetchNextPage}
              onSearch={(value) => handleFilter({ model: value })}
            />
            <Input
              label="Zona"
              {...register('code')}
              ref={codeInputRef}
              value={watch('code') ?? undefined}
              onChange={(event) =>
                setValue('code', event?.target.value, {
                  shouldValidate: true,
                })
              }
            />
            {!hasRemoteControl && (
              <Input
                label="Nome"
                {...register('name')}
                value={watch('name')}
                onChange={(event) =>
                  setValue('name', event.target.value, {
                    shouldValidate: true,
                  })
                }
              />
            )}
          </>
        )}

        {firstStageCompleted && (
          <div>
            {hasRemoteControl && (
              <Combobox<ContactQueryResponse>
                label={{
                  text: 'Contato',
                }}
                disabled={!watch('partitions')}
                {...register('contact')}
                value={watch('contact')?.name ?? undefined}
                items={parseDataToComboboxV2(contacts?.data || [], 'name')}
                onChange={(selected) => {
                  const contact = selected as ComboboxItem<ContactQueryResponse>
                  setValue(
                    'contact',
                    {
                      id: contact.value.id,
                      name: contact.value.name,
                    },
                    {
                      shouldValidate: true,
                    },
                  )
                }}
                isError={contactError}
                isLoading={contactLoading}
                onSearch={(value) => handleFilter({ contact: value })}
              />
            )}
            <Combobox
              label={{
                text: 'Tags',
              }}
              multiple
              {...register('tags')}
              value={watch('tags')?.map((tag) => ({
                label: 'name',
                value: tag,
              }))}
              items={parseDataToComboboxV2(
                tags.map((tag) => ({
                  id: tag.id,
                  name: tag.name,
                })) || [],
                'name',
              )}
              onChange={(selecteds) => {
                const tags =
                  selecteds as unknown as ComboboxItem<TagsResponse>[]

                const formattedTags = tags.map((tag) => ({
                  id: tag.value.id,
                  name: tag.value.name,
                }))
                setValue('tags', formattedTags, { shouldValidate: true })
              }}
              isError={tagsError}
              isLoading={tagsLoading}
              onEndReached={tagsFetchNextPage}
              onSearch={(value) => handleFilter({ tag: value })}
            />
            <div className={styles.inputImage}>
              {!hasRemoteControl && (
                <label className={styles.inputImage}>
                  {hasNewImage || currentImage ? (
                    <>
                      <span className={styles.imageLabel}>
                        Foto de instalação
                      </span>
                      {hasNewImage ? (
                        <img
                          alt="Imagem do dispositivo instalado"
                          src={URL.createObjectURL(
                            new Blob([watch('image')?.[0]]),
                          )}
                        />
                      ) : (
                        currentImage && (
                          <ProtectedImage
                            imageId={currentImage}
                            alt="Imagem do dispositivo instalado"
                          />
                        )
                      )}
                    </>
                  ) : (
                    <span>
                      <CameraIcon />
                      Upload foto de instalação (opcional)
                    </span>
                  )}
                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    {...register('image')}
                  />
                </label>
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        <Button
          type="cancel-secondary"
          width="300px"
          onClick={() => navigate({ search: '' })}
          buttonTitle="Cancelar"
        />
        <Button
          type="primary"
          width="300px"
          htmlType="submit"
          disabled={!isValid}
          buttonTitle="Salvar"
        />
      </div>
    </form>
  )
}

export default DeviceForm
