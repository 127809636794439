import { useForm } from 'react-hook-form'

import { RadioButton } from 'components/RadioButton/RadioButton'
import { Input, RadioGroup } from 'components'

import { ConditionType } from 'services/attendancePolicy/types'

import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'

import styles from './DeviceQuantity.module.scss'
import { handleDefaultQuantityType } from 'domains/attendancePolicy/components/CondititonForms/DeviceQuantity/utilities'

export const DeviceQuantity = ({ onChange, data }: ConditionOptionForm) => {
  const { facts } = data

  const { register, watch, setValue } = useForm<{
    deviceQuantityType: 'SINGLE' | 'INTERVAL' | 'NONE'
    minDevices: number
    maxDevices: number
  }>({
    defaultValues: {
      deviceQuantityType: handleDefaultQuantityType(facts),
      minDevices: facts?.minDevices,
      maxDevices: facts?.maxDevices,
    },
  })

  return (
    <div>
      <RadioGroup
        title="Número de dispositivos"
        customStyle={styles.customRadioGroup}
        {...register('deviceQuantityType')}
      >
        <RadioButton
          name={`single-${data.id}`}
          checked={watch('deviceQuantityType') === 'SINGLE'}
          onChange={() => {
            setValue('deviceQuantityType', 'SINGLE')

            onChange({
              type: ConditionType.DEVICE_QUANTITY,
            })
          }}
          value="Único"
        />
        <RadioButton
          name={`interval-${data.id}`}
          checked={watch('deviceQuantityType') === 'INTERVAL'}
          onChange={() => {
            setValue('deviceQuantityType', 'INTERVAL')

            onChange({
              type: ConditionType.DEVICE_QUANTITY,
            })
          }}
          value="Intervalo"
        />
      </RadioGroup>
      <div
        className={watch('deviceQuantityType') !== 'NONE' ? styles.wrapper : ''}
      >
        {
          {
            SINGLE: (
              <Input
                autoComplete="off"
                label="Quantidade"
                {...register('minDevices')}
                placeholder="Digite um número de 1 a 1000000"
                type="number"
                value={String(facts?.minDevices)}
                onChange={(e) => {
                  setValue('minDevices', Number(e.target.value))

                  onChange({
                    type: ConditionType.DEVICE_QUANTITY,
                    facts: {
                      minDevices: Number(e.target.value),
                    },
                  })
                }}
              />
            ),
            INTERVAL: (
              <>
                <label>Quantidade</label>
                <div className={styles.intervalWrapper}>
                  <span>De</span>
                  <Input
                    id={`min-devices-${data.id}`}
                    {...register('minDevices')}
                    placeholder="0"
                    min={0}
                    className={styles.input}
                    autoComplete="off"
                    value={String(facts?.minDevices)}
                    type="number"
                    onChange={(e) => {
                      setValue('minDevices', Number(e.target.value))

                      onChange({
                        type: ConditionType.DEVICE_QUANTITY,
                        facts: {
                          minDevices: Number(e.target.value),
                          maxDevices: watch('maxDevices'),
                        },
                      })
                    }}
                  />

                  <span>até</span>
                  <Input
                    id={`max-devices-${data.id}`}
                    {...register('maxDevices')}
                    placeholder="0"
                    min={1}
                    className={styles.input}
                    autoComplete="off"
                    value={String(facts?.maxDevices)}
                    type="number"
                    onChange={(e) => {
                      setValue('maxDevices', Number(e.target.value))

                      onChange({
                        type: ConditionType.DEVICE_QUANTITY,
                        facts: {
                          minDevices: watch('minDevices'),
                          maxDevices: Number(e.target.value),
                        },
                      })
                    }}
                  />
                </div>
              </>
            ),
            NONE: <></>,
          }[watch('deviceQuantityType')]
        }
      </div>
    </div>
  )
}
