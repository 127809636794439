import {
  HOUR_IN_MILLISECONDS,
  MINUTE_IN_MILLISECONDS,
  THOUSAND_MILISECONDS,
} from 'domains/occurrence/constants/occurrence'
import { useState, useEffect } from 'react'
import { formatDecimal } from 'utilities/date'

type TimerHookProps = {
  initialTime: number
  finalTime?: number
}

export function useTimer({ initialTime, finalTime }: TimerHookProps): string {
  const [timer, setTimer] = useState<string>('00:00:00')

  function getIntervalInHours(
    initialDate: number,
    deadline = new Date().getTime(),
  ) {
    const interval = deadline - initialDate

    const seconds = formatDecimal(
      Math.floor((interval / THOUSAND_MILISECONDS) % 60),
    )
    const minutes = formatDecimal(
      Math.floor((interval / MINUTE_IN_MILLISECONDS) % 60),
    )
    const hours = formatDecimal(Math.floor(interval / HOUR_IN_MILLISECONDS))

    const formattedTimer = `${hours}:${minutes}:${seconds}`

    setTimer(formattedTimer)
  }

  useEffect(() => {
    if (finalTime) {
      getIntervalInHours(initialTime, finalTime)
      return
    }

    const timerId = setInterval(() => {
      getIntervalInHours(initialTime)
    }, 1000)

    return () => {
      clearInterval(timerId)
    }
  }, [timer, initialTime, finalTime])

  return timer
}
