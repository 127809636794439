import { useMutation, useQueryClient } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'

interface DeleteNote {
  serviceOrderId?: string
  interventionId?: string
  noteId?: string
}

export const deleteNote = async ({
  serviceOrderId,
  interventionId,
  noteId,
}: DeleteNote) => {
  const response = await HttpClient.delete(
    buildUrl({
      route: endpoints.deleteNote,
      params: { serviceOrderId, interventionId, noteId },
    }),
  )

  return response.data
}

export const useDeleteNote = (
  serviceOrderId?: string,
  interventionId?: string,
  noteId?: string,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (_nothing: null) =>
      await deleteNote({ serviceOrderId, interventionId, noteId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['service-order-intervention', serviceOrderId],
      })
    },
  })
}
