import { useCallback, useContext, createContext, ReactNode } from 'react'
import { parseCookies } from 'nookies'
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {
  AuthDriver,
  ChangePasswordRequest,
  UserInfoRequest,
  UserInfoResponse,
} from '../../../services/auth'
import { Loader } from 'components'

export interface UserInfoContextData {
  isLoading: boolean
  userInfo: UserInfoResponse
  fetchUserInfo: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<UserInfoResponse | undefined>>
  getUserPermissions: () => Promise<void>
  updateUserProfileImage: (image: File) => Promise<void>
  updateUserInfo: (info: UserInfoRequest) => Promise<void>
  updateUserPassword: (password: ChangePasswordRequest) => Promise<void>
}

export const UserInfoContext = createContext<UserInfoContextData>(
  {} as UserInfoContextData,
)

interface UserInfoProviderProps {
  children: ReactNode
}

export const fetchUserInfo = async (userId: string) => {
  if (userId) {
    const userInfoResponse = await AuthDriver.getUserInfo(userId)
    const userPermissionsResponse = await AuthDriver.getUserPermissions(userId)

    return {
      ...userInfoResponse,
      permissions: {
        ...userPermissionsResponse,
      },
    }
  }
}

const useFetchUserInfo = (userId = '') => {
  return useQuery({
    queryKey: ['fetch-user-info'],
    queryFn: async () => {
      if (userId) {
        return await fetchUserInfo(userId)
      }
    },
    enabled: !!userId,
  })
}

export const UserInfoProvider = ({ children }: UserInfoProviderProps) => {
  const { userId } = parseCookies()
  const queryClient = useQueryClient()

  const { data: userInfo, isLoading, refetch } = useFetchUserInfo(userId)

  const updateUserInfo = async (info: UserInfoRequest): Promise<void> => {
    await AuthDriver.updateUserInfo(info).then(() =>
      queryClient.invalidateQueries({ queryKey: ['fetch-user-info', userId] }),
    )
  }

  const updateUserProfileImage = useCallback(
    async (image: File): Promise<void> => {
      await AuthDriver.updateUserProfileImage(image).then(() =>
        queryClient.invalidateQueries({
          queryKey: ['fetch-user-info', userId],
        }),
      )
    },
    [queryClient, userId],
  )

  const updateUserPassword = useCallback(
    async (password: ChangePasswordRequest): Promise<void> => {
      await AuthDriver.changePassword(password)
    },
    [],
  )

  const getUserPermissions = useCallback(async (): Promise<void> => {
    await AuthDriver.getUserPermissions(userId).then(() =>
      queryClient.invalidateQueries({ queryKey: ['fetch-user-info', userId] }),
    )
  }, [queryClient, userId])

  return (
    <UserInfoContext.Provider
      value={{
        userInfo,
        isLoading,
        updateUserInfo,
        fetchUserInfo: refetch,
        updateUserProfileImage,
        updateUserPassword,
        getUserPermissions,
      }}
    >
      {isLoading ? <Loader isVisible /> : children}
    </UserInfoContext.Provider>
  )
}

export function useUserInfo(): UserInfoContextData {
  const context = useContext(UserInfoContext)

  if (!context)
    throw new Error('You need to use UserInfoContext within a UserInfoProvider')

  return context
}
