export enum DetectionName {
  PERSON = 'Person',
  PET = 'Pet',
}

export type DetectLabelBoundingBox = {
  width: number
  height: number
  left: number
  top: number
}

export type DetectLabelInstance = {
  id: string
  confidence: number
  boundingBox: DetectLabelBoundingBox
}

export type DetectLabelParent = {
  name: string
}

export type DetectLabel = {
  name: string
  confidence: number
  instances: DetectLabelInstance[]
  parents: DetectLabelParent[]
}

export type PictureInfo = {
  id: string
  date: number
  labels?: DetectLabel[]
  deviceName?: string
  note?: string
}

export const OwnerName = {
  Patrimony: 'patrimony',
  ServiceOrder: 'serviceOrder',
  Customer: 'customer',
  Event: 'event',
  Account: 'account',
  Central: 'central',
  InstalledDevice: 'installedDevice',
  Partition: 'partition',
  QuickView: 'quickView',
  Device: 'device',
  Command: 'command',
  PatrimonyFront: 'patrimonyFront',
  Occurrence: 'occurrence',
  Agent: 'agent',
  Attendant: 'attendant',
  InterventionNote: 'interventionNote',
  internalProfile: 'internalProfile',
  SID: 'SID',
}

export type OwnerNameType = keyof typeof OwnerName

export type Owner = {
  id: string
  name: OwnerNameType
}

export type ImageResponse = {
  id: string
  url: string
  title?: string
  note?: string
  hidden: boolean
  creation: number
  owners: Owner[]
  labels?: DetectLabel[]
}

export type ImageRequest = {
  principalOwner?: Owner
  owners?: Owner[]
}

export type GalleryData = {
  imageId: string
  images: ImageResponse[]
  onClose: () => void
}

export type Query = {
  owners: string
  hasAnyOwner?: boolean
  ownerNames?: string
  hasAnyOwnerName?: boolean
  labels?: string
  hidden?: boolean
  creationFrom?: string
  creationTo?: string
  sortType?: 'asc' | 'desc'
}
