export const endpoints = {
  query: 'serviceOrder',
  getAccount: 'serviceOrder/account/:accountId',
  queryTags: '/serviceOrder/tags/query',
  getSO: '/serviceOrder/:serviceOrderId',
  getIntervention: '/serviceOrder/:serviceOrderId/intervention',
  create: '/serviceOrder/create',
  update: '/serviceOrder/:id/update',
  createIntervention: '/serviceOrder/:serviceOrderId/intervention',
  createNote: '/serviceOrder/:serviceOrderId/intervention/:interventionId/note',
  updateNote:
    '/serviceOrder/:serviceOrderId/intervention/:interventionId/note/:noteId',
  deleteNote:
    '/serviceOrder/:serviceOrderId/intervention/:interventionId/note/:noteId',
  deleteIntervention:
    '/serviceOrder/:serviceOrderId/intervention/:interventionId',
}
