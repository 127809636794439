import { useMutation } from '@tanstack/react-query'
import HttpClient from '../httpClient'
import buildUrl from 'utilities/buildUrl'
import { endpoints } from './endpoints'
import { CommandPayload } from './types'

interface SendMaintenanceCommand {
  centralId?: string
  data: CommandPayload
}

const sendMaintenanceCommand = async ({
  centralId,
  data,
}: SendMaintenanceCommand) => {
  const response = await HttpClient.post(
    buildUrl({
      route: endpoints.maintenance,
      params: { centralId },
    }),
    data,
  )
  return response.data
}

export const useMaintenanceMode = (centralId?: string) =>
  useMutation({
    mutationFn: async (data: CommandPayload) =>
      await sendMaintenanceCommand({ centralId, data }),
  })
