import { ComboboxItem } from 'components'

import { ReactComponent as IconMiniX } from '../../../assets/svg/miniX.svg'
import { getItemValue } from '../utilities'

import styles from './MultiSelectTags.module.scss'

type MultiSelectTags<T> = {
  items: (string | ComboboxItem<T>)[]
  onRemoveItem: (item: string | ComboboxItem<T>) => void
  placeholder?: string
}

export function MultiSelectTags<T>({
  items,
  onRemoveItem,
  placeholder,
}: MultiSelectTags<T>): JSX.Element {
  return (
    <ul className={styles.contentWrapper}>
      {items.length ? (
        items.map((item, index) => (
          <li
            data-testid="multi-selected-item"
            className={styles.innerContentWrapper}
            key={index}
          >
            <p className={styles.innerContentText}>{getItemValue(item)}</p>
            <IconMiniX
              role="img"
              className={styles.deleteIcon}
              onClick={(e) => {
                e.stopPropagation()
                onRemoveItem && onRemoveItem(item)
              }}
            />
          </li>
        ))
      ) : (
        <li data-testid="multi-selected-item" className={styles.placeholder}>
          {placeholder}
        </li>
      )}
    </ul>
  )
}
