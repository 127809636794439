import { Button, Modal } from 'components'

export type VehicleFormExitModalProps = {
  isVisible: boolean
  onClose: () => void
  onClick: () => void
}

export const VehicleFormExitModal = ({
  isVisible,
  onClose,
  onClick,
}: VehicleFormExitModalProps) => {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      size={'sm'}
      simple
      title={'Voltar à seção anterior'}
    >
      <div>
        <p>
          Você tem certeza de que deseja voltar? Todas as informações <br />
          preenchidas serão perdidas.
        </p>
      </div>
      <Modal.Footer>
        <Button
          buttonTitle="Cancelar"
          onClick={onClose}
          type="secondary"
          width="172px"
        />
        <Button
          buttonTitle="Voltar"
          onClick={onClick}
          type="cancel-primary"
          width="172px"
        />
      </Modal.Footer>
    </Modal>
  )
}
