import { Result } from 'services/types'
import buildUrl from 'utilities/buildUrl'

import { Tag } from './types'
import HttpClient from '../httpClient'
import { endpoints } from './endpoints'

export const getServiceOrderTags = async (name?: string, offset = 0) => {
  const response = await HttpClient.get<Result<Tag>>(
    buildUrl({
      route: endpoints.queryTags,
      queryParams: {
        offset,
        ...(name && { name }),
        recordsPerPage: 15,
      },
    }),
  )

  return response.data
}
