import ReactDOM from 'react-dom'
import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { ToggleHook, useDebounce } from 'shared/hooks'

import { Button } from 'components'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'

import { ReactComponent as CloseButton } from 'assets/svg/close.svg'

import { useGetPartitions } from 'services/partition'

import { PartitionResponse } from 'services/partition/types'
import { OccurrenceTypeQueryResponse } from 'services/occurrence/types'
import { useGetOccurrenceType } from 'shared/hooks/services/occurrence/useGetOccurrenceType'

import { parseDataToComboboxV2 } from 'utilities/combobox'

import { OccurrenceFiltersSchema } from 'domains/customer/schemas/occurrence/searchOccurrenceSchema'

import styles from './Filter.module.scss'

interface Filter extends ToggleHook {
  onApplyFilters: () => void
  filteredAccount: string
}

interface ComboboxFilter {
  code?: string
  partition?: string
  occurrenceType?: string
}

export const Filter: FC<Filter> = ({
  isVisible,
  hide,
  onApplyFilters,
  filteredAccount,
}) => {
  const { setValue, watch, reset, register } =
    useFormContext<OccurrenceFiltersSchema>()

  const [filter, setFilter] = useState<ComboboxFilter>({
    code: '',
    partition: '',
    occurrenceType: '',
  })
  const handleFilter = useDebounce(setFilter)

  const {
    data: partitions,
    isError: partitionsError,
    isLoading: partitionsLoading,
    fetchNextPage: partitionsFetchNextPage,
  } = useGetPartitions({
    accountIds: filteredAccount,
    code: filter.partition,
  })

  const {
    occurrenceTypes,
    isError: occurrenceTypeError,
    isFetching: occurrenceTypeFetching,
    fetchNextOccurrenceTypesPage,
  } = useGetOccurrenceType()

  return ReactDOM.createPortal(
    <div
      className={[styles.container, isVisible ? styles.show : styles.hide]
        .filter(Boolean)
        .join(' ')}
      data-testid="filters-component"
    >
      <div className={styles.innerWrapper}>
        <div className={styles.header}>
          <span>Filtros</span>
          <CloseButton
            height={16}
            width={16}
            onClick={hide}
            className={styles.closeIcon}
            data-testid="filter-close-button"
          />
        </div>
        <div className={styles.contentWrapper}>
          <Combobox<PartitionResponse>
            disabled={!filteredAccount}
            label={{
              text: 'Partição',
            }}
            {...register('partition')}
            value={watch('partition')?.code ?? undefined}
            items={parseDataToComboboxV2(partitions || [], 'code')}
            onChange={(selected) => {
              const partition = selected as ComboboxItem<PartitionResponse>

              setValue(
                'partition',
                {
                  id: partition.value.id,
                  code: partition.value.code,
                },
                {
                  shouldValidate: true,
                },
              )
            }}
            isError={partitionsError}
            isLoading={partitionsLoading}
            onEndReached={partitionsFetchNextPage}
            onSearch={(value) => handleFilter({ partition: value })}
          />

          <Combobox<OccurrenceTypeQueryResponse>
            label={{
              text: 'Tipo de ocorrência',
            }}
            {...register('occurrenceType')}
            value={watch('occurrenceType')?.name ?? undefined}
            items={parseDataToComboboxV2(occurrenceTypes || [], 'name')}
            onChange={(selected) => {
              const occurrenceType =
                selected as ComboboxItem<OccurrenceTypeQueryResponse>

              setValue(
                'occurrenceType',
                {
                  id: occurrenceType.value.id,
                  name: occurrenceType.value.name,
                },
                {
                  shouldValidate: true,
                },
              )
            }}
            isError={occurrenceTypeError}
            isLoading={occurrenceTypeFetching}
            onEndReached={fetchNextOccurrenceTypesPage}
            onSearch={(value) => handleFilter({ occurrenceType: value })}
          />
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              reset({})
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            width="162px"
            onClick={onApplyFilters}
          />
        </div>
      </div>
    </div>,
    document.getElementById('main-container') || document.body,
  )
}
