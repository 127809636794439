import { useMutation } from '@tanstack/react-query'
import { AttendancePolicyDriver } from 'services/attendancePolicy'
import { AttendancePolicyPayload } from 'services/attendancePolicy/types'

export type UpdateAttendancePolicyRequest = {
  id: string
  payload: AttendancePolicyPayload
}

function updateAttendancePolicy(
  request: UpdateAttendancePolicyRequest,
): Promise<void> {
  const { id, payload } = request
  return AttendancePolicyDriver.update(id, payload)
}

export const usePutAttendancePolicy = () => {
  const { status, mutate } = useMutation({
    mutationFn: (request: UpdateAttendancePolicyRequest) =>
      updateAttendancePolicy(request),
  })

  return {
    mutateUpdateAttendancePolicy: mutate,
    statusUpdateAttendancePolicy: status,
  }
}
