import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { Loader, FallBackError } from 'components'
import { useToast, useUserInfo } from 'shared/hooks'
import { useHandleCloseApp } from 'routes/hooks/useHandleCloseApp'
import { useKeepAliveUserStatus } from 'shared/hooks/useKeepAliveUserStatus/useKeepAliveUserStatus'

import { NavBar } from '../Navbar/Navbar'
import classes from './MainContainer.module.scss'
import { ErrorBoundary } from 'react-error-boundary'

export const MainContainer = () => {
  const { userInfo, isLoading } = useUserInfo()
  const [changedStation, setChangedStation] = useState(false)

  const { addToast } = useToast()

  useHandleCloseApp()
  useKeepAliveUserStatus()

  useEffect(() => {
    if (userInfo?.station && changedStation) {
      addToast({
        message: `Você está usando o posto de atendimento ${
          userInfo?.station?.name || ''
        }`,
      })
      setChangedStation(false)
    }
  }, [userInfo, addToast, changedStation])

  return (
    <>
      {isLoading && <Loader isVisible />}
      <div className={classes.container}>
        <NavBar />

        <div className={classes.content}>
          <ErrorBoundary
            key={window.location.pathname}
            fallbackRender={({ error }) => <FallBackError error={error} />}
          >
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </>
  )
}
