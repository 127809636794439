import { Button, Modal } from 'components'

import styles from './VisualizationModal.module.scss'

type VisualizationModalProps = {
  isVisible: boolean
  onClose: () => void
  holiday: {
    id: string
    name: string
    date: string
    type: string
    coverage: string
  }
}

export const VisualizationModal = ({
  isVisible,
  onClose,
  holiday,
}: VisualizationModalProps) => {
  return (
    <Modal
      title="Feriado"
      isVisible={isVisible}
      onClose={onClose}
      className={styles.container}
    >
      <div className={styles.content}>
        <div>
          <label>Nome</label>
          <p>{holiday.name}</p>
        </div>
        <div>
          <label>Data</label>
          <p>{holiday.date}</p>
        </div>
        <div>
          <label>Tipo</label>
          <p>{holiday.type}</p>
        </div>
        <div>
          <label>Abrangência</label>
          <p>{holiday.coverage}</p>
        </div>
      </div>
      <Modal.Footer>
        <Button
          id="close-modal"
          buttonTitle="Voltar"
          onClick={onClose}
          type="secondary"
        />
      </Modal.Footer>
    </Modal>
  )
}
