import { ReactElement } from 'react'

import { ReactComponent as CarIcon } from 'assets/svg/car.svg'
import { ReactComponent as MotorBikeIcon } from 'assets/svg/motorbike.svg'

import { VehicleCategory } from 'services/displacementMap/types'

export const icons: Record<VehicleCategory, ReactElement> = {
  CAR: <CarIcon width={24} height={24} />,
  MOTORCYCLE: <MotorBikeIcon width={24} height={24} />,
}
