import { FC, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'components'
import { usePostIntervention } from 'services/attendance'
import { InterventionPayload } from 'services/occurrence/types'
import { RouteParams } from 'domains/occurrence/screens/Attendance/types'
import { useModal } from 'domains/occurrence/screens/Attendance/context'

import styles from './styles.module.scss'
import { useUserInfo } from 'shared/hooks'

const AddRegister: FC = () => {
  const { occurrenceId } = useParams<RouteParams>()
  const { userInfo } = useUserInfo()

  const [visible, setVisible] = useState(false)

  const { handleOpenModal } = useModal()
  const { mutate } = usePostIntervention(occurrenceId)

  const handleCreateIntervention = useCallback(
    (data: InterventionPayload) => {
      const userId = userInfo.id

      if (userId) {
        mutate(
          {
            ...data,
            userId,
            typeName: 'MANUAL_RECORD',
            tags: [{ name: 'registroManual' }],
            title: `Registro de nota ${
              data.attributes.hidden ? 'oculta' : 'pública'
            }`,
          },
          {
            onSuccess: () => {
              setVisible(false)
            },
          },
        )
      }
    },
    [mutate],
  )

  return (
    <div className={styles.container}>
      {visible && (
        <ul onMouseLeave={() => setVisible(false)}>
          <li onClick={() => handleOpenModal('INTRUSION_CONFIRMED')}>
            Registrar intrusão
          </li>
          <li
            onClick={() =>
              handleCreateIntervention({
                attributes: {
                  hidden: true,
                },
              })
            }
          >
            Registrar nota oculta
          </li>
          <li
            onClick={() =>
              handleCreateIntervention({
                attributes: {
                  hidden: false,
                },
              })
            }
          >
            Registrar nota pública
          </li>
        </ul>
      )}

      <Button
        type="secondary"
        width="180px"
        buttonTitle="Adicionar registro"
        onClick={() => setVisible((old) => !old)}
      />
    </div>
  )
}

export default AddRegister
