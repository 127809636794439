import { OccurrenceInterventionTypeName } from 'services/displacementMap/types'

export const translatedInterventionType: Record<
  OccurrenceInterventionTypeName,
  string
> = {
  PHONE_CALL: 'Ligação telefônica',
  IMAGE_VERIFICATION: 'Verificação de imagem',
  SEND_MESSAGE: 'Envio de mensagem',
  STATE_CHANGE: 'Alteração de estado da ocorrência',
  SEND_ALERT: 'Envio de alerta',
  SEND_IMAGE: 'Envio de imagem',
  SEND_CHECKLIST: 'Envio de checklist',
  CONFIRM_INTRUSION: 'Intrusão confirmada',
  MANUAL_RECORD: 'Registro manual',
}

export const convertInterventionType: Record<
  string,
  OccurrenceInterventionTypeName
> = {
  'Ligação telefônica': 'PHONE_CALL',
  'Verificação de imagem': 'IMAGE_VERIFICATION',
  'Envio de mensagem': 'SEND_MESSAGE',
  'Alteração de estado da ocorrência': 'STATE_CHANGE',
  'Envio de alerta': 'SEND_ALERT',
  'Envio de imagem': 'SEND_IMAGE',
  'Envio de checklist': 'SEND_CHECKLIST',
  'Intrusão confirmada': 'CONFIRM_INTRUSION',
  'Registro manual': 'MANUAL_RECORD',
}
