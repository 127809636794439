import Joi from '@hapi/joi'

import { State } from 'services/address/state'

export const stateSchema = Joi.object<State>({
  id: Joi.string().uuid().allow('', null),
  countryId: Joi.string().uuid().allow('', null),
  name: Joi.string().allow('', null),
  acronym: Joi.string().allow('', null),
  createdAt: Joi.number().allow('', null),
  updatedAt: Joi.number().allow('', null),
}).unknown(true)
