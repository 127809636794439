import { AxiosInstance, AxiosResponse } from 'axios'
import HttpClient from '../httpClient'

import { Result } from 'services/types'
import { Vehicle, VehicleAggregatedQueryResponse, VehicleQuery } from '.'

export interface VehicleDriver {
  create(vehicle: Vehicle): Promise<void>
  update(id: string, vehicle: Vehicle): Promise<void>
  findById(id: string): Promise<VehicleAggregatedQueryResponse>
  query(query: VehicleQuery): Promise<Result<VehicleAggregatedQueryResponse>>
  delete(vehicleId: string): Promise<AxiosResponse<void>>
}

export class VehicleDriverImpl implements VehicleDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async create(vehicle: Vehicle): Promise<void> {
    return this.httpClient.post('/vehicle/create', vehicle)
  }

  public async update(id: string, vehicle: Vehicle): Promise<void> {
    return this.httpClient.put(`/vehicle/${id}/update`, vehicle)
  }

  public async findById(id: string): Promise<VehicleAggregatedQueryResponse> {
    return this.httpClient
      .get<VehicleAggregatedQueryResponse>(`/vehicle/${id}/find`)
      .then((res) => res.data)
  }

  public async query(
    query: VehicleQuery,
  ): Promise<Result<VehicleAggregatedQueryResponse>> {
    const result = await this.httpClient.get<
      Result<VehicleAggregatedQueryResponse>
    >(`/vehicle/query`, {
      params: query,
    })

    return result.data
  }

  public async delete(vehicleId: string): Promise<AxiosResponse<void>> {
    return this.httpClient.delete(`/vehicle/${vehicleId}`)
  }
}

export const VehicleDriver = new VehicleDriverImpl()
