import { PartitionStatus } from 'services/patrimony/types'

export const getPartitionStatusLabel = (status: PartitionStatus): string => {
  switch (status) {
    case 'D':
      return 'Desarmado'
    case 'S':
      return 'Armado (Ficar)'
    case 'A':
      return 'Armado (Sair)'
    default:
      return 'Desconhecido'
  }
}
