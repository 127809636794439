import { useMemo } from 'react'

import { DeviceType } from 'services/partition/types'

import { ReactComponent as QRCodeIcon } from 'assets/svg/qrCode.svg'

import { ReactComponent as DeviceCommunicationOn } from 'assets/svg/deviceCommunicationOn.svg'
import { ReactComponent as DeviceCommunicationOff } from 'assets/svg/deviceCommunicationOff.svg'
import { ReactComponent as FullBattery } from 'utilities/central/svg/battery/fullBattery.svg'
import { ReactComponent as Sensor } from 'assets/svg/sensor.svg'

import { ReactComponent as Gear } from 'assets/svg/gear.svg'

import styles from './DeviceCardFooter.module.scss'

import { batteryVoltageMasker } from 'domains/customer/screens/Equipments/utilities/masks/masks'
import { getSignalLevelBarIcon } from 'domains/customer/screens/Equipments/utilities/icons'
import { PartitionDeviceAggregatedResponse } from 'services/device/types'

export interface DeviceCardFooterProps {
  device: PartitionDeviceAggregatedResponse
}

export const DeviceCardFooter = ({ device }: DeviceCardFooterProps) => {
  const isRemoteControl = device.typeCode === DeviceType.RemoteControl

  const deviceInfo = useMemo(() => {
    if (isRemoteControl)
      return {
        code: device.code,
        model: device.model?.name,
      }

    const getDeviceStatus = (status: boolean) => {
      type DeviceStatusLabel = 'TRUE' | 'FALSE'

      const deviceStatus: Record<DeviceStatusLabel, string> = {
        TRUE: 'Aberto',
        FALSE: 'Fechado',
      }

      const statusLabel = String(status).toUpperCase() as DeviceStatusLabel

      return deviceStatus[statusLabel]
    }

    return {
      code: device.code,
      status:
        device.status === undefined ? '-' : getDeviceStatus(device.status),
      batteryVoltage: device.battery?.voltage
        ? `${batteryVoltageMasker(String(device.battery.voltage))} V`
        : '-',
      firmware: device.versions?.firmware
        ? `Versão ${device.versions.firmware}`
        : '-',
      hardware: device.versions?.hardware
        ? `Versão ${device.versions.hardware}`
        : '-',
      rssi: device.communication?.rssi
        ? `RSSI ${device.communication.rssi}`
        : '-',
      communicationStatus: device.communication?.status,
      model: device.model?.name,
    }
  }, [device, isRemoteControl])

  return (
    <>
      <div className={styles.deviceDetails}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <QRCodeIcon aria-label="qrcode-icon" />
          </div>
          <div className={styles.infos}>
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>Código</label>
            </div>
            <span>{deviceInfo.code}</span>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <FullBattery aria-label="full-battery" />
          </div>
          <div className={styles.infos}>
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>Bateria</label>
            </div>
            <span>{deviceInfo.batteryVoltage || '-'}</span>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <Gear aria-label="gear-icon" />
          </div>
          <div className={styles.infos}>
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>FW</label>
            </div>
            <span>{deviceInfo.firmware || '-'}</span>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <Sensor aria-label="sensor-icon" />
          </div>
          <div className={styles.infos}>
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>Sensor principal</label>
            </div>
            <span>{deviceInfo.status || '-'}</span>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            {deviceInfo.communicationStatus ? (
              <DeviceCommunicationOn aria-label="device-communication-on" />
            ) : (
              <DeviceCommunicationOff aria-label="device-communication-off" />
            )}
          </div>
          <div className={styles.infos}>
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>Sinal</label>
              {!isRemoteControl && device.hasCommandSupport && (
                <div className={styles.signalIcon}>
                  {getSignalLevelBarIcon(device.communication?.level)}
                </div>
              )}
            </div>
            <span>{deviceInfo.rssi || '-'}</span>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <Gear aria-label="gear-icon" />
          </div>

          {device.hasCommandSupport ? (
            <div className={styles.infos}>
              <div className={styles.wrapperLabel}>
                <label className={styles.label}>HW</label>
              </div>
              <span>{deviceInfo.hardware || '-'}</span>
            </div>
          ) : (
            <div className={styles.infos}>
              <div className={styles.wrapperLabel}>
                <label className={styles.label}>Modelo</label>
              </div>
              <span>{deviceInfo.model || '-'}</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
