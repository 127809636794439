import { Button, Modal } from 'components'

import {
  useFetchQuickAlerts,
  useUpdateQuickAlert,
} from 'services/displacementMap'
import { useDisplacementModal } from '../../../../../../contexts'
import { useUserInfo } from 'shared/hooks'
import styles from './styles.module.scss'

interface StopAlertProps {
  onClose: () => void
}

export const StopAlert: React.FC<StopAlertProps> = ({ onClose }) => {
  const { userInfo } = useUserInfo()

  const userId = userInfo.id
  const { data: quickAlerts } = useFetchQuickAlerts(userId)
  const { handleCloseModal } = useDisplacementModal()
  const { mutate } = useUpdateQuickAlert()

  const handleSubmit = () => {
    const alert = quickAlerts?.find((alert) => alert.user.id === userId)

    if (alert)
      mutate(
        {
          userId: '',
          id: alert?.id,
        },
        {
          onSuccess: () => {
            handleCloseModal()
          },
        },
      )
  }

  return (
    <Modal
      simple
      isVisible
      title="Deixar de atender ocorrência"
      className={styles.container}
      onClose={handleCloseModal}
    >
      <p>
        Esta ocorrência ficará pendente e livre para ser atendida por outro
        operador. Tem certeza disto?
      </p>

      <Modal.Footer>
        <Button buttonTitle="Voltar" onClick={onClose} type="secondary" />
        <Button
          buttonTitle="Continuar"
          htmlType="submit"
          onClick={handleSubmit}
          type="primary"
        />
      </Modal.Footer>
    </Modal>
  )
}
