import { ImageResponse } from 'services/image/types'
import { OccurrenceStateName } from 'services/occurrence/types'
import { Paging, Timestamps } from 'services/types'

const occurrenceTypes = {
  ALARM: 'ALARM',
  RESCUE: 'RESCUE',
  FAILURE: 'FAILURE',
  FINISHED: 'FINISHED',
  IMMINENT: 'IMMINENT',
  EMERGENCY: 'EMERGENCY',
  COMMERCIAL: 'COMMERCIAL',
  SCHEDULED: 'SCHEDULED',
  MANUAL: 'MANUAL',
}

const tacticalStatus = {
  DISPLACEMENT: 'DISPLACEMENT',
  COMMUNICATION_ON: 'COMMUNICATION_ON',
  COMMUNICATION_OFF: 'COMMUNICATION_OFF',
  WITHOUT_COMMUNICATION: 'WITHOUT_COMMUNICATION',
  PAUSED: 'PAUSED',
}

const alertTypes = {
  COORDINATES_UPDATE_REQUEST: 'COORDINATES_UPDATE_REQUEST',
  CHECKLIST_SENT: 'CHECKLIST_SENT',
  TRAVEL_WITHOUT_CHANGES: 'TRAVEL_WITHOUT_CHANGES',
  SUPPORT_REQUEST: 'SUPPORT_REQUEST',
}

const agentDutyStatus = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  FINISHED: 'FINISHED',
}

type AgentDutyStatus = keyof typeof agentDutyStatus
export type OccurrenceTypes = keyof typeof occurrenceTypes
export type TacticalStatus = keyof typeof tacticalStatus
export type AlertTypes = keyof typeof alertTypes

export type Position = {
  latitude: number
  longitude: number
}

export type Occurrece = {
  id: string
  totalEvents: number
  name: string
  type: OccurrenceTypes
  image: string
  tags: string[]
  address: string
  tactical?: string
  comments: string
  createdAt: number
  finishedAt?: number
  route: string
  received?: number
  displaced?: number
  displayed?: number
  started?: number
  atLocation?: number
}

export type Tactical = {
  id: string
  color: string
  plate: string
  driver: string
  tracker: string
  status: TacticalStatus
  speed: number
  tags: string[]
  occurrence?: string
  lastUpdate: number
  travelMode: 'FOUR_WHEELS' | 'TWO_WHEELS'
  lastDisplacement: number
  startDisplacement: number
}

export type MarkerType = {
  id: string
  color?: string
  alert?: boolean
  position?: Position
}

export type FetchOccurrences = Pick<Occurrece, 'id' | 'tactical' | 'type'> &
  MarkerType
export type FetchTacticals = Pick<
  Tactical,
  'id' | 'status' | 'driver' | 'occurrence' | 'travelMode' | 'color'
> &
  MarkerType

export type NextAppointments = {
  id: string
  account: string
  occurrenceType: OccurrenceTypes
  serviceProfile: string
  position: Position
  tactical: Pick<
    Tactical,
    'id' | 'color' | 'plate' | 'driver' | 'travelMode' | 'status'
  >[]
  alertTime: string
  createdAt: number
  finishedAt?: number
}

export type QuickAlertResponse = {
  id: string
  title: string
  endDate: number
  startDate: number
  alertType: AlertTypes
  account: {
    id: string
    code: string
    name: string
  }
  agent: {
    id: string
    name: string
  }
  occurrence: {
    id: string
    typeName: OccurrenceTypes
    position: {
      latitude: number
      longitude: number
    }
  }
  user: {
    id: string
    name: string
  }
}

export type Car = {
  id: string
  plate: string
  model: string
}

export interface StartDutyParams {
  userId: string
  vehicleId: string
  allowOccurrenceDiscovery?: boolean
  allowMultipleLinks?: boolean
}

export const occurrenceInterventionTypeName = {
  PHONE_CALL: 'PHONE_CALL',
  IMAGE_VERIFICATION: 'IMAGE_VERIFICATION',
  SEND_MESSAGE: 'SEND_MESSAGE',
  STATE_CHANGE: 'STATE_CHANGE',
  SEND_ALERT: 'SEND_ALERT',
  SEND_IMAGE: 'SEND_IMAGE',
  SEND_CHECKLIST: 'SEND_CHECKLIST',
  CONFIRM_INTRUSION: 'CONFIRM_INTRUSION',
  MANUAL_RECORD: 'MANUAL_RECORD',
  AUTOMATIC: 'AUTOMATIC',
}

export type OccurrenceInterventionTypeName =
  keyof typeof occurrenceInterventionTypeName

export interface updateVehicleRequest {
  vehicleId: string
}

type Tag = {
  id: string
  name: string
  type: string
}

// Usado na busca da lista De táticos
const vehicleCategory = {
  CAR: 'CAR',
  MOTORCYCLE: 'MOTORCYCLE',
}

const vehicleIgnition = {
  ON: 'ON',
  OFF: 'OFF',
}

export type VehicleCategory = keyof typeof vehicleCategory
export type VehicleIgnition = keyof typeof vehicleIgnition

export interface AgentDuty {
  id: string
  agentId: string
  agentName: string
  allowMultipleLinks: boolean
  position: {
    latitude: number
    longitude: number
  }
  vehicle: {
    id: string
    vehicleCategory: VehicleCategory
    iconColor: string
    ignitionStatus: VehicleIgnition
    licensePlate: string
  }
  speed: number
  occurrencesLinked?: {
    occurrenceId: string
    order: number
    attending: boolean
  }[]
  occurrenceId?: string
  lastPositionDate: number
  status: AgentDutyStatus
}

// Usado no envio de intervenções
const stateName = {
  FINISHED: 'FINISHED',
  TRAVEL_SENT: 'TRAVEL_SENT',
  TRAVEL_VIEWED: 'TRAVEL_VIEWED',
  QUEUED: 'QUEUED',
  AVAILABLE: 'AVAILABLE',
  IN_PROGRESS: 'IN_PROGRESS',
  TRAVEL_STARTED: 'TRAVEL_STARTED',
  TRAVEL_WAITING: 'TRAVEL_WAITING',
  TRAVEL_FINISHED: 'TRAVEL_FINISHED',
}

export type StateName = keyof typeof stateName

const alerts = {
  intrusion: 'intrusion',
  tactical: 'tactical',
  checkList: 'checkList',
  supportRequest: 'supportRequest',
  coordinatesUpdateRequest: 'coordinatesUpdateRequest',
}

export type Alerts = keyof typeof alerts

export type OccurrenceEvent = {
  id: string
  createdAt: number
  deviceName: string
  partition: {
    id: string
    name: string
  }
  occurrenceType: OccurrenceTypes
  imageId?: string
  details: {
    code: string
    device: string
    name: string
    auxiliary?: string
    sensorName: string
    originName: string
  }
}

export interface OccurrenceDetails {
  id: string
  number: string
  totalEvents: number
  typeName: OccurrenceTypes
  stateName: StateName
  title?: string
  createdAt: number
  finishedAt?: number
  images: ImageResponse[]
  priority: number
  account: {
    id: string
    code: string
    name: string
    note?: string
    tags?: Tag[]
  }
  customer: {
    id: string
    name: string
    document: string
    secondName?: string
  }
  tactical?: {
    agent: string
    vehicle: string
  }
  patrimony: {
    id: string
    name: string
    note?: string
    tags?: Tag[]
    image?: string
    address: {
      city: string
      state: string
      street: string
      number: string
      acronym: string
      district: string
    }
    serviceType?: {
      id: string
      name: string
      color?: string
    }
  }
  events?: OccurrenceEvent[]
  alerts: Record<Alerts, string>
  states: {
    received: number
    travelSent?: number
    travelViewed?: number
    travelStarted?: number
    travelFinished?: number
  }
}

export interface Intervention {
  title: string
  userId: string
  tags: { name: string }[]
  typeName: OccurrenceInterventionTypeName
  note?: string
  attributes: {
    hidden?: boolean
    stateName?: StateName
    agent?: {
      id: string
      name: string
    }
    vehicle?: {
      id: string
      licensePlate: string
    }
    agentCoordinates?: Position
  }
}

// Usado na lista de ocorrencias do mapa
export interface OccurrenceResponse {
  id: string
  number: string
  color?: string
  typeName: OccurrenceTypes
  position: Position
  accountId: string
  agentId?: string
  stateName: OccurrenceStateName
  vehicleId?: string
}

export interface OccurrenceMarker extends MarkerType {
  accountId: string
  number?: string
  stateName: StateName
  tacticalId?: string
  agent?: {
    id: string
    name: string
  }
  typeName: OccurrenceTypes
}

// Usado na lista de taticos do mapa
export interface TacticalMarker extends MarkerType {
  occurrenceId?: string
  status: TacticalStatus
  allowMultipleLinks: boolean
  agent: {
    id: string
    name: string
  }
  occurrencesLinked?: {
    occurrenceId: string
    order: number
    attending: boolean
  }[]
  vehicle: {
    id: string
    category: VehicleCategory
    licensePlate: string
  }
}

// Usado nos detalhes do tatico

export interface TacticalDetails {
  id: string
  agent: {
    id: string
    name: string
  }
  vehicle: {
    id: string
    licensePlate: string
  }
  tracker: {
    model: string
    serial: number
  }
  phone: {
    provinceCode: number
    number: number
    extension: number
  }
  occurrencesOnService: {
    id: string
    typeName: OccurrenceTypes
    accountCode: string
    title?: string
    customerName: string
  }[]
  speed: number
  startDate: number
  lastPositionDate: number
  lastMovementDate: number
  status: AgentDutyStatus
}

export type AgentDutyRecords = {
  user?: string
  date: number
  description: string
  occurrenceId: string
}

export type AgentDutyRecordsPayload = {
  userId: string
  description: string
  occurrenceId: string
}

export type QuickAlertPayload = {
  userId: string
  id: string
}

export type QueryVehicleRequest = {
  description?: string
  licensePlate?: string
  vehicleCategory?: string
  trackerSerial?: number
} & Partial<Paging> &
  Partial<Timestamps>
