import { ReactNode } from 'react'

type DefaultState = { loading: ReactNode; error: ReactNode; view: ReactNode }

type StateMap<T> = T extends unknown[]
  ? DefaultState & { empty: ReactNode }
  : DefaultState

interface HandleRenderStateProps<T> {
  data: T
  isError: boolean
  isLoading: boolean
  isEmpty?: boolean
}

export const handleRenderState = <T>({
  data,
  isError,
  isLoading,
  isEmpty,
}: HandleRenderStateProps<T>): keyof StateMap<T> => {
  if (isError) {
    return 'error'
  }

  if (isLoading) {
    return 'loading'
  }

  if ((Array.isArray(data) && !data.length) || isEmpty) {
    return 'empty'
  }

  if (data) {
    return 'view'
  }

  return 'loading'
}
