import { useCallback, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { dateNow } from 'utilities/date'
import { useToast, useUserInfo } from 'shared/hooks'
import { useGetImageFile } from 'services/image'
import joinClassNames from 'utilities/joinClassNames'
import { ButtonV2, Icon, IconButton, Tag, TooltipV2 } from 'components'
import {
  Intervention as InterventionType,
  useCreateNote,
  useDeleteIntervention,
} from 'services/serviceOrderV2'
import { useModal } from 'domains/serviceOrder/screens/Details/context'

import Field, { Form } from '../Field'
import styles from './styles.module.scss'
import { Edit, Notes, UserVerification } from './components'
import { handleContent, handleDownload, handleTag } from './utils'

type InterventionProps = {
  intervention: InterventionType
}

type RenderState = 'remove' | 'edit' | 'response' | null

const Intervention = ({ intervention }: InterventionProps) => {
  const { addToast } = useToast()
  const { userInfo } = useUserInfo()
  const { serviceOrderId } = useParams()
  const { handleOpenModal, handleCloseModal } = useModal()
  const [renderState, setRenderState] = useState<RenderState>(null)

  const fieldRef = useRef<HTMLDivElement>(null)
  const note = intervention.notes?.[0]

  const { data: images } = useGetImageFile(note?.images)

  const { mutate: createNote } = useCreateNote(serviceOrderId, intervention.id)
  const { mutate: deleteIntervention } = useDeleteIntervention(
    serviceOrderId,
    intervention.id,
  )

  const handleHasNote = useCallback(() => {
    if (intervention.notes) {
      const dateDiff = intervention.notes?.[0]?.datetime - intervention.datetime

      return dateDiff < 1000
    }

    return false
  }, [intervention])

  const handleResponses = useCallback(() => {
    if (handleHasNote()) {
      return intervention.notes?.slice(1, intervention.notes?.length)
    }

    return intervention.notes
  }, [intervention, handleHasNote])

  const handleCreateNote = useCallback(
    (data: Form) => {
      const tags = data.tags?.map(({ name }) => ({
        name,
      }))

      createNote(
        {
          ...data,
          userId: userInfo.id,
          ...(tags?.length && {
            tags,
          }),
        },
        {
          onSuccess: () => {
            addToast({
              message: 'Resposta adicionada com sucesso.',
              type: 'success',
            })

            setRenderState(null)
          },

          onError: () => {
            addToast({
              message: 'Erro ao adicionar resposta. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [addToast, createNote],
  )

  const handleDelete = useCallback(() => {
    deleteIntervention(null, {
      onSuccess: () => {
        addToast({
          message: 'Nota excluída com sucesso.',
          type: 'success',
        })

        setRenderState(null)
      },
      onError: () => {
        addToast({
          message: 'Erro ao excluir nota. Tente novamente.',
          type: 'alert',
        })
      },
    })
  }, [addToast, deleteIntervention])

  const tags = note?.tags

  const handleMainContent = useCallback(() => {
    if (intervention.title) {
      return intervention.title
    }

    if (handleHasNote()) {
      return note?.note
    }

    return undefined
  }, [intervention, handleHasNote])

  const principalText = handleMainContent()

  const contentInItalic = ['PHONE_CALL', 'SEND_MESSAGE'].includes(
    intervention.typeName,
  )

  return (
    <li className={styles.container}>
      <div
        className={joinClassNames(
          styles.content,
          note?.hidden && styles.hidden,
          contentInItalic && styles.contentInItalic,
        )}
      >
        <div className={styles.title}>
          <div>
            {handleTag(intervention)}
            <span>•</span>
            <strong>{intervention.user?.name}</strong>
            <small>- {dateNow(intervention.datetime)}</small>
            {note?.updatedBy && (
              <>
                <i>(Editada)</i>
                <TooltipV2.Root>
                  <TooltipV2.Trigger>
                    <Icon
                      name="information"
                      width={10}
                      color="element-medium"
                    />
                  </TooltipV2.Trigger>
                  <TooltipV2.Content position="bottom" size="lg">
                    <span>
                      Nota editada por {note.updatedBy.name} e autorizada por{' '}
                      {note.authorizedBy?.name}
                    </span>
                  </TooltipV2.Content>
                </TooltipV2.Root>
              </>
            )}
          </div>
          <div>
            {intervention.typeName === 'MANUAL_RECORD' && (
              <IconButton>
                <Icon
                  name="edit"
                  width={14}
                  onClick={() => setRenderState('edit')}
                  color={renderState === 'edit' ? 'brand' : 'element'}
                />
              </IconButton>
            )}
            {[
              'PHONE_CALL',
              'MANUAL_RECORD',
              'STATUS_CHANGE',
              'SEND_MESSAGE',
            ].includes(intervention.typeName) && (
              <IconButton>
                <Icon
                  name="reply"
                  width={14}
                  color={renderState === 'response' ? 'brand' : 'element'}
                  onClick={() => {
                    setRenderState('response')

                    fieldRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }}
                />
              </IconButton>
            )}
            {['SEND_MESSAGE', 'PHONE_CALL', 'MANUAL_RECORD'].includes(
              intervention.typeName,
            ) && (
              <IconButton>
                <Icon
                  name="delete"
                  width={14}
                  color={renderState === 'remove' ? 'brand' : 'element'}
                  onClick={() => setRenderState('remove')}
                />
              </IconButton>
            )}
          </div>
        </div>
        {renderState === 'edit' ? (
          <Edit
            intervention={intervention}
            onCancel={() => setRenderState(null)}
            images={images?.map((image) => image.url)}
          />
        ) : (
          <>
            {principalText && <p>{principalText}</p>}

            {handleContent(intervention)}

            {tags && handleHasNote() && (
              <div className={styles.tags}>
                {tags?.map((tag) => (
                  <Tag key={tag.id} size="md">
                    {tag.name}
                  </Tag>
                ))}
              </div>
            )}
            {!!images && handleHasNote() && (
              <div className={styles.attaches}>
                <div>
                  {images.slice(0, 4).map((image, index) => {
                    return index < 3 ? (
                      <img
                        key={image.id}
                        alt=""
                        src={image.url}
                        onClick={() =>
                          handleOpenModal({
                            type: 'GALLERY',
                            data: {
                              imageId: image.id,
                              onClose: handleCloseModal,
                              images: images || [],
                            },
                          })
                        }
                      />
                    ) : (
                      <div key={image.id} className={styles.imageMessage}>
                        <img alt="" src={image.url} />
                        <button
                          onClick={() =>
                            handleOpenModal({
                              type: 'GALLERY',
                              data: {
                                imageId: image.id,
                                onClose: handleCloseModal,
                                images: images || [],
                              },
                            })
                          }
                        >
                          Ver todos
                        </button>
                      </div>
                    )
                  })}
                </div>
                <div>
                  <Tag color="outlined">
                    <Icon name="attach" color="element" width={12} />
                    {images?.length} anexos
                  </Tag>
                  <ButtonV2
                    size="md"
                    appearance="tertiary"
                    onClick={() => handleDownload(images)}
                  >
                    <Icon name="download" /> Download
                  </ButtonV2>
                </div>
              </div>
            )}
          </>
        )}

        <ul className={styles.responsesList}>
          <Notes notes={handleResponses()} intervention={intervention} />

          <div ref={fieldRef}>
            {renderState === 'response' && (
              <Field
                onSubmit={handleCreateNote}
                onCancel={() => setRenderState(null)}
                visibility={
                  intervention.notes?.[0]?.hidden ? 'private' : undefined
                }
              />
            )}
          </div>
        </ul>
      </div>
      <UserVerification
        type="delete"
        onSubmit={handleDelete}
        isOpen={renderState === 'remove'}
        onCancel={() => setRenderState(null)}
      />
    </li>
  )
}

export default Intervention
