import { useRef } from 'react'

type DebouncedFn<T> = (args: T) => void
type Timer = ReturnType<typeof setTimeout>

export const useDebounce = <T>(
  fn: DebouncedFn<T>,
  delay = 700,
): DebouncedFn<T> => {
  const timer = useRef<Timer>()

  return (args: T) => {
    clearTimeout(timer.current)

    timer.current = setTimeout(() => {
      fn(args)
    }, delay)
  }
}
