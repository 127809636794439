import { memo, RefObject, useEffect, useRef } from 'react'
import Hls from 'hls.js'

import { ReactComponent as ScreenShotIcon } from '../../svg/screenshot.svg'
import styles from './styles.module.scss'

interface VideoPlayerProps {
  className?: string
  stream: {
    url: string
    sid: string
    ubistreamToken: string
    playing: boolean
  }
  getRef: (playerRef: RefObject<HTMLVideoElement> | null) => void
  onScreenShot: (videoRef: RefObject<HTMLVideoElement>, sid: string) => void
  onProgress: (time: number) => void
  onDuration: (duration: number) => void
}

export const VideoPlayer = memo(
  ({
    stream,
    onScreenShot,
    getRef,
    onDuration,
    onProgress,
    className,
  }: VideoPlayerProps) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    const hasInitialized = useRef(false)

    const handleVideoReady = () => {
      if (videoRef.current && !hasInitialized.current) {
        const videoDuration = videoRef.current.duration
        onDuration(videoDuration)
        videoRef.current.play()

        hasInitialized.current = true
      }
    }

    useEffect(() => {
      if (Hls.isSupported() && videoRef.current) {
        const hls = new Hls({
          xhrSetup: (xhr) => {
            xhr.setRequestHeader('Cache-Control', 'no-cache')
            xhr.setRequestHeader('apikey', stream.ubistreamToken)
          },
        })
        hls.loadSource(stream.url)
        hls.attachMedia(videoRef.current)

        getRef(videoRef)

        return () => {
          hls.destroy()
        }
      }
    }, [videoRef])

    useEffect(() => {
      if (videoRef.current) {
        stream.playing ? videoRef.current.play() : videoRef.current.pause()
      }
    }, [videoRef, stream.playing])

    return (
      <div className={className}>
        <div className={styles.videoWrapper}>
          <video
            ref={videoRef}
            className={styles.video}
            controls
            onCanPlay={handleVideoReady}
            onTimeUpdate={() => {
              if (videoRef.current) {
                onProgress(videoRef.current.currentTime)
              }
            }}
            muted
            width="100%"
            height="100%"
          />
          <div className={styles.optionsContainer}>
            <button
              className={styles.option}
              onClick={() => onScreenShot(videoRef, stream.sid)}
            >
              <ScreenShotIcon width={32} height={32} />
            </button>
          </div>
        </div>
      </div>
    )
  },
)

VideoPlayer.displayName = 'VideoPlayer'
