import { useEffect, useState } from 'react'
import { UbideskPermissions } from 'routes/types'
import { usePermissions } from 'shared/hooks'

import { Button, ButtonProps } from '../Button'

type ProtectedButtonProps = ButtonProps & {
  permissionName: UbideskPermissions
}

export const ProtectedButton = (props: ProtectedButtonProps) => {
  const { permissionName, ...rest } = props

  const [shouldRender, setShouldRender] = useState(false)

  const { hasAccess } = usePermissions()

  useEffect(() => {
    setShouldRender(hasAccess(permissionName))
  }, [hasAccess, permissionName])

  return shouldRender ? <Button {...rest} /> : null
}
