import { PatrimonyWithCustomerAndAccountPayload } from 'services/account/types'
import HttpClient from 'services/httpClient'
import { useMutation } from '@tanstack/react-query'
import { endpoints } from './endpoints'

async function createCustomer(payload: PatrimonyWithCustomerAndAccountPayload) {
  const response =
    await HttpClient.post<PatrimonyWithCustomerAndAccountPayload>(
      endpoints.createCustomer,
      {
        ...payload,
        patrimony: {
          ...payload.patrimony,
          state: undefined,
          city: undefined,
          district: undefined,
          stateName: undefined,
          cityName: undefined,
          districtName: undefined,
          imageId: undefined,
        },
      },
    )
  return response.data
}

export const usePostCustomer = () =>
  useMutation({
    mutationFn: async (payload: PatrimonyWithCustomerAndAccountPayload) => {
      return await createCustomer(payload)
    },
    retry: false,
  })
