import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import {
  flattenPages,
  getNextPageOffSet,
} from '../../services/utilities/pagination'

import { DeviceDriver } from 'services/device'
import { TagQueryResponse } from 'services/auth'
import { AccountTag, TagType } from 'services/account/types'
import { Result } from 'services/types'

const getDeviceTags = async (offset = 0, name?: string) => {
  const response = await DeviceDriver.queryDeviceTags({
    offset,
    recordsPerPage: 15,
    ...(name && { name }),
  })

  const tagResponse: Result<AccountTag> = {
    data: response?.data.map((tag) => ({
      id: tag.id,
      name: tag.name,
      type: TagType.PRINCIPAL,
    })),
    totalElements: response.totalElements,
  }

  return tagResponse
}

export function useGetDeviceTags(name: string, enabled: boolean) {
  const infinityQuery = useInfiniteQuery({
    queryKey: ['device-tag', name],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => getDeviceTags(pageParam, name),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<TagQueryResponse>(lastPage, allPages),
    enabled,
  })

  const deviceTags = useMemo(
    () => flattenPages<AccountTag>(infinityQuery?.data),
    [infinityQuery],
  )

  return {
    ...infinityQuery,
    deviceTags,
  }
}
