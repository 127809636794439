import {
  NotificationType,
  PhoneCallTarget,
} from 'services/attendancePolicy/types'

export const translatedNotificationType: Record<NotificationType, string> = {
  SOUND: 'Som',
}

export const convertNotificationType: Record<string, NotificationType> = {
  Som: NotificationType.SOUND,
}

export type TranslatedPhoneCallTarget =
  | 'Agente tático'
  | 'Central de alarme'
  | 'Local'
  | 'Plano de ação'
  | 'Polícia'

export const translatedPhoneCallTarget: Record<
  PhoneCallTarget,
  TranslatedPhoneCallTarget
> = {
  TACTICAL_AGENT: 'Agente tático',
  CENTRAL: 'Central de alarme',
  PATRIMONY: 'Local',
  ACTION_PLAN: 'Plano de ação',
  POLICE: 'Polícia',
}

export const convertPhoneCallTarget: Record<
  TranslatedPhoneCallTarget,
  PhoneCallTarget
> = {
  'Agente tático': 'TACTICAL_AGENT',
  'Central de alarme': 'CENTRAL',
  Local: 'PATRIMONY',
  'Plano de ação': 'ACTION_PLAN',
  Polícia: 'POLICE',
}

export const validateJSON = (value: string): boolean => {
  try {
    JSON.parse(value)
    return true
  } catch (error) {
    return false
  }
}
