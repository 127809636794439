import { useMemo } from 'react'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { getNextPageOffSet, flattenPages } from '../utilities/pagination'
import { City } from 'services/address/city/types'
import CityDriver from 'services/address/city'

interface UseGetCitysReturn {
  isError: boolean
  isFetching: boolean
  cities: City[]
  fetchNextCitiesPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

async function getCities(offset = 0, stateId: string, name?: string) {
  const response = await CityDriver.queryCities({
    stateId,
    recordsPerPage: 15,
    offset,
    ...(name && { name }),
  })

  return response
}

export const useGetCities = (
  stateId = '',
  name?: string,
): UseGetCitysReturn => {
  const {
    isError,
    data,
    fetchNextPage: fetchNextCitiesPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['cities', stateId, name],
    queryFn: ({ pageParam }) => getCities(pageParam, stateId, name),
    initialPageParam: 0,
    enabled: Boolean(stateId),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<City>(lastPage, allPages),
  })

  const cities = useMemo(() => {
    return flattenPages<City>(data)
  }, [data])

  return { isError, isFetching, cities, fetchNextCitiesPage }
}
