import { Button, Modal } from 'components'

import styles from './styles.module.scss'
import { useCloseQuickAlert } from 'services/displacementMap'
import { useDisplacementModal } from '../../../../../../contexts'

interface CloseAlertProps {
  alertId: string
  onClose: () => void
}

export const CloseAlert = ({ alertId, onClose }: CloseAlertProps) => {
  const { handleCloseModal } = useDisplacementModal()

  const { mutate } = useCloseQuickAlert()

  const handleSubmit = () => {
    mutate(alertId, {
      onSuccess: handleCloseModal,
    })
  }

  return (
    <Modal
      simple
      isVisible
      title="Finalizar alerta"
      className={styles.container}
      onClose={handleCloseModal}
    >
      <p>
        Deseja encerrar este atendimento? Lembre-se que ainda será necessário
        acessar a tela de atendimento para finalizar a ocorrência.
      </p>

      <Modal.Footer>
        <Button buttonTitle="Voltar" onClick={onClose} type="secondary" />
        <Button
          buttonTitle="Continuar"
          htmlType="submit"
          onClick={handleSubmit}
          type="primary"
        />
      </Modal.Footer>
    </Modal>
  )
}
