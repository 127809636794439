import { ChangeEvent, useCallback, useState } from 'react'
import {
  Button,
  ButtonV2,
  Datepicker,
  Input,
  RadioGroup,
  ComboBoxV3,
  FormItem,
  FormLabel,
  Tag,
  Icon,
  TextField,
} from 'components'
import ReactDOM from 'react-dom'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'

import styles from './Filters.module.scss'
import { useFormContext } from 'react-hook-form'
import { AccountFilters } from '../../../../schemas/searchAccountSchema'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { SortedCustomer } from 'services/customer/types'

import { ReactComponent as CloseButton } from 'assets/svg/close.svg'

import { State } from 'services/address/state'
import { City } from 'services/address/city/types'
import { District } from 'services/address/district/types'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { getCustomer, useGetCustomers } from 'shared/hooks/services/customer'
import {
  useGetCities,
  useGetDistricts,
  useGetStates,
} from 'shared/hooks/services'
import { getServiceType } from 'shared/hooks/services/serviceType/useGetServiceType'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { getAccountStatus } from './utilities'
import { ACCOUNT_STATUS } from './types'
import { useDebounce, useGetAccountTags } from 'shared/hooks'
import { AccountTag } from 'services/account/types'
import { formatMACORIMEI } from 'utilities/masks/macAndImei/macAndImei'
import { useInfinitePatrimonies } from 'shared/hooks/patrimonies/useInfinitePatrimonies'
import { PatrimonyResponse } from 'shared/hooks/patrimonies/types'

export type FilterComponentProps = {
  isVisible: boolean
  onClose: () => void
  onApplyFilters: () => void
}

export const Filters = (props: FilterComponentProps) => {
  const [accountStatus, setAccountStatus] = useState<ACCOUNT_STATUS>(
    ACCOUNT_STATUS.ALL,
  )
  const { isVisible, onClose, onApplyFilters } = props

  const { watch, setValue, reset, getValues, register } =
    useFormContext<AccountFilters>()
  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const customer = watch('customer')
  const customerBySecondName = watch('customerBySecondName')
  const state = watch('state')
  const city = watch('city')
  const district = watch('district')
  const createdFrom = watch('createdFrom')
  const createdTo = watch('createdTo')
  const externalId = watch('externalId')
  const contractNumber = watch('contractNumber')
  const accountTag = watch('accountTag')
  const centralCode = watch('centralCode')

  const isDisabledSubmit =
    !Object.values(getValues()).some((value) =>
      value === false ? true : value,
    ) ||
    (!!createdFrom && !createdTo)

  const [filters, setFilters] = useState({
    city: '',
    state: '',
    district: '',
    patrimony: '',
    serviceType: '',
    customer: '',
    document: '',
    secondName: '',
    accountTag: '',
  })

  const {
    data: patrimonies,
    isError: isErrorPatrimonies,
    isFetching: isFetchingPatrimonies,
    fetchNextPage: fetchNextPatrimoniesPage,
  } = useInfinitePatrimonies({
    name: filters.patrimony,
    ...(!!customer && { customerId: customer.id }),
  })

  const {
    isError: isErrorCustomers,
    isFetching: isFetchingCustomers,
    customers,
    fetchNextCustomersPage,
  } = useGetCustomers(filters.customer, filters.document, '', true)

  const {
    states,
    isError: isErrorFetchStates,
    isFetching: isFetchingStates,
    fetchNextPage: fetchNextStatesPage,
  } = useGetStates(true, filters.state)

  const {
    cities,
    fetchNextCitiesPage,
    isError: isErrorFetchCities,
    isFetching: isFetchingCities,
  } = useGetCities(state?.id, filters.city)

  const {
    districts,
    fetchNextDistrictsPage,
    isError: isErrorFetchDistricts,
    isFetching: isFetchingDistricts,
  } = useGetDistricts(city?.id, filters.district)

  const {
    accountTags,
    fetchNextTagsPage,
    isError: isErrorAccountTags,
    isFetching: isFetchingAccountTags,
  } = useGetAccountTags(filters.accountTag)

  const handleFilter = useDebounce((newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }))
  }, 700)

  const handleChangeScheduleDateInput = useCallback(
    (formKey: string, event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        const date = convertStringToDate(value)
        setValue(formKey, date.getTime())
        return value
      } else {
        return value
      }
    },
    [setValue],
  )

  return ReactDOM.createPortal(
    <div
      className={[styles.container, isVisible ? styles.show : styles.hide]
        .filter(Boolean)
        .join(' ')}
      data-testid="filters-component"
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !isDisabledSubmit) {
          onApplyFilters()
        }
      }}
    >
      <div className={styles.innerWrapper}>
        <div className={styles.header}>
          <span>Filtros</span>
          <CloseButton
            height={16}
            width={16}
            onClick={onClose}
            className={styles.closeIcon}
            data-testid="filter-close-button"
          />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.radio}>
            <RadioGroup title="Situação">
              {Object.values(ACCOUNT_STATUS).map((option, key) => (
                <RadioButton
                  value={option}
                  onChange={(e) => {
                    const status = getAccountStatus(e.target.value)

                    setAccountStatus(status.label)
                    setValue('active', status.value, { shouldDirty: true })
                  }}
                  checked={accountStatus === option}
                  name={'active'}
                  key={key}
                />
              ))}
            </RadioGroup>
          </div>
          <Input
            id="externalId"
            label="ID externo"
            type="text"
            disabled={!!centralCode}
            value={externalId || ''}
            {...register('externalId')}
            onChange={(e) => {
              setValue('externalId', e.target.value.toUpperCase(), {
                shouldValidate: true,
              })
            }}
          />
          <Input
            type="text"
            label="Código da central"
            value={formatMACORIMEI(centralCode || '')}
            {...register('centralCode')}
            onChange={(e) => {
              setValue('centralCode', e.target.value, {
                shouldValidate: true,
              })
            }}
          />
          <FormItem>
            <FormLabel>Contrato</FormLabel>
            <TextField
              {...register('contractNumber')}
              value={contractNumber || ''}
              onChange={(event) => {
                setValue('contractNumber', event.target.value, {
                  shouldValidate: true,
                })
              }}
              disabled={!!centralCode}
            />
          </FormItem>
          <Combobox
            id="name-input"
            label={{ text: 'Cliente (Razão social)' }}
            disabled={!!centralCode}
            value={
              customer
                ? {
                    label: 'name',
                    value: customer,
                  }
                : undefined
            }
            items={parseDataToComboboxV2(customers || [], 'name')}
            onEndReached={fetchNextCustomersPage}
            isLoading={isFetchingCustomers}
            isError={isErrorCustomers}
            onSearch={(search) => handleFilter({ customer: search })}
            onChange={(selected) => {
              const selectedCustomer = selected as ComboboxItem<SortedCustomer>
              if (selectedCustomer) {
                setValue('customer', selectedCustomer?.value)
                setValue('patrimony', undefined)
              }
            }}
          />

          <FormItem>
            <FormLabel>Nome fantasia</FormLabel>
            <ComboBoxV3.Root findOptions={getCustomer} valueKey="secondName">
              {({ unSelectedOptions }) => (
                <>
                  <ComboBoxV3.Field value={customerBySecondName?.secondName} />
                  <ComboBoxV3.Group>
                    {unSelectedOptions?.map((option) => (
                      <ComboBoxV3.Option
                        key={option.id}
                        onClick={() => {
                          setValue('customerBySecondName', option)
                        }}
                      >
                        {option.secondName}
                      </ComboBoxV3.Option>
                    ))}
                  </ComboBoxV3.Group>
                </>
              )}
            </ComboBoxV3.Root>
          </FormItem>

          <Combobox
            id="document-input"
            label={{ text: 'Documento' }}
            disabled={!!centralCode}
            value={
              customer
                ? {
                    label: 'aggregatedName',
                    value: customer,
                  }
                : undefined
            }
            onEndReached={fetchNextCustomersPage}
            isLoading={isFetchingCustomers}
            isError={isErrorCustomers}
            onSearch={(search) =>
              handleFilter({ document: search.replace(/[.\-/]/g, '') })
            }
            items={parseDataToComboboxV2(customers || [], 'aggregatedName')}
            inputProps={{
              maxLength: 18,
            }}
            onChange={(selected) => {
              const selectedCustomer = selected as ComboboxItem<SortedCustomer>
              if (selectedCustomer) {
                setValue('customer', selectedCustomer?.value)
              }
            }}
          />

          <Combobox
            id="patrimony-input"
            {...register('patrimony')}
            label={{ text: 'Patrimônio' }}
            value={
              watch('patrimony')
                ? {
                    label: 'name',
                    value: watch('patrimony'),
                  }
                : undefined
            }
            onEndReached={fetchNextPatrimoniesPage}
            isLoading={isFetchingPatrimonies}
            isError={isErrorPatrimonies}
            onSearch={(search) => handleFilter({ patrimony: search })}
            items={parseDataToComboboxV2(patrimonies || [], 'name')}
            onChange={(selected) => {
              const selectedPatrimony =
                selected as ComboboxItem<PatrimonyResponse>

              if (selectedPatrimony) {
                setValue('patrimony', {
                  id: selectedPatrimony?.value.id,
                  name: selectedPatrimony?.value.name,
                })
              }
            }}
          />

          <FormItem>
            <FormLabel>Tipo de serviço</FormLabel>
            <ComboBoxV3.Root findOptions={getServiceType} valueKey="name">
              {({ unSelectedOptions }) => (
                <>
                  <ComboBoxV3.Field value={watch('serviceTypes')} />
                  {!!watch('serviceTypes')?.length && (
                    <>
                      <ComboBoxV3.Options>
                        {watch('serviceTypes').map((type) => (
                          <Tag key={type.id}>
                            <span>{type.name}</span>

                            <Icon
                              name="close-xlg"
                              width={8}
                              height={8}
                              onClick={() =>
                                setValue(
                                  'serviceTypes',
                                  watch('serviceTypes').filter(
                                    (item) => item.id !== type.id,
                                  ),
                                )
                              }
                            />
                          </Tag>
                        ))}
                      </ComboBoxV3.Options>
                      <ButtonV2
                        size="md"
                        appearance="tertiary"
                        onClick={() => setValue('serviceTypes', [])}
                      >
                        <Icon name="delete" width={12} height={12} />
                        Remover tudo
                      </ButtonV2>
                    </>
                  )}

                  <ComboBoxV3.Group>
                    {unSelectedOptions?.map((option) => (
                      <ComboBoxV3.Option
                        key={option.name}
                        shouldCloseGroup={false}
                        onClick={() => {
                          setValue('serviceTypes', [
                            ...(watch('serviceTypes') || []),
                            {
                              id: option.id,
                              name: option.name,
                            },
                          ])
                        }}
                      >
                        {option.name}
                      </ComboBoxV3.Option>
                    ))}
                  </ComboBoxV3.Group>
                </>
              )}
            </ComboBoxV3.Root>
          </FormItem>

          <Combobox
            id="accountTag"
            label={{ text: 'Tags' }}
            disabled={!!centralCode}
            value={
              accountTag
                ? {
                    label: 'name',
                    value: accountTag,
                  }
                : undefined
            }
            items={parseDataToComboboxV2(accountTags || [], 'name')}
            onEndReached={fetchNextTagsPage}
            isLoading={isFetchingAccountTags}
            isError={isErrorAccountTags}
            onSearch={(search) => handleFilter({ accountTag: search })}
            onChange={(selected) => {
              const selectedTag = selected as ComboboxItem<AccountTag>
              if (selectedTag) {
                setValue('accountTag', selectedTag?.value)
              }
            }}
          />
          <Combobox
            id="state-input"
            label={{ text: 'Estado' }}
            disabled={!!centralCode}
            value={
              state
                ? {
                    label: 'name',
                    value: state,
                  }
                : undefined
            }
            items={parseDataToComboboxV2(states, 'name')}
            onSearch={(search) => handleFilter({ state: search })}
            isError={isErrorFetchStates}
            isLoading={isFetchingStates}
            onEndReached={fetchNextStatesPage}
            onChange={(selected) => {
              const state = selected as ComboboxItem<State>
              if (state) {
                setValue('state', state?.value)
                if (city) {
                  setValue('city', undefined)
                }
              }
            }}
          />
          <Combobox
            id="city-input"
            label={{ text: 'Cidade' }}
            value={
              city
                ? {
                    label: 'name',
                    value: city,
                  }
                : undefined
            }
            onSearch={(search) => handleFilter({ city: search })}
            items={parseDataToComboboxV2(cities || [], 'name')}
            onEndReached={fetchNextCitiesPage}
            isError={isErrorFetchCities}
            isLoading={isFetchingCities}
            onChange={(selected) => {
              const city = selected as ComboboxItem<City>
              setValue('city', city?.value)

              if (district) {
                setValue('district', undefined)
              }
            }}
            disabled={!state}
          />
          <Combobox
            id="district-input"
            label={{ text: 'Bairro' }}
            value={
              district
                ? {
                    label: 'name',
                    value: district,
                  }
                : undefined
            }
            items={parseDataToComboboxV2(districts || [], 'name')}
            onEndReached={fetchNextDistrictsPage}
            isError={isErrorFetchDistricts}
            isLoading={isFetchingDistricts}
            onChange={(selected) => {
              const district = selected as ComboboxItem<District>
              setValue('district', district?.value)
            }}
            disabled={!city}
          />
          <Input
            id="address"
            label="Endereço"
            disabled={!!centralCode}
            autoComplete="off"
            {...register('address')}
          />
          <div
            className={styles.datePickerWrapper}
            data-testid="create-date-filter-wrapper"
          >
            <p className={styles.sectionLabel}>Cadastro</p>
            <div className={styles.datePickerInnerWrapper}>
              <span className={styles.dateLabel}>De</span>
              <Datepicker
                id="createdFrom"
                initialValueInput={
                  createdFrom
                    ? convertDateToString(new Date(createdFrom))
                    : undefined
                }
                initialDate={createdFrom ? new Date(createdFrom) : undefined}
                onChangeInput={(event) =>
                  handleChangeScheduleDateInput('createdFrom', event)
                }
                disabled={!!centralCode}
                onChangeDate={(date) => {
                  setValue('createdFrom', date?.getTime())
                }}
              />
              <span className={styles.dateLabel}>até</span>
              <Datepicker
                id="createdTo"
                disabled={!createdFrom}
                selectEnd
                initialValueInput={
                  createdTo
                    ? convertDateToString(new Date(createdTo))
                    : undefined
                }
                initialDate={createdFrom ? new Date(createdFrom) : undefined}
                onChangeInput={(event) =>
                  handleChangeScheduleDateInput('createdTo', event)
                }
                onChangeDate={(date) => setValue('createdTo', date?.getTime())}
                errorMessage={
                  createdFrom && !createdTo ? 'Campo obrigatório.' : ''
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              reset({ address: '' })
              clearAllLocalStorageFilters()
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            width="162px"
            onClick={onApplyFilters}
          />
        </div>
      </div>
    </div>,
    document.getElementById('main-container') || document.body,
  )
}
