import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { CustomerDetails } from './components'
import {
  ComboBoxV3,
  TextareaV2,
  FormLabel,
  FormItem,
  ButtonV2,
  SelectV2,
  Icon,
  Info,
  Tag,
  TextField,
} from 'components'

import { fetchTechnicians } from 'services/serviceOrder/hooks/useGetTechnicians'
import { fetchAccounts } from 'shared/hooks/accounts/useGetAccounts'
import ScheduleServiceOrderModal from '../ScheduleServiceOrderModal'
import { fetchServiceOrderLabel } from 'services/serviceOrderLabel'
import { getCustomer } from 'shared/hooks/services'
import { serviceOrderSchema } from './schemas'
import { useToggle } from 'shared/hooks'
import { dateNow, maskedDate } from 'utilities/date'
import {
  getServiceOrderTags,
  ServiceOrderPayload,
  serviceOrderTypes,
  schedulePeriods,
  ServiceOrder,
  useGetAccount,
} from 'services/serviceOrderV2'

import { contactQuery } from 'services/contact'

import styles from './styles.module.scss'
import { SERVICE_ORDER_TYPE_ENUM } from 'services/serviceOrder'
import { formatGenericPhone } from 'domains/serviceOrders/utilities/mask/phone'

type ServiceOrderFormProps = {
  onSubmit: (data: ServiceOrderPayload) => void
  serviceOrder?: ServiceOrder
}

const ServiceOrderForm = ({
  onSubmit,
  serviceOrder,
}: ServiceOrderFormProps) => {
  const navigate = useNavigate()

  const { refetch } = useGetAccount(
    serviceOrder?.accountId || localStorage.getItem('accountId') || '',
  )

  const form = useForm<Omit<ServiceOrderPayload, 'userId'>>({
    resolver: joiResolver(serviceOrderSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: async () => {
      const { data } = await refetch()

      if (data) {
        const { account, customer } = data

        return {
          customer,
          id: serviceOrder?.id,
          note: serviceOrder?.note,
          labels: serviceOrder?.labels,
          number: serviceOrder?.number,
          technician: serviceOrder?.technician,
          type: serviceOrder?.serviceOrderType,
          status: serviceOrder?.status || 'OPEN',
          scheduleDate: serviceOrder?.scheduleDate,
          schedulePeriod: serviceOrder?.schedulePeriod,
          account: {
            id: account.id,
            code: account.code,
            aggregatedName: account.name,
          },
          ...(serviceOrder?.contact && {
            contact: serviceOrder?.contact,
          }),
          ...(serviceOrder?.tags && {
            tags: serviceOrder.tags.map((tag) => ({ name: tag.name })),
          }),
        }
      }
    },
  })

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = form

  const {
    tags,
    labels,
    account,
    customer,
    technician,
    scheduleDate,
    schedulePeriod,
    contact,
    note,
    id,
    number,
    type,
    status,
  } = watch()

  const {
    isVisible: isScheduleModalOpen,
    hide: hideScheduleModal,
    show: showScheduleModal,
  } = useToggle()

  const handleGetOriginLabel = useCallback(
    async (searchFilter?: string, offset = 0) => {
      const response = await fetchServiceOrderLabel({
        offset,
        active: true,
        type: 'ORIGIN',
        recordsPerPage: 15,
        ...(searchFilter && { description: searchFilter }),
      })

      return response
    },
    [],
  )

  const handleGetReasonLabel = useCallback(
    async (searchFilter?: string, offset = 0) => {
      const response = await fetchServiceOrderLabel({
        offset,
        active: true,
        type: 'REASON',
        recordsPerPage: 15,
        ...(searchFilter && { description: searchFilter }),
      })

      return response
    },
    [],
  )

  const handleGetContacts = useCallback(
    async (searchFilter: string) => {
      const response = await contactQuery({
        customerId: customer?.id,
        ...(searchFilter && {
          name: searchFilter,
        }),
        hasPhone: true,
      })

      return {
        data: [{ name: 'Outro' }, ...response.data],
        totalElements: response.totalElements + 1,
      }
    },
    [customer],
  )

  const handleGetTechnicians = useCallback(
    async (
      searchFilter: string,
      offset = 0,
      serviceOrderType: SERVICE_ORDER_TYPE_ENUM,
    ) => {
      return await fetchTechnicians(
        account?.id,
        type || serviceOrderType,
        offset,
        searchFilter,
      )
    },
    [type, account],
  )

  const handleGetAccounts = useCallback(
    async (searchFilter: string, offset = 0) =>
      await fetchAccounts(offset, 15, customer?.id, {
        ...(searchFilter && {
          code: searchFilter,
        }),
      }),
    [customer],
  )

  const reasonLabel = useMemo(
    () => labels?.find((label) => label.type === 'REASON'),
    [labels],
  )

  const originLabel = useMemo(
    () => labels?.find((label) => label.type === 'ORIGIN'),
    [labels],
  )

  const handleSchedule = useCallback(
    (serviceOrder: ServiceOrderPayload) => {
      hideScheduleModal()
      setValue('schedulePeriod', serviceOrder.schedulePeriod)
      setValue(
        'tags',
        serviceOrder.tags?.map((tag) => ({ name: tag.name })),
      )
      setValue('contact', {
        id: serviceOrder.contact?.id || '',
        number: serviceOrder.contact?.number,
        name: serviceOrder.contact?.name || '',
        countryCode: serviceOrder.contact?.countryCode,
        provinceCode: serviceOrder.contact?.provinceCode,
      })
      setValue('scheduleDate', serviceOrder.scheduleDate)
      setValue('technician', serviceOrder.technician)
      setValue('status', serviceOrder.status)
      setValue('note', serviceOrder.note, {
        shouldValidate: true,
      })
    },
    [hideScheduleModal, setValue],
  )

  const handleResetOptionalValues = () => {
    setValue('contact', undefined)
    setValue('technician', undefined)
    setValue('scheduleDate', undefined)
    setValue('schedulePeriod', undefined)
  }

  const serviceOrderForSchedule = {
    customer,
    account: {
      code: account?.code,
      id: account?.id,
    },
    technician,
    type,
    contact,
    number,
    schedulePeriod,
    tags,
    scheduleDate,
    status,
    note,
    id,
  }

  const handleFormatScheduleDate = () => {
    if (scheduleDate) {
      return !schedulePeriod
        ? dateNow(scheduleDate)
        : `${maskedDate(scheduleDate)}${schedulePeriod ? ` - ${schedulePeriods[schedulePeriod]}` : ''}`
    }

    return 'Não há agendamento para esta ordem de serviço'
  }

  return (
    <>
      <FormProvider {...form}>
        {isScheduleModalOpen && (
          <ScheduleServiceOrderModal
            onSave={handleSchedule}
            onClose={hideScheduleModal}
            serviceOrder={serviceOrderForSchedule}
          />
        )}

        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.container}>
            <div>
              <div>
                <div className={styles.header}>
                  <FormItem>
                    <FormLabel>Cliente</FormLabel>
                    <ComboBoxV3.Root valueKey="name" findOptions={getCustomer}>
                      {({ unSelectedOptions }) => (
                        <>
                          <ComboBoxV3.Field
                            value={customer?.name || ''}
                            disabled={!!serviceOrder?.id}
                          />
                          <ComboBoxV3.Group>
                            {unSelectedOptions?.map((option) => (
                              <ComboBoxV3.Option
                                key={option.id}
                                onClick={() => {
                                  setValue(
                                    'customer',
                                    {
                                      id: option.id,
                                      name: option.name,
                                    },
                                    {
                                      shouldValidate: true,
                                    },
                                  )

                                  if (option.accounts?.length === 1) {
                                    const account = option.accounts[0]

                                    setValue(
                                      'account',
                                      {
                                        id: account.id,
                                        code: account?.code || '',
                                        aggregatedName: account.aggregatedName,
                                      },
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  } else {
                                    setValue('account', undefined, {
                                      shouldValidate: true,
                                    })
                                  }

                                  handleResetOptionalValues()
                                }}
                              >
                                {option.name}
                              </ComboBoxV3.Option>
                            ))}
                          </ComboBoxV3.Group>
                        </>
                      )}
                    </ComboBoxV3.Root>
                  </FormItem>
                  <FormItem>
                    <FormLabel>Conta</FormLabel>
                    <ComboBoxV3.Root
                      findOptions={handleGetAccounts}
                      valueKey="aggregatedAccountName"
                    >
                      {({ unSelectedOptions }) => (
                        <>
                          <ComboBoxV3.Field
                            disabled={!!serviceOrder?.id}
                            value={watch('account')?.aggregatedName || ''}
                          />
                          <ComboBoxV3.Group>
                            {unSelectedOptions?.map((option) => (
                              <ComboBoxV3.Option
                                key={option.id}
                                onClick={() => {
                                  setValue(
                                    'account',
                                    {
                                      id: option.id,
                                      code: option?.code || '',
                                      aggregatedName:
                                        option.aggregatedAccountName,
                                    },
                                    {
                                      shouldValidate: true,
                                    },
                                  )

                                  if (option.customer) {
                                    setValue(
                                      'customer',
                                      {
                                        id: option.customer?.id,
                                        name: option.customer?.name,
                                      },
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }

                                  handleResetOptionalValues()
                                }}
                              >
                                {option.aggregatedAccountName}
                              </ComboBoxV3.Option>
                            ))}
                          </ComboBoxV3.Group>
                        </>
                      )}
                    </ComboBoxV3.Root>
                  </FormItem>
                </div>
                <section>
                  <div className={styles.head}>
                    <span>1</span>
                    <div>
                      <Info.Root>
                        <Info.Title className={styles.headTitle}>
                          Dados gerais
                        </Info.Title>
                        <Info.Content>
                          Informações básicas sobre o serviço a ser realizado
                        </Info.Content>
                      </Info.Root>
                    </div>
                  </div>
                  <div className={styles.sectionItens}>
                    <FormItem>
                      <FormLabel>Tipo de OS</FormLabel>
                      <SelectV2.Root
                        valueKey="name"
                        data={Object.keys(serviceOrderTypes).map(
                          (serviceOrderType) => ({
                            name: serviceOrderType,
                          }),
                        )}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <SelectV2.Field
                              value={type ? serviceOrderTypes[type] : ''}
                            />
                            <SelectV2.Group>
                              {unSelectedOptions?.map((option) => (
                                <SelectV2.Option
                                  key={option.name}
                                  onClick={async () => {
                                    setValue('type', option.name, {
                                      shouldValidate: true,
                                    })

                                    const result = await handleGetTechnicians(
                                      '',
                                      0,
                                      option.name as SERVICE_ORDER_TYPE_ENUM,
                                    )

                                    if (result?.data?.length) {
                                      setValue(
                                        'technician',
                                        {
                                          id: result.data[0].id,
                                          name: result.data[0].name,
                                        },
                                        { shouldValidate: true },
                                      )
                                    }
                                  }}
                                >
                                  {serviceOrderTypes[option.name]}
                                </SelectV2.Option>
                              ))}
                            </SelectV2.Group>
                          </>
                        )}
                      </SelectV2.Root>
                    </FormItem>
                    <FormItem>
                      <FormLabel>Motivo</FormLabel>
                      <ComboBoxV3.Root
                        valueKey="description"
                        findOptions={handleGetReasonLabel}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field
                              value={reasonLabel?.description || ''}
                            />
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.id}
                                  onClick={() => {
                                    const newLabel = [
                                      {
                                        id: option.id,
                                        type: option.type,
                                        active: option.active,
                                        description: option.description,
                                      },
                                    ]

                                    originLabel && newLabel.push(originLabel)

                                    setValue('labels', newLabel, {
                                      shouldValidate: true,
                                    })
                                  }}
                                >
                                  {option.description}
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                    </FormItem>
                    <FormItem>
                      <FormLabel>Solicitante</FormLabel>
                      <ComboBoxV3.Root
                        valueKey="description"
                        findOptions={handleGetOriginLabel}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field
                              value={originLabel?.description || ''}
                            />
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.id}
                                  onClick={() => {
                                    const newLabel = [
                                      {
                                        id: option.id,
                                        type: option.type,
                                        active: option.active,
                                        description: option.description,
                                      },
                                    ]

                                    reasonLabel && newLabel.push(reasonLabel)

                                    setValue('labels', newLabel, {
                                      shouldValidate: true,
                                    })
                                  }}
                                >
                                  {option.description}
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                    </FormItem>
                    <FormItem>
                      <FormLabel>Contato responsável</FormLabel>
                      <ComboBoxV3.Root
                        valueKey="name"
                        findOptions={handleGetContacts}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field
                              value={
                                contact && !contact?.id
                                  ? 'Outro'
                                  : contact?.name || ''
                              }
                              disabled={!account}
                            />
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.id}
                                  onClick={() => {
                                    setValue(
                                      'contact',
                                      {
                                        ...(!!option?.id && {
                                          id: option.id,
                                        }),
                                        name: option.name,
                                        number: option.phones?.[0].number,
                                        countryCode:
                                          option.phones?.[0].countryCode,
                                        provinceCode:
                                          option.phones?.[0].provinceCode,
                                      },
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }}
                                >
                                  {option.name}
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                      {contact && !contact?.id && (
                        <div className={styles.contact}>
                          <FormItem>
                            <FormLabel>Nome</FormLabel>
                            <TextField
                              {...register('contact.name')}
                              value={
                                contact && contact.name !== 'Outro'
                                  ? contact.name
                                  : ''
                              }
                            />
                          </FormItem>
                          <FormItem>
                            <FormLabel>Telefone</FormLabel>
                            <TextField
                              maxLength={15}
                              value={
                                contact
                                  ? formatGenericPhone(
                                      String(contact.provinceCode || '') +
                                        String(contact.number || ''),
                                    )
                                  : ''
                              }
                              onChange={(event) =>
                                setValue('contact', {
                                  countryCode: 55,
                                  name: contact?.name || '',
                                  provinceCode: Number(
                                    event.target?.value
                                      .replace(/\D/g, '')
                                      .substring(0, 2),
                                  ),
                                  number: Number(
                                    event.target?.value
                                      .replace(/\D/g, '')
                                      .substring(2),
                                  ),
                                })
                              }
                            />
                          </FormItem>
                        </div>
                      )}
                    </FormItem>
                    <FormItem>
                      <FormLabel>Descrição do serviço</FormLabel>
                      <TextareaV2
                        rows={5}
                        maxLength={5000}
                        {...register('note')}
                      />
                    </FormItem>
                    <FormItem>
                      <FormLabel>Tags</FormLabel>
                      <ComboBoxV3.Root
                        valueKey="name"
                        findOptions={getServiceOrderTags}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field value={tags} />
                            {!!watch('tags')?.length && (
                              <>
                                <ComboBoxV3.Options className={styles.options}>
                                  {watch('tags')?.map((tag) => (
                                    <Tag key={tag.name}>
                                      <span>{`${tag.name}`}</span>
                                      <Icon
                                        name="close-xlg"
                                        width={8}
                                        height={8}
                                        onClick={() =>
                                          setValue(
                                            'tags',
                                            watch('tags')?.filter(
                                              (item) => item.name !== tag.name,
                                            ),
                                            {
                                              shouldValidate: true,
                                            },
                                          )
                                        }
                                      />
                                    </Tag>
                                  ))}
                                </ComboBoxV3.Options>
                                <ButtonV2
                                  size="md"
                                  appearance="tertiary"
                                  onClick={() =>
                                    setValue('tags', [], {
                                      shouldValidate: true,
                                    })
                                  }
                                >
                                  <Icon name="delete" width={12} height={12} />
                                  Remover tudo
                                </ButtonV2>
                              </>
                            )}
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.id}
                                  shouldCloseGroup={false}
                                  onClick={() => {
                                    setValue(
                                      'tags',
                                      [
                                        ...(watch('tags') || []),
                                        {
                                          name: option.name,
                                        },
                                      ],
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }}
                                >
                                  {option.name}
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                    </FormItem>
                  </div>
                </section>
                <section>
                  <div className={styles.head}>
                    <span>2</span>
                    <div>
                      <Info.Root>
                        <Info.Title className={styles.headTitle}>
                          Agendamento
                        </Info.Title>
                        <Info.Content>
                          Data e hora da execução da ordem de serviço
                        </Info.Content>
                      </Info.Root>
                    </div>
                  </div>
                  <div className={styles.sectionItens}>
                    <FormItem>
                      <FormLabel>Técnico responsável</FormLabel>
                      <ComboBoxV3.Root
                        valueKey="name"
                        findOptions={handleGetTechnicians}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field
                              value={watch('technician.name')}
                            />
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.name}
                                  hasLabel
                                  onClick={() => {
                                    setValue(
                                      'technician',
                                      {
                                        id: option.id,
                                        name: option.name,
                                      },
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }}
                                >
                                  {option.name}
                                  <span>
                                    {option.isAccountTechnician &&
                                      'Responsável'}
                                  </span>
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                    </FormItem>
                    {!serviceOrder?.id && (
                      <>
                        <Info.Root>
                          <Info.Title>Agendamentos</Info.Title>
                          <Info.Content>
                            {handleFormatScheduleDate()}
                          </Info.Content>
                        </Info.Root>
                        <ButtonV2
                          appearance="tertiary"
                          onClick={showScheduleModal}
                          disabled={!customer?.id || !account?.id}
                        >
                          <Icon name={scheduleDate ? 'edit' : 'add'} />
                          {scheduleDate
                            ? 'Editar agendamento da ordem de serviço'
                            : 'Agendar ordem de serviço'}
                        </ButtonV2>
                      </>
                    )}
                  </div>
                </section>
              </div>
            </div>
            <div>
              <CustomerDetails accountId={account?.id} />
            </div>
          </div>

          <footer>
            <ButtonV2 appearance="secondary" onClick={() => navigate(-1)}>
              Voltar
            </ButtonV2>
            <ButtonV2 type="submit" disabled={!isValid}>
              Salvar
            </ButtonV2>
          </footer>
        </form>
      </FormProvider>
    </>
  )
}

export default ServiceOrderForm
