import Joi from '@hapi/joi'
import { StringSchemeErrorsEnum } from 'utilities/validation'

export const resetPasswordSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow('')
    .messages({
      [StringSchemeErrorsEnum.EMAIL]:
        'Um email válido segue o formato: nome@domínio.tld',
    }),
})
