import { Central, EquipmentsModel } from 'services/serviceOrder'

import { CentralInformation } from '../central/CentralInformation'
import { DevicesInformation } from '../devices/DevicesInformation/DevicesInformation'

import styles from './EquipmentsInfo.module.scss'
import { PartitionDevice } from 'services/partition/types'
import { ServiceOrderImagePayload } from 'domains/serviceOrders/types'

interface EquipmentsInfoProps {
  handleSelect?: (device: PartitionDevice) => void
  centralInfo?: Central
  serviceOrder: ServiceOrderImagePayload
  onPressGallery?: () => void
  model?: EquipmentsModel
}

export const EquipmentsInfo = ({
  handleSelect,
  centralInfo,
  onPressGallery,
  model,
  serviceOrder,
}: EquipmentsInfoProps): JSX.Element => {
  if (!centralInfo) return <></>
  return (
    <div className={styles.wrapperEquipmentsValidation}>
      <section className={styles.centralInfo}>
        <CentralInformation
          central={centralInfo}
          model={model}
          onPressGallery={onPressGallery}
          serviceOrder={serviceOrder}
        />
      </section>
      <section className={styles.devicesInfo}>
        <DevicesInformation
          partitions={centralInfo.partitions}
          handleSelect={handleSelect}
        />
      </section>
    </div>
  )
}
