import { useState } from 'react'

import { Combobox } from 'components/ComboboxV2/Combobox'

import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import { ConditionType } from 'services/attendancePolicy/types'
import { useGetServiceType } from 'shared/hooks/services/serviceType/useGetServiceType'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { ComboboxItem } from 'components'

export const ServiceType = ({ onChange, data }: ConditionOptionForm) => {
  const { facts } = data

  const [serviceTypeFilter, setServiceTypeFilter] = useState('')

  const {
    serviceTypes,
    isFetching: isFetchingServiceTypes,
    fetchNextServiceTypesPage,
  } = useGetServiceType(serviceTypeFilter)

  return (
    <Combobox
      id={`service-type-${data.id}`}
      placeholder="Selecione um tipo"
      label={{ text: 'Tipo de serviço' }}
      value={
        facts?.serviceType
          ? { label: 'name', value: facts?.serviceType }
          : undefined
      }
      items={parseDataToComboboxV2(
        serviceTypes?.map((serviceType) => ({
          id: serviceType.id,
          name: serviceType.name,
        })) || [],
        'name',
      )}
      onChange={(selected) => {
        const selectedServiceType = selected as ComboboxItem<{
          id: string
          name: string
        }>

        onChange({
          type: ConditionType.SERVICE_TYPE,
          facts: {
            serviceType: selectedServiceType.value,
          },
        })
      }}
      isLoading={isFetchingServiceTypes}
      onEndReached={fetchNextServiceTypesPage}
      onSearch={(search) => setServiceTypeFilter(search)}
    />
  )
}
