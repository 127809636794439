import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useToast } from 'shared/hooks'

import { HolidayForm } from 'domains/holiday/components'

import { Loader } from 'components'
import { useGetHoliday } from '../../hooks/useGetHoliday'
import { usePutHoliday } from '../../hooks/usePutHoliday'
import { HolidayPayload } from 'services/holiday/types'
import { parseCoverageInfoToEntityFormat } from 'domains/holiday/utilities'

export const UpdateHoliday = () => {
  const { addToast } = useToast()
  const location = useLocation()
  const navigate = useNavigate()

  const {
    isFetching,
    data: holiday,
    isLoading,
  } = useGetHoliday(location.state?.holidayId)

  const { mutate: mutateUpdateHoliday } = usePutHoliday(
    location.state?.holidayId,
  )

  const onSave = useCallback(
    (data: HolidayPayload) => {
      const { year, ...rest } = data

      const payload: HolidayPayload = {
        ...rest,
        ...(!!year && { year }),
      }

      mutateUpdateHoliday(payload, {
        onSuccess: () => {
          addToast({ message: 'Feriado editado com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          addToast({
            message: 'Erro ao editar feriado. Tente novamente.',
            type: 'alert',
          })
        },
      })
    },
    [mutateUpdateHoliday, addToast, navigate],
  )

  if (isFetching || isLoading || !holiday) {
    return <Loader isVisible />
  }

  return (
    <HolidayForm
      onGoBack={() => navigate(-1)}
      onSubmit={onSave}
      holiday={{
        name: holiday.name,
        repeatable: holiday?.repeatable,
        day: holiday.day,
        month: holiday.month,
        year: holiday?.year,
        coverageType: holiday.coverageType,
        coverages: parseCoverageInfoToEntityFormat(holiday?.coverage),
      }}
    />
  )
}
