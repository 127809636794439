import { useForm } from 'react-hook-form'

import { useToast } from 'shared/hooks'
import { Button, Modal } from 'components'
import { usePatchAccountNote } from 'services/attendance'
import {
  AccountNotesData,
  RouteParams,
} from 'domains/occurrence/screens/Attendance/types'
import { useModal } from 'domains/occurrence/screens/Attendance/context'

import styles from './styles.module.scss'
import { useParams } from 'react-router-dom'
import { FC } from 'react'

interface FormProps {
  note: string
}

const AccountNotes: FC<AccountNotesData> = ({ accountId, note }) => {
  const { occurrenceId } = useParams<RouteParams>()

  const { addToast } = useToast()
  const { handleCloseModal } = useModal()

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormProps>()

  const { mutate, isPending } = usePatchAccountNote(occurrenceId)

  const onSubmit = ({ note }: FormProps) => {
    mutate(
      {
        accountId,
        note,
      },
      {
        onSuccess: () => {
          addToast({
            message: 'Anotações atualizadas com sucesso.',
          })
          handleCloseModal()
        },
        onError: () => {
          addToast({
            message: `Erro ao ${
              note ? 'editar' : 'adicionar'
            } anotação. Tente novamente.`,
            type: 'alert',
          })
        },
      },
    )
  }

  return (
    <Modal
      isVisible
      size="md"
      title="Anotações da conta"
      onClose={handleCloseModal}
      className={styles.container}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <textarea
          defaultValue={note}
          {...register('note')}
          onChange={(event) =>
            setValue('note', event.target.value, {
              shouldDirty: true,
            })
          }
        />
        <Modal.Footer>
          <Button
            type="secondary"
            buttonTitle="Voltar"
            onClick={handleCloseModal}
            width="172px"
          />
          <Button
            type="primary"
            buttonTitle="Salvar"
            width="172px"
            htmlType="submit"
            disabled={!isDirty || isPending}
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AccountNotes
