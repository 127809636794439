import { TechnicianStatusOption } from './types'

export const handleCheckboxSelectedOption = (
  selectedOption: string,
  options: string[],
) => {
  const valueAlreadyExists = options.some((type) => type === selectedOption)

  if (valueAlreadyExists)
    return options.filter((type) => type !== selectedOption)

  return [...options, selectedOption]
}

export const technicianStatusOptions: TechnicianStatusOption[] = [
  { key: 'WITH_TECHNICIAN', label: 'Com técnico', value: true },
  { key: 'WITHOUT_TECHNICIAN', label: 'Sem técnico', value: false },
  { key: 'BOTH', label: 'Ambos', value: undefined },
]

export const getTechnicianStatus = (
  key: string,
): TechnicianStatusOption | undefined =>
  technicianStatusOptions.find((option) => option.key === key)
