import { Info, Skeleton } from 'components'

const Loading = () => (
  <>
    {Array.from({ length: 3 }).map((_, index) => (
      <Info.Root key={index}>
        <Info.Title>
          <Skeleton height={30} width="70%" />
        </Info.Title>
        <Info.Content>
          <Skeleton height={240} width="70%" />
        </Info.Content>
      </Info.Root>
    ))}
  </>
)

export default Loading
