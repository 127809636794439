import { FC } from 'react'

import { PhoneCallTarget, HistoryIntervention } from 'services/attendance/types'
import { useModal } from 'domains/occurrence/screens/Attendance/context'
import { ReactComponent as OccurrenceIcon } from 'assets/svg/occurrenceExclamation.svg'

import styles from './styles.module.scss'

interface AlertInterventionCardProps {
  intervention: HistoryIntervention
}

const AlertInterventionCard: FC<AlertInterventionCardProps> = ({
  intervention,
}) => {
  const { handleOpenModal } = useModal()

  const handleChooseScenario = () => {
    const callTarget = intervention.attributes
      ?.phoneCallTarget as PhoneCallTarget

    handleOpenModal('AUTOMATIC_RULE', {
      intervention,
      type: callTarget,
    })
  }

  return (
    <li className={styles.container}>
      <div>
        <OccurrenceIcon />
        <span>
          Atenção! Existe uma ligação pendente para o local desta ocorrência.
        </span>
      </div>
      <button type="button" onClick={handleChooseScenario}>
        VER MAIS
      </button>
    </li>
  )
}

export default AlertInterventionCard
