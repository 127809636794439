import { useEffect } from 'react'
import { usePostAnnulDeviceCommand } from 'shared/hooks/services/commands'
import { useToast } from 'shared/hooks'
import { useQueryClient } from '@tanstack/react-query'

export const usePostAndHandleSentAnnulDeviceCommand = (isAnnuled: boolean) => {
  const { annulDeviceCommandMutationStatus, mutateAnnulDeviceCommand } =
    usePostAnnulDeviceCommand()

  const queryClient = useQueryClient()
  const { addToast } = useToast()

  useEffect(() => {
    if (annulDeviceCommandMutationStatus === 'success') {
      addToast({
        message: isAnnuled
          ? 'Equipamento reativado com sucesso.'
          : 'Equipamento anulado com sucesso.',
      })

      queryClient.invalidateQueries({ queryKey: ['equipments'] })
    }

    if (annulDeviceCommandMutationStatus === 'error') {
      addToast({
        message: isAnnuled
          ? 'Erro ao reativar equipamento. Tente novamente.'
          : 'Erro ao anular equipamento. Tente novamente.',
        type: 'alert',
      })
    }
  }, [addToast, queryClient, annulDeviceCommandMutationStatus])

  return {
    mutateAnnulDeviceCommand,
    annulDeviceCommandMutationStatus,
  }
}
