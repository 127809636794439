import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import {
  OccurrenceTypeName,
  OccurrenceTypeQueryResponse,
} from 'services/occurrence/types'

import OccurrenceService from 'services/occurrence'
import { OccurrenceTypeTitle } from 'domains/occurrence/data/occurrence'

type UseGetOccurrenceType = {
  occurrenceTypes: OccurrenceTypeQueryResponse[] | undefined
  isFetching: boolean
  isError: boolean
  fetchNextOccurrenceTypesPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const getOccurrenceTypes = async (offset = 0, filter?: string) => {
  const response = await OccurrenceService.queryOccurrenceType({
    recordsPerPage: 15,
    offset,
    ...(filter && { name: filter }),
  })

  const normalizedOccurrenceTypeName = response.data.map((occurrenceType) => {
    return {
      ...occurrenceType,
      originName: occurrenceType.name,
      name: OccurrenceTypeTitle[occurrenceType.name as OccurrenceTypeName],
    }
  })
  return {
    data: normalizedOccurrenceTypeName,
    totalElements: response.totalElements,
  }
}

export function useGetOccurrenceType(filter?: string): UseGetOccurrenceType {
  const {
    isError,
    data,
    isFetching,
    fetchNextPage: fetchNextOccurrenceTypesPage,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['occurrenceTypes', filter],
    queryFn: ({ pageParam }) => getOccurrenceTypes(pageParam, filter),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<OccurrenceTypeQueryResponse>(lastPage, allPages),
  })

  const occurrenceTypes = useMemo(
    () => flattenPages<OccurrenceTypeQueryResponse>(data),
    [data],
  )

  return {
    isError,
    occurrenceTypes,
    fetchNextOccurrenceTypesPage,
    isFetching,
  }
}
