import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  FC,
} from 'react'

import { Position, MarkerType } from 'services/displacementMap/types'
import { useLocation } from 'react-router-dom'
import { useDisplacementData } from './data'

interface Provider {
  children: ReactNode
}

type RoutesKey = 'tactical' | 'occurrence'

type HandleRoute = {
  type: RoutesKey
  marker: MarkerType
}

type Route = {
  id: string
  position: Position
  focused: boolean
}

type SelectedRoute = {
  tactical?: Route
  occurrence?: Route
}

interface MapContextProps {
  map?: google.maps.Map
  selectedRoute: SelectedRoute
  handleCentralization: () => void
  handleRoute: (params: HandleRoute) => void
  handleMap: (param: google.maps.Map) => void
  handleUnfocusedRoute: (params: RoutesKey) => void
}

const ZOOM_WITH_TWO_MARKERS = { left: 464, right: 456, top: 20, bottom: 20 }

const defaultSelectedRoute: SelectedRoute = {
  tactical: undefined,
  occurrence: undefined,
}

const defaultValue: MapContextProps = {
  selectedRoute: defaultSelectedRoute,
  handleMap: () => null,
  handleRoute: () => null,
  handleUnfocusedRoute: () => null,
  handleCentralization: () => null,
}

export const MapContext = createContext<MapContextProps>(defaultValue)

export function useMap(): MapContextProps {
  const context = useContext(MapContext)

  if (!context) {
    throw new Error('You need to use MapContext within a MapProvider')
  }

  return context
}

export const MapProvider: FC<Provider> = ({ children }) => {
  const location = useLocation()
  const { occurrences, tacticals } = useDisplacementData()

  const occurrenceId = location.state?.occurrenceId
  const [map, handleMap] = useState<google.maps.Map>()
  const [selectedRoute, setSelectedRoute] =
    useState<SelectedRoute>(defaultSelectedRoute)

  const handleUnfocusedRoute = useCallback(
    (key: RoutesKey) =>
      setSelectedRoute((old) => ({
        ...old,
        [key]: {
          ...old[key],
          focused: false,
        },
      })),
    [],
  )

  const handleRoute = useCallback(
    ({ marker, type }: HandleRoute) => {
      const bounds = new google.maps.LatLngBounds()
      const secondaryKey = type === 'occurrence' ? 'tactical' : 'occurrence'

      const handleOccurrence = () => {
        const currentOccurrence = occurrences.data?.find(
          (occ) => occ.id === marker.id,
        )

        bounds.extend({
          lat: currentOccurrence?.position?.latitude || 0,
          lng: currentOccurrence?.position?.longitude || 0,
        })

        const currentTactical = tacticals.data?.find((tactical) =>
          tactical.occurrencesLinked?.find(
            (linked) => linked.occurrenceId === marker.id,
          ),
        )

        if (currentTactical && !currentTactical.allowMultipleLinks) {
          bounds.extend({
            lat: currentTactical?.position?.latitude || 0,
            lng: currentTactical?.position?.longitude || 0,
          })
        }

        map?.fitBounds(bounds, ZOOM_WITH_TWO_MARKERS)

        setSelectedRoute({
          [type]: {
            ...marker,
            focused: true,
          },
          ...(!!currentTactical && {
            [secondaryKey]: {
              ...currentTactical,
              focused: true,
            },
          }),
        })
      }

      const handleTactical = () => {
        occurrences.data?.filter((occ) => {
          if (occ.tacticalId === marker.id) {
            bounds.extend({
              lat: occ?.position?.latitude || 0,
              lng: occ?.position?.longitude || 0,
            })
          }
        })

        const currentTactical = tacticals.data.filter(
          (tactical) => tactical.id === marker.id,
        )[0]

        if (!currentTactical.allowMultipleLinks) {
          bounds.extend({
            lat: currentTactical?.position?.latitude || 0,
            lng: currentTactical?.position?.longitude || 0,
          })

          const currentOccurrence = occurrences.data.find(
            (occ) =>
              occ.id === currentTactical.occurrencesLinked?.[0].occurrenceId,
          )

          setSelectedRoute({
            [type]: {
              ...marker,
              focused: true,
            },
            ...(!!currentOccurrence && {
              [secondaryKey]: {
                focused: true,
                id: currentOccurrence.id,
                position: currentOccurrence.position,
              },
            }),
          })
        } else {
          setSelectedRoute({
            [type]: {
              ...marker,
              focused: true,
            },
          })
        }

        map?.fitBounds(bounds, ZOOM_WITH_TWO_MARKERS)
      }

      if (type === 'occurrence') {
        handleOccurrence()
      } else {
        handleTactical()
      }
    },
    [map, occurrences, tacticals],
  )

  const handleCentralization = () => {
    const bounds = new google.maps.LatLngBounds()

    if (tacticals && occurrences) {
      const routes = [
        ...tacticals.data.filter((tact) => !tact.allowMultipleLinks),
        ...occurrences.data,
      ]

      routes.map((route) =>
        bounds.extend({
          lat: route.position.latitude,
          lng: route.position.longitude,
        }),
      )
    }

    map?.fitBounds(bounds)
  }

  useEffect(() => {
    handleCentralization()

    const occurrencePosition = occurrences.data.find(
      (occurrence) => occurrence.id === occurrenceId,
    )?.position

    if (occurrencePosition) {
      handleRoute({
        marker: {
          id: occurrenceId,
          alert: false,
          position: {
            latitude: occurrencePosition?.latitude,
            longitude: occurrencePosition?.longitude,
          },
        },
        type: 'occurrence',
      })
    }
  }, [map])

  return (
    <MapContext.Provider
      value={{
        map,
        selectedRoute,
        handleMap,
        handleRoute,
        handleUnfocusedRoute,
        handleCentralization,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}
