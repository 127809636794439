import { ActionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'

import { Input } from 'components'

import styles from './PriorityForm.module.scss'

export const PriorityForm = ({ onChange, data }: ActionOptionForm) => {
  const { index, parameters } = data

  return (
    <div className={styles.container}>
      <Input
        key={index}
        type="number"
        value={String(parameters?.occurrencePriority) || '0'}
        min={0}
        max={100000}
        onChange={(input) => {
          onChange({
            index,
            parameters: {
              occurrencePriority: Number(input.target.value),
            },
          })
        }}
        id="change-priority"
        name="change-priority"
        label="Alterar prioridade"
      />
    </div>
  )
}
