import {
  OccurrenceActionLabel,
  OccurrenceActionValue,
  ActionBorder,
  ActionBorderStyle,
} from 'domains/occurrence/types'
import { OccurrenceColumnTitle } from 'services/occurrence/types'

const borderStylesMap: Record<OccurrenceColumnTitle, ActionBorder | undefined> =
  {
    Disponível: {
      [OccurrenceActionLabel.VISUALIZAR_OCORRENCIA]: 'borderBoth',
    },
    'Em observação': {
      [OccurrenceActionLabel.VISUALIZAR_OCORRENCIA]: 'borderBoth',
    },
    Deslocamento: {
      [OccurrenceActionLabel.VISUALIZAR_OCORRENCIA]: 'borderTop',
      [OccurrenceActionLabel.ALTERAR_TATICO]: 'borderTop',
      [OccurrenceActionLabel.VISUALIZAR_NO_MAPA]: 'borderBottom',
    },
    'Em atendimento': {
      [OccurrenceActionLabel.VISUALIZAR_OCORRENCIA]: 'borderBottom',
    },
  }

export const getBorderStyle = (
  columnTitle: OccurrenceColumnTitle,
  actionLabel: OccurrenceActionValue,
): ActionBorderStyle => {
  const columnStyles = borderStylesMap[columnTitle]
  if (columnStyles) {
    return columnStyles[actionLabel] ?? ''
  }
  return ''
}
