import { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { Button, Combobox, ComboboxItem, Input, RadioGroup } from 'components'

import { ReactComponent as CloseButton } from 'assets/svg/close.svg'

import { useFormContext } from 'react-hook-form'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'

import {
  AuthDriver,
  PermissionGroupsQueryResponse,
  TagQueryResponse,
} from 'services/auth'
import { parseDataToCombobox } from 'utilities/combobox'

import styles from './Filters.module.scss'
import { UserFilters } from 'domains/user/schemas/searchUserSchema'
import { Pagination } from 'services/types'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { USER_STATUS } from 'domains/user/components/Filters/types'
import { CoverageDriver, CoverageQueryResponse } from 'services/coverages'
import { getUserStatus } from 'domains/user/components/Filters/utilities'

export type FiltersProps = {
  isVisible: boolean
  onClose: () => void
  onApplyFilters: () => void
}

export const Filters = ({
  isVisible,
  onClose,
  onApplyFilters,
}: FiltersProps) => {
  const { watch, reset, register, setValue, formState } =
    useFormContext<UserFilters>()
  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const phoneExtension = watch('phoneExtension')
  const coverage = watch('coverage')
  const permissionGroup = watch('permissionGroup')
  const tags = watch('tags')

  const [userStatus, setUserStatus] = useState<USER_STATUS>(USER_STATUS.ALL)

  const handleFetchProfiles = useCallback(
    async (pagination: Pagination, filter?: string) => {
      const response = await CoverageDriver.query({
        ...(filter && { name: filter }),
        ...pagination,
      })
      return parseDataToCombobox(response, 'name')
    },
    [],
  )

  const handleFetchTags = useCallback(
    async (pagination: Pagination, filter?: string) => {
      const response = await AuthDriver.queryTags({
        ...(filter && { name: filter }),
        ...pagination,
      })
      return parseDataToCombobox(response, 'name')
    },
    [],
  )

  const handleFetchPermissionGroups = useCallback(
    async (pagination: Pagination, filter?: string) => {
      const response = await AuthDriver.queryPermissionGroups({
        ...(filter && { name: filter }),
        ...pagination,
      })
      return parseDataToCombobox(response, 'name')
    },
    [],
  )

  useEffect(() => {
    register('phoneExtension')
    register('coverage')
    register('permissionGroup')
    register('tags')
    register('active')
    setValue('active', undefined)
  }, [register, setValue])

  return ReactDOM.createPortal(
    <div
      className={[styles.container, isVisible ? styles.show : styles.hide]
        .filter(Boolean)
        .join(' ')}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && formState.isValid) {
          onApplyFilters()
        }
      }}
      data-testid="filters-component"
    >
      <div className={styles.innerWrapper}>
        <div className={styles.upperContainer}>
          <div className={styles.header}>
            <span>Filtros</span>
            <CloseButton
              height={16}
              width={16}
              onClick={onClose}
              className={styles.closeIcon}
              data-testid="filter-close-button"
            />
          </div>
          <div className={styles.contentWrapper}>
            <Input
              id="phoneExtension"
              className={styles.customInputNumber}
              name="phoneExtension"
              label="Ramal"
              autoComplete="off"
              value={phoneExtension ? String(phoneExtension) : ''}
              onChange={(e) =>
                setValue('phoneExtension', e.target.value, {
                  shouldDirty: true,
                })
              }
            />
            <Combobox
              id="coverage"
              label="Perfis de atendimento"
              searchable
              fetcher={handleFetchProfiles}
              value={
                coverage
                  ? {
                      label: 'name',
                      value: coverage,
                    }
                  : undefined
              }
              getSelected={(selected) => {
                const coverage = selected as ComboboxItem<CoverageQueryResponse>
                setValue('coverage', coverage?.value, {
                  shouldDirty: true,
                })
              }}
            />
            <Combobox
              id="permissionGroupId"
              label="Permissões"
              searchable
              fetcher={handleFetchPermissionGroups}
              value={
                permissionGroup
                  ? {
                      label: 'name',
                      value: permissionGroup,
                    }
                  : undefined
              }
              getSelected={(selected) => {
                const permissions =
                  selected as ComboboxItem<PermissionGroupsQueryResponse>

                setValue('permissionGroup', permissions?.value, {
                  shouldDirty: true,
                })
              }}
            />
            <Combobox
              id="tag"
              value={tags}
              label="Tag"
              searchable
              fetcher={handleFetchTags}
              getSelected={(selected) => {
                const tags = selected as ComboboxItem<TagQueryResponse>
                setValue('tags', tags?.value.name, { shouldDirty: true })
              }}
            />
            <RadioGroup title="Situação">
              {Object.values(USER_STATUS).map((option, key) => (
                <RadioButton
                  value={option}
                  onChange={(e) => {
                    const status = getUserStatus(e.target.value)

                    setUserStatus(status.label)
                    setValue('active', status.value, { shouldDirty: true })
                  }}
                  checked={userStatus === option}
                  name={'active'}
                  key={key}
                />
              ))}
            </RadioGroup>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              clearAllLocalStorageFilters()
              setUserStatus(USER_STATUS.ALL)
              reset({})
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            width="162px"
            onClick={onApplyFilters}
            disabled={!formState.isValid}
          />
        </div>
      </div>
    </div>,
    document.getElementById('main-container') || document.body,
  )
}
