import { useInfiniteQuery } from '@tanstack/react-query'
import { CoverageDriver, CoverageQueryResponse } from 'services/coverages'
import {
  flattenPages,
  getNextPageOffSet,
} from '../services/utilities/pagination'
import { useMemo } from 'react'

const getAttendanceProfiles = async (offset = 0, filter?: string) => {
  const response = await CoverageDriver.query({
    recordsPerPage: 15,
    offset,
    ...(filter && { name: filter }),
  })
  return response
}

export function useGetAttendanceProfiles(filter: string) {
  const {
    isError,
    isFetching,
    data,
    fetchNextPage: fetchNextAttendanceProfilesPage,
  } = useInfiniteQuery({
    initialPageParam: 0,
    queryFn: ({ pageParam }) => getAttendanceProfiles(pageParam, filter),
    queryKey: ['coverages', filter],
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<CoverageQueryResponse>(lastPage, allPages),
  })

  const attendanceProfiles = useMemo(
    () => flattenPages<CoverageQueryResponse>(data),
    [data],
  )

  return {
    isError,
    isFetching,
    attendanceProfiles,
    fetchNextAttendanceProfilesPage,
  }
}
