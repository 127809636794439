import { ComponentProps, forwardRef } from 'react'
import styles from './styles.module.scss'

export const IconButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<'button'>
>(({ className, type = 'button', ...props }, ref) => (
  <button
    ref={ref}
    {...props}
    type={type}
    className={[styles.button, className].join(' ')}
  />
))

IconButton.displayName = 'Button'

export default IconButton
