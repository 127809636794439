import { FunctionComponent, SVGProps } from 'react'
import styles from './TriggerExplanation.module.scss'

interface TriggerExplanationProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  title: string
  description: string
}

export const TriggerExplanation = ({
  Icon,
  title,
  description,
}: TriggerExplanationProps) => {
  return (
    <div className={styles.triggerHeader}>
      <Icon />
      <div className={styles.headerInfo}>
        <span>{title}</span>
        <p>{description}</p>
      </div>
    </div>
  )
}
