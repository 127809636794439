import { useRef } from 'react'

import { Tooltip } from 'components'

import { ReactComponent as Trash } from 'assets/svg/trash.svg'
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg'

import { Coverage } from 'domains/attendancePolicy/types'
import { useToggle } from 'shared/hooks'

import styles from './CoverageItem.module.scss'

interface CoverageItem {
  onAddNewCoverage?: () => void
  onDelete?: (coverage: Coverage) => void
  onInspectAccount?: (coverage: Coverage) => void
  coverage: Coverage
}

export const CoverageItem = ({
  onDelete,
  onAddNewCoverage,
  onInspectAccount,
  coverage,
}: CoverageItem) => {
  const tooltip = useToggle()
  const tooltipRef = useRef<HTMLDivElement>(null)
  return (
    <div onMouseEnter={tooltip.show} onMouseLeave={tooltip.hide}>
      <div className={styles.coverageItem}>
        <div ref={tooltipRef}>
          <p>{coverage.name}</p>
          <p>{coverage.type}</p>
        </div>
        {!onAddNewCoverage ? (
          <div
            aria-label="delete-item"
            className={styles.trashIcon}
            onClick={() => onDelete && onDelete(coverage)}
          >
            <Trash />
          </div>
        ) : null}
      </div>
      {onAddNewCoverage && (
        <Tooltip
          parentRef={tooltipRef}
          type="informative"
          isVisible={tooltip.isVisible}
        >
          <div className={styles.tooltipText}>
            <h1>Informações gerais</h1>

            {coverage.precedence.account && (
              <>
                <small>Conta</small>
                <p>{coverage.precedence.account.name}</p>
              </>
            )}

            {coverage.precedence.patrimony && (
              <>
                <small>Patrimônio</small>
                <p>{coverage.precedence.patrimony.name}</p>
              </>
            )}

            {coverage.precedence.customer && (
              <>
                <small>Cliente</small>
                <p>{coverage.precedence.customer.name}</p>
              </>
            )}

            <span className={styles.line} />
            <button
              className={styles.learnMore}
              onClick={() => onInspectAccount?.(coverage)}
            >
              <LinkIcon />
              Ver mais
            </button>
          </div>
        </Tooltip>
      )}
    </div>
  )
}
