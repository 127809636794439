import { useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button } from 'components'
import { useGetOccurrenceHistory } from 'services/attendance'

import {
  EventCard,
  AddRegister,
  ChangeStateCard,
  InterventionCard,
  AutomaticRuleCard,
  AlertInterventionCard,
} from './components'

import { ReactComponent as HistoryIcon } from 'assets/svg/history.svg'
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg'

import { RouteParams } from '../../types'
import { handleFilter, handleHistoryItems } from './utils'
import {
  HistoryEvent,
  HistoryIntervention,
  PhoneCallTarget,
} from 'services/attendance/types'
import { useFilter, useModal } from '../../context'
import { Loader } from '../../components'

import styles from './styles.module.scss'
import { isInterventionExpandable } from '../../utils'
import { useGetOccurrenceImages } from 'services/occurrence'

const History: React.FC = () => {
  const { occurrenceId } = useParams<RouteParams>()
  const location = useLocation()
  const navigate = useNavigate()

  const { handleOpenModal, handleCloseModal } = useModal()
  const { data } = useGetOccurrenceHistory(occurrenceId)
  const { filter, handleClearFilter } = useFilter()

  const { data: images } = useGetOccurrenceImages(occurrenceId || '')

  const handleHasCallBackIntervention = useCallback(
    (id: string) => {
      const hasCallbackIntervention = data?.interventions.some(
        (intervention) => {
          const matchIntervention =
            intervention.attributes?.requirePhoneCallInterventionId === id

          return (
            ['REFUSE_PHONE_CALL_REQUIREMENT', 'PHONE_CALL'].includes(
              intervention.typeName,
            ) && matchIntervention
          )
        },
      )

      return hasCallbackIntervention
    },
    [data],
  )

  const readOnly = !!location.state?.readonly

  const handleAlertIntervention = useCallback(() => {
    const failedRuleExecutionIntervention = data?.interventions.filter(
      (intervention) =>
        intervention.typeName === 'REQUIRE_PHONE_CALL' &&
        intervention.attributes.status === 'FAILURE',
    )?.[0]

    const hasCallbackIntervention = data?.interventions.some((intervention) => {
      const matchIntervention =
        intervention.attributes?.requirePhoneCallInterventionId ===
        failedRuleExecutionIntervention?.id

      if (
        ['REFUSE_PHONE_CALL_REQUIREMENT', 'PHONE_CALL'].includes(
          intervention.typeName,
        ) &&
        matchIntervention
      ) {
        return true
      }

      return false
    })

    return !hasCallbackIntervention && failedRuleExecutionIntervention
  }, [data])

  const handleChooseScenario = useCallback(
    (intervention: HistoryIntervention) => {
      const callTarget = intervention.attributes
        ?.phoneCallTarget as PhoneCallTarget

      if (callTarget === 'ACTION_PLAN') {
        handleOpenModal('ACTION_PLAN', {
          intervention,
        })
      } else {
        handleOpenModal('AUTOMATIC_RULE', {
          intervention,
          type: callTarget,
        })
      }
    },
    [handleOpenModal],
  )

  const handleRenderState = () => {
    if (data?.id) return 'view'

    return 'loading'
  }

  const alertIntervention = handleAlertIntervention()
  const handleFinalize = () => {
    if (alertIntervention) {
      handleChooseScenario(alertIntervention)
      return
    }

    const hasExpandableInterventionWithoutNote = data?.interventions
      ?.filter((intervention) => isInterventionExpandable(intervention))
      .some((intervention) => !intervention.notes?.length)

    if (hasExpandableInterventionWithoutNote) {
      handleOpenModal('INTERVENTION_WITHOUT_NOTE')
      return
    }

    handleOpenModal('FINISH_OCCURRENCE')
  }

  const handleOpenGallery = (imageId: string) => {
    handleOpenModal('GALLERY', {
      imageId,
      onClose: handleCloseModal,
      images: [
        ...(images || []),
        {
          id: imageId,
          url: '',
          creation: new Date(),
        },
      ],
    })
  }

  return (
    <div className={styles.container}>
      <div>
        <h1>Histórico</h1>
        <Button
          icon={FilterIcon}
          buttonTitle={
            filter
              ? `Remover filtro da partição ${filter.partition.name}`
              : 'Filtrar eventos por partição'
          }
          type="tertiary"
          className={styles.alignRight}
          onClick={() =>
            filter ? handleClearFilter() : handleOpenModal('FILTER')
          }
        />
        <Button
          icon={HistoryIcon}
          buttonTitle="Visualizar históricos anteriores"
          type="tertiary"
          className={styles.alignRight}
          onClick={() =>
            handleOpenModal('ACCOUNT_HISTORY', {
              accountId: data?.accountId,
            })
          }
        />
      </div>
      {
        {
          view: (
            <ul>
              {alertIntervention && (
                <AlertInterventionCard intervention={alertIntervention} />
              )}
              {data &&
                handleHistoryItems(
                  handleFilter(
                    {
                      ...data,
                      interventions: data.interventions.map((intervention) => {
                        if (intervention.typeName === 'REQUIRE_PHONE_CALL') {
                          return {
                            ...intervention,
                            hasCallback: handleHasCallBackIntervention(
                              intervention.id,
                            ),
                          }
                        } else {
                          return intervention
                        }
                      }),
                    },
                    filter?.partition.id,
                  ),
                ).map((intervention) => {
                  const options = {
                    EVENT: (
                      <EventCard
                        key={intervention.value.id}
                        event={intervention.value as HistoryEvent}
                        onOpenGallery={handleOpenGallery}
                      />
                    ),
                    INTERVENTION: (
                      <InterventionCard
                        key={intervention.value.id}
                        intervention={intervention.value as HistoryIntervention}
                        onOpenGallery={handleOpenGallery}
                      />
                    ),
                    STATE_CHANGE: (
                      <ChangeStateCard
                        key={intervention.value.id}
                        intervention={intervention.value as HistoryIntervention}
                      />
                    ),
                    AUTOMATIC_RULE: (
                      <AutomaticRuleCard
                        key={intervention.value.id}
                        intervention={intervention.value as HistoryIntervention}
                      />
                    ),
                  }

                  return options[intervention.type]
                })}
            </ul>
          ),
          loading: <Loader />,
        }[handleRenderState()]
      }
      <div>
        <AddRegister />
        {readOnly ? (
          <Button
            type="primary"
            width="172px"
            buttonTitle="Voltar"
            onClick={() => navigate(-1)}
          />
        ) : (
          <Button
            type="primary"
            width="172px"
            buttonTitle="FINALIZAR"
            onClick={handleFinalize}
          />
        )}
      </div>
    </div>
  )
}

export default History
