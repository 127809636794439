import { State } from './types'
import { Pagination, Result } from '../../types'
import HttpClient from '../../httpClient'
import { AxiosInstance } from 'axios'

export interface StateApiClient {
  queryStates(
    queryParams: Partial<State> & Partial<Pagination>,
  ): Promise<Result<State>>
  find(stateId: string): Promise<State>
}
class StateApiClientImpl implements StateApiClient {
  public constructor(private readonly httClient: AxiosInstance = HttpClient) {}

  public async queryStates(
    queryParams?: Partial<State> & Partial<Pagination>,
  ): Promise<Result<State>> {
    const response = await this.httClient.get<Result<State>>(
      `/address/state/query/`,
      { params: queryParams },
    )
    return response.data
  }

  public async find(stateId: string): Promise<State> {
    const response = await this.httClient.get<State>(
      `/address/state/${stateId}`,
    )
    return response.data
  }
}

export const StateDriver = new StateApiClientImpl()
export default StateDriver
export * from './types'
