import { Combobox } from 'components/ComboboxV2/Combobox'
import { ActionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'

import styles from './styles.module.scss'
import { useMemo } from 'react'

export const ChangeFinishRestriction = ({
  onChange,
  data,
}: ActionOptionForm) => {
  const comboboxLabel = 'Finalização de ocorrência pelo tático'

  const defaultValue = useMemo(() => {
    if (data.parameters?.allowFinishByDuty === undefined) return ''

    return data.parameters?.allowFinishByDuty ? 'Permitir' : 'Não Permitir'
  }, [data])

  return (
    <div className={styles.container}>
      <Combobox
        value={defaultValue}
        label={{
          text: comboboxLabel,
        }}
        items={['Permitir', 'Não Permitir']}
        onChange={(selected) => {
          const selectedOption = selected as string

          onChange({
            parameters: {
              allowFinishByDuty: selectedOption === 'Permitir',
            },
          })
        }}
      />
    </div>
  )
}
