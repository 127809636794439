import React, { useRef, useState } from 'react'

import { maskedDateTime } from 'utilities/date'
import { HistoryIntervention } from 'services/attendance/types'
import { POSITION } from 'shared/hooks/useElementPosition/types'
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'

import styles from './styles.module.scss'
import { ButtonV2, ModalV2, Tag, Tooltip } from 'components'

interface AutomaticRuleCardProps {
  intervention: HistoryIntervention
}

const AutomaticRuleCard: React.FC<AutomaticRuleCardProps> = ({
  intervention,
}) => {
  const svgRef = useRef(null)
  const [visible, setVisible] = useState(false)

  const hasNote = !!intervention.notes?.length

  return (
    <li className={styles.container}>
      <h4>
        {intervention.typeName === 'TAG_INCLUSION' ? (
          <>
            <strong> Adição automática de tags</strong> {` • `}
            {maskedDateTime(intervention.createdAt)}
          </>
        ) : (
          <>
            <strong>{intervention.title}</strong>
            {` por ${intervention.user.name || 'Sistema'} •
            ${maskedDateTime(intervention.createdAt)}`}
          </>
        )}
      </h4>
      <ModalV2.Root>
        {intervention.typeName === 'TAG_INCLUSION' && (
          <ModalV2.Trigger asChild>
            <ButtonV2 appearance="tertiary" size="md">
              Visualizar
            </ButtonV2>
          </ModalV2.Trigger>
        )}
        <ModalV2.Content size="sm">
          <ModalV2.Title className={styles.title}>
            Tags adicionadas
            <ModalV2.Close />
          </ModalV2.Title>
          <div className={styles.flex}>
            {intervention.tags?.map((tag) => (
              <Tag key={tag.name} size="md">
                {tag.name}
              </Tag>
            ))}
          </div>
        </ModalV2.Content>
      </ModalV2.Root>
      {hasNote && (
        <div ref={svgRef}>
          <InfoIcon
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
          />
          <Tooltip
            positionOnEdge
            hasArrow={false}
            isVisible={visible}
            parentRef={svgRef}
            positionAbove={POSITION.BOTTOM}
            type="informative"
          >
            <p>{intervention.notes?.[0]?.note}</p>
          </Tooltip>
        </div>
      )}
    </li>
  )
}

export default AutomaticRuleCard
