import * as Joi from '@hapi/joi'
import { PhoneType } from '../../../../services/contact/types'
import { ContactType } from '../../../../services/partition/types'
import { StringSchemeErrorsEnum } from 'utilities/validation'

const phonesSchema = Joi.array()
  .items({
    id: Joi.string().required(),
    type: Joi.string().required(),
    extension: Joi.number().allow('', null).optional(),
    number: Joi.number().required(),
    countryCode: Joi.number().required(),
    provinceCode: Joi.number().required(),
    priorityOrder: Joi.number().required(),
  })
  .min(1)
  .required()

export const createContactSchema = Joi.object({
  generalInfo: Joi.object({
    name: Joi.string()
      .messages({
        [StringSchemeErrorsEnum.EMPTY]:
          'Por favor preencha este campo para continuar',
      })
      .required(),
    type: Joi.string()
      .valid(PhoneType.CellPhone, PhoneType.Comercial, PhoneType.Residential)
      .optional(),
    number: Joi.string().allow('').optional(),
    extension: Joi.string().allow('').optional(),
    phones: phonesSchema,
  }),
  patrimonies: Joi.object({
    email: Joi.when('patrimonies', {
      is: Joi.array()
        .items(
          Joi.object({
            id: Joi.string().required(),
            name: Joi.string().required(),
            accountId: Joi.string().required(),
            contactType: Joi.string().required(),
          }),
        )
        .has(
          Joi.object({
            id: Joi.string().required(),
            name: Joi.string().required(),
            accountId: Joi.string().required(),
            contactType: Joi.string()
              .valid(ContactType.ADMIN, ContactType.GUEST)
              .required(),
          }),
        ),
      then: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
      otherwise: Joi.string()
        .email({ tlds: { allow: false } })
        .allow('', null)
        .optional(),
    }).messages({
      [StringSchemeErrorsEnum.EMAIL]:
        'Um email válido segue o formato: nome@domínio.tld',
      [StringSchemeErrorsEnum.EMPTY]: 'O e-mail é obrigatório',
    }),
    patrimonies: Joi.array()
      .items(
        Joi.object({
          id: Joi.string().required(),
          name: Joi.string().required(),
          accountId: Joi.string().required(),
          contactType: Joi.string().required(),
        }),
      )
      .required()
      .min(1),
  }),
  partitions: Joi.array()
    .items({
      id: Joi.string().uuid().required(),
      name: Joi.string().required(),
      patrimonyId: Joi.string().uuid().required(),
      contacts: Joi.array()
        .items({
          id: Joi.string().uuid().optional(),
          type: Joi.string().required(),
          priorityOrder: Joi.number().required(),
          name: Joi.string().allow('', null).optional(),
          hasControlDevice: Joi.boolean().optional(),
          contactId: Joi.string().uuid().optional(),
        })
        .min(1)
        .required(),
    })
    .min(1)
    .required(),
})

export const editContactSchema = Joi.object({
  generalInfo: Joi.object({
    name: Joi.string()
      .messages({
        [StringSchemeErrorsEnum.EMPTY]:
          'Por favor preencha este campo para continuar',
      })
      .required(),
    type: Joi.string()
      .valid(PhoneType.CellPhone, PhoneType.Comercial, PhoneType.Residential)
      .optional(),
    number: Joi.string().allow('').optional(),
    extension: Joi.number().allow('').optional(),
    phones: phonesSchema,
  }),
})
