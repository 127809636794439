import { useLocation, useParams } from 'react-router-dom'
import { useState, useCallback, useEffect } from 'react'

import { ReactComponent as EditIcon } from 'assets/svg/edit.svg'
import { ReactComponent as PlayerIcon } from 'assets/svg/player.svg'
import { ReactComponent as ShareIcon } from 'assets/svg/shareBox.svg'
import { ReactComponent as PaperIcon } from 'assets/svg/paperPlus.svg'
import { ReactComponent as OccurrenceIcon } from 'assets/svg/occurrenceExclamation.svg'

import { usePersistentTab } from 'domains/occurrence/hooks'
import {
  useTimer,
  useToast,
  useToggle,
  useTooltipVisibility,
} from 'shared/hooks'
import { useGetServiceOrders } from 'shared/hooks/serviceOrder/useGetServiceOrders'

import { formatDocument } from 'utilities/masks/document'
import { Button, ProtectedImage, TabBar, Tag, Tooltip } from 'components'
import { CustomerPaths } from 'routes/constants/paths/customer'
import { PartitionOnAttendance } from 'services/attendance/types'
import { useGetOccurrenceOnAttendance } from 'services/attendance'
import { OccurrenceDetails as OccurrenceDetailsType } from 'services/displacementMap/types'

import styles from './styles.module.scss'
import { Loader } from '../../components'
import { RouteParams } from '../../types'
import { PartitionItem, Phones, ChangeState } from './components'
import { occurrenceTypesLabel, handleStateRender } from '../../utils'
import { useModal } from '../../context'
import Icon from 'components/Icon'

type StyleOccurrence = 'ISN_OCCURRENCE' | 'ISNT_OCCURRENCE'

type Filter = {
  partition: PartitionOnAttendance
}

const OccurrenceDetails: React.FC = () => {
  const location = useLocation()
  const { occurrenceId } = useParams<RouteParams>()

  const readOnly = !!location.state?.readonly

  const { addToast } = useToast()
  const informativeToast = useToggle()
  const { handleOpenModal, handleCloseModal } = useModal()
  const [filter, setFilter] = useState<Filter | null>(null)

  const handleFilter = (data: Filter) => {
    setFilter(data)
  }

  const { data, isFetched } = useGetOccurrenceOnAttendance(occurrenceId)
  const [tabState, setTabState] = useState<'address' | 'partition'>('address')

  const fromOccurrenceManagement =
    readOnly && location.state.from === 'management-occurrence'

  const canEdit =
    (!readOnly || fromOccurrenceManagement) &&
    data?.occurrence.stateName !== 'FINISHED'

  const timer = useTimer({
    initialTime: data?.occurrence?.createdAt ?? new Date().getTime(),
    finalTime: data?.occurrence?.finishedAt,
  })

  const { replaceUrl } = usePersistentTab('service-order-account-tab')

  const { ref, isTooltipVisible, handleMouseEnter, handleMouseLeave } =
    useTooltipVisibility()

  const handleSeeMore = useCallback(
    (path: string) => {
      if (data?.account.id) {
        localStorage.setItem('accountId', data?.account.id)
        localStorage.setItem(
          'aggregatedAccountName',
          `${data?.account.code} - ${data?.account.name}`,
        )
      }

      localStorage.setItem('customerName', data?.customer?.name || '')
      localStorage.setItem('patrimonyId', data?.patrimony.id || '')
      localStorage.setItem('customerId', data?.customer.id || '')
      localStorage.setItem('centralId', data?.centralId || '')

      replaceUrl(path)
    },
    [data],
  )

  const handlePushCFTV = useCallback(() => {
    window.open('/occurrence/cftv')
    localStorage.setItem('occurrenceId', data?.occurrence.id || '')
  }, [data])

  const selectedPartition = filter?.partition || data?.partitions[0]

  const handleFormatAddress = useCallback(
    (address: OccurrenceDetailsType['patrimony']['address']) => {
      return `${address?.street}, ${address?.number} - ${address?.district}, ${address?.city}, ${address?.acronym}`
    },
    [],
  )

  const { data: serviceOrders, status } = useGetServiceOrders(
    {
      accountIds: [data?.account.id || ''],
      status: ['OPEN'],
    },
    Boolean(data?.account.id),
  )

  useEffect(() => {
    if (serviceOrders?.totalElements) {
      informativeToast.show()
    }

    if (status === 'error') {
      addToast({
        type: 'alert',
        message:
          'Não foi possível recuperar as ordens de serviço. Tente novamente',
      })
    }
  }, [addToast, informativeToast, serviceOrders, status])

  useEffect(() => {
    if (data?.occurrence.stateName === 'FINISHED' && !readOnly) {
      handleOpenModal('FINISHED_EXTERNALLY')
    }
  }, [data, handleOpenModal, readOnly])

  const serviceType = data?.patrimony?.serviceType?.name
  const serviceTypeColor = data?.patrimony?.serviceType?.color

  return (
    <main className={styles.container}>
      {
        {
          view: !!data && (
            <div className={styles.mainContent}>
              <header>
                <div>
                  <h1>Ocorrência #{data.occurrence.number}</h1>
                  <div className={styles.actions}>
                    {canEdit && <ChangeState />}
                  </div>
                </div>
                <div
                  className={[
                    styles.occurrenceInfo,
                    styles[data.occurrence.type.name],
                    styles[data.occurrence.stateName],
                  ].join(' ')}
                >
                  <span
                    ref={ref}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <OccurrenceIcon />

                    {[
                      occurrenceTypesLabel[data.occurrence.type.name],
                      data.occurrence?.title,
                    ]
                      .filter(Boolean)
                      .join(' - ')}
                  </span>
                  {isTooltipVisible && (
                    <Tooltip
                      type="informative"
                      isVisible={isTooltipVisible}
                      parentRef={ref}
                    >
                      {data.occurrence?.title}
                    </Tooltip>
                  )}
                  <div>
                    <span>{timer}</span>
                  </div>
                </div>
              </header>
              <div className={styles.content}>
                <div className={styles.imageBox}>
                  {data.patrimony.image && (
                    <ProtectedImage
                      imageId={data.patrimony.image}
                      onClick={() => {
                        handleOpenModal('GALLERY', {
                          selectedImageId: data.patrimony.image,
                          images: [
                            {
                              id: data.patrimony.image,
                            },
                          ],
                          onClose: handleCloseModal,
                        })
                      }}
                    />
                  )}
                </div>
                <section className={styles.customerInfo}>
                  <h2>{data.customer?.name}</h2>
                  {data.customer?.secondName && (
                    <h3>{data.customer?.secondName}</h3>
                  )}
                  <h4 className={styles.document}>
                    {formatDocument(data.customer.document)}
                  </h4>
                </section>
                <section>
                  <div className={styles.patrimonyInfo}>
                    <h3 className={styles.patrimonyName}>
                      {data.patrimony?.name}
                    </h3>
                    <h4>
                      {data.account?.code} - {data.account.name}
                    </h4>
                    {!!serviceType && (
                      <Tag
                        color="green"
                        {...(serviceTypeColor && {
                          style: {
                            backgroundColor: serviceTypeColor,
                            color: serviceTypeColor,
                          },
                        })}
                        singleColor={!!serviceTypeColor}
                      >
                        {serviceType}
                      </Tag>
                    )}
                  </div>
                  {informativeToast.isVisible && (
                    <div className={styles.toast}>
                      <div className={styles.toastContent}>
                        <Icon name="information" />
                        <p className={styles.toastContentText}>
                          Existem ordens de serviço abertas <br /> para esta
                          conta.
                        </p>
                      </div>
                      <button
                        className={styles.buttonToast}
                        onClick={() => handleSeeMore(CustomerPaths.History)}
                      >
                        ACESSAR
                      </button>
                    </div>
                  )}
                  <div>
                    {data.account.tags && (
                      <>
                        <h4 className={styles.accountInfo}>
                          Detalhes da conta
                        </h4>
                        <div className={styles.tags}>
                          {data.account.tags.map((tag) => (
                            <span key={tag.id}>{tag.name}</span>
                          ))}
                        </div>
                      </>
                    )}

                    <Button
                      icon={ShareIcon}
                      buttonTitle="Ver conta"
                      type="tertiary"
                      onClick={() => handleSeeMore(CustomerPaths.Patrimonies)}
                    />
                  </div>
                </section>

                <div
                  className={[
                    styles.note,
                    data.account.note && styles.hasNote,
                  ].join(' ')}
                >
                  <div>
                    {!!data.account.note && <OccurrenceIcon />}
                    <h4>Anotações</h4>
                  </div>
                  <p>{data.account.note}</p>
                  <Button
                    type="tertiary"
                    icon={data.account.note ? EditIcon : PaperIcon}
                    buttonTitle="Editar"
                    onClick={() =>
                      handleOpenModal('ACCOUNT_NOTES', {
                        accountId: data.account.id,
                        note: data.account.note,
                      })
                    }
                  />
                </div>
                <div className={styles.tabs}>
                  <TabBar>
                    <TabBar.Tab
                      id="address"
                      label="Endereço"
                      idSelected={tabState}
                      onClick={() => setTabState('address')}
                    >
                      <div>
                        <section>
                          <div>
                            <h4>Endereço</h4>
                            <span>
                              {handleFormatAddress(data.patrimony.address)}
                            </span>
                          </div>
                          {data.patrimony.note && (
                            <div>
                              <h4>Observações</h4>
                              <span>{data.patrimony.note}</span>
                            </div>
                          )}
                          {(data.occurrence.priority ||
                            data.patrimony.serviceType) && (
                            <div className={styles.patrimonyTags}>
                              {!!data.occurrence.priority && (
                                <div>Prioridade {data.occurrence.priority}</div>
                              )}
                            </div>
                          )}
                        </section>
                      </div>
                    </TabBar.Tab>
                    <TabBar.Tab
                      id="partition"
                      label="Partições"
                      idSelected={tabState}
                      onClick={() => setTabState('partition')}
                    >
                      <div className={styles.partition}>
                        <div className={styles.partitionButtons}>
                          {data.partitions?.map((partition) => {
                            const isSelected =
                              selectedPartition?.id === partition.id
                            const isDisabled = filter
                              ? filter.partition.id !== partition.id
                              : false
                            const describe: StyleOccurrence =
                              partition.isInOccurrence
                                ? 'ISN_OCCURRENCE'
                                : 'ISNT_OCCURRENCE'

                            return (
                              <button
                                key={partition.id}
                                className={[
                                  styles.partitionButton,
                                  styles[describe],
                                  isDisabled && styles.isDisabled,
                                  isSelected
                                    ? styles.isSelected
                                    : styles.unSelected,
                                ].join(' ')}
                                onClick={() => handleFilter({ partition })}
                              >
                                {partition.code} - {partition.name}
                                {partition.isInOccurrence}
                              </button>
                            )
                          })}
                        </div>
                        {selectedPartition && (
                          <PartitionItem partition={selectedPartition} />
                        )}
                      </div>
                    </TabBar.Tab>
                  </TabBar>
                </div>
                <hr />
                <section className={styles.imagesSection}>
                  <span>Galeria de fotos</span>
                  <div>
                    {!!data.occurrence.images?.length && (
                      <ul>
                        {data.occurrence.images.map((image) => (
                          <ProtectedImage
                            key={image.id}
                            imageId={image.id}
                            onClick={() => {
                              handleOpenModal('GALLERY', {
                                imageId: image.id,
                                onClose: handleCloseModal,
                                images: data.occurrence.images,
                              })
                            }}
                          />
                        ))}
                      </ul>
                    )}
                  </div>
                  <div>
                    <Button
                      type="tertiary"
                      buttonTitle="Adicionar foto"
                      onClick={() => handleOpenModal('IMAGE_UPLOADER')}
                    >
                      <Icon name="gallery-add" />
                    </Button>
                  </div>
                </section>
                {data.patrimony.hasCFTV &&
                  data.occurrence.type.name !== 'FINISHED' && (
                    <>
                      <hr />
                      <section
                        className={styles.cftvSection}
                        onClick={handlePushCFTV}
                      >
                        <span>CFTV</span>
                        <ProtectedImage imageId={data.patrimony.image} />
                        <button>
                          <PlayerIcon />
                        </button>
                      </section>
                    </>
                  )}
              </div>
              <Phones
                occurrenceId={occurrenceId}
                phones={{
                  ...data.phones,
                  actionPlan: {
                    extensionCode: String(
                      selectedPartition?.actionPlan?.[0]?.phone?.extension,
                    ),
                    phone: String(
                      selectedPartition?.actionPlan?.[0]?.phone?.number,
                    ),
                  },
                }}
              />
            </div>
          ),
          loading: <Loader />,
          error: (
            <span className={styles.error}>
              Erro ao carregar ocorrência. Tente novamente.
            </span>
          ),
        }[handleStateRender(data, isFetched)]
      }
    </main>
  )
}

export default OccurrenceDetails
