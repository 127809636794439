import styles from './CentralCard.module.scss'
import { CentralAttributes } from './types'
import { ReactComponent as CameraEmptyState } from 'assets/svg/cameraEmptyState.svg'
import {
  getCentralBatteryIcon,
  getCentralCommunicationIcon,
} from 'utilities/central'
import { ReactComponent as TamperOpenIcon } from './assets/exclamation.svg'
import { PowerStatus } from 'services/serviceOrder'
import { Icon, IconWithTooltip } from 'components'
import { dateNow } from 'utilities/date'

type CentralCardProps = {
  central: CentralAttributes
  onPress: (central: CentralAttributes) => void
  isSelected?: boolean
}

export const CentralCard = ({
  isSelected,
  central,
  onPress,
}: CentralCardProps) => {
  return (
    <div
      className={[
        styles.equipment,
        isSelected && styles.selected,
        central.tamper && styles.error,
      ].join(' ')}
      role="button"
      aria-label={`Central ${central.aggregatedAccountName ?? ''}`}
      onClick={() => onPress(central)}
    >
      {central.hasCommandSupport ? (
        <Icon name="device-central" width={110} height={80} />
      ) : (
        <div className={styles.emptyStateRender}>
          <CameraEmptyState width={56} height={56} />
        </div>
      )}

      <div className={styles.equipmentInfo}>
        <h1>{central.aggregatedAccountName}</h1>
        <h2>{central.serviceType?.name}</h2>
        <h3>Central {central.manufacturer?.name}</h3>

        {central.hasCommandSupport && (
          <div className={styles.iconsWrapper}>
            {getCentralCommunicationIcon({
              communicationType: central.connectionMode,
              isConnected: central.isConnected,
              hasCommandSupport: central.hasCommandSupport,
              ethernetConnectionStatus: central.ethernet?.connectionStatus,
              wifiConnectionStatus: central.wifi?.connectionStatus,
              gprsConnectionStatus: central.gprs?.connectionStatus,
            })}
            {getCentralBatteryIcon({
              batteryAlarmed: central.batteryAlarmed,
              batteryLevel: central.batteryLevel,
              isConnected: central.powerStatus === PowerStatus.ON,
            })}
            {central.tamper && (
              <IconWithTooltip Icon={TamperOpenIcon} text="Tamper aberto" />
            )}
          </div>
        )}
      </div>
      {!central.isConnected && (
        <div className={styles.cardOverlay}>
          A central está offline desde <br />
          {dateNow(central.lastConnectionTimestamp)}
        </div>
      )}
    </div>
  )
}
