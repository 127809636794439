import { useMutation } from '@tanstack/react-query'
import CommandService from 'services/command'
import { CommandPayload } from 'services/command/types'

async function sendReboot(centralId: string, payload: CommandPayload) {
  await CommandService.reboot(centralId, payload)
}

export const usePostRebootCommand = (
  centralId: string,
  payload: CommandPayload,
) => {
  const { mutate: mutateRebootCommand, status: rebootCommandMutationStatus } =
    useMutation({
      mutationFn: () => sendReboot(centralId, payload),
    })

  return {
    mutateRebootCommand,
    rebootCommandMutationStatus,
  }
}
