import { useMutation } from '@tanstack/react-query'
import CommandService from 'services/command'
import { MaintenanceModePayload } from 'services/command/types'

async function setMaintenanceModeCommand(
  centralId: string,
  payload: MaintenanceModePayload,
) {
  await CommandService.setMaintenanceMode(centralId, payload)
}

export const usePostSetMaintenanceModeCommand = (
  centralId: string,
  payload: MaintenanceModePayload,
) => {
  const {
    mutate: mutateSetMaintenanceModeCommand,
    status: statusSetMaintenanceModeCommandMutation,
  } = useMutation({
    mutationFn: () => setMaintenanceModeCommand(centralId, payload),
  })

  return {
    mutateSetMaintenanceModeCommand,
    statusSetMaintenanceModeCommandMutation,
  }
}
