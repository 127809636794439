import { useQuery } from '@tanstack/react-query'
import OccurrenceDriver from 'services/occurrence/index'
import { OccurrenceResponse } from 'services/occurrence/types'
import { useUserInfo } from 'shared/hooks'

async function fetchOccurrenceForAttend(userId: string) {
  const result = await OccurrenceDriver.getNextByUser(userId)

  return result
}

export function useGetOccurrenceForAttend(enabled: boolean): {
  isFetching: boolean
  refetch: () => void
  data: OccurrenceResponse | undefined
} {
  const { userInfo } = useUserInfo()
  const { isFetching, refetch, data } = useQuery<OccurrenceResponse>({
    queryKey: ['search-occurrence-for-attend'],
    queryFn: () => fetchOccurrenceForAttend(userInfo?.id || ''),

    enabled,
    refetchInterval: 5000,
  })

  return { isFetching, refetch, data }
}
