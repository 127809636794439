import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { HolidayDriver } from 'services/holiday/holiday.driver'
import { AggregatedHolidayResponse } from 'services/holiday/types'

const getHoliday = async (holidayId: string) => {
  return await HolidayDriver.findById(holidayId)
}

export function useGetHoliday(
  holidayId: string,
): UseQueryResult<AggregatedHolidayResponse> {
  return useQuery({
    queryKey: ['holiday', holidayId],
    queryFn: () => getHoliday(holidayId),
  })
}
