import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { destroyCookie, setCookie, parseCookies } from 'nookies'

import { RefreshTokenResponse } from './auth'

export const DefaultHttpClientV1: AxiosInstance = Axios.create({
  baseURL: process.env.BFF_URL,
})

DefaultHttpClientV1.interceptors.request.use(
  (config) => {
    if (
      config.url !== '/login' &&
      config.url !== '/refreshToken' &&
      config.url !== '/validatePasswordToken'
    ) {
      const token = parseCookies().accessToken

      config.headers = {
        authorization: `bearer ${token || ''}`,
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

let pendingRefreshToken: Promise<void> | null = null

const refreshToken = () => {
  if (pendingRefreshToken) {
    return pendingRefreshToken
  }
  pendingRefreshToken = DefaultHttpClientV1.post('/refreshToken', {
    refreshToken: parseCookies().refreshToken || '',
  })
    .then((res) => {
      const userData = res.data as RefreshTokenResponse

      const userId = parseCookies()?.userId

      setCookie(undefined, 'refreshToken', userData.refreshToken, {
        maxAge: 60 * 300,
      })
      setCookie(undefined, 'accessToken', userData.accessToken, {
        maxAge: 60 * 300,
      })
      setCookie(undefined, 'userId', userId, {
        maxAge: 60 * 600,
      })
    })
    .finally(() => {
      pendingRefreshToken = null
    })
  return pendingRefreshToken
}

type CustomAxiosRequestConfig = {
  _retry: boolean
} & AxiosRequestConfig

DefaultHttpClientV1.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error: AxiosError) {
    const originalRequest = error.config as CustomAxiosRequestConfig
    if (
      originalRequest.url === '/login' ||
      originalRequest.url === 'auth/user/validatePasswordToken'
    ) {
      return Promise.reject(error)
    }
    if (
      error?.response?.status === 401 &&
      originalRequest.url === '/refreshToken'
    ) {
      destroyCookie(undefined, 'accessToken')
      destroyCookie(undefined, 'refreshToken')

      if (!window.location.href.includes('/login')) {
        window.location.pathname = '/login'
      }

      return Promise.reject(error)
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      return refreshToken()
        .then(() => {
          // Axios sets the content-type header to text/plain
          // when retrying a request because the body has already been transformed
          // to a string in the first try, and we cannot override the header here
          // as it will be overridden again in the transformRequest.
          // We need to convert the body to JSON again to fix this.
          if (originalRequest.data) {
            originalRequest.data = JSON.parse(originalRequest.data)
          }

          return DefaultHttpClientV1.request(originalRequest)
        })
        .catch(() => {
          if (!window.location.href.includes('/login')) {
            window.location.pathname = '/login'
          }
        })
    }
    return Promise.reject(error)
  },
)

export default DefaultHttpClientV1
