import { useQuery } from '@tanstack/react-query'
import { AccountQuery } from 'services/account/types'

import { CoverageAccount, CoverageDriver } from 'services/coverages'

type UseGetAccounts = {
  accounts: CoverageAccount[] | undefined
  isFetchingAccounts: boolean
  isLoading: boolean
}

const getAccount = async (payload: Partial<AccountQuery>) => {
  const result = await CoverageDriver.queryAccounts(payload)

  return result.data
}

export const useGetAccounts = (
  payload: Partial<AccountQuery>,
  enabled?: boolean,
): UseGetAccounts => {
  const {
    data: accounts,
    isFetching: isFetchingAccounts,
    isLoading,
  } = useQuery({
    queryKey: ['accounts', payload],
    queryFn: () => getAccount(payload),
    enabled,

    refetchOnMount: false,
  })

  return { accounts, isFetchingAccounts, isLoading }
}
