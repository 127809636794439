import { ReactComponent as ContactIcon } from 'assets/svg/contact.svg'

import { Button } from 'components'

import { EquipmentPhoto } from 'domains/customer/screens/Equipments/components/EquipmentPhoto/EquipmentPhoto'

import styles from './RemoteControlCard.module.scss'
import { deviceCodeMasker } from 'domains/customer/screens/Equipments/utilities/masks/masks'
import { DeviceUpdate } from 'domains/customer/screens/Equipments/components/DeviceUpdate/DeviceUpdate'
import { useToggle } from 'shared/hooks'
import { DeviceCardFooter } from 'domains/customer/screens/Equipments/components/DeviceCardFooter/DeviceCardFooter'
import { RemoteControlContact } from 'domains/customer/screens/Equipments/components/RemoteControlContact/RemoteControlContact'
import { CentralFragment } from 'domains/customer/screens/Equipments/types'
import { DeviceCardHeader } from 'domains/customer/screens/Equipments/components/DeviceCardHeader/DeviceCardHeader'
import { PartitionDeviceAggregatedResponse } from 'services/device/types'

interface RemoteControlCardProps {
  remoteControl: PartitionDeviceAggregatedResponse
  central: CentralFragment
}

export const RemoteControlCard = (props: RemoteControlCardProps) => {
  const { remoteControl, central } = props

  const editModal = useToggle()
  const changeContactModal = useToggle()

  return (
    <div className={styles.cardContainer} aria-label="equipment-card-details">
      <DeviceUpdate
        device={{
          id: remoteControl.id,
          partitions: remoteControl.partitions,
        }}
        central={central}
        onClose={editModal.hide}
        isVisible={editModal.isVisible}
      />

      <RemoteControlContact
        centralId={central.id}
        isVisible={changeContactModal.isVisible}
        onClose={changeContactModal.hide}
        remoteControl={{
          id: remoteControl.id,
          contact: remoteControl.contact,
          partitionId: remoteControl.partitions[0]?.id || '',
        }}
      />

      <div className={styles.header}>
        <DeviceCardHeader
          deviceId={remoteControl.id}
          title={
            remoteControl.contact
              ? remoteControl.contact.name
              : deviceCodeMasker(remoteControl.name)
          }
          type={remoteControl.typeCode}
          hiddenZone={remoteControl.hiddenZone}
          hiddenZoneTo={remoteControl.hiddenZoneTo}
          tamper={false}
          onClick={editModal.show}
          hasCommandSupport={remoteControl.hasCommandSupport}
        />
        <div className={styles.cardButtonOptions}>
          <Button
            id="change-contact-button"
            buttonTitle="Alterar contato"
            type="secondary"
            icon={ContactIcon}
            width="184px"
            onClick={changeContactModal.show}
          />
        </div>
      </div>
      <EquipmentPhoto imageId={''} alt="Controle Remoto" />
      <div className={styles.footer}>
        <DeviceCardFooter device={remoteControl} />
      </div>
    </div>
  )
}
