import { OfficeHourParameters } from 'services/officeHours/types'

export const officeHoursConfigLabel = {
  ACTIVE: 'Controlar tabela de horários',
  NOT_ARMED_ACTIVE: 'Gerar UB02 - Não armado',
  NOT_DISARMED_ACTIVE: 'Gerar UB03 - Não desarmado',
  ARMED_OUT_OF_SCALE_ACTIVE: 'Gerar UB04 - Armado fora do horário',
  DISARMED_OUT_OF_SCALE_ACTIVE: 'Gerar UB05 - Desarmado fora do horário',
}

export type OFFICE_HOURS_CONFIG = keyof typeof officeHoursConfigLabel

export const parametersKeys: Record<string, keyof OfficeHourParameters> = {
  'Controlar tabela de horários': 'active',
  'Gerar UB02 - Não armado': 'notArmedActive',
  'Gerar UB03 - Não desarmado': 'notDisarmedActive',
  'Gerar UB04 - Armado fora do horário': 'armedOutOfScaleActive',
  'Gerar UB05 - Desarmado fora do horário': 'disarmedOutOfScaleActive',
}
