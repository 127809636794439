import { useCallback, useEffect, useMemo } from 'react'

import { Button } from 'components'

import {
  triggerOptions,
  groupedTriggerType,
} from 'domains/attendancePolicy/data/triggerInfo'

import { RuleTriggerFormComponent } from 'domains/attendancePolicy/types'

import { ReactComponent as ButtonBack } from 'assets/svg/leftArrow.svg'

import { joiResolver } from '@hookform/resolvers/joi'
import { useForm, useFormContext } from 'react-hook-form'
import {
  AggregatedRule,
  AggregatedTrigger,
  TriggerType,
} from 'services/attendancePolicy/types'

import { triggerSchema } from 'domains/attendancePolicy/schema'
import {
  Arrival,
  OccurrenceClosing,
  Time,
  TriggerCard,
  TriggerExplanation,
} from 'domains/attendancePolicy/components/TriggerForms'

import { Intervention } from 'domains/attendancePolicy/components/TriggerForms/Intervention/Intervention'

import styles from './TriggerFormGroup.module.scss'

interface TriggerFormGroupProps {
  onGoBack: () => void
}

export const TriggerFormGroup = ({ onGoBack }: TriggerFormGroupProps) => {
  const { setValue: setRuleForm, watch: watchRuleForm } =
    useFormContext<AggregatedRule>()

  const form = useForm<AggregatedTrigger>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(triggerSchema),
    defaultValues: {
      type: watchRuleForm('trigger')?.type,
      parameters: {
        ...watchRuleForm('trigger')?.parameters,
      },
    },
  })

  const { watch, setValue, register, formState, trigger } = form

  const parameters = watch('parameters')
  const type = watch('type')

  const selectedTrigger = useMemo(
    () => triggerOptions[groupedTriggerType[type]],
    [type],
  )

  const onSubmit = useCallback(() => {
    setRuleForm(
      'trigger',
      {
        parameters: {
          ...watch('parameters'),
        },
        type: TriggerType[watch('type')],
      },
      { shouldDirty: true },
    )

    onGoBack()
  }, [onGoBack, setRuleForm, watch])

  useEffect(() => {
    register('type')
    register('parameters')
  }, [register])

  const triggerFormComponents: RuleTriggerFormComponent = useMemo(
    () => ({
      [TriggerType.EVENT_ARRIVAL]: Arrival,
      [TriggerType.OCCURRENCE_ARRIVAL]: Arrival,
      [TriggerType.OCCURRENCE_CLOSING]: OccurrenceClosing,
      [TriggerType.TIME_BASED]: Time,
      [TriggerType.INTERVENTION_CREATED]: Intervention,
    }),
    [],
  )

  const renderFormComponent = useCallback(
    (trigger: AggregatedTrigger) => {
      if (trigger.type) {
        const Form = triggerFormComponents[trigger.type]

        return (
          <Form
            data={trigger}
            onChange={(changedTrigger) => {
              const { parameters, type } = changedTrigger

              setValue('type', type, {
                shouldValidate: true,
              })

              setValue('parameters', parameters, {
                shouldValidate: true,
              })
            }}
          />
        )
      }
    },
    [triggerFormComponents, setValue],
  )

  return (
    <>
      <section className={styles.container}>
        <Button
          buttonTitle={
            type ? 'Voltar para lista de gatilhos' : 'Voltar para automação'
          }
          type="tertiary"
          icon={ButtonBack}
          className={styles.buttonBack}
          onClick={() => {
            if (type) {
              setValue('type', '')
              trigger()
              return
            }

            return onGoBack()
          }}
        />

        {!type && (
          <>
            <div className={styles.header}>
              <span>Adicionar gatilho</span>
              <p>
                Evento único que tem por objetivo iniciar a execução de uma
                automação
              </p>
            </div>
            <div className={styles.cardsWrapper}>
              {Object.values(triggerOptions).map((triggerType) => {
                return (
                  <TriggerCard
                    {...triggerType}
                    key={triggerType.title}
                    onClick={() => {
                      setValue('type', triggerType.formType, {
                        shouldValidate: true,
                      })
                      setValue('parameters', {})
                    }}
                  />
                )
              })}
            </div>
          </>
        )}

        {type && (
          <div className={styles.triggerOptions}>
            <TriggerExplanation {...selectedTrigger} />
            <div className={styles.triggerContainer}>
              {renderFormComponent({
                type,
                parameters,
              })}
            </div>
          </div>
        )}
      </section>

      {type && (
        <div className={styles.footer}>
          <Button
            disabled={!formState.isValid}
            buttonTitle="Salvar gatilho"
            onClick={onSubmit}
            type="secondary"
            width="172px"
          />
        </div>
      )}
    </>
  )
}
