import {
  Button,
  Checkbox,
  Input,
  Loader,
  Modal,
  ProtectedButton,
} from 'components'
import { useCallback, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  configureSafeCallSchema,
  ConfigureSafeCallSchema,
} from 'domains/customer/screens/Equipments/schemas'

import styles from './CentralConfigureSafeCall.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import { usePostAndHandleConfigureSafeCallCommand } from 'domains/customer/screens/Equipments/hooks/services/commands'
import { UbideskPermissions } from 'routes/types'

type CentralEnableDigitProps = {
  isVisible: boolean
  onClose: () => void
  central: {
    id: string
    safeCall?: {
      enabled: boolean
      delay: number
      number: number
    }
  }
}

export const CentralConfigureSafeCall = ({
  isVisible,
  onClose,
  central,
}: CentralEnableDigitProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    formState,
    watch,
    control,
  } = useForm<ConfigureSafeCallSchema>({
    resolver: joiResolver(configureSafeCallSchema),
    mode: 'onChange',
    defaultValues: {
      centralId: central.id,
      dial: '',
    },
  })

  const dial = watch('dial')

  const {
    configureSafeCallCommandMutationStatus,
    mutateConfigureSafeCallCommand,
  } = usePostAndHandleConfigureSafeCallCommand()

  const onSave = useCallback(
    (data: ConfigureSafeCallSchema) => {
      mutateConfigureSafeCallCommand(data)
      onClose()
    },
    [mutateConfigureSafeCallCommand, onClose],
  )

  useEffect(() => {
    register('centralId')
    register('dial')
  }, [register])

  useEffect(() => {
    if (central.safeCall) {
      const value = central.safeCall.enabled ? 'dial' : ''
      setValue('dial', value)
    }
  }, [central, setValue])

  return (
    <div>
      <Loader
        isVisible={configureSafeCallCommandMutationStatus === 'pending'}
      />
      <Modal
        isVisible={isVisible}
        onClose={onClose}
        title="Habilitar dígito 190"
        hint="Em algumas cidades, ao ligar para o 190, é necessário discar um número para falar com a polícia. Esta tela permite que a central do cliente disque o dígito automaticamente."
      >
        <div className={styles.inputWrapper}>
          <Controller
            control={control}
            name="dial"
            defaultValue={central.safeCall?.enabled ? 'dial' : ''}
            render={({ onChange, value }) => (
              <Checkbox
                id="dial"
                label="Incluir dígito nas ligações para 190"
                checked={Boolean(value)}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            id="dialDelay"
            label="Intervalo em segundos"
            type="number"
            {...register('dialDelay')}
            defaultValue={
              central.safeCall ? String(central.safeCall.delay) : ''
            }
            min={0}
            max={10}
            disabled={!dial}
            errorMessage={errors.dialDelay?.message}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            id="dialNumber"
            label="Dígito"
            type="number"
            {...register('dialNumber')}
            defaultValue={
              central.safeCall ? String(central.safeCall.number) : ''
            }
            min={0}
            max={9}
            maxLength={1}
            disabled={!dial}
            errorMessage={errors.dialNumber?.message}
          />
        </div>
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Voltar"
            type="secondary"
            onClick={onClose}
          />
          <ProtectedButton
            permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            onClick={handleSubmit(onSave)}
            disabled={!formState.isDirty || !formState.isValid}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
