import { ChangeEvent, useCallback, useState } from 'react'
import styles from './AttendanceCompletionModal.module.scss'

import { handleHoursAndMinutes } from 'domains/occurrence/screens/Attendance/containers/Modals/components/FinishOccurrence/utils'
import { useGetAttendanceSentence } from 'shared/hooks/services/attendanceSentence/useGetAttendanceSentence'
import { MAX_NOTE_LENGTH } from 'domains/occurrence/constants/attendance'

import { InterventionTag } from 'domains/occurrence/data/interventionTag'
import { AttendanceSentenceResponse } from 'services/occurrence/types'
import { InterventionNoteSubmitData } from 'domains/occurrence/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useDebounce } from 'shared/hooks'

import { Combobox as ComboboxV2 } from 'components/ComboboxV2/Combobox'
import {
  ButtonV2,
  Checkbox,
  ComboboxItem,
  Datepicker,
  ModalV2,
  Textarea,
} from 'components'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'

export type AttendanceCompletionModalProps = {
  onSave(data: InterventionNoteSubmitData): void
  canDisableHabitDeviation: boolean
  occurrenceTypeId?: string
  isDisabledSave: boolean
  serviceTypeId?: string
  onClose: () => void
  isVisible: boolean
}

export const AttendanceCompletionModal = ({
  canDisableHabitDeviation,
  occurrenceTypeId,
  isDisabledSave,
  serviceTypeId,
  isVisible,
  onClose,
  onSave,
}: AttendanceCompletionModalProps) => {
  const [sentenceName, setSentenceName] = useState('')
  const [interventionProps, setInterventionProps] =
    useState<InterventionNoteSubmitData>({
      note: '',
      interventionTag: InterventionTag.FINALIZADA,
      interventionNoteTags: [],
      hour: '',
      postponeUntil: '',
      date: 0,
    })

  const handleSelectedComment = (comment: AttendanceSentenceResponse) => {
    handleChangeNote(interventionProps.note + comment.sentence)
    setInterventionProps((prevState) => ({
      ...prevState,
      interventionNoteTags: comment.tags,
    }))
  }

  const handleChangeNote = (newNote: string) => {
    setInterventionProps((prevState) => ({
      ...prevState,
      note: newNote.slice(0, MAX_NOTE_LENGTH),
    }))
  }

  const handleChangeScheduleDateInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        setInterventionProps((prevState) => ({
          ...prevState,
          date: convertStringToDate(value).getTime(),
        }))
      }

      return value
    },
    [],
  )

  const handleSubmit = () => {
    onSave(interventionProps)
    setInterventionProps((prevState) => ({
      ...prevState,
      note: '',
      interventionNoteTags: [],
    }))
  }

  const handleFilter = useDebounce(setSentenceName)

  const { data: attendanceSentence } = useGetAttendanceSentence({
    interventionType: 'STATE_CHANGE',
    occurrenceTypeId,
    serviceTypeId,
    name: sentenceName,
  })

  return (
    <ModalV2.Root isOpen={isVisible} onClose={onClose}>
      <ModalV2.Content className={styles.content} size="md">
        <ModalV2.Title>Finalizar atendimento de ocorrência</ModalV2.Title>
        <ComboboxV2
          searchable
          onSearch={handleFilter}
          id="Tratativa"
          className={styles.combobox}
          label={{ text: 'Tratativa' }}
          placeholder="Selecione uma opção de tratativa"
          items={parseDataToComboboxV2(attendanceSentence?.data || [], 'name')}
          onChange={(data: ComboboxItem<AttendanceSentenceResponse>) =>
            handleSelectedComment(data.value)
          }
        />
        <div className={styles.textareaContainer}>
          <Textarea
            rows={7}
            label="Justificativa"
            value={interventionProps.note}
            onChange={(event) => handleChangeNote(event.target.value)}
          />
          {canDisableHabitDeviation && (
            <div className={styles.habitDeviation}>
              <Checkbox
                id="postponeUntil"
                label="Adiar atendimento até"
                checked={!!interventionProps.postponeUntil}
                onChange={(event) =>
                  setInterventionProps((prevState) => ({
                    ...prevState,
                    postponeUntil: event.target.value,
                  }))
                }
              />
              <Datepicker
                id="date"
                initialValueInput={
                  interventionProps.date
                    ? convertDateToString(new Date(interventionProps.date))
                    : undefined
                }
                onChangeInput={handleChangeScheduleDateInput}
                disabled={!interventionProps.postponeUntil?.length}
                initialDate={
                  interventionProps.date
                    ? new Date(interventionProps.date)
                    : undefined
                }
                onChangeDate={(date) =>
                  setInterventionProps((prevState) => ({
                    ...prevState,
                    date: date?.getTime(),
                  }))
                }
              />
              <span>às</span>
              <ComboboxV2
                id="hour-input"
                placeholder="--:--"
                value={interventionProps.hour}
                items={handleHoursAndMinutes()}
                disabled={!interventionProps.postponeUntil?.length}
                onChange={(value: string) =>
                  setInterventionProps((prevState) => ({
                    ...prevState,
                    hour: value,
                  }))
                }
              />
            </div>
          )}
        </div>
        <ModalV2.Footer>
          <ModalV2.Close asChild>
            <ButtonV2 appearance="secondary">Cancelar</ButtonV2>
          </ModalV2.Close>
          <ButtonV2
            disabled={!interventionProps.note || isDisabledSave}
            type="submit"
            onClick={handleSubmit}
          >
            Salvar
          </ButtonV2>
        </ModalV2.Footer>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}
