import { FC, useCallback, useMemo, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { Button, ComboboxItem, Input, ViewEntities } from 'components'
import { ViewEntityContent } from 'components/ViewEntities/types'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { AggregatedAccount, PatrimonyFragment } from 'services/account/types'

import { useGetPatrimonies } from 'shared/hooks/patrimonies/useGetPatrimonies'
import { useGetAccounts } from 'shared/hooks/accounts/useGetAccounts'

import { formatEntityCoverage } from 'domains/customer/screens/OfficeHours/utilities/utilities'

import {
  Coverages,
  OfficeHoursFormData,
} from 'domains/customer/screens/OfficeHours/types'

import styles from './GeneralInformation.module.scss'
import { useGetPartitions } from 'services/partition'
import { useDebounce } from 'shared/hooks'
import { PartitionResponse } from 'services/partition/types'

export const GeneralInformation: FC = () => {
  const customerId = localStorage.getItem('customerId') ?? ''

  const {
    setValue: setOfficeHours,
    watch: watchOfficeHours,
    register: registerOfficeHours,
  } = useFormContext<OfficeHoursFormData>()

  const coverageList = useMemo(() => {
    const formattedItems = formatEntityCoverage(watchOfficeHours('coverage'))

    return formattedItems
  }, [watchOfficeHours('coverage')])

  const { register, setValue, watch, reset } = useForm<Coverages>()

  const [filters, setFilters] = useState({
    account: '',
    patrimony: '',
    partition: '',
  })

  const handleFilter = useDebounce((newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }))
  }, 700)

  const {
    patrimonies: patrimoniesOptions,
    fetchNextPatrimoniesPage,
    isError: isErrorPatrimonies,
    isFetching: isFetchingPatrimonies,
  } = useGetPatrimonies(customerId, filters.patrimony)

  const {
    accounts: accountsOptions,
    isFetching: isFetchingAccounts,
    isError: isErrorAccounts,
    fetchNextAccountsPage,
  } = useGetAccounts(true, customerId, {
    name: filters.account,
    patrimonyId: watch('patrimony')?.id,
  })

  const {
    data: partitions,
    isError: partitionsError,
    isLoading: partitionsLoading,
    fetchNextPage: partitionsFetchNextPage,
  } = useGetPartitions({
    accountIds: watch('account')?.id,
    code: filters.partition,
  })

  const handleAddCoverage = useCallback(() => {
    const selectedAccount = watch('account')
    const selectedPatrimony = watch('patrimony')
    const selectedPartitions = watch('partitions')

    const { patrimonies, accounts, partitions } = watchOfficeHours('coverage')

    if (selectedPartitions) {
      setOfficeHours(
        'coverage',
        {
          ...watchOfficeHours('coverage'),
          partitions: [
            ...partitions,
            ...selectedPartitions.map(({ id, name }) => ({
              id,
              name,
              precedents: {
                patrimony: selectedPatrimony,
                account: selectedAccount,
              },
            })),
          ],
        },
        { shouldValidate: true },
      )
    }

    if (selectedAccount && !selectedPartitions) {
      setOfficeHours(
        'coverage',
        {
          ...watchOfficeHours('coverage'),
          accounts: [
            ...accounts,
            {
              id: selectedAccount.id,
              accountCode: selectedAccount.name.split('-')[0].trim(),
              precedents: { patrimony: selectedPatrimony },
            },
          ],
        },
        { shouldValidate: true },
      )
    }

    if (selectedPatrimony && !selectedAccount && !selectedPartitions) {
      setOfficeHours(
        'coverage',
        {
          ...watchOfficeHours('coverage'),
          patrimonies: [...patrimonies, selectedPatrimony],
        },
        { shouldValidate: true },
      )
    }

    reset()
  }, [reset, watch, watchOfficeHours])

  const handleRemoveCoverage = useCallback(
    (entity: ViewEntityContent) => {
      const { patrimonies, accounts, partitions } = watchOfficeHours('coverage')

      const valuesByKey: Record<string, unknown[]> = {
        partitions: partitions.filter(
          (partition) => partition.id !== entity.id,
        ),
        accounts: accounts.filter((account) => account.id !== entity.id),
        patrimonies: patrimonies.filter(
          (patrimony) => patrimony.id !== entity.id,
        ),
      }

      if (entity.formKey) {
        setOfficeHours(
          'coverage',
          {
            ...watchOfficeHours('coverage'),
            [entity.formKey]: valuesByKey[entity.formKey],
          },
          { shouldValidate: true },
        )
      }
    },
    [watchOfficeHours, setOfficeHours],
  )

  return (
    <div>
      <section className={styles.column}>
        <div className={styles.inputs}>
          <Input
            label="Nome"
            id="name"
            autoComplete="off"
            {...registerOfficeHours('name')}
            value={watchOfficeHours('name') || ''}
            onChange={(event) => setOfficeHours('name', event.target.value)}
          />
          <Combobox
            label={{
              text: 'Patrimônio',
            }}
            id="patrimony"
            onSearch={(filter) => handleFilter({ patrimony: filter })}
            isLoading={isFetchingPatrimonies}
            isError={isErrorPatrimonies}
            {...register('patrimony')}
            value={watch('patrimony')?.name}
            items={parseDataToComboboxV2(patrimoniesOptions || [], 'name')}
            onChange={(selected) => {
              const selectedPatrimony =
                selected as ComboboxItem<PatrimonyFragment>

              setValue(
                'patrimony',
                {
                  id: selectedPatrimony?.value.id,
                  name: selectedPatrimony?.value.name,
                },
                { shouldDirty: true },
              )
            }}
            onEndReached={fetchNextPatrimoniesPage}
          />
          <Combobox
            id="account"
            disabled={!watch('patrimony')}
            label={{ text: 'Conta' }}
            {...register('account')}
            value={watch('account')?.name}
            items={parseDataToComboboxV2(
              accountsOptions || [],
              'aggregatedAccountName',
            )}
            isError={isErrorAccounts}
            onChange={(selected) => {
              const selectedAccount =
                selected as ComboboxItem<AggregatedAccount>

              setValue('account', {
                id: selectedAccount?.value.id,
                name: selectedAccount?.value.aggregatedAccountName,
              })
            }}
            isLoading={isFetchingAccounts}
            onEndReached={fetchNextAccountsPage}
            onSearch={(filter) => handleFilter({ account: filter })}
          />

          <Combobox<PartitionResponse>
            disabled={!watch('account')?.name}
            label={{
              text: 'Partição',
            }}
            id="partitions"
            isLoading={partitionsLoading}
            multiple
            value={
              watch('partitions')?.map((item) => ({
                label: 'aggregatedName',
                value: item,
              })) || []
            }
            isError={partitionsError}
            {...register('partitions')}
            items={parseDataToComboboxV2(partitions || [], 'aggregatedName')}
            onChange={(selected) => {
              const partitions = selected as ComboboxItem<PartitionResponse>[]

              setValue(
                'partitions',
                partitions.map((partition) => ({
                  id: partition.value.id,
                  name: partition?.value.name,
                  aggregatedName: partition?.value.aggregatedName,
                })),
                {
                  shouldValidate: true,
                },
              )
            }}
            onEndReached={partitionsFetchNextPage}
            onSearch={(filter) => handleFilter({ partition: filter })}
          />
          <Button
            buttonTitle="Adicionar abrangência"
            type="tertiary"
            width="171px"
            disabled={!watch('patrimony')}
            onClick={handleAddCoverage}
            className={styles.AddCoverage}
            icon={PlusIcon}
          />
        </div>
        <div className={styles.coverageList}>
          <div className={styles.coverageWrapper}>
            <div>
              <ViewEntities
                {...registerOfficeHours('coverage')}
                sections={coverageList}
                onRemove={handleRemoveCoverage}
                customStyle={styles.customViewEntities}
              />
            </div>
            <h2 className={styles.totalAdded}>
              Total adicionado:{' '}
              {
                [
                  ...watchOfficeHours('coverage').accounts,
                  ...watchOfficeHours('coverage').partitions,
                  ...watchOfficeHours('coverage').patrimonies,
                ].length
              }
            </h2>
          </div>
        </div>
      </section>
    </div>
  )
}
