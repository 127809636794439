import { useQuery } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { ServiceOrderAccount } from './types'

export const getAccount = async (accountId?: string) => {
  const response = await HttpClient.get<ServiceOrderAccount>(
    buildUrl({ route: endpoints.getAccount, params: { accountId } }),
  )

  return response.data
}

export const useGetAccount = (accountId?: string) => {
  return useQuery({
    queryKey: ['service-order-account', accountId],
    queryFn: async () => await getAccount(accountId),
    enabled: !!accountId,
  })
}
