import { useEffect, useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import styles from './ServiceOrderForm.module.scss'

import { SERVICE_ORDER } from 'domains/attendancePolicy/types'
import {
  Evaluation,
  SERVICE_ORDER_TYPE_ENUM,
  SERVICE_ORDER_TYPE_EN_PT,
  TagOutput,
} from 'services/serviceOrder'

import { handleServiceOrderForm } from 'domains/attendancePolicy/utilities/Accordion/Accordion'

import { Combobox } from 'components/ComboboxV2/Combobox'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { ComboboxItem, RadioGroup } from 'components'

import { parseDataToComboboxV2 } from 'utilities/combobox'
import { ConditionType } from 'services/attendancePolicy/types'
import { useGetServiceOrderTags } from 'shared/hooks/serviceOrder/useGetServiceOrderTags'
import { translateServiceOrderType } from 'domains/attendancePolicy/components/RuleForm/data/occurrenceStateName'
import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import { serviceOrderOptionType } from 'domains/attendancePolicy/components/RuleForm/utilities'
import {
  evaluationLabel,
  evaluationOption,
  evaluationOSLabel,
} from 'domains/attendancePolicy/utilities/constants'

export const ServiceOrderForm = ({ onChange, data }: ConditionOptionForm) => {
  const comboboxLabel = 'Ordem de serviço'
  const { facts } = data

  const { register, watch, setValue } = useForm<{
    tags: TagOutput[]
    type: ConditionType
    evaluation: Evaluation
    serviceOrderTypes: SERVICE_ORDER_TYPE_ENUM[]
  }>({
    defaultValues: {
      evaluation: data?.evaluation || 'HAS',
      tags: facts?.serviceOrderTags || [],
      type: data.type || ConditionType.SERVICE_ORDER_TYPES,
      serviceOrderTypes: facts?.serviceOrderTypes || [],
    },
  })

  const tags = watch('tags')

  const [tagFilter, setTagFilter] = useState('')
  const {
    isError: isErrorServiceOrderTags,
    isFetching: isFetchingServiceOrderTags,
    serviceOrderTags,
    fetchNextTagsPage,
  } = useGetServiceOrderTags(tagFilter)

  const [serviceOrder, setServiceOrder] = useState<SERVICE_ORDER | null>(null)

  const serviceOrderTypes = watch('serviceOrderTypes')

  useEffect(() => {
    register('tags')
    register('type')
    register('serviceOrderTypes')
  }, [register])

  useLayoutEffect(() => {
    if (facts?.serviceOrderTags) {
      setServiceOrder(SERVICE_ORDER.HAS_TAG)

      return
    }

    if (facts?.serviceOrderTypes) {
      setServiceOrder(SERVICE_ORDER.TYPE_OF)
    }
  }, [facts])

  const currentEvaluationLabel =
    watch('type') === ConditionType.SERVICE_ORDER_TYPES
      ? evaluationOSLabel
      : evaluationLabel

  return (
    <div>
      <RadioGroup title={comboboxLabel}>
        {Object.values(SERVICE_ORDER).map((option, key) => (
          <RadioButton
            value={option}
            onChange={(e) => {
              const value = e.target.value as SERVICE_ORDER
              const status = handleServiceOrderForm(value)
              const statusLabel = status.label
              const type = serviceOrderOptionType[statusLabel]

              setServiceOrder(statusLabel)
              setValue('serviceOrderType', statusLabel)
              setValue('tags', [])
              setValue('type', type)
              setValue('serviceOrderTypes', [])

              onChange({
                type,
                facts: {},
              })
            }}
            checked={serviceOrder === option}
            name={`serviceOrderType-${data.id}`}
            key={key}
          />
        ))}
      </RadioGroup>

      <div className={styles.wrapper}>
        <Combobox
          label={{
            text: 'Validar se',
          }}
          {...register('evaluation')}
          value={currentEvaluationLabel[watch('evaluation')]}
          items={parseDataToComboboxV2(
            evaluationOption.map((option) => ({
              ...option,
              label: currentEvaluationLabel[option.value],
            })),
            'label',
          )}
          onChange={(selected) => {
            setValue('evaluation', selected.value.value)

            onChange({
              type: watch('type'),
              facts: {
                ...(serviceOrder === SERVICE_ORDER.TYPE_OF && {
                  serviceOrderTypes: watch('serviceOrderTypes'),
                }),
                ...(serviceOrder === SERVICE_ORDER.HAS_TAG && {
                  serviceOrderTags: watch('tags'),
                }),
              },
              evaluation: selected.value.value,
            })
          }}
          placeholder="Selecione uma opção"
        />
      </div>

      {serviceOrder === SERVICE_ORDER.TYPE_OF && (
        <div className={styles.wrapper}>
          <Combobox
            value={serviceOrderTypes?.map(
              (type) => SERVICE_ORDER_TYPE_EN_PT[type] || [],
            )}
            label={{
              text: 'Tipo de OS',
            }}
            items={[...Object.values(SERVICE_ORDER_TYPE_EN_PT)]}
            onChange={(selected) => {
              const selectedServiceOrder =
                selected as SERVICE_ORDER_TYPE_EN_PT[]

              const normalizedTypes = selectedServiceOrder.map(
                (type) => translateServiceOrderType[type],
              )

              setValue('serviceOrderTypes', normalizedTypes, {
                shouldValidate: true,
              })

              onChange({
                type: ConditionType.SERVICE_ORDER_TYPES,
                facts: {
                  serviceOrderTypes: normalizedTypes,
                },
                evaluation: watch('evaluation'),
              })
            }}
            placeholder="Selecione um ou mais tipos de OS"
            multiple
          />
        </div>
      )}

      {serviceOrder === SERVICE_ORDER.HAS_TAG && (
        <div className={styles.wrapper}>
          <Combobox
            label={{
              text: comboboxLabel,
            }}
            multiple
            onSearch={(search) => setTagFilter(search)}
            items={parseDataToComboboxV2(serviceOrderTags || [], 'name')}
            value={tags?.map((tag) => ({
              label: 'name',
              value: tag,
            }))}
            onChange={(selecteds) => {
              const tagsSelected = selecteds as ComboboxItem<TagOutput>[]

              const formattedTags = tagsSelected.map((tag) => ({
                id: tag.value.id,
                name: tag.value.name,
              }))

              setValue('tags', formattedTags)

              onChange?.({
                type: ConditionType.SERVICE_ORDER_ACCOUNT_TAGS,
                facts: {
                  serviceOrderTags: formattedTags,
                },
                evaluation: watch('evaluation'),
              })
            }}
            isLoading={isFetchingServiceOrderTags}
            isError={isErrorServiceOrderTags}
            onEndReached={fetchNextTagsPage}
          />
        </div>
      )}
    </div>
  )
}
