import { useState } from 'react'

export interface ToggleHook {
  isVisible: boolean
  show(): void
  hide(): void
}

export const useToggle = (): ToggleHook => {
  const [isVisible, setVisible] = useState<boolean>(false)

  const show = () => {
    setVisible(true)
  }
  const hide = () => {
    setVisible(false)
  }

  return {
    isVisible,
    hide,
    show,
  }
}
