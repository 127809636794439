import { useEffect } from 'react'

interface ObjectKeys {
  [key: string]: string
}

export interface StorageTokens extends ObjectKeys {
  id: string
  name: string
  accessToken: string
  refreshToken: string
}

export const useStorage = () => {
  useEffect(() => {
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', Date.now().toString())
    }

    window.addEventListener('storage', (event) => {
      if (event.key === 'getSessionStorage') {
        localStorage.setItem(
          'sessionStorage',
          JSON.stringify(sessionStorage || ''),
        )
        localStorage.removeItem('sessionStorage')
      } else if (
        event.key === 'sessionStorage' &&
        !sessionStorage.length &&
        event.newValue
      ) {
        const data = JSON.parse(event.newValue || '') as StorageTokens | null

        if (data !== null) {
          for (const key in data) {
            sessionStorage.setItem(key, data[key])
          }
        }
      }
    })
  })
}
