import { useCallback, useEffect } from 'react'
import {
  Button,
  Combobox,
  ComboboxItem,
  Loader,
  Modal,
  ProtectedButton,
} from 'components'

import {
  ConfigurationProfileResponse,
  DeviceType,
} from 'services/partition/types'
import { useForm } from 'react-hook-form'

import styles from './PhotosensorConfig.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import {
  UpdatePhotosensorProfilesSchema,
  updatePhotosensorProfilesSchema,
} from 'domains/customer/screens/Equipments/schemas/devices/updatePhotosensorProfiles'
import { useGetDeviceTypeProfiles } from 'shared/hooks/services/device/useGetDeviceTypeProfiles'
import {
  PhotosensorFragment,
  Profile,
} from 'domains/customer/screens/Equipments/types'
import { usePostAndHandleConfigurePhotosensorCommand } from 'domains/customer/screens/Equipments/hooks/services/commands'
import { UbideskPermissions } from 'routes/types'
import { useQueryClient } from '@tanstack/react-query'
import { EquipmentResponse } from 'services/device/types'

type PhotosensorConfigProps = {
  centralId: string
  photosensor: PhotosensorFragment
  isVisible: boolean
  onClose: () => void
  partitionId?: string
}

export const PhotosensorConfig = ({
  centralId,
  photosensor,
  isVisible,
  onClose,
  partitionId,
}: PhotosensorConfigProps) => {
  const { register, setValue, watch, handleSubmit, formState, trigger, reset } =
    useForm<UpdatePhotosensorProfilesSchema>({
      resolver: joiResolver(updatePhotosensorProfilesSchema),
      mode: 'onChange',
    })

  const {
    mutateConfigurePhotosensorCommand,
    configurePhotosensorCommandMutationStatus,
  } = usePostAndHandleConfigurePhotosensorCommand()

  const pirProfile: Profile = watch('profiles.pirProfile')
  const cameraProfile: Profile = watch('profiles.cameraProfile')

  const {
    deviceProfiles: pirProfiles,
    isFetchingDeviceProfiles: isFetchingPirProfiles,
  } = useGetDeviceTypeProfiles(DeviceType.PassiveInfraRedSensor, isVisible)

  const {
    deviceProfiles: cameraProfiles,
    isFetchingDeviceProfiles: isFetchingCameraProfiles,
  } = useGetDeviceTypeProfiles(DeviceType.PassiveInfraPhotoRedSensor, isVisible)
  const queryClient = useQueryClient()

  const onSave = ({ profiles }: UpdatePhotosensorProfilesSchema) => {
    mutateConfigurePhotosensorCommand(
      {
        centralId,
        deviceId: photosensor.id,
        profiles: {
          ...(profiles?.pirProfile && {
            pirProfileId: profiles.pirProfile?.id,
          }),
          ...(profiles?.cameraProfile && {
            cameraProfileId: profiles.cameraProfile?.id,
          }),
        },
      },
      {
        onSuccess: async (_data, variables) => {
          const patrimonyId = localStorage.getItem('patrimonyId') ?? ''

          const previousEquipments =
            queryClient.getQueryData<EquipmentResponse>([
              'equipments',
              patrimonyId,
              centralId,
              partitionId,
            ])

          queryClient.setQueryData<EquipmentResponse>(
            ['equipments', patrimonyId, centralId, partitionId],
            {
              ...previousEquipments,
              devices:
                previousEquipments?.devices.map((device) =>
                  device.id === photosensor.id
                    ? {
                        ...device,
                        profiles: {
                          ...device.profiles,
                          ...(device.profiles.camera && {
                            camera: {
                              ...cameraProfiles?.find(
                                (profile) =>
                                  profile.id ===
                                  variables.profiles.cameraProfileId,
                              ),
                            },
                          }),
                          ...(device.profiles.pir && {
                            pir: {
                              ...pirProfiles?.find(
                                (profile) =>
                                  profile.id ===
                                  variables.profiles.pirProfileId,
                              ),
                            },
                          }),
                        },
                      }
                    : device,
                ) || [],
            },
          )

          reset({
            deviceId: photosensor.id,
            profiles,
          })

          onClose()
        },
      },
    )
  }

  const comboboxProfiles = useCallback(
    (profiles: ConfigurationProfileResponse[] | undefined) => {
      if (!profiles) return []

      const formattedProfiles: ComboboxItem<Profile>[] = profiles.map(
        (profile) => ({
          label: 'name',
          value: { id: profile.id, name: profile.name },
        }),
      )

      return formattedProfiles
    },
    [],
  )

  useEffect(() => {
    register('deviceId')
    register('profiles.cameraProfile')
    register('profiles.pirProfile')

    setValue('deviceId', photosensor.id)
    setValue(
      'profiles.pirProfile',
      {
        id: photosensor.profiles.pir?.id,
        name: photosensor.profiles.pir?.name,
      },
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    )
    setValue(
      'profiles.cameraProfile',
      {
        id: photosensor.profiles.camera?.id,
        name: photosensor.profiles.camera?.name,
      },
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    )
  }, [photosensor, setValue, register])

  return (
    <div>
      <Loader
        isVisible={
          isFetchingCameraProfiles ||
          isFetchingPirProfiles ||
          configurePhotosensorCommandMutationStatus === 'pending'
        }
      />
      <Modal
        title="Configurar fotossensor"
        isVisible={isVisible}
        onClose={onClose}
      >
        <div className={styles.wrapper}>
          <Combobox
            id="pir-config"
            value={
              pirProfile?.id
                ? {
                    label: 'name',
                    value: pirProfile,
                  }
                : undefined
            }
            label={'Sensibilidade do PIR'}
            items={comboboxProfiles(pirProfiles)}
            getSelected={(selected) => {
              const profile = selected as ComboboxItem<Profile>
              setValue('profiles.pirProfile', profile.value, {
                shouldDirty: true,
              })

              trigger()
            }}
          />

          <Combobox
            id="camera-config"
            label={'Configuração da câmera'}
            value={
              cameraProfile?.id
                ? {
                    label: 'name',
                    value: cameraProfile,
                  }
                : undefined
            }
            items={comboboxProfiles(cameraProfiles)}
            getSelected={(selected) => {
              const profile = selected as ComboboxItem<Profile>
              setValue('profiles.cameraProfile', profile.value, {
                shouldDirty: true,
              })

              trigger()
            }}
          />
        </div>
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Voltar"
            type="secondary"
            onClick={onClose}
          />
          <ProtectedButton
            permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            onClick={handleSubmit(onSave)}
            disabled={!formState.isDirty || !formState.isValid}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
