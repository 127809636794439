import Joi from '@hapi/joi'

export type SearchContact = {
  name?: string
  phone?: string
  email?: string
}

export const searchContactSchema = Joi.object<SearchContact>({
  name: Joi.string().optional().allow(null, ''),
  phone: Joi.string().optional().allow(null, ''),
  email: Joi.string().optional().allow(null, ''),
})
