import { useRef, useMemo, FC } from 'react'

import { Tooltip } from 'components'
import { dateNowWithSeconds } from 'utilities/date'
import { useToggle } from 'shared/hooks'
import { occurrenceTypesLabel } from '../../../../../types'
import { POSITION } from 'shared/hooks/useElementPosition/types'

import { colors } from '../../../utils'
import styles from './styles.module.scss'
import { OccurrenceEvent } from 'services/displacementMap/types'

interface EventProps {
  event: OccurrenceEvent
}

export const EventItem: FC<EventProps> = ({ event }) => {
  const eventRef = useRef<HTMLDivElement>(null)
  const eventTooltipRef = useToggle()

  const eventTitle = useMemo(() => {
    return `
    ${event.details.code} 
    - 
    ${event.details.name || ''} 
    ${event.details.sensorName}
    ${event.details?.device ? ` via ${event.details.device}` : ''}
    `
  }, [event])

  return (
    <li className={styles.container}>
      <div
        className={styles.eventBorder}
        style={{
          backgroundColor: colors[event.occurrenceType],
        }}
      />
      <div className={styles.eventContent}>
        <div className={styles.title}>
          <Tooltip
            positionOnEdge
            hasArrow={false}
            isVisible={eventTooltipRef.isVisible}
            parentRef={eventRef}
            positionAbove={POSITION.BOTTOM}
            type="informative"
          >
            <span>{eventTitle}</span>
          </Tooltip>
          <span
            ref={eventRef}
            onMouseEnter={eventTooltipRef.show}
            onMouseLeave={eventTooltipRef.hide}
          >
            {eventTitle}
          </span>
          <div className={styles[event.occurrenceType]}>
            {occurrenceTypesLabel[event.occurrenceType]}
          </div>
        </div>
        <div className={styles.details}>
          {event.partition?.name && <div>{event.partition.name}</div>}
          {(event.details.auxiliary ||
            event.deviceName ||
            event.details.originName) && (
            <div>
              {event.details.auxiliary || ''}
              {event.details.auxiliary && event.deviceName && ' - '}
              {event.deviceName || ''}
              {event.deviceName && event.details.originName && ' / '}
              {event.details.originName || ''}
            </div>
          )}
          <span>{dateNowWithSeconds(event.createdAt)}</span>
        </div>
      </div>
    </li>
  )
}
