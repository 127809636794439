import { useMutation } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import buildUrl from '../utils/buildUrl'
import { endpoints } from './endpoints'
import { DisableHabitDeviationPayload } from './types'

const disableHabitDeviation = async (
  partitionId: string,
  data: DisableHabitDeviationPayload,
) => {
  const response = await HttpClient.post(
    buildUrl({
      route: endpoints.disableHabitDeviation,
      params: { partitionId },
    }),
    data,
  )

  return response
}

export const useDisableHabitDeviation = () => {
  return useMutation({
    mutationFn: async ({
      partitionId,
      scheduledDate,
    }: DisableHabitDeviationPayload) => {
      if (partitionId) {
        return await disableHabitDeviation(partitionId, { scheduledDate })
      }
    },
  })
}
