import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { Button, Modal } from 'components'
import { BackDrop } from '../../../../components'

import { useUpdateVehicle, useFetchCars } from 'services/displacementMap'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { VehicleAggregatedQueryResponse } from 'services/vehicle'
import { useToast } from 'shared/hooks'
import { useMap, useDisplacementModal } from '../../../../contexts'

interface FormProps {
  vehicleId: string
}

export const schema = Joi.object<FormProps>({
  vehicleId: Joi.string().required(),
})

export const ChangeCar = () => {
  const { handleCloseModal } = useDisplacementModal()
  const { selectedRoute, handleRoute } = useMap()
  const { addToast } = useToast()
  const {
    setValue,
    handleSubmit,
    formState: { isValid },
    register,
  } = useForm({
    mode: 'all',
    resolver: joiResolver(schema),
  })

  const { data: carsOptions, isLoading: loadingCars } = useFetchCars()
  const { mutate } = useUpdateVehicle(selectedRoute.tactical?.id || '')

  const carsOnDuty = carsOptions?.data.filter((car) => car.driver.id)

  const onSubmit = (data: Omit<FormProps, 'occurrence'>) => {
    if (selectedRoute.tactical?.id) {
      mutate(data, {
        onSuccess: (success) => {
          if (success) {
            handleCloseModal()
            handleRoute({ marker: selectedRoute.tactical, type: 'tactical' })
          }
        },
        onError: () =>
          addToast({
            message: 'Erro ao alterar viatura. Tente novamente.',
            type: 'alert',
          }),
      })
    }
  }

  return (
    <BackDrop onClose={handleCloseModal}>
      <Modal
        simple
        title="Alterar viatura"
        isVisible
        onClose={handleCloseModal}
      >
        <form>
          <Combobox
            label={{ text: 'Viatura' }}
            items={parseDataToComboboxV2(
              carsOptions?.data || [],
              'licensePlate',
            )}
            {...register('vehicleId')}
            isLoading={loadingCars}
            onChange={(selected) => {
              const vehicle =
                selected as ComboboxItem<VehicleAggregatedQueryResponse>
              setValue('vehicleId', vehicle.value.id, {
                shouldValidate: true,
              })
            }}
            isDisabled={(item) => {
              const { value } =
                item as ComboboxItem<VehicleAggregatedQueryResponse>
              return carsOnDuty?.includes(value) ?? false
            }}
          />
        </form>
        <Modal.Footer>
          <Button
            type="secondary"
            buttonTitle="Cancelar"
            onClick={handleCloseModal}
            width="172px"
          />
          <Button
            type="primary"
            buttonTitle="Continuar"
            disabled={!isValid}
            width="172px"
            onClick={handleSubmit(onSubmit)}
          />
        </Modal.Footer>
      </Modal>
    </BackDrop>
  )
}
