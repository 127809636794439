import { useMutation } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'

interface DeleteImage {
  imageId?: string
}

export const deleteImage = async ({ imageId }: DeleteImage) => {
  const response = await HttpClient.delete(
    buildUrl({
      route: endpoints.delete,
      params: { imageId },
    }),
  )

  return response.data
}

export const useDeleteImage = (imageId?: string) => {
  return useMutation({
    mutationFn: async (_nothing: null) => await deleteImage({ imageId }),
  })
}
