import { DropDown } from '../../../../components'
import { useDisplacementModal } from '../../../../contexts'

export const Options = () => {
  const { handleOpenModal, handleCloseModal } = useDisplacementModal()

  return (
    <DropDown
      style={{ top: 64, right: 16, width: 183 }}
      onClose={handleCloseModal}
      items={[
        {
          label: 'Iniciar plantão tático manualmente',
          onClick: () => handleOpenModal('START_DUTY'),
        },
        {
          label: 'Ver lista de táticos disponíveis',
          onClick: () => handleOpenModal('TACTICAL'),
        },
      ]}
    />
  )
}
