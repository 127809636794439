import styles from './PolicyVisualization.module.scss'
import { ReactComponent as Trigger } from 'domains/attendancePolicy/assets/svg/trigger.svg'
import { ReactComponent as Condition } from 'domains/attendancePolicy/assets/svg/condition.svg'
import { ReactComponent as Action } from 'domains/attendancePolicy/assets/svg/action.svg'
import { PolicyVisualizationCard } from './components/PolicyVisualizationCard/PolicyVisualizationCard'
import { Policy, PolicyGroup } from 'domains/attendancePolicy/types'
import { AutomationFormKeys } from 'domains/attendancePolicy/components/RuleForm/types'

type PolicyVisualizationProps = {
  selected?: AutomationFormKeys
  triggerPolicy?: Policy
  conditionPolicies?: Policy | PolicyGroup[]
  actionPolicies?: Policy | PolicyGroup[]
  allActions?: boolean
  allConditions?: boolean
  onEdit?: (automationTitle: string) => void
}

export const PolicyVisualization = ({
  selected,
  triggerPolicy,
  conditionPolicies,
  actionPolicies,
  allActions,
  allConditions,
  onEdit,
}: PolicyVisualizationProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <PolicyVisualizationCard
          subtitle="Evento único que tem por objetivo iniciar a execução de uma automação"
          title="Gatilho"
          icon={<Trigger />}
          isSelected={selected === 'TRIGGER'}
          policies={triggerPolicy}
          onEdit={onEdit}
        />
        <PolicyVisualizationCard
          subtitle="Critério que precisa ser atendido para executar uma determinada ação"
          title="Condição"
          icon={<Condition />}
          isSelected={selected === 'CONDITION'}
          policies={conditionPolicies}
          allPolicies={allConditions}
          onEdit={onEdit}
        />
        <PolicyVisualizationCard
          subtitle="Realiza modificações em uma ocorrência após a execução de um gatilho"
          title="Ação"
          isSelected={selected === 'ACTION'}
          icon={<Action />}
          policies={actionPolicies}
          allPolicies={allActions}
          onEdit={onEdit}
        />
      </div>
      <div className={styles.gradient} />
    </div>
  )
}
