import { AxiosError } from 'axios'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export enum ErrorType {
  BAD_REQUEST = 1,
  SERVICE_UNAVAILABLE = 2,
}

export type ErrorHandlerHook = {
  handleError(error: Error): ErrorType | undefined
}

export const useErrorHandler = (): ErrorHandlerHook => {
  const navigate = useNavigate()

  const handleError = useCallback(
    (error: AxiosError): ErrorType | undefined => {
      if (
        error?.response?.status === 401 &&
        !window.location.href.includes('/login')
      ) {
        navigate('/login', {
          state: {
            sessionClosed: true,
          },
        })
      } else if (error?.response?.status === 400) {
        return ErrorType.BAD_REQUEST
      } else {
        return ErrorType.SERVICE_UNAVAILABLE
      }
    },
    [navigate],
  )

  return {
    handleError,
  }
}
