import FullCalendar from '@fullcalendar/react'
import { EventSourceInput } from '@fullcalendar/core'
import timegrid from '@fullcalendar/timegrid'

import classNames from './Calendar.module.scss'
import './Calendar.scss'
import { DAY_OF_WEEK } from 'services/types'
import ServiceOrderStatusCard from './components/ServiceOrderStatusCard'
import { WeekInterval } from './types'

type DayHeaderProps = {
  text: string
  isToday: boolean
}

function DayHeader({ text, isToday }: DayHeaderProps): JSX.Element {
  const parts = text.split(', ')
  const day = parts[1]
  let weekday = parts[0]
  weekday = weekday.charAt(0).toUpperCase() + weekday.slice(1)

  return (
    <div className={classNames.dayHeader}>
      <div className={classNames.dayHeaderWeekday}>{weekday}</div>
      <div
        className={[
          classNames.dayHeaderDay,
          isToday && classNames.dayHeaderDayToday,
        ].join(' ')}
      >
        {day}
      </div>
    </div>
  )
}

type CalendarProps = {
  events: EventSourceInput
  height?: number | string
  onChangeWeekInterval?: (interval: WeekInterval) => void
}

export function Calendar({
  events,
  height,
  onChangeWeekInterval,
}: CalendarProps): JSX.Element {
  return (
    <FullCalendar
      plugins={[timegrid]}
      initialView="timeGridWeek"
      hiddenDays={[DAY_OF_WEEK.SUNDAY]}
      datesSet={(dates) => {
        const newInterval: WeekInterval = {
          startDate: dates.start.getTime(),
          endDate: dates.end.getTime(),
        }
        onChangeWeekInterval?.(newInterval)
      }}
      headerToolbar={{ start: '', end: 'prev title next' }}
      titleFormat={{
        month: 'long',
        year: 'numeric',
        separator: ' - ',
      }}
      allDayText="Período"
      locale="pt-BR"
      eventBorderColor="transparent"
      eventClassNames={classNames.calendarEvent}
      viewClassNames={classNames.calendarPeriod}
      dayCellClassNames={classNames.calendarDayCell}
      dayHeaderClassNames={classNames.calendarDayHeader}
      allDayClassNames={classNames.calendarSlotLabel}
      slotLabelClassNames={classNames.calendarSlotLabel}
      dayHeaderContent={(e) => <DayHeader text={e.text} isToday={e.isToday} />}
      dayHeaderFormat={{ weekday: 'short', day: '2-digit' }}
      slotLabelFormat={{ hour: '2-digit', minute: '2-digit' }}
      eventContent={({ event }) => <ServiceOrderStatusCard event={event} />}
      events={events}
      scrollTime={{ hour: 0 }}
      slotDuration={{ hour: 1 }}
      height={height}
      dayMaxEvents={2}
      moreLinkText={(count) => `mais ${count}`}
      slotMaxTime="19:00"
      slotMinTime="08:00"
    />
  )
}
