import { Button } from 'components'
import { ReactComponent as RewindInTime } from '../../svg/rewindInTime.svg'
import { ReactComponent as AdvanceByTime } from '../../svg/advanceByTime.svg'
import { ReactComponent as Play } from '../../svg/play.svg'
import { ReactComponent as Pause } from '../../svg/pause.svg'

import styles from './styles.module.scss'
import { FC } from 'react'

type ControlsProps = {
  onPlayPause: () => void
  onSeek: (time: number) => void
  playing: boolean
}

const TEN_SECONDS = 10

const Controls: FC<ControlsProps> = ({ onPlayPause, onSeek, playing }) => {
  return (
    <div className={styles.container}>
      <RewindInTime
        className={styles.tenSecondsButton}
        onClick={() => onSeek(-TEN_SECONDS)}
      />

      <Button
        className={styles.playButton}
        type="primary"
        icon={playing ? Pause : Play}
        onClick={onPlayPause}
      />

      <AdvanceByTime
        className={styles.tenSecondsButton}
        onClick={() => onSeek(+TEN_SECONDS)}
      />
    </div>
  )
}

export default Controls
