import React from 'react'

import { ProtectedImage } from 'components'
import { dateNowWithSeconds } from 'utilities/date'
import { HistoryEvent } from 'services/attendance/types'

import styles from './styles.module.scss'
import { colors, occurrenceTypesLabel } from './utils'

interface EventCardProps {
  event: HistoryEvent
  onImage: (imageId: string) => void
}

const EventCard: React.FC<EventCardProps> = ({ event, onImage }) => (
  <li className={styles.container}>
    <div
      className={styles.eventBorder}
      style={{
        backgroundColor: colors[event.occurrenceType],
      }}
    />
    <div className={styles.eventContent}>
      <div>
        <div className={styles.title}>
          <span>
            {`
            ${event.details.code} 
            - 
            ${event.details.name || ''} 
            ${event.details.sensorName}
            ${event.details?.device ? ` via ${event.details.device}` : ''}
          `}
          </span>
          <div className={styles[event.occurrenceType]}>
            {occurrenceTypesLabel[event.occurrenceType]}
          </div>
        </div>
        <div className={styles.details}>
          {event.partition?.name && <div>{event.partition.name}</div>}
          {(event.details.auxiliary ||
            event.deviceName ||
            event.details.originName) && (
            <div>
              {event.details.auxiliary || ''}
              {event.details.auxiliary && event.deviceName && ' - '}
              {event.deviceName || ''}
              {event.deviceName && event.details.originName && ' / '}
              {event.details.originName || ''}
            </div>
          )}
          <span>{dateNowWithSeconds(event.createdAt)}</span>
        </div>
      </div>

      {event.imageId && (
        <ProtectedImage
          imageId={event.imageId}
          onClick={() => event.imageId && onImage(event.imageId)}
        />
      )}
    </div>
  </li>
)

export default EventCard
