import { FC, useMemo, useState } from 'react'

import styles from './styles.module.scss'
import { ReactComponent as SeeMore } from 'assets/svg/listInspectIcon.svg'

type ExpandableTextProps = {
  text: string
}

const MAXIMUM_SIZE = 200

const ExpandableText: FC<ExpandableTextProps> = ({ text }) => {
  const [textIsExpanded, setTextIsExpanded] = useState(false)
  const textIsExpandable = useMemo(() => text.length > MAXIMUM_SIZE, [text])

  const lines = text.split('\n')

  return (
    <div className={styles.container}>
      <span className={[!textIsExpanded && styles.truncated].join(' ')}>
        {lines.map((line) => (
          <>
            {line}
            <br />
          </>
        ))}
      </span>
      {textIsExpandable && (
        <button onClick={() => setTextIsExpanded((old) => !old)}>
          <SeeMore />
          {textIsExpanded ? 'Ver menos' : 'Ver mais'}
        </button>
      )}
    </div>
  )
}

export default ExpandableText
