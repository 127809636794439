import * as Joi from '@hapi/joi'

export type UpdateSirenVolumeSchema = {
  deviceId: string
  gain: number
}

export const updateSirenVolumeSchema = Joi.object<UpdateSirenVolumeSchema>({
  deviceId: Joi.string().uuid().required(),
  gain: Joi.number().min(1).max(3).required(),
})
