import React, { useRef, useState } from 'react'
import Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { Button, Modal, Textarea, Tooltip } from 'components'
import Loader from '../Loader'

import {
  ServiceOrderNoteInput,
  useCreateServiceOrderNote,
  useGetServiceOrderNotes,
} from 'services/serviceOrder'
import { dateNow } from 'utilities/date'
import { useToast, useToggle, useUserInfo } from 'shared/hooks'

import styles from './styles.module.scss'

interface FormProps {
  isVisible: boolean
  onClose: () => void
  serviceOrderId: string | undefined
}

const schema = Joi.object<ServiceOrderNoteInput>({
  userId: Joi.string().uuid(),
  description: Joi.string().required(),
})

export const Notes: React.FC<FormProps> = ({
  isVisible,
  onClose,
  serviceOrderId,
}) => {
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isValid },
  } = useForm<ServiceOrderNoteInput>({
    mode: 'onChange',
    resolver: joiResolver(schema),
    defaultValues: {
      description: '',
    },
  })

  const tooltip = useToggle()
  const tooltipRef = useRef<HTMLDivElement>(null)
  const { addToast } = useToast()

  const description = watch('description')
  const [triedToClose, setTriedToClose] = useState(false)
  const { data } = useGetServiceOrderNotes(serviceOrderId)
  const { mutate, isPending: isSubmitting } =
    useCreateServiceOrderNote(serviceOrderId)

  const { userInfo } = useUserInfo()

  const onSubmit = (data: ServiceOrderNoteInput) => {
    const userId = userInfo.id

    if (userId && isValid) {
      mutate(
        {
          userId,
          description: data.description,
        },
        {
          onSuccess: () => {
            addToast({
              message: 'Nota criada com sucesso.',
              type: 'success',
            })
            reset()
          },
          onError: () => {
            addToast({
              message: 'Erro ao criar nota. Tente novamente',
              type: 'alert',
            })
          },
        },
      )
    }
  }

  const handleClose = () => {
    if (description && !triedToClose) {
      setTriedToClose(true)
      tooltip.show()
    } else {
      onClose()
      reset()
      setTriedToClose(false)
    }
  }

  return (
    <>
      <Modal
        title="Histórico de notas"
        isVisible={isVisible}
        className={styles.container}
      >
        {isSubmitting && <Loader />}
        <div className={styles.content}>
          {data?.length ? (
            <ul>
              {data
                .sort((prev, next) => prev?.createdAt + next?.createdAt)
                .map((note) => (
                  <li key={note?.createdAt}>
                    <div>
                      <span>
                        {dateNow(note?.createdAt)} - {note?.userName}
                      </span>
                    </div>
                    <span>
                      {note?.description.split(/(\n)/g).map((part, index) =>
                        part === '\n' ? (
                          <React.Fragment key={index}>
                            {part}
                            <br />
                          </React.Fragment>
                        ) : (
                          part
                        ),
                      )}
                    </span>
                  </li>
                ))}
            </ul>
          ) : (
            <span>Não existem notas adicionadas a essa OS.</span>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="description">Notas da OS</label>
          <Textarea
            id="description"
            {...register('description')}
            onChange={(event) =>
              setValue('description', event?.target.value, {
                shouldValidate: true,
              })
            }
            value={description}
            onFocus={() => {
              tooltip.hide()
              setTriedToClose(false)
            }}
            rows={6}
          />
          <Modal.Footer>
            <div
              className={styles.iconTooltip}
              ref={tooltipRef}
              onClick={tooltip.show}
            >
              <Button
                type={triedToClose ? 'cancel-secondary' : 'secondary'}
                buttonTitle="Fechar"
                onClick={handleClose}
              />
              {triedToClose && (
                <Tooltip
                  parentRef={tooltipRef}
                  type="informative"
                  isVisible={tooltip.isVisible}
                >
                  <span>
                    Existem informações que não foram salvas. Deseja realmente
                    fechar?
                  </span>
                </Tooltip>
              )}
            </div>
            <Button type="primary" htmlType="submit" buttonTitle="Salvar" />
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default Notes
