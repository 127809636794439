import { UseMutationResult, useMutation } from '@tanstack/react-query'

import { OfficeHoursDriver } from 'services/officeHours'
import { OfficeHoursPayload } from 'services/officeHours/types'

export type CreateOfficeHoursRequest = {
  payload: OfficeHoursPayload
}

function createOfficeHours(request: CreateOfficeHoursRequest): Promise<void> {
  const { payload } = request
  return OfficeHoursDriver.create(payload)
}

export type UsePostOfficeHours = UseMutationResult<
  void,
  unknown,
  CreateOfficeHoursRequest,
  unknown
>

export const usePostOfficeHours = (): UsePostOfficeHours => {
  const mutation = useMutation({
    mutationFn: async (request: CreateOfficeHoursRequest) => {
      await createOfficeHours(request)
    },
  })

  return mutation
}
