import { useQuery } from '@tanstack/react-query'
import { CoverageDriver } from 'services/coverages'

const query = async (memoizedAttendanceProfileId: string) => {
  const result = await CoverageDriver.findById(memoizedAttendanceProfileId)
  return result
}

export const useGetCoverage = (
  memoizedAttendanceProfileId: string,
  enabled?: boolean,
) => {
  const { data: coverage, ...rest } = useQuery({
    queryKey: ['coverage', memoizedAttendanceProfileId],
    queryFn: async () => await query(memoizedAttendanceProfileId),
    enabled,
  })

  return {
    coverage,
    ...rest,
  }
}
