import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg'
import { ReactComponent as BackIcon } from 'assets/svg/back.svg'
import { ReactComponent as PlusSign } from 'domains/attendancePolicy/assets/svg/plusSign.svg'
import { ReactComponent as Info } from 'assets/svg/info.svg'

import { Accordion, Button, ButtonGroup, Tooltip } from 'components'
import {
  RuleConditionFormComponent,
  ConditionRuleOption,
  AccordionType,
  ConditionOption,
} from 'domains/attendancePolicy/types'

import styles from './AccordionForm.module.scss'

import {
  OccurrenceStateForm,
  DislocationLimitForm,
  EventMitigationForm,
  EventTypeForm,
  TagsForm,
  ServiceOrderForm,
  CentralPartitionStatus,
  DeviceQuantity,
  ServiceType,
  OccurrenceFinishType,
  ImageDetection,
} from '../'
import {
  AggregatedBranch,
  AggregatedCondition,
  BranchOperation,
  ConditionType,
} from 'services/attendancePolicy/types'

import { useToggle } from 'shared/hooks'
import { AccordionCondition } from 'domains/attendancePolicy/components/RuleForm/types'

import { joiResolver } from '@hookform/resolvers/joi'
import { useForm } from 'react-hook-form'
import { conditionsSchema } from 'domains/attendancePolicy/schema'
import {
  conditionOption,
  getConditionAccordionTitle,
} from 'domains/attendancePolicy/components/RuleForm/utilities/condition/condition'

interface AccordionCardProps {
  ruleOptions?: ConditionRuleOption[]
  type: AccordionType
  branch: AggregatedBranch
  onSubmit: (
    conditions: AggregatedCondition[],
    conditionOperation: BranchOperation,
    shouldAddNewGroup?: boolean,
  ) => void
}

export const AccordionForm = ({
  ruleOptions,
  type,
  branch,
  onSubmit,
}: AccordionCardProps) => {
  const form = useForm<{ conditions: AccordionCondition[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(conditionsSchema),
    defaultValues: {
      conditions: [],
    },
  })

  const { register, watch, setValue, formState, trigger } = form

  const conditions = watch('conditions')
  const [conditionOperation, setConditionOperation] = useState<BranchOperation>(
    branch.conditionsOperation || BranchOperation.AND,
  )

  const tooltipRef = useRef<HTMLDivElement>(null)

  const tooltip = useToggle()

  const ruleFormComponents: RuleConditionFormComponent = useMemo(
    () => ({
      [ConditionOption.OCCURRENCE_STATE_NAMES]: OccurrenceStateForm,
      [ConditionOption.OCCURRENCE_EVENTS_MITIGATION]: EventMitigationForm,
      [ConditionOption.TAGS]: TagsForm,
      [ConditionOption.SERVICE_ORDER]: ServiceOrderForm,
      [ConditionOption.OCCURRENCE_HAS_EVENTS]: EventTypeForm,
      [ConditionOption.ACCOUNT_TRAVEL_LIMIT]: DislocationLimitForm,
      [ConditionOption.CENTRAL_PARTITIONS_STATUS]: CentralPartitionStatus,
      [ConditionOption.DEVICE_QUANTITY]: DeviceQuantity,
      [ConditionOption.SERVICE_TYPE]: ServiceType,
      [ConditionOption.OCCURRENCE_FINISHED_MANUALLY]: OccurrenceFinishType,
      [ConditionOption.EVENT_IMAGE_DETECTIONS]: ImageDetection,
    }),
    [],
  )

  const renderFormComponent = useCallback(
    (condition: AccordionCondition) => {
      if (!condition.formType) return

      const Form = ruleFormComponents[condition.formType]

      return (
        <Form
          data={condition}
          onChange={(changedCondition) => {
            const updatedConditions = [...conditions]

            updatedConditions[condition.id] = {
              id: condition.id,
              formType: condition.formType,
              facts: changedCondition.facts,
              evaluation: changedCondition.evaluation,
              type: changedCondition.type || condition.type,
            }

            setValue('conditions', updatedConditions, { shouldValidate: true })
          }}
        />
      )
    },
    [ruleFormComponents, setValue, conditions],
  )

  const onSave = useCallback(
    (shouldAddNewGroup?: boolean) => {
      const normalizedConditions: AggregatedCondition[] = conditions.map(
        (condition) => ({
          evaluation: condition.evaluation,
          facts: condition.facts || {},
          type: condition.type || ConditionType.ACCOUNT_TAGS,
        }),
      )

      onSubmit(normalizedConditions, conditionOperation, shouldAddNewGroup)
    },
    [conditions, onSubmit, conditionOperation],
  )

  useLayoutEffect(() => {
    register('conditions')
    setValue(
      'conditions',
      branch.conditions.length
        ? branch.conditions.map((condition, index) => ({
            ...condition,
            formType: conditionOption[condition.type],
            id: index,
          }))
        : [{ id: 0 }],
    )
    trigger()
  }, [register, trigger, branch, setValue])

  return (
    <>
      {conditions?.length > 1 && (
        <div className={styles.buttonGroupWrapper}>
          <ButtonGroup
            leftButton={{
              id: 'only-condition',
              onClick: () => {
                setConditionOperation(BranchOperation.OR)
              },
              title: 'Apenas uma',
              selected: conditionOperation === BranchOperation.OR,
            }}
            rightButton={{
              id: 'all-conditions',
              onClick: () => setConditionOperation(BranchOperation.AND),
              title: 'Todas abaixo',
              selected: conditionOperation === BranchOperation.AND,
            }}
          />
        </div>
      )}

      <main className={styles.accordionWrapper}>
        {conditions?.map((condition, index) => (
          <div key={index}>
            <div className={styles.container}>
              <Accordion.Root>
                <Accordion.Item>
                  {({ isOpen }) => (
                    <>
                      <Accordion.Header>
                        <div>
                          <Accordion.Trigger>
                            <div className={styles.startHeader}>
                              {condition.formType ? (
                                <>
                                  <div className={styles.header}>
                                    <div>
                                      <h1 className={styles.text}>
                                        {type} {index + 1}
                                        {!isOpen && condition.type && (
                                          <>
                                            <span
                                              className={styles.conditionType}
                                            >
                                              {`: `}
                                              {getConditionAccordionTitle({
                                                evaluation:
                                                  condition.evaluation || 'HAS',
                                                facts: condition.facts,
                                                type: condition.type,
                                              })}
                                            </span>
                                          </>
                                        )}
                                      </h1>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <h2>Selecione o tipo de condição</h2>
                              )}

                              <Accordion.Icon
                                className={[
                                  styles.arrow,
                                  condition.facts && styles.arrowWrapper,
                                ].join(' ')}
                              />
                            </div>
                          </Accordion.Trigger>
                        </div>
                        {!!condition.formType && (
                          <div className={styles.endHeader}>
                            <BackIcon
                              className={styles.hoverIcon}
                              onClick={() => {
                                const updatedConditions = [...conditions]
                                updatedConditions[condition.id] = {
                                  id: condition.id,
                                }

                                setValue('conditions', updatedConditions, {
                                  shouldValidate: true,
                                })
                              }}
                            />
                            <TrashIcon
                              className={[styles.icon, styles.hoverIcon].join(
                                ' ',
                              )}
                              onClick={() => {
                                if (conditions.length !== 1) {
                                  setValue(
                                    'conditions',
                                    conditions.filter(
                                      (prevCondition) =>
                                        prevCondition.id !== condition.id,
                                    ),
                                  )
                                }
                              }}
                            />
                          </div>
                        )}
                      </Accordion.Header>

                      <Accordion.Content className={styles.innerContent}>
                        {!condition.formType ? (
                          <>
                            <div className={styles.optionContainer}>
                              {ruleOptions?.map((option) => (
                                <div
                                  onClick={() => {
                                    const updatedConditions = [...conditions]
                                    updatedConditions[condition.id] = {
                                      id: condition.id,
                                      formType: option.formType,
                                    }

                                    setValue('conditions', updatedConditions)
                                  }}
                                  key={option.id}
                                  className={styles.option}
                                >
                                  <option.icon className={styles.optionIcon} />
                                  <h2>{option.title}</h2>
                                  <p>{option.description}</p>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div className={styles.childrenContent}>
                            {renderFormComponent(condition)}
                          </div>
                        )}
                      </Accordion.Content>
                    </>
                  )}
                </Accordion.Item>
              </Accordion.Root>
            </div>

            <div key={condition.id}>
              {index !== conditions.length - 1 && (
                <div
                  data-text={
                    conditionOperation === BranchOperation.AND ? 'E' : 'Ou'
                  }
                  className={styles.spacer}
                />
              )}
            </div>
          </div>
        ))}

        <button
          type="button"
          onClick={() => {
            setValue('conditions', [...conditions, { id: conditions.length }], {
              shouldValidate: true,
            })
          }}
          className={styles.button}
        >
          <PlusSign />
          {`Adicionar nova ${type.toLowerCase()}`}
        </button>

        <div className={styles.footer}>
          <Button
            buttonTitle="Salvar condição"
            onClick={() => onSave(false)}
            disabled={!formState.isValid}
            type="secondary"
            width="172px"
          />
        </div>

        <div className={styles.addNewGroup}>
          <Button
            onClick={() => onSave(true)}
            icon={PlusSign}
            type="tertiary"
            disabled={!formState.isValid}
            buttonTitle={`Adicionar novo grupo de condições`}
          />
          <div
            ref={tooltipRef}
            onMouseEnter={tooltip.show}
            onMouseLeave={tooltip.hide}
          >
            <Info className={styles.info} />
          </div>

          <Tooltip
            type="informative"
            isVisible={tooltip.isVisible}
            parentRef={tooltipRef}
          >
            <p className={styles.tooltipContent}>
              Conjunto de condições que devem ser atendidas em sequência ou em
              paralelo para realizar uma tarefa.
            </p>
          </Tooltip>
        </div>
      </main>
    </>
  )
}
