export function generateHoursSlots(): string[] {
  const hours: string[] = []

  for (let i = 1; i <= 23; i++) {
    const hour = i <= 9 ? `0${i}` : `${i}`
    hours.push(hour)
  }

  return [...hours, '00']
}

export function generateMinutesSlots() {
  const minutes: string[] = []

  for (let i = 0; i < 60; i++) {
    const minute = i < 10 ? `0${i}` : `${i}`
    minutes.push(minute)
  }

  return minutes
}

export function getHours(timestamp: number): string {
  const data = new Date(timestamp)

  const hours = data.getHours().toString().padStart(2, '0')
  const minutes = data.getMinutes().toString().padStart(2, '0')

  return `${hours}:${minutes}`
}
