import { ACCOUNT_STATUS, AccountStatus } from './types'

const userStatus: Record<string, AccountStatus> = {
  [ACCOUNT_STATUS.ACTIVE]: {
    label: ACCOUNT_STATUS.ACTIVE,
    value: true,
  },
  [ACCOUNT_STATUS.INACTIVE]: {
    label: ACCOUNT_STATUS.INACTIVE,
    value: false,
  },
  [ACCOUNT_STATUS.ALL]: {
    label: ACCOUNT_STATUS.ALL,
    value: undefined,
  },
}

export const getAccountStatus = (status: string): AccountStatus =>
  userStatus[status]
