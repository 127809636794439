import { Info, Skeleton } from 'components'

import styles from './styles.module.scss'

const Loading = () => (
  <>
    <section>
      <div className={styles.title}>
        <div>
          <h2>Descrição da OS</h2>
          <span>
            Detalhes sobre o serviço a ser realizado durante a execução da ordem
            de serviço
          </span>
        </div>
        <Skeleton height={24} width={24} />
      </div>

      <div className={styles.infoGrid}>
        {Array.from({ length: 8 }).map((_, index) => (
          <Info.Root key={index}>
            <Info.Title>
              <Skeleton height={18} width={116} />
            </Info.Title>
            <Info.Content>
              <Skeleton height={34} />
            </Info.Content>
          </Info.Root>
        ))}
      </div>
    </section>
  </>
)

export default Loading
