import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AuthDriver, CreateUserSessionRequest } from 'services/auth'

async function createUserSession(payload: CreateUserSessionRequest) {
  return await AuthDriver.createUserSession(payload)
}

export const useUserSession = () => {
  const queryClient = useQueryClient()

  const { mutate: mutateCreateUserSession, status: createUserSessionStatus } =
    useMutation({
      mutationFn: (payload: CreateUserSessionRequest) =>
        createUserSession(payload),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['fetch-user-info'] })
      },
    })

  return {
    mutateCreateUserSession,
    createUserSessionStatus,
  }
}
