import { AxiosInstance } from 'axios'
import HttpClient from '../httpClient'
import { SoundQueryParams, SoundResponse } from 'services/sound/types'
import { Result } from 'services/types'

interface SoundDriver {
  getSound(soundId: string): Promise<string>
  query(query: SoundQueryParams): Promise<Result<SoundResponse>>
}

class SoundDriverImpl implements SoundDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async query(query: SoundQueryParams): Promise<Result<SoundResponse>> {
    const result = await this.httpClient.get<Promise<Result<SoundResponse>>>(
      `/sound/query`,
      {
        params: query,
      },
    )

    return result.data
  }

  public async getSound(soundId: string) {
    const response = await this.httpClient.get(`/sound/${soundId}/file`, {
      responseType: 'blob',
    })
    return URL.createObjectURL(response.data)
  }
}

export default new SoundDriverImpl()
