import * as Joi from '@hapi/joi'

import { AttendancePolicyFilter } from 'domains/attendancePolicy/types'

import {
  accountSchema,
  customerSchema,
  occurrenceTypeSchema,
  serviceTypeSchema,
} from 'shared/schemas'

export const attendancePolicyFiltersSchema = Joi.object<AttendancePolicyFilter>(
  {
    name: Joi.string().allow('').optional(),
    serviceType: serviceTypeSchema.optional(),
    occurrenceType: occurrenceTypeSchema.optional(),
    active: Joi.boolean().optional(),
    hasSelectors: Joi.boolean().optional(),
    customer: customerSchema.optional(),
    account: accountSchema.optional(),
    createdFrom: Joi.number().optional(),
    createdTo: Joi.number().optional(),
  },
)
