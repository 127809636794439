import { ButtonV2, Icon, ModalV2 } from 'components'
import { useFormContext } from 'react-hook-form'

const DiscardModal = () => {
  const { reset } = useFormContext()

  return (
    <>
      <ModalV2.Title>
        <Icon name="warning" color="danger" width={20} />
        Descartar conteúdo
      </ModalV2.Title>
      <p>
        Tem certeza de que deseja descartar o conteúdo antes de salvar? Você
        perderá todas as informações.
      </p>
      <ModalV2.Footer>
        <ModalV2.Close>
          <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
        </ModalV2.Close>
        <ModalV2.Close>
          <ButtonV2 color="red" onClick={() => reset({})}>
            Descartar
          </ButtonV2>
        </ModalV2.Close>
      </ModalV2.Footer>
    </>
  )
}

export default DiscardModal
