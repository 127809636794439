import { Coverage } from 'services/coverages'
import { Station } from '../station'
import { Pagination, Result, Timestamps } from '../types'
import { CoverageType } from 'services/occurrence/types'

export declare type UserQuery = {
  realm?: string
  scope?: string
  username?: string
  name?: string
  phoneExtension?: number
  permissionGroupId?: string
  coverageType?: CoverageType
  coverageId?: string
  updatedFrom?: number
  updatedTo?: number
  sort?: string
  sortType?: string
  active?: boolean
  tags?: string
} & Partial<Pagination>

export type PermissionGroups = {
  id: string
  name: string
}

export type Profiles = {
  id: string
  name: string
}

export type Tags = {
  id: string
  name: string
}

export type UserCoverageFragment = {
  id: string
  name: string
}

export type UserCoverages = {
  primary?: Coverage[]
  secondary?: Coverage[]
}

export type UserQueryResult = {
  id: string
  username: string
  name: string
  email: string
  document?: string
  phone: number
  active: boolean
  createdAt: number
  updatedAt: number
  permissionGroups?: PermissionGroups[]
  profiles?: Profiles[]
  tags?: Tags[]
  phoneExtension?: number
}

export type AggregatedUserQueryResponse = UserQueryResult & {
  userCoverages?: {
    primary: UserCoverageFragment[]
    secondary: UserCoverageFragment[]
  }
}

export type UserResult = {
  id: string
  active: boolean
  realm: string
  name: string
  email: string
  document?: string
  phone?: number
  scopes?: string
  type: string
  createdAt: number
  updatedAt: number
}

export declare type TagQueryResponse = {
  id: string
  name: string
}

export type AccessToken = {
  realm: string
  scopes: string
  exp: number
  iat: number
  user: {
    id: string
    username: string
  }
  jti: string
}

export type LoginResponse = {
  access_token: string
  token_type: string
  expires_in: number
  refresh_token: string
}

export type RefreshTokenResponse = {
  accessToken: string
  refreshToken: string
}

export type LoginRequest = {
  login: string
  password: string
}

export type ResetPasswordRequest = {
  email: string
}

export type UserCoveragesRequest = {
  primary?: string[]
  secondary?: string[]
}

export type UserTag = {
  name: string
}

export type CreateUserRequest = {
  name: string
  email?: string
  username?: string
  document?: string
  password?: string
  confirmPassword?: string
  phoneExtension: string
  permissionGroups: string[]
  tags: UserTag[]
  userCoverages?: UserCoveragesRequest
  phone?: {
    countryCode: number
    provinceCode: number
    phoneNumber: number
  }
  address?: {
    postalCode?: number
    districtId?: string
    address?: string
    number?: string
    adjunct?: string
    reference?: string
  }
}

export type UpdateUserRequest = {
  active: boolean
} & CreateUserRequest

export type SavePasswordRequest = {
  token: string
  newPassword: string
  confirmNewPassword: string
}

export type ChangePasswordRequest = {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export type ValidateTokenRequest = {
  token: string
}

export type TokenError = {
  response: {
    config: {
      baseURL: string
    }
    data: {
      error: string
    }
    status: number
  }
}

export type UserPermissions = {
  [key: string]: boolean
}

export type Permissions = {
  id: string
  name: string
}

export type PermissionGroupsQueryResponse = {
  id: string
  name: string
}

export type UserInfo = {
  name: string
  email: string
  active: string
  phoneExtension: string
  permissionGroups: PermissionGroupsQueryResponse[]
  tags: TagQueryResponse[]
  userCoverages: UserCoverages
}

export type PermissionGroupsListResponse = Result<PermissionGroupsQueryResponse>

export type ProfileQueryResponse = {
  id: string
  name: string
}

export interface UserInfoRequest {
  name: string
  email: string
  phoneExtension: string
}

export type UserInfoStation = Pick<
  Station,
  'id' | 'name' | 'phoneExtension' | 'stationType'
>

type commonType = {
  id: string
  name: string
}
export interface UserInfoResponse {
  id: string
  name: string
  username: string
  email: string
  document: string
  profiles: ProfileQueryResponse[]
  active: boolean
  permissionGroups: PermissionGroupsQueryResponse[]
  userCoverages?: UserCoverages
  phoneExtension: string
  tags: TagQueryResponse[]
  imageId?: string
  permissions: UserPermissions
  station?: UserInfoStation
  isOnline?: boolean
  phone: number
  phoneCountryCode: number
  phoneProvinceCode: number
  address?: {
    postalCode?: number
    districtId?: string
    address?: string
    number?: string
    adjunct?: string
    reference?: string
  }
  state?: commonType
  city?: commonType
  district?: commonType
  activeDateTime: number
}

export type UserTagResponse = {
  id: string
  name: string
}

export type Profile = {
  id: string
  name: string
} & Timestamps

export type UserAddress = {
  postalCode: number
  districtId: string
  address: string
  number: string
  adjunct: string
  reference: string
}

export type User = {
  realm: string
  name: string
  username?: string
  email?: string
  document?: string
  phone?: number
  phoneExtension?: string
  scopes?: string
  type: string
  tags?: UserTag[]
  phoneCountryCode?: number
  phoneProvinceCode?: number
  address?: UserAddress
}
export type UserResponse = {
  id: string
  active: boolean
  activeDateTime: number
  permissionGroups: PermissionGroupsQueryResponse[] | string[]
  tags: UserTagResponse[]
  profiles: Profile[] | string[]
} & User &
  Timestamps

export interface UserProfileImageResponse {
  id: string
  address: string
  dateTime: number
}

export type ProfileListResponse = Result<ProfileQueryResponse>

export enum UserSessionStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export type CreateUserSessionRequest = {
  userId: string
  status: UserSessionStatus
}

export type TagQueryRequest = {
  name?: string
} & Partial<Pagination>
