import { ButtonV2, Icon, ModalV2, TabsV2 } from 'components'
import {
  AllHistory,
  EventHistory,
  OccurrenceHistory,
  SOHistory,
} from './components'

const History = () => {
  return (
    <section>
      <h3>Histórico da conta</h3>
      <TabsV2.Root>
        <TabsV2.List>
          <TabsV2.Tab>Ordens de serviço</TabsV2.Tab>
          <TabsV2.Tab>Ocorrências</TabsV2.Tab>
          <TabsV2.Tab>Eventos</TabsV2.Tab>
        </TabsV2.List>
        <TabsV2.Panels>
          <TabsV2.Panel>
            <SOHistory />
          </TabsV2.Panel>
          <TabsV2.Panel>
            <OccurrenceHistory />
          </TabsV2.Panel>
          <TabsV2.Panel>
            <EventHistory />
          </TabsV2.Panel>
        </TabsV2.Panels>
      </TabsV2.Root>
      <ModalV2.Root>
        <ModalV2.Trigger>
          <ButtonV2 appearance="tertiary" size="md">
            <Icon name="open" />
            Ver todo o histórico
          </ButtonV2>
        </ModalV2.Trigger>
        <ModalV2.Content size="lg">
          <AllHistory />
        </ModalV2.Content>
      </ModalV2.Root>
    </section>
  )
}

export default History
