import { useCallback, useState } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import usePostIntervention from 'shared/hooks/services/occurrence/usePostIntervention'
import { Coordinates } from 'services/occurrence/types'
import {
  fetchTacticals,
  useFetchTacticals,
  useGetOccurrencesByUserCoverageType,
} from 'services/displacementMap'
import { useToast, useUserInfo } from 'shared/hooks'

import styles from './ChangeTactical.module.scss'
import { useMap, useDisplacementModal } from '../../../../contexts'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { Button, ComboBoxV3, FormItem, FormLabel, Modal } from 'components'
import { BackDrop } from 'domains/occurrence/screens/DisplacementMap/components'

interface FormProps {
  tactical: {
    id: string
    agentName: string
    vehicleId: string
    licensePlate: string
    occurrenceId?: string
    coordinates: Coordinates
  }
  observation?: string
  occurrence?: string
  comment?: string
}

export const schema = Joi.object<FormProps>({
  tactical: Joi.object({
    id: Joi.string(),
    agentName: Joi.string(),
    vehicleId: Joi.string(),
    licensePlate: Joi.string(),
    occurrenceId: Joi.string().allow('', null).optional(),
    coordinates: Joi.object<Coordinates>({
      latitude: Joi.number(),
      longitude: Joi.number(),
    }),
  }).required(),
  observation: Joi.string().optional(),
  comment: Joi.string().optional(),
})

type ChangeTacticalStateDecisions = 'ERROR' | 'QUESTION' | 'FORM'
type Decisions = Exclude<ChangeTacticalStateDecisions, 'FORM'> & 'SUCCESS'

export const ChangeTactical = () => {
  const { selectedRoute } = useMap()
  const { userInfo } = useUserInfo()
  const userId = userInfo.id
  const { data: occurrences } = useGetOccurrencesByUserCoverageType(userId)
  const { handleCloseModal } = useDisplacementModal()

  const [stateRender, setStateRender] =
    useState<ChangeTacticalStateDecisions>('FORM')

  const { data: agentDuty } = useFetchTacticals(userId)

  const defaultTactical = agentDuty?.find(
    (duty) => duty.id === selectedRoute.tactical?.id,
  )

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormProps>({
    mode: 'onChange',
    defaultValues: {
      ...(defaultTactical && {
        tactical: {
          id: defaultTactical?.agentId,
          agentName: defaultTactical?.agentName,
          vehicleId: defaultTactical?.vehicle.id,
          occurrenceId: defaultTactical.occurrenceId,
          licensePlate: defaultTactical?.vehicle.licensePlate,
          coordinates: defaultTactical?.position,
        },
      }),
    },
    resolver: joiResolver(schema),
  })

  const { addToast } = useToast()

  const { mutateCreateIntervention } = usePostIntervention({
    occurrenceId: selectedRoute.occurrence?.id,
  })

  const handleCreateIntervention = useCallback(
    (data: Omit<FormProps, 'occurrence'>) => {
      mutateCreateIntervention(
        {
          title: `Solicitação enviada ao agente tático ${data.tactical.agentName}`,
          typeName: 'STATE_CHANGE',
          note: data.comment,
          userId: userId || '',
          tags: [
            {
              name: 'deslocamentoEnviado',
            },
          ],
          attributes: {
            stateName: 'TRAVEL_SENT',
            agent: {
              id: data.tactical.id,
              name: data.tactical.agentName,
            },
            vehicle: {
              id: data.tactical.vehicleId,
              licensePlate: data.tactical.licensePlate,
            },
            agentCoordinates: data.tactical.coordinates,
          },
        },
        {
          onSuccess: () => {
            handleCloseModal()
          },
          onError: () => {
            addToast({
              message: 'Erro ao realizar intervenção',
              type: 'alert',
            })
          },
        },
      )
    },
    [
      addToast,
      handleCloseModal,
      mutateCreateIntervention,
      userId,
      selectedRoute,
    ],
  )

  const onSubmit = useCallback(
    (data: Omit<FormProps, 'occurrence'>) => {
      const currentOccurrence = data.tactical.occurrenceId

      const isOccurrenceInList = occurrences?.some(
        (occurrence) => occurrence.id === currentOccurrence,
      )

      if (stateRender === 'QUESTION') {
        handleCreateIntervention(data)
      }

      const stateDecisions: Record<Decisions, boolean> = {
        SUCCESS: !currentOccurrence,
        ERROR: !!currentOccurrence && !isOccurrenceInList,
        QUESTION: !!currentOccurrence && isOccurrenceInList,
      }

      const stateDecision = Object.keys(stateDecisions).find(
        (key) => stateDecisions[key as Decisions],
      ) as Decisions

      if (stateDecision === 'SUCCESS') {
        handleCreateIntervention(data)
      } else {
        setStateRender(stateDecision)
      }
    },
    [handleCreateIntervention, occurrences, stateRender],
  )

  const handleFilterTactical = async (
    searchFilter: string,
    offset?: number,
  ) => {
    return fetchTacticals(userId).then((response) => {
      const items = response.data.filter((data) =>
        !offset
          ? data.agentName.toLowerCase().includes(searchFilter.toLowerCase())
          : [],
      )

      return {
        data: items,
        totalElements: items.length,
      }
    })
  }

  return (
    <BackDrop onClose={handleCloseModal}>
      <Modal
        title={
          selectedRoute.tactical?.id
            ? 'Alterar agente tático'
            : 'Atribuir agente tático'
        }
        isVisible
        simple
        className={styles.container}
        onClose={handleCloseModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            {
              FORM: (
                <>
                  <FormItem>
                    <FormLabel>Tático</FormLabel>
                    <ComboBoxV3.Root
                      {...register('tactical')}
                      findOptions={handleFilterTactical}
                      valueKey="agentName"
                    >
                      {({ unSelectedOptions }) => (
                        <>
                          <ComboBoxV3.Field
                            value={watch('tactical')?.agentName}
                          />
                          <ComboBoxV3.Group>
                            {unSelectedOptions?.map((option) => (
                              <ComboBoxV3.Option
                                hasLabel
                                key={option.id}
                                aria-disabled={option.status === 'PAUSED'}
                                onClick={() => {
                                  setValue(
                                    'tactical',
                                    {
                                      id: option.agentId,
                                      agentName: option.agentName,
                                      vehicleId: option.vehicle.id,
                                      occurrenceId: option.occurrenceId,
                                      licensePlate: option.vehicle.licensePlate,
                                      coordinates: option.position,
                                    },
                                    {
                                      shouldValidate: true,
                                    },
                                  )
                                }}
                              >
                                {option.agentName}
                                <span>
                                  {option.status === 'PAUSED'
                                    ? 'Pausado'
                                    : option.occurrencesLinked?.length
                                      ? 'Ocupado'
                                      : 'Livre'}
                                </span>
                              </ComboBoxV3.Option>
                            ))}
                          </ComboBoxV3.Group>
                        </>
                      )}
                    </ComboBoxV3.Root>
                  </FormItem>

                  {selectedRoute.tactical?.id && (
                    <div>
                      <Combobox
                        {...register('observation')}
                        label={{ text: 'Observações' }}
                        items={[]}
                        onChange={(selected) =>
                          setValue('observation', selected, {
                            shouldValidate: true,
                          })
                        }
                      />
                      <textarea
                        {...register('comment')}
                        onChange={(event) =>
                          setValue('comment', event.target.value, {
                            shouldValidate: true,
                          })
                        }
                      />
                    </div>
                  )}
                  <Modal.Footer>
                    <Button
                      type="secondary"
                      buttonTitle="Cancelar"
                      onClick={handleCloseModal}
                      width="172px"
                    />
                    <Button
                      type="primary"
                      buttonTitle="Continuar"
                      htmlType="submit"
                      disabled={!isValid}
                      width="172px"
                    />
                  </Modal.Footer>
                </>
              ),
              ERROR: (
                <div>
                  <p>
                    Este agente está respondendo a uma ocorrência que não
                    pertence ao seu perfil de atendimento. Não será possível
                    designá-lo para uma nova ocorrência até que ele esteja
                    disponível.
                  </p>
                  <Modal.Footer>
                    <Button
                      type="primary"
                      onClick={() => setStateRender('FORM')}
                      buttonTitle="Voltar"
                      width="172px"
                    />
                  </Modal.Footer>
                </div>
              ),
              QUESTION: (
                <div>
                  <p>
                    Este tático já está vinculado a outra ocorrência. Tem
                    certeza de que deseja interromper o atendimento em andamento
                    e realocá-lo?
                  </p>
                  <Modal.Footer>
                    <Button
                      type="secondary"
                      buttonTitle="Cancelar"
                      onClick={() => setStateRender('FORM')}
                      width="172px"
                    />
                    <Button
                      type="primary"
                      buttonTitle="Continuar"
                      htmlType="submit"
                      disabled={!isValid}
                      width="172px"
                    />
                  </Modal.Footer>
                </div>
              ),
            }[stateRender]
          }
        </form>
      </Modal>
    </BackDrop>
  )
}
