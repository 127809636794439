enum RouteEntity {
  'Account' = 'Account',
  'Patrimony' = 'Patrimony',
  'Device' = 'Device',
  'Partition' = 'Partition',
  'Contact' = 'Contact',
  'Coverage' = 'Coverage',
  'ServiceOrder' = 'ServiceOrder',
  'Vehicle' = 'Vehicle',
}

export const DomainPath: Record<RouteEntity, string> = {
  [RouteEntity.Account]: '/account/list?page=1',
  [RouteEntity.Patrimony]: '/patrimonies?page=1',
  [RouteEntity.Device]: '/devices?page=1',
  [RouteEntity.Partition]: '/partitions?page=1',
  [RouteEntity.Contact]: '/contacts?page=1',
  [RouteEntity.Coverage]: '/so/coverages?page=1',
  [RouteEntity.ServiceOrder]: '/so/search?page=1',
  [RouteEntity.Vehicle]: '/config/vehicles?page=1',
}
