import { ReactNode } from 'react'

import joinClassNames from 'utilities/joinClassNames'
import { OccurrenceTypes } from 'services/occurrence/types'

import Tag, { TagProps } from '../Tag'

import styles from './styles.module.scss'

interface OccurrenceTagProps extends Omit<TagProps, 'children'> {
  typeName: OccurrenceTypes
  children?: ReactNode | ((label: string) => ReactNode)
}

export const occurrenceTypesLabel: Record<OccurrenceTypes, string> = {
  ALARM: 'Alarme',
  MANUAL: 'Manual',
  FAILURE: 'Falha',
  RESCUE: 'Socorro',
  FINISHED: 'Finalizado',
  IMMINENT: 'Iminência',
  EMERGENCY: 'Emergência',
  COMMERCIAL: 'Comercial',
  SCHEDULED: 'Periódica',
}

const OccurrenceTag = ({
  typeName,
  className,
  children,
  ...props
}: OccurrenceTagProps) => {
  const label = occurrenceTypesLabel[typeName]

  const handleRenderContent = () => {
    if (typeof children === 'function') {
      return children(label)
    }

    return children
  }

  return (
    <Tag
      className={joinClassNames(styles.occurrence, styles[typeName], className)}
      {...props}
    >
      {children ? handleRenderContent() : label}
    </Tag>
  )
}

export const serviceOrderStatus = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  FINISHED: 'FINISHED',
  CANCELED: 'CANCELED',
  SCHEDULED: 'SCHEDULED',
  IN_VALIDATION: 'IN_VALIDATION',
  DISAPPROVED: 'DISAPPROVED',
  RESCHEDULED: 'RESCHEDULED',
}

export type ServiceOrderStatus = keyof typeof serviceOrderStatus

export const serviceOrderStatusLabel: Record<ServiceOrderStatus, string> = {
  OPEN: 'Aberta',
  SCHEDULED: 'Agendada',
  IN_PROGRESS: 'Em execução',
  PAUSED: 'Pausada',
  IN_VALIDATION: 'Em validação',
  DISAPPROVED: 'Reprovada',
  FINISHED: 'Finalizada',
  CANCELED: 'Cancelada',
  RESCHEDULED: 'Reagendada',
}

interface SOTagProps extends TagProps {
  status: ServiceOrderStatus
}

const SOTag = ({ status, className, ...props }: SOTagProps) => {
  return (
    <Tag
      className={joinClassNames(styles.so, styles[status], className)}
      {...props}
    >
      {serviceOrderStatusLabel[status]}
    </Tag>
  )
}

export { OccurrenceTag, SOTag }
