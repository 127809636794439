import { useNavigate, useParams } from 'react-router-dom'

import { useToggle } from 'shared/hooks'
import { usePersistentTab } from 'domains/occurrence/hooks'
import { DropDown, Icon, IconButton, ModalV2 } from 'components'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import { Execute, Finish, Pause, Schedule, ValidateSO } from './components'

const Actions = () => {
  const navigate = useNavigate()
  const { serviceOrderId } = useParams()
  const { serviceOrder } = useServiceOrderDetails()

  const { replaceUrl } = usePersistentTab('service-order')

  const {
    hide: hideScheduleModal,
    show: showScheduleModal,
    isVisible: isScheduleModalOpen,
  } = useToggle()

  if (!serviceOrder) return null

  const OS_MANAGER_URL = `https://os-manager.orsegups.com.br/ubisafe/${serviceOrder.number}`

  const canEdit = !['FINISHED', 'CANCELED'].includes(serviceOrder.status)

  const canExecute = [
    'OPEN',
    'SCHEDULED',
    'RESCHEDULED',
    'PAUSED',
    'DISAPPROVED',
  ].includes(serviceOrder.status)

  const canReschedule = [
    'SCHEDULED',
    'RESCHEDULED',
    'PAUSED',
    'DISAPPROVED',
  ].includes(serviceOrder.status)

  return (
    <>
      <DropDown.Root>
        <DropDown.Trigger>
          <IconButton>
            <Icon name="menu-kebab" color="element" />
          </IconButton>
        </DropDown.Trigger>
        <DropDown.Content>
          {serviceOrder.status === 'IN_VALIDATION' && (
            <DropDown.Sub>
              <DropDown.SubTrigger>Validar</DropDown.SubTrigger>
              <DropDown.SubContent>
                <ModalV2.Root>
                  <ModalV2.Trigger>
                    <DropDown.Item>Aprovar</DropDown.Item>
                  </ModalV2.Trigger>
                  <ValidateSO status="APPROVE" />
                </ModalV2.Root>
                <ModalV2.Root>
                  <ModalV2.Trigger>
                    <DropDown.Item>Reprovar</DropDown.Item>
                  </ModalV2.Trigger>
                  <ValidateSO status="DISAPPROVE" />
                </ModalV2.Root>
              </DropDown.SubContent>
            </DropDown.Sub>
          )}

          {serviceOrder.status === 'IN_PROGRESS' && (
            <>
              <ModalV2.Root>
                <ModalV2.Trigger>
                  <DropDown.Item>Concluir</DropDown.Item>
                </ModalV2.Trigger>
                <Finish />
              </ModalV2.Root>
              <ModalV2.Root>
                <ModalV2.Trigger>
                  <DropDown.Item>Pausar</DropDown.Item>
                </ModalV2.Trigger>
                <Pause />
              </ModalV2.Root>
            </>
          )}

          {canExecute && (
            <ModalV2.Root>
              <ModalV2.Trigger>
                <DropDown.Item>Executar</DropDown.Item>
              </ModalV2.Trigger>
              <Execute />
            </ModalV2.Root>
          )}

          {serviceOrder.status === 'OPEN' && (
            <>
              <DropDown.Item onClick={showScheduleModal}>Agendar</DropDown.Item>
              {isScheduleModalOpen && <Schedule onClose={hideScheduleModal} />}
            </>
          )}

          {canReschedule && (
            <>
              <DropDown.Item onClick={showScheduleModal}>
                Reagendar
              </DropDown.Item>
              {isScheduleModalOpen && <Schedule onClose={hideScheduleModal} />}
            </>
          )}

          {canEdit && (
            <>
              <DropDown.Separator />
              <DropDown.Item
                onClick={() => navigate(`/soV2/update/${serviceOrderId}`)}
              >
                Editar
              </DropDown.Item>
              <DropDown.Separator />
            </>
          )}

          <DropDown.Item onClick={() => replaceUrl(OS_MANAGER_URL)}>
            {`Ir para "Compras/FAP"`}
          </DropDown.Item>
        </DropDown.Content>
      </DropDown.Root>
    </>
  )
}

export default Actions
