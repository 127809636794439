import {
  HistoryEvent,
  HistoryIntervention,
  InterventionTypeNames,
  OccurrenceOnAttendanceHistory,
} from 'services/attendance/types'

type HistoryItems =
  | { type: 'EVENT'; value: HistoryEvent }
  | {
      type: 'INTERVENTION' | 'STATE_CHANGE' | 'AUTOMATIC_RULE'
      value: HistoryIntervention
    }

export const handleHistoryItems = (
  history: OccurrenceOnAttendanceHistory,
): HistoryItems[] => {
  const items: HistoryItems[] = []

  history.events?.forEach((event) => {
    items.push({
      type: 'EVENT',
      value: event,
    })
  })

  history.interventions?.forEach((intervention) => {
    const automaticRules: InterventionTypeNames[] = [
      'REQUIRE_PHONE_CALL',
      'PRIORITY_CHANGE',
      'TITLE_CHANGE',
      'SEND_OCCURRENCE_CUSTOM_EVENT',
      'SEND_SOUND_NOTIFICATION',
      'TAG_INCLUSION',
    ]

    if (automaticRules.includes(intervention.typeName)) {
      return items.push({
        type: 'AUTOMATIC_RULE',
        value: intervention,
      })
    }
    if (intervention.title.includes('Mudança')) {
      return items.push({
        type: 'STATE_CHANGE',
        value: intervention,
      })
    }

    items.push({
      type: 'INTERVENTION',
      value: intervention,
    })
  })

  return items.sort((a, b) => b.value.createdAt - a.value.createdAt)
}

export const handleFilter = (
  history: OccurrenceOnAttendanceHistory,
  filter?: string,
): OccurrenceOnAttendanceHistory => {
  const eventsFiltered =
    filter &&
    history.events?.filter((item) => {
      return item.partition.id === filter
    })

  return { ...history, events: eventsFiltered || history.events }
}
