import { Intervention, InterventionNote } from 'services/serviceOrderV2'
import { Note } from './components'

interface NoteProps {
  intervention: Intervention
  notes?: InterventionNote[]
}

const Notes = ({ intervention, notes }: NoteProps) => {
  return notes?.map((note) => (
    <Note key={note.id} note={note} intervention={intervention} />
  ))
}

export default Notes
