export enum USER_STATUS {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
  ALL = 'Todos',
}

export type UserStatus = {
  value: boolean | undefined
  label: USER_STATUS
}
