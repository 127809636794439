import {
  ATTENDANCE_POLICY_STATUS,
  AttendancePolicyStatus,
  CoverageType,
  COVERAGE_TYPE,
} from '../../types'

const AttendancePolicy: Record<string, AttendancePolicyStatus> = {
  [ATTENDANCE_POLICY_STATUS.ACTIVE]: {
    label: ATTENDANCE_POLICY_STATUS.ACTIVE,
    value: true,
  },

  [ATTENDANCE_POLICY_STATUS.INACTIVE]: {
    label: ATTENDANCE_POLICY_STATUS.INACTIVE,
    value: false,
  },
}

const Coverage: Record<string, CoverageType> = {
  [COVERAGE_TYPE.ALL]: {
    label: COVERAGE_TYPE.ALL,
    value: false,
  },

  [COVERAGE_TYPE.PERSONALIZED]: {
    label: COVERAGE_TYPE.PERSONALIZED,
    value: true,
  },
}

export const handleAttendancePolicyStatus = (
  status: string,
): AttendancePolicyStatus => AttendancePolicy[status]

export const handleCoverageType = (status: string): CoverageType =>
  Coverage[status]
