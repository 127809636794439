import { useMutation } from '@tanstack/react-query'
import { ConfigureDoorCommand } from 'services/command/types'
import CommandService from 'services/command'
import { useUserInfo } from 'shared/hooks/userInfo/useUserInfo'

async function sendConfigureDoorCommand(
  payload: ConfigureDoorCommand,
  userId: string,
) {
  const { centralId, ...rest } = payload
  await CommandService.configureDoor(centralId, {
    ...rest,
    userId,
  })
}

export const usePostConfigureDoorCommand = () => {
  const { userInfo } = useUserInfo()
  const {
    mutate: mutateConfidureDoorCommand,
    status: configureDoorCommandMutationStatus,
  } = useMutation({
    mutationFn: (payload: ConfigureDoorCommand) =>
      sendConfigureDoorCommand(payload, userInfo.id),
  })

  return {
    mutateConfidureDoorCommand,
    configureDoorCommandMutationStatus,
  }
}
