import React, { useEffect, useMemo, useState } from 'react'
import { Account } from 'services/account/types'
import { Button, Input } from 'components'

import { ReactComponent as PlusIconBlue } from 'assets/svg/plusSign.svg'
import { ReactComponent as PlusIconGray } from 'assets/svg/plusSignGray.svg'
import { ReactComponent as ViewIcon } from 'assets/svg/eye.svg'
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg'

import styles from './ListAccounts.module.scss'

type ListAccountsProps = {
  onCreate: () => void
  onView: (account: Account) => void
  onEdit: (account: Account) => void
  isEnabled: boolean
  accounts?: Account[]
}

const ListAccounts: React.FC<ListAccountsProps> = ({
  accounts,
  onEdit,
  onView,
  onCreate,
  isEnabled,
}) => {
  const sortedAccounts = useMemo(
    () =>
      accounts?.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      ),
    [accounts],
  )
  const [accountsToSearch, setAccountsToSearch] = useState(sortedAccounts)

  useEffect(() => {
    setAccountsToSearch(sortedAccounts)
  }, [sortedAccounts])

  const renderButtonNewAccount = () => (
    <Button
      type="tertiary"
      onClick={onCreate}
      icon={isEnabled ? PlusIconBlue : PlusIconGray}
      disabled={!isEnabled}
      buttonTitle="Nova conta"
      className={styles.newAccountButton}
    />
  )

  return (
    <section>
      {!sortedAccounts && (
        <div className={styles.hasNotAccount}>{renderButtonNewAccount()}</div>
      )}
      {sortedAccounts && (
        <>
          <div className={styles.header}>
            <h2>Contas</h2>
            {renderButtonNewAccount()}
          </div>
          <div className={styles.container}>
            <Input
              placeholder="Digite o código da conta"
              className={styles.searchInput}
              onChange={(e) =>
                setAccountsToSearch(
                  sortedAccounts?.filter((account) =>
                    account.code
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()),
                  ),
                )
              }
            />
            <ul>
              {accountsToSearch?.map((account) => (
                <li key={account.id} className={styles.itemList}>
                  <div className={styles.title}>
                    <h3>
                      Conta {account.code} - {account.name}{' '}
                      {account && !account.active && (
                        <span className={styles.inactiveAccount}>Inativa</span>
                      )}
                    </h3>
                    <h4>{account.serviceType?.name}</h4>
                  </div>
                  <div className={styles.icons}>
                    <ViewIcon
                      className={styles.icon}
                      onClick={() => onView(account)}
                    />
                    <EditIcon
                      className={styles.icon}
                      onClick={() => onEdit(account)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </section>
  )
}

export default ListAccounts
