import React, { useRef } from 'react'

import classes from './RowActions.module.scss'
import { UbideskPermissions } from 'routes/types'
import { usePermissions, useToggle } from 'shared/hooks'
import Tooltip from 'components/Tooltip/Tooltip'

type PermissionConfig = {
  name: UbideskPermissions | undefined
  message?: string
}

export type RowAction<T> = {
  label: string
  Icon?: React.FC
  handler: (item: T) => void
  isVisible?: (item: T) => boolean
  permission?: PermissionConfig
}

interface RowActionProps<T> {
  item: T
  actions: RowAction<T>[]
  hasMoreThanOneAction?: boolean
}

export function RowAction<T>(props: RowActionProps<T>): JSX.Element {
  const { item, actions, hasMoreThanOneAction } = props
  const { hasAccess } = usePermissions()
  const buttonRef = useRef<HTMLDivElement>(null)
  const tooltip = useToggle()

  return (
    <>
      {actions.map(({ label, handler, Icon, permission }) => {
        const disabled = permission?.name ? !hasAccess(permission?.name) : false

        return (
          <div
            key={label}
            onMouseEnter={() => disabled && tooltip.show()}
            onMouseLeave={() => tooltip.hide()}
            ref={buttonRef}
          >
            <button
              disabled={disabled}
              id={label}
              data-testid={label}
              key={label}
              onClick={() => !disabled && handler(item)}
              className={[
                hasMoreThanOneAction
                  ? classes.droplistButton
                  : classes.rowSideButton,
                classes.defaultButtonStyles,
                disabled ? classes.disabled : '',
              ].join(' ')}
            >
              {Icon && <Icon />} {hasMoreThanOneAction ? label : ''}
            </button>
            {disabled && permission?.message && (
              <Tooltip
                type="informative"
                isVisible={tooltip.isVisible}
                parentRef={buttonRef}
              >
                {permission?.message}
              </Tooltip>
            )}
          </div>
        )
      })}
    </>
  )
}
