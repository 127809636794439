import { CENTRAL_MODE } from 'services/central/types'
import {
  DeviceType,
  EquipmentResponse,
  PartitionDeviceAggregatedResponse,
} from 'services/partition/types'
import { ConnectionIPMode } from 'services/serviceOrder'
import { mockUUID } from 'utilities/tests/fixtures'

export const equipmentsInitialState: EquipmentResponse = {
  central: {
    batteryLevel: 0,
    code: '',
    connected: true,
    demo: false,
    safeCall: {
      delay: 0,
      enabled: false,
      number: 3,
    },
    ethernet: {
      connectionMode: 0,
      connectionStatus: false,
      ip: '',
      ipMode: ConnectionIPMode.DHCP,
    },
    hasCommandSupport: false,
    manufacturer: {
      id: '',
      name: '',
    },
    model: {
      id: '',
      name: '',
    },
    gprs: {
      connectionStatus: false,
      iccid: '',
      signal: 0,
      telecom: '',
    },
    id: '',
    installationImageId: '',
    lastConnectionTimestamp: 0,
    mode: CENTRAL_MODE.DEFAULT,
    powerStatus: 0,
    tamper: false,
    versions: {
      centralCore: '',
      firmware: '',
      software: '',
    },
    wifi: {
      ip: '',
      ipMode: ConnectionIPMode.DHCP,
      connectionStatus: false,
      signal: 0,
      ssid: '',
    },
  },
  devices: [],
}

export const deviceInitialState: PartitionDeviceAggregatedResponse = {
  code: '000',
  status: false,
  installationImageId: '',
  typeCode: DeviceType.Central,
  id: '',
  name: '',
  partitions: [],
  hiddenZone: false,
  isTamperOpened: false,
  lastConnectionTimestamp: 0,
  sirenVolume: 0,
  typeId: mockUUID,
  accelerometerEnabled: true,
  hasCommandSupport: false,
  contact: {
    id: '',
    name: '',
  },
  model: {
    id: '',
    name: '',
  },
  communication: {
    level: 0,
    status: false,
    rssi: 10,
  },
  battery: {
    isLow: false,
    level: 0,
    voltage: 50,
  },
  profiles: {
    camera: undefined,
    pir: undefined,
    shock: undefined,
  },
  versions: {
    hardware: '',
    firmware: '',
  },
}
