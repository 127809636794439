import { useQuery } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { ServiceOrderAggregatedResponse } from './types'

export const useGetServiceOrderDetails = (id = '') =>
  useQuery({
    queryKey: ['get-service-order-details', id],
    queryFn: async () => {
      const response = await HttpClient.get<ServiceOrderAggregatedResponse>(
        `serviceOrder/find/${id}`,
      )

      return response.data
    },
    enabled: !!id,
  })
