import { InterventionTag } from 'domains/occurrence/data/interventionTag'

import {
  InterventionAttributeStatus,
  InterventionAttributes,
  Intervention,
  OccurrenceInterventionAttributes,
  InterventionStateEnum,
  InterventionTypeEnum,
} from 'services/occurrence/types'

export const getInterventionStatusName = (
  status: InterventionAttributeStatus,
): string => {
  switch (status) {
    case InterventionAttributeStatus.SUCCESS:
      return 'Sucesso'
    case InterventionAttributeStatus.FAILURE:
      return 'Falha'
    default:
      return ''
  }
}

export const getInterventionStateName = (
  state: InterventionStateEnum,
): string => {
  switch (state) {
    case InterventionStateEnum.AVAILABLE:
      return 'Disponível'
    case InterventionStateEnum.FINISHED:
      return 'Finalizado'
    case InterventionStateEnum.HIDDEN:
      return 'Oculta'
    case InterventionStateEnum.IN_PROGRESS:
      return 'Em atendimento'
    case InterventionStateEnum.QUEUED:
      return 'Em observação'
    case InterventionStateEnum.TRAVEL_WAITING:
      return 'Deslocamento'
    case InterventionStateEnum.TRAVEL_SENT:
      return 'Enviado'
    case InterventionStateEnum.TRAVEL_STARTED:
      return 'Iniciado'
    case InterventionStateEnum.TRAVEL_VIEWED:
      return 'Visualizado'
    case InterventionStateEnum.TRAVEL_FINISHED:
      return 'No local'

    default:
      return ''
  }
}

export function getInterventionTitle(
  interventionAttributes: InterventionAttributes,
  tag: InterventionTag,
): string {
  switch (interventionAttributes.typeName) {
    case InterventionTypeEnum.PHONE_CALL:
      if (tag === InterventionTag.LIGACAO_CONTATO) {
        return `Ligação para contato ${
          interventionAttributes.attributes.contactName || ''
        }`
      } else if (tag === InterventionTag.LIGACAO_POLICIA) {
        return 'Ligação para a polícia'
      } else {
        return 'Ligação para módulo'
      }
    case InterventionTypeEnum.SEND_MESSAGE:
      return `Mensagem de WhatsApp para ${
        interventionAttributes.attributes.contactName || ''
      }`
    case InterventionTypeEnum.IMAGE_VERIFICATION:
      return 'Verificação de imagem'
    case InterventionTypeEnum.CONFIRM_INTRUSION:
      return 'Intrusão confirmada'
    case InterventionTypeEnum.MANUAL_RECORD:
      return `Registro manual${
        interventionAttributes.attributes.hidden ? ' oculto' : ''
      }`
    case InterventionTypeEnum.STATE_CHANGED:
      if (
        interventionAttributes.attributes.stateName ===
        InterventionStateEnum.FINISHED
      ) {
        return 'Finalização de ocorrência'
      }

      return `Mudança de status para "${getInterventionStateName(
        interventionAttributes.attributes.stateName,
      )}"`
    case InterventionTypeEnum.SEND_IMAGE:
      return 'Fotos do local adicionadas remotamente'
    default:
      return ''
  }
}

export function clearInterventionAttributes(
  interventionAttributes: OccurrenceInterventionAttributes,
): OccurrenceInterventionAttributes {
  const attributesWithoutNull: OccurrenceInterventionAttributes =
    {} as OccurrenceInterventionAttributes

  if (interventionAttributes) {
    Object.keys(interventionAttributes).forEach((key) => {
      type Key = keyof OccurrenceInterventionAttributes

      if (interventionAttributes[key as Key]) {
        attributesWithoutNull[key as Key] = interventionAttributes[key as Key]
      }
    })
  }

  return attributesWithoutNull
}

export function isInterventionEditable(intervention: Intervention): boolean {
  return (
    intervention.typeName === InterventionTypeEnum.PHONE_CALL ||
    intervention.typeName === InterventionTypeEnum.IMAGE_VERIFICATION ||
    intervention.typeName === InterventionTypeEnum.SEND_MESSAGE
  )
}
