import styles from './PolicyVisualizationCard.module.scss'
import { Icon } from 'components'
import {
  PolicyDetail,
  Policy,
  PolicyGroup,
} from 'domains/attendancePolicy/types'
import { ReactNode } from 'react'

type PolicyVisualizationCardProps = {
  isSelected?: boolean
  icon: ReactNode
  title: string
  subtitle: string
  policies?: Policy | PolicyGroup[]
  allPolicies?: boolean
  onEdit?: (automationType: string) => void
}

export const PolicyVisualizationCard = ({
  icon,
  title,
  subtitle,
  policies,
  isSelected,
  allPolicies,
  onEdit,
}: PolicyVisualizationCardProps) => {
  let cardClass = styles.default

  if (policies) {
    cardClass = styles.saved
  } else if (isSelected) {
    cardClass = styles.selected
  }

  return (
    <div className={styles.container}>
      <button
        className={styles.cardButton}
        onClick={() => onEdit?.(title)}
        type="button"
      >
        <div
          className={`${cardClass} ${styles.contentWrapper}`}
          aria-label="card-automação"
        >
          <div className={styles.icon}>{icon}</div>
          <div className={styles.textContainer}>
            <h1>{title}</h1>
            <div className={styles.defaultSubtitle}>
              {!isSelected && !policies && title === 'Condição'
                ? '(Opcional)'
                : ''}
              {isSelected && !policies
                ? 'Selecione uma das opções ao lado'
                : ''}
            </div>

            {policies ? (
              <>
                <p className={styles.subtitle}>{subtitle}</p>
                {Array.isArray(policies) ? (
                  policies.map((policyGroup: PolicyGroup, index) => (
                    <div
                      key={index}
                      className={styles.group}
                      data-text={allPolicies ? 'E' : 'Ou'}
                      data-testid="policy-group"
                    >
                      <h2 className={styles.policyTitle}>
                        Grupo de
                        {title === 'Condição'
                          ? ` condições ${index + 1}`
                          : ` ações ${index + 1}`}
                      </h2>

                      {policyGroup.policies.map((policy: Policy, index) => (
                        <div
                          data-text={policyGroup.allSubPolicies ? 'E' : 'Ou'}
                          data-testid="grouped-policies"
                          className={styles.grouped}
                          key={index}
                        >
                          <h2 className={styles.groupedPolicyTitle}>
                            {policy.title}
                          </h2>
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <div>
                    <h2 className={styles.policyTitle}>{policies.title}</h2>
                    {policies.subtitle && (
                      <p className={styles.policySubtitle}>
                        {policies.subtitle}
                      </p>
                    )}
                    {policies.details?.map((detail: PolicyDetail, index) => (
                      <div className={styles.bulletTitle} key={index}>
                        <h3>{detail.title}</h3>
                        <p className={styles.detailSubtitle}>
                          {detail.description}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : null}
          </div>

          {policies && (
            <div className={styles.editIcon}>
              <Icon name="edit" data-testid="edit-icon" />
            </div>
          )}
        </div>
      </button>
    </div>
  )
}
