import { useMemo, useState } from 'react'
import { EventInput } from '@fullcalendar/core'

import { ButtonV2 as Button, Calendar, ModalV2 } from 'components'
import { WeekInterval } from 'components/Calendar/types'

import styles from './styles.module.scss'
import { handleRenderState } from 'utilities/handleRenderState'
import Loading from './Loading'
import { useGetServiceOrders } from 'shared/hooks/serviceOrder/useGetServiceOrders'
import { EmptyState, ErrorState } from './components'

export interface TechniciansScheduleProps {
  technician: {
    id: string
    name: string
  }
}

const TechniciansSchedule = ({ technician }: TechniciansScheduleProps) => {
  const [calendarWeekInterval, setCalendarWeekInterval] =
    useState<WeekInterval>()

  const {
    data: serviceOrders,
    isFetching,
    isError,
  } = useGetServiceOrders(
    {
      technicianIds: [technician.id],
      scheduleDateFrom: calendarWeekInterval?.startDate,
      scheduleDateTo: calendarWeekInterval?.endDate,
      status: [
        'SCHEDULED',
        'RESCHEDULED',
        'FINISHED',
        'IN_PROGRESS',
        'IN_VALIDATION',
        'DISAPPROVED',
        'PAUSED',
        'OPEN',
      ],
    },
    !!technician?.id,
  )

  const currentServiceOrders: EventInput[] = useMemo(
    () =>
      serviceOrders?.data.map((serviceOrder) => ({
        id: serviceOrder?.id,
        title: serviceOrder.customer?.name || '',
        tags: serviceOrder.tags,
        allDay: !!serviceOrder?.schedulePeriod,
        start: new Date(serviceOrder?.scheduleDate),
        end:
          !serviceOrder?.schedulePeriod && serviceOrder?.scheduleDate
            ? new Date(serviceOrder?.scheduleDate).setHours(
                new Date(serviceOrder?.scheduleDate).getHours() + 2,
              )
            : '',
        extendedProps: {
          schedulePeriod: serviceOrder?.schedulePeriod ?? undefined,
          status: serviceOrder?.status,
          number: serviceOrder?.number,
          accountCode: serviceOrder?.account?.code,
          customer: serviceOrder?.customer?.name,
        },
      })) || [],
    [serviceOrders],
  )

  return (
    <>
      <ModalV2.Title>{technician.name}</ModalV2.Title>
      {
        {
          view: (
            <div className={styles.container}>
              <div className={styles.content}>
                <Calendar
                  height="calc(100vh - 288px)"
                  events={currentServiceOrders}
                  onChangeWeekInterval={(newInterval) => {
                    if (
                      calendarWeekInterval?.startDate !==
                        newInterval.startDate ||
                      calendarWeekInterval?.endDate !== newInterval.endDate
                    ) {
                      setCalendarWeekInterval(newInterval)
                    }
                  }}
                />
              </div>
            </div>
          ),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyState />,
        }[
          handleRenderState({
            isError,
            isLoading: isFetching,
            data: serviceOrders?.data,
            isEmpty: !serviceOrders?.data,
          })
        ]
      }

      <ModalV2.Footer className={styles.footer}>
        <ModalV2.Close>
          <Button size="sm" appearance="primary">
            Fechar
          </Button>
        </ModalV2.Close>
      </ModalV2.Footer>
    </>
  )
}

export default TechniciansSchedule
