import React from 'react'

import { ActionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'

import { Input } from 'components'

import styles from './OccurrenceTitle.module.scss'

export const OccurrenceTitle: React.FC<ActionOptionForm> = ({
  onChange,
  data,
}) => {
  const { index, parameters } = data

  return (
    <div className={styles.container}>
      <Input
        key={index}
        type="text"
        value={parameters?.occurrenceTitle || ''}
        maxLength={45}
        onChange={(input) => {
          onChange({
            index,
            parameters: {
              occurrenceTitle: input.target.value,
            },
          })
        }}
        id="occurrence-title"
        name="occurrence-title"
        label="Alterar título identificador para"
        placeholder="Digite um título"
      />
    </div>
  )
}
