import { useEffect, useMemo } from 'react'

import {
  Button,
  Combobox,
  ComboboxItem,
  Input,
  Loader,
  Modal,
  ProtectedButton,
} from 'components'

import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  UpdateDeviceSchema,
  updateDeviceSchema,
} from '../../schemas/devices/updateDevice'

import styles from './DeviceUpdate.module.scss'
import { usePatchAndHandleUpdateDevice } from 'domains/customer/screens/Equipments/hooks/services/device'
import { useGetPatrimonyPartitions } from 'domains/customer/screens/Equipments/hooks/services/patrimony'
import { Result } from 'services/types'
import { parseDataToCombobox } from 'utilities/combobox'
import {
  CentralFragment,
  Device,
  PartitionFragment,
} from 'domains/customer/screens/Equipments/types'
import { UbideskPermissions } from 'routes/types'
import { CENTRAL_MODE } from 'services/central/types'

type DeviceUpdateProps = {
  device: Device
  central: CentralFragment
  isVisible: boolean
  onClose: () => void
}

export const DeviceUpdate = ({
  device,
  central,
  isVisible,
  onClose,
}: DeviceUpdateProps) => {
  const form = useForm<UpdateDeviceSchema>({
    mode: 'onChange',
    resolver: joiResolver(updateDeviceSchema),
    defaultValues: {
      id: device.id,
      centralId: central.id,
    },
  })

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors, isDirty, isValid },
    watch,
  } = form

  const { isFetchingPatrimonyPartitions, patrimonyPartitions } =
    useGetPatrimonyPartitions(isVisible)

  const { mutateUpdateDevice, updateDeviceMutationStatus } =
    usePatchAndHandleUpdateDevice()

  function hasDeviceChangedPartitions(
    form: UpdateDeviceSchema,
    device: Device,
  ) {
    const formPartitionsIds = form.partitions.map((partition) => partition.id)
    const devicePartitionsIds = device.partitions.map(
      (partition) => partition.id,
    )

    const partitionsDiff = formPartitionsIds
      .filter((id) => !devicePartitionsIds.includes(id))
      .concat(devicePartitionsIds.filter((x) => !formPartitionsIds.includes(x)))

    return Boolean(partitionsDiff.length)
  }

  const onSubmit = (form: UpdateDeviceSchema) => {
    const hasPartitionsDiff = hasDeviceChangedPartitions(form, device)

    mutateUpdateDevice(
      {
        centralId: form.centralId,
        device: {
          id: form.id,
          ...(form.name && { name: form.name }),
          ...(hasPartitionsDiff && {
            partitions: form.partitions.map((partition) => partition.id),
          }),
        },
      },
      {
        onSuccess: () => onClose(),
      },
    )
  }

  const handleGetSelectedPartition = (
    selected:
      | string
      | ComboboxItem<PartitionFragment>
      | (string | ComboboxItem<PartitionFragment>)[]
      | undefined,
  ) => {
    const partitions = selected as ComboboxItem<PartitionFragment>[]

    setValue(
      'partitions',
      partitions.map((partition) => partition.value),
      { shouldDirty: true, shouldValidate: true },
    )
  }

  const comboboxPatrimonyPartitions = useMemo(() => {
    let response: Result<PartitionFragment> = {
      data: [],
      totalElements: 0,
    }

    if (patrimonyPartitions) {
      const partitionsFragment = patrimonyPartitions.data.map((partition) => ({
        id: partition.id,
        name: partition.name,
      }))
      response = {
        data: partitionsFragment,
        totalElements: patrimonyPartitions.totalElements,
      }
    }

    const parsed = parseDataToCombobox(response, 'name')
    return parsed.data
  }, [patrimonyPartitions])

  const formPartitions = watch('partitions')

  const comboboxPatrimonyPartitionsInitialValue = useMemo(() => {
    if (!formPartitions) return undefined

    const initialValue: ComboboxItem<PartitionFragment>[] = formPartitions.map(
      (partition) => ({
        label: 'name',
        value: partition,
      }),
    )

    return initialValue
  }, [formPartitions])

  useEffect(() => {
    register('centralId', { setValueAs: () => central.id })
    register('partitions')
  }, [central.id, device.id, device.partitions, register])

  useEffect(() => {
    setValue('partitions', device.partitions)
  }, [device.partitions, setValue])

  if (!isVisible) return <div />

  return (
    <div>
      <Loader
        isVisible={
          isFetchingPatrimonyPartitions ||
          updateDeviceMutationStatus === 'pending'
        }
      />
      <Modal title="Editar equipamento" isVisible={isVisible} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.wrapper}>
            {device.name && (
              <Input
                id="name"
                {...register('name')}
                label="Nome"
                defaultValue={device.name}
                placeholder="Digite o novo nome do dispositivo"
                errorMessage={errors.name?.message}
              />
            )}
            <Combobox
              label={'Partição'}
              items={comboboxPatrimonyPartitions}
              value={comboboxPatrimonyPartitionsInitialValue}
              getSelected={handleGetSelectedPartition}
              multiple
              disabled={central.mode !== CENTRAL_MODE.MAINTENANCE}
              errorMessage={
                errors.partitions
                  ? 'Obrigatório haver ao menos uma partição'
                  : ''
              }
            />
          </div>
        </form>
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Voltar"
            type="secondary"
            onClick={onClose}
          />
          <ProtectedButton
            permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
