import { useMemo } from 'react'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { AuthDriver, TagQueryResponse } from 'services/auth'
import {
  flattenPages,
  getNextPageOffSet,
} from '../services/utilities/pagination'

type UseGetTagsReturn = {
  isError: boolean
  isFetching: boolean
  tags: TagQueryResponse[]
  fetchNextTagsPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const getTags = async (offset = 0, filter?: string) => {
  const response = await AuthDriver.queryTags({
    recordsPerPage: 15,
    offset,
    ...(filter && { name: filter }),
  })
  return response
}

export function useGetTags(filter: string): UseGetTagsReturn {
  const {
    isError,
    isFetching,
    data,
    fetchNextPage: fetchNextTagsPage,
  } = useInfiniteQuery({
    queryKey: ['tags', filter],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => getTags(pageParam, filter),

    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<TagQueryResponse>(lastPage, allPages),
  })

  const tags = useMemo(() => flattenPages<TagQueryResponse>(data), [data])

  return {
    isError,
    isFetching,
    tags,
    fetchNextTagsPage,
  }
}
