import { useMutation, useQueryClient } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'

interface DeleteIntervention {
  serviceOrderId?: string
  interventionId?: string
}

export const deleteIntervention = async ({
  serviceOrderId,
  interventionId,
}: DeleteIntervention) => {
  const response = await HttpClient.delete(
    buildUrl({
      route: endpoints.deleteIntervention,
      params: { serviceOrderId, interventionId },
    }),
  )

  return response.data
}

export const useDeleteIntervention = (
  serviceOrderId?: string,
  interventionId?: string,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (_nothing: null) =>
      await deleteIntervention({ serviceOrderId, interventionId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['service-order-intervention', serviceOrderId],
      })
    },
  })
}
