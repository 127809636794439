import { FC, useRef, useState } from 'react'

import { ReactComponent as MenuIcon } from 'assets/svg/menuIcon.svg'

import { Icon, Tooltip } from 'components'

import { Account } from './../../types'
import styles from './styles.module.scss'

interface ContactItem {
  account: Account
  onAdmin: (account: string, value: boolean) => void
  onRemove: (account: string) => void
}

const ContactItem: FC<ContactItem> = ({ account, onAdmin, onRemove }) => {
  const [visible, setVisible] = useState(false)

  const disable = account.hasControl || account.actionable

  const [tooltip, setTooltip] = useState('')
  const tooltipRef = useRef<HTMLDivElement>(null)

  return (
    <li className={styles.container}>
      <div>
        <span>{account.aggregatedAccountName}</span>
        <span>{account.serviceType}</span>
      </div>
      <div className={styles.iconsBox} ref={tooltipRef}>
        <Tooltip
          type="informative"
          parentRef={tooltipRef}
          isVisible={!!tooltip}
        >
          {tooltip}
        </Tooltip>
        {account.admin && (
          <Icon
            name="admin-user"
            width={10}
            height={10}
            onMouseEnter={() => setTooltip('Contato administrador')}
            onMouseLeave={() => setTooltip('')}
          />
        )}
        {account.hasControl && (
          <Icon
            name="control"
            width={10}
            height={10}
            onMouseEnter={() => setTooltip('Usuário com controle')}
            onMouseLeave={() => setTooltip('')}
          />
        )}
        {account.actionable && (
          <Icon
            name="action-plan-on"
            width={10}
            height={10}
            onMouseEnter={() => setTooltip('Contato do plano de ação')}
            onMouseLeave={() => setTooltip('')}
          />
        )}
      </div>
      <div>
        <button type="button" onClick={() => setVisible(true)}>
          <MenuIcon />
        </button>
        {visible && (
          <ul onMouseLeave={() => setVisible(false)}>
            <li
              className={[disable && styles.isDisabled].join(' ')}
              onClick={() => {
                if (!disable) {
                  onRemove(account.id)
                  setVisible(false)
                }
              }}
            >
              Desvincular desta conta
            </li>
            <li
              onClick={() => {
                onAdmin(account.id, !account.admin)
                setVisible(false)
              }}
            >
              {account.admin ? 'Remover administrador' : 'Tornar administrador'}
            </li>
          </ul>
        )}
      </div>
    </li>
  )
}

export default ContactItem
