import { Button, Loader, Modal } from 'components'

import { useDeleteDevice } from 'shared/hooks/services/device/useDeleteDevice'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'shared/hooks'
import { useNavigate } from 'react-router-dom'

type DeviceUpdateProps = {
  deviceId: string
  isVisible: boolean
  onClose: () => void
}

export const DeleteDevice = ({
  deviceId,
  isVisible,
  onClose,
}: DeviceUpdateProps) => {
  const queryClient = useQueryClient()
  const { addToast } = useToast()
  const navigate = useNavigate()

  const { mutate, status } = useDeleteDevice()

  const onSubmit = () => {
    mutate(deviceId, {
      onSuccess: () => {
        addToast({
          message: 'Equipamento excluído com sucesso.',
          type: 'success',
        })
        queryClient.invalidateQueries({ queryKey: ['equipments'] })
        onClose()
        navigate({ search: '' })
      },
      onError: () => {
        addToast({
          message: 'Erro ao excluir equipamento. Tente novamente.',
          type: 'alert',
        })
        onClose()
      },
    })
  }

  if (!isVisible) return <div />

  return (
    <div>
      <Loader isVisible={status === 'pending'} />
      <Modal
        simple
        title="Excluir equipamento"
        isVisible={isVisible}
        onClose={onClose}
      >
        Você tem certeza de que deseja excluir este equipamento? Todas as
        informações serão perdidas.
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Cancelar"
            type="secondary"
            onClick={onClose}
          />
          <Button
            width="172px"
            buttonTitle="Excluir"
            type="cancel-primary"
            onClick={onSubmit}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
