import { joiResolver } from '@hookform/resolvers/joi'
import { useForm } from 'react-hook-form'

import { PartitionFragmentPayload } from 'services/central/types'
import { partitionSchema } from 'domains/customer/schemas'
import styles from './AddPartitionModal.module.scss'
import { Button, Input, Modal } from 'components'

type AddPartitionModalProps = {
  onSave: (partition: PartitionFragmentPayload) => void
  partition?: PartitionFragmentPayload
  hasCommandSupport?: boolean
  onClose: () => void
}

export const AddPartitionModal = ({
  hasCommandSupport,
  partition,
  onClose,
  onSave,
}: AddPartitionModalProps) => {
  const { register, handleSubmit, setValue, watch } = useForm({
    mode: 'onChange',
    resolver: joiResolver(partitionSchema),
    defaultValues: {
      id: partition?.id,
      code: partition?.code,
      name: partition?.name,
      keyNumber: partition?.keyNumber,
      status: partition?.status || 'D',
      externalId: partition?.externalId,
    },
  })

  return (
    <Modal
      isVisible
      onClose={onClose}
      title={partition ? 'Editar partição' : 'Nova partição'}
    >
      <form className={styles.form} onSubmit={handleSubmit(onSave)}>
        <Input
          label="Código"
          {...register('code')}
          value={watch('code')}
          onChange={(e) => setValue('code', e.target.value)}
          disabled={hasCommandSupport}
        />
        <Input
          label="Nome"
          {...register('name')}
          value={watch('name')}
          onChange={(e) => setValue('name', e.target.value)}
        />
        <Input
          label="Chave (opcional)"
          {...register('keyNumber')}
          value={watch('keyNumber')}
          onChange={(e) => setValue('keyNumber', e.target.value)}
        />
        <Input
          label="ID externo"
          {...register('externalId')}
          value={watch('externalId')}
          onChange={(e) => setValue('externalId', e.target.value)}
        />
        <Modal.Footer>
          <Button
            width="172px"
            type="secondary"
            onClick={onClose}
            buttonTitle="Cancelar"
          />
          <Button
            width="172px"
            type="primary"
            htmlType="submit"
            buttonTitle={partition ? 'Confirmar' : 'Adicionar'}
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}
