import { PartitionStatus } from 'services/attendancePolicy/types'

export const partitionQuantity = {
  ALL: 'ALL',
  SOME: 'SOME',
}

export type PartitionQuantity = keyof typeof partitionQuantity

export type PartitionQuantityLabel = 'Todas' | 'Ao menos uma'

export const translatedPartitionQuantityLabel: Record<
  PartitionQuantity,
  PartitionQuantityLabel
> = {
  ALL: 'Todas',
  SOME: 'Ao menos uma',
}

export const convertPartitionCondition: Record<
  PartitionQuantityLabel,
  boolean
> = {
  Todas: true,
  'Ao menos uma': false,
}

export const convertPartitionStatus: Record<string, PartitionStatus> = {
  'Armada(s)': 'ARMED',
  'Desarmada(s)': 'DISARMED',
}

export const partitionStatusLabel: Record<'ARMED' | 'DISARMED', string> = {
  ARMED: 'Armada(s)',
  DISARMED: 'Desarmada(s)',
}

export const handleAllPartitionArmed = (
  option: boolean | undefined,
): string => {
  if (option === undefined) {
    return ''
  }

  return option ? 'Todas' : 'Ao menos uma'
}
