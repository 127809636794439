import { Result } from 'services/types'
import HttpClient from '../httpClient'
import {
  AggregatedOfficeHours,
  OfficeHoursPayload,
  OfficeHoursQuery,
  OfficeHoursWithAggregations,
} from 'services/officeHours/types'
import { AxiosInstance } from 'axios'

export interface OfficeHoursDriver {
  query(query: OfficeHoursQuery): Promise<Result<OfficeHoursWithAggregations>>
  delete(id: string): Promise<void>
  find(id: string): Promise<AggregatedOfficeHours>
  update(id: string, payload: OfficeHoursPayload): Promise<void>
  create(payload: OfficeHoursPayload): Promise<void>
}

class OfficeHoursDriverImpl implements OfficeHoursDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async create(payload: OfficeHoursPayload): Promise<void> {
    return this.httpClient.post(`/officeHours/create`, payload)
  }

  public async update(id: string, payload: OfficeHoursPayload): Promise<void> {
    return this.httpClient.put(`/officeHours/${id}`, payload)
  }

  public async find(id: string): Promise<AggregatedOfficeHours> {
    const response = await this.httpClient.get<AggregatedOfficeHours>(
      `/officeHours/${id}/find`,
    )
    return response.data
  }

  public async query(
    query: Partial<OfficeHoursQuery>,
  ): Promise<Result<OfficeHoursWithAggregations>> {
    const response = await this.httpClient.get<
      Result<OfficeHoursWithAggregations>
    >('/officeHours/query', {
      params: query,
    })

    return response.data
  }

  public async delete(id: string): Promise<void> {
    await this.httpClient.delete(`/officeHours/${id}`)
  }
}

export const OfficeHoursDriver = new OfficeHoursDriverImpl()
