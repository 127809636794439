import {
  AccordionAction,
  ActionOptionForm,
} from 'domains/attendancePolicy/components/RuleForm/types'

import { useFormContext } from 'react-hook-form'

import { ActionType } from 'services/attendancePolicy/types'
import { ReactComponent as ErrorIcon } from '../../../assets/svg/error.svg'

import styles from './Payload.module.scss'

export const Payload = ({ onChange, data }: ActionOptionForm) => {
  const { parameters } = data

  const {
    formState: { errors },
  } = useFormContext<{ actions: AccordionAction[] }>()

  const isError =
    errors?.actions?.[data.index - 1]?.parameters?.stringifiedPayload

  return (
    <div className={styles.container}>
      <label>Payload</label>
      <textarea
        spellCheck={false}
        defaultValue={parameters?.stringifiedPayload}
        onChange={(event) => {
          const value = event.target.value

          onChange({
            type: ActionType.SEND_OCCURRENCE_CUSTOM_EVENT,
            parameters: {
              stringifiedPayload: value,
            },
          })
        }}
        className={[styles.textArea, isError && styles.errorTextArea].join(' ')}
      />
      {isError && (
        <div className={styles.errorHint}>
          <ErrorIcon />
          <small>Formato JSON inválido</small>
        </div>
      )}
    </div>
  )
}
