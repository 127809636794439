import {
  Children,
  ReactElement,
  ReactNode,
  isValidElement,
  useEffect,
  useState,
} from 'react'

import classes from './Wizard.module.scss'

interface WizardStepProps {
  title: string
  children: ReactNode
}

function WizardStep({ children }: WizardStepProps): JSX.Element {
  return <>{children}</>
}

interface Step {
  title: string
  component: ReactNode
}

interface WizardProps {
  activeStepIndex: number
  wizardBackground?: string
  children: ReactNode
}

export function Wizard({
  activeStepIndex,
  wizardBackground = '#fff',
  children,
}: WizardProps): JSX.Element {
  const [steps, setSteps] = useState<Step[]>([])

  useEffect(() => {
    const currentSteps = (
      Children.toArray(children).filter((element) =>
        isValidElement(element),
      ) as ReactElement<WizardStepProps>[]
    ).map(({ props }, index) => ({
      title: `${index + 1}. ${props.title}`,
      component: props.children,
    }))

    setSteps(currentSteps)
  }, [children])

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--wizard-background',
      wizardBackground,
    )
  }, [wizardBackground])

  return (
    <>
      <div className={classes.header}>
        {steps.map((step, index) => (
          <div
            key={step.title}
            className={[
              classes.step,
              index === activeStepIndex && classes.selectedStep,
            ].join(' ')}
            data-testid="wizard-step"
          >
            <span className={classes.title} data-testid="wizard-step-title">
              {step.title}
            </span>
          </div>
        ))}
      </div>

      <div className={classes.body}>
        {steps.map((step, index) => {
          return (
            <div
              key={step.title}
              className={index !== activeStepIndex ? classes.hidden : ''}
            >
              {step.component}
            </div>
          )
        })}
      </div>
    </>
  )
}

Wizard.Step = WizardStep
