import { Button, Modal } from 'components'

type InfoModalProps = {
  isVisible: boolean
  title: string
  text: string
  onClose: () => void
}

export const InfoModal = ({
  isVisible,
  title,
  text,
  onClose,
}: InfoModalProps) => {
  return (
    <Modal isVisible={isVisible} onClose={onClose} title={title} simple>
      <div>
        <p>{text}</p>
      </div>
      <Modal.Footer>
        <Button
          buttonTitle="Fechar"
          onClick={onClose}
          type="secondary"
          width="172px"
        />
      </Modal.Footer>
    </Modal>
  )
}
