import React, { useEffect } from 'react'

import { useModal } from 'domains/occurrence/screens/Attendance/context'

import { useLocation, useNavigate } from 'react-router-dom'
import { EventHistory } from 'domains/customer/screens/History/components/EventHistory/EventHistory'
import { ServiceOrderHistory } from 'domains/customer/screens/History/components/ServiceOrderHistory/ServiceOrderHistory'
import { Occurrence } from 'domains/customer/screens/History/components/Occurrence/Occurrence'
import { AccountHistoryData } from 'domains/occurrence/screens/Attendance/types'
import { Modal } from 'components'
import { TabData } from 'components/Tabs/types'
import { Tabs } from 'components/Tabs'

import styles from './styles.module.scss'

const AccountHistory: React.FC<AccountHistoryData> = ({ accountId }) => {
  const tabs: TabData[] = [
    {
      id: 'event',
      label: 'Eventos',
      content: (
        <div className={styles.container}>
          <EventHistory filteredAccount={accountId} openInNewTab />
        </div>
      ),
      path: '?page=1&history-type=Eventos',
    },
    {
      id: 'occurrence',
      label: 'Ocorrências',
      content: (
        <div className={styles.container}>
          <Occurrence filteredAccount={accountId} openInNewTab />
        </div>
      ),
      path: '?page=1&history-type=Ocorrências',
    },
    {
      id: 'os',
      label: 'Ordens de serviço',
      content: (
        <div className={styles.container}>
          <ServiceOrderHistory filteredAccounts={[accountId]} openInNewTab />
        </div>
      ),
      path: '?page=1&history-type=Ordens de serviço',
    },
  ]

  const { handleCloseModal } = useModal()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    if (!searchParams.get('page')) {
      searchParams.set('page', '1')
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        state: location.state,
        replace: true,
      })
    }
  }, [location, navigate])

  return (
    <Modal isVisible size="xl" title="Histórico" onClose={handleCloseModal}>
      <div className={styles.mainConatiner}>
        <Tabs tabs={tabs} />
      </div>
    </Modal>
  )
}

export default AccountHistory
