import { useQuery } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { ServiceOrder } from './types'

export const useGetSO = (serviceOrderId?: string) => {
  return useQuery({
    queryKey: ['service-order', serviceOrderId],
    queryFn: async () => {
      const response = await HttpClient.get<ServiceOrder>(
        buildUrl({ route: endpoints.getSO, params: { serviceOrderId } }),
      )

      return response.data
    },
    enabled: !!serviceOrderId,
  })
}
