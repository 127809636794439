import React, { useCallback } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'
import { useToast, useUserInfo } from 'shared/hooks'
import { ButtonV2, ModalV2, Textarea } from 'components'
import { useGetAgentDetails, usePostIntervention } from 'services/attendance'
import {
  InterventionJustificationData,
  RouteParams,
} from 'domains/occurrence/screens/Attendance/types'
import { DisplacementStep } from 'services/attendance/types'
import { useModal } from 'domains/occurrence/screens/Attendance/context'
import { FormattedInterventionTag } from 'domains/occurrence/data/interventionTag'
import styles from './styles.module.scss'

interface FormProps {
  note: string
}

const handleTitle = (step: DisplacementStep, agentName: string) => {
  const steps: Record<DisplacementStep, string> = {
    TRAVEL_FINISHED: 'Chegada no local',
    QUEUED: 'Mudança de status para Observação',
    AVAILABLE: 'Mudança de status para Disponível',
    TRAVEL_WAITING: 'Mudança de status para Deslocamento',
    TRAVEL_STARTED: `Agente tático ${agentName} em trânsito`,
    TRAVEL_VIEWED: `Solicitação visualizada pelo agente tático ${agentName}`,
  }

  return steps[step]
}

export const schema = Joi.object<FormProps>({
  note: Joi.string().required(),
})

const InterventionJustification: React.FC<InterventionJustificationData> = ({
  step,
}) => {
  const { occurrenceId } = useParams<RouteParams>()
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormProps>({
    resolver: joiResolver(schema),
  })

  const { addToast } = useToast()
  const { handleCloseModal } = useModal()
  const { userInfo } = useUserInfo()
  const { mutate, isPending: isLoading } = usePostIntervention(occurrenceId)
  const { data: agent } = useGetAgentDetails(occurrenceId)

  const onSubmit = useCallback(
    ({ note }: FormProps) => {
      const userId = userInfo.id

      if (userId) {
        const currentAgent = agent?.[0]

        mutate(
          {
            userId,
            title: handleTitle(step, currentAgent?.agentName || ''),
            tags: [{ name: FormattedInterventionTag[step] }],
            typeName: 'STATE_CHANGE',
            attributes: {
              stateName: step,
              ...(currentAgent &&
                step !== 'TRAVEL_WAITING' && {
                  agent: {
                    id: currentAgent.agentId,
                    name: currentAgent.agentName,
                  },
                  vehicle: currentAgent.vehicle,
                  agentCoordinates: currentAgent.position,
                }),
            },
            note,
          },
          {
            onSuccess: handleCloseModal,
            onError: () =>
              addToast({
                message: 'Erro ao realizar intervenção',
                type: 'alert',
              }),
          },
        )
      }
    },
    [addToast, agent, handleCloseModal, mutate, step],
  )

  return (
    <ModalV2.Root isOpen onClose={handleCloseModal}>
      <ModalV2.Content className={styles.container}>
        <ModalV2.Title>Justificar ação</ModalV2.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Textarea
            rows={5}
            label="Justificativa"
            {...register('note')}
            onChange={(event) =>
              setValue('note', event.target.value, {
                shouldValidate: true,
              })
            }
          />
          <ModalV2.Footer>
            <ModalV2.Close asChild>
              <ButtonV2 appearance="secondary">Cancelar</ButtonV2>
            </ModalV2.Close>
            <ButtonV2 type="submit" disabled={!isValid || isLoading}>
              Salvar
            </ButtonV2>
          </ModalV2.Footer>
        </form>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default InterventionJustification
