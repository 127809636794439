import { PartitionDevice } from 'services/partition/types'

export const sortDevices = (devices: PartitionDevice[]): PartitionDevice[] => {
  return devices.sort((prev, next) => {
    if (prev.type !== next.type) {
      return Number(prev.type) - Number(next.type)
    } else {
      return prev.name.localeCompare(next.name)
    }
  })
}
