import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'shared/hooks'
import { usePostWriteCommand } from 'shared/hooks/services/commands'
import { WriteCommandPayload } from 'services/command/types'

export const usePostAndHandleWriteCommand = (
  centralId: string,
  payload: WriteCommandPayload,
  onSuccess?: () => Promise<unknown>,
) => {
  const { addToast } = useToast()
  const queryClient = useQueryClient()

  const { mutateWriteCommand, writeCommandMutationStatus } =
    usePostWriteCommand(centralId, {
      userId: payload.userId,
      attributes: {
        demo: payload.attributes.demo,
      },
    })

  useEffect(() => {
    if (writeCommandMutationStatus === 'success') {
      if (onSuccess) onSuccess()

      addToast({
        message: payload.attributes.demo
          ? 'Tipo de central alterado para “Demonstração” com sucesso.'
          : 'Tipo de central alterado para “Cliente” com sucesso.',
      })
      queryClient.invalidateQueries({ queryKey: ['queryCentralByAccounts'] })
    }

    if (writeCommandMutationStatus === 'error') {
      addToast({
        message: 'Erro ao mudar tipo de central. Tente novamente.',
        type: 'alert',
      })
    }
  }, [writeCommandMutationStatus])

  return { mutateWriteCommand, writeCommandMutationStatus }
}
