import { AxiosInstance } from 'axios'
import HttpClient from '../httpClient'

import { Result } from '../types'
import { TrackerModel, TrackerModelQuery } from './types'

export interface TrackerModelDriver {
  query(params: TrackerModelQuery): Promise<Result<TrackerModel>>
}

export class TrackerModelDriverImpl implements TrackerModelDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async query(params: TrackerModelQuery): Promise<Result<TrackerModel>> {
    return this.httpClient
      .get<Result<TrackerModel>>('/tracker-model/query', { params })
      .then((res) => res.data)
  }
}

export const TrackerModelDriver = new TrackerModelDriverImpl()
