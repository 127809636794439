import { useEffect } from 'react'
import { InterventionPayload } from 'services/occurrence/types'
import { useToast } from 'shared/hooks'
import usePostIntervention from 'shared/hooks/services/occurrence/usePostIntervention'

export default function usePostAndHandleIntervention(
  occurrenceId: string,
  onSuccessCallback?: (data: InterventionPayload) => void,
) {
  const { mutateCreateIntervention, statusCreateIntervention } =
    usePostIntervention({ occurrenceId, onSuccessCallback })
  const { addToast } = useToast()

  useEffect(() => {
    if (statusCreateIntervention === 'success') {
      addToast({
        message: 'Intervenção realizada com sucesso',
      })
    }

    if (statusCreateIntervention === 'error') {
      addToast({
        message: 'Erro ao realizar intervenção na ocorrência',
        type: 'alert',
      })
    }
  }, [addToast, statusCreateIntervention])

  return {
    mutateCreateIntervention,
    statusCreateIntervention,
  }
}
