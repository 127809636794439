import { useEffect } from 'react'
import { useToast, useUserInfo } from 'shared/hooks'
import { usePostInfoRequestCommand } from 'shared/hooks/services/commands'

export const usePostAndHandleInfoRequestCommand = (
  centralId: string,
  onSuccess: () => void,
) => {
  const { addToast } = useToast()
  const { userInfo } = useUserInfo()
  const { infoRequestStatus, mutateInfoRequest } = usePostInfoRequestCommand(
    centralId,
    {
      userId: userInfo.id,
    },
  )

  useEffect(() => {
    if (infoRequestStatus === 'success') {
      onSuccess()
      addToast({
        message: 'Dados atualizados com sucesso.',
      })
    }

    if (infoRequestStatus === 'error') {
      addToast({
        message: 'Erro ao atualizar dados. Tente novamente.',
        type: 'alert',
      })
    }
  }, [infoRequestStatus, onSuccess, addToast])

  return { mutateInfoRequest, infoRequestStatus }
}
