import { useMutation } from '@tanstack/react-query'
import { CoverageInputRequest, CoverageDriver } from 'services/coverages'

function createCoverage(data: CoverageInputRequest): Promise<void> {
  return CoverageDriver.create(data)
}

export const usePostCoverage = () => {
  const { status, mutate } = useMutation({
    mutationFn: async (data: CoverageInputRequest) => {
      await createCoverage(data)
    },
  })

  return {
    mutateCreateCoverage: mutate,
    statusCreateCoverage: status,
  }
}
