import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  InterventionPayload,
  OccurrenceByUserCoverageQuery,
  OccurrenceListByCoverage,
} from 'services/occurrence/types'
import OccurrenceService from 'services/occurrence'
import { Result } from 'services/types'

function createIntervention(
  occurrenceId: string,
  data: InterventionPayload,
): Promise<void> {
  return OccurrenceService.createIntervention(occurrenceId, data)
}

export const useFinishOccurrence = (
  occurrenceId = '',
  queryParams: OccurrenceByUserCoverageQuery,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: InterventionPayload) => {
      if (occurrenceId) {
        return await createIntervention(occurrenceId, data)
      }
    },
    retry: false,
    onSuccess: async () => {
      const previousOccurrences = queryClient.getQueryData<
        Result<OccurrenceListByCoverage>
      >(['occurrences-management', queryParams])

      if (previousOccurrences) {
        queryClient.setQueryData<Result<OccurrenceListByCoverage>>(
          ['occurrences-management', queryParams],
          {
            data: previousOccurrences.data.filter(
              (data) => data.id !== occurrenceId,
            ),
            totalElements: previousOccurrences?.totalElements,
          },
        )
      } else {
        queryClient.invalidateQueries({
          queryKey: ['occurrences-management', queryParams],
        })
      }
    },
  })
}
