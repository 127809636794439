import { Button, Modal, Tags } from '../../../../components/index'

import { Tag } from 'components/Tags/types'
import styles from './TagsModal.module.scss'

interface TagsModalProps {
  isVisible: boolean
  onClose(): void
  tags: Tag[]
}

export const TagsModal = (props: TagsModalProps) => {
  const { isVisible, tags, onClose } = props

  return (
    <>
      <Modal
        size="sm"
        isVisible={isVisible}
        onClose={() => {
          onClose()
        }}
        title={'Tags da Ordem de Serviço'}
      >
        <div className={styles.container}>
          <Tags
            expandTags
            keyId={'userCoverages'}
            keyLabel="userCoverages"
            data={tags}
            className={styles.serviceOrderTags}
          />
        </div>

        <Modal.Footer>
          <Button
            buttonTitle="Voltar"
            onClick={() => {
              onClose()
            }}
            type="secondary"
            id="so-status-return-button"
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
