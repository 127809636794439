import { Button, Droplist, IconWithTooltip } from 'components'

import { ReactComponent as Option } from 'assets/svg/option.svg'
import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'
import { ReactComponent as AnnuledIcon } from '../../assets/annulled.svg'
import { ReactComponent as Exclamation } from 'assets/svg/exclamationWarning.svg'

import { DeviceType } from 'services/partition/types'

import { formatHiddenZoneToForTooltip } from '../../utilities/time/time'

import {
  DeleteDevice,
  DeviceEnabled,
} from 'domains/customer/screens/Equipments/components'

import { useToggle } from 'shared/hooks'

import styles from './DeviceCardHeader.module.scss'

type DeviceCardHeaderProps = {
  deviceId: string
  centralId?: string
  title: string
  hiddenZone: boolean
  hiddenZoneTo?: number
  tamper: boolean
  type?: DeviceType
  deviceTypeName?: string
  onClick?: () => void
  hasCommandSupport: boolean
}

export const DeviceCardHeader = ({
  deviceId,
  centralId,
  title,
  hiddenZone,
  hiddenZoneTo,
  tamper,
  type,
  onClick,
  hasCommandSupport,
  deviceTypeName,
}: DeviceCardHeaderProps) => {
  const deleteModal = useToggle()
  const deviceAnnulModal = useToggle()

  const subtitle = {
    [DeviceType.PassiveInfraPhotoRedSensor]: 'Fotosensor',
    [DeviceType.ReedSwitch]: 'Sensor de abertura',
    [DeviceType.WirelessSiren]: 'Sirene',
    [DeviceType.RemoteControl]: 'Controle',
    [DeviceType.Central]: '',
    [DeviceType.PassiveInfraRedSensor]: '',
  }

  return (
    <>
      <DeleteDevice
        deviceId={deviceId}
        onClose={deleteModal.hide}
        isVisible={deleteModal.isVisible}
      />

      <DeviceEnabled
        isVisible={deviceAnnulModal.isVisible}
        onClose={deviceAnnulModal.hide}
        device={{
          id: deviceId,
          hiddenZone,
          hiddenZoneTo,
          hasCommandSupport,
        }}
        centralId={centralId || ''}
      />
      <div className={styles.informationHeader}>
        <div className={styles.cardTitle}>
          <div className={styles.deviceNameWraper}>
            <span>{title}</span>
            {hiddenZone || hiddenZoneTo ? (
              <IconWithTooltip
                text={
                  hiddenZoneTo
                    ? `Equipamento anulado até ${formatHiddenZoneToForTooltip(
                        hiddenZoneTo,
                      )}`
                    : 'Equipamento anulado até o próximo desarme.'
                }
                Icon={AnnuledIcon}
              />
            ) : null}
            {tamper && (
              <IconWithTooltip text="Tamper aberto" Icon={Exclamation} />
            )}
          </div>
          <small className={styles.cardSubtitle}>
            {type ? subtitle[type] : deviceTypeName}
          </small>
        </div>
        <div className={styles.editWrapper}>
          {hasCommandSupport ? (
            <Button
              className={styles.editButton}
              icon={EditIcon}
              buttonTitle="Editar"
              type="tertiary"
              onClick={onClick}
            />
          ) : (
            <Droplist
              trigger={
                <div className={styles.options}>
                  <Option aria-label="options" />
                </div>
              }
            >
              <div className={styles.dropListWrapper}>
                <button onClick={deleteModal.show}>Excluir</button>
                <button onClick={deviceAnnulModal.show}>
                  {hiddenZone || hiddenZoneTo
                    ? 'Reativar equipamento'
                    : 'Anular equipamento'}
                </button>
              </div>
            </Droplist>
          )}
        </div>
      </div>
    </>
  )
}
