import { Button, EmptyState, Modal } from 'components'

type SearchOccurrenceEmptyStateProps = {
  isVisible: boolean
  onClose: () => void
}

export const SearchOccurrenceEmptyState = ({
  isVisible,
  onClose,
}: SearchOccurrenceEmptyStateProps) => {
  return (
    <>
      <EmptyState type="EmptyOccurrence" />
      <Modal
        isVisible={isVisible}
        onClose={onClose}
        title="Atendente não habilitado"
        simple
      >
        <div>
          <p>
            Para continuar, solicite a alteração do seu cadastro. Caso esta
            mudança já tenha sido realizada, atualize a página e tente
            novamente.
          </p>
        </div>
        <Modal.Footer>
          <Button
            buttonTitle="Fechar"
            onClick={onClose}
            type="secondary"
            width="172px"
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
