import {
  ButtonV2,
  Popover,
  Icon,
  FormItem,
  FormLabel,
  TextField,
} from 'components'

import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  AccessToken,
  LoginRequest,
  useGetPermissions,
  useLogin,
} from 'services/auth'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from '@hapi/joi'

import joinClassNames from 'utilities/joinClassNames'

import { useToast } from 'shared/hooks'
import { jwtDecode } from 'jwt-decode'

import { UbideskPermissions } from 'routes/types'

import styles from './styles.module.scss'

interface ConfirmDeleteProps {
  onFinish: () => void
}

export const schema = Joi.object<LoginRequest>({
  login: Joi.string().required(),
  password: Joi.string().required(),
})

const UserVerification = ({ onFinish }: ConfirmDeleteProps) => {
  const [step, setStep] = useState<'confirmation' | 'credentials'>(
    'confirmation',
  )

  const {
    reset,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginRequest>({
    resolver: joiResolver(schema),
  })

  const { addToast } = useToast()
  const { mutate: loginFn } = useLogin()
  const { mutate: getPermissions } = useGetPermissions()

  const closeRef = useRef<HTMLButtonElement>(null)

  const handleOnCancel = () => {
    reset({})
  }

  const handleOnSubmit = ({ login, password }: LoginRequest) => {
    loginFn(
      {
        login,
        password,
      },
      {
        onSuccess: (data) => {
          const {
            user: { id: userId },
          } = jwtDecode(data.access_token) as AccessToken

          getPermissions(userId, {
            onSuccess: (permissions) => {
              const permission = UbideskPermissions.SO_EDIT

              const hasAccess = () => {
                if (permissions) {
                  if (permission in permissions) {
                    return permissions[permission]
                  }
                }

                return false
              }

              if (hasAccess()) {
                if (closeRef.current) {
                  closeRef.current.click()
                }

                setStep('confirmation')
                onFinish()
              } else {
                addToast({
                  message:
                    'Esse usuário não tem permissão para realizar essa ação.',
                  type: 'alert',
                })
              }
            },
          })
        },
      },
    )
  }

  return (
    <Popover.Root>
      <Popover.Trigger>
        <div className={styles.trashBackground}>
          <button className={styles.trigger}>
            <Icon name="delete" width={16} color="neutral" />
          </button>
        </div>
      </Popover.Trigger>
      <Popover.Content
        position="top"
        offsetY={step === 'credentials' ? 12 : 14}
        offsetX={-20}
        className={styles.content}
      >
        <div className={joinClassNames(styles.container, styles.arrow)}>
          <div className={styles.header}>
            <span className={styles.icon}>
              <Icon name="warning" color="yellow" />
            </span>
            <h3>Excluir imagem</h3>
          </div>
          {
            {
              confirmation: (
                <>
                  <p className={styles.text}>
                    Tem certeza de que deseja excluir esta imagem desta ordem de
                    serviço? Esta ação só poderá ser concluída com o login e a
                    senha de um usuário autorizado.
                  </p>
                  <div className={styles.actions}>
                    <Popover.Close>
                      <ButtonV2 appearance="tertiary" size="md">
                        Cancelar
                      </ButtonV2>
                    </Popover.Close>

                    <ButtonV2 size="md" onClick={() => setStep('credentials')}>
                      Continuar
                    </ButtonV2>
                  </div>
                </>
              ),
              credentials: (
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                  <p>
                    Entre com um usuário autorizado para confirmar a exclusão.
                    Esta ação não poderá ser desfeita.
                  </p>
                  <div className={styles.formContainer}>
                    <FormItem>
                      <FormLabel>Usuário</FormLabel>
                      <TextField placeholder="Usuário" {...register('login')} />
                    </FormItem>
                    <FormItem>
                      <FormLabel>Senha</FormLabel>
                      <TextField
                        placeholder="••••••••"
                        type="password"
                        {...register('password')}
                      />
                    </FormItem>
                    <div className={styles.actions}>
                      <Popover.Close>
                        <ButtonV2
                          appearance="tertiary"
                          onClick={handleOnCancel}
                          ref={closeRef}
                        >
                          Cancelar
                        </ButtonV2>
                      </Popover.Close>

                      <ButtonV2 type="submit" color="red" disabled={!isValid}>
                        Excluir
                      </ButtonV2>
                    </div>
                  </div>
                </form>
              ),
            }[step]
          }
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

export default UserVerification
