import { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { Combobox } from 'components'

import { ReactComponent as RemoveIcon } from 'assets/svg/remove.svg'

import styles from './ViewEntities.module.scss'

import { DEFAULT_ENTITIES_GROUPER } from './constants/constants'
import { ViewEntityContent } from './types'

type ViewEntitiesProps = {
  filterable?: boolean
  sections: Map<string, ViewEntityContent[]>
  onRemove?: (data: ViewEntityContent) => void
  customStyle?: string
}

export const ViewEntities = ({
  sections,
  filterable = true,
  onRemove,
  customStyle,
}: ViewEntitiesProps) => {
  const deletable = Boolean(onRemove)

  const [entityGrouper, setEntityGrouper] = useState(DEFAULT_ENTITIES_GROUPER)

  const [showFade, setShowFade] = useState(false)
  const entitiesRef = useRef<HTMLDivElement | null>(null)

  const entities = useMemo(() => {
    const items: ViewEntityContent[] = []
    let counter = 0

    const values = sections.values()

    for (const value of Array.from(values)) {
      if (entityGrouper === DEFAULT_ENTITIES_GROUPER) {
        items.push(...value)
      }
      const filteredEntity = value.filter(
        (entity) => entity.subtitle === entityGrouper,
      )
      items.push(...filteredEntity)
      counter += 1
    }

    return {
      data: items,
      counter,
    }
  }, [sections, entityGrouper])

  const entitiesGroupers = useMemo(() => {
    const filters = new Set<string>()
    const values = sections.values()
    for (const value of Array.from(values)) {
      value.map((entity) => filters.add(entity.subtitle))
    }
    return Array.from(filters.values()).concat(DEFAULT_ENTITIES_GROUPER)
  }, [sections])

  const updateFade = useCallback(() => {
    if (!entitiesRef.current) {
      return
    }

    const element = entitiesRef.current
    setShowFade(element.scrollTop + element.clientHeight < element.scrollHeight)
  }, [])

  useEffect(() => {
    if (!entitiesRef.current) {
      return
    }

    const observer = new ResizeObserver(() => {
      updateFade()
    })

    observer.observe(entitiesRef.current)
    return () => observer.disconnect()
  }, [updateFade])

  return (
    <div
      className={[
        customStyle,
        styles.container,
        showFade && styles.fade,
        !deletable && styles.hideCounter,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {filterable && (
        <Combobox
          id="entity-view-input"
          label="Visualizar"
          className={styles.viewEntitiesCombobox}
          items={entitiesGroupers}
          value={entityGrouper}
          getSelected={(selected) => {
            const grouper = String(selected)
            setEntityGrouper(grouper)
          }}
        />
      )}

      <div
        className={[styles.entitiesWrapper, !deletable && styles.hideScroll]
          .filter(Boolean)
          .join(' ')}
        ref={entitiesRef}
        onScroll={updateFade}
      >
        {entities.data?.length ? (
          <ul aria-label="entityGrouperList">
            {entities.data.map((entity) => (
              <li key={entity.id}>
                <div
                  className={[styles.entityTitle, customStyle]
                    .filter(Boolean)
                    .join('  ')}
                >
                  <span>{entity.title}</span>
                  <small className={!customStyle ? styles.entitySubtitle : ''}>
                    {entity.subtitle}
                  </small>
                </div>
                {onRemove && (
                  <button
                    type="button"
                    data-testid="delete-entity-button"
                    onClick={() => onRemove(entity)}
                    className={styles.deleteAction}
                  >
                    <RemoveIcon />
                  </button>
                )}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  )
}
