import Joi from '@hapi/joi'

type Fragment = {
  id: string
  name: string
}

type Partition = {
  id: string
  code: string
}

export interface OccurrenceListFiltersSchema {
  number?: number
  account?: Fragment
  partition?: Partition
  occurrenceType?: Fragment
}

const fragmentSchema = Joi.object<Fragment>({
  id: Joi.string().required(),
  name: Joi.string().required(),
})

const partitionSchema = Joi.object<Partition>({
  id: Joi.string().required(),
  code: Joi.string().required(),
})

export const occurrenceListFiltersSchema =
  Joi.object<OccurrenceListFiltersSchema>({
    number: Joi.number().optional().allow(null, ''),
    account: fragmentSchema.optional().allow(null, ''),
    partition: partitionSchema.optional().allow(null, ''),
    occurrenceType: fragmentSchema.optional().allow(null, ''),
  })
