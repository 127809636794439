import { SensorTypeEnum } from 'services/occurrence/types'
import { DeviceType } from 'services/partition/types'

export const getDeviceSensorName = (
  deviceType?: DeviceType,
  sensorType?: number,
): string => {
  if (deviceType === DeviceType.WirelessSiren) {
    if (sensorType === SensorTypeEnum.TAMPER) return 'tamper'
  }

  if (deviceType === DeviceType.Central) {
    if (sensorType === SensorTypeEnum.TAMPER) return 'tamper'
  }

  if (deviceType === DeviceType.PassiveInfraPhotoRedSensor) {
    if (sensorType === SensorTypeEnum.MAIN) return 'detecção'
    if (sensorType === SensorTypeEnum.TAMPER) return 'tamper'
    if (sensorType === SensorTypeEnum.ANTI_SABOTAGE) return 'sabotagem'
  }

  if (deviceType === DeviceType.ReedSwitch) {
    if (sensorType === SensorTypeEnum.MAIN) return 'magnético'
    if (sensorType === SensorTypeEnum.TAMPER) return 'tamper'
    if (sensorType === SensorTypeEnum.ACCELEROMETER) return 'shock'
  }

  return ''
}
