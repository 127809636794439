import { Paging, QueryRange, TagInput } from 'services/types'
import { PartitionStatus, PatrimonyPayload } from '../patrimony/types'
import { CustomerPayload } from 'services/customer/types'

export type PatrimonyWithCustomerAndAccountPayload = {
  customer: CustomerPayload
  patrimony: PatrimonyPayload
  hasAccount?: boolean
}

export type AccountAttendanceProfileNames = {
  tactical?: string[]
  technical?: string[]
}

export type AccountPartitions = {
  id: string
  name: string
  code?: string
  externalId?: string
  keyNumber?: string
  securityQuestion?: string
  securityAnswer?: string
  securityCoercionAnswer?: string
}

export type AccountRequest = {
  name?: string
  serviceType?: {
    id: string
    name: string
  }
  note?: string
  tags?: TagInput[]
  securityQuestion?: string
  securityAnswer?: string
  securityCoercionAnswer?: string
  patrimonyId?: string
  active?: boolean
  partitions?: AccountPartitions[]
  contractNumber?: string
}

const paymentMethodType = {
  CREDIT_CARD: 'CREDIT_CARD',
  DIRECT_DEBIT: 'DIRECT_DEBIT',
  BANK_SLIP: 'BANK_SLIP',
  NONE: 'NONE',
}

type PaymentMethodType = keyof typeof paymentMethodType

export const PaymentMethodLabel: Record<PaymentMethodType, string> = {
  CREDIT_CARD: 'Cartão de crédito',
  DIRECT_DEBIT: 'Débito em conta',
  BANK_SLIP: 'Boleto bancário',
  NONE: 'Nenhum',
}

export enum TagType {
  PRINCIPAL = 'Principal',
}

export type Account = {
  id: string
  name: string
  code: string
  securityAnswer: string
  securityQuestion: string
  securityCoercionAnswer: string
  paymentMethod?: {
    paymentMethodType: PaymentMethodType
    createdAt: number
  }
  patrimonyId: string
  active: 'ACTIVE' | 'INACTIVE'
  activeDateTime: number
  createdAt: number
  updatedAt: number
  tags?: {
    name: string
    type: TagType
  }[]
  note?: string
  serviceType?: {
    id: string
    name: string
  }
  externalId?: string
  attendanceProfiles?: AccountAttendanceProfileNames
  observations?: string
  partitions?: AccountPartitions[]
  contractNumber?: string
}

export type PatrimonyFragment = {
  id: string
  name: string
  accountId: string
  address: {
    address: string
    number: string
    district: string
    city: string
  }
}

export type CustomerFragment = {
  id: string
  name: string
  document: string
}

export enum CommunicationType {
  'CABLE' = 1,
  'WIFI' = 2,
  'GSM' = 3,
}

export type Partition = {
  id: string
  name: string
  code: string
  status: PartitionStatus
}

export type AccountResponse = {
  name?: string
  code?: string
  customerId?: string
  stateId?: string
  cityId?: string
  districtId?: string
  createdFrom?: number
  createdTo?: number
  securityQuestion?: string
  securityAnswer?: string
  securityCoercionAnswer?: string
  patrimonyId?: string
  tagIds?: string
  active?: boolean
  sort?: string
  sortType?: string
  address?: string
  serviceTypeIds?: string[]
  userId?: string
  contractNumber?: string
  centralCode?: string
  note: string
  id: string
  externalId: string
  createdAt: number
  activeDateTime: number
  updatedAt: number
}

export declare type AccountQuery = {
  name?: string
  code?: string
  customerId?: string
  stateId?: string
  cityId?: string
  districtId?: string
  createdFrom?: number
  createdTo?: number
  securityQuestion?: string
  securityAnswer?: string
  securityCoercionAnswer?: string
  patrimonyId?: string
  tagIds?: string
  active?: boolean
  sort?: string
  sortType?: string
  address?: string
  serviceTypeIds?: string[]
  userId?: string
  contractNumber?: string
  centralCode?: string
} & Partial<Paging> &
  Partial<QueryRange>

export type UpdateAccountNotePayload = {
  accountId: string
  note: string
}

export type AccountTagQueryPayload = {
  name?: string
  type?: string
} & Partial<Paging>

export type CentralFragment = {
  id: string
  energy: boolean
  battery: number
  communicationType: CommunicationType
  connected: boolean
  partitions: Partition[]
  hasCommandSupport: boolean
}

export type AggregatedAccount = {
  id: string
  active?: boolean
  code?: string
  aggregatedAccountName: string
  serviceType?: {
    id: string
    name: string
  }
  patrimony?: PatrimonyFragment
  customer?: CustomerFragment
  central?: CentralFragment
  createdAt?: number
  externalId?: string
  tags?: {
    id: string
    name: string
    type: TagType
  }[]
}

export declare type AccountTag = {
  id: string
  name: string
  type: TagType
  totalLinks?: number
  createdAt?: number
  updatedAt?: number
}

export type AccountPayload = AccountRequest & {
  id?: string
  externalId?: string
  active?: 'ACTIVE' | 'INACTIVE'
  attendanceProfiles?: AccountAttendanceProfileNames
  updatedAt?: number
  createdAt?: number
  activeDateTime?: number
  code?: string
  paymentMethod?: {
    paymentMethodType: PaymentMethodType
    createdAt: string
  }
  observations?: string
  partitions?: AccountPartitions[]
}
