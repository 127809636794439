import { forwardRef, Ref, useState } from 'react'
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { ReactComponent as Pen } from 'assets/svg/listEditIcon.svg'
import styles from './DragAndDropAutomation.module.scss'
import { ReactComponent as DragAndDropIcon } from 'domains/attendancePolicy/assets/svg/DragAndDrop.svg'
import { Button, Icon, Modal } from 'components'

interface DragAndDropItem {
  id: string
  title: string
  email?: string
  phone?: string
  subtitle?: string
  onEdit?: () => void
  onCopy?: () => void
  isDragDisabled?: boolean
}
interface DragAndDropItemProps extends Omit<DragAndDropItem, 'id'> {
  index: number
  handleOpen?: () => void
  [key: string]: unknown
}

const DragAndDropItem = forwardRef(
  (props: DragAndDropItemProps, ref: Ref<HTMLDivElement>) => {
    const {
      title,
      subtitle,
      index,
      onCopy,
      onEdit,
      email,
      phone,
      isDragDisabled = false,
      handleOpen,
      ...rest
    } = props

    return (
      <div
        ref={ref}
        className={[styles.item].join(' ')}
        data-testid={`draggable-item`}
        {...rest}
      >
        <div className={styles.leftContent}>
          {isDragDisabled ? (
            <span className={styles.icon} />
          ) : (
            <DragAndDropIcon
              className={[styles.icon, styles.leftItem].join(' ')}
            />
          )}
          <div className={styles.titleWrapper}>
            <span className={styles.title}>
              {!isDragDisabled && `${index + 1}º`} {title}
            </span>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            {(!!phone || !!email) && (
              <div className={styles.info}>
                {phone && <span>{phone}</span>}
                {email && <span> • {email}</span>}
              </div>
            )}
          </div>
        </div>

        <div className={styles.rightContent}>
          {onEdit && (
            <button
              type="button"
              data-testid={`edit-button-${index}`}
              onClick={onEdit}
              className={styles.hoverIcon}
            >
              <Pen />
            </button>
          )}
          {onCopy && (
            <button
              type="button"
              data-testid={`copy-button-${index}`}
              onClick={onCopy}
              className={styles.hoverIcon}
            >
              <Icon name="copy" />
            </button>
          )}
          {handleOpen && (
            <button
              type="button"
              data-testid={`delete-button-${index}`}
              onClick={handleOpen}
              className={styles.hoverIcon}
            >
              <Icon name="delete" />
            </button>
          )}
        </div>
      </div>
    )
  },
)

DragAndDropItem.displayName = 'DragAndDropItem'

interface DragAndDropProps<T> {
  items: T[]
  getItem: (item: T) => DragAndDropItem
  onChange?: (items: T[], itemIndex?: number) => void
  onDelete?: (item: T) => void
  onCopy?: (item: DragAndDropItem) => void
  onEdit?: (item: DragAndDropItem) => void
  draggable?: boolean
  canDelete?: boolean
}

export function DragAndDrop<T>({
  items,
  getItem,
  onChange,
  onDelete,
  onEdit,
  onCopy,
  draggable = true,
  canDelete = true,
}: DragAndDropProps<T>): JSX.Element {
  const handleReorderItems = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems = [...items]
    const [removed] = newItems.splice(result.source.index, 1)

    newItems.splice(result.destination.index, 0, removed)

    if (onChange) {
      onChange(newItems)
    }
  }

  const getVerticalDraggingStyle = (
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => {
    if (!draggableStyle) return

    const { transform } = draggableStyle
    let activeTransform = {}
    if (transform) {
      activeTransform = {
        transform: `translate(0, ${transform.substring(
          transform.indexOf(',') + 1,
          transform.indexOf(')'),
        )})`,
      }
    }

    return {
      userSelect: 'none',
      ...draggableStyle,
      ...activeTransform,
    }
  }

  const [isVisible, setIsVisible] = useState(false)
  const [currentItem, setCurrentItem] = useState<T | null>(null)

  const handleOpen = (item: T) => {
    setCurrentItem(item)
    setIsVisible(true)
  }

  const handleClose = () => {
    setIsVisible(false)
  }

  return (
    <DragDropContext onDragEnd={handleReorderItems}>
      <Droppable droppableId="drag-and-drop">
        {(droppableProvided) => (
          <div className={styles.wrapper}>
            <div
              className={styles.container}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {items.map((rawItem, index) => {
                const item = getItem(rawItem)

                return (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={!draggable}
                  >
                    {(draggableProvided) => (
                      <DragAndDropItem
                        {...(onDelete && {
                          handleOpen: () => handleOpen(rawItem),
                        })}
                        index={index}
                        email={item.email}
                        phone={item.phone}
                        title={item.title}
                        subtitle={item.subtitle || ''}
                        isDragDisabled={!draggable}
                        onCopy={onCopy ? () => onCopy?.(item) : undefined}
                        onEdit={onEdit ? () => onEdit?.(item) : undefined}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={getVerticalDraggingStyle(
                          draggableProvided.draggableProps.style,
                        )}
                      />
                    )}
                  </Draggable>
                )
              })}

              {droppableProvided.placeholder}
            </div>
          </div>
        )}
      </Droppable>

      <Modal
        simple
        onClose={handleClose}
        title="Excluir item"
        isVisible={isVisible}
      >
        <p>
          Você tem certeza de que deseja remover este item? Esta ação não poderá
          ser desfeita.
        </p>
        <Modal.Footer>
          <Button
            width="180px"
            onClick={handleClose}
            id="btn-secondary"
            buttonTitle="Cancelar"
            type="secondary"
          />
          {canDelete && (
            <Button
              data-testid="btn-primary"
              width="180px"
              id="btn-primary"
              buttonTitle="Excluir"
              type="cancel-primary"
              onClick={() => {
                if (onDelete && currentItem) {
                  onDelete(currentItem)
                }
                handleClose()
              }}
            />
          )}
        </Modal.Footer>
      </Modal>
    </DragDropContext>
  )
}
