import { Button, Popover } from 'components'
import React, { useState } from 'react'
import { TechnicianScheduleResponse } from 'services/technicianSchedule/types'
import { maskedDateTime } from 'utilities/date'
import { ReactComponent as WarningIcon } from 'assets/svg/blockedWarning.svg'
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'

import styles from './styles.module.scss'

type BlockedScheduleProps = {
  technicianSchedule: TechnicianScheduleResponse[]
  technician: string
}

export const BlockedSchedule: React.FC<BlockedScheduleProps> = ({
  technicianSchedule,
  technician,
}) => {
  const [currentPage, setCurrentPage] = useState(0)

  const handleNext = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % technicianSchedule?.length)
  }

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + technicianSchedule?.length) %
        technicianSchedule?.length,
    )
  }

  return (
    <div className={styles.container}>
      <Popover.Close>
        <CloseIcon />
      </Popover.Close>
      <div className={styles.content}>
        <header>{technician}</header>
        <span>Motivo</span>
        <p>{technicianSchedule[currentPage]?.reason}</p>
        <span>Início do bloqueio</span>
        <p>{maskedDateTime(technicianSchedule[currentPage]?.startDate)}</p>
        <span>Retorno do bloqueio</span>
        <p>{maskedDateTime(technicianSchedule[currentPage]?.endDate)}</p>
        <span>Observação</span>
        <p>{technicianSchedule[currentPage]?.note}</p>
      </div>
      {technicianSchedule.length > 1 && (
        <div className={styles.footer}>
          <span>
            <WarningIcon />
            Possui mais de um bloqueio
          </span>
          <div className={styles.buttonsContainer}>
            <Button
              disabled={currentPage === 0}
              type="tertiary"
              buttonTitle="Anterior"
              onClick={handlePrev}
            />
            <Button
              disabled={currentPage === technicianSchedule.length - 1}
              type="tertiary"
              buttonTitle="Próximo"
              onClick={handleNext}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default BlockedSchedule
