import { ComboboxItem } from '../Combobox'

export interface Item {
  id: string
}

export function getItemValue<T>(item: string | ComboboxItem<T>): string {
  if (typeof item === 'string') {
    return item
  }

  if (typeof item.value === 'string') {
    return item.value
  }

  return String(item.value[item.label])
}

export const isItemEquals = <T extends Item>(
  value: string | ComboboxItem<T>,
  other: string | ComboboxItem<T>,
): boolean => {
  const parsedValue = getItemValue(value)
  const parsedOther = getItemValue(other)

  return parsedValue.toLocaleLowerCase() === parsedOther.toLocaleLowerCase()
}
