import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import SoundDriver from '../../../../services/sound'
import { SoundResponse } from 'services/sound/types'

type UseGetSounds = {
  sounds: SoundResponse[] | undefined
  isFetching: boolean
  isError: boolean
  fetchNextSoundsPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const getSounds = async (offset = 0, filter?: string) => {
  const response = await SoundDriver.query({
    recordsPerPage: 15,
    offset,
    ...(filter && { name: filter }),
  })
  return response
}

export function useGetSounds(filter?: string): UseGetSounds {
  const {
    isError,
    data,
    isFetching,
    fetchNextPage: fetchNextSoundsPage,
  } = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['sounds', filter],
    queryFn: ({ pageParam }) => getSounds(pageParam, filter),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<SoundResponse>(lastPage, allPages),
  })

  const sounds = useMemo(() => flattenPages<SoundResponse>(data), [data])

  return {
    isError,
    sounds,
    fetchNextSoundsPage,
    isFetching,
  }
}
