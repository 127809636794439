import React, { useEffect, useState } from 'react'

import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { ContactQueryResponse } from 'services/contact/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useGetContacts } from 'shared/hooks/services'
import { Button, Modal } from 'components'
import { useDebounce } from 'shared/hooks'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import styles from './styles.module.scss'

type AddContactModalProps = {
  onClose: () => void
  contacts?: string[]
  onSave: (contactId: string) => void
}

export const AddContactModal: React.FC<AddContactModalProps> = ({
  onSave,
  onClose,
  contacts,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [newContact, setNewContact] = useState({ id: '', name: '' })
  const [contactName, setContactName] = useState('')
  const handleFilter = useDebounce(setContactName)

  const customerId = localStorage.getItem('customerId') || ''

  const { contacts: contactsList, isFetchingContacts } = useGetContacts(
    {
      customerId,
      name: contactName,
    },
    !!customerId,
  )

  useEffect(() => {
    if (location?.state?.contactName) {
      setContactName(location?.state?.contactName)
    }
  }, [location])

  return (
    <Modal isVisible onClose={onClose} title="Adicionar contato">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSave(newContact.id)
        }}
      >
        <div className={styles.comboBoxWrapper}>
          <Combobox
            isLoading={isFetchingContacts}
            label={{ text: 'Contato' }}
            items={parseDataToComboboxV2(
              contactsList
                ?.filter((contact) => !contacts?.includes(contact.id))
                .sort((a, b) => a.name.localeCompare(b.name)) || [],
              'name',
            )}
            onSearch={handleFilter}
            value={newContact.name}
            onChange={(selected) => {
              const contact = selected as ComboboxItem<ContactQueryResponse>

              setNewContact({
                id: contact.value.id,
                name: contact.value.name,
              })
            }}
          />
          <Button
            type="tertiary"
            buttonTitle="Criar contato"
            onClick={() =>
              navigate('/contact', {
                state: { background: location },
              })
            }
          />
          <Outlet />
        </div>

        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Cancelar"
            onClick={onClose}
            type="secondary"
          />
          <Button
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            htmlType="submit"
            disabled={!newContact.id}
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}
