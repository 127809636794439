import { Button, Modal } from 'components'

import styles from './CustomerAlreadyExistsModal.module.scss'

type CustomerAlreadyExistsModalProps = {
  isVisible: boolean
  onClose: () => void
  onClick: () => void
}

export const CustomerAlreadyExistsModal = ({
  isVisible,
  onClick,
  onClose,
}: CustomerAlreadyExistsModalProps) => {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      size={'sm'}
      simple
      title={'Cliente já cadastrado'}
    >
      <div className={styles.content}>
        <p>
          Você quer ser redirecionado para a página do cliente para <br />
          efetuar as alterações necessárias
        </p>
      </div>
      <Modal.Footer>
        <Button
          buttonTitle="Voltar"
          onClick={onClose}
          type="secondary"
          width="172px"
        />
        <Button
          buttonTitle="Continuar"
          onClick={onClick}
          type="primary"
          width="172px"
        />
      </Modal.Footer>
    </Modal>
  )
}
