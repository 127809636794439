import { Tooltip } from 'components'
import { ReactNode, useRef } from 'react'
import { useToggle } from 'shared/hooks'

interface IconWithTooltip {
  Icon: ReactNode
  tooltipInfo: string
}

export const IconWithTooltip = ({ Icon, tooltipInfo }: IconWithTooltip) => {
  const iconTooltipRef = useRef<HTMLDivElement>(null)
  const tooltip = useToggle()

  return (
    <>
      <div
        ref={iconTooltipRef}
        onMouseEnter={tooltip.show}
        onMouseLeave={tooltip.hide}
      >
        {Icon}
      </div>
      <Tooltip
        parentRef={iconTooltipRef}
        type="informative"
        isVisible={tooltip.isVisible}
      >
        <span>{tooltipInfo}</span>
      </Tooltip>
    </>
  )
}
