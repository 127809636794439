import { useMutation, useQueryClient } from '@tanstack/react-query'

import { deleteImage } from 'services/image'

export const useDeleteImage = (imageId?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (_nothing: null) => await deleteImage({ imageId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['service-order-intervention'],
      })
      queryClient.invalidateQueries({
        queryKey: ['get-image'],
      })
    },
  })
}
