import Joi from '@hapi/joi'
import { ServiceType, TimeUnit } from 'services/serviceType/types'

export const serviceTypeSchema = Joi.object<ServiceType>({
  id: Joi.string().uuid(),
  name: Joi.string(),
  travelLimitEnabled: Joi.boolean(),
  travelLimitQuantity: Joi.number(),
  travelLimitIntervalValue: Joi.number(),
  travelLimitIntervalUnit: Joi.string().valid(...Object.values(TimeUnit)),
  sensorTriggersEnabled: Joi.boolean(),
  sensorTriggersQuantity: Joi.number(),
  sensorTriggersIntervalValue: Joi.number(),
  sensorTriggersIntervalUnit: Joi.string().valid(...Object.values(TimeUnit)),
  createdAt: Joi.number(),
  updatedAt: Joi.number(),
})
