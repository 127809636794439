import { Breadcrumbs, ContainerScreen, Loader } from 'components'

import { useNavigate } from 'react-router-dom'
import { useToast } from 'shared/hooks'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { createCentralSchema } from 'domains/customer/schemas/central/centralSchema'
import { CreateCentralPayload } from 'services/central/types'
import { usePostCentral } from 'services/central'
import ImageDriver from 'services/image'
import { CentralForm } from 'domains/customer/components/CentralForm/CentralForm'

export const CreateCentral = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()

  const { mutatePostCentral, statusPostCentral } = usePostCentral()

  const form = useForm<CreateCentralPayload>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(createCentralSchema),
  })

  const customerName = localStorage.getItem('customerName') || ''
  const customerId = localStorage.getItem('customerId') || ''
  const patrimonyId = localStorage.getItem('patrimonyId') || ''

  const submit = (payload: CreateCentralPayload) => {
    mutatePostCentral(
      {
        ...payload,
        central: {
          ...payload.central,
          code: payload.central.code?.replace(/:/g, '') || '',
        },
        account: {
          id: payload.account?.id,
          aggregatedAccountName: '',
        },
        contacts: payload.contacts.map((contact) => ({
          contactId: contact.id,
          code: contact.code,
        })),
      },
      {
        onSuccess: async (data) => {
          if (payload.image) {
            try {
              await ImageDriver.persist(payload.image, {
                principalOwner: {
                  id: String(data.centralId),
                  name: 'Central',
                },
                owners: [
                  {
                    id: customerId,
                    name: 'Customer',
                  },
                  {
                    id: patrimonyId,
                    name: 'Patrimony',
                  },
                  {
                    id: payload.account?.id,
                    name: 'Account',
                  },
                  {
                    id: data.centralId,
                    name: 'Central',
                  },
                  {
                    id: data.centralId,
                    name: 'InstalledDevice',
                  },
                ],
              })
            } catch (error) {
              console.debug('Erro ao cadastrar imagem')
            }
          }
          addToast({
            message: 'Central cadastrada com sucesso.',
            type: 'success',
          })
          navigate('/account/management/central')
        },
        onError: () =>
          addToast({
            message: 'Erro ao cadastrar central. Tente novamente.',
            error: true,
            type: 'alert',
          }),
      },
    )
  }

  return (
    <ContainerScreen
      clickable
      renderBreadcrumbs={
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Cliente', href: '/account/management/account' },
            { title: `${customerName}`, href: '/account/management/account' },
            { title: 'NOVA CENTRAL', href: '#' },
          ]}
        />
      }
    >
      <Loader isVisible={statusPostCentral === 'pending'} />
      <FormProvider {...form}>
        <CentralForm onSubmit={submit} />
      </FormProvider>
    </ContainerScreen>
  )
}
