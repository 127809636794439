import { useCallback, useMemo } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import ImageDriver from 'services/image'

import { Breadcrumbs, Loader } from 'components'
import { UserForm } from 'domains/user/components/UserForm/UserForm'

import { useGetUserInfo } from 'domains/user/hooks/services/users/useGetUserInfo'

import styles from './UpdateUser.module.scss'
import { useToast } from 'shared/hooks'
import { usePutUserInfo } from 'domains/user/hooks/services/users/usePutUserInfo'
import { UserFormData } from 'domains/user/types'

export const UpdateUser = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { addToast } = useToast()

  const memoizedUserIdAndReadOnly = useMemo(() => {
    return {
      userId: location.pathname.split('/').reverse()[0],
      readOnly: location?.state?.readOnly ?? true,
    }
  }, [location])

  const { userInfo, isFetching } = useGetUserInfo(
    memoizedUserIdAndReadOnly.userId,
    Boolean(memoizedUserIdAndReadOnly.userId),
  )

  const { mutateUpdateUserInfo, statusUpdateUserInfo } = usePutUserInfo(
    memoizedUserIdAndReadOnly.userId,
  )

  const onSubmit = useCallback(
    (payload: UserFormData) => {
      mutateUpdateUserInfo(payload, {
        onSuccess: async (data) => {
          if (payload.avatarFile) {
            try {
              await ImageDriver.persist(payload.avatarFile as File, {
                principalOwner: {
                  id: data.id,
                  name: 'internalProfile',
                },
                owners: [
                  {
                    id: data.id,
                    name: 'internalProfile',
                  },
                ],
              })
            } catch (error) {
              console.debug('Erro ao criar imagem')
            }
          }
          addToast({ message: 'Usuário editado com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          addToast({
            message: 'Erro ao editar usuário. Tente novamente.',
            type: 'alert',
          })
        },
      })
    },
    [mutateUpdateUserInfo, addToast, navigate],
  )

  if (!userInfo || isFetching) {
    return <Loader isVisible />
  }

  return (
    <div className={styles.container}>
      <Loader
        isVisible={
          !userInfo || isFetching || statusUpdateUserInfo === 'pending'
        }
      />
      <Breadcrumbs
        items={[
          { title: 'Home', href: '/' },
          { title: 'Configurações', href: '' },
          {
            title: 'Gestão de usuários',
            href: '/config/userManagement?page=1',
          },
          { title: userInfo?.name || '', href: '' },
        ]}
      />
      <div className={styles.headerSeparator} />

      <UserForm
        user={userInfo}
        onSubmit={onSubmit}
        onGoBack={() => navigate(-1)}
        readOnly={memoizedUserIdAndReadOnly.readOnly}
      />
    </div>
  )
}
