import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { HistoryIntervention } from 'services/attendance/types'

import { maskedDateTime } from 'utilities/date'
import { POSITION } from 'shared/hooks/useElementPosition/types'
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { useModal } from 'domains/occurrence/screens/Attendance/context'

import styles from './styles.module.scss'
import { ButtonV2, Tooltip } from 'components'

interface AutomaticRuleCardProps {
  intervention: HistoryIntervention
}

const AutomaticRuleCard: FC<AutomaticRuleCardProps> = ({ intervention }) => {
  const svgRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const { handleOpenModal, modal } = useModal()

  const handleChooseScenario = useCallback(() => {
    if (intervention.typeName === 'REQUIRE_PHONE_CALL') {
      const callTarget = intervention.attributes?.phoneCallTarget

      handleOpenModal('AUTOMATIC_RULE', {
        intervention,
        type: callTarget,
      })
    }
  }, [handleOpenModal, intervention])

  useEffect(() => {
    if (!intervention.hasCallback && !modal) {
      handleChooseScenario()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasNote = !!intervention.notes?.length

  return (
    <li className={styles.container}>
      <h4>
        {intervention.typeName === 'TAG_INCLUSION' ? (
          <>
            <strong> Adição automática de tags</strong> {` • `}
            {maskedDateTime(intervention.createdAt)}
          </>
        ) : (
          <>
            <strong>{intervention.title}</strong>
            {` por ${intervention.user.name || 'Sistema'} •
            ${maskedDateTime(intervention.createdAt)}`}
          </>
        )}
      </h4>
      {intervention.typeName === 'TAG_INCLUSION' && (
        <ButtonV2
          appearance="tertiary"
          size="md"
          onClick={() => {
            handleOpenModal('TAGS_INCLUDES', {
              tags: intervention.tags,
            })
          }}
        >
          Visualizar
        </ButtonV2>
      )}
      {hasNote && (
        <div ref={svgRef}>
          <InfoIcon
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
          />
          <Tooltip
            positionOnEdge
            hasArrow={false}
            isVisible={visible}
            parentRef={svgRef}
            positionAbove={POSITION.BOTTOM}
            type="informative"
          >
            <p>{intervention.notes?.[0]?.note}</p>
          </Tooltip>
        </div>
      )}
    </li>
  )
}

export default AutomaticRuleCard
