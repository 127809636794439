import { useMemo } from 'react'
import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'

import {
  flattenPages,
  getNextPageOffSet,
} from '../../services/utilities/pagination'

import HttpClient from 'services/httpClient'
import { TagQueryResponse } from 'services/auth'
import { AccountTag, TagType } from 'services/account/types'
import { Result, TagOutput } from 'services/types'
import OccurrenceDriver from 'services/occurrence'
import { endpoints } from './endpoints'

type UseGetOccurrenceTagsReturn = UseInfiniteQueryResult<
  Result<AccountTag>,
  unknown
> & {
  occurrenceTags: AccountTag[]
}

const getOccurrenceTags = async (offset = 0, name?: string) => {
  const response = await OccurrenceDriver.queryOccurrenceTags({
    size: 15,
    page: Math.round(offset / 15),
    ...(name && { name }),
  })

  const tagResponse: Result<AccountTag> = {
    data: response?.data.map((tag) => ({
      id: tag.id,
      name: tag.name,
      type: TagType.PRINCIPAL,
    })),
    totalElements: response.totalElements,
  }

  return tagResponse
}

export async function getOccurrenceTag(name?: string, offset = 0) {
  const result = await HttpClient.get<Result<TagOutput>>(
    endpoints.getInterventionTag,
    {
      params: {
        size: 15,
        page: Math.round(offset / 15),
        ...(name && { name }),
      },
    },
  )

  const tagResponse: Result<AccountTag> = {
    data: result.data.data.map((tag) => ({
      id: tag.id,
      name: tag.name,
      type: TagType.PRINCIPAL,
    })),
    totalElements: result.data.totalElements,
  }

  return tagResponse
}

export function useGetOccurrenceTag(
  name: string,
  enabled: boolean,
): UseGetOccurrenceTagsReturn {
  const infinityQuery = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['occurrence-tag', name],
    queryFn: ({ pageParam }) => getOccurrenceTags(pageParam, name),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<TagQueryResponse>(lastPage, allPages),
    enabled,
  })

  const occurrenceTags = useMemo(
    () => flattenPages<AccountTag>(infinityQuery?.data),
    [infinityQuery],
  )

  return {
    ...infinityQuery,
    occurrenceTags,
  }
}
