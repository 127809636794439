import { useEffect, useState } from 'react'

import { SensorType, TriggerType } from 'services/attendancePolicy/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { Combobox } from 'components/ComboboxV2/Combobox'

import styles from './Arrival.module.scss'
import { useForm } from 'react-hook-form'
import { EventTypeResponse } from 'services/event/types'
import { useGetEventTypes } from 'shared/hooks/services/events'
import { ComboboxItem, RadioGroup } from 'components'
import { SENSOR_TYPE_EN_PT } from 'services/device/types'
import {
  convertSensorType,
  translatedSensorType,
} from 'domains/attendancePolicy/components/RuleForm/utilities'
import { TriggerOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import { RadioButton } from 'components/RadioButton/RadioButton'
import {
  ARRIVED_TYPE,
  ARRIVED_TYPE_EN_PT,
} from 'domains/attendancePolicy/types'

export const Arrival = ({ onChange, data }: TriggerOptionForm) => {
  const { parameters, type } = data
  const { register, watch, setValue, trigger, reset } = useForm<{
    eventTypes: EventTypeResponse[]
    sensorTypes: SensorType[]
    occurrenceTypeId: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      occurrenceTypeId: parameters?.occurrenceTypeId,
      eventTypes: parameters?.eventType || [],
      sensorTypes: parameters?.sensorTypes || [],
    },
  })

  const [arrivedType, setArrivedType] = useState<ARRIVED_TYPE | null>(null)

  const selectedEventTypes = watch('eventTypes')
  const sensorTypes = watch('sensorTypes')

  const [eventTypeFilter, setEventTypeFilter] = useState('')
  const { eventTypes, isFetching, fetchNextEventTypesPage, isError } =
    useGetEventTypes(eventTypeFilter)

  useEffect(() => {
    register('eventTypes')
    register('sensorTypes')
    register('occurrenceTypeId')
  }, [register])

  useEffect(() => {
    if (parameters?.eventType) {
      setArrivedType(ARRIVED_TYPE.EVENT)
    }

    if (parameters?.occurrenceTypeId) {
      setArrivedType(ARRIVED_TYPE.OCCURRENCE)
    }

    trigger()
  }, [parameters, type, trigger])

  return (
    <>
      <RadioGroup title="Entidade">
        <RadioButton
          name="trigger"
          checked={arrivedType === ARRIVED_TYPE.OCCURRENCE}
          onChange={() => {
            setArrivedType(ARRIVED_TYPE.OCCURRENCE)
            reset()
            onChange({
              type: TriggerType.OCCURRENCE_ARRIVAL,
              parameters: { occurrenceTypeId: ' ' },
            })
          }}
          value={ARRIVED_TYPE_EN_PT.OCCURRENCE}
        />
        <RadioButton
          name="trigger"
          checked={arrivedType === ARRIVED_TYPE.EVENT}
          onChange={() => {
            setArrivedType(ARRIVED_TYPE.EVENT)
            reset()
            onChange({ type: TriggerType.EVENT_ARRIVAL, parameters: {} })
          }}
          value={ARRIVED_TYPE_EN_PT.EVENT}
        />
      </RadioGroup>

      {arrivedType === ARRIVED_TYPE.EVENT && (
        <div className={styles.arrivedEvent}>
          <Combobox
            label={{ text: 'Tipo de evento' }}
            items={parseDataToComboboxV2(
              eventTypes || [],
              'aggregatedCodeDescription',
            )}
            value={selectedEventTypes?.map((eventType) => ({
              label: 'name',
              value: eventType,
            }))}
            placeholder="Selecione um ou mais tipos"
            onChange={(selected) => {
              const selectedEventTypes =
                selected as ComboboxItem<EventTypeResponse>[]

              const formattedEventTypes = selectedEventTypes.map(
                (selectedType) => selectedType.value,
              )

              setValue('eventTypes', formattedEventTypes)

              onChange({
                type: TriggerType.EVENT_ARRIVAL,
                parameters: {
                  eventType: formattedEventTypes,
                  sensorTypes,
                },
              })
            }}
            isError={isError}
            isLoading={isFetching}
            onEndReached={fetchNextEventTypesPage}
            onSearch={(search) => setEventTypeFilter(search)}
            multiple
          />
          <Combobox
            label={{ text: 'Tipo de sensor (opcional)' }}
            items={Object.values(SENSOR_TYPE_EN_PT)}
            value={sensorTypes?.map(
              (sensorType) => translatedSensorType[sensorType],
            )}
            placeholder="Selecione um ou mais tipos"
            onChange={(selected) => {
              const selectedSensorTypes = selected as SENSOR_TYPE_EN_PT[]

              const convertedSensorTypes = selectedSensorTypes.map(
                (sensorType) => convertSensorType[sensorType],
              )

              setValue('sensorTypes', convertedSensorTypes)

              onChange({
                type: TriggerType.EVENT_ARRIVAL,
                parameters: {
                  eventType: selectedEventTypes,
                  sensorTypes: convertedSensorTypes,
                },
              })
            }}
            multiple
          />
        </div>
      )}
    </>
  )
}
