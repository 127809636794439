import {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import { Breadcrumbs, Button, ContainerScreen, Input } from 'components'

import {
  FieldError,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form'
import {
  AggregatedAttendPolicyResponse,
  AggregatedRule,
  BranchOperation,
} from 'services/attendancePolicy/types'

import { joiResolver } from '@hookform/resolvers/joi'
import { rulesSchema } from 'domains/attendancePolicy/schema'

import {
  automationTypeForm,
  getTrigger,
} from 'domains/attendancePolicy/components/RuleForm/utilities'

import {
  TriggerFormGroup,
  PolicyVisualization,
  ConditionFormGroup,
  AutomationMenuOptions,
} from 'domains/attendancePolicy/components'

import { ActionFormGroup } from 'domains/attendancePolicy/components/ActionForms/ActionFormGroup/ActionFormGroup'

import styles from './RuleForm.module.scss'
import { AutomationFormKeys } from 'domains/attendancePolicy/components/RuleForm/types'
import { getResumeActions } from 'domains/attendancePolicy/components/RuleForm/utilities/action/action'
import { getBranches } from 'domains/attendancePolicy/components/RuleForm/utilities/condition/condition'

type RuleFormProps = {
  onNavigate: () => void
  rule?: AggregatedRule
}

export const RuleForm = ({ onNavigate, rule }: RuleFormProps) => {
  const { setValue: setMainForm, watch: watchMainForm } =
    useFormContext<AggregatedAttendPolicyResponse>()

  const form = useForm<AggregatedRule>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(rulesSchema),
    defaultValues: {
      index: rule?.index,
      name: rule?.name,
      trigger: rule?.trigger,
      actions: rule?.actions,
      branches: rule?.branches || [],
    },
  })

  const [automationForm, setAutomationForm] =
    useState<AutomationFormKeys>('MENU_OPTIONS')

  const {
    watch,
    register,
    trigger: triggerForm,
    formState: { errors, isValid },
  } = form

  const trigger = watch('trigger')
  const actions = watch('actions')
  const branches = watch('branches')

  const branchOperation = useMemo(() => {
    const [branch] = branches

    return branch?.branchOperation === BranchOperation.AND
  }, [branches])

  const onSubmit = useCallback(() => {
    const prevRules = watchMainForm('rules')
    const rule = watch()

    const isEditedRule = Boolean(rule.index)

    if (!isEditedRule) {
      setMainForm(
        'rules',
        [...prevRules, { ...rule, index: prevRules.length + 1 }],
        { shouldValidate: true },
      )
    } else {
      const updatedRules = prevRules.map((prevRule) =>
        prevRule.index === rule.index ? rule : prevRule,
      )

      setMainForm('rules', updatedRules, { shouldValidate: true })
    }
  }, [setMainForm, watchMainForm, watch])

  const handleNavigateForm = useCallback(() => {
    setAutomationForm('MENU_OPTIONS')
    triggerForm()
  }, [triggerForm])

  const handleRenderForms = useCallback(
    (formKey: AutomationFormKeys) => {
      const forms: Record<AutomationFormKeys, ReactNode> = {
        MENU_OPTIONS: <AutomationMenuOptions onSelect={setAutomationForm} />,
        TRIGGER: <TriggerFormGroup onGoBack={handleNavigateForm} />,
        CONDITION: <ConditionFormGroup onGoBack={handleNavigateForm} />,
        ACTION: <ActionFormGroup onGoBack={handleNavigateForm} />,
      }

      return forms[formKey]
    },
    [handleNavigateForm],
  )

  useLayoutEffect(() => {
    register('index')
    register('trigger')
    register('branches')
    register('actions')
  }, [register])

  return (
    <ContainerScreen
      clickable
      divider={false}
      renderBreadcrumbs={
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Configurações', href: '/#' },
            { title: 'Nova regra', href: '#' },
            { title: 'Nova automação', href: '#' },
          ]}
        />
      }
    >
      <div className={styles.container}>
        <div className={styles.headerSeparator} />
        <div className={styles.formWrapper}>
          <section className={[styles.firstSection].join(' ')}>
            <Input
              label="Nome"
              placeholder="Digite o nome da automação"
              id="name"
              autoComplete="off"
              errorMessage={(errors.name as FieldError)?.message}
              {...register('name')}
              maxLength={60}
            />

            <div className={styles.policySummary}>
              <PolicyVisualization
                selected={automationForm}
                onEdit={(automationTitle) => {
                  setAutomationForm(automationTypeForm[automationTitle])
                }}
                {...(!!trigger && {
                  triggerPolicy: getTrigger(trigger),
                })}
                {...(actions && { actionPolicies: getResumeActions(actions) })}
                {...(branches && {
                  conditionPolicies: getBranches(branches),
                  allConditions: branchOperation,
                })}
              />
            </div>
          </section>
          <section className={styles.secondSection}>
            <FormProvider {...form}>
              {handleRenderForms(automationForm)}
            </FormProvider>
          </section>
        </div>
      </div>

      <div className={styles.footer}>
        {automationForm === 'MENU_OPTIONS' && (
          <>
            <Button
              buttonTitle="Voltar à regra"
              onClick={onNavigate}
              type="secondary"
              width="172px"
            />
            <Button
              buttonTitle="Salvar"
              type="primary"
              width="172px"
              disabled={!isValid}
              onClick={() => {
                onSubmit()
                onNavigate()
              }}
            />
          </>
        )}
      </div>
    </ContainerScreen>
  )
}
