import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'shared/hooks'
import { usePostConfigureSafeCallCommand } from 'shared/hooks/services/commands/usePostConfigureSafeCallCommand'

export const usePostAndHandleConfigureSafeCallCommand = () => {
  const { addToast } = useToast()

  const queryClient = useQueryClient()

  const {
    configureSafeCallCommandMutationStatus,
    mutateConfigureSafeCallCommand,
  } = usePostConfigureSafeCallCommand()

  useEffect(() => {
    if (configureSafeCallCommandMutationStatus === 'success') {
      addToast({
        message: 'Dígito para ligação 190 alterado com sucesso.',
      })

      queryClient.invalidateQueries({ queryKey: ['equipments'] })
    }
    if (configureSafeCallCommandMutationStatus === 'error') {
      addToast({
        message: 'Erro ao alterar dígito para ligação 190. Tente novamente.',
        type: 'alert',
      })
    }
  }, [addToast, configureSafeCallCommandMutationStatus, queryClient])

  return {
    configureSafeCallCommandMutationStatus,
    mutateConfigureSafeCallCommand,
  }
}
