import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { ReactComponent as ZoomIcon } from 'assets/svg/comboSearch.svg'
import { ReactComponent as CameraEmptyState } from 'assets/svg/cameraEmptyStateGrey.svg'
import { PatrimonyWithCustomerAndAccountPayload } from 'services/account/types'
import { useGetAddress } from 'shared/hooks/services/address/useGetAddress'
import { PatrimonyWithAccountRequest } from 'services/patrimony/types'
import { District } from 'services/address/district/types'
import { City } from 'services/address/city/types'
import { State } from 'services/address/state'

import { formatPostalCode } from 'domains/customer/utilities/mask/postalCode'
import { ModalImageView } from 'components/ModalImageView/ModalImageView'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { ToastPatrimonyError, TypeError } from './components'
import { Button, Input, ProtectedImage } from 'components'

import { parseDataToComboboxV2 } from 'utilities/combobox'
import { formatPhone } from 'utilities/masks/phone'
import { PhoneType } from 'services/contact/types'
import { AccountAccordionGroup } from '../index'
import { useToggle } from 'shared/hooks'
import {
  useGetCities,
  useGetDistricts,
  useGetStates,
} from 'shared/hooks/services'

import styles from './PatrimonyForm.module.scss'

type PatrimonyFormProps = {
  title: string
  onSave?: () => void
  onUndo?: () => void
  onGoBack?: () => void
  errorType?: TypeError
}

export const PatrimonyForm: React.FC<PatrimonyFormProps> = ({
  title,
  onSave,
  onUndo,
  onGoBack,
  errorType,
}) => {
  const {
    watch,
    setValue,
    register,
    formState: { isValid },
  } = useFormContext<
    PatrimonyWithCustomerAndAccountPayload | PatrimonyWithAccountRequest
  >()

  const [addressFilters, setAddressFilters] = useState({
    city: '',
    district: '',
    state: '',
  })

  const [postalCodeToSearch, setPostalCodeToSearch] = useState('')

  const city = watch('patrimony.city')
  const state = watch('patrimony.state')
  const postalCode = watch('patrimony.postalCode') || ''
  const patrimonyImageId = watch('patrimony.imageId')
  const phone = watch('patrimony.phone')
  const responsible = watch('patrimony.responsible')

  const persistedPostalCode = postalCode ? String(postalCode) : ''

  const modalImageView = useToggle()

  const { data: address, refetch } = useGetAddress(postalCodeToSearch)

  const {
    states,
    isError: isErrorFetchStates,
    isFetching: isFetchingStates,
    fetchNextPage: fetchNextStatesPage,
  } = useGetStates(true, addressFilters.state)

  const {
    cities,
    fetchNextCitiesPage,
    isError: isErrorFetchCities,
    isFetching: isFetchingCities,
  } = useGetCities(state, addressFilters.city)

  const {
    districts,
    fetchNextDistrictsPage,
    isError: isErrorFetchDistricts,
    isFetching: isFetchingDistricts,
  } = useGetDistricts(city, addressFilters.district)

  useEffect(() => {
    register('patrimony.stateName')
    register('patrimony.cityName')
    register('patrimony.districtName')

    if (address) {
      setValue(
        'patrimony',
        {
          ...watch('patrimony'),
          state: address.state?.id,
          stateName: address.state?.name,
          city: address.city?.id,
          cityName: address.city?.name,
          districtId: address.district?.id,
          districtName: address.district?.name,
          address: address?.address || '',
          number: address?.number || '',
        },
        {
          shouldValidate: true,
        },
      )
    }
  }, [address, register, setValue])

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <section>
          <h1>{title}</h1>
          {errorType && <ToastPatrimonyError type={errorType} />}
          <Input
            value={watch('patrimony.name') || ''}
            label={'Nome'}
            {...register('patrimony.name')}
            onChange={(event) => {
              setValue('patrimony.name', event.target.value, {
                shouldValidate: true,
              })
            }}
          />
          <div className={styles.aggregated}>
            <Input
              className={styles.input}
              label={'Telefone do local (opcional)'}
              maxLength={15}
              value={
                Number(phone?.provinceCode) > 0
                  ? formatPhone(
                      PhoneType.CellPhone,
                      `${phone?.provinceCode}${phone?.phoneNumber}`,
                    )
                  : ''
              }
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '')

                setValue(
                  'patrimony.phone',
                  {
                    countryCode: 55,
                    provinceCode: value.substring(0, 2),
                    phoneNumber: value.substring(2),
                  },
                  {
                    shouldValidate: true,
                  },
                )
              }}
            />
            <Input
              type="text"
              maxLength={100}
              label={'Responsável'}
              className={styles.input}
              {...register('patrimony.responsible')}
              value={responsible || ''}
              onChange={(e) => {
                const value = e.target.value

                setValue('patrimony.responsible', value, {
                  shouldValidate: true,
                })
              }}
            />
          </div>
          <div className={styles.aggregated}>
            <Input
              label="CEP"
              type="text"
              maxLength={10}
              className={styles.postalCode}
              {...register('patrimony.postalCode')}
              value={formatPostalCode(
                postalCodeToSearch || persistedPostalCode,
              )}
              onChange={(event) => {
                const inputValue = event.target.value
                const userInputWithoutMask = inputValue.replace(/\D/g, '')

                setPostalCodeToSearch(userInputWithoutMask)
                setValue('patrimony.postalCode', Number(userInputWithoutMask), {
                  shouldValidate: true,
                })
                userInputWithoutMask.length === 8 && refetch()
              }}
            />
            <Combobox
              label={{ text: 'Estado' }}
              isError={isErrorFetchStates}
              isLoading={isFetchingStates}
              {...register('patrimony.state')}
              onEndReached={fetchNextStatesPage}
              items={parseDataToComboboxV2(states || [], 'name')}
              onChange={(selected) => {
                const selectedState = selected as ComboboxItem<State>
                setValue('patrimony.state', selectedState.value.id, {
                  shouldValidate: true,
                })
                setValue('patrimony.city', undefined, {
                  shouldValidate: true,
                })
                setValue('patrimony.cityName', undefined, {
                  shouldValidate: true,
                })
                setValue('patrimony.districtId', undefined, {
                  shouldValidate: true,
                })
                setValue('patrimony.districtName', undefined, {
                  shouldValidate: true,
                })
              }}
              onSearch={(search) =>
                setAddressFilters((old) => ({ ...old, state: search }))
              }
              value={watch('patrimony.stateName') || ''}
            />
          </div>
          <div className={styles.aggregated}>
            <Combobox
              label={{ text: 'Cidade' }}
              disabled={!state}
              isError={isErrorFetchCities}
              isLoading={isFetchingCities}
              {...register('patrimony.city')}
              onEndReached={fetchNextCitiesPage}
              items={parseDataToComboboxV2(cities || [], 'name')}
              onSearch={(search) =>
                setAddressFilters((old) => ({ ...old, city: search }))
              }
              onChange={(selected) => {
                const selectedCity = selected as ComboboxItem<City>
                setValue('patrimony.city', selectedCity.value.id, {
                  shouldValidate: true,
                })
                setValue('patrimony.cityName', selectedCity.value.name, {
                  shouldValidate: true,
                })
                setValue('patrimony.districtId', undefined, {
                  shouldValidate: true,
                })
                setValue('patrimony.districtName', undefined, {
                  shouldValidate: true,
                })
              }}
              value={watch('patrimony.cityName') || ''}
            />
            <Combobox
              label={{ text: 'Bairro' }}
              disabled={!city}
              isError={isErrorFetchDistricts}
              isLoading={isFetchingDistricts}
              {...register('patrimony.districtId')}
              onEndReached={fetchNextDistrictsPage}
              items={parseDataToComboboxV2(districts || [], 'name')}
              onSearch={(search) =>
                setAddressFilters((old) => ({
                  ...old,
                  district: search,
                }))
              }
              onChange={(selected) => {
                const selectedDistrict = selected as ComboboxItem<District>
                setValue('patrimony.districtId', selectedDistrict.value.id, {
                  shouldValidate: true,
                })

                setValue(
                  'patrimony.districtName',
                  selectedDistrict.value.name,
                  {
                    shouldValidate: true,
                  },
                )
              }}
              value={watch('patrimony.districtName') || ''}
            />
          </div>
          <div className={styles.aggregated}>
            <Input
              label="Logradouro"
              className={styles.address}
              {...register('patrimony.address')}
              value={watch('patrimony.address') || ''}
              onChange={(event) =>
                setValue('patrimony.address', event.target.value, {
                  shouldValidate: true,
                })
              }
            />
            <Input
              label="Número"
              className={styles.number}
              {...register('patrimony.number')}
              value={watch('patrimony.number') || ''}
              onChange={(event) =>
                setValue('patrimony.number', event.target.value, {
                  shouldValidate: true,
                })
              }
            />
          </div>
          <Input
            label="Complemento (opcional)"
            {...register('patrimony.adjunct')}
            onChange={(event) =>
              setValue('patrimony.adjunct', event.target.value, {
                shouldValidate: true,
              })
            }
          />
          <Input
            label="Referências (opcional)"
            {...register('patrimony.reference')}
            maxLength={100}
            onChange={(event) =>
              setValue('patrimony.reference', event.target.value, {
                shouldValidate: true,
              })
            }
          />
          <Input
            id="note"
            label="Observações (opcional)"
            {...register('patrimony.note')}
            onChange={(event) =>
              setValue('patrimony.note', event.target.value, {
                shouldValidate: true,
              })
            }
          />
          {onUndo && (
            <>
              <h2 className={styles.imageLabel}>Foto da fachada</h2>
              {patrimonyImageId ? (
                <>
                  <section className={styles.sectionImage}>
                    <ProtectedImage
                      height={130}
                      className={styles.imagePatrimony}
                      imageId={patrimonyImageId}
                      alt="patrimônio"
                    />
                    <div
                      className={styles.hoverImage}
                      onClick={modalImageView.show}
                    >
                      <ZoomIcon className={styles.zoomIcon} />
                    </div>
                  </section>

                  <ModalImageView
                    isVisible={modalImageView.isVisible}
                    onClose={modalImageView.hide}
                    imageIds={[patrimonyImageId]}
                  />
                </>
              ) : (
                <section className={styles.emptyState}>
                  <CameraEmptyState className={styles.cameraIcon} />
                  <span className={styles.emptyStateLabel}>
                    Foto da fachada pendente. Esta será adicionada após o
                    atendimento da próxima ordem de serviço no local.
                  </span>
                </section>
              )}
            </>
          )}
        </section>
        <section>
          <AccountAccordionGroup />
        </section>
      </div>
      <div className={styles.buttons}>
        {onUndo && (
          <Button
            width="172px"
            type="secondary"
            onClick={onUndo}
            buttonTitle="Desfazer alterações"
          />
        )}
        <div
          className={[styles.divider, onUndo && styles.editButtons]
            .filter(Boolean)
            .join(' ')}
        >
          <Button
            width="172px"
            type="secondary"
            onClick={onGoBack}
            buttonTitle={onUndo ? 'Voltar à pesquisa' : 'Voltar'}
          />
          <Button
            width="172px"
            type="primary"
            onClick={onSave}
            buttonTitle={'Salvar'}
            disabled={!isValid || !watch('hasAccount')}
          />
        </div>
      </div>
    </div>
  )
}
