import { conditionTypeLabel } from 'domains/attendancePolicy/components/RuleForm/utilities/condition/getConditionLabelFragment'

import {
  ConditionOption,
  Policy,
  PolicyGroup,
  SERVICE_ORDER,
} from 'domains/attendancePolicy/types'
import {
  AggregatedBranch,
  AggregatedCondition,
  BranchOperation,
  ConditionType,
  PartitionStatus,
} from 'services/attendancePolicy/types'

export const conditionOption: Record<ConditionType, ConditionOption> = {
  [ConditionType.ACCOUNT_TAGS]: ConditionOption.TAGS,
  [ConditionType.DEVICE_TAGS]: ConditionOption.TAGS,
  [ConditionType.OCCURRENCE_TAGS]: ConditionOption.TAGS,
  [ConditionType.ACCOUNT_TRAVEL_LIMIT]: ConditionOption.ACCOUNT_TRAVEL_LIMIT,
  [ConditionType.OCCURRENCE_EVENTS_MITIGATION]:
    ConditionOption.OCCURRENCE_EVENTS_MITIGATION,
  [ConditionType.OCCURRENCE_HAS_EVENTS]: ConditionOption.OCCURRENCE_HAS_EVENTS,
  [ConditionType.OCCURRENCE_STATE_NAMES]:
    ConditionOption.OCCURRENCE_STATE_NAMES,
  [ConditionType.SERVICE_ORDER_ACCOUNT_TAGS]: ConditionOption.SERVICE_ORDER,
  [ConditionType.SERVICE_ORDER_TYPES]: ConditionOption.SERVICE_ORDER,
  [ConditionType.CENTRAL_PARTITIONS_STATUS]:
    ConditionOption.CENTRAL_PARTITIONS_STATUS,
  [ConditionType.DEVICE_QUANTITY]: ConditionOption.DEVICE_QUANTITY,
  [ConditionType.SERVICE_TYPE]: ConditionOption.SERVICE_TYPE,
  [ConditionType.EVENT_IMAGE_DETECTIONS]:
    ConditionOption.EVENT_IMAGE_DETECTIONS,
  [ConditionType.OCCURRENCE_FINISHED_MANUALLY]:
    ConditionOption.OCCURRENCE_FINISHED_MANUALLY,
}

export const getConditionAccordionTitle = (condition: AggregatedCondition) => {
  const { accordion } = conditionTypeLabel[condition.type](condition)

  return accordion
}

export const getBranches = (
  branches: AggregatedBranch[],
): Policy | PolicyGroup[] | undefined => {
  const hasOnlyOneBranch = branches.length === 1

  if (!branches.length) return undefined

  if (hasOnlyOneBranch) {
    const [branch] = branches
    const hasOnlyOneCondition = branch.conditions.length === 1

    if (hasOnlyOneCondition) {
      const [condition] = branch.conditions

      const { resume } = conditionTypeLabel[condition.type](condition)

      return {
        title: resume,
      }
    }
  }

  const conditionGroup: PolicyGroup[] = branches.map((branch) => ({
    allSubPolicies: branch.conditionsOperation === BranchOperation.AND,
    policies: branch.conditions.map((condition) => {
      const { resume } = conditionTypeLabel[condition.type](condition)

      return {
        title: resume,
      }
    }),
  }))

  return conditionGroup
}

export const serviceOrderOptionType: Record<SERVICE_ORDER, ConditionType> = {
  [SERVICE_ORDER.HAS_TAG]: ConditionType.SERVICE_ORDER_ACCOUNT_TAGS,
  [SERVICE_ORDER.TYPE_OF]: ConditionType.SERVICE_ORDER_TYPES,
}

const eventDeviceOption = {
  EQUALS: 'EQUALS',
  DIFFERENT: 'DIFFERENT',
  ANY: 'ANY',
}

export type EventDeviceOption = keyof typeof eventDeviceOption

export const translatedEventDeviceOption: Record<
  EventDeviceOption,
  { label: string; value: boolean | undefined }
> = {
  EQUALS: {
    label: 'Iguais',
    value: true,
  },
  DIFFERENT: {
    label: 'Diferentes',
    value: false,
  },
  ANY: {
    label: 'Quaisquer',
    value: undefined,
  },
}

export const convertEventDeviceOption: Record<string, EventDeviceOption> = {
  Iguais: 'EQUALS',
  Diferentes: 'DIFFERENT',
  Quaisquer: 'ANY',
}

export const handleEventDeviceOption = (
  option: boolean | undefined,
): string => {
  if (option === undefined) {
    return 'Quaisquer'
  }

  return option ? 'Iguais' : 'Diferentes'
}

export const formattedPartitionStatusTerms: Record<PartitionStatus, string> = {
  ARMED: 'armada(s)',
  DISARMED: 'desarmada(s)',
  STAYED: '',
  TRIGGERED: '',
}
