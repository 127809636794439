import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Combobox } from 'components/ComboboxV2/Combobox'
import { ComboboxItem } from 'components'

import styles from './NotifyForm.module.scss'

import { ActionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'

import { useGetSounds } from 'shared/hooks/services/sound/useGetSounds'
import { SoundResponse } from 'services/sound/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { PlaySound } from 'domains/attendancePolicy/components/ActionForms/PlaySound/PlaySound'
import { NotificationType } from 'services/attendancePolicy/types'
import {
  convertNotificationType,
  translatedNotificationType,
} from 'domains/attendancePolicy/components/RuleForm/utilities/action/utilities'

export const NotifyForm = ({ onChange, data }: ActionOptionForm) => {
  const { parameters } = data

  const { register, watch, setValue } = useForm<{
    sound: SoundResponse | undefined
  }>({
    defaultValues: {
      sound: {
        id: parameters?.sendNotification?.soundId,
        name: parameters?.sendNotification?.soundName,
      },
    },
  })

  const [notificationType, setNotificationType] = useState<
    NotificationType | undefined
  >(parameters?.sendNotification?.type)

  const [soundFilter, setSoundFilter] = useState('')
  const { sounds, isFetching, fetchNextSoundsPage, isError } =
    useGetSounds(soundFilter)

  const sound = watch('sound')

  return (
    <div className={styles.container}>
      <div className={styles.groupedCombobox}>
        <Combobox
          id="notification-type"
          value={
            notificationType
              ? translatedNotificationType[notificationType]
              : undefined
          }
          label={{
            text: 'Notificar via',
          }}
          items={[
            ...Object.values(NotificationType).map(
              (notifyType) => translatedNotificationType[notifyType],
            ),
          ]}
          onChange={(selected) => {
            const selectedNotificationType = selected as NotificationType
            setNotificationType(
              convertNotificationType[selectedNotificationType],
            )
          }}
          placeholder="Selecione um tipo de notificação"
        />

        {notificationType && (
          <div className={styles.soundOption}>
            <div>
              <Combobox
                id="notification-sound"
                label={{
                  text: 'Som da notificação',
                }}
                {...register('sound')}
                value={watch('sound')?.name}
                items={parseDataToComboboxV2(sounds || [], 'name')}
                onChange={(selected) => {
                  const sound = selected as ComboboxItem<SoundResponse>

                  setValue('sound', sound.value, { shouldValidate: true })

                  onChange({
                    parameters: {
                      sendNotification: {
                        type: notificationType,
                        soundId: sound.value.id,
                        soundName: sound.value.name,
                      },
                    },
                  })
                }}
                placeholder="Selecione um tipo de som"
                isLoading={isFetching}
                onEndReached={fetchNextSoundsPage}
                onSearch={(search) => setSoundFilter(search)}
                isError={isError}
              />
            </div>
          </div>
        )}
      </div>

      {notificationType && (
        <div className={styles.buttonWrapper}>
          <PlaySound soundId={sound?.id} />
        </div>
      )}
    </div>
  )
}
