export class JuridicalPersonConstants {
  static minMultiplicator = 2
  static firstDigitIndex = 12
  static secondDigitIndex = 13
  static documentLength = 14
  static personType = 'J'
}

export class NaturalPersonConstants {
  static minMultiplicator = 0
  static firstDigitIndex = 9
  static secondDigitIndex = 10
  static documentLenght = 11
  static personType = 'F'
}

export class LegalEntityConstants {
  static maxMultiplicator = 9
  static module = 11
}

const accountTitleKeys = {
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  CREATED_AT: 'CREATED_AT',
  SECURITY_QUESTION: 'SECURITY_QUESTION',
  SECURITY_ANSWER: 'SECURITY_ANSWER',
  COERCION_ANSWER: 'COERCION_ANSWER',
}

type AccountTitleKey = keyof typeof accountTitleKeys

export type AccountTitlesData = Record<
  AccountTitleKey,
  { title: string; attribute: string; isSecret?: boolean }
>

export const tabsAccountModalKeys = {
  main: 'main',
  notes: 'notes',
  attendanceProfiles: 'attendanceProfiles',
  passwords: 'passwords',
}

export type TabsAccountModalKeys = keyof typeof tabsAccountModalKeys

export const TABS_ACCOUNT_MODAL_KEYS_EN_PT = {
  main: 'Geral',
  notes: 'Anotações',
  attendanceProfiles: 'Perfis de atendimento',
  passwords: 'Senhas',
}
