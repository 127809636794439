import { Column } from 'react-table'

import { alertTypesLabel, occurrenceTypesLabel } from '../../../../types'
import { useTimer } from 'shared/hooks'
import { QuickAlertResponse } from 'services/displacementMap/types'

import styles from './QuickAlerts.module.scss'

interface TimerProps {
  startDate: number
  endDate?: number
}

export const Timer = ({ startDate, endDate }: TimerProps) => {
  const timer = useTimer({ initialTime: startDate, finalTime: endDate })

  return timer
}

export const columns: Column<QuickAlertResponse>[] = [
  {
    id: 'account',
    Header: 'Conta',
    accessor: (row: QuickAlertResponse) =>
      `${row.account.code} - ${row.account.name} `,
  },
  {
    id: 'agent',
    Header: 'Táticos',
    // eslint-disable-next-line react/display-name
    accessor: (row: QuickAlertResponse) => row?.agent?.name,
  },
  {
    id: 'type',
    Header: 'tipo de alerta',
    accessor: (row: QuickAlertResponse) => (
      <span className={[styles.alerts, styles[row.alertType]].join(' ')}>
        {alertTypesLabel[row.alertType]}
      </span>
    ),
  },
  {
    id: 'occurrenceType',
    Header: 'tipo de ocorrência',
    accessor: (row: QuickAlertResponse) => (
      <span
        className={[styles.occurrence, styles[row.occurrence?.typeName]].join(
          ' ',
        )}
      >
        {occurrenceTypesLabel[row.occurrence?.typeName]}
      </span>
    ),
  },
  {
    id: 'user',
    Header: 'Usuário',
    accessor: (row: QuickAlertResponse) => row?.user?.name || '--',
  },
  {
    id: 'alertTime',
    Header: 'tempo de alerta',
    accessor: (row: QuickAlertResponse) => <Timer {...row} />,
  },
]
