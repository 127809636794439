import { OccurrenceResponse } from 'services/occurrence/types'
import { useMutation } from '@tanstack/react-query'
import OccurrenceService from 'services/occurrence'

type CreateAttendPayload = {
  occurrenceId: string
  userId: string
}

async function createAttendance(
  payload: CreateAttendPayload,
): Promise<OccurrenceResponse> {
  return await OccurrenceService.attendByUser(
    payload.occurrenceId,
    payload.userId,
  )
}

export const usePostAndHandleAttendance = () =>
  useMutation({
    mutationFn: async (payload: CreateAttendPayload) =>
      await createAttendance(payload),
  })
