import Joi from '@hapi/joi'

import { PermissionGroupsQueryResponse } from 'services/auth'
import { ATTENDANCE_ROLE, CoverageQueryResponse } from 'services/coverages'

export type UserFilters = {
  userNameFilter: string
  email: string
  phoneExtension: number
  coverage: CoverageQueryResponse
  permissionGroup: PermissionGroupsQueryResponse
  tags: string
  active: boolean
}

export const userFiltersSchema = Joi.object<UserFilters>({
  userNameFilter: Joi.string().optional().allow('', null),
  email: Joi.string().optional(),
  phoneExtension: Joi.number().integer().allow('', null).optional(),
  coverage: Joi.object<CoverageQueryResponse>({
    id: Joi.string().uuid(),
    name: Joi.string(),
    coverageType: Joi.string().valid(...Object.values(ATTENDANCE_ROLE)),
    createdAt: Joi.number().positive(),
    totalUsers: Joi.number().optional(),
    serviceTypeIds: Joi.array()
      .items(Joi.string().allow('', null))
      .optional()
      .allow('', null),
    serviceTypes: Joi.array().items(
      Joi.object({
        id: Joi.string().optional(),
        name: Joi.string().optional(),
      }).optional(),
    ),
  }).optional(),
  permissionGroup: Joi.object<PermissionGroupsQueryResponse>({
    id: Joi.string().optional(),
    name: Joi.string().optional(),
  }).optional(),
  tags: Joi.string().optional(),
  active: Joi.boolean().optional(),
})
