import { InterventionStateEnum } from 'services/occurrence/types'

export enum InterventionTag {
  LIGACAO_CONTATO = 'ligacaoContato',
  ENVIO_MENSAGEM = 'envioMensagem',
  SAFE_CALL = 'safecall',
  LIGACAO_POLICIA = 'ligacaoPolicia',
  VERIFICACAO_IMAGEM = 'verificacaoImagem',
  QUEUED = 'emObservacao',
  FINALIZADA = 'finalizada',
  AVAILABLE = 'disponivel',
  TRAVEL_WAITING = 'deslocamentoEmEspera',
  TRAVEL_STARTED = 'deslocamentoIniciado',
  TRAVEL_FINISHED = 'deslocamentoNoLocal',
  TRAVEL_SENT = 'deslocamentoEnviado',
  TRAVEL_VIEWED = 'deslocamentoVisualizado',
  INTRUSAO_CONFIRMADA = 'intrusaoConfirmada',
  REGISTRO_MANUAL = 'registroManual',
  SEND_IMAGE = 'sendImage',
  HIDDEN = 'oculto',
  IN_PROGRESS = 'emAtendimento',
}

export enum InterventionNoteTag {
  NAO_ENVIA_COMUNICADO = 'naoEnviaComunicado',
  ENCERRADO_DETECTADO_PET = 'encerradoDetectadoPET',
  CONTACTO_SEM_EXITO = 'contatoSemExito',
  NUMERO_INDISPONÍVEL = 'numeroIndisponivel',
  CIENTE_BATERIA_BAIXA = 'cienteBateriaBaixa',
  SEM_ENERGIA = 'semEnergia',
  TUDO_BEM_NO_LOCAL = 'tudoBemNoLocal',
  CONFIRMOU_PALAVRA_CHAVE = 'confirmouPalavraChave',
  NAO_CONFIRMOU_PALAVRA_CHAVE = 'naoConfirmouPalavraChave',
  NAO_ESTA_LOCAL = 'naoEstaLocal',
  PROXIMO_CONTATO = 'proximoContato',
  SOLICITOU_POLICIA = 'solicitouPolicia',
  INFORMOU_INTRUSAO = 'informouIntrusao',
  SOLICITOU_RETORNO_CONTATO = 'solicitouRetornoContato',
  NAO_ATENDE = 'naoAtende',
  OCUPADO = 'ocupado',
  CAIXA_POSTAL = 'caixaPostal',
  TELEFONE_NAO_EXISTE = 'telefoneNaoExiste',
  TELEFONE_INCORRETO = 'telefoneIncorreto',
  COM_DESLOCAMENTO = 'comDeslocamento',
  SEM_DESLOCAMENTO = 'semDeslocamento',
  PESSOA_DETECTADA = 'pessoaDetectada',
  PET_DETECTADO = 'petDetectado',
  SEM_ANORMALIDADE = 'semAnormalidade',
  PROBLEMA_IMAGEM = 'problemaImagem',
  ANORMALIDADE_NO_LOCAL = 'anormalidadeNoLocal',
  SEM_ANORMALIDADE_NO_LOCAL = 'semAnormalidadeNoLocal',
  SEM_RESPOSTA_MODULO = 'semRespostaModulo',
  VALIDACAO_OS = 'validacaoOS',
  POSSUI_OS_ABERTA = 'possuiOSAberta',
  ABERTO_OS = 'abertoOS',
  DISPARO_FALSO = 'disparoFalso',
  MALETA_ESPECIALISTA = 'maletaEspecialista',
  FALHA_COMUNICACAO = 'falhaComunicacao',
  SOMENTE_SHOCK = 'somenteShock',
  DISPARO_SEM_FOTO = 'disparoSemFoto',
  ESPECIALISTA_PEDIU_DESCONSIDERAR = 'especialistaPediuDesconsiderar',
  DEMONSTRACAO_MALETA = 'demonstracaoMaleta',
  DEMONSTRACAO_SUCESSO = 'demonstracaoSucesso',
  DEMONSTRACAO_FALHA = 'demonstracaoFalha',
  ESPECIALISTA_INTERROMPEU_DEMONSTRACAO = 'especialistaInterrompeuDemonstracao',
  ESPECIALISTA_NAO_IDENTIFICOU_CLIENTE = 'especialistaNaoIdentificouCliente',
  ESPECIALISTA_IGNOROU_DEMONSTRACAO = 'especialistaIgnorouDemonstracao',
}

export const FormattedInterventionTag: Record<
  InterventionStateEnum,
  InterventionTag
> = {
  [InterventionStateEnum.TRAVEL_VIEWED]: InterventionTag.TRAVEL_VIEWED,
  [InterventionStateEnum.TRAVEL_STARTED]: InterventionTag.TRAVEL_STARTED,
  [InterventionStateEnum.TRAVEL_FINISHED]: InterventionTag.TRAVEL_FINISHED,
  [InterventionStateEnum.TRAVEL_SENT]: InterventionTag.TRAVEL_SENT,
  [InterventionStateEnum.TRAVEL_WAITING]: InterventionTag.TRAVEL_WAITING,
  [InterventionStateEnum.FINISHED]: InterventionTag.FINALIZADA,
  [InterventionStateEnum.HIDDEN]: InterventionTag.HIDDEN,
  [InterventionStateEnum.IN_PROGRESS]: InterventionTag.IN_PROGRESS,
  [InterventionStateEnum.QUEUED]: InterventionTag.QUEUED,
  [InterventionStateEnum.AVAILABLE]: InterventionTag.AVAILABLE,
}
