import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { OfficeHoursDriver } from 'services/officeHours'

async function deleteOfficeHours(id: string): Promise<void> {
  await OfficeHoursDriver.delete(id)
}

export const useDeleteOfficeHours = (): UseMutationResult<
  void,
  unknown,
  string,
  unknown
> => {
  return useMutation({
    mutationFn: async (id: string) => {
      await deleteOfficeHours(id)
    },
  })
}
