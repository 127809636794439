import { useCallback, memo, FC } from 'react'

import { PartitionOnAttendance } from 'services/attendance/types'

import styles from './styles.module.scss'
import { ActionPlanItem } from './components'

interface PartitionItemProps {
  partition: PartitionOnAttendance
}

const PartitionItem: FC<PartitionItemProps> = memo(({ partition }) => {
  const handleFormatDate = useCallback((unixDate: number) => {
    const date = new Date(unixDate)
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }

    return new Intl.DateTimeFormat('pt-BR', options).format(date)
  }, [])

  return (
    <div className={styles.mainPartition}>
      <section>
        <div>
          <h4>Status</h4>
          <span>
            {partition.status.type === 'D' ? 'Desarmado' : 'Armado'}
            {partition.status.dateTime &&
              ` desde ${handleFormatDate(partition.status.dateTime)}`}
          </span>
        </div>
      </section>
      <section>
        <div>
          <h4>Chave</h4>
          <span>{partition.key || '-'}</span>
        </div>
      </section>
      {partition.security && (
        <section className={styles.securityBox}>
          <span>Palavra-chave</span>
          <div className={styles.security}>
            <div>
              <h4>Senha Orsegups</h4>
              <span>{partition.security.question || '-'}</span>
            </div>
            <div>
              <h4>Senha Cliente</h4>
              <span>{partition.security.answer || '-'}</span>
            </div>
            <div>
              <h4>Senha Coação</h4>
              <span>{partition.security.coercionAnswer || '-'}</span>
            </div>
          </div>
        </section>
      )}
      <section>
        <span>Plano de ação</span>
        <ul>
          {partition.actionPlan?.map((plan) => (
            <ActionPlanItem key={plan.id} actionPlan={plan} />
          ))}
        </ul>
      </section>
    </div>
  )
})

PartitionItem.displayName = 'PartitionItem'

export default PartitionItem
