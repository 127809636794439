import Joi from '@hapi/joi'
import {
  OccurrenceCategoryName,
  OccurrenceTypeQueryResponse,
} from 'services/occurrence/types'

export const occurrenceTypeSchema = Joi.object<OccurrenceTypeQueryResponse>({
  id: Joi.string().uuid(),
  categoryName: Joi.string().valid(...Object.values(OccurrenceCategoryName)),
  defaultStateName: Joi.string(),
  name: Joi.string(),
  priority: Joi.number(),
  severity: Joi.number(),
  temporaryStateName: Joi.string().allow(null).optional(),
  temporaryStatePeriod: Joi.number().allow(null).optional(),
})
