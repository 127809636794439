import { FunctionComponent, SVGProps } from 'react'
import styles from './TriggerCard.module.scss'

interface TriggerCardProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  title?: string
  description?: string
  onClick?: () => void
}

export const TriggerCard = ({
  Icon,
  title,
  description,
  onClick,
}: TriggerCardProps) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <Icon />
      <div className={styles.bodyWrapper}>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  )
}
