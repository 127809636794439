import Joi from '@hapi/joi'

import {
  Vehicle,
  VehicleTracker,
  VEHICLE_CATEGORY_ENUM,
} from '../../../services/vehicle'
import {
  StringSchemeErrorsEnum,
  NumberSchemeErrorsEnum,
} from 'utilities/validation'

export const vehicleTrackerSchema = Joi.object<VehicleTracker>({
  model: Joi.string(),
  serial: Joi.when('model', {
    is: Joi.string().exist().not(null, ''),
    then: Joi.number()
      .integer()
      .required()
      .messages({
        [NumberSchemeErrorsEnum.NUMBER]:
          'Por favor preencha este campo para continuar',
        [StringSchemeErrorsEnum.REQUIRED]:
          'Por favor preencha este campo para continuar',
      }),
  }),
})

export const vehicleSchema = Joi.object<Vehicle>({
  model: Joi.string()
    .required()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]:
        'Por favor preencha este campo para continuar',
      [StringSchemeErrorsEnum.REQUIRED]:
        'Por favor preencha este campo para continuar',
    }),
  modelYear: Joi.number()
    .integer()
    .min(1000)
    .required()
    .messages({
      [NumberSchemeErrorsEnum.NUMBER]:
        'Por favor preencha este campo para continuar',
      [StringSchemeErrorsEnum.REQUIRED]:
        'Por favor preencha este campo para continuar',
      [NumberSchemeErrorsEnum.MIN]: 'Por favor preencha um ano válido',
    }),
  productionYear: Joi.number()
    .integer()
    .min(1000)
    .required()
    .messages({
      [NumberSchemeErrorsEnum.NUMBER]:
        'Por favor preencha este campo para continuar',
      [StringSchemeErrorsEnum.REQUIRED]:
        'Por favor preencha este campo para continuar',
      [NumberSchemeErrorsEnum.MIN]: 'Por favor preencha um ano válido',
    }),
  licensePlate: Joi.string()
    .required()
    .length(7)
    .messages({
      [StringSchemeErrorsEnum.EMPTY]:
        'Por favor preencha este campo para continuar',
      [StringSchemeErrorsEnum.REQUIRED]:
        'Por favor preencha este campo para continuar',
      [StringSchemeErrorsEnum.LENGHT]: 'Por favor preencha uma placa válida',
    }),
  tracker: vehicleTrackerSchema,
  vehicleCategory: Joi.string()
    .valid(...Object.values(VEHICLE_CATEGORY_ENUM))
    .required(),
  iconColor: Joi.string()
    .regex(/^#(?:[0-9a-fA-F]{3}){1,2}([0-9a-fA-F]{2})?$/)
    .required(),
})
