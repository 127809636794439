import { Button, Modal } from 'components'

import styles from './CentralVersions.module.scss'

type CentralVersionsProps = {
  isVisible: boolean
  onClose: () => void
  softwareVersion: {
    software?: string
    firmware?: string
    centralCore?: string
    modelName: string
  }
}

export const CentralVersions = ({
  isVisible,
  onClose,
  softwareVersion,
}: CentralVersionsProps) => {
  if (!softwareVersion) return <div />

  return (
    <>
      <div>
        <Modal
          title="Versões de Software"
          size="sm"
          isVisible={isVisible}
          onClose={onClose}
        >
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.rowItem}>
                <label className={styles.label}>Central-Core</label>

                <p className={styles.infoText}>
                  {softwareVersion.centralCore
                    ? ['Versão', softwareVersion.centralCore].join(' - ')
                    : '-'}
                </p>
              </div>
              <div className={styles.rowItem}>
                <label className={styles.label}>SW</label>
                {softwareVersion.centralCore
                  ? ['Versão', softwareVersion.software].join(' - ')
                  : '-'}
              </div>

              <div className={styles.rowItem}>
                <label className={styles.label}>FW</label>
                {softwareVersion.centralCore
                  ? ['Versão', softwareVersion.firmware].join(' - ')
                  : '-'}
              </div>

              <div className={styles.rowItem}>
                <label className={styles.label}>Modelo</label>
                <p className={styles.infoText}>{softwareVersion.modelName}</p>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button
              width="87px"
              buttonTitle="Voltar"
              type="secondary"
              onClick={onClose}
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
