import { Schedules } from 'domains/customer/screens/Patrol/components/PatrolForm/types'
import {
  DayOfWorkOptions,
  workingDaysOfTheWeek,
} from 'domains/customer/utilities/dayOfWork'

export const handleDaySelectedOption = (
  selectedDay: DayOfWorkOptions,
  schedules: Schedules[],
) => {
  const valueAlreadyExists = schedules.some(
    (scale) => scale.dayOfWork === selectedDay,
  )

  if (valueAlreadyExists)
    return schedules.filter((scale) => scale.dayOfWork !== selectedDay)

  if (selectedDay === 'WORKDAYS') {
    return [
      ...schedules.filter(
        (scale) => !workingDaysOfTheWeek.includes(scale.dayOfWork),
      ),
      {
        dayOfWork: selectedDay,
        periods: [
          {
            hours: '',
            minutes: '',
          },
        ],
      },
    ]
  }

  return [
    ...schedules,
    {
      dayOfWork: selectedDay,
      periods: [
        {
          hours: '',
          minutes: '',
        },
      ],
    },
  ]
}
