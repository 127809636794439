import { ComponentProps } from 'react'

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg'

import styles from './DropDown.module.scss'

interface DropDownProps extends ComponentProps<'ul'> {
  onClose: () => void
  items: {
    label: string
    hasDivider?: boolean
    onClick?: () => void
    subItems?: {
      label: string
      onClick: () => void
    }[]
  }[]
}

export const DropDown = ({
  items,
  className,
  onClose,
  ...props
}: DropDownProps) => (
  <ul
    {...props}
    className={[styles.container, className].join(' ')}
    onMouseLeave={onClose}
  >
    {items.map((item) => (
      <div key={item.label}>
        {item.hasDivider && <hr />}
        <li onClick={item.onClick} className={styles.item}>
          {item.label}
          {item.subItems && (
            <>
              <ArrowIcon />
              <ul>
                {item.subItems.map((subItem) => (
                  <li key={subItem.label} onClick={subItem.onClick}>
                    {subItem.label}
                  </li>
                ))}
              </ul>
            </>
          )}
        </li>
      </div>
    ))}
  </ul>
)
