import { memo } from 'react'
import { OverlayView, Marker as MarkerPrimitive } from '@react-google-maps/api'

import { ReactComponent as OccurrenceIcon } from 'assets/svg/occurrence.svg'
import { ReactComponent as PausedTactical } from 'assets/svg/pause.svg'
import styles from './Marker.module.scss'

import {
  Position,
  TacticalStatus,
  VehicleCategory,
} from 'services/displacementMap/types'
import { icons } from './utils'
import { colors } from '../../utils'
import { OccurrenceTypeName } from 'services/occurrence/types'

interface MarkerProps {
  number?: string
  color?: string
  alert: boolean
  position: Position
  status?: TacticalStatus
  typeName?: OccurrenceTypeName
  vehicle?: {
    category: VehicleCategory
  }
  onAction: () => void
}

export const Marker = memo(
  ({
    typeName,
    alert,
    number,
    color,
    onAction,
    position,
    status,
    vehicle,
  }: MarkerProps) => {
    const handleMarkerIcon = () => {
      if (status === 'PAUSED') return <PausedTactical />

      return icons[vehicle?.category] ?? <OccurrenceIcon />
    }

    return (
      <MarkerPrimitive
        position={{
          lat: position.latitude,
          lng: position.longitude,
        }}
        visible={false}
      >
        <OverlayView
          getPixelPositionOffset={() => ({ x: -21, y: -30 })}
          position={{
            lat: position.latitude,
            lng: position.longitude,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <>
            <div
              className={[
                styles.content,
                status && styles[status],
                status && styles.status,
                alert && styles.alert,
              ].join(' ')}
              onClick={onAction}
            >
              <div
                style={{
                  background: color ?? '#656975',
                }}
                className={styles.icon}
              >
                {handleMarkerIcon()}
              </div>
              {typeName && (
                <div
                  style={{ background: colors[typeName] }}
                  className={styles.label}
                >
                  <span>{number}</span>
                </div>
              )}
            </div>
            <span className={styles.location} />
          </>
        </OverlayView>
      </MarkerPrimitive>
    )
  },
)

Marker.displayName = 'Marker'
