import * as Joi from '@hapi/joi'

import {
  HolidayCoverageType,
  holidayCoverageType,
} from 'services/holiday/types'

export type HolidayFilters = {
  name?: string
  coverageType?: HolidayCoverageType
  repeatable?: boolean
  createdFrom?: number
  createdTo?: number
}

export const holidayFiltersSchema = Joi.object<HolidayFilters>({
  name: Joi.string().allow('').optional(),
  coverageType: Joi.string()
    .valid(...Object.values(holidayCoverageType))
    .optional(),
  repeatable: Joi.boolean().optional(),
  createdFrom: Joi.number().optional(),
  createdTo: Joi.number().optional(),
})
