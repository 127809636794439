import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { ReactComponent as MenuIcon } from 'assets/svg/menuIcon.svg'

import { DisplacementStep, InterventionStates } from 'services/attendance/types'
import { RouteParams } from 'domains/occurrence/screens/Attendance/types'
import { useGetOccurrenceOnAttendance } from 'services/attendance'
import { useModal } from 'domains/occurrence/screens/Attendance/context'

import styles from './styles.module.scss'

type ModalItems = {
  label: string
  displacementStep: DisplacementStep
}

const modalAllOptions: Record<DisplacementStep, ModalItems> = {
  AVAILABLE: {
    label: 'Tornar disponível',
    displacementStep: 'AVAILABLE',
  },
  QUEUED: {
    label: 'Colocar em observação',
    displacementStep: 'QUEUED',
  },
  TRAVEL_FINISHED: {
    label: 'Chegada no local',
    displacementStep: 'TRAVEL_FINISHED',
  },
  TRAVEL_VIEWED: {
    label: 'Solicitação visualizada',
    displacementStep: 'TRAVEL_VIEWED',
  },
  TRAVEL_WAITING: {
    label: 'Enviar para deslocamento',
    displacementStep: 'TRAVEL_WAITING',
  },
  TRAVEL_STARTED: {
    label: 'Trânsito iniciado',
    displacementStep: 'TRAVEL_STARTED',
  },
  FINISHED: {
    label: 'Finalizar',
    displacementStep: 'FINISHED',
  },
}

const handleModalOptions = (
  stateName: InterventionStates,
  vehicleTravelEnabled: boolean,
) => {
  const {
    QUEUED,
    FINISHED,
    AVAILABLE,
    TRAVEL_VIEWED,
    TRAVEL_WAITING,
    TRAVEL_STARTED,
    TRAVEL_FINISHED,
  } = modalAllOptions

  const vehicleTravelEnabledOptions = vehicleTravelEnabled
    ? [QUEUED, TRAVEL_WAITING]
    : [QUEUED]

  const options: Record<InterventionStates, ModalItems[]> = {
    CREATED: [AVAILABLE, QUEUED, TRAVEL_WAITING, FINISHED],
    HIDDEN: [AVAILABLE, QUEUED, TRAVEL_WAITING, FINISHED],
    FINISHED: [],
    QUEUED: [AVAILABLE, FINISHED],
    TRAVEL_WAITING: [QUEUED],
    TRAVEL_FINISHED: [QUEUED, FINISHED],
    AVAILABLE: [...vehicleTravelEnabledOptions, FINISHED],
    IN_PROGRESS: vehicleTravelEnabledOptions,
    TRAVEL_STARTED: [QUEUED, TRAVEL_FINISHED, FINISHED],
    TRAVEL_VIEWED: [QUEUED, TRAVEL_STARTED, TRAVEL_FINISHED, FINISHED],
    TRAVEL_SENT: [
      QUEUED,
      TRAVEL_VIEWED,
      TRAVEL_STARTED,
      TRAVEL_FINISHED,
      FINISHED,
    ],
  }

  return options[stateName]
}

const ChangeState = () => {
  const { occurrenceId } = useParams<RouteParams>()

  const [visible, setVisible] = useState(false)
  const { handleOpenModal } = useModal()

  const { data: occurrence } = useGetOccurrenceOnAttendance(occurrenceId)

  const vehicleTravelEnabled =
    !!occurrence?.occurrence.parameters?.vehicleTravelEnabled

  return (
    <div className={styles.container}>
      <button type="button" onClick={() => setVisible(true)}>
        <MenuIcon />
      </button>
      {visible && (
        <ul onMouseLeave={() => setVisible(false)}>
          {occurrence &&
            handleModalOptions(
              occurrence?.occurrence.stateName,
              vehicleTravelEnabled,
            )?.map((item) => (
              <li
                key={item.displacementStep}
                onClick={() => {
                  if (
                    ['TRAVEL_SENT', 'TRAVEL_VIEWED', 'TRAVEL_STARTED'].includes(
                      occurrence.occurrence.stateName,
                    ) &&
                    ['FINISHED', 'QUEUED'].includes(item.displacementStep)
                  ) {
                    handleOpenModal('CHANGE_STATE', {
                      step: item.displacementStep,
                    })
                  } else {
                    if (item.displacementStep === 'FINISHED') {
                      handleOpenModal('FINISH_OCCURRENCE')
                    } else {
                      handleOpenModal('INTERVENTION_JUSTIFICATION', {
                        step: item.displacementStep,
                      })
                    }
                  }

                  setVisible(false)
                }}
              >
                {item.label}
              </li>
            ))}
        </ul>
      )}
    </div>
  )
}

export default ChangeState
