export enum POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ElementPosition {
  top: number
  left: number
  position: POSITION
}
