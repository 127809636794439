import { ModalV2, Tag } from 'components'
import { useModal } from 'domains/occurrence/screens/Attendance/context'

import styles from './styles.module.scss'

const TagsIncludes = () => {
  const { handleCloseModal, data } = useModal()

  return (
    <ModalV2.Root isOpen onClose={handleCloseModal}>
      <ModalV2.Content size="sm">
        <ModalV2.Title className={styles.title}>
          Tags adicionadas
          <ModalV2.Close />
        </ModalV2.Title>
        <div className={styles.flex}>
          {data.TAGS_INCLUDES?.tags.map((tag) => (
            <Tag key={tag.id} size="md">
              {tag.name}
            </Tag>
          ))}
        </div>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default TagsIncludes
