import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { DeviceDriver } from 'services/device'

async function deleteDevice(id: string): Promise<void> {
  await DeviceDriver.removeDevice(id)
}

export const useDeleteDevice = (): UseMutationResult<
  void,
  unknown,
  string,
  unknown
> => {
  return useMutation({
    mutationFn: (id: string) => deleteDevice(id),
  })
}
