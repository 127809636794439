import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useToast, useUserInfo } from 'shared/hooks'
import {
  Intervention,
  InterventionNote,
  useUpdateNote,
} from 'services/serviceOrderV2'

import Field, { Form } from '../../../../../../../Field'
import UserVerification from '../../../../../UserVerification'

interface EditProps {
  onClose: () => void
  note: InterventionNote
  intervention: Intervention
  images?: string[]
}

const Edit = ({ images, intervention, note, onClose }: EditProps) => {
  const {
    userInfo: { id: userId },
  } = useUserInfo()

  const { addToast } = useToast()
  const { serviceOrderId } = useParams()

  const [formValues, setFormValues] = useState<Form | null>(null)

  const { mutate } = useUpdateNote(serviceOrderId, intervention.id, note.id)

  const handleSubmit = (authorizedBy: string) => {
    if (formValues) {
      const tags = formValues.tags?.map(({ name }) => ({
        name,
      }))

      mutate(
        {
          userId,
          authorizedBy,
          updatedBy: userId,
          note: formValues.note,
          hidden: formValues.hidden,
          ...(tags?.length && {
            tags,
          }),
          images: formValues.images,
        },
        {
          onSuccess: () => {
            addToast({
              message: 'Nota editada com sucesso.',
              type: 'success',
            })
            onClose()
          },
          onError: () => {
            addToast({
              message: 'Erro ao editar nota. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    }
  }

  return (
    <>
      <Field
        onCancel={onClose}
        defaultValues={{
          images,
          tags: note?.tags,
          note: note?.note,
          hidden: !!note.hidden,
        }}
        onSubmit={setFormValues}
      />

      <UserVerification
        isOpen={!!formValues}
        onSubmit={handleSubmit}
        onCancel={() => setFormValues(null)}
      />
    </>
  )
}

export default Edit
