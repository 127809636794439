import { ComponentProps, forwardRef } from 'react'
import fieldStyles from '../field.module.scss'
import styles from './styles.module.scss'

const Input = forwardRef<
  HTMLInputElement,
  Omit<ComponentProps<'input'>, 'type'>
>(({ className, ...props }, ref) => (
  <input
    {...props}
    ref={ref}
    type="number"
    className={[styles.input, fieldStyles.field, className].join(' ')}
  />
))

Input.displayName = 'Input'

export default Input
