import * as Joi from '@hapi/joi'
import { City } from 'services/address/city/types'
import { State } from 'services/address/state/types'
import { District } from 'services/address/district/types'
import { SortedCustomer } from 'services/customer/types'
import { citySchema, districtSchema, stateSchema } from 'shared/schemas'
import { ServiceType } from 'services/serviceType/types'
import { AccountTag } from 'services/account/types'
import { PatrimonyResponse } from 'shared/hooks/patrimonies/types'

export type AccountFilters = {
  code: string
  customer: SortedCustomer
  patrimony: PatrimonyResponse
  customerBySecondName: SortedCustomer
  accountTag: AccountTag
  externalId: string
  city: City
  district: District
  state: State
  createdFrom: number
  createdTo: number
  address: string
  active: boolean
  serviceTypes: Pick<ServiceType, 'id' | 'name'>[]
  contractNumber: string
  centralCode: string
}

export const customerSchema = Joi.object<SortedCustomer>({
  id: Joi.string().uuid().allow('', null),
  name: Joi.string().allow('', null),
  secondName: Joi.string().allow('', null),
  aggregatedName: Joi.string().allow('', null),
  accounts: Joi.array().optional().allow('', null),
}).unknown(true)

export const accountFiltersSchema = Joi.object<AccountFilters>({
  code: Joi.string().optional().allow('', null),
  customer: customerSchema.optional().allow('', null),
  patrimony: Joi.object({
    id: Joi.string().uuid().optional().allow('', null),
    name: Joi.string().optional().allow('', null),
  }).optional(),
  customerBySecondName: customerSchema.optional().allow('', null),
  state: stateSchema.optional().allow('', null).unknown(true),
  city: citySchema.optional().allow('', null).unknown(true),
  district: districtSchema.optional().unknown(true),
  address: Joi.string().optional().allow('', null),
  contractNumber: Joi.string().optional().allow('', null),
  createdFrom: Joi.number().allow('', null),
  createdTo: Joi.number().allow('', null),
  active: Joi.boolean().optional().allow('', null),
  externalId: Joi.string().optional().allow(null, ''),
  serviceTypes: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().uuid().optional().allow('', null),
        name: Joi.string().optional().allow('', null),
      }).unknown(true),
    )
    .optional(),
  accountTag: Joi.object<AccountTag>({
    id: Joi.string().uuid().optional().allow(null, ''),
    name: Joi.string().optional().allow(null, ''),
    type: Joi.string().optional().allow(null, ''),
    totalLinks: Joi.number().optional().allow(null, ''),
    createdAt: Joi.number().optional().allow(null, ''),
    updatedAt: Joi.number().optional().allow(null, ''),
  })
    .optional()
    .allow('', null)
    .unknown(true),
  centralCode: Joi.string().allow(null, '').optional(),
}).unknown(true)
