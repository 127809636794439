type States = 'view' | 'error' | 'loading'

export const handleStateRender = (
  data: unknown,
  isFetched: boolean,
): States => {
  if (data && isFetched) {
    return 'view'
  }

  if (isFetched && !data) {
    return 'error'
  }

  return 'loading'
}
