import { useMemo } from 'react'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'

import EventDriver from 'services/event'
import { EventTypeResponse } from 'services/event/types'

import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'

type UseGetEventTypes = {
  eventTypes: EventTypeResponse[] | undefined
  isFetching: boolean
  isError: boolean
  fetchNextEventTypesPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

const queryEventTypes = async (offset = 0, filter?: string) => {
  const eventTypes = await EventDriver.queryEventTypes({
    recordsPerPage: 15,
    offset,
    ...(filter && { name: filter }),
  })

  return eventTypes
}

export function useGetEventTypes(filter?: string): UseGetEventTypes {
  const {
    isError,
    data,
    isFetching,
    fetchNextPage: fetchNextEventTypesPage,
  } = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['eventTypes', filter],
    queryFn: ({ pageParam }) => queryEventTypes(pageParam, filter),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<EventTypeResponse>(lastPage, allPages),
  })

  const eventTypes = useMemo(
    () => flattenPages<EventTypeResponse>(data),
    [data],
  )

  return {
    isError,
    eventTypes,
    fetchNextEventTypesPage,
    isFetching,
  }
}
