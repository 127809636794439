import { AxiosInstance } from 'axios'
import HttpClient from '../httpClient'

import {
  ServiceOrderAggregatedQueryResponse,
  ServiceOrderResponse,
  ServiceOrderQuery,
  ServiceOrder,
  ServiceOrderStatus,
  ServiceOrderAggregatedResponse,
  ServiceOrderValidationInfo,
  ServiceOrderUpdateRequest,
  TagOutput,
  ServiceOrderTagQuery,
  ServiceOrderWithStatusUpdateRequest,
  AggregatedUserServiceOrders,
  ServiceOrderNoteInput,
  ServiceOrderNoteOutput,
  PersistImagePayload,
  ServiceOrderHistory,
  ServiceOrderAggregatedQuery,
} from './types'

import { Result } from '../types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import buildUrl from 'utilities/buildUrl'
import { endpoints } from './endpoints'

export interface ServiceOrderDriver {
  create(serviceOrder: ServiceOrder): Promise<ServiceOrderResponse>
  update(id: string, data: ServiceOrderUpdateRequest): Promise<void>
  findById(id: string): Promise<ServiceOrderAggregatedResponse>
  query(query: ServiceOrderQuery): Promise<Result<ServiceOrderAggregatedQuery>>
  queryPost(
    query: ServiceOrderQuery,
  ): Promise<Result<ServiceOrderAggregatedQueryResponse>>
  getInProgressServiceOrders(
    query: ServiceOrderQuery,
  ): Promise<Result<AggregatedUserServiceOrders>>
  getInProgressServiceOrdersPost(
    query: ServiceOrderQuery,
  ): Promise<Result<AggregatedUserServiceOrders>>
  getValidationInfo(id: string): Promise<ServiceOrderValidationInfo>
  queryTag(query: ServiceOrderTagQuery): Promise<Result<TagOutput>>
  updateStatus(
    id: string,
    serviceOrderStatus: ServiceOrderStatus,
  ): Promise<ServiceOrderResponse>
  updateServiceOrder(
    id: string,
    serviceOrderStatus: ServiceOrderWithStatusUpdateRequest,
  ): Promise<void>
}

export class ServiceOrderDriverImpl implements ServiceOrderDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async create(
    serviceOrder: ServiceOrder,
  ): Promise<ServiceOrderResponse> {
    return await this.httpClient.post('/serviceOrder/create', serviceOrder)
  }

  public async update(
    id: string,
    data: ServiceOrderUpdateRequest,
  ): Promise<void> {
    return await this.httpClient.put(`/serviceOrder/${id}`, data)
  }

  public async findById(id: string): Promise<ServiceOrderAggregatedResponse> {
    const result = await this.httpClient.get<ServiceOrderAggregatedResponse>(
      `serviceOrder/find/${id}`,
    )
    return result.data
  }

  public async query(
    query: ServiceOrderQuery,
  ): Promise<Result<ServiceOrderAggregatedQuery>> {
    const result = await this.httpClient.get<
      Result<ServiceOrderAggregatedQuery>
    >(`/serviceOrder/query`, {
      params: query,
    })
    return result.data
  }

  public async queryPost(
    query: ServiceOrderQuery,
  ): Promise<Result<ServiceOrderAggregatedQueryResponse>> {
    const result = await this.httpClient.post<
      Result<ServiceOrderAggregatedQueryResponse>
    >(`/serviceOrder/query`, query)

    return result.data
  }

  public async getInProgressServiceOrders(
    query: ServiceOrderQuery,
  ): Promise<Result<AggregatedUserServiceOrders>> {
    const result = await this.httpClient.get<
      Result<AggregatedUserServiceOrders>
    >(`/serviceOrder/inProgress`, {
      params: query,
    })
    return result.data
  }

  public async getInProgressServiceOrdersPost(
    query: ServiceOrderQuery,
  ): Promise<Result<AggregatedUserServiceOrders>> {
    const result = await this.httpClient.post<
      Result<AggregatedUserServiceOrders>
    >(`/serviceOrder/inProgress`, query)

    return result.data
  }

  public async queryTag(
    query: ServiceOrderTagQuery,
  ): Promise<Result<TagOutput>> {
    const result = await this.httpClient.get<Result<TagOutput>>(
      `/serviceOrder/tags/query`,
      {
        params: query,
      },
    )
    return result.data
  }

  public async getValidationInfo(
    id: string,
  ): Promise<ServiceOrderValidationInfo> {
    const result = await this.httpClient.get<ServiceOrderValidationInfo>(
      `/serviceOrder/validate/${id}`,
    )
    return result.data
  }

  public async updateStatus(
    id: string,
    serviceOrderStatus: ServiceOrderStatus,
  ): Promise<ServiceOrderResponse> {
    return await this.httpClient.put(
      `serviceOrder/${id}/status`,
      serviceOrderStatus,
    )
  }

  public async updateServiceOrder(
    id: string,
    data: ServiceOrderWithStatusUpdateRequest,
  ): Promise<void> {
    return await this.httpClient.put(`/serviceOrder/${id}/update`, {
      ...data,
      shouldUpdateStatus: true,
    })
  }
}

export const ServiceOrderDriver = new ServiceOrderDriverImpl()

const useGetServiceOrders = (query: ServiceOrderQuery) =>
  useQuery({
    queryKey: ['get-service-orders', query],
    queryFn: async () => {
      const response = await HttpClient.get<
        Result<ServiceOrderAggregatedQueryResponse>
      >(endpoints.query, {
        params: query,
      })
      return response.data
    },
    enabled: !!query.technicianIds?.length,
  })

const useCreateServiceOrderNote = (serviceOrderId: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['create-serviceOrder-note', serviceOrderId],
    mutationFn: async (payload: ServiceOrderNoteInput) => {
      const response = await HttpClient.post<ServiceOrderNoteInput>(
        buildUrl({
          route: endpoints.createNotes,
          params: { id: serviceOrderId },
        }),
        { ...payload },
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-serviceOrder-notes', serviceOrderId],
      })
    },
  })
}

// userId mantido apenas por conta de retrocompatibilidade
const useGetServiceOrderNotes = (serviceOrderId?: string) => {
  return useQuery({
    queryKey: ['get-serviceOrder-notes', serviceOrderId],
    queryFn: async () => {
      const response = await HttpClient.get<ServiceOrderNoteOutput[]>(
        buildUrl({
          route: endpoints.queryNotes,
          params: { id: serviceOrderId },
        }),
      )

      return response.data
    },
    enabled: !!serviceOrderId,
  })
}

const persistImages = async (payload: PersistImagePayload) => {
  const imageIds: string[] = []

  for await (const image of payload.images) {
    try {
      const formPayload = {
        ...(payload.principalOwner && {
          principalOwner: payload.principalOwner,
        }),
        ...(payload.owners && { owners: payload.owners }),
      }

      const formData = new FormData()

      formData.append('imageFile', image, image.name)
      formData.append('payload', JSON.stringify(formPayload))

      const response = await HttpClient.post(endpoints.persistImage, formData, {
        headers: {
          accept: 'application/json',
          'Content-Type': `multipart/form-data;`,
        },
      })

      imageIds.push(String(response.data))
    } catch (error) {
      throw new Error('Error adding image on service order')
    }
  }
  return imageIds
}

const usePersistImages = (serviceOrderId?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['persist-images', serviceOrderId],
    mutationFn: async (payload: PersistImagePayload) => {
      const response = await persistImages(payload)
      return response
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['service-order-info', serviceOrderId],
      })
    },
  })
}

const useGetServiceOrderHistory = (serviceOrderId?: string) =>
  useQuery({
    queryKey: ['get-serviceOrder-history', serviceOrderId],
    queryFn: async () => {
      const response = await HttpClient.get<ServiceOrderHistory[]>(
        buildUrl({
          route: endpoints.getHistory,
          params: { id: serviceOrderId },
        }),
      )

      return response.data
    },
    enabled: !!serviceOrderId,
  })

const useGetServiceOrder = (serviceOrderId?: string) =>
  useQuery({
    queryKey: ['get-service-order', serviceOrderId],
    queryFn: async () => {
      const response = await HttpClient.get<ServiceOrderAggregatedResponse>(
        buildUrl({
          route: endpoints.find,
          params: { id: serviceOrderId },
        }),
      )
      return response.data
    },
    enabled: !!serviceOrderId,
  })

export {
  useCreateServiceOrderNote,
  useGetServiceOrderNotes,
  usePersistImages,
  useGetServiceOrders,
  useGetServiceOrderHistory,
  useGetServiceOrder,
}
