import { useQuery } from '@tanstack/react-query'
import OccurrenceDriver from 'services/occurrence/index'

async function fetchAttendance(occurrenceId = '') {
  const result = await OccurrenceDriver.findAttendance(occurrenceId)

  return result
}

export function useGetOccurrence(occurrenceId?: string, enabled?: boolean) {
  return useQuery({
    queryKey: ['attendance', occurrenceId],
    queryFn: async () => await fetchAttendance(occurrenceId),
    retry: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    enabled,
  })
}
