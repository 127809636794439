import { Button, Loader, Modal } from 'components'
import { usePostAndHandleWriteCommand } from '../../hooks/services/commands'

import styles from './CentralTypeChange.module.scss'
import { useUserInfo } from 'shared/hooks'

type CentralTypeChangeProps = {
  isVisible: boolean
  onClose: () => void
  onClick: () => void
  central: {
    id: string
    demo: boolean
  }
}

export const CentralTypeChange = ({
  isVisible,
  onClose,
  onClick,
  central,
}: CentralTypeChangeProps) => {
  const { userInfo } = useUserInfo()

  const { mutateWriteCommand, writeCommandMutationStatus } =
    usePostAndHandleWriteCommand(central.id, {
      userId: userInfo.id,
      attributes: {
        demo: !central.demo,
      },
    })

  return (
    <>
      <div>
        <Loader isVisible={writeCommandMutationStatus === 'pending'} />
        <Modal
          title="Alterar modo da central"
          isVisible={isVisible}
          onClose={onClose}
          simple
        >
          <p className={styles.info}>
            {`Você tem certeza que deseja alterar o modo desta central para ${
              !central.demo ? 'Demonstração' : 'Cliente'
            } ?`}
          </p>
          <Modal.Footer>
            <Button
              width="172px"
              buttonTitle="Cancelar"
              type="secondary"
              onClick={onClose}
            />
            <Button
              width="172px"
              buttonTitle="Continuar"
              type="primary"
              onClick={() => {
                mutateWriteCommand({
                  userId: userInfo.id,
                  attributes: {
                    demo: central.demo,
                  },
                })
                onClick()
              }}
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
