import { useMutation, useQueryClient } from '@tanstack/react-query'

import { InterventionPayload } from 'services/occurrence/types'
import HttpClient from '../httpClient'
import buildUrl from '../utils/buildUrl'
import { endpoints } from './endpoints'
import { NotePayload } from './types'

const createIntervention = async (
  occurrenceId: string,
  data: InterventionPayload,
) => {
  const response = await HttpClient.post(
    buildUrl({
      route: endpoints.createIntervention,
      params: { occurrenceId },
    }),
    data,
  )

  return response
}

const createNote = async (
  occurrenceId: string,
  interventionId: string,
  data: NotePayload,
) => {
  const response = await HttpClient.post(
    buildUrl({
      route: endpoints.createNote,
      params: { occurrenceId, interventionId },
    }),
    data,
  )

  return response
}

export const usePostIntervention = (occurrenceId?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: InterventionPayload) => {
      if (occurrenceId) {
        return await createIntervention(occurrenceId, data)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['occurrence-attendance', occurrenceId],
      })
      queryClient.invalidateQueries({
        queryKey: ['occurrence-history', occurrenceId],
      })
    },
  })
}

export const usePostNote = (occurrenceId?: string, interventionId?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: NotePayload) => {
      if (occurrenceId && interventionId) {
        return await createNote(occurrenceId, interventionId, data)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['occurrence-history', occurrenceId],
      })
    },
  })
}
