import {
  useModal,
  useFilter,
  Filter,
} from 'domains/occurrence/screens/Attendance/context'
import { Button, Modal } from 'components'
import { FC } from 'react'

interface ChangePartitionProps {
  newPartition: Filter['partition']
}

const ChangePartition: FC<ChangePartitionProps> = ({ newPartition }) => {
  const { handleCloseModal } = useModal()
  const { handleFilter } = useFilter()

  const onSubmit = () => {
    handleFilter({
      partition: newPartition,
    })

    handleCloseModal()
  }

  return (
    <Modal
      isVisible
      onClose={handleCloseModal}
      title="Alterar partição"
      size="sm"
      simple
    >
      <p>
        Existe um filtro ativo na linha do tempo desta ocorrência. Ao selecionar
        uma outra partição, este filtro será removido. Deseja continuar?
      </p>
      <Modal.Footer>
        <Button
          width="186px"
          buttonTitle="Cancelar"
          onClick={handleCloseModal}
          type="secondary"
        />
        <Button
          width="186px"
          type="cancel-primary"
          onClick={onSubmit}
          buttonTitle="Alterar"
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePartition
