import { ReactNode } from 'react'

import './pagination.styles.scss'
import { useSearchParams } from 'react-router-dom'

export interface PaginationProps {
  totalPages: number
}

const handleVisiblePageNumbers = (
  totalPages: number,
  maxVisiblePages: number,
  currentPage: number,
) => {
  const calcVisiblePages = Math.floor(maxVisiblePages / 2)

  let lastVisiblePage = maxVisiblePages

  if (currentPage + calcVisiblePages >= totalPages) {
    lastVisiblePage = totalPages
  } else if (currentPage > calcVisiblePages) {
    lastVisiblePage = currentPage + calcVisiblePages
  }

  const firstVisiblePage = lastVisiblePage - maxVisiblePages + 1

  return { firstVisiblePage, lastVisiblePage }
}

export const Pagination = ({ totalPages }: PaginationProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = Number(searchParams.get('page')) || 1

  const renderPage = () => {
    const result: ReactNode[] = []
    const maxVisiblePages = totalPages < 5 ? totalPages : 5

    const { firstVisiblePage, lastVisiblePage } = handleVisiblePageNumbers(
      totalPages,
      maxVisiblePages,
      currentPage,
    )

    for (let index = firstVisiblePage; index <= lastVisiblePage; index++) {
      result.push(
        <button
          key={index}
          data-testid="pagination-page"
          onClick={() => handleChangePage(index)}
          className={[
            'pagination-number-button',
            currentPage === index ? '--pagination-active' : '',
          ].join(' ')}
        >
          {index}
        </button>,
      )
    }
    return result
  }

  const handleChangePage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: String(page),
    })
  }

  return (
    <div className="pagination-wrapper" data-testid="pagination">
      <button
        className="pagination-text-button"
        id="first-button"
        data-testid="first-button"
        onClick={() => handleChangePage(1)}
        disabled={currentPage === 1}
      >
        Primeira
      </button>
      <button
        data-testid="previous-button"
        className="previous-button"
        disabled={currentPage === 1}
        onClick={() => handleChangePage(currentPage - 1)}
      >
        <svg
          className="previous-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 5.857 9.919"
        >
          <path
            d="M453.825,748.967a.966.966,0,0,1-.69-.291,1,1,0,0,1,0-1.4l3.215-3.266-3.215-3.266a1,1,0,0,1,0-1.4.965.965,0,0,1,1.38,0l3.9,3.968a1,1,0,0,1,0,1.4l-3.9,3.968A.966.966,0,0,1,453.825,748.967Z"
            transform="translate(-452.849 -739.048)"
          />
        </svg>
      </button>
      {renderPage()}
      <button
        data-testid="next-button"
        className="next-button"
        disabled={currentPage === totalPages}
        onClick={() => handleChangePage(currentPage + 1)}
      >
        <svg
          className="next-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 5.857 9.919"
        >
          <path
            d="M453.825,748.967a.966.966,0,0,1-.69-.291,1,1,0,0,1,0-1.4l3.215-3.266-3.215-3.266a1,1,0,0,1,0-1.4.965.965,0,0,1,1.38,0l3.9,3.968a1,1,0,0,1,0,1.4l-3.9,3.968A.966.966,0,0,1,453.825,748.967Z"
            transform="translate(-452.849 -739.048)"
          />
        </svg>
      </button>
      <button
        className="pagination-text-button"
        id="last-button"
        onClick={() => handleChangePage(totalPages)}
        disabled={currentPage === totalPages}
      >
        Última
      </button>
    </div>
  )
}

export default Pagination
