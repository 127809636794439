import { useEffect, useMemo } from 'react'
import {
  Button,
  Checkbox,
  Combobox,
  ComboboxItem,
  Loader,
  Modal,
  ProtectedButton,
} from 'components'
import styles from './DeviceShockSensor.module.scss'
import { DeviceType } from 'services/partition/types'
import { useForm } from 'react-hook-form'
import {
  profilesQuerySchema,
  ProfilesQuerySchema,
} from 'domains/customer/screens/Equipments/schemas/devices/profilesQuery'
import { joiResolver } from '@hookform/resolvers/joi'
import { usePostAndHandleConfigureDoorCommand } from 'domains/customer/screens/Equipments/hooks/services/commands/usePostAndHandleConfigureDoorCommand'
import { Profile } from 'domains/customer/screens/Equipments/types'
import { useGetDeviceTypeProfiles } from 'shared/hooks/services/device/useGetDeviceTypeProfiles'
import { UbideskPermissions } from 'routes/types'
import { PartitionDeviceAggregatedResponse } from 'services/device/types'

type DeviceShockSensorProps = {
  isVisible: boolean
  reedswitch: PartitionDeviceAggregatedResponse
  centralId: string
  onClose: () => void
}

export const DeviceShockSensor = ({
  isVisible,
  onClose,
  reedswitch,
  centralId,
}: DeviceShockSensorProps) => {
  const { register, unregister, watch, setValue, handleSubmit, formState } =
    useForm<ProfilesQuerySchema>({
      resolver: joiResolver(profilesQuerySchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        enableAccelerometer: reedswitch.accelerometerEnabled,
        ...(reedswitch.profiles.shock && {
          profile: {
            id: reedswitch.profiles.shock.id,
            name: reedswitch.profiles.shock.name,
          },
        }),
      },
    })

  const profile = watch('profile')
  const enableAccelerometer = watch('enableAccelerometer')

  const { configureDoorCommandMutationStatus, mutateConfidureDoorCommand } =
    usePostAndHandleConfigureDoorCommand()

  const { deviceProfiles, isFetchingDeviceProfiles } = useGetDeviceTypeProfiles(
    DeviceType.ReedSwitch,
    isVisible,
  )

  const comboboxProfiles = useMemo(() => {
    if (!deviceProfiles) return []

    const formattedProfiles: ComboboxItem<Profile>[] = deviceProfiles.map(
      (profile) => ({
        label: 'name',
        value: { id: profile.id, name: profile.name },
      }),
    )

    return formattedProfiles
  }, [deviceProfiles])

  const onSave = (data: ProfilesQuerySchema) => {
    mutateConfidureDoorCommand({
      centralId,
      deviceId: reedswitch.id,
      enableAccelerometer: data.enableAccelerometer,
      ...(data.enableAccelerometer && {
        profileId: data.profile.id,
      }),
    })
    onClose()
  }

  useEffect(() => {
    register('profile')
    register('enableAccelerometer')

    return () => {
      unregister('profile')
      unregister('enableAccelerometer')
    }
  }, [register, unregister])

  return (
    <div>
      <Loader
        isVisible={
          configureDoorCommandMutationStatus === 'pending' ||
          isFetchingDeviceProfiles
        }
      />
      <Modal
        title="Ajustar sensor shock"
        isVisible={isVisible}
        onClose={onClose}
      >
        <div className={styles.wrapper}>
          <Checkbox
            id="shock-sensor"
            label="Ativar acelerômetro"
            checked={enableAccelerometer}
            onChange={(e) => {
              setValue('enableAccelerometer', e.target.checked, {
                shouldDirty: true,
              })
            }}
          />
          <Combobox
            value={
              profile
                ? {
                    label: 'name',
                    value: profile,
                  }
                : undefined
            }
            label={'Sensibilidade'}
            items={comboboxProfiles}
            disabled={!enableAccelerometer}
            getSelected={(selected) => {
              const profile = selected as ComboboxItem<Profile>
              if (profile)
                setValue('profile', profile.value, {
                  shouldDirty: true,
                })
            }}
          />
        </div>
        <Modal.Footer>
          <Button
            id="shock-sensor-cancel-button"
            width="172px"
            buttonTitle="Voltar"
            type="secondary"
            onClick={onClose}
          />
          <ProtectedButton
            permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
            id="shock-sensor-save-button"
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            onClick={handleSubmit(onSave)}
            disabled={!formState.isValid && !formState.isDirty}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
