import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'shared/hooks'
import { usePostConfigureDoorCommand } from 'shared/hooks/services/commands/usePostConfigureDoorCommand'

export const usePostAndHandleConfigureDoorCommand = () => {
  const { addToast } = useToast()

  const queryClient = useQueryClient()

  const { configureDoorCommandMutationStatus, mutateConfidureDoorCommand } =
    usePostConfigureDoorCommand()

  useEffect(() => {
    if (configureDoorCommandMutationStatus === 'success') {
      addToast({
        message: 'Shock ajustado com sucesso.',
      })

      queryClient.invalidateQueries({ queryKey: ['equipments'] })
    }
    if (configureDoorCommandMutationStatus === 'error') {
      addToast({
        message: 'Erro ao ajustar shock. Tente novamente.',
        type: 'alert',
      })
    }
  }, [addToast, configureDoorCommandMutationStatus, queryClient])

  return {
    configureDoorCommandMutationStatus,
    mutateConfidureDoorCommand,
  }
}
