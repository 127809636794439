import { useMutation } from '@tanstack/react-query'

import {
  ServiceOrderDriver,
  ServiceOrderWithStatusUpdateRequest,
} from 'services/serviceOrder'

function updateServiceOrder(data: ServiceOrderWithStatusUpdateRequest) {
  const { id, ...payload } = data
  return ServiceOrderDriver.updateServiceOrder(id || '', payload)
}

export const usePutServiceOrder = () => {
  const { mutate: mutatePutServiceOrder, status: updateServiceOrderStatus } =
    useMutation({
      mutationFn: async (data: ServiceOrderWithStatusUpdateRequest) => {
        return await updateServiceOrder(data)
      },
    })

  return { mutatePutServiceOrder, updateServiceOrderStatus }
}
