import React, { useCallback, useMemo } from 'react'

import { ReactComponent as Exclamation } from '../../../../assets/exclamation.svg'

import { Checkbox, IconWithTooltip } from 'components'
import { useFormContext } from 'react-hook-form'

import { PatrolFormData } from 'domains/customer/screens/Patrol/components/PatrolForm/types'
import {
  dayOfWorkIndexPosition,
  dayOfWorkKeys,
  translatedDayOfWork,
  workingDaysOfTheWeek,
} from 'domains/customer/utilities/dayOfWork'

import { ScheduleTimeConfig } from 'domains/customer/screens/Patrol/components/PatrolForm/components'
import { handleDaySelectedOption } from 'domains/customer/screens/Patrol/components/PatrolForm/components/Scale/utilities'

import styles from './styles.module.scss'

const Scale: React.FC = () => {
  const { watch, setValue, register } = useFormContext<PatrolFormData>()

  const schedules = watch('schedules')

  const dayOfWork = useMemo(() => {
    return schedules?.map((scale) => scale.dayOfWork)
  }, [schedules])

  const isWorkDaySelected = useMemo(
    () => dayOfWork.includes('WORKDAYS'),
    [dayOfWork],
  )

  const selectedAllDaysOfTheWeek = useMemo(() => {
    const allDaysPresent = workingDaysOfTheWeek.every((day) =>
      dayOfWork.includes(day),
    )

    return allDaysPresent
  }, [dayOfWork])

  const checkSelectedDay = useCallback(
    (dayOption: string) => {
      const selectedDay = dayOfWorkKeys[dayOption]

      return dayOfWork.includes(selectedDay)
    },
    [dayOfWork],
  )

  const handleDisableCheckBox = useCallback(
    (day: string) => {
      if (selectedAllDaysOfTheWeek && dayOfWorkKeys[day] === 'WORKDAYS') {
        return true
      }

      if (!isWorkDaySelected) return false

      return workingDaysOfTheWeek.includes(dayOfWorkKeys[day])
    },
    [selectedAllDaysOfTheWeek, isWorkDaySelected],
  )

  return (
    <div className={styles.container}>
      <section className={styles.leftSection}>
        <div>
          <h3>Dia da semana</h3>
          <div className={styles.dayOfWorkOptions}>
            {Object.values(translatedDayOfWork).map((day) => {
              return (
                <div key={day} className={styles.checkBoxContainer}>
                  <Checkbox
                    id={day}
                    disabled={handleDisableCheckBox(day)}
                    checked={checkSelectedDay(day)}
                    small
                    label={day}
                    onChange={() => {
                      const selectedDay = dayOfWorkKeys[day]

                      const selectedDays = handleDaySelectedOption(
                        selectedDay,
                        schedules,
                      )

                      setValue('schedules', selectedDays, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }}
                  />

                  {dayOfWorkKeys[day] === 'HOLIDAY' && (
                    <div className={styles.iconInfo}>
                      <IconWithTooltip
                        text="Feriados cadastrados via sistema"
                        Icon={Exclamation}
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section className={styles.rightSection} {...register('schedules')}>
        {schedules
          ?.sort(
            (a, b) =>
              dayOfWorkIndexPosition[a.dayOfWork] -
              dayOfWorkIndexPosition[b.dayOfWork],
          )
          ?.map((schedule, index) => {
            return (
              <ScheduleTimeConfig
                key={index}
                dayOfWork={schedule.dayOfWork}
                periods={schedule.periods || []}
                onChangeScale={(changedScale) => {
                  const filteredScales = schedules.filter(
                    (scale) => scale.dayOfWork !== changedScale.dayOfWork,
                  )

                  setValue('schedules', [...filteredScales, changedScale], {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }}
              />
            )
          })}
      </section>
    </div>
  )
}

export default Scale
