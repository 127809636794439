import * as Joi from '@hapi/joi'
import { WifiConfigurationCommandPayload } from 'services/command/types'
import { ConnectionIPMode } from 'services/serviceOrder'
import { StringSchemeErrorsEnum } from 'utilities/validation'

export const centralWifiConfigurationSchema =
  Joi.object<WifiConfigurationCommandPayload>({
    wifiIpMode: Joi.number()
      .valid(...Object.values(ConnectionIPMode))
      .required(),
    wifiSsid: Joi.string()
      .required()
      .messages({
        [StringSchemeErrorsEnum.EMPTY]: 'Campo obrigatório',
      }),
    wifiPassword: Joi.string()
      .required()
      .messages({
        [StringSchemeErrorsEnum.EMPTY]: 'Campo obrigatório',
      }),
  })
