import './emptyState.scss'
import Icon from 'components/Icon'

export type ErrorTypes =
  | 'EmptyEquipment'
  | 'ErrorToLoadInfos'
  | 'EmptyDataFromBFF'
  | 'EmptyDataFromGivenFilter'
  | 'EmptyOccurrence'
  | 'Default'
export interface EmptyStateProps {
  id?: string
  type: ErrorTypes
}

export const EmptyState = (props: EmptyStateProps) => {
  const { type } = props

  const getInfos = () => {
    const infos = {
      ErrorToLoadInfos: {
        icon: (
          <Icon
            name="warning"
            color="medium-gray"
            className="emptyState-icon displaced-top"
            width={120}
            height={120}
          />
        ),
        iconOrientationStyle: 'emptyState-circle',
        primaryButtonTitle: 'Tentar novamente',
        secondaryButtonTitle: 'Tela inicial',
        titleMessage: 'Ocorreu um erro',
        messages: [
          'Houve um problema ao carregar',
          'as informações, tente novamente.',
        ],
      },
      EmptyDataFromBFF: {
        icon: (
          <Icon name="search" color="medium-gray" width={120} height={120} />
        ),
        iconOrientationStyle: 'emptyState-circle emptyState-icon-displaced',
        primaryButtonTitle: 'Tela inicial',
        secondaryButtonTitle: 'Voltar',
        titleMessage: 'Sem resultados',
        messages: ['Nenhum registro foi encontrado nesta seção.'],
      },
      EmptyDataFromGivenFilter: {
        icon: (
          <Icon name="search" width={120} height={120} color="medium-gray" />
        ),
        iconOrientationStyle: 'emptyState-circle emptyState-icon-displaced',
        primaryButtonTitle: 'Tela inicial',
        secondaryButtonTitle: 'Voltar',
        titleMessage: 'Sem resultados',
        messages: [
          'Nenhum registro foi encontrado utilizando os filtros selecionados.',
        ],
      },
      EmptyOccurrence: {
        icon: (
          <Icon name="search" width={172} height={172} color="medium-gray" />
        ),
        iconOrientationStyle: 'emptyState-circle',
        primaryButtonTitle: 'Tela inicial',
        secondaryButtonTitle: 'Voltar',
        titleMessage: 'Recarregue a página',
        messages: ['Uma nova ocorrência será carregada aqui'],
      },
      EmptyEquipment: {
        icon: (
          <Icon name="device" width={182} height={182} color="medium-gray" />
        ),
        iconOrientationStyle: 'emptyState-circle emptyState-icon-displaced',
        primaryButtonTitle: 'Tela inicial',
        secondaryButtonTitle: 'Voltar',
        titleMessage: 'Não há equipamentos instalados',
        messages: ['Aguarde a execução da ordem de serviço'],
      },
      Default: {
        icon: null,
        iconOrientationStyle: '',
        primaryButtonTitle: '',
        secondaryButtonTitle: '',
        titleMessage: '',
        messages: [''],
      },
    }
    return infos[type]
  }

  return (
    <>
      {type === 'Default' ? null : (
        <div className="emptyState-card">
          <div className={getInfos().iconOrientationStyle}>
            {getInfos().icon}
          </div>
          <h1
            id="title-001-user"
            className="emptyState-text emptyState-title"
            aria-label="titleLabel"
          >
            {getInfos().titleMessage}
          </h1>
          {getInfos().messages.map((message) => {
            return (
              <p className="emptyState-text emptyState-subtitle " key={message}>
                {message}
              </p>
            )
          })}
        </div>
      )}
    </>
  )
}

export default EmptyState
