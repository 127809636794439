import { ComboboxItem } from './../../Combobox/Combobox'

import { ReactComponent as IconMiniX } from '../../../assets/svg/miniX.svg'
import { getItemValue } from '../utilities'

import styles from './MultiSelectTags.module.scss'

type MultiSelectTagsProps = {
  items: (string | ComboboxItem<string>)[]
  onRemoveItem: (item: string | ComboboxItem<string>) => void
  placeholder?: string
}

const MultiSelectTags = ({
  items,
  onRemoveItem,
  placeholder,
}: MultiSelectTagsProps) => (
  <ul className={styles.contentWrapper}>
    {items.length ? (
      items.map((item, index) => (
        <li
          data-testid="multi-selected-item"
          className={styles.innerContentWrapper}
          key={index}
        >
          <p className={styles.innerContentText}>{getItemValue(item)}</p>
          <IconMiniX
            role="img"
            className={styles.deleteIcon}
            onClick={(e) => {
              e.stopPropagation()
              onRemoveItem && onRemoveItem(item)
            }}
          />
        </li>
      ))
    ) : (
      <li data-testid="multi-selected-item" className={styles.placeholder}>
        {placeholder}
      </li>
    )}
  </ul>
)

export default MultiSelectTags
