import * as Joi from '@hapi/joi'

export interface TechnicianScheduleForm {
  id: string
  technician: {
    id: string
    name: string
  }
  reason: string
  startDate: number
  startTime?: string
  endDate: number
  endTime?: string
  note?: string
}

export const formSchema = Joi.object<TechnicianScheduleForm>({
  id: Joi.string().uuid().optional(),
  technician: Joi.object({
    id: Joi.string().uuid(),
    name: Joi.string().required(),
  }),
  reason: Joi.string().max(100).required(),
  note: Joi.string().allow('', null),
  startDate: Joi.number().required(),
  startTime: Joi.string().allow('').optional(),
  endDate: Joi.number().required(),
  endTime: Joi.string().allow('').optional(),
})
