import { useQuery, UseQueryResult } from '@tanstack/react-query'
import HttpClient from 'services/httpClient'
import { GetStreamsResponse } from 'services/occurrence/types'
import { endpoints } from 'services/occurrence/endpoints'
import buildUrl from 'utilities/buildUrl'

export function useGetStreams(
  accountId?: string,
): UseQueryResult<GetStreamsResponse> {
  return useQuery({
    queryKey: ['streams', accountId],
    queryFn: async () => {
      const response = await HttpClient.get<GetStreamsResponse>(
        buildUrl({
          route: endpoints.getStreams,
          params: { accountId },
        }),
      )
      return response.data
    },
    enabled: !!accountId,
  })
}
