import { useState, useEffect, useMemo } from 'react'

import { ReactComponent as Logo } from 'assets/svg/logo.svg'
import { ReactComponent as ExpiredLinkIcon } from 'assets/svg/passwordManagementScreenExpiredLinkIcon.svg'
import { ReactComponent as InvalidLinkIcon } from 'assets/svg/passwordManagementScreenInvalidLinkIcon.svg'
import { ReactComponent as SuccessIcon } from 'assets/svg/passwordManagementScreenSuccessIcon.svg'
import { ReactComponent as InvalidRuleIcon } from 'assets/svg/passwordManagementScreenInvalidRuleIcon.svg'
import { ReactComponent as ValidRuleIcon } from 'assets/svg/passwordManagementScreenValidRuleIcon.svg'
import { ReactComponent as InternalErrorIcon } from 'assets/svg/passwordManagementScreenInternalErrorIcon.svg'

import { Input, Button, Loader } from 'components'
import { PasswordSchema } from '../../schemas'

import { useForm } from 'react-hook-form'
import { AuthDriver, TokenError } from 'services/auth'
import { useToggle } from 'shared/hooks'

import styles from './PasswordManagement.module.scss'
import { useLocation } from 'react-router-dom'

enum TOKEN_STATUS {
  'EXPIRED' = 'expired_token',
  'INVALID' = 'invalid_token',
}

export const PasswordManagementScreen = () => {
  const [expiredToken, setExpiredToken] = useState(false)
  const [invalidToken, setInvalidToken] = useState(false)

  const [successfullyRedefined, setSuccessfullyRedefined] = useState(false)
  const [failToRedefine, setFailToRedefine] = useState(false)

  const [successfullySentNewLink, setSuccessfullySentNewLink] = useState(false)
  const [failToSentNewLink, setFailToSentNewLink] = useState(false)

  const [canShowInputsToSaveOrRedefine, setCanShowInputsToSaveOrRedefine] =
    useState(false)

  const [isCheckingToken, setCheckingToken] = useState(true)

  const { setValue, watch, register, handleSubmit } = useForm<PasswordSchema>({
    mode: 'all',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })

  const location = useLocation()
  const loader = useToggle()

  const { password, passwordConfirmation } = watch()

  const checkTokenErrorType = (error: TokenError) => {
    if (error.response?.data?.error === TOKEN_STATUS.INVALID) {
      setInvalidToken(true)
    } else if (error.response?.data?.error === TOKEN_STATUS.EXPIRED) {
      setExpiredToken(true)
    } else {
      setFailToRedefine(true)
    }
  }

  const handleSavePassword = async () => {
    const [token] = location.pathname.split('/').reverse()

    try {
      loader.show()
      await AuthDriver.savePassword({
        token,
        newPassword: password,
        confirmNewPassword: passwordConfirmation,
      })
      setSuccessfullyRedefined(true)
    } catch (error) {
      checkTokenErrorType(error as TokenError)
    } finally {
      loader.hide()
    }
  }

  const handleNewLinkRequest = async () => {
    const email = new URLSearchParams(location.search).get('email') || ''

    loader.show()
    try {
      await AuthDriver.resetPassword(email)
      setSuccessfullySentNewLink(true)
      setExpiredToken(false)
    } catch (error) {
      setExpiredToken(false)
      setFailToSentNewLink(true)
    } finally {
      loader.hide()
    }
  }

  useEffect(() => {
    if (
      !expiredToken &&
      !invalidToken &&
      !successfullyRedefined &&
      !failToRedefine &&
      !successfullySentNewLink &&
      !failToSentNewLink
    ) {
      setCanShowInputsToSaveOrRedefine(true)
    } else {
      setCanShowInputsToSaveOrRedefine(false)
    }
  }, [
    expiredToken,
    invalidToken,
    successfullyRedefined,
    failToRedefine,
    successfullySentNewLink,
    failToSentNewLink,
  ])

  useEffect(() => {
    const validateToken = async () => {
      const [token] = location.pathname.split('/').reverse()
      try {
        await AuthDriver.validateToken({ token })
      } catch (error) {
        checkTokenErrorType(error as TokenError)
      } finally {
        setCheckingToken(false)
      }
    }

    validateToken()
  }, [location])

  const passwordErrors = useMemo(
    () => [
      {
        validate: password.length >= 8,
        message: 'Mínimo de oito caracteres',
      },
      {
        validate: /\d/.test(password),
        message: 'Mínimo uma número',
      },
      {
        validate: /.*[a-z]/.test(password),
        message: 'Mínimo uma letra maiúscula',
      },
      {
        validate: /.*[A-Z]/.test(password),
        message: 'Mínimo uma letra minúscula',
      },
      {
        validate: /\W|_/g.test(password),
        message: 'Mínimo um caractere especial (@*!%:.)',
      },
      {
        validate: password === passwordConfirmation && !!password,
        message: 'A confirmação de senha não confere',
      },
    ],
    [password, passwordConfirmation],
  )

  return (
    <div
      className={[
        styles.container,
        invalidToken || expiredToken ? styles.resetPasswordError : '',
      ].join(' ')}
    >
      <Loader isVisible={loader.isVisible} />
      <div className={styles.wrapper}>
        <Logo />
        {canShowInputsToSaveOrRedefine && !isCheckingToken && (
          <>
            <form onSubmit={handleSubmit(handleSavePassword)}>
              <span className={styles.title}>
                {location.pathname.includes('/save-password')
                  ? 'Cadastre sua senha'
                  : 'Redefina sua senha'}
              </span>
              <Input
                password
                {...register('password')}
                onChange={(event) =>
                  setValue('password', event.target.value, {
                    shouldValidate: true,
                  })
                }
                label="Defina uma nova senha"
                placeholder="Defina uma nova senha"
              />
              <div className={styles.passwordContainer}>
                <Input
                  password
                  {...register('passwordConfirmation')}
                  onChange={(event) =>
                    setValue('passwordConfirmation', event.target.value, {
                      shouldValidate: true,
                    })
                  }
                  label="Confirme sua nova senha"
                  placeholder="Confirme sua nova senha"
                />
                <div className={styles.errors}>
                  {passwordErrors.map(({ validate, message }) => (
                    <span
                      key={message}
                      className={[
                        styles.feedback,
                        password && styles[validate ? 'sucess' : 'error'],
                      ].join(' ')}
                    >
                      {password ? (
                        validate ? (
                          <ValidRuleIcon />
                        ) : (
                          <InvalidRuleIcon />
                        )
                      ) : (
                        <span style={{ width: 9 }}>•</span>
                      )}
                      {message}
                    </span>
                  ))}
                </div>
              </div>
              <Button
                htmlType="submit"
                type="primary"
                buttonTitle="Salvar"
                width="134px"
                disabled={Object.values(passwordErrors).some(
                  (error) => !error.validate,
                )}
              />
            </form>
          </>
        )}
        {successfullySentNewLink && (
          <>
            <div className={styles.resetPasswordSuccessContainer}>
              <div>
                <SuccessIcon />
              </div>
              <h1 className={styles.resetPasswordInvalidLinkHeader}>
                {'LINK ENVIADO'}
              </h1>
            </div>
          </>
        )}
        {failToSentNewLink && (
          <>
            <div className={styles.resetPasswordInvalidLinkContainer}>
              <div>
                <InternalErrorIcon />
              </div>
              <h1 className={styles.resetPasswordInvalidLinkHeader}>
                ERRO INTERNO
              </h1>
              <p className={styles.resetPasswordInvalidLinkText}>
                Não foi possível gerar um
              </p>
              <p className={styles.resetPasswordInvalidLinkText}>
                {location.pathname.includes('/save-password')
                  ? 'novo link para cadastrar sua'
                  : 'novo link para redefinir sua'}
              </p>
              <p className={styles.resetPasswordInvalidLinkText}>
                nova senha. Por gentileza,
              </p>
              <p className={styles.resetPasswordInvalidLinkText}>
                tente novamente.
              </p>
            </div>
          </>
        )}
        {successfullyRedefined && (
          <>
            <div className={styles.resetPasswordSuccessContainer}>
              <div>
                <SuccessIcon />
              </div>
              <h1 className={styles.resetPasswordInvalidLinkHeader}>
                {location.pathname.includes('/save-password')
                  ? 'SENHA CADASTRADA'
                  : 'SENHA REDEFINIDA'}
              </h1>
            </div>
          </>
        )}
        {failToRedefine && (
          <>
            <div className={styles.resetPasswordInvalidLinkContainer}>
              <div>
                <InternalErrorIcon />
              </div>
              <h1 className={styles.resetPasswordInvalidLinkHeader}>
                ERRO INTERNO
              </h1>
              <p className={styles.resetPasswordInvalidLinkText}>
                {location.pathname.includes('/save-password')
                  ? ' Não foi possível cadastrar sua'
                  : ' Não foi possível redefinir sua'}
              </p>
              <p className={styles.resetPasswordInvalidLinkText}>
                nova senha. Por gentileza,
              </p>
              <p className={styles.resetPasswordInvalidLinkText}>
                tente novamente.
              </p>
            </div>
          </>
        )}
        {expiredToken && (
          <>
            <div className={styles.resetPasswordExpiredLinkContainer}>
              <div>
                <ExpiredLinkIcon />
              </div>
              <h1 className={styles.resetPasswordInvalidLinkHeader}>
                LINK EXPIRADO
              </h1>
              <p className={styles.resetPasswordInvalidLinkText}>
                Solicite um novo link
              </p>
              <p className={styles.resetPasswordInvalidLinkText}>
                {location.pathname.includes('/save-password')
                  ? 'e cadastre sua senha'
                  : 'e redefina sua senha'}
              </p>
              <p className={styles.resetPasswordInvalidLinkText}>
                dentro de 24 horas.
              </p>
              <div className={styles.resetPasswordExpiredLinkButton}>
                <Button
                  type="primary"
                  id="reset-password-action-button"
                  onClick={handleSubmit(handleNewLinkRequest)}
                  buttonTitle="Novo Link"
                />
              </div>
            </div>
          </>
        )}
        {invalidToken && (
          <>
            <div className={styles.resetPasswordInvalidLinkContainer}>
              <div>
                <InvalidLinkIcon />
              </div>
              <h1 className={styles.resetPasswordInvalidLinkHeader}>
                LINK INVÁLIDO
              </h1>
              <p className={styles.resetPasswordInvalidLinkText}>
                {location.pathname.includes('/save-password')
                  ? 'Você já cadastrou sua'
                  : 'Você já redefiniu sua'}
              </p>
              <p className={styles.resetPasswordInvalidLinkText}>
                senha com este link.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PasswordManagementScreen
