import { Breadcrumbs, ContainerScreen, Loader } from 'components'

import { useLocation, useNavigate } from 'react-router-dom'
import { useToast } from 'shared/hooks'

import { CreateCentralPayload } from 'services/central/types'
import { useGetCentral, usePatchCentral } from 'services/central'
import ImageDriver from 'services/image'
import { CentralForm } from 'domains/customer/components/CentralForm/CentralForm'
import { useQueryClient } from '@tanstack/react-query'

export const UpdateCentral = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const { mutatePatchCentral, statusPatchCentral } = usePatchCentral(
    location.state.centralId,
  )

  const { data: centralInfo } = useGetCentral(location.state.centralId)
  const queryClient = useQueryClient()

  const customerName = localStorage.getItem('customerName') || ''
  const customerId = localStorage.getItem('customerId') || ''
  const patrimonyId = localStorage.getItem('patrimonyId') || ''

  const submit = (payload: CreateCentralPayload) => {
    if (payload.central) {
      mutatePatchCentral(
        {
          contacts: payload.contacts.map((contact) => ({
            contactId: contact.id,
            code: contact.code,
          })),
          central: {
            code: payload.central.code?.replace(/:/g, '') || '',
            externalId: payload.central.externalId || '',
            topicGroup: payload.central.topicGroup || '',
            model: payload.central.model,
          },
          partitions: payload.partitions,
        },
        {
          onSuccess: async (data) => {
            if (payload.image) {
              try {
                await ImageDriver.persist(payload.image, {
                  principalOwner: {
                    id: location.state.centralId,
                    name: 'Central',
                  },
                  owners: [
                    {
                      id: customerId,
                      name: 'Customer',
                    },
                    {
                      id: patrimonyId,
                      name: 'Patrimony',
                    },
                    {
                      id: payload.account?.id,
                      name: 'Account',
                    },
                    {
                      id: data.centralId,
                      name: 'Central',
                    },
                    {
                      id: data.centralId,
                      name: 'InstalledDevice',
                    },
                  ],
                })
              } catch (error) {
                console.debug('Erro ao cadastrar imagem')
              }
            }
            addToast({
              message: 'Central cadastrada com sucesso.',
              type: 'success',
            })
            queryClient.invalidateQueries({ queryKey: ['central'] })
            navigate('/account/management/central')
          },
          onError: () =>
            addToast({
              message: 'Erro ao cadastrar central. Tente novamente.',
              error: true,
              type: 'alert',
            }),
        },
      )
    }
  }

  if (!centralInfo || statusPatchCentral === 'pending')
    return <Loader isVisible />

  return (
    <ContainerScreen
      clickable
      renderBreadcrumbs={
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Cliente', href: '/account/management/account' },
            { title: `${customerName}`, href: '/account/management/account' },
            {
              title: `${centralInfo ? 'EDIÇÃO DE CENTRAL' : 'NOVA CENTRAL'}`,
              href: '#',
            },
          ]}
        />
      }
    >
      <CentralForm
        centralToUpdate={centralInfo}
        onSubmit={submit}
        accountId={centralInfo.central.accountId}
      />
    </ContainerScreen>
  )
}
