import styles from './AutomationList.module.scss'
import { ReactComponent as PlusSignIcon } from 'assets/svg/plusSign.svg'
import { Button } from 'components'
import { DragAndDrop } from './components/DragAndDropAutomation/DragAndDropAutomation'
import {
  AggregatedAttendPolicyResponse,
  AggregatedRule,
} from 'services/attendancePolicy/types'
import { useFormContext } from 'react-hook-form'
export interface Item {
  id: string
  title: string
}
interface AutomationListProps {
  items?: AggregatedRule[]
  onAddNewAutomation?: () => void
  onDelete?: (item: AggregatedRule) => void
  onEdit?: (item: Item) => void
  onCopy?: (item: Item) => void
}

export const AutomationList = (props: AutomationListProps) => {
  const { onAddNewAutomation, items = [], onDelete, onCopy, onEdit } = props

  const { setValue } = useFormContext<AggregatedAttendPolicyResponse>()

  const getItem = (item: AggregatedRule): Item => ({
    id: String(item.index),
    title: item.name,
  })

  const handleChange = (newItems: AggregatedRule[]) => {
    setValue(
      'rules',
      newItems.map((item, index) => ({ ...item, index: index + 1 })),
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.listHead}>
        <h1 className={styles.text}>Automações</h1>
        <Button
          buttonTitle="Adicionar nova automação"
          type="tertiary"
          icon={PlusSignIcon}
          onClick={onAddNewAutomation}
        />
      </div>

      {items.length ? (
        <DragAndDrop
          items={items}
          getItem={getItem}
          onChange={handleChange}
          onDelete={onDelete}
          onCopy={onCopy}
          onEdit={onEdit}
          draggable
        />
      ) : null}
    </div>
  )
}
