import { DetectLabel } from 'services/image/types'

const LabelTranslations: { [key: string]: string } = {
  Person: 'Person',
  Pet: 'Pet',
}

function translateLabel(label: DetectLabel) {
  if (LabelTranslations[label.name]) {
    return LabelTranslations[label.name]
  }

  const validParent = label.parents.find(
    (parent) => !!LabelTranslations[parent.name],
  )

  if (validParent) {
    return LabelTranslations[validParent.name]
  }

  return label.name
}

export const formatDetectLabels = (labels: DetectLabel[]): DetectLabel[] => {
  return labels
    ? labels.map((label: DetectLabel, labelIndex: number) => ({
        ...label,
        name: translateLabel(label),
        instances: label.instances.map((instance, instanceIndex) => ({
          ...instance,
          id: `${labelIndex}-${instanceIndex}`,
        })),
      }))
    : []
}
