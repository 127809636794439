import Joi from '@hapi/joi'
import {
  VehicleQuery,
  VEHICLE_CATEGORY_ENUM,
  VEHICLE_TYPE_ENUM,
} from 'services/vehicle'

export const vehicleQuerySchema = Joi.object<VehicleQuery>({
  description: Joi.string().max(256).optional(),
  licensePlate: Joi.string().max(7).optional().allow('', null),
  vehicleCategory: Joi.string()
    .valid(...Object.values(VEHICLE_CATEGORY_ENUM))
    .optional(),
  vehicleType: Joi.string()
    .valid(...Object.values(VEHICLE_TYPE_ENUM))
    .optional(),
  coverageId: Joi.string().uuid().optional(),
  trackerSerial: Joi.number().integer().optional(),
})
