import {
  SERVICE_ORDER,
  ServiceOrder,
  DISLOCATION_LIMIT,
  DislocationLimit,
  EVENT,
  Event,
} from 'domains/attendancePolicy/types'
import { Evaluation } from 'services/serviceOrder'

const ServiceOrderType: Record<string, ServiceOrder> = {
  [SERVICE_ORDER.TYPE_OF]: {
    label: SERVICE_ORDER.TYPE_OF,
    value: 'Do tipo',
  },

  [SERVICE_ORDER.HAS_TAG]: {
    label: SERVICE_ORDER.HAS_TAG,
    value: 'Contém tag',
  },
}

const DislocationLimitType: Record<string, DislocationLimit> = {
  [DISLOCATION_LIMIT.REACHED]: {
    label: DISLOCATION_LIMIT.REACHED,
    value: 'Atingido',
  },

  [DISLOCATION_LIMIT.NOT_REACHED]: {
    label: DISLOCATION_LIMIT.NOT_REACHED,
    value: 'Não atingido',
  },
}

const EventType: Record<string, Event> = {
  [EVENT.MITIGATED]: {
    label: EVENT.MITIGATED,
    value: 'Mitigado',
  },

  [EVENT.NOT_MITIGATED]: {
    label: EVENT.NOT_MITIGATED,
    value: 'Não Mitigado',
  },
}

const OccurrenceFinishTypeLabel: Record<string, Evaluation> = {
  Manual: 'HAS',
  Automática: 'HASNT',
}

export const handleServiceOrderForm = (status: SERVICE_ORDER): ServiceOrder =>
  ServiceOrderType[status]

export const handleDislocationLimitForm = (
  status: DISLOCATION_LIMIT,
): DislocationLimit => DislocationLimitType[status]

export const handleMitigationEventForm = (status: EVENT): Event =>
  EventType[status]

export const handleOccurrenceFinishForm = (status: string): Evaluation =>
  OccurrenceFinishTypeLabel[status]
