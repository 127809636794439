import Joi from '@hapi/joi'
import { StringSchemeErrorsEnum } from 'utilities/validation'

export const loginSchema = Joi.object({
  login: Joi.string()
    .required()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]: 'O Usuário precisa ser preenchido',
    }),
  password: Joi.string()
    .required()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]: 'A Senha precisa ser preenchida',
    }),
})
