import { FC, forwardRef, Ref, useState } from 'react'
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { ReactComponent as Trash } from 'assets/svg/trash.svg'
import { ReactComponent as ActionPlan } from 'assets/svg/actionPlanBlue.svg'
import { ReactComponent as Remove } from 'assets/svg/remove.svg'
import { Modal, Button, ComboboxItem } from 'components'
import styles from './DragAndDropContacts.module.scss'
import { ReactComponent as DragAndDropIcon } from 'domains/attendancePolicy/assets/svg/DragAndDrop.svg'
import { getContactTypeLabel } from 'domains/customer/utilities/contact'
import { ContactType } from 'services/attendance/types'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { PartitionContacts } from 'services/central/types'
import { ContactSelected } from 'domains/customer/screens/Partitions/screens/Partition/types'

interface DragAndDropItem {
  id: string
  title: string
  subtitle?: string
  type?: 'contacts' | 'actionPlan'
  email?: string
  phone?: string
  icon?: string
  onEdit?: () => void
  onChangeRemoteControl?: () => void
  onCopy?: () => void
  isDragDisabled?: boolean
}
interface DragAndDropItemProps extends Omit<DragAndDropItem, 'id'> {
  index: number
  handleOpen?: () => void
  [key: string]: unknown
}

interface DragAndDropItemProps extends Omit<DragAndDropItem, 'id'> {
  index: number
  handleOpen?: () => void
  [key: string]: unknown
}

const DragAndDropItem: FC<DragAndDropItemProps> = forwardRef(
  (props: DragAndDropItemProps, ref: Ref<HTMLDivElement>) => {
    const {
      title,
      subtitle,
      icon,
      email,
      phone,
      index,
      type,
      onEdit,
      isDragDisabled = false,
      handleOpen,
      ...rest
    } = props

    return (
      <div
        ref={ref}
        className={[styles.item].join(' ')}
        data-testid={`draggable-item`}
        {...rest}
      >
        <div className={styles.leftContent}>
          {!isDragDisabled && (
            <DragAndDropIcon
              className={[styles.icon, styles.leftItem].join(' ')}
            />
          )}
          <span className={styles.order}>
            {!isDragDisabled && `${index + 1}º`}{' '}
          </span>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>
              {title}
              {icon && (
                <img
                  className={styles.controlIcon}
                  src={icon}
                  alt="Contato possui controle remoto"
                />
              )}
            </span>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            {(!!phone || !!email) && (
              <div className={styles.info}>
                {phone && <span>{phone}</span>}
                {email && <span> • {email}</span>}
              </div>
            )}
          </div>
        </div>

        <div className={styles.rightContent}>
          {onEdit && (
            <button
              type="button"
              data-testid={`edit-button-${index}`}
              onClick={onEdit}
              className={styles.hoverIcon}
            >
              <ActionPlan />
            </button>
          )}
          {handleOpen && (
            <button
              type="button"
              data-testid={`delete-button-${index}`}
              onClick={handleOpen}
              className={styles.hoverIcon}
            >
              {type === 'contacts' ? <Trash /> : <Remove />}
            </button>
          )}
        </div>
      </div>
    )
  },
)

DragAndDropItem.displayName = 'DragAndDropItem'

interface DragAndDropProps<T> {
  items: T[]
  getItem: (item: T) => DragAndDropItem
  onChange?: (items: T[], itemIndex?: number) => void
  onDelete?: (item: T) => void
  onCopy?: (item: DragAndDropItem) => void
  onEdit?: (item: DragAndDropItem) => void
  onChangeRemoteControl?: (
    item: DragAndDropItem,
    oldContact: DragAndDropItem,
  ) => void
  draggable?: boolean
  canDelete?: boolean
  type?: 'actionPlan' | 'contacts'
}

export function DragAndDrop<T>({
  items,
  getItem,
  onChange,
  onDelete,
  onEdit,
  onCopy,
  onChangeRemoteControl,
  type,
  draggable = true,
  canDelete = true,
}: DragAndDropProps<T>): JSX.Element {
  const handleReorderItems = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems = [...items]
    const [removed] = newItems.splice(result.source.index, 1)

    newItems.splice(result.destination.index, 0, removed)

    if (onChange) {
      onChange(newItems)
    }
  }

  const getVerticalDraggingStyle = (
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => {
    if (!draggableStyle) return

    const { transform } = draggableStyle
    let activeTransform = {}
    if (transform) {
      activeTransform = {
        transform: `translate(0, ${transform.substring(
          transform.indexOf(',') + 1,
          transform.indexOf(')'),
        )})`,
      }
    }

    return {
      userSelect: 'none',
      ...draggableStyle,
      ...activeTransform,
    }
  }

  const [isVisible, setIsVisible] = useState(false)
  const [isVisibleActionPlan, setIsVisibleActionPlan] = useState(false)
  const [currentItem, setCurrentItem] = useState<T | null>(null)
  const [isVisibleHandleControl, setIsVisibleHandleControl] = useState(false)
  const [modalContactIndex, setModalContactIndex] = useState(0)
  const [selectedContact, setSelectedContact] =
    useState<ContactSelected | null>(null)
  const [contactToRemoveControl, setContactToRemoveControl] =
    useState<ContactSelected | null>(null)

  const handleAddActionPlan = (item: T) => {
    setCurrentItem(item)
    setIsVisibleActionPlan(true)
  }

  const handleCloseActionPlan = () => {
    setIsVisibleActionPlan(false)
  }

  const handleOpen = (item: T, hasRemoteControl: boolean) => {
    setCurrentItem(item)

    if (type === 'contacts' && hasRemoteControl) {
      setContactToRemoveControl(item)
      setIsVisibleHandleControl(true)
    } else {
      setIsVisible(true)
    }
  }

  const handleClose = () => {
    setIsVisible(false)
    setIsVisibleActionPlan(false)
    setIsVisibleHandleControl(false)
    setModalContactIndex(0)
  }

  return (
    <DragDropContext onDragEnd={handleReorderItems}>
      <Droppable droppableId="drag-and-drop">
        {(droppableProvided) => (
          <div className={styles.wrapper}>
            <div
              className={styles.container}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {items
                .sort((a, b) => a.priorityOrder - b.priorityOrder)
                .map((rawItem, index) => {
                  const item = getItem(rawItem)

                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={!draggable}
                    >
                      {(draggableProvided) => (
                        <DragAndDropItem
                          {...(onDelete && {
                            handleOpen: () => handleOpen(rawItem, !!item.icon),
                          })}
                          {...(onEdit && {
                            onEdit: () => handleAddActionPlan(rawItem),
                          })}
                          index={index}
                          icon={item.icon}
                          email={item.email}
                          phone={item.phone}
                          title={item.title}
                          subtitle={item.subtitle || ''}
                          type={type}
                          isDragDisabled={!draggable}
                          onCopy={onCopy ? () => onCopy?.(item) : undefined}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={getVerticalDraggingStyle(
                            draggableProvided.draggableProps.style,
                          )}
                        />
                      )}
                    </Draggable>
                  )
                })}

              {droppableProvided.placeholder}
            </div>
          </div>
        )}
      </Droppable>

      <Modal
        simple
        onClose={handleClose}
        title={
          type === 'contacts' ? 'Excluir contato?' : 'Remover do plano de ação?'
        }
        isVisible={isVisible}
      >
        <p>
          {type === 'actionPlan'
            ? 'Esse contato não será mais acionado em caso de ocorrências relacionadas à conta.'
            : 'Este contato será removido desta partição e não poderá mais fazer parte do seu plano de ação.'}
        </p>
        <Modal.Footer>
          <Button
            width="180px"
            onClick={handleClose}
            id="btn-secondary"
            buttonTitle="Cancelar"
            type="secondary"
          />
          {canDelete && (
            <Button
              data-testid="btn-primary"
              width="180px"
              id="btn-primary"
              buttonTitle="Remover"
              type="cancel-primary"
              onClick={() => {
                if (onDelete && currentItem) {
                  onDelete(currentItem)
                }
                handleClose()
              }}
            />
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        simple
        onClose={handleClose}
        title={'Adicionar ao plano de ação?'}
        isVisible={isVisibleActionPlan}
      >
        <p>
          Este contato poderá ser acionado em caso de ocorrências relacionadas à
          conta.
        </p>
        <Modal.Footer>
          <Button
            width="180px"
            onClick={handleClose}
            id="btn-secondary"
            buttonTitle="Cancelar"
            type="secondary"
          />
          {canDelete && (
            <Button
              data-testid="btn-primary"
              width="180px"
              id="btn-primary"
              buttonTitle="Adicionar"
              type="primary"
              onClick={() => {
                if (onEdit && currentItem) {
                  onEdit(currentItem)
                }
                handleCloseActionPlan()
              }}
            />
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        simple
        onClose={handleClose}
        title={
          modalContactIndex
            ? 'Vincular controle a outro contato'
            : 'Contato vinculado a um controle'
        }
        isVisible={isVisibleHandleControl}
      >
        {modalContactIndex ? (
          <Combobox
            items={parseDataToComboboxV2(
              items.filter((contact) => !contact.remoteControls?.length),
              'name',
            )}
            itemLabel={(item) => {
              const contact = item as ComboboxItem<PartitionContacts>
              return getContactTypeLabel(contact.value.type as ContactType)
            }}
            onChange={(item) => setSelectedContact(item.value)}
          />
        ) : (
          <p>
            Este contato está vinculado a um controle. Para excluí-lo, é <br />
            necessário associá-lo a outro contato.
          </p>
        )}

        <Modal.Footer>
          <Button
            width="180px"
            onClick={handleClose}
            buttonTitle="Cancelar"
            type="secondary"
          />
          <Button
            width="180px"
            buttonTitle={modalContactIndex ? 'Salvar' : 'Avançar'}
            type="primary"
            onClick={() => {
              if (modalContactIndex) {
                onChangeRemoteControl?.(selectedContact, contactToRemoveControl)
                setIsVisibleHandleControl(false)
              } else setModalContactIndex(1)

              handleCloseActionPlan()
            }}
          />
        </Modal.Footer>
      </Modal>
    </DragDropContext>
  )
}
