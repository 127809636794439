import {
  ReactElement,
  FC,
  useState,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { Toast } from 'components'
import { ToastType } from 'components/Toast/types'

interface ToastProps {
  message: string
  error?: boolean
  type?: ToastType
  autohideTimeout?: number
}

interface ToastContextData {
  addToast: (toastProps: ToastProps) => void
  clearToast: () => void
  toast: ToastProps | null
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

export const ToastProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const emptyData = {
    message: '',
  }
  const [toast, setToast] = useState<ToastProps>(emptyData)
  const [isVisible, setIsVisible] = useState(false)

  const addToast = useCallback((toast: ToastProps) => {
    setToast(toast)
    setIsVisible(true)
  }, [])

  const clearToast = useCallback(() => {
    setIsVisible(false)
  }, [])

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        clearToast()
      }, toast.autohideTimeout || 4000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [isVisible, clearToast, toast.autohideTimeout])

  return (
    <ToastContext.Provider
      value={{
        toast,
        addToast,
        clearToast,
      }}
    >
      {children}
      <Toast isVisible={isVisible} onClose={clearToast} type={toast.type}>
        {toast?.message}
      </Toast>
    </ToastContext.Provider>
  )
}

export function useToast(): ToastContextData {
  const context = useContext(ToastContext)
  if (!context)
    throw new Error('You need to use ToastContext within a ToastProvider')

  return context
}
