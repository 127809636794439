import { useQuery } from '@tanstack/react-query'
import HttpClient from 'services/httpClient'
import { GetOccurrence } from 'services/occurrence/types'
import { endpoints } from 'services/occurrence/endpoints'
import buildUrl from 'utilities/buildUrl'

export function useGetOccurrence(occurrenceId = '', interval: number) {
  return useQuery({
    queryKey: ['get-occurrence-for-cftv', occurrenceId],
    queryFn: async () => {
      const response = await HttpClient.get<GetOccurrence>(
        buildUrl({
          route: endpoints.getForCFTV,
          params: { occurrenceId },
        }),
      )

      return response.data
    },
    enabled: !!occurrenceId,
    refetchInterval: interval,
  })
}
