import { useMutation } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import buildUrl from 'utilities/buildUrl'

export const getPermissions = async (userId?: string) => {
  const response = await HttpClient.get(
    buildUrl({ route: endpoints.getPermissions, params: { userId } }),
  )

  return response.data
}

export const useGetPermissions = () => {
  return useMutation({
    mutationFn: async (userId?: string) => await getPermissions(userId),
  })
}
