import { useQuery } from '@tanstack/react-query'
import SoundDriver from 'services/sound/index'

async function fetchSoundFile(soundId: string) {
  const result = await SoundDriver.getSound(soundId)

  return result
}

export function useGetSoundFile(soundId: string) {
  const { data, isFetching } = useQuery<string>({
    queryKey: ['sound', soundId],
    queryFn: () => fetchSoundFile(soundId),
    staleTime: 1000 * 60 * 60,

    enabled: !!soundId,
  })

  return { data, isFetching }
}
