export function dynamicSort<T>(property: keyof T): (a: T, b: T) => number {
  const sortOrder = 1

  return function (a: T, b: T): number {
    // works with strings and numbers,
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}
