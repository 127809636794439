export const formatPostalCode = (value: string): string => {
  let postalCodeWithMask
  const inputLenght = value.length

  switch (true) {
    case inputLenght >= 2 && inputLenght <= 5:
      postalCodeWithMask = value.replace(/(\d{2})(\d{1,})/g, '$1.$2')
      break
    default:
      postalCodeWithMask = value.replace(/(\d{2})(\d{3})(\d{1,})/g, '$1.$2-$3')
  }
  return postalCodeWithMask
}
