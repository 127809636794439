import Joi from '@hapi/joi'
import { AccountFragment, Fragment, PatrolFiltersSchema } from './types'

const fragmentSchema = Joi.object<Fragment>({
  id: Joi.string(),
  name: Joi.string(),
})

const accountFragmentSchema = Joi.object<AccountFragment>({
  id: Joi.string(),
  code: Joi.string(),
  aggregatedAccountName: Joi.string(),
})

export const patrolFiltersSchema = Joi.object<PatrolFiltersSchema>({
  name: Joi.string().optional().allow(null, ''),
  account: accountFragmentSchema.optional().allow(null, ''),
  patrimony: fragmentSchema.optional().allow(null, ''),
  active: Joi.boolean().optional().allow(null, ''),
  startDateFrom: Joi.string().optional().allow(null, ''),
  startDateTo: Joi.string().optional().allow(null, ''),
  endDateFrom: Joi.string().optional().allow(null, ''),
  endDateTo: Joi.string().optional().allow(null, ''),
  createdFrom: Joi.string().optional().allow(null, ''),
  createdTo: Joi.string().optional().allow(null, ''),
})
