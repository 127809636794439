import { Paging, Timestamps } from 'services/types'

export enum VEHICLE_CATEGORY_ENUM {
  CAR = 'CAR',
  MOTORCYCLE = 'MOTORCYCLE',
}

export enum VEHICLE_TYPE_ENUM {
  TACTICAL = 'TACTICAL',
  TECHNICAL = 'TECHNICAL',
}

export enum VEHICLE_IGNITION_STATUS_ENUM {
  ON = 'ON',
  OFF = 'OFF',
}

export enum VEHICLE_CATEGORY_ENUM_EN_PT {
  'CAR' = 'Carro',
  'MOTORCYCLE' = 'Moto',
}

export enum VEHICLE_CATEGORY_ENUM_PT_EN {
  'Carro' = VEHICLE_CATEGORY_ENUM.CAR,
  'Moto' = VEHICLE_CATEGORY_ENUM.MOTORCYCLE,
}

export type VehicleTracker = {
  model: string
  serial: number
}

export declare type VehicleQuery = {
  description?: string
  licensePlate?: string
  vehicleCategory?: string
  vehicleType?: string
  coverageId?: string
  trackerSerial?: number
} & Partial<Paging> &
  Partial<Timestamps>

export type Driver = {
  id: string
  name?: string
}

export type CoverageFragment = {
  coverageId: string
  priorityOrder: number
}

export type VehicleAggregatedQueryResponse = {
  id: string
  description: string
  model: string
  modelYear: number
  productionYear: number
  licensePlate: string
  tracker: VehicleTracker & { name?: string }
  vehicleCategory: VEHICLE_CATEGORY_ENUM
  vehicleType: VEHICLE_TYPE_ENUM
  active: true
  createdAt: number
  updatedAt: number
  latitude: number
  longitude: number
  lastMovement: number
  lastCommunication: number
  speed: number
  driver: Driver
  status: string
  ignitionStatus: VEHICLE_IGNITION_STATUS_ENUM
  coverages: CoverageFragment[]
  iconColor?: string
} & Timestamps

export type Vehicle = {
  model: string
  modelYear: number
  productionYear: number
  licensePlate: string
  vehicleCategory: VEHICLE_CATEGORY_ENUM
  vehicleType: VEHICLE_TYPE_ENUM
  iconColor: string
  tracker?: VehicleTracker
}
