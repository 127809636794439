import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useGetAccountTags } from 'shared/hooks'

import { Combobox } from 'components/ComboboxV2/Combobox'
import { AccountTag } from 'services/account/types'

import { parseDataToComboboxV2 } from 'utilities/combobox'
import { ComboboxItem } from 'components'
import { ConditionOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import {
  TagBelongs,
  tagBelongs,
  tagTypeCondition,
  translatedTagType,
} from 'domains/attendancePolicy/components/CondititonForms/TagsForm/utilties'
import { useGetDeviceTags } from 'shared/hooks/services/device/useGetDeviceTag'
import { ConditionType } from 'services/attendancePolicy/types'
import { useGetOccurrenceTag } from 'shared/hooks/services'

import styles from './TagsForm.module.scss'
import {
  evaluationLabel,
  evaluationOption,
} from 'domains/attendancePolicy/utilities/constants'
import { Evaluation } from 'services/serviceOrder'

export const TagsForm = ({ onChange, data }: ConditionOptionForm) => {
  const { facts, type } = data

  const { register, watch, setValue } = useForm<{
    tagType: TagBelongs
    tags: AccountTag[]
    type: ConditionType
    evaluation: Evaluation
  }>({
    defaultValues: {
      evaluation: data?.evaluation || 'HAS',
      ...(type && {
        tagType: tagTypeCondition[type],
      }),
      ...(facts && {
        tags: facts.accountTags || facts.deviceTags || facts.occurrenceTags,
      }),
    },
  })

  const tags = watch('tags')
  const tagType = watch('tagType')

  const [tagFilter, setTagFilter] = useState('')

  const {
    isError: isErrorAccountTags,
    isFetching: isFetchingAccountTags,
    accountTags,
    fetchNextTagsPage,
  } = useGetAccountTags(tagFilter, tagType === 'ACCOUNT_TAGS')

  const [tagDeviceFilter, setDeviceTagFilter] = useState('')

  const {
    isError: isErrorDeviceTags,
    isFetching: isFetchingDeviceTags,
    deviceTags,
    fetchNextPage: fetchNextDeviceTagsPage,
  } = useGetDeviceTags(tagDeviceFilter, tagType === 'DEVICE_TAGS')

  const [tagOccurrenceFilter, setOccurrenceTagFilter] = useState('')

  const {
    isError: isErrorOccurrenceTags,
    isFetching: isFetchingDOccurrenceTags,
    occurrenceTags,
    fetchNextPage: fetchNextOccurrenceTagsPage,
  } = useGetOccurrenceTag(tagOccurrenceFilter, tagType === 'OCCURRENCE_TAGS')

  return (
    <>
      <Combobox
        id="tag-type"
        {...register('tagType')}
        label={{
          text: 'Tag pertence',
        }}
        value={tagBelongs[watch('tagType')]}
        items={Object.values(tagBelongs)}
        onChange={(selected) => {
          const selectedOption = selected as TagBelongs

          setValue('tagType', translatedTagType[selectedOption])

          if (tags?.length) {
            setValue('tags', [])
            onChange({
              type: undefined,
              facts: {},
            })
          }
        }}
        placeholder="Selecione uma opção"
      />

      <div className={styles.wrapper}>
        <Combobox
          label={{
            text: 'Validar se',
          }}
          {...register('evaluation')}
          value={evaluationLabel[watch('evaluation')]}
          items={parseDataToComboboxV2(
            evaluationOption.map((option) => ({
              ...option,
              label: evaluationLabel[option.value],
            })),
            'label',
          )}
          onChange={(selected) => {
            setValue('evaluation', selected.value.value)

            onChange?.({
              type: watch('type'),
              facts: {
                accountTags: watch('tags'),
              },
              evaluation: selected.value.value,
            })
          }}
          placeholder="Selecione uma opção"
        />
      </div>

      <div className={styles.tagWrapper}>
        {
          {
            ACCOUNT_TAGS: (
              <Combobox
                id="account-tag"
                label={{
                  text: 'Conta possui tag',
                }}
                {...register('tags')}
                multiple
                onSearch={(search) => setTagFilter(search)}
                items={parseDataToComboboxV2(accountTags || [], 'name')}
                value={tags?.map((tag) => ({
                  label: 'name',
                  value: tag,
                }))}
                onChange={(selecteds) => {
                  const tagsSelected = selecteds as ComboboxItem<AccountTag>[]

                  const formattedTags = tagsSelected.map((tag) => ({
                    id: tag.value.id,
                    name: tag.value.name,
                  }))

                  setValue('tags', formattedTags, { shouldValidate: true })
                  setValue('type', ConditionType.ACCOUNT_TAGS)

                  onChange?.({
                    type: ConditionType.ACCOUNT_TAGS,
                    facts: {
                      accountTags: formattedTags,
                    },
                    evaluation: watch('evaluation'),
                  })
                }}
                isLoading={isFetchingAccountTags}
                isError={isErrorAccountTags}
                onEndReached={fetchNextTagsPage}
              />
            ),
            OCCURRENCE_TAGS: (
              <Combobox
                id="occurrence-tag"
                label={{
                  text: 'Ocorrência possui tag',
                }}
                {...register('tags')}
                multiple
                onSearch={(search) => setOccurrenceTagFilter(search)}
                items={parseDataToComboboxV2(occurrenceTags || [], 'name')}
                value={tags?.map((tag) => ({
                  label: 'name',
                  value: tag,
                }))}
                onChange={(selecteds) => {
                  const tagsSelected = selecteds as ComboboxItem<AccountTag>[]

                  const formattedTags = tagsSelected.map((tag) => ({
                    id: tag.value.id,
                    name: tag.value.name,
                  }))

                  setValue('tags', formattedTags, { shouldValidate: true })
                  setValue('type', ConditionType.OCCURRENCE_TAGS)

                  onChange?.({
                    type: ConditionType.OCCURRENCE_TAGS,
                    facts: {
                      occurrenceTags: formattedTags,
                    },
                    evaluation: watch('evaluation'),
                  })
                }}
                isLoading={isFetchingDOccurrenceTags}
                isError={isErrorOccurrenceTags}
                onEndReached={fetchNextOccurrenceTagsPage}
              />
            ),
            DEVICE_TAGS: (
              <Combobox
                id="device-tag"
                label={{
                  text: 'Dispositivo possui tag',
                }}
                {...register('tags')}
                multiple
                onSearch={(search) => setDeviceTagFilter(search)}
                items={parseDataToComboboxV2(deviceTags || [], 'name')}
                value={tags?.map((tag) => ({
                  label: 'name',
                  value: tag,
                }))}
                onChange={(selecteds) => {
                  const tagsSelected = selecteds as ComboboxItem<AccountTag>[]

                  const formattedTags = tagsSelected.map((tag) => ({
                    id: tag.value.id,
                    name: tag.value.name,
                  }))

                  setValue('tags', formattedTags, { shouldValidate: true })
                  setValue('type', ConditionType.DEVICE_TAGS)

                  onChange?.({
                    type: ConditionType.DEVICE_TAGS,
                    facts: {
                      deviceTags: formattedTags,
                    },
                    evaluation: watch('evaluation'),
                  })
                }}
                isLoading={isFetchingDeviceTags}
                isError={isErrorDeviceTags}
                onEndReached={fetchNextDeviceTagsPage}
              />
            ),
            EMPTY: <></>,
          }[watch('tagType')]
        }
      </div>
    </>
  )
}
