import * as Joi from '@hapi/joi'
import { Profile } from 'domains/customer/screens/Equipments/types'

export type ProfilesQuerySchema = {
  profile: Profile
  enableAccelerometer: boolean
}

const profileSchema = Joi.object<Profile>({
  id: Joi.string().uuid().required(),
  name: Joi.string().required(),
})

export const profilesQuerySchema = Joi.object<ProfilesQuerySchema>({
  enableAccelerometer: Joi.boolean().required(),
  profile: Joi.alternatives().conditional('enableAccelerometer', {
    is: true,
    then: profileSchema.required(),
    otherwise: profileSchema.optional(),
  }),
})
