import { Skeleton } from 'components'
import styles from './styles.module.scss'

const Loading = () => (
  <div className={styles.loading}>
    <Skeleton width={300} height={40} />
    <Skeleton className={styles.date} width={300} height={40} />
    <Skeleton width="100%" height={80} />
    <Skeleton width="100%" height={331} />
  </div>
)

export default Loading
