import { Button, Loader, Modal } from 'components'
import { usePostAndHandleRebootCommand } from '../../hooks/services/commands'

import styles from './CentralReboot.module.scss'
import { useToast } from 'shared/hooks'

type CentralRebootProps = {
  isVisible: boolean
  onClose: () => void
  onClick: () => void
  central: {
    id: string
    connected: boolean
  }
}

export const CentralReboot = ({
  isVisible,
  onClose,
  onClick,
  central,
}: CentralRebootProps) => {
  const { rebootCommandMutationStatus, mutateRebootCommand } =
    usePostAndHandleRebootCommand(central.id)

  const { addToast } = useToast()

  function handleClick() {
    if (!central.connected) {
      addToast({
        message:
          'Erro ao reiniciar central pois ela parece estar offline. Tente novamente.',
        type: 'alert',
      })
      onClose()
      return
    }

    mutateRebootCommand()
    onClick()
  }

  return (
    <>
      <div>
        <Loader isVisible={rebootCommandMutationStatus === 'pending'}>
          <div className={styles.loaderInfoWrapper}>
            <p className={styles.loaderTitle}>Reiniciando central...</p>
            <p className={styles.loaderInfo}>Isto pode levar alguns minutos.</p>
          </div>
        </Loader>
        <Modal
          title="Reiniciar central"
          isVisible={isVisible}
          onClose={onClose}
          simple
        >
          <p className={styles.info}>
            {`Você tem certeza que deseja reiniciar esta central de alarme? 
              Isto pode levar alguns minutos.`}
          </p>
          <Modal.Footer>
            <Button
              width="172px"
              buttonTitle="Cancelar"
              type="secondary"
              onClick={onClose}
            />
            <Button
              width="172px"
              buttonTitle="Continuar"
              type="primary"
              onClick={handleClick}
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
