import { useEffect, useMemo } from 'react'
import {
  Button,
  Combobox,
  ComboboxItem,
  Loader,
  Modal,
  ProtectedButton,
} from 'components'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { v4 as uuid } from 'uuid'
import {
  updateSirenVolumeSchema,
  UpdateSirenVolumeSchema,
} from '../../schemas/devices/updateSirenVolume'
import { usePostAndHandleConfigureSirenCommand } from 'domains/customer/screens/Equipments/hooks/services/commands/usePostAndHandleConfigureSirenCommand'
import { UbideskPermissions } from 'routes/types'

type SirenVolumeAdjustmentProps = {
  centralId: string
  siren: {
    id: string
    gain: number
  }
  isVisible: boolean
  onClose: () => void
}

type SirenVolumes = {
  id: string
  gain: number
  label: string
}

export const SirenVolumeAdjustment = ({
  centralId,
  siren,
  isVisible,
  onClose,
}: SirenVolumeAdjustmentProps) => {
  const form = useForm<UpdateSirenVolumeSchema>({
    mode: 'onChange',
    defaultValues: {
      deviceId: siren.id,
      gain: siren.gain,
    },
    resolver: joiResolver(updateSirenVolumeSchema),
  })

  const {
    handleSubmit,
    setValue,
    register,
    trigger,
    watch,
    getValues,
    formState,
    reset,
  } = form

  const selectedGain = watch('gain')

  const { mutateConfigureSirenCommand, configureSirenCommandMutationStatus } =
    usePostAndHandleConfigureSirenCommand(centralId, getValues())

  const onSubmit = (data: UpdateSirenVolumeSchema) => {
    mutateConfigureSirenCommand()
    reset(data)
    onClose()
  }

  const comboboxSirenVolumes: ComboboxItem<SirenVolumes>[] = [
    { label: 'label', value: { id: uuid(), label: 'Baixo', gain: 1 } },
    { label: 'label', value: { id: uuid(), label: 'Médio', gain: 2 } },
    { label: 'label', value: { id: uuid(), label: 'Alto', gain: 3 } },
  ]

  const handleGetSelectedVolume = (
    selected:
      | string
      | ComboboxItem<SirenVolumes>
      | (string | ComboboxItem<SirenVolumes>)[]
      | undefined,
  ) => {
    const volumeSiren = selected as ComboboxItem<SirenVolumes>

    setValue('gain', volumeSiren.value.gain, { shouldDirty: true })
    trigger()
  }

  const comboboxSirenInitialValue = useMemo(() => {
    const initialValue = comboboxSirenVolumes.find(
      ({ value: siren }) => siren.gain === selectedGain,
    )

    return initialValue
  }, [comboboxSirenVolumes, selectedGain])

  useEffect(() => {
    register('deviceId', { setValueAs: () => siren.id })
    register('gain')
  }, [register, siren.id])

  return (
    <div>
      <Loader isVisible={configureSirenCommandMutationStatus === 'pending'} />
      <Modal title="Ajustar volume" isVisible={isVisible} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Combobox
            label={'Volume'}
            value={comboboxSirenInitialValue}
            items={comboboxSirenVolumes}
            getSelected={handleGetSelectedVolume}
          />
        </form>
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Voltar"
            type="secondary"
            onClick={onClose}
          />
          <ProtectedButton
            permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isDirty || !formState.isValid}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
