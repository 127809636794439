import Joi from '@hapi/joi'
import { TagInput } from 'services/types'
import { patrimonySchema } from 'shared/schemas/patrimony/patrimonySchema'
import {
  AccountAttendanceProfileNames,
  AccountPayload,
  AggregatedAccount,
} from 'services/account/types'
import { centralFragmentSchema } from 'shared/schemas/central/centralSchema'
import { customerFragmentSchema } from 'shared/schemas/customer/customerSchema'

export const accountSchema = Joi.object<AggregatedAccount>({
  id: Joi.string().uuid(),
  code: Joi.string(),
  aggregatedAccountName: Joi.string(),
  patrimony: patrimonySchema.optional(),
  customer: customerFragmentSchema.optional(),
  central: centralFragmentSchema.optional(),
  createdAt: Joi.number().optional(),
  active: Joi.boolean().optional(),
})

const tagsSchema = Joi.object<TagInput>({
  id: Joi.string().uuid().optional(),
  name: Joi.string().required(),
  type: Joi.string().required(),
})

const attendanceProfilesSchema = Joi.object<AccountAttendanceProfileNames>({
  tactical: Joi.array().items(Joi.string().optional().allow(null, '')),
  technical: Joi.array().items(Joi.string().optional().allow(null, '')),
})

export const accountPayloadSchema = Joi.object<AccountPayload>({
  name: Joi.string().required(),
  serviceType: Joi.object({
    id: Joi.string().uuid().required(),
    name: Joi.string().required(),
  }).required(),
  tags: Joi.array().items(tagsSchema).optional().allow(null, ''),
  observations: Joi.string().allow(null, '').optional(),
  active: Joi.string().optional().allow(null, ''),
  id: Joi.string().uuid().optional().allow(null, ''),
  patrimonyId: Joi.string().uuid().optional().allow(null, ''),
  note: Joi.string().optional().allow(null, ''),
  externalId: Joi.string().optional().allow(null, ''),
  securityAnswer: Joi.string().optional().allow(null, ''),
  securityCoercionAnswer: Joi.string().optional().allow(null, ''),
  securityQuestion: Joi.string().optional().allow(null, ''),
  attendanceProfiles: attendanceProfilesSchema.optional().allow(null, ''),
  updatedAt: Joi.number().optional().allow(null, ''),
  createdAt: Joi.number().optional().allow(null, ''),
  activeDateTime: Joi.number().optional().allow(null, ''),
  code: Joi.string().optional().allow(null, ''),
  paymentMethod: Joi.object({
    paymentMethodType: Joi.string().optional().allow(null, ''),
    createdAt: Joi.number().optional().allow(null, ''),
  })
    .allow(null, '')
    .optional(),
  partitions: Joi.array()
    .items({
      id: Joi.string().allow(null, '').optional(),
      name: Joi.string().allow(null, '').optional(),
      keyNumber: Joi.string().allow(null, '').optional(),
      externalId: Joi.string().allow(null, '').optional(),
      code: Joi.string().allow(null, '').optional(),
      securityQuestion: Joi.string().allow(null, '').optional(),
      securityAnswer: Joi.string().allow(null, '').optional(),
      securityCoercionAnswer: Joi.string().allow(null, '').optional(),
    })
    .allow(null, '')
    .optional(),
  contractNumber: Joi.string().optional().allow(null, ''),
})
