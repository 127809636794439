import { ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import { ReactComponent as Close } from 'assets/svg/closeIcon.svg'

import styles from './Toast.module.scss'
import { ToastType } from 'components/Toast/types'
import { ToastStyleProps } from 'components/Toast/utilities/ToastStyleProps'

export interface ToastProps {
  isVisible: boolean
  onClose(): void
  type?: ToastType
  autohideTimeout?: number
  children: ReactNode
}
export const Toast = (props: ToastProps) => {
  const {
    children,
    isVisible,
    onClose,
    autohideTimeout = 4000,
    type = 'success',
  } = props

  useEffect(() => {
    if (autohideTimeout) {
      const timer = setTimeout(() => {
        onClose()
      }, autohideTimeout)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [autohideTimeout, onClose])

  return (
    <>
      {ReactDOM.createPortal(
        <CSSTransition
          in={isVisible}
          timeout={{
            enter: 400,
            exit: 100,
          }}
          classNames={{
            enter: styles.slideInEnter,
            enterActive: styles.slideInEnterActive,
            exit: styles.slideInExit,
            exitActive: styles.slideInExitActive,
          }}
          unmountOnExit
        >
          <div
            aria-label={type}
            className={[styles.toast, styles[ToastStyleProps[type].style]].join(
              ' ',
            )}
          >
            {ToastStyleProps[type].icon}
            <p className={styles.toastText}>{children}</p>
            <button
              onClick={onClose}
              className={styles.toastCloseButton}
              aria-label="button-close"
            >
              <Close className={styles.toastCloseIcon} />
            </button>
          </div>
        </CSSTransition>,
        document.body,
      )}
    </>
  )
}
