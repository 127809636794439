import { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ReactComponent as CloseButton } from 'assets/svg/close.svg'
import { Button, Combobox, ComboboxItem } from 'components'
import { Combobox as ComboboxV2 } from 'components/ComboboxV2/Combobox'

import { parseDataToCombobox, parseDataToComboboxV2 } from 'utilities/combobox'
import { Pagination } from 'services/types'

import { useFormContext } from 'react-hook-form'

import { City } from 'services/address/city/types'
import { District } from 'services/address/district/types'

import StateDriver, { State } from 'services/address/state'
import AccountDriver from 'services/account'
import DistrictDriver from 'services/address/district'
import CityDriver from 'services/address/city'
import { AccountTag, TagType, AggregatedAccount } from 'services/account/types'

import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { AttendanceProfileFilters } from 'domains/attendanceProfile/schemas/searchAttendanceProfileSchema'
import {
  SERVICE_ORDER_TYPE_EN_PT,
  SERVICE_ORDER_TYPE_PT_EN,
} from 'services/serviceOrder'

import {
  OCCURRENCE_CATEGORY_EN_PT,
  OCCURRENCE_CATEGORY_PT_EN,
} from 'services/occurrence/types'

import styles from './Filters.module.scss'
import {
  ATTENDANCE_ROLE_EN_PT,
  ATTENDANCE_ROLE_PT_EN,
} from 'services/coverages'
import { useGetServiceType } from 'shared/hooks/services/serviceType/useGetServiceType'
import { ServiceType } from 'services/serviceType/types'

type FiltersProps = {
  isVisible: boolean
  onClose: () => void
  onApplyFilters: () => void
}

export const Filters = ({
  isVisible,
  onClose,
  onApplyFilters,
}: FiltersProps) => {
  const { watch, setValue, register, reset } =
    useFormContext<AttendanceProfileFilters>()

  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const coverageType = watch('coverageType')
  const state = watch('state')
  const city = watch('city')
  const district = watch('district')
  const account = watch('account')
  const tag = watch('tag')
  const serviceOrderType = watch('serviceOrderType')
  const occurrenceCategory = watch('occurrenceCategory')
  const coverageServiceTypes = watch('serviceTypes')

  const [serviceTypeFilter, setServiceTypeFilter] = useState('')

  const {
    serviceTypes,
    isFetching: isFetchingServiceTypes,
    fetchNextServiceTypesPage,
  } = useGetServiceType(serviceTypeFilter)

  const handleFetchStates = useCallback(
    async (pagination: Pagination, name?: string) => {
      const response = await StateDriver.queryStates({
        ...(name && { name }),
        ...pagination,
      })

      return parseDataToCombobox(response, 'name')
    },
    [],
  )

  const handleFetchCities = useCallback(
    async (pagination: Pagination, name?: string) => {
      const response = await CityDriver.queryCities({
        ...(name && { name }),
        ...(state && { stateId: state.id }),
        ...pagination,
      })

      return parseDataToCombobox(response, 'name')
    },
    [state],
  )

  const handleFetchDistricts = useCallback(
    async (pagination: Pagination, name?: string) => {
      const response = await DistrictDriver.queryDistricts({
        ...(name && { name }),
        ...(city && { cityId: city.id }),
        ...pagination,
      })

      return parseDataToCombobox(response, 'name')
    },
    [city],
  )

  const handleFetchAccounts = useCallback(
    async (pagination: Pagination, code?: string) => {
      const response = await AccountDriver.queryAggregatedAccounts({
        ...(code && { code }),
        ...pagination,
      })

      return parseDataToCombobox(response, 'aggregatedAccountName')
    },
    [],
  )

  const handleFetchAccountsTags = useCallback(
    async (pagination: Pagination, name?: string) => {
      const response = await AccountDriver.queryTags({
        ...(name && { name }),
        ...pagination,
        type: TagType.PRINCIPAL,
      })

      return parseDataToCombobox(response, 'name')
    },
    [],
  )

  useEffect(() => {
    register('coverageType')
    register('state')
    register('city')
    register('district')
    register('account')
    register('tag')
    register('serviceOrderType')
    register('occurrenceCategory')
  }, [register])

  return ReactDOM.createPortal(
    <div
      className={[styles.container, isVisible ? styles.show : styles.hide]
        .filter(Boolean)
        .join(' ')}
      aria-label="filters-component"
    >
      <div className={styles.innerWrapper}>
        <div className={styles.header}>
          <span>Filtros</span>
          <CloseButton
            height={16}
            width={16}
            onClick={onClose}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.contentWrapper}>
          <ComboboxV2
            multiple
            id="service-type"
            {...register('serviceTypes')}
            isLoading={isFetchingServiceTypes}
            label={{ text: 'Tipo de serviço' }}
            value={
              coverageServiceTypes?.map((serviceType) => ({
                label: 'name',
                value: serviceType,
              })) || []
            }
            onEndReached={fetchNextServiceTypesPage}
            items={parseDataToComboboxV2(serviceTypes || [], 'name')}
            onChange={(selected) => {
              const serviceTypes =
                selected as unknown as ComboboxItem<ServiceType>[]

              setValue(
                'serviceTypes',
                serviceTypes?.map((serviceType) => ({
                  id: serviceType.value.id,
                  name: serviceType.value.name,
                })),
                {
                  shouldValidate: true,
                },
              )
            }}
            onSearch={(search: string) => setServiceTypeFilter(search)}
          />
          <Combobox
            id="attendance-role"
            label="Função"
            value={
              coverageType ? ATTENDANCE_ROLE_EN_PT[coverageType] : undefined
            }
            items={[...Object.values(ATTENDANCE_ROLE_EN_PT)]}
            getSelected={(selected) => {
              const coverageType = selected as ATTENDANCE_ROLE_EN_PT

              setValue('coverageType', ATTENDANCE_ROLE_PT_EN[coverageType])
            }}
          />
          <Combobox
            id="state"
            label="Estado"
            className="col-start-1 col-span-6"
            value={
              state
                ? {
                    label: 'name',
                    value: state,
                  }
                : undefined
            }
            searchable
            fetcher={handleFetchStates}
            getSelected={(selected) => {
              const state = selected as ComboboxItem<State>
              setValue('state', state?.value)
              if (city) {
                setValue('city', undefined)
              }
            }}
          />

          <Combobox
            id="city"
            label="Cidade"
            value={
              city
                ? {
                    label: 'name',
                    value: city,
                  }
                : undefined
            }
            searchable
            fetcher={handleFetchCities}
            getSelected={(selected) => {
              const city = selected as ComboboxItem<City>
              setValue('city', city?.value)
              if (district) {
                setValue('district', undefined)
              }
            }}
            disabled={!state}
          />

          <Combobox
            id="district"
            label="Bairro"
            value={
              district
                ? {
                    label: 'name',
                    value: district,
                  }
                : undefined
            }
            searchable
            fetcher={handleFetchDistricts}
            getSelected={(selected) => {
              const district = selected as ComboboxItem<District>
              setValue('district', district?.value)
            }}
            disabled={!city}
          />

          <Combobox
            id="account"
            label="Conta"
            value={
              account
                ? {
                    label: 'aggregatedAccountName',
                    value: account,
                  }
                : undefined
            }
            searchable
            fetcher={handleFetchAccounts}
            getSelected={(selected) => {
              const account = selected as ComboboxItem<AggregatedAccount>
              setValue('account', account?.value)
            }}
          />

          <Combobox
            id="tag"
            label="Tag"
            value={
              tag
                ? {
                    label: 'name',
                    value: tag,
                  }
                : undefined
            }
            searchable
            fetcher={handleFetchAccountsTags}
            getSelected={(selected) => {
              const tag = selected as ComboboxItem<AccountTag>
              setValue('tag', tag?.value)
            }}
          />

          <Combobox
            id="service-order"
            label="Tipo de Ordem de Serviço"
            items={[...Object.values(SERVICE_ORDER_TYPE_EN_PT)]}
            value={
              serviceOrderType
                ? SERVICE_ORDER_TYPE_EN_PT[serviceOrderType]
                : undefined
            }
            disabled={Boolean(occurrenceCategory)}
            getSelected={(selected) => {
              const type = selected as SERVICE_ORDER_TYPE_EN_PT

              setValue('serviceOrderType', SERVICE_ORDER_TYPE_PT_EN[type])
            }}
          />

          <Combobox
            id="occurrence"
            label="Categoria de ocorrência"
            value={
              occurrenceCategory
                ? OCCURRENCE_CATEGORY_EN_PT[occurrenceCategory]
                : undefined
            }
            items={[...Object.values(OCCURRENCE_CATEGORY_EN_PT)]}
            getSelected={(selected) => {
              const category = selected as OCCURRENCE_CATEGORY_EN_PT

              setValue(
                'occurrenceCategory',
                OCCURRENCE_CATEGORY_PT_EN[category],
              )
            }}
            disabled={Boolean(serviceOrderType)}
          />
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              clearAllLocalStorageFilters()
              reset({})
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            width="162px"
            onClick={onApplyFilters}
          />
        </div>
      </div>
    </div>,
    document.getElementById('main-container') || document.body,
  )
}
