import { Skeleton } from 'components'

const Loading = () => (
  <>
    <h3>Galeria de fotos</h3>
    <div>
      {Array.from({ length: 8 }).map((_, index) => (
        <Skeleton key={index} height={144} width={118} />
      ))}
    </div>
  </>
)

export default Loading
