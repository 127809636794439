import { useRef } from 'react'
import { useToggle } from 'shared/hooks'
import { ComboboxItem } from '../Combobox'

import styles from '../Combobox.module.scss'
import { Tooltip } from 'components'
import { getItemValue } from '../utilities'

interface Item {
  id: string
  type?: string
}

type ComboboxItemComponentProps<T> = {
  item: string | ComboboxItem<T>
  itemId: string
  itemDisabled: boolean
  onSelectItem: (item: string | ComboboxItem<T>) => void
  itemLabel?: string | ((item: ComboboxItem<T> | string) => string)
}

export function ComboboxItemComponent<T extends Item>({
  item,
  itemId,
  itemDisabled,
  onSelectItem,
  itemLabel,
}: ComboboxItemComponentProps<T>): JSX.Element {
  const tooltip = useToggle()
  const tooltipRef = useRef<HTMLDivElement>(null)

  const label =
    itemLabel && typeof itemLabel !== 'string' ? itemLabel(item) : itemLabel

  return (
    <li
      className={[styles.item, itemDisabled && styles.itemDisabled]
        .filter(Boolean)
        .join(' ')}
      data-combobox-item
      onClick={() => {
        if (!itemDisabled) {
          onSelectItem(item)
        }
      }}
      key={itemId}
    >
      <div
        ref={tooltipRef}
        className={styles.itemTextContainer}
        onMouseEnter={tooltip.show}
        onMouseLeave={tooltip.hide}
      >
        {getItemValue(item)}
      </div>
      {(itemDisabled || itemLabel) && (
        <div>
          <span>{itemDisabled ? 'Em uso' : label}</span>
        </div>
      )}
      <Tooltip
        parentRef={tooltipRef}
        type="informative"
        isVisible={tooltip.isVisible}
        onMouseIn={tooltip.show}
        onMouseOut={tooltip.hide}
      >
        <div>
          <p>{getItemValue(item)}</p>
        </div>
      </Tooltip>
    </li>
  )
}
