import React, { useCallback, useMemo, useRef, useState } from 'react'

import { CellProps, Column } from 'react-table'
import { useNavigate } from 'react-router-dom'

import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  Button,
  ContainerScreen,
  Input,
  Loader,
  Table,
  TableRef,
  Tags,
} from 'components'

import { Filters, RemoveModal, VisualizationModal } from '../components'

import { HolidayFilter } from '../types'
import { HolidayQueryResponse } from 'services/holiday/types'

import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { useToast, useToggle } from 'shared/hooks'

import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { ReactComponent as InspectIcon } from 'assets/svg/listInspectIcon.svg'
import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/trashCan.svg'
import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'
import { Pagination, Result } from 'services/types'
import { HolidayDriver } from 'services/holiday/holiday.driver'
import { holidayFiltersSchema } from '../schemas/searchHolidaySchema'
import {
  getHolidayRepeatableTerm,
  getHolidayCoverageTags,
  translatedHolidayCoverageType,
  formatDate,
} from 'domains/holiday/utilities'

import { useDeleteHoliday } from 'shared/hooks/holiday'

import styles from './Holidays.module.scss'

const columns: Column<HolidayQueryResponse>[] = [
  {
    id: 'name',
    Header: 'Nome',
    accessor: (holiday) => holiday.name,
  },
  {
    id: 'coverage',
    Header: 'Abrangência',
    accessor: (holiday) => holiday,
    // eslint-disable-next-line react/display-name
    Cell: (cell: CellProps<HolidayQueryResponse>) => {
      const holiday = cell.value as HolidayQueryResponse

      const tags = getHolidayCoverageTags(holiday)

      return (
        <div>
          <Tags
            keyId={holiday.id}
            className={styles.firstTag}
            keyLabel="coverage"
            data={tags}
          />
        </div>
      )
    },
  },
  {
    id: 'type',
    Header: 'Tipo',
    accessor: (holiday) => getHolidayRepeatableTerm(holiday.repeatable),
  },
  {
    id: 'date',
    Header: 'Data',
    accessor: (holiday) => formatDate(holiday.day, holiday.month, holiday.year),
  },
]

export const Holidays: React.FC = () => {
  const [selectedHoliday, setSelectedHoliday] = useState({
    id: '',
    name: '',
    date: '',
    type: '',
    coverage: '',
  })

  const { getFilters, setFilters } =
    usePersistentFilters<HolidayFilter>('holiday')

  const loader = useToggle()
  const filterSheetProps = useToggle()
  const removeModal = useToggle()
  const visualizationModal = useToggle()

  const { addToast } = useToast()

  const navigate = useNavigate()

  const tableRef = useRef<TableRef>(null)

  const { mutate, status } = useDeleteHoliday()

  const form = useForm<HolidayFilter>({
    resolver: joiResolver(holidayFiltersSchema),
    mode: 'onChange',
    defaultValues: getFilters(),
  })

  const { watch, register, getValues, reset, setValue } = form

  const name = getValues('name')
  const coverageType = getValues('coverageType')
  const repeatable = getValues('repeatable')
  const createdFrom = watch('createdFrom')
  const createdTo = watch('createdTo')

  const hasFilter = useMemo(() => {
    return Object.values(watch())
      .slice(0, 4)
      .some((value) => value !== undefined)
  }, [watch])

  const handleSelectHoliday = useCallback((holiday: HolidayQueryResponse) => {
    setSelectedHoliday({
      id: holiday.id,
      name: holiday.name,
      type: getHolidayRepeatableTerm(holiday.repeatable),
      date: formatDate(holiday.day, holiday.month, holiday.year),
      coverage: translatedHolidayCoverageType[holiday.coverageType],
    })
  }, [])

  const fetchHolidays = useCallback(
    async (pagination: Pagination) => {
      loader.show()
      let holidays: Result<HolidayQueryResponse> = {
        data: [],
        totalElements: 0,
      }

      if (name) {
        reset()
        setValue('name', name)
        setFilters(watch())
      }

      try {
        const response = await HolidayDriver.query({
          ...(name && { name }),
          ...(coverageType && {
            coverageType,
          }),
          ...(repeatable !== undefined && { repeatable }),
          ...(createdFrom && { createdFrom }),
          ...(createdTo && { createdTo }),
          sort: 'createdAt',
          sortType: 'DESC',
          offset: pagination.offset,
          recordsPerPage: pagination.recordsPerPage,
        })
        holidays = {
          data: response.data,
          totalElements: response.totalElements,
        }
      } finally {
        loader.hide()
      }
      return holidays
    },
    [
      name,
      coverageType,
      repeatable,
      reset,
      loader,
      setValue,
      watch,
      setFilters,
      createdFrom,
      createdTo,
    ],
  )

  return (
    <>
      <Loader isVisible={status === 'pending'} />

      <VisualizationModal
        holiday={selectedHoliday}
        isVisible={visualizationModal.isVisible}
        onClose={visualizationModal.hide}
      />

      <RemoveModal
        holidayName={selectedHoliday.name}
        isVisible={removeModal.isVisible}
        onClose={removeModal.hide}
        onRemove={() => {
          mutate(selectedHoliday.id, {
            onSuccess: () => {
              addToast({
                message: 'Feriado excluído com sucesso.',
                type: 'success',
              })

              tableRef.current?.handleFetchPage()
            },
            onError: () =>
              addToast({
                message: 'Erro ao excluir feriado. Tente novamente.',
                type: 'alert',
              }),
          })
          removeModal.hide()
        }}
      />

      <FormProvider {...form}>
        <Filters
          isVisible={filterSheetProps.isVisible}
          onClose={filterSheetProps.hide}
          onApplyFilters={() => {
            if (name) {
              form.setValue('name', undefined)
            }
            setFilters(watch())
            tableRef.current?.handleFetchPage()
            filterSheetProps.hide()
            navigate('?page=1')
          }}
        />
        <ContainerScreen
          title="Feriados"
          clickable={!filterSheetProps.isVisible}
          renderFilters={
            <form
              onSubmit={(event) => {
                event.preventDefault()
                tableRef.current?.handleFetchPage()
              }}
            >
              <section className={styles.innerWrapper}>
                <Input
                  id="name"
                  label="Nome"
                  placeholder="Digite o nome do feriado"
                  {...register('name')}
                  autoComplete="off"
                  disabled={filterSheetProps.isVisible}
                />
                <Button
                  buttonTitle="Pesquisar"
                  type="primary"
                  htmlType="submit"
                />
                <Button
                  buttonTitle="Novo feriado"
                  type="secondary"
                  icon={PlusIcon}
                  width="143px"
                  onClick={() => navigate('/config/holiday/create')}
                />
                <Button
                  buttonTitle={
                    hasFilter ? 'Editar filtros' : 'Adicionar filtros'
                  }
                  type="tertiary"
                  icon={FiltersIcon}
                  onClick={filterSheetProps.show}
                />
              </section>
            </form>
          }
        >
          <Table
            innerRef={tableRef}
            columns={columns}
            paginated
            actions={[
              {
                label: 'Editar',
                Icon: EditIcon,
                handler: (holiday) =>
                  navigate('/config/holiday/update', {
                    state: {
                      holidayId: holiday.id,
                    },
                  }),
              },
              {
                label: 'Visualizar',
                Icon: InspectIcon,
                handler: (holiday) => {
                  handleSelectHoliday(holiday)

                  visualizationModal.show()
                },
              },
              {
                label: 'Excluir',
                Icon: DeleteIcon,
                handler: (holiday) => {
                  handleSelectHoliday(holiday)

                  removeModal.show()
                },
              },
            ]}
            resetPagination={false}
            fetcher={fetchHolidays}
          />
        </ContainerScreen>
      </FormProvider>
    </>
  )
}
