import {
  ComponentProps,
  ReactElement,
  cloneElement,
  createContext,
  useContext,
  MouseEvent as ReactMouseEvent,
  useState,
  useCallback,
  useEffect,
  RefObject,
  useRef,
} from 'react'
import styles from './styles.module.scss'
import joinClassNames from 'utilities/joinClassNames'
import Icon from 'components/Icon'

interface DropdownContextProps {
  isOpen: boolean
  onOpen: () => void
  contentRef: RefObject<HTMLUListElement>
  triggerRef: RefObject<HTMLButtonElement>
}

export const DropDownContext = createContext<DropdownContextProps>({
  isOpen: false,
  onOpen: () => null,
  contentRef: { current: null },
  triggerRef: { current: null },
})

const useDropdownContext = () => {
  const context = useContext(DropDownContext)

  if (!context) {
    throw new Error('useDropdownContext must be used within a DropdownProvider')
  }

  return context
}

interface RootProps extends ComponentProps<'div'> {
  isOpen?: boolean
}

const Root = ({ isOpen = false, children, className, ...props }: RootProps) => {
  const [open, setOpen] = useState(isOpen)
  const triggerRef = useRef<HTMLButtonElement>(null)
  const contentRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpen(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    triggerRef.current?.setAttribute('data-active', open ? 'true' : 'false')

    const handleMouseLeave = (event: MouseEvent) => {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.relatedTarget as Node)
      ) {
        setOpen(false)
      }
    }

    const element = contentRef.current

    if (element) {
      element.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if (element) {
        element.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [open])

  return (
    <DropDownContext.Provider
      value={{
        triggerRef,
        contentRef,
        isOpen: open,
        onOpen: () => setOpen(true),
      }}
    >
      <div {...props} className={joinClassNames(styles.root, className)}>
        {children}
      </div>
    </DropDownContext.Provider>
  )
}

type TriggerProps = ComponentProps<'button'>

const Trigger = ({ onClick, children, ...props }: TriggerProps) => {
  const { onOpen, triggerRef } = useDropdownContext()

  const handleClick = (event: ReactMouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    onOpen()
  }

  return cloneElement(children as ReactElement, {
    onClick: handleClick,
    ref: triggerRef,
    ...props,
  })
}

type Position = 'left' | 'right' | 'top' | 'bottom'

interface ContentProps extends ComponentProps<'ul'> {
  offset?: number
  position?: Position
}

const Content = ({
  className,
  offset = 4,
  position = 'bottom',
  ...props
}: ContentProps) => {
  const { isOpen, triggerRef, contentRef } = useDropdownContext()

  const handlePosition = useCallback(() => {
    const triggerElement = triggerRef.current
    const contentElement = contentRef.current

    if (triggerElement && contentElement) {
      const positions: Record<Position, { top: string; left: string }> = {
        left: {
          top: '0px',
          left: `${-contentElement.clientWidth - offset}px`,
        },
        right: {
          top: '0px',
          left: `${triggerElement.clientWidth + offset}px`,
        },
        top: {
          top: `${-contentElement.clientHeight - offset}px`,
          left: 'auto',
        },
        bottom: {
          top: `${triggerElement.clientHeight + offset}px`,
          left: 'auto',
        },
      }

      const { top, left } = positions[position]

      contentRef.current.style.top = top
      contentRef.current.style.left = left
    }
  }, [triggerRef, position])

  useEffect(() => {
    if (isOpen) {
      handlePosition()
    }
  }, [isOpen, handlePosition])

  return (
    <ul
      ref={contentRef}
      className={joinClassNames(
        styles.content,
        isOpen && styles.visible,
        className,
      )}
      {...props}
    />
  )
}

const Item = ({ className, children, ...props }: ComponentProps<'li'>) => (
  <li {...props} className={joinClassNames(styles.item, className)}>
    {children}
  </li>
)

const Separator = ({ className, ...props }: ComponentProps<'hr'>) => (
  <hr {...props} className={joinClassNames(styles.separator, className)} />
)

const Sub = ({ children, className, ...props }: ComponentProps<'div'>) => (
  <div {...props} className={joinClassNames(styles.subMenu, className)}>
    {children}
  </div>
)

type SubTriggerProps = ComponentProps<'li'>

const SubTrigger = ({
  onClick,
  children,
  className,
  ...props
}: SubTriggerProps) => {
  const { onOpen } = useDropdownContext()

  const handleClick = (event: ReactMouseEvent<HTMLLIElement>) => {
    onClick?.(event)
    onOpen()
  }

  return (
    <li
      {...props}
      onClick={handleClick}
      className={joinClassNames(styles.subTrigger, className)}
    >
      {children}
      <Icon name="chevron-sm-right" color="element" />
    </li>
  )
}

interface SubContentProps extends ComponentProps<'ul'> {
  position?: 'left' | 'right'
}

const SubContent = ({
  className,
  position = 'left',
  ...props
}: SubContentProps) => {
  const subContentRef = useRef<HTMLUListElement>(null)

  const handlePosition = useCallback(() => {
    const contentElement = subContentRef.current

    if (contentElement) {
      if (position === 'left') {
        subContentRef.current.style.right = '100%'
      } else {
        subContentRef.current.style.left = '100%'
      }
    }
  }, [position])

  useEffect(() => {
    handlePosition()
  }, [])

  return (
    <ul
      {...props}
      ref={subContentRef}
      className={joinClassNames(styles.subContent, className)}
    />
  )
}

const Dropdown = {
  Root,
  Trigger,
  Content,
  Separator,
  Sub,
  SubContent,
  Item,
  SubTrigger,
}

export default Dropdown
