import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Table, TableRef } from 'components'
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'
import { ReactComponent as InspectIcon } from 'assets/svg/listInspectIcon.svg'

import {
  fetchQuickAlerts,
  useFetchQuickAlerts,
  useUpdateQuickAlert,
} from 'services/displacementMap'
import { QuickAlertResponse } from 'services/displacementMap/types'

import styles from './QuickAlerts.module.scss'
import { BackDrop } from '../../../../components'
import { RowAction } from 'components/Table/components/RowActions'
import { CloseAlert, StopAlert } from './components'
import { columns } from './columns'
import { useMap, useDisplacementModal } from '../../../../contexts'
import { useUserInfo } from 'shared/hooks'

type AlertModals = 'CLOSE_ALERT' | 'STOP_ALERT'

type Modals = {
  type: AlertModals
  id?: string
} | null

export const QuickAlerts: React.FC = () => {
  const tableRef = useRef<TableRef>(null)
  const { userInfo } = useUserInfo()
  const [visibleModal, setVisibleModal] = useState<Modals>()
  const { handleRoute } = useMap()
  const userId = userInfo.id
  const { data: quickAlerts } = useFetchQuickAlerts(userId)
  const { handleCloseModal } = useDisplacementModal()
  const { mutate: updateQuickAlert } = useUpdateQuickAlert()

  const noAttendance = useMemo(
    () => quickAlerts?.find((alert) => alert.user.id === userId)?.id,
    [quickAlerts],
  )

  const handleModal = (modal: AlertModals) => {
    const modals = {
      CLOSE_ALERT: visibleModal?.id && (
        <CloseAlert alertId={visibleModal.id} onClose={handleCloseSubModal} />
      ),
      STOP_ALERT: <StopAlert onClose={handleCloseSubModal} />,
    }

    return modals[modal]
  }

  const handleCloseSubModal = () => {
    setVisibleModal(null)
    tableRef.current?.handleFetchPage()
  }

  const handleActionsList = useCallback(
    (alert: QuickAlertResponse) => {
      if (!!noAttendance && noAttendance !== alert.id) {
        const option: RowAction<QuickAlertResponse>[] = [
          {
            label: 'Visualizar',
            Icon: InspectIcon,
            handler: (item) => {
              handleCloseModal()
              handleRoute({
                marker: item.occurrence,
                type: 'occurrence',
              })
            },
          },
        ]

        return option
      }

      const defaultItems: RowAction<QuickAlertResponse>[] = [
        {
          label: 'Visualizar ocorrência',
          handler: (item) => {
            handleCloseModal()
            handleRoute({
              marker: item.occurrence,
              type: 'occurrence',
            })
          },
        },
      ]

      if (!alert.user?.id && !noAttendance) {
        defaultItems.push({
          label: 'Atender',
          handler: () => {
            if (userId) {
              updateQuickAlert(
                {
                  userId,
                  id: alert.id,
                },
                {
                  onSuccess: () => {
                    handleRoute({
                      marker: alert.occurrence,
                      type: 'occurrence',
                    })
                    handleCloseModal()
                    tableRef.current?.handleFetchPage()
                  },
                },
              )
            }
          },
        })
      }

      if (alert.user.id === userId) {
        const attendance: RowAction<QuickAlertResponse>[] = [
          {
            label: 'Deixar de atender',
            handler: () => setVisibleModal({ type: 'STOP_ALERT' }),
          },
          {
            label: 'Finalizar',
            handler: (item) =>
              setVisibleModal({ type: 'CLOSE_ALERT', id: item.id }),
          },
        ]

        defaultItems.push(...attendance)
      }

      return defaultItems
    },
    [handleCloseModal, handleRoute, noAttendance, updateQuickAlert, userId],
  )

  useEffect(() => tableRef.current?.handleFetchPage(), [visibleModal])

  return (
    <>
      {visibleModal?.type ? (
        handleModal(visibleModal.type)
      ) : (
        <BackDrop onClose={handleCloseModal}>
          <main className={styles.container}>
            <h1>Alertas táticos</h1>
            <button onClick={handleCloseModal} className={styles.closeButton}>
              <CloseIcon />
            </button>

            <Table
              resetPagination
              columns={columns}
              recordsPerPage={15}
              innerRef={tableRef}
              actions={handleActionsList}
              fetcher={() => fetchQuickAlerts(userInfo.id)}
            />
          </main>
        </BackDrop>
      )}
    </>
  )
}
