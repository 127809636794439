import { useMutation } from '@tanstack/react-query'
import { HolidayDriver } from 'services/holiday/holiday.driver'
import { HolidayPayload } from 'services/holiday/types'

function createHoliday(data: HolidayPayload): Promise<HolidayPayload> {
  return HolidayDriver.create(data)
}

export const usePostHoliday = () => {
  return useMutation({
    mutationFn: async (data: HolidayPayload) => {
      await createHoliday(data)
    },
  })
}
