import { useState, useEffect, Fragment, useMemo } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as Arrow } from 'assets/svg/arrow.svg'

import { usePermissions } from 'shared/hooks/permissions/permissions.hook'

import styles from './Navbar.module.scss'
import { sidebarData } from './sidebarData'
import { useHierarchyAccess } from 'routes/hooks/useHierarchyAccess/useHierarchyAccess'
import { Icon } from 'components'
import { User } from './components'

export const NavBar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [navbarOpen, setNavbarOpen] = useState(false)

  const defaultSubItemSelected = useMemo(() => {
    const array = location.pathname.split('/')

    return array[1]
  }, [location])

  const [subItemOpen, setSubItemOpen] = useState<string | undefined>(
    defaultSubItemSelected,
  )

  useEffect(() => {
    setSubItemOpen(() => defaultSubItemSelected)
  }, [location, navbarOpen])

  const { hasAccess } = usePermissions()
  const { hierarchyAccess } = useHierarchyAccess()

  const handleOpenSubItems = (title?: string) => {
    if (subItemOpen === title) {
      return setSubItemOpen('')
    }

    setSubItemOpen(title)
  }

  return (
    <nav
      onMouseEnter={() => {
        setNavbarOpen(true)
      }}
      onMouseLeave={() => {
        setNavbarOpen(false)
      }}
      className={[styles.container, navbarOpen && styles.opened].join(' ')}
    >
      <div>
        <div className={styles.logo}>
          <Icon
            name={navbarOpen ? 'logo' : 'micro-logo'}
            height={32}
            onClick={() => {
              navigate('/')
            }}
          />
        </div>
        <ul>
          {sidebarData.map((menu) => {
            const hasPermission = menu.permission.some((permission) =>
              hierarchyAccess(permission),
            )

            const subItemsOpen = subItemOpen
              ? menu.path.includes(subItemOpen || '')
              : false

            const showSubItems = menu.subItems && navbarOpen

            return (
              hasPermission && (
                <Fragment key={menu.path}>
                  <li
                    className={[
                      (menu.path === location.pathname || subItemsOpen) &&
                        styles.active,
                    ].join(' ')}
                  >
                    <div
                      onClick={() => {
                        if (!menu.subItems) {
                          navigate(menu.path)
                        }

                        handleOpenSubItems(menu.path)
                      }}
                    >
                      <div>
                        {menu.icon}
                        <span>{menu.title}</span>
                      </div>
                      {showSubItems && (
                        <Arrow className={subItemsOpen ? styles.opened : ''} />
                      )}
                    </div>

                    <div
                      className={[
                        styles.subItems,
                        showSubItems && subItemsOpen && styles.opened,
                      ].join(' ')}
                    >
                      {menu.subItems?.map((subItem) => {
                        const shouldShowNavLink = subItem.permission.some(
                          (permission) => hasAccess(permission),
                        )

                        if (shouldShowNavLink) {
                          return (
                            <NavLink
                              to={subItem.path}
                              key={subItem.path}
                              aria-label={subItem.title}
                              className={({ isActive }) =>
                                isActive ? styles.active : ''
                              }
                            >
                              {subItem.title}
                            </NavLink>
                          )
                        }
                      })}
                    </div>
                  </li>
                </Fragment>
              )
            )
          })}
        </ul>
      </div>
      <div className={styles.user}>
        <User />
      </div>
    </nav>
  )
}

export default NavBar
