import { City } from './types'
import { Pagination, Result } from '../../types'
import HttpClient from '../../httpClient'
import { AxiosInstance } from 'axios'

export interface CityApiClient {
  queryCities(
    queryParams: Partial<City> & Partial<Pagination>,
  ): Promise<Result<City>>
  findById(cityId: string): Promise<City>
}
class CityApiClientImpl implements CityApiClient {
  public constructor(private readonly httClient: AxiosInstance = HttpClient) {}

  public queryCities = async (
    queryParams: Partial<City> & Partial<Pagination>,
  ): Promise<Result<City>> => {
    const response = await this.httClient.get<Result<City>>(
      `/address/city/query/`,
      { params: queryParams },
    )
    return response.data
  }

  public findById = async (cityId: string): Promise<City> => {
    const response = await this.httClient.get<City>(`/address/city/${cityId}`)
    return response.data
  }
}

export default new CityApiClientImpl()
