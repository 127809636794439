export const endpoints = {
  addImage: '/image',
  queryAgents: '/agentDuty/query',
  patchAccountNote: 'account/:accountId/note',
  history: '/occurrence/:occurrenceId/history',
  occurrence: '/occurrence/:occurrenceId/attendance',
  createIntervention: '/occurrence/:occurrenceId/intervention',
  createNote: '/occurrence/:occurrenceId/intervention/:interventionId/note',
  disableHabitDeviation:
    '/officeHours/partition/:partitionId/disableHabitDeviation',
  call: 'https://intranet.orsegups.com.br:443/fusion/servlet/com.neomind.fusion.custom.orsegups.servlets.RamalServlet',
}
