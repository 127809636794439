import { useState } from 'react'

import { useGetTacticalDetails, useTerminaDuty } from 'services/displacementMap'

import { Button, Modal } from 'components'
import { useMap, useDisplacementModal } from '../../../../contexts'

type RequestFeedback = {
  title: string
  success: boolean
  body: string
}

export const TerminateDuty = () => {
  const { mutate } = useTerminaDuty()
  const { handleCloseModal } = useDisplacementModal()
  const { selectedRoute } = useMap()
  const { data: tactical } = useGetTacticalDetails(selectedRoute.tactical?.id)

  const [requestFeedback, setRequestFeedback] = useState<RequestFeedback>({
    success: true,
    title: 'Plantão tático encerrado com sucesso',
    body: `${
      tactical?.agent.name || ''
    } deixará de atender ocorrências enviadas para deslocamento.`,
  })

  const onSubmit = () =>
    mutate(tactical?.id, {
      onSuccess: handleCloseModal,
      onError: () => {
        setRequestFeedback({
          success: false,
          title: 'Erro ao encerrar plantão tático',
          body: 'Não foi possível finalizar o plantão do agente Mick Jagger. Tente novamente.',
        })
      },
    })

  return (
    <Modal
      simple
      title={requestFeedback.title}
      isVisible
      onClose={handleCloseModal}
    >
      {tactical && (
        <>
          <p>{requestFeedback.body}</p>
          <Modal.Footer>
            <Button
              autofocus
              buttonTitle="Cancelar"
              onClick={handleCloseModal}
              type="secondary"
              width="172px"
            />
            {requestFeedback.success && (
              <Button
                autofocus
                buttonTitle="Continuar"
                onClick={onSubmit}
                type="primary"
                width="172px"
              />
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}
