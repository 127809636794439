import * as Joi from '@hapi/joi'

import { AccountTag, AggregatedAccount } from 'services/account/types'

import { SortedCustomer } from 'services/customer/types'
import { PatrimonyFragment } from 'services/patrimony/types'
import {
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_TYPE_ENUM,
  TagOutput,
} from 'services/serviceOrder'
import { QueryTechniciansResult } from 'services/serviceOrder/hooks/useGetTechnicians'
import { ServiceOrderLabelOutput } from 'services/serviceOrderLabel/types'
import { ServiceType } from 'services/serviceType/types'

const labelSchema = Joi.object<ServiceOrderLabelOutput>({
  id: Joi.string().uuid(),
  description: Joi.string(),
})

const commonSchema = Joi.object({
  id: Joi.string().uuid(),
  name: Joi.string().allow(''),
})

const customerSchema = Joi.object({
  id: Joi.string().uuid(),
  name: Joi.string().allow(''),
  accounts: Joi.array().items(
    Joi.object({
      id: Joi.string().uuid(),
      code: Joi.string().allow(''),
    }),
  ),
})

const patrimonySchema = Joi.object({
  id: Joi.string().uuid(),
  name: Joi.string().allow(''),
  accountId: Joi.string().uuid(),
})

const accountSchema = Joi.object({
  id: Joi.string().uuid(),
  aggregatedAccountName: Joi.string().allow(''),
})

export type ServiceOrderFiltersSchema = {
  number: number
  customer: SortedCustomer
  patrimony: PatrimonyFragment
  account: AggregatedAccount
  technicians: QueryTechniciansResult[]
  type: SERVICE_ORDER_TYPE_ENUM[]
  status: SERVICE_ORDER_STATUS_ENUM[]
  createdAtFrom: number
  createdAtTo: number
  scheduleDateFrom: number
  scheduleDateTo: number
  closedAtFrom: number
  closedAtTo: number
  tag: TagOutput
  tags: TagOutput[]
  applyAttendanceExclusivity: boolean
  hasTechnician: boolean
  accountTag: Pick<AccountTag, 'id' | 'name'>
  serviceType: Pick<ServiceType, 'id' | 'name'>
  origin: ServiceOrderLabelOutput[]
  reason: ServiceOrderLabelOutput[]
  pauseReason: ServiceOrderLabelOutput[]
  issue: ServiceOrderLabelOutput[]
  resolution: ServiceOrderLabelOutput[]
}

export const filterSchema = Joi.object<ServiceOrderFiltersSchema>({
  number: Joi.number().optional().allow('', null),
  customer: customerSchema.optional(),
  account: accountSchema.optional(),
  patrimony: patrimonySchema.optional(),
  technicians: Joi.array().items(commonSchema).optional(),
  tag: commonSchema.optional(),
  type: Joi.array().items(
    Joi.string().valid(...Object.values(SERVICE_ORDER_TYPE_ENUM)),
  ),
  status: Joi.array().items(
    Joi.string().valid(...Object.values(SERVICE_ORDER_STATUS_ENUM)),
  ),
  createdAtFrom: Joi.number(),
  createdAtTo: Joi.number(),
  scheduleDateFrom: Joi.number(),
  scheduleDateTo: Joi.number(),
  closedAtFrom: Joi.number(),
  closedAtTo: Joi.number(),
  applyAttendanceExclusivity: Joi.boolean().optional(),
  hasTechnician: Joi.boolean().optional().allow(null),
  accountTag: Joi.string().uuid().allow(null, ''),
  serviceType: Joi.string().uuid().allow(null, ''),
  origin: Joi.array().items(labelSchema).optional(),
  reason: Joi.array().items(labelSchema).optional(),
  pauseReason: Joi.array().items(labelSchema).optional(),
  issue: Joi.array().items(labelSchema).optional(),
  resolution: Joi.array().items(labelSchema).optional(),
})
