import { SERVICE_ORDER_TYPE_ENUM } from 'services/serviceOrder'

export const translateServiceOrderType: Record<
  string,
  SERVICE_ORDER_TYPE_ENUM
> = {
  Instalação: SERVICE_ORDER_TYPE_ENUM.INSTALL,
  Manutenção: SERVICE_ORDER_TYPE_ENUM.MAINTENANCE,
  'Manutenção remota': SERVICE_ORDER_TYPE_ENUM.REMOTE_MAINTENANCE,
  Desinstalação: SERVICE_ORDER_TYPE_ENUM.UNINSTALL,
}

export const conditionOccurrenceStateName = {
  CREATED: 'CREATED',
  HIDDEN: 'HIDDEN',
  AVAILABLE: 'AVAILABLE',
  IN_PROGRESS: 'IN_PROGRESS',
  TRAVEL_WAITING: 'TRAVEL_WAITING',
  TRAVEL_SENT: 'TRAVEL_SENT',
  TRAVEL_VIEWED: 'TRAVEL_VIEWED',
  TRAVEL_STARTED: 'TRAVEL_STARTED',
  TRAVEL_FINISHED: 'TRAVEL_FINISHED',
  QUEUED: 'QUEUED',
}

export type OccurrenceStateName = keyof typeof conditionOccurrenceStateName

export const convertConditionOccurrenceState: Record<
  string,
  OccurrenceStateName
> = {
  Criada: 'CREATED',
  Disponível: 'AVAILABLE',
  Oculta: 'HIDDEN',
  'Em atendimento': 'IN_PROGRESS',
  'Em observação': 'QUEUED',
  'Deslocamento em espera': 'TRAVEL_WAITING',
  'Deslocamento visualizado': 'TRAVEL_VIEWED',
  'Deslocamento iniciado': 'TRAVEL_STARTED',
  'Deslocamento no local': 'TRAVEL_FINISHED',
  'Deslocamento enviado': 'TRAVEL_SENT',
}

export const triggerOccurrenceStateName = {
  HIDDEN: 'HIDDEN',
  AVAILABLE: 'AVAILABLE',
  IN_PROGRESS: 'IN_PROGRESS',
  TRAVEL_WAITING: 'TRAVEL_WAITING',
  TRAVEL_SENT: 'TRAVEL_SENT',
  TRAVEL_VIEWED: 'TRAVEL_VIEWED',
  TRAVEL_STARTED: 'TRAVEL_STARTED',
  TRAVEL_FINISHED: 'TRAVEL_FINISHED',
  QUEUED: 'QUEUED',
}

export type TriggerOccurrenceStateName = keyof typeof triggerOccurrenceStateName

export const convertTriggerOccurrenceState: Record<
  string,
  TriggerOccurrenceStateName
> = {
  Disponível: 'AVAILABLE',
  Oculta: 'HIDDEN',
  'Em atendimento': 'IN_PROGRESS',
  'Em observação': 'QUEUED',
  'Deslocamento em espera': 'TRAVEL_WAITING',
  'Deslocamento visualizado': 'TRAVEL_VIEWED',
  'Deslocamento iniciado': 'TRAVEL_STARTED',
  'Deslocamento no local': 'TRAVEL_FINISHED',
  'Deslocamento enviado': 'TRAVEL_SENT',
}

export const actionOccurrenceStateName = {
  HIDDEN: 'HIDDEN',
  AVAILABLE: 'AVAILABLE',
  TRAVEL_WAITING: 'TRAVEL_WAITING',
  QUEUED: 'QUEUED',
  FINISHED: 'FINISHED',
}

export type ActionOccurrenceStateName = keyof typeof actionOccurrenceStateName

export const convertActionOccurrenceState: Record<
  string,
  ActionOccurrenceStateName
> = {
  Oculta: 'HIDDEN',
  Disponível: 'AVAILABLE',
  'Deslocamento em espera': 'TRAVEL_WAITING',
  'Em observação': 'QUEUED',
  Finalizada: 'FINISHED',
}

export const translatedOccurrenceState: Record<string, string> = {
  HIDDEN: 'Oculta',
  AVAILABLE: 'Disponível',
  TRAVEL_WAITING: 'Deslocamento em espera',
  QUEUED: 'Em observação',
  FINISHED: 'Finalizada',
  CREATED: 'Criada',
  IN_PROGRESS: 'Em atendimento',
  TRAVEL_VIEWED: 'Deslocamento visualizado',
  TRAVEL_STARTED: 'Deslocamento iniciado',
  TRAVEL_FINISHED: 'Deslocamento no local',
  TRAVEL_SENT: 'Deslocamento enviado',
}
