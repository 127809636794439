import { useCallback, useEffect, useMemo, useState } from 'react'

import { useForm, useFormContext } from 'react-hook-form'

import { Coverage } from '../../types'

import { CoverageList } from '../CoverageList/CoverageList'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { Breadcrumbs, Button, ContainerScreen } from 'components'
import { ReactComponent as PlusSignIcon } from 'assets/svg/plusSign.svg'

import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useGetCustomers } from 'shared/hooks/customers/useGetCustomers'

import { useGetAccounts } from 'shared/hooks/accounts/useGetAccounts'

import { AggregatedAccount } from 'services/account/types'
import { SortedCustomer } from 'services/customer/types'

import {
  AccountCoverage,
  AggregatedAttendPolicyResponse,
  CoverageGrouper,
  CustomerCoverage,
} from 'services/attendancePolicy/types'
import { joiResolver } from '@hookform/resolvers/joi'
import { coverageSchema } from 'domains/attendancePolicy/schema'
import { CoverageFormSchema } from 'domains/attendancePolicy/components/CoverageForm/types'

import { coverageKeys } from 'domains/attendancePolicy/components/CoverageForm/utilities/coverages'

import styles from './CoverageForm.module.scss'

type CoverageFormProps = {
  onNavigate: () => void
}

export const CoverageForm = ({ onNavigate }: CoverageFormProps) => {
  const { setValue: setMainFormValue, watch: watchMainForm } =
    useFormContext<AggregatedAttendPolicyResponse>()

  const storedCoverages = watchMainForm('coverages')

  const hasStoredCoverages: boolean = useMemo(
    () =>
      Object.values(storedCoverages).some((coverage) =>
        Boolean(coverage.length),
      ),
    [storedCoverages],
  )

  const form = useForm<CoverageFormSchema>({
    mode: 'onChange',
    resolver: joiResolver(coverageSchema),
  })

  const { setValue, watch, register } = form

  const [coverages, setCoverages] = useState<CoverageGrouper>(storedCoverages)

  const customer = watch('customer')
  const account = watch('account')

  const [customerFilter, setCustomerFilter] = useState('')

  const {
    customers,
    isFetching: isFetchingCustomers,
    fetchNextCustomersPage,
  } = useGetCustomers(customerFilter)

  const [accountsFilter, setAccountFilter] = useState('')

  const {
    accounts,
    isFetching: isFetchingAccounts,
    fetchNextAccountsPage,
  } = useGetAccounts(true, customer?.id, { name: accountsFilter })

  useEffect(() => {
    register('customer')
    register('account')
  }, [register])

  const handleAddCoverage = useCallback(() => {
    const addedCoverageIds = {
      customers: new Set(coverages.customers.map((customer) => customer.id)),
      accounts: new Set(coverages.accounts.map((account) => account.id)),
    }

    if (customer && !account) {
      const alreadyAdded = addedCoverageIds.customers.has(customer.id)

      if (!alreadyAdded) {
        const normalizedCustomer: CustomerCoverage = {
          id: customer.id,
          name: customer.name,
        }

        setCoverages((prev) => ({
          ...prev,
          customers: [...prev.customers, normalizedCustomer],
        }))
      }
    }

    if (account && customer) {
      const alreadyAdded = addedCoverageIds.accounts.has(account.id)

      if (alreadyAdded) return

      const normalizedAccount: AccountCoverage = {
        id: account.id,
        aggregatedAccountName: account.aggregatedAccountName,
        customer: {
          id: customer.id,
          name: customer.name,
        },
        patrimony: {
          id: account.patrimony.id,
          name: account.patrimony.name,
        },
      }

      setCoverages((prev) => ({
        ...prev,
        accounts: [...prev.accounts, normalizedAccount],
      }))
    }
  }, [coverages, customer, account])

  const handleRemoveCoverage = useCallback((coverage: Coverage) => {
    const coverageKey = coverageKeys[coverage.type]

    setCoverages((prev) => ({
      ...prev,
      [coverageKey]: (prev[coverageKey] as Coverage[]).filter(
        (item) => item.id !== coverage.id,
      ),
    }))
  }, [])

  return (
    <ContainerScreen
      clickable
      divider={false}
      renderBreadcrumbs={
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Configurações', href: '/#' },
            { title: 'Nova regra', href: '#' },
            { title: 'Abrangência', href: '#' },
          ]}
        />
      }
    >
      <div className={styles.container}>
        <div className={styles.headerSeparator} />
        <div className={styles.titleWrapper}>
          <h1>
            {hasStoredCoverages ? 'Abrangência' : 'Adicionar abrangência'}
          </h1>
          <p>
            Permite a seleção de grupos de clientes, patrimônios ou contas a fim
            de criar regras sob medida
          </p>
        </div>
        <div className={styles.formWrapper}>
          <section className={styles.firstSection}>
            <Combobox
              id="customer"
              label={{ text: 'Cliente' }}
              value={customer ? { label: 'name', value: customer } : undefined}
              items={parseDataToComboboxV2(customers || [], 'name')}
              onChange={(selected) => {
                const selectedCustomer =
                  selected as ComboboxItem<SortedCustomer>
                setValue('customer', selectedCustomer?.value, {
                  shouldDirty: true,
                })

                setValue('account', undefined, {
                  shouldDirty: true,
                })
              }}
              isLoading={isFetchingCustomers}
              onEndReached={fetchNextCustomersPage}
              onSearch={(search) => setCustomerFilter(search)}
            />

            <Combobox
              id="accounts"
              disabled={!customer}
              label={{ text: 'Conta' }}
              value={
                account
                  ? { label: 'aggregatedAccountName', value: account }
                  : undefined
              }
              items={parseDataToComboboxV2(
                accounts || [],
                'aggregatedAccountName',
              )}
              onChange={(selected) => {
                const selectedAccount =
                  selected as ComboboxItem<AggregatedAccount>
                setValue('account', selectedAccount?.value, {
                  shouldDirty: true,
                })
              }}
              isLoading={isFetchingAccounts}
              onEndReached={fetchNextAccountsPage}
              onSearch={(search) => setAccountFilter(search)}
            />

            <Button
              buttonTitle="Adicionar à lista de abrangências"
              type="tertiary"
              icon={PlusSignIcon}
              className={styles.addButton}
              onClick={handleAddCoverage}
            />
          </section>
          <section className={styles.secondSection}>
            <p>Lista de abrangências</p>
            <CoverageList
              coverageGrouper={coverages}
              onDelete={handleRemoveCoverage}
            />
          </section>
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Voltar"
            onClick={onNavigate}
            type="secondary"
            width="172px"
          />
          <Button
            buttonTitle="Salvar"
            type="primary"
            width="172px"
            onClick={() => {
              setMainFormValue('coverages', coverages)

              onNavigate()
            }}
          />
        </div>
      </div>
    </ContainerScreen>
  )
}
