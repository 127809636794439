import { Button, Modal } from '../../../../components/index'

import {
  VehicleAggregatedQueryResponse,
  VehicleDriver,
} from '../../../../services/vehicle'
import { useToast } from '../../../../shared/hooks'
import { formatLicensePlate } from '../../utilities/masks'

export interface VehicleDeleteProps {
  isVisible: boolean
  onClose(): void
  vehicle: VehicleAggregatedQueryResponse
}

export const VehicleDelete = (props: VehicleDeleteProps) => {
  const { isVisible, vehicle, onClose } = props
  const { addToast } = useToast()

  const onDeleteVehicle = async (vehicleId: string) => {
    try {
      await VehicleDriver.delete(vehicleId)
      addToast({ message: 'Viatura excluída com sucesso.' })
    } catch (error) {
      addToast({
        message: 'Não foi possível excluir a viatura. Tente novamente.',
        type: 'alert',
      })
    } finally {
      onClose()
    }
  }

  const getModalMessage = (): string => {
    if (vehicle.driver.name) {
      return `Esta viatura está em uso pelo técnico "${vehicle.driver.name}". Para excluí-la, aguarde o fim da utilização da viatura e tente novamente.`
    }
    if (vehicle.driver.id && !vehicle.driver.name) {
      return 'Esta viatura está em uso. Para excluí-la, aguarde o fim da utilização da viatura e tente novamente.'
    }
    return `Você tem certeza que deseja excluir a viatura "${formatLicensePlate(
      vehicle.licensePlate,
    )}"?`
  }

  return (
    <Modal
      simple
      title={
        vehicle.driver?.id
          ? 'Esta viatura não pode ser excluída.'
          : 'Exclusão de viatura'
      }
      isVisible={isVisible}
      onClose={onClose}
    >
      <p>{getModalMessage()}</p>
      {vehicle.driver?.id ? (
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Voltar"
            type="primary"
            id="btn-close"
            onClick={onClose}
          />
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Excluir"
            id="delete-button"
            type="cancel-primary"
            onClick={() => onDeleteVehicle(vehicle.id)}
          />
          <Button
            width="172px"
            buttonTitle="Cancelar"
            id="cancel-delete-button"
            type="secondary"
            onClick={onClose}
          />
        </Modal.Footer>
      )}
    </Modal>
  )
}
