import { useUserInfo } from '../userInfo/useUserInfo'
import { UserInfoResponse } from '../../../services/auth'

export type PermissionHook = {
  userInfo: UserInfoResponse | undefined
  hasAccess: (permission: string) => boolean
}

export const usePermissions = (): PermissionHook => {
  const { userInfo } = useUserInfo()

  const hasAccess = (permissionName: string) => {
    if (userInfo?.permissions) {
      if (permissionName in userInfo?.permissions) {
        return userInfo?.permissions[permissionName]
      }
    }
    return false
  }

  return {
    hasAccess,
    userInfo,
  }
}
