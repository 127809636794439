import { useMutation } from '@tanstack/react-query'
import CommandService from 'services/command'
import { WriteCommandPayload } from 'services/command/types'

async function sendWriteCommand(
  centralId: string,
  payload: WriteCommandPayload,
) {
  await CommandService.write(centralId, payload)
}

export const usePostWriteCommand = (
  centralId: string,
  payload: WriteCommandPayload,
) => {
  const { mutate: mutateInfoRequest, status: infoRequestStatus } = useMutation({
    mutationFn: (data: Partial<WriteCommandPayload>) => {
      return sendWriteCommand(centralId, { ...data, ...payload })
    },
  })

  return {
    mutateWriteCommand: mutateInfoRequest,
    writeCommandMutationStatus: infoRequestStatus,
  }
}
