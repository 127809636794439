import { useQuery } from '@tanstack/react-query'
import { AuthDriver, UserInfoResponse } from 'services/auth'

type UseGetUserInfo = {
  userInfo: UserInfoResponse | undefined
  isFetching: boolean
}

const query = async (userId: string) => {
  const result = await AuthDriver.getUserInfo(userId)
  return result
}

export const useGetUserInfo = (
  userId: string,
  enabled?: boolean,
): UseGetUserInfo => {
  const { data: userInfo, isFetching } = useQuery({
    queryKey: ['userInfo', userId],
    queryFn: async () => await query(userId),
    enabled,
  })

  return {
    userInfo,
    isFetching,
  }
}
