import { ChangeEvent, useCallback } from 'react'
import ReactDOM from 'react-dom'

import { ReactComponent as CloseButton } from 'assets/svg/close.svg'

import { useFormContext } from 'react-hook-form'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'

import styles from './Filters.module.scss'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { Button, Datepicker } from 'components'
import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import { COVERAGE_HOLIDAY_EN_PT, typeHoliday } from '../../types'
import {
  convertHolidayCoverageType,
  getHolidayRepeatableTerm,
  translatedHolidayCoverageType,
} from 'domains/holiday/utilities'
import { HolidayFilters } from 'domains/holiday/schemas/searchHolidaySchema'

export type FilterComponentProps = {
  isVisible: boolean
  onClose: () => void
  onApplyFilters: () => void
}

export const Filters = ({
  isVisible,
  onApplyFilters,
  onClose,
}: FilterComponentProps) => {
  const { watch, setValue, reset, register } = useFormContext<HolidayFilters>()

  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const coverageType = watch('coverageType')
  const repeatable = watch('repeatable')
  const createdFrom = watch('createdFrom')
  const createdTo = watch('createdTo')

  const handleChangeScheduleDateInput = useCallback(
    (formKey: keyof HolidayFilters, event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        const date = convertStringToDate(value)
        setValue(formKey, date.getTime())
        return value
      } else {
        return value
      }
    },
    [setValue],
  )

  return ReactDOM.createPortal(
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onApplyFilters()
      }}
      className={[styles.container, isVisible ? styles.show : styles.hide]
        .filter(Boolean)
        .join(' ')}
      data-testid="Filtros"
    >
      <div className={styles.innerWrapper}>
        <div className={styles.header}>
          <span>Filtros</span>
          <CloseButton
            height={16}
            width={16}
            onClick={onClose}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.contentWrapper}>
          <Combobox
            id="coverageType-input"
            label={{
              text: 'Abrangência',
            }}
            value={
              coverageType
                ? translatedHolidayCoverageType[coverageType]
                : undefined
            }
            {...register('coverageType')}
            onChange={(selected) => {
              const selectedValue = selected as string

              setValue(
                'coverageType',
                convertHolidayCoverageType[selectedValue],
                {
                  shouldValidate: true,
                },
              )
            }}
            items={Object.values(COVERAGE_HOLIDAY_EN_PT)}
          />
          <Combobox
            id="repeatable-input"
            label={{
              text: 'Tipo',
            }}
            {...register('repeatable')}
            value={getHolidayRepeatableTerm(repeatable)}
            onChange={(selected) => {
              const selectedValue = selected === 'Móvel'
              setValue('repeatable', selectedValue, {
                shouldValidate: true,
              })
            }}
            items={Object.values(typeHoliday)}
          />
          <div className={styles.datePickerWrapper}>
            <p className={styles.sectionLabel}>Data</p>
            <div className={styles.datePickerInnerWrapper}>
              <span className={styles.dateLabel}>De</span>
              <Datepicker
                id="createdFrom"
                initialValueInput={
                  createdFrom
                    ? convertDateToString(new Date(createdFrom))
                    : undefined
                }
                initialDate={createdFrom ? new Date(createdFrom) : undefined}
                onChangeInput={(event) =>
                  handleChangeScheduleDateInput('createdFrom', event)
                }
                onChangeDate={(date) =>
                  setValue('createdFrom', date?.getTime())
                }
                {...register('createdFrom')}
              />
              <span className={styles.dateLabel}>até</span>
              <Datepicker
                id="createdTo"
                disabled={!createdFrom}
                selectEnd
                initialValueInput={
                  createdTo
                    ? convertDateToString(new Date(createdTo))
                    : undefined
                }
                initialDate={createdFrom ? new Date(createdFrom) : undefined}
                onChangeInput={(event) =>
                  handleChangeScheduleDateInput('createdTo', event)
                }
                onChangeDate={(date) => setValue('createdTo', date?.getTime())}
                errorMessage={
                  createdFrom && !createdTo ? 'Campo obrigatório.' : ''
                }
                {...register('createdTo')}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              clearAllLocalStorageFilters()
              setValue('repeatable', undefined)
              reset({})
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            width="162px"
            htmlType="submit"
          />
        </div>
      </div>
    </form>,
    document.getElementById('main-container') || document.body,
  )
}
