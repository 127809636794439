import { useQuery } from '@tanstack/react-query'

import { Result } from 'services/types'
import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { Intervention } from './types'

export const useGetInterventions = (serviceOrderId?: string) => {
  return useQuery({
    queryKey: ['service-order-intervention', serviceOrderId],
    queryFn: async () => {
      const response = await HttpClient.get<Result<Intervention>>(
        buildUrl({
          route: endpoints.getIntervention,
          params: { serviceOrderId },
        }),
      )

      return response.data
    },
    enabled: !!serviceOrderId,
  })
}
