import * as Joi from '@hapi/joi'
import { StringSchemeErrorsEnum } from 'utilities/validation'

export type UpdateDeviceSchema = {
  centralId: string
  id: string
  name?: string
  partitions: {
    id: string
    name: string
  }[]
}

export const updateDeviceSchema = Joi.object<UpdateDeviceSchema>({
  centralId: Joi.string().uuid().required(),
  id: Joi.string().uuid().required(),
  name: Joi.string()
    .optional()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]: 'O campo nome precisa ser preenchido.',
    }),
  partitions: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().uuid(),
        name: Joi.string(),
      }),
    )
    .required()
    .min(1)
    .messages({
      [StringSchemeErrorsEnum.REQUIRED]: 'O campo é obrigatório.',
    }),
})
