import { Paging, QueryRange } from 'services/types'
import { Account, AccountPayload, AccountTag } from '../account/types'

export type PatrimonyWithAccount = {
  id: string
  name: string
  responsible: string
  postalCode: string
  address: string
  number: string
  adjunct: string
  reference: string
  latitude: number
  longitude: number
  timezone: string
  customerId: string
  districtId: string
  note: string
  keyNumber: number
  active: boolean
  activeDateTime: number
  createdAt: number
  updatedAt: number
  tags?: AccountTag[]
  stateId: string
  stateAcronym: string
  stateName: string
  cityId: string
  cityName: string
  districtName: string
  accounts?: Account[]
  phone?: {
    countryCode: number
    provinceCode: number
    phoneNumber: number
  }
  patrimonyImageId?: string
}

export type PatrimonyFragment = {
  id: string
  name: string
  accountCode: string
  accountId: string
}

export type PatrimonyImage = {
  id: string
  name: string
  imageId?: string
  address: {
    acronym: string
    city: string
    district: string
    number: string
    state: string
    street: string
  }
}

export type PatrimonyWithAccountTagRequest = {
  name: string
  type: string
}

export type AccountOnPatrimonyWithAccountRequest = {
  id: string
  name: string
  securityQuestion: string
  securityAnswer: string
  securityCoercionAnswer: string
  active: boolean
  tags?: PatrimonyWithAccountTagRequest[]
}

export type patrimonyPhone = {
  countryCode: number
  provinceCode: number
  phoneNumber: number
}

export type UpdatePatrimony = {
  name: string
  postalCode: number
  address: string
  number: string
  adjunct?: string
  reference?: string
  latitude: number
  longitude: number
  districtId?: string
  note?: string
  patrimonyId?: string
  customerId?: string
  timezone?: string
  keyNumber?: number
  tags?: PatrimonyWithAccountTagRequest[]
  phone?: patrimonyPhone
  responsible?: string
  intrusionConfirmed?: boolean
  state?: string
  city?: string
  district?: string
}

export type PatrimonyPayload = Omit<
  UpdatePatrimony,
  | 'patrimonyId'
  | 'timezone'
  | 'keyNumber'
  | 'tags'
  | 'intrusionConfirmed'
  | 'latitude'
  | 'longitude'
> & {
  id?: string
  districtName?: string
  stateName?: string
  cityName?: string
  imageId?: string
}

export type PatrimonyWithAccountRequest = {
  patrimony: PatrimonyPayload & { id: string; customerId: string }
  accounts?: AccountPayload[]
  customerId?: string
}

export enum PartitionStatus {
  'ARMED' = 'A',
  'ARMED_STAY' = 'S',
  'DISARMED' = 'D',
}

export type PartitionListResponse = {
  id: string
  centralId: string
  code: string
  name: string
  status: PartitionStatus
  remoteControl: number
  passiveInfraRedSensor: number
  passiveInfraPhotoRedSensor: number
  switch: number
  reedSwitch: number
  wirelessSiren: number
  totalContacts: number
  createdAt: number
  updatedAt: number
}

export type PatrimonyQuery = {
  name?: string
  customerId?: string
  stateId?: string
  cityId?: string
  districtId?: string
  address?: string
  number?: number
  postalCode?: number
  active?: boolean
  sort?: string
  sortType?: string
  tagIds?: string[]
} & Partial<Paging> &
  Partial<QueryRange>
