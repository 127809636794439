import { useState } from 'react'

import { TabBar } from 'components'
import { PartitionDevice } from 'services/partition/types'
import { ServiceOrderValidationInfo } from 'services/serviceOrder'
import { SERVICE_ORDER_STATUS_HEADER } from 'domains/serviceOrders/types'

import styles from './styles.module.scss'
import EquipmentsInfo from '../EquipmentsInfo'
import { SearchEquipmentsEvent } from 'domains/serviceOrders/screens/ServiceOrderInfo/components/SearchEquipmentsEvent/SearchEquipmentsEvent'

interface ServiceOrderSnapshotProps {
  serviceOrderInfo: ServiceOrderValidationInfo
  handleSelect: (device: PartitionDevice) => void
  onPressGallery?: () => void
}

const ServiceOrderSnapshot = ({
  serviceOrderInfo,
  handleSelect,
  onPressGallery,
}: ServiceOrderSnapshotProps) => {
  const [serviceOrderStatus, setServiceOrderStatus] =
    useState<SERVICE_ORDER_STATUS_HEADER>(
      SERVICE_ORDER_STATUS_HEADER.SYSTEM_BEFORE_SO,
    )

  const [selectedDeviceId, setSelectedDeviceId] = useState('')

  const handleActionOnClick = (device: PartitionDevice) => {
    if (device.model?.name) {
      setSelectedDeviceId(device.id)
      setServiceOrderStatus(SERVICE_ORDER_STATUS_HEADER.EVENTS)
    } else {
      handleSelect(device)
    }
  }

  return (
    <TabBar className={styles.customTabWrapper}>
      <TabBar.Tab
        id={SERVICE_ORDER_STATUS_HEADER.SYSTEM_BEFORE_SO}
        idSelected={serviceOrderStatus}
        label="Sistema antes da OS"
        onClick={() =>
          setServiceOrderStatus(SERVICE_ORDER_STATUS_HEADER.SYSTEM_BEFORE_SO)
        }
      >
        {serviceOrderInfo.centralInfo?.inProgressSnapshot && (
          <EquipmentsInfo
            serviceOrder={{
              serviceOrderId: serviceOrderInfo?.id,
              customerId: serviceOrderInfo?.serviceOrderInfo?.customer?.id,
              accountId: serviceOrderInfo?.serviceOrderInfo?.account?.id,
            }}
            centralInfo={serviceOrderInfo.centralInfo.inProgressSnapshot}
            model={serviceOrderInfo.centralInfo?.model}
          />
        )}
      </TabBar.Tab>
      <TabBar.Tab
        id={SERVICE_ORDER_STATUS_HEADER.SYSTEM_AFTER_SO}
        idSelected={serviceOrderStatus}
        label="Sistema após a OS"
        onClick={() =>
          setServiceOrderStatus(SERVICE_ORDER_STATUS_HEADER.SYSTEM_AFTER_SO)
        }
      >
        {serviceOrderInfo.centralInfo?.finishedSnapshot && (
          <EquipmentsInfo
            centralInfo={serviceOrderInfo.centralInfo.finishedSnapshot}
            model={serviceOrderInfo.centralInfo?.model}
            onPressGallery={onPressGallery}
            handleSelect={handleActionOnClick}
            serviceOrder={{
              serviceOrderId: serviceOrderInfo?.id,
              customerId: serviceOrderInfo?.serviceOrderInfo?.customer?.id,
              accountId: serviceOrderInfo?.serviceOrderInfo?.account?.id,
            }}
          />
        )}
        {serviceOrderInfo.centralInfo?.currentShadow && (
          <EquipmentsInfo
            centralInfo={serviceOrderInfo.centralInfo.currentShadow}
            model={serviceOrderInfo.centralInfo?.model}
            onPressGallery={onPressGallery}
            handleSelect={handleActionOnClick}
            serviceOrder={{
              serviceOrderId: serviceOrderInfo?.id,
              customerId: serviceOrderInfo?.serviceOrderInfo?.customer?.id,
              accountId: serviceOrderInfo?.serviceOrderInfo?.account?.id,
            }}
          />
        )}
      </TabBar.Tab>
      {!serviceOrderInfo.centralInfo?.hasCommandSupport && (
        <TabBar.Tab
          id={SERVICE_ORDER_STATUS_HEADER.EVENTS}
          idSelected={serviceOrderStatus}
          label="Eventos"
          onClick={() => {
            setSelectedDeviceId('')
            setServiceOrderStatus(SERVICE_ORDER_STATUS_HEADER.EVENTS)
          }}
        >
          <SearchEquipmentsEvent
            serviceOrderInfo={serviceOrderInfo.serviceOrderInfo}
            accountId={serviceOrderInfo.serviceOrderInfo.account.id}
            deviceId={selectedDeviceId}
          />
        </TabBar.Tab>
      )}
    </TabBar>
  )
}

export default ServiceOrderSnapshot
