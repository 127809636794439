import { useNavigate } from 'react-router-dom'

import { dateNow } from 'utilities/date'
import { useQueryEvent } from 'services/event'
import { handleRenderState } from 'utilities/handleRenderState'
import { EmptyStateV2, ErrorState, OccurrenceTag, Tag } from 'components'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import Loading from './Loading'
import styles from './styles.module.scss'

const EventHistory = () => {
  const navigate = useNavigate()

  const { serviceOrder } = useServiceOrderDetails()

  const { data, isLoading, isError } = useQueryEvent({
    accountId: serviceOrder?.accountId,
    fields: ['id', 'eventType', 'datetime', 'occurrenceId'],
    page: 1,
    size: 4,
  })

  return (
    <ul className={styles.history}>
      {
        {
          view: data?.data.map((event) => {
            const typeName = event.eventType?.occurrenceTypeName || 'SCHEDULED'

            return (
              <li
                className={styles[typeName]}
                key={event.id}
                onClick={() => {
                  if (event.occurrenceId) {
                    navigate(`/occurrence/attendance/${event.occurrenceId}`, {
                      state: {
                        readonly: true,
                      },
                    })
                  }
                }}
              >
                <div>
                  <OccurrenceTag typeName={typeName} />
                  {event.partition?.name && (
                    <Tag color="outlined">{event.partition.name}</Tag>
                  )}
                </div>
                <span>
                  {event.eventType?.code} - {event.eventType?.description}
                </span>
                <span>{dateNow(event.datetime)}</span>
              </li>
            )
          }),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyStateV2 />,
        }[
          handleRenderState({
            data,
            isLoading,
            isError,
            isEmpty: !data?.totalElements,
          })
        ]
      }
    </ul>
  )
}

export default EventHistory
