import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import StateDriver, { State } from 'services/address/state'
import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'

async function getStates(offset = 0, filter?: string) {
  const result = await StateDriver.queryStates({
    recordsPerPage: 15,
    offset,
    ...(filter && { name: filter }),
  })
  return result
}

export const useGetStates = (enabled: boolean, filter?: string) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['states', filter],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => getStates(pageParam, filter),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<State>(lastPage, allPages),
    enabled,
  })

  const states = useMemo(() => {
    return flattenPages<State>(infiniteQuery?.data)
  }, [infiniteQuery])

  return { ...infiniteQuery, states }
}
