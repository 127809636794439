import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import HttpClient from '../httpClient'
import { Result } from 'services/types'
import {
  CentralAggregatedResponse,
  CentralFindByIdResult,
  CentralResponse,
  CreateCentralPayload,
} from './types'

const queryCentralByAccounts = async (accountIds?: string[]) => {
  if (!accountIds) return
  const response = await HttpClient.get<Result<CentralAggregatedResponse>>(
    '/central/queryByAccounts',
    {
      params: {
        accountIds,
      },
    },
  )
  return response.data
}

export function useQueryCentralByAccounts(
  accountIds?: string[],
): UseQueryResult<Result<CentralAggregatedResponse>> {
  return useQuery({
    queryKey: ['queryCentralByAccounts', accountIds],
    queryFn: async () => await queryCentralByAccounts(accountIds),
    enabled: !!accountIds?.length,
  })
}

const getCentral = async (centralId?: string) => {
  if (!centralId) return
  const response = await HttpClient.get<CentralResponse>(
    `/central/${centralId}`,
  )
  return response.data
}

export function useGetCentral(
  centralId: string,
): UseQueryResult<CentralFindByIdResult> {
  return useQuery({
    queryKey: ['central', centralId],
    queryFn: () => getCentral(centralId),
    enabled: !!centralId,
  })
}

type CentralCreateResponse = {
  centralId: string
}

export const usePostCentral = () => {
  const { mutate, status } = useMutation({
    mutationFn: async (payload: CreateCentralPayload) => {
      const response = await HttpClient.post<CentralCreateResponse>(
        '/central',
        {
          central: {
            code: payload.central.code,
            modelId: payload.central.model.id,
            externalId: payload.central?.externalId,
            topicGroup: payload.central?.topicGroup,
          },
          contacts: payload.contacts,
          partitions: payload.partitions,
          accountId: payload.account?.id,
        },
      )
      return response.data
    },
  })

  return {
    mutatePostCentral: mutate,
    statusPostCentral: status,
  }
}

export const usePatchCentral = (centralId: string) => {
  const { mutate, status } = useMutation({
    mutationFn: async (payload: CreateCentralPayload) => {
      const response = await HttpClient.patch(`/central/${centralId}`, {
        central: {
          code: payload.central.code,
          modelId: payload.central.model?.id,
          externalId: payload.central?.externalId,
          topicGroup: payload.central?.topicGroup,
        },
        contacts: payload.contacts,
        partitions: payload.partitions,
      })

      return response.data
    },
  })

  return {
    mutatePatchCentral: mutate,
    statusPatchCentral: status,
  }
}
