import { Button, Modal } from 'components'

export interface OfficeHoursDeleteProps {
  isVisible: boolean
  onClose: () => void
  onSubmit: () => void
  officeHoursName: string
}

export const OfficeHoursDelete = (props: OfficeHoursDeleteProps) => {
  const { isVisible, onSubmit, onClose, officeHoursName } = props

  return (
    <Modal
      simple
      title={'Exclusão de horário'}
      isVisible={isVisible}
      onClose={onClose}
    >
      <p>Você tem certeza que deseja excluir o horário “{officeHoursName}”?</p>
      <Modal.Footer>
        <Button
          width="172px"
          buttonTitle="Cancelar"
          id="cancel-delete-button"
          type="secondary"
          onClick={onClose}
        />

        <Button
          width="172px"
          buttonTitle="Excluir"
          id="delete-button"
          type="cancel-primary"
          onClick={onSubmit}
        />
      </Modal.Footer>
    </Modal>
  )
}
