import { FC, useMemo, useState } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'

import { Button, ComboboxItem, Modal } from 'components'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useGetOccurrenceOnAttendance } from 'services/attendance'
import { RouteParams } from 'domains/occurrence/screens/Attendance/types'
import {
  useModal,
  useFilter,
} from 'domains/occurrence/screens/Attendance/context'
import { PartitionOnAttendance } from 'services/attendance/types'

type FormProps = {
  partition: PartitionOnAttendance
}

export const schema = Joi.object<FormProps>({
  partition: Joi.object().required(),
})

const Filter: FC = () => {
  const { occurrenceId } = useParams<RouteParams>()
  const { handleCloseModal } = useModal()
  const { filter, handleFilter } = useFilter()
  const { data, isLoading, isError } =
    useGetOccurrenceOnAttendance(occurrenceId)

  const [searchValue, setSearchValue] = useState('')

  const {
    setValue,
    register,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormProps>({
    defaultValues: {
      partition: filter?.partition,
    },
    resolver: joiResolver(schema),
  })

  const comboBoxItems = useMemo(
    () =>
      parseDataToComboboxV2(
        data?.partitions.filter(
          (partition) =>
            data.occurrence.partitionIds.includes(partition.id) &&
            partition.name.toLowerCase().includes(searchValue.toLowerCase()),
        ) || [],
        'name',
      ),
    [data, searchValue],
  )

  const onSubmit = (data: FormProps) => {
    handleFilter(data)
    handleCloseModal()
  }

  return (
    <Modal
      isVisible
      onClose={handleCloseModal}
      title="Filtrar evento por partição"
      size="sm"
      simple
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Combobox
          label={{
            text: 'Partição',
          }}
          isError={isError}
          items={comboBoxItems}
          isLoading={isLoading}
          onSearch={setSearchValue}
          {...register('partition')}
          value={watch('partition')?.name || ''}
          onChange={(selected) => {
            const selectedPartition =
              selected as ComboboxItem<PartitionOnAttendance>

            setValue('partition', selectedPartition.value, {
              shouldValidate: true,
            })
          }}
        />
        <Modal.Footer>
          <Button
            width="186px"
            buttonTitle="Cancelar"
            onClick={handleCloseModal}
            type="secondary"
          />
          <Button
            width="186px"
            type="primary"
            htmlType="submit"
            disabled={!isValid}
            buttonTitle="Filtrar"
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Filter
