import { useCallback, useMemo } from 'react'

import { Modal, Button, Loader } from 'components'
import { useToast, useUserInfo } from 'shared/hooks'
import { PartitionStatus } from 'services/patrimony/types'
import { usePostAndHandleMaintenanceCommand } from 'domains/customer/screens/Equipments/hooks/services/commands'
import {
  CENTRAL_MODE,
  CentralAggregatedResponse,
  PartitionFragment,
} from 'services/central/types'

type CentralMaintenanceModalProps = {
  isVisible: boolean
  onHandleCloseModal: () => void
  central: CentralAggregatedResponse
  partitions?: PartitionFragment[]
}

export const CentralMaintenanceModal = ({
  isVisible,
  onHandleCloseModal,
  central,
  partitions,
}: CentralMaintenanceModalProps) => {
  const { userInfo } = useUserInfo()

  const { onHandleCentralMaintenanceMode, CentralMaintenanceModeIsLoading } =
    usePostAndHandleMaintenanceCommand(central.id, {
      userId: userInfo.id,
    })

  const { addToast } = useToast()

  const centralIsInMaintenanceMode = central.mode === CENTRAL_MODE.MAINTENANCE

  const modalInformation = useMemo(() => {
    if (centralIsInMaintenanceMode) {
      return {
        title: 'Parar manutenção de central',
        content:
          'Você tem certeza que deseja parar a manutenção desta central?',
      }
    }

    return {
      title: 'Iniciar manutenção de central',
      content:
        'Você tem certeza que deseja colocar esta central em manutenção?',
    }
  }, [centralIsInMaintenanceMode])

  const onHandleMaintenanceMode = useCallback(() => {
    if (!central.connected) {
      addToast({
        message:
          'Não é possível iniciar manutenção pois a central parece estar offline.',
        type: 'alert',
      })
      return onHandleCloseModal()
    }

    if (!centralIsInMaintenanceMode) {
      if (
        partitions?.some(
          (partition) => partition.status !== PartitionStatus.DISARMED,
        )
      ) {
        addToast({
          message:
            'Não é possível iniciar manutenção pois a central está armada.',
          type: 'alert',
        })
        return onHandleCloseModal()
      }

      if (central.mode === CENTRAL_MODE.STANDBY) {
        addToast({
          message:
            'Não é possível iniciar manutenção pois a central está em stand by.',
          type: 'alert',
        })
        return onHandleCloseModal()
      }
    }

    onHandleCentralMaintenanceMode(centralIsInMaintenanceMode)
    onHandleCloseModal()
  }, [
    centralIsInMaintenanceMode,
    onHandleCloseModal,
    central.connected,
    central.mode,
    addToast,
    onHandleCentralMaintenanceMode,
    partitions,
  ])

  return (
    <>
      <Loader isVisible={CentralMaintenanceModeIsLoading} />

      <Modal
        isVisible={isVisible}
        onClose={onHandleCloseModal}
        title={modalInformation.title}
        simple
      >
        <p>{modalInformation.content}</p>
        <Modal.Footer>
          <Button
            buttonTitle="Cancelar"
            type="secondary"
            width="172px"
            onClick={onHandleCloseModal}
          />
          <Button
            buttonTitle="Continuar"
            type="primary"
            width="172px"
            onClick={onHandleMaintenanceMode}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
