import { useMutation } from '@tanstack/react-query'
import { ConfigurePhotoSensorCommandPayload } from 'services/command/types'
import CommandService from 'services/command'
import { useUserInfo } from 'shared/hooks/userInfo/useUserInfo'

export type ConfigurePhotoSensorMutationPayload = Omit<
  ConfigurePhotoSensorCommandPayload,
  'userId'
> & {
  centralId: string
}

async function sendConfigurePhotosensorCommand(
  payload: ConfigurePhotoSensorMutationPayload,
  userId: string,
) {
  const { centralId, ...rest } = payload
  await CommandService.configurePhotosensor(centralId, {
    ...rest,
    userId,
  })
}

export const usePostConfigurePhotosensorCommand = () => {
  const { userInfo } = useUserInfo()
  const {
    mutate: mutateConfigurePhotosensorCommand,
    status: configurePhotosensorCommandMutationStatus,
  } = useMutation({
    mutationFn: async (payload: ConfigurePhotoSensorMutationPayload) =>
      await sendConfigurePhotosensorCommand(payload, userInfo.id),
    retry: false,
  })

  return {
    mutateConfigurePhotosensorCommand,
    configurePhotosensorCommandMutationStatus,
  }
}
