import { ComponentProps, forwardRef } from 'react'

import joinClassNames from 'utilities/joinClassNames'

import styles from './styles.module.scss'

interface ToggleProps extends Omit<ComponentProps<'input'>, 'type' | 'size'> {
  size?: 'md' | 'lg'
}

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ className, size = 'md', children, ...props }, ref) => (
    <input
      {...props}
      ref={ref}
      type="checkbox"
      className={joinClassNames(styles.toggle, styles[size], className)}
    />
  ),
)

Toggle.displayName = 'Toggle'

export default Toggle
