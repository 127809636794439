import { useMutation } from '@tanstack/react-query'
import { AttendancePolicyDriver } from 'services/attendancePolicy'
import { AttendancePolicyPayload } from 'services/attendancePolicy/types'

function createAttendancePolicy(
  payload: AttendancePolicyPayload,
): Promise<void> {
  return AttendancePolicyDriver.create(payload)
}

export const usePostAttendancePolicy = () => {
  const { status, mutate } = useMutation({
    mutationFn: (payload: AttendancePolicyPayload) =>
      createAttendancePolicy(payload),
  })

  return {
    mutateCreateAttendancePolicy: mutate,
    statusCreateAttendancePolicy: status,
  }
}
