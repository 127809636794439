export function generateHoursAndMinutesForHiddenZoneTo(date: Date) {
  const hoursAndMinutes = []

  let hour =
    new Date().getMinutes() > 30
      ? new Date().getHours() + 1
      : new Date().getHours()
  let minutes = new Date().getMinutes() < 30 ? 30 : 0

  if (date > new Date()) {
    hour = 0
    minutes = 0
  }

  while (hour <= 23) {
    const formatedHoursAndMinutes = `${hour
      .toString()
      .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

    hoursAndMinutes.push(formatedHoursAndMinutes)

    minutes += 30
    if (minutes === 60) {
      minutes = 0
      hour++
    }
  }

  return hoursAndMinutes
}

export function aggregatesDateTimeAndReturnMilliseconds(
  data: Date,
  hoursAndMinutes: string,
) {
  const date = data

  date.setHours(Number(hoursAndMinutes.split(':')[0]))
  date.setMinutes(Number(hoursAndMinutes.split(':')[1]))
  date.setSeconds(0)

  return date.getTime()
}

export function formatHiddenZoneToForTooltip(timestamp: number) {
  const date = new Date(timestamp)

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  const hours = date.getHours()
  const minutes = date.getMinutes()

  return `${day.toString().padStart(2, '0')}/${(month + 1)
    .toString()
    .padStart(2, '0')}/${year} às ${hours
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}
