import React from 'react'
import styles from './styles.module.scss'

interface TabProps {
  id: string
  icon?: JSX.Element
  label: string
  onActivate: () => void
  isActive: boolean
}

const Tab: React.FC<TabProps> = ({ id, icon, label, onActivate, isActive }) => {
  return (
    <li
      id={`tab-${id}`}
      className={`${styles.tab} ${isActive ? styles.active : ''}`}
      onClick={onActivate}
      role="tab"
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
    >
      {icon && icon}
      {label}
    </li>
  )
}

export default Tab
