import { Policy, PolicyGroup } from 'domains/attendancePolicy/types'

import { Action } from 'services/attendancePolicy/types'

import { actionTypeLabel } from 'domains/attendancePolicy/components/RuleForm/utilities/action/getActionLabelFragment'

export const getResumeActions = (
  actions: Action[],
): Policy | PolicyGroup[] | undefined => {
  const hasOnlyOneAction = actions.length === 1
  const hasMoreOneAction = actions.length > 1

  if (!actions.length) return undefined

  if (hasOnlyOneAction) {
    const [action] = actions

    const { resume } = actionTypeLabel[action.type](action.parameters)

    return {
      title: resume,
    }
  }

  if (hasMoreOneAction) {
    const actionsGroup: PolicyGroup[] = [
      {
        allSubPolicies: true,
        policies: actions
          .sort((prev, next) => prev.index - next.index)
          .map((action) => {
            const { resume } = actionTypeLabel[action.type](action.parameters)

            return {
              title: resume,
            }
          }),
      },
    ]

    return actionsGroup
  }
}

export const getActionAccordionTitle = (
  action: Pick<Action, 'type' | 'parameters'>,
) => {
  const { accordion } = actionTypeLabel[action.type](action.parameters)

  return accordion
}
