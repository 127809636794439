import { useEffect } from 'react'
import { useToast, useUserInfo } from 'shared/hooks'
import { usePostRebootCommand } from 'shared/hooks/services/commands'

export const usePostAndHandleRebootCommand = (centralId: string) => {
  const { addToast } = useToast()
  const { userInfo } = useUserInfo()

  const { mutateRebootCommand, rebootCommandMutationStatus } =
    usePostRebootCommand(centralId, {
      userId: userInfo.id,
    })

  useEffect(() => {
    if (rebootCommandMutationStatus === 'success') {
      addToast({
        message: 'Central reiniciada com sucesso.',
      })
    }

    if (rebootCommandMutationStatus === 'error') {
      addToast({
        message: 'Erro ao reiniciar central. Tente novamente.',
        type: 'alert',
      })
    }
  }, [rebootCommandMutationStatus, addToast])

  return { mutateRebootCommand, rebootCommandMutationStatus }
}
