import { ReactNode, useCallback, useEffect, ComponentProps, FC } from 'react'

interface BackDropProps extends ComponentProps<'div'> {
  onClose: () => void
  children: ReactNode
}

const BackDrop: FC<BackDropProps> = ({ onClose, ...props }) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return <div {...props} />
}

export default BackDrop
