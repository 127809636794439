import { ScalePeriod } from 'services/officeHours/types'
import { PartitionStatus } from 'services/patrimony/types'

export const modifyTimeByMinutes = (value?: string, minutes = 1): string => {
  if (value) {
    const date = new Date(`1970-01-01T${value}:00`)

    date.setMinutes(date.getMinutes() + minutes)

    return date.toLocaleTimeString('pt-BR', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
  }

  return ''
}

export function generateTimeSlots(interval: number): string[] {
  const timeSlots: string[] = []
  const minutesPerDay = 24 * 60

  for (let minutes = 0; minutes <= minutesPerDay; minutes += interval) {
    const hours = Math.floor(minutes / 60)
    const minutesFormatted = minutes % 60

    const hoursFormatted = String(hours % 24).padStart(2, '0')
    const minutesFormattedString = String(minutesFormatted).padStart(2, '0')

    const timeSlot = `${hoursFormatted}:${minutesFormattedString}`
    timeSlots.push(timeSlot)
  }

  return timeSlots
}

const time = generateTimeSlots(30)

type GetRangeBetWeenTimes = {
  startTime?: string
  endTime?: string
  slots?: string[]
  startOffset?: number
  endOffset?: number
}

export function getRangeBetweenTimes({
  startTime,
  endTime,
  slots = time,
  startOffset = 0,
  endOffset = 0,
}: GetRangeBetWeenTimes): string[] {
  const startTimeIndex = startTime
    ? slots.findIndex((timeSlot) => timeSlot === startTime) + startOffset
    : 0

  const endTimeIndex = endTime
    ? slots.findIndex(
        (timeSlot) =>
          timeSlot === endTime && timeSlot !== slots[slots.length - 1],
      ) + endOffset
    : slots.length

  return slots.slice(startTimeIndex, endTimeIndex)
}

export function fillRemainingTimeSlots(periods: ScalePeriod[]): ScalePeriod[] {
  const result: ScalePeriod[] = []

  const sortedPeriods = periods.sort(
    (a, b) =>
      new Date(`1970-01-01T${a.startTime}:00`).getTime() -
      new Date(`1970-01-01T${b.startTime}:00`).getTime(),
  )

  sortedPeriods.forEach((period, index) => {
    if (period.startTime === '00:00') return

    const previousEndTime =
      modifyTimeByMinutes(sortedPeriods[index - 1]?.endTime) ??
      time[time.length - 1]

    const rangeTime = getRangeBetweenTimes({
      startTime: previousEndTime,
      endTime: period.startTime,
      endOffset: 1,
    })

    const startTime = index === 0 ? '00:00' : rangeTime[0]
    const endTime = modifyTimeByMinutes(rangeTime[rangeTime.length - 1], -1)

    result.push({ startTime, endTime, partitionStatus: PartitionStatus.ARMED })
  })

  const lastPeriod = periods[periods.length - 1]

  if (lastPeriod?.endTime !== '23:59') {
    const fillRestTime = getRangeBetweenTimes({
      startTime: modifyTimeByMinutes(lastPeriod.endTime),
      endTime: '23:59',
    })
    result.push({
      startTime: fillRestTime[0],
      endTime: '23:59',
      partitionStatus: PartitionStatus.ARMED,
    })
  }

  return [...result, ...periods]
}
