import { ContactAccountFragment } from 'services/contact/types'
import { ContactType } from 'services/partition/types'

export const getContactTypeLabel = (type: ContactType): string => {
  switch (type) {
    case 'ADMIN':
      return 'Administrador'
    case 'GUEST':
      return 'Convidado'
    case 'CONTACT':
      return 'Emergência'
    default:
      return ''
  }
}

export const getContactType = (
  accounts: ContactAccountFragment[] | undefined | null,
): ContactType => {
  if (accounts) {
    accounts.map((account) => {
      return account.admin ? ContactType.ADMIN : ContactType.GUEST
    })
  }
  return ContactType.CONTACT
}
