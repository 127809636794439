export enum StringSchemeErrorsEnum {
  ALPHANUM = 'string.alphanum',
  EMAIL = 'string.email',
  EMPTY = 'string.empty',
  GUID = 'string.guid',
  HEX_ALIGN = 'string.hexAlign',
  HEX = 'string.hex',
  HOSTNAME = 'string.hostname',
  IP_VERSION = 'string.ipVersion',
  IP = 'string.ip',
  LENGHT = 'string.length',
  LOWERCASE = 'string.lowercase',
  MAX = 'string.max',
  MIN = 'string.min',
  UPPERCASE = 'string.uppercase',
  PATTERN = 'string.pattern.name',
  ANY = 'any.only',
  REQUIRED = 'any.required',
  ARRAY_MIN = 'array.min',
  PATTERN_BASE = 'string.pattern.base',
  INVALID = 'any.invalid',
}

export enum DateSchemeErrorsEnum {
  BASE = 'date.base',
  FORMAT = 'date.format',
  GREATER = 'date.greater',
  LESS = 'date.less',
  MAX = 'date.max',
  MIN = 'date.min',
  STRICT = 'date.strict',
}

export enum NumberSchemeErrorsEnum {
  NUMBER = 'number.base',
  INTEGER = 'number.integer',
  GREATER = 'number.greater',
  LESS = 'number.less',
  MAX = 'number.max',
  MIN = 'number.min',
  MULTIPLE = 'number.multiple',
  NEGATIVE = 'number.negative',
  PORT = 'number.port',
  POSITIVE = 'number.positive',
  PRECISION = 'number.precision',
}
