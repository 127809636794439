import { Button, Modal } from '../../../../components/index'
import { maskedDate } from 'utilities/date'
import { formatLicensePlate } from '../../utilities/masks'

import styles from './VehicleDetails.module.scss'
import {
  VehicleAggregatedQueryResponse,
  VEHICLE_CATEGORY_ENUM_EN_PT,
} from '../../../../services/vehicle'

export interface VehicleDetailsProps {
  isVisible: boolean
  onClose(): void
  vehicle: VehicleAggregatedQueryResponse
}

export const VehicleDetails = (props: VehicleDetailsProps) => {
  const { isVisible, vehicle, onClose } = props

  return (
    <Modal
      className={styles.customModal}
      size="sm"
      isVisible={isVisible}
      onClose={() => {
        onClose()
      }}
      title={`Viatura ${formatLicensePlate(vehicle.licensePlate)}`}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <label className={styles.label}>Veículo</label>
            <p className={styles.infoText}>
              {VEHICLE_CATEGORY_ENUM_EN_PT[vehicle.vehicleCategory]}
            </p>
          </div>
          <div className={styles.rowItem}>
            <label className={styles.label}>Modelo</label>
            <p className={styles.infoText}>{vehicle.model}</p>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowItem}>
            <label className={styles.label}>Ano de fabricação</label>
            <p className={styles.infoText}>{vehicle.productionYear}</p>
          </div>
          <div className={styles.rowItem}>
            <label className={styles.label}>Ano do modelo</label>
            <p className={styles.infoText}>{vehicle.modelYear}</p>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowItem}>
            <label className={styles.label}>Rastreador</label>
            <p className={styles.infoText}>
              {vehicle.tracker?.model ? vehicle.tracker?.name : '-'}
            </p>
          </div>
          <div className={styles.rowItem}>
            <label className={styles.label}>Número de série</label>
            <p className={styles.infoText}>
              {vehicle.tracker?.serial ? vehicle.tracker?.serial : '-'}
            </p>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowItem}>
            <label className={styles.label}>Técnico logado</label>
            <p className={styles.infoText}>
              {vehicle.driver?.name ? vehicle.driver?.name : '-'}
            </p>
          </div>

          <div className={styles.rowItem}>
            <label className={styles.label}>Cadastrado em</label>
            <p className={styles.infoText}>{maskedDate(vehicle.createdAt)}</p>
          </div>
        </div>
      </div>
      <Modal.Footer>
        <Button
          buttonTitle="Voltar"
          onClick={onClose}
          type="secondary"
          id="so-status-return-button"
        />
      </Modal.Footer>
    </Modal>
  )
}
