import * as Joi from '@hapi/joi'
import { FormPayload } from './types'

export const EVENT_CODES_CAN_DISABLE_HABIT_DEVIATION = [
  'UB02',
  'UB03',
  'UB04',
  'UB05',
  'XXX2',
  'XXX3',
  'XXX4',
  'XXX5',
]

export const schema = Joi.object<FormPayload>({
  note: Joi.string().required(),
  postponeUntil: Joi.string().optional(),
  hour: Joi.when('postponeUntil', {
    is: 'postponeUntil',
    then: Joi.string().required(),
    otherwise: Joi.string().optional(),
  }),
  date: Joi.when('postponeUntil', {
    is: 'postponeUntil',
    then: Joi.number().required(),
    otherwise: Joi.number().optional(),
  }),
  tags: Joi.array()
    .items(Joi.object({ name: Joi.string() }))
    .optional(),
})

export const handleHoursAndMinutes = () => {
  const hoursAndMinutes = []

  let hour = 0
  let minutes = 0

  while (hour <= 23) {
    const formattedHoursAndMinutes = `${hour
      .toString()
      .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

    hoursAndMinutes.push(formattedHoursAndMinutes)

    minutes += 30
    if (minutes === 60) {
      minutes = 0
      hour++
    }
  }

  return hoursAndMinutes
}
