import { AxiosInstance } from 'axios'
import HttpClient from '../httpClient'

import { Pagination, Result } from '../types'
import {
  CustomerQuery,
  CustomerRequest,
  CustomerResponse,
  PersonType,
  SortedCustomer,
} from './types'

export interface CustomersApiClient {
  updateCustomer(customer: CustomerResponse): Promise<void>
  fetchCustomer(customerId: string): Promise<CustomerResponse>
  querySortedCustomers(
    queryParams: Partial<Pagination>,
  ): Promise<Result<SortedCustomer>>
}
class CustomersDriver implements CustomersApiClient {
  public constructor(private readonly httClient: AxiosInstance = HttpClient) {}

  async updateCustomer(customer: CustomerResponse): Promise<void> {
    const params: CustomerRequest = {
      active: customer.active,
      document: customer.document,
      name: customer.name,
      secondName: customer.secondName,
      personType: customer.personType as PersonType,
      deviceId: customer.deviceId ? customer.deviceId : '',
      tags: customer.tags ? customer.tags : [],
      costCenter: customer.costCenter,
    }
    await this.httClient.put(`/customer/${customer.id}`, params)
  }

  async fetchCustomer(customerId: string): Promise<CustomerResponse> {
    const response = await this.httClient.get<CustomerResponse>(
      `/customer/${customerId}`,
    )
    return response.data
  }

  public async querySortedCustomers(
    queryParams: CustomerQuery,
  ): Promise<Result<SortedCustomer>> {
    const response = await this.httClient.get<Result<SortedCustomer>>(
      '/customer/dropdown/query',
      { params: queryParams },
    )

    return response.data
  }
}

export const CustomerDriver = new CustomersDriver()
