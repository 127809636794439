import { ComponentProps, FC, FunctionComponent, SVGProps, memo } from 'react'
import buttonStyles from './button.module.scss'

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'cancel-primary'
  | 'cancel-secondary'
  | 'icon-secondary'

export type HtmlButtonType = 'submit' | 'reset' | 'button'
export interface ButtonProps extends Omit<ComponentProps<'button'>, 'type'> {
  buttonTitle?: string
  icon?: string | FunctionComponent<SVGProps<SVGSVGElement>>
  type: ButtonTypes
  htmlType?: HtmlButtonType
  autofocus?: boolean
  width?: string
  onClick?: () => void
}

/**
 * @deprecated "This components is deprecated and will be removed in a future release. Please use `ButtonV2` instead."
 */
export const Button: FC<ButtonProps> = memo((props) => {
  const {
    width,
    buttonTitle,
    icon,
    type,
    htmlType = 'button',
    id,
    onClick,
    disabled,
    autofocus,
    children,
    className,
  } = props

  const getStyle = (type: string) => {
    if (buttonStyles[type]) {
      return buttonStyles[type]
    }
    return buttonStyles.primary
  }

  const getIcon = (
    Icon: string | FunctionComponent<SVGProps<SVGSVGElement>>,
  ) => {
    if (typeof icon === 'string') {
      return <img src={icon} alt="icon" />
    }

    return <Icon fill="currentColor" />
  }

  return (
    <button
      autoFocus={autofocus}
      id={id}
      data-testid={id}
      style={{ width }}
      className={[
        buttonStyles.button,
        getStyle(type),
        icon && buttonStyles['button-content-icon'],
        className,
      ].join(' ')}
      type={htmlType}
      onClick={onClick}
      disabled={disabled}
      aria-label={buttonTitle}
    >
      <div className={buttonStyles['button-content']}>
        {icon && getIcon(icon)}
        {children}
        {buttonTitle}
      </div>
    </button>
  )
})

Button.displayName = 'Button'

export default Button
