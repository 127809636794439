import Joi, { CustomHelpers } from '@hapi/joi'
import { ConfigureSafeCallPayload } from 'services/command/types'
import { NumberSchemeErrorsEnum } from 'utilities/validation'

export type ConfigureSafeCallSchema = Pick<
  ConfigureSafeCallPayload,
  'dialDelay' | 'dialNumber'
> & {
  centralId: string
  dial: string
}

const validate = (value: number, helpers: CustomHelpers) => {
  if (value < 0) return helpers.error(NumberSchemeErrorsEnum.NUMBER)

  return value
}

export const configureSafeCallSchema = Joi.object<ConfigureSafeCallSchema>({
  centralId: Joi.string().uuid().required(),
  dial: Joi.string().required().allow(''),
  dialDelay: Joi.number()
    .custom(validate)
    .messages({
      [NumberSchemeErrorsEnum.NUMBER]: 'Campo obrigatório. Apenas números',
    }),
  dialNumber: Joi.number()
    .custom(validate)
    .messages({
      [NumberSchemeErrorsEnum.NUMBER]: 'Campo obrigatório. Apenas números',
    }),
})
