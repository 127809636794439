import { District } from './types'
import { Pagination, Result } from '../../types'
import HttpClient from '../../httpClient'
import { AxiosInstance } from 'axios'

export interface DistrictApiClient {
  queryDistricts(
    queryParams: Partial<District> & Partial<Pagination>,
  ): Promise<Result<District>>
}
class DistrictApiClientImpl implements DistrictApiClient {
  public constructor(private readonly httClient: AxiosInstance = HttpClient) {}

  public queryDistricts = async (
    queryParams?: Partial<District> & Partial<Pagination>,
  ): Promise<Result<District>> => {
    const response = await this.httClient.get<Result<District>>(
      `/address/district/query/`,
      { params: queryParams },
    )
    return response.data
  }
}

export default new DistrictApiClientImpl()
