import { Button, Modal } from 'components'

type RemoveModalProps = {
  isVisible: boolean
  onClose: () => void
  onRemove: () => void
  holidayName: string
}

export const RemoveModal = ({
  isVisible,
  onClose,
  onRemove,
  holidayName,
}: RemoveModalProps) => {
  return (
    <Modal
      simple
      title="Exclusão de feriado"
      isVisible={isVisible}
      onClose={onClose}
    >
      <div>
        <p>Você tem certeza que deseja exluir o feriado</p>
        <p>{holidayName}?</p>
      </div>
      <Modal.Footer>
        <Button
          id="close-modal"
          buttonTitle="Cancelar"
          onClick={onClose}
          type="secondary"
          width="172px"
        />
        <Button
          id="delete-button"
          buttonTitle="Excluir"
          onClick={onRemove}
          type="cancel-primary"
          width="172px"
        />
      </Modal.Footer>
    </Modal>
  )
}
