import { forwardRef, useState, ComponentProps } from 'react'
import { ReactComponent as IconError } from './../../assets/svg/error.svg'
import { ReactComponent as OpenedEye } from './../../assets/svg/inputPasswordOpenEye.svg'
import { ReactComponent as ClosedEye } from './../../assets/svg/inputPasswordClosedEye.svg'

import './input.scss'

interface InputProps extends ComponentProps<'input'> {
  label?: string
  password?: boolean
  errorMessage?: string
  wrapperClassName?: string
}

/**
 * @deprecated "This components is deprecated and will be removed in a future release. Please use `Textfield` instead."
 */
const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    id,
    name,
    className,
    wrapperClassName,
    label,
    errorMessage,
    password,
    type = 'text',
    autoComplete = 'off',
    ...rest
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const handlePasswordType = () => {
    if (password && !showPassword) {
      return 'password'
    }
    return type
  }

  return (
    <div
      className={['input-wrapper', wrapperClassName].filter(Boolean).join(' ')}
    >
      {label && (
        <label htmlFor={id || 'input'} className="label">
          {label}
        </label>
      )}
      <div className="inner-input-wrapper">
        <input
          ref={ref}
          {...rest}
          id={id}
          name={name || 'input'}
          autoComplete={autoComplete}
          className={[
            'input',
            password && 'input--password',
            errorMessage && 'input--error',
            className,
          ].join(' ')}
          type={handlePasswordType()}
        />
        {password && (
          <span className="show-password">
            <i
              className={[
                'input-password-icon',
                showPassword && '--closed-eye',
              ].join(' ')}
              data-testid="input-password-icon"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? (
                <ClosedEye data-testid="closed-eye" />
              ) : (
                <OpenedEye data-testid="opened-eye" />
              )}
            </i>
          </span>
        )}
      </div>
      {errorMessage && (
        <div className="error-wrapper">
          <span className="error-inner-wrapper">
            <IconError className="error-icon" />
            <p className="error-text">{errorMessage}</p>
          </span>
        </div>
      )}
    </div>
  )
})

Input.displayName = 'Input'

export default Input
