import { Gallery } from 'components'
import { useModal } from '../../../../context'
import { Modal } from '../../../../context/types'
import { dateToMilliseconds } from 'utilities/date'

const handleRenderModal = (modal: Modal) => {
  switch (modal.type) {
    case 'GALLERY': {
      const formattedImages = modal.data.images.map((image) => ({
        ...image,
        creation: dateToMilliseconds(String(image.creation)) || 0,
      }))

      const data = { ...modal.data, images: formattedImages }

      return <Gallery {...data} />
    }
  }
}

const ModalsContainer = () => {
  const { modal } = useModal()

  return <>{modal && handleRenderModal(modal)}</>
}

export default ModalsContainer
