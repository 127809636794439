import { useParams } from 'react-router-dom'

import { EmptyState, ErrorState } from 'components'
import { useGetServiceOrderInfo } from 'shared/hooks'
import { handleRenderState } from 'utilities/handleRenderState'
import { SERVICE_ORDER_TYPE_ENUM } from 'services/serviceOrder'

import {
  EquipmentsInfo,
  ServiceOrderSnapshot,
  UninstalledEquipmentsStatus,
} from './components'
import styles from './styles.module.scss'
import Loading from './Loading'

/* TODO Refazer todas a parte de Equipments com novos componentes e logicas, por enquanto será mantido o código antigo e feito algumas cópias/imports do antigo domínio
desconsiderar em code review =)
 */
const Equipments = () => {
  const { serviceOrderId } = useParams()

  const { data, isLoading, isError } = useGetServiceOrderInfo(
    serviceOrderId,
    Boolean(serviceOrderId),
    true,
  )

  const centralInfoAfterSnapshot =
    data?.centralInfo?.currentShadow || data?.centralInfo?.finishedSnapshot

  const isUninstallServiceOrder =
    data?.serviceOrderInfo?.type === SERVICE_ORDER_TYPE_ENUM.UNINSTALL

  const uninstalledEquipments =
    isUninstallServiceOrder && centralInfoAfterSnapshot?.partitions.length

  const equipmentsInfo =
    !data?.centralInfo?.inProgressSnapshot &&
    !data?.centralInfo?.devicesRemoved &&
    centralInfoAfterSnapshot?.partitions.length

  const serviceOrderSnapshot = data?.centralInfo?.inProgressSnapshot

  return (
    <>
      {
        {
          view: !!data && (
            <div className={styles.container}>
              {serviceOrderSnapshot && (
                <ServiceOrderSnapshot
                  serviceOrderInfo={data}
                  /*  handleSelect={handleActionOnClick}
                  onPressGallery={handleOpenGallery} */
                />
              )}
              {equipmentsInfo && (
                <div className={styles.currentShadow}>
                  <EquipmentsInfo
                    serviceOrder={{
                      serviceOrderId: data?.id,
                      customerId: data?.serviceOrderInfo?.customer?.id,
                      accountId: data?.serviceOrderInfo?.account?.id,
                    }}
                    centralInfo={centralInfoAfterSnapshot}
                    /*      handleSelect={handleActionOnClick}
                      onPressGallery={handleOpenGallery} */
                    model={data.centralInfo?.model}
                  />
                </div>
              )}
              {uninstalledEquipments && (
                <UninstalledEquipmentsStatus
                  centralInfo={centralInfoAfterSnapshot}
                  devicesRemoved={data.centralInfo?.devicesRemoved}
                />
              )}
            </div>
          ),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyState type="EmptyEquipment" />,
        }[
          handleRenderState({
            data,
            isLoading,
            isError,
            isEmpty: !data?.centralInfo?.currentCentralId,
          })
        ]
      }
    </>
  )
}

export default Equipments
