import { EventVariation } from 'domains/occurrence/data/event'
import { OccurrenceTypeName } from 'services/occurrence/types'

import styles from './EventTag.module.scss'
import { useTooltipVisibility } from 'shared/hooks'
import { Tooltip } from 'components'

type EventTagProps = {
  type: OccurrenceTypeName
  title?: string
  isFinished?: boolean
  small?: boolean
}

export const EventTag: React.FC<EventTagProps> = ({
  type,
  title,
  isFinished,
  small = false,
}) => {
  const { ref, isTooltipVisible, handleMouseEnter, handleMouseLeave } =
    useTooltipVisibility()

  return (
    <div
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={[
        styles.container,
        isFinished ? styles.finished : styles[EventVariation[type]?.classname],
        small ? styles.small : '',
      ].join(' ')}
    >
      <span>
        {isFinished
          ? 'Finalização'
          : [EventVariation[type]?.name, title].filter(Boolean).join(' - ')}
      </span>
      {isTooltipVisible && (
        <Tooltip
          type="informative"
          isVisible={isTooltipVisible}
          parentRef={ref}
        >
          {title}
        </Tooltip>
      )}
    </div>
  )
}
