import { useLoadScript } from '@react-google-maps/api'

import { Loader } from 'components'

import { ReactComponent as OccurrenceIcon } from 'assets/svg/occurrence.svg'

import {
  DisplacementDataProvider,
  DisplacementStepProvider,
  FilterProvider,
  MapProvider,
  DisplacementModalsProvider,
} from './contexts'
import { Actions } from './components'
import { Map, ModalsContainer } from './containers'

import styles from './styles.module.scss'

const DisplacementMap = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB4K9_-pV-J1Ex4qTRezBZpfNeLTrZ8Rp8',
  })

  const handleRenderMap = () => {
    if (isLoaded) {
      return 'view'
    }

    if (loadError) {
      return 'view'
    }

    return 'loading'
  }

  return (
    <>
      {
        {
          view: (
            <div style={{ position: 'relative' }}>
              <DisplacementDataProvider>
                <MapProvider>
                  <DisplacementModalsProvider>
                    <FilterProvider>
                      <DisplacementStepProvider>
                        <Map />
                        <Actions />
                        <ModalsContainer />
                      </DisplacementStepProvider>
                    </FilterProvider>
                  </DisplacementModalsProvider>
                </MapProvider>
              </DisplacementDataProvider>
            </div>
          ),
          loading: <Loader isVisible />,
          error: (
            <div className={styles.error}>
              <div>
                <OccurrenceIcon />
              </div>
              <div>
                <p>Recarregue a página</p>
                <span>
                  Seu mapa de atendimento de ocorrências será carregado aqui.
                </span>
              </div>
            </div>
          ),
        }[handleRenderMap()]
      }
    </>
  )
}

export default DisplacementMap
