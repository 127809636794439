import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
  FC,
} from 'react'

import { DisplacementStep } from '../types'

interface Provider {
  children: ReactNode
}

interface DisplacementStepContextProps {
  displacementStep: DisplacementStep
  handleDisplacementStep: (step: DisplacementStep) => void
}

const defaultValue: DisplacementStepContextProps = {
  displacementStep: 'TRAVEL_VIEWED',
  handleDisplacementStep: () => null,
}

export const DisplacementStepContext =
  createContext<DisplacementStepContextProps>(defaultValue)

export function useDisplacementStep(): DisplacementStepContextProps {
  const context = useContext(DisplacementStepContext)
  if (!context)
    throw new Error(
      'You need to use DisplacementStepContext within a DisplacementStepProvider',
    )

  return context
}

export const DisplacementStepProvider: FC<Provider> = ({ children }) => {
  const [displacementStep, setDisplacementStep] =
    useState<DisplacementStep>('TRAVEL_VIEWED')

  const handleDisplacementStep = useCallback((step: DisplacementStep) => {
    setDisplacementStep(step)
  }, [])

  return (
    <DisplacementStepContext.Provider
      value={{
        displacementStep,
        handleDisplacementStep,
      }}
    >
      {children}
    </DisplacementStepContext.Provider>
  )
}
