import { CoverageGrouper } from 'services/attendancePolicy/types'

export enum CoverageType {
  Customer = 'Cliente',
  Account = 'Conta',
}

export const coverageKeys: Record<string, keyof CoverageGrouper> = {
  [CoverageType.Customer]: 'customers',
  [CoverageType.Account]: 'accounts',
}
