import React, { useCallback, useRef, useState } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { ReactComponent as AddPic } from 'assets/svg/addPic.svg'

import { useToast } from 'shared/hooks'
import { Button, ButtonV2, Icon, Modal } from 'components'
import { usePersistImages } from 'services/serviceOrder'
import { ServiceOrderImagePayload } from 'domains/serviceOrders/types'

import styles from './styles.module.scss'

interface FormProps {
  images: File[]
}

export type ImageUploaderProps = {
  isVisible: boolean
  onClose: () => void
  serviceOrder: ServiceOrderImagePayload
}

export const schema = Joi.object<FormProps>({
  images: Joi.array().min(1).required(),
})

const ImageUploader: React.FC<ImageUploaderProps> = ({
  isVisible,
  onClose,
  serviceOrder,
}) => {
  const { addToast } = useToast()
  const { mutate, isPending: isLoading } = usePersistImages(
    serviceOrder?.serviceOrderId,
  )
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormProps>({
    resolver: joiResolver(schema),
  })

  const [dragging, setDragging] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      if (event.dataTransfer && event.dataTransfer.files.length) {
        const currentValue = watch('images') || []

        setValue(
          'images',
          [...currentValue, ...Array.from(event.dataTransfer.files)],
          { shouldValidate: true },
        )
      }

      setDragging(false)
    },
    [setValue, watch],
  )

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setDragging(true)
    },
    [],
  )

  const handleRemoveImage = useCallback(
    (index: number) => {
      const newImages = watch('images')

      newImages.splice(index, 1)
      setValue('images', newImages)
    },
    [setValue, watch],
  )

  const onSubmit = useCallback(
    ({ images }: FormProps) => {
      const { accountId, customerId, serviceOrderId } = serviceOrder
      if (serviceOrderId) {
        mutate(
          {
            images,
            principalOwner: {
              id: serviceOrder?.serviceOrderId,
              name: 'ServiceOrder',
            },
            owners: [
              {
                id: customerId,
                name: 'Customer',
              },
              {
                id: accountId,
                name: 'Account',
              },
            ],
          },
          {
            onSuccess: () => {
              onClose()
              reset()
              addToast({
                message: 'Fotos enviadas com sucesso!',
                error: true,
                type: 'success',
              })
            },
            onError: () =>
              addToast({
                message:
                  'Erro ao enviar arquivos. Verifique a extensão das imagens. Apenas as opções jpeg, jpg e png são permitidas.',
                error: true,
                type: 'alert',
              }),
          },
        )
      }
    },
    [serviceOrder, mutate, onClose, reset, addToast],
  )

  return (
    <Modal
      isVisible={isVisible}
      size="md"
      onClose={onClose}
      className={styles.container}
      title="Adicionar foto à ordem de serviço"
    >
      <form className={styles.modal} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.mainContent}>
          <div
            className={[styles.dropZone, dragging && styles.dragging]
              .filter(Boolean)
              .join(' ')}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={() => setDragging(false)}
          >
            <input
              multiple
              id="file"
              type="file"
              {...register('images')}
              ref={fileInputRef}
              accept="image/jpeg, image/png"
              className={styles.hiddenButton}
              onChange={(event) => {
                if (event.target?.files?.length) {
                  const currentValue = watch('images') || []

                  setValue(
                    'images',
                    [...currentValue, ...Array.from(event.target.files || [])],
                    { shouldValidate: true },
                  )
                }
              }}
            />
            <div className={styles.dropZoneContent}>
              <AddPic />
              <p>
                <span onClick={() => fileInputRef.current?.click()}>
                  Selecione
                </span>{' '}
                ou arraste as imagens para adicionar à galeria da ordem de
                serviço
              </p>
            </div>
          </div>
          <div className={styles.imagesWrapper}>
            <p>Arquivos enviados</p>
            <ul className={styles.imagesList}>
              {watch('images')?.map((image, index) => (
                <li key={index}>
                  <img alt="" src={URL.createObjectURL(image)} width={100} />
                  <div className={styles.imageDetails}>
                    <div>
                      <span>{image.name}</span>
                      <ButtonV2
                        size="md"
                        appearance="tertiary"
                        onClick={() => handleRemoveImage(index)}
                      >
                        <Icon name="remove" />
                        Remover
                      </ButtonV2>
                    </div>
                    <div />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Modal.Footer>
          <Button type="secondary" buttonTitle="Cancelar" onClick={onClose} />
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isValid || isLoading}
            buttonTitle="Salvar"
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ImageUploader
