import Joi from '@hapi/joi'
import { PartitionResponse } from 'services/partition/types'

export const partitionSchema = Joi.object<PartitionResponse>({
  id: Joi.string().uuid(),
  name: Joi.string(),
  centralId: Joi.string().uuid(),
  code: Joi.string(),
  status: Joi.string().valid('A', 'D'),
  securityQuestion: Joi.string().optional(),
  securityAnswer: Joi.string().optional(),
  securityCoercionAnswer: Joi.string().optional(),
  createdAt: Joi.number(),
  updatedAt: Joi.number(),
})
