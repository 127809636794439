import { QueryRange, Timestamps } from 'services/types'
import { ContactType } from 'domains/customer/screens/Contacts/types'

export enum PhoneType {
  'Comercial' = 'W',
  'Residential' = 'H',
  'CellPhone' = 'C',
}

export type Phone = {
  type: PhoneType
  countryCode: number
  provinceCode: number
  number: number
  extension?: number
  priorityOrder: number
}

export type PhoneResponse = Phone & {
  id: string
}

export type Account = {
  id?: string
  admin: boolean
}

export type Contact = {
  customerId: string
  name: string
  email: string
  note?: string
  phones?: Phone[]
  userId?: string
}

export type ContactResponse = Contact & {
  id: string
  phones: PhoneResponse[]
  createdAt: number
  updatedAt: number
}

export type ContactAccount = {
  id: string
  type: ContactType
}

export type PartitionContactOrder = {
  id?: string
  priorityOrder: number
}

export type ContactPartition = {
  id: string
  contacts: PartitionContactOrder[]
}

export interface UpdateContactRequest {
  name: string
  customerId: string
  phones: Phone[]
}

export type ContactQuery = {
  name?: string
  customerId?: string
  userId?: string
  email?: string
  countryCode?: number
  provinceCode?: number
  number?: number
  note?: string
  sort?: string
  sortType?: string
  partitionId?: string
  hasPhone?: boolean
} & Partial<QueryRange>

export type ContactAccountFragment = {
  id: string
  admin: boolean
}

export type ContactQueryResponse = {
  id: string
  phones?: PhoneResponse[]
  accounts?: ContactAccountFragment[] | null
  customerId: string
  name: string
  email: string
  note?: string
  userId?: string
  role?: string
} & Timestamps

export type ContactQueryResponseWithType = ContactQueryResponse & {
  type: ContactType
}

export type PartitionCanDeleteReponse = {
  id: string
  name: string
}

export type ContactCanDeleteReponse = {
  contactId: string
  name?: string
  partitions?: PartitionCanDeleteReponse[]
  hasControlDevice?: boolean
}

export type ContactPayload = {
  customerId: string
  name: string
  email?: string
  role?: string
  phone?: {
    number: number
    provinceCode: number
    type: 'W' | 'H' | 'C'
  }
  accounts: { id: string }[]
  ubiAppUser: boolean
  allPartitions: boolean
  allActionPlans: boolean
}

export type GetContact = {
  id: string
  name: string
  role?: string
  email?: string
  phone?: {
    type: string
    number: number
    provinceCode: number
  }
  accounts: {
    id: string
    admin: boolean
    serviceType: string
    actionable: boolean
    hasControl: boolean
    aggregatedAccountName: string
  }[]
  ubiAppUser: boolean
  hasPartitions: boolean
}
