import { DisplacementStep } from '../../../../types'

export const handleSteps = (step: DisplacementStep): string => {
  const steps: Record<DisplacementStep, string> = {
    TRAVEL_VIEWED: 'Solicitação visualizada',
    TRAVEL_FINISHED: 'Chegada no local',
    TRAVEL_STARTED: 'Trânsito iniciado',
    TRAVEL_SENT: 'Solicitação enviada',
  }

  return steps[step]
}
