import { useMutation, useQuery } from '@tanstack/react-query'

import HttpClient from '../httpClient'

import buildUrl from 'utilities/buildUrl'

import { endpoints } from './endpoints'

import {
  AggregatedTechnicianSchedulePause,
  TechnicianSchedulePayload,
  TechnicianScheduleQuery,
  TechnicianScheduleResponse,
} from './types'
import { Result } from 'services/types'
import { AxiosInstance } from 'axios'

export interface TechnicianSchedulePauseDriver {
  query(
    query?: TechnicianScheduleQuery,
  ): Promise<Result<AggregatedTechnicianSchedulePause>>
}

export class TechnicianSchedulePauseDriverImpl
  implements TechnicianSchedulePauseDriver
{
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async query(
    query?: TechnicianScheduleQuery,
  ): Promise<Result<AggregatedTechnicianSchedulePause>> {
    const result = await this.httpClient.get<
      Result<AggregatedTechnicianSchedulePause>
    >(`/technicianSchedule/query`, {
      params: query,
    })

    return result.data
  }
}

export const TechnicianSchedulePauseDriver =
  new TechnicianSchedulePauseDriverImpl()

const useQueryTechnicianSchedule = (technicianId = '') =>
  useQuery({
    queryKey: ['query-technician-schedule', technicianId],
    queryFn: async () => {
      const response = await HttpClient.get<
        Result<AggregatedTechnicianSchedulePause>
      >(
        buildUrl({
          route: endpoints.query,
          queryParams: { technicianId },
        }),
      )
      return response.data
    },
    enabled: !!technicianId,
    refetchOnWindowFocus: false,
  })

const createTechnicianSchedulePause = async (
  payload: TechnicianSchedulePayload,
) => {
  return await HttpClient.post(endpoints.create, payload)
}

const useCreateTechnicianSchedulePause = () => {
  return useMutation({
    mutationKey: ['create-technician-schedule-pause'],
    mutationFn: async (payload: TechnicianSchedulePayload) =>
      await createTechnicianSchedulePause(payload),
  })
}

const editTechnicianSchedulePause = async (
  id: string,
  payload: TechnicianSchedulePayload,
) => {
  return await HttpClient.put(
    buildUrl({ route: endpoints.edit, params: { id } }),
    payload,
  )
}

const useEditTechnicianSchedulePause = (id = '') => {
  return useMutation({
    mutationKey: ['edit-technician-schedule-pause'],
    mutationFn: async (payload: TechnicianSchedulePayload) => {
      if (id) {
        return await editTechnicianSchedulePause(id, payload)
      }
    },
  })
}

const removeTechnicianSchedulePause = async (id = '') => {
  return await HttpClient.delete(
    buildUrl({
      route: endpoints.remove,
      params: { id },
    }),
  )
}

const useDeleteTechnicianSchedulePause = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      await removeTechnicianSchedulePause(id)
    },
    retry: false,
  })
}

const useGetTechnicianSchedule = (technicianId = '') =>
  useQuery({
    queryKey: ['get-technician-schedule', technicianId],
    queryFn: async () => {
      const response = await HttpClient.get<TechnicianScheduleResponse>(
        buildUrl({
          route: endpoints.find,
          params: { id: technicianId },
        }),
      )
      return response.data
    },
    enabled: !!technicianId,
    refetchOnWindowFocus: false,
  })

const useValidateTechnicianSchedules = (technicianId = '') =>
  useQuery({
    queryKey: ['validate-technician-schedules', technicianId],
    queryFn: async () => {
      const response = await HttpClient.get<TechnicianScheduleResponse[]>(
        buildUrl({
          route: endpoints.validate,
          params: { technicianId },
        }),
      )
      return response.data
    },
    enabled: !!technicianId,
    refetchOnWindowFocus: false,
  })

export {
  useValidateTechnicianSchedules,
  useGetTechnicianSchedule,
  useQueryTechnicianSchedule,
  useEditTechnicianSchedulePause,
  useCreateTechnicianSchedulePause,
  useDeleteTechnicianSchedulePause,
}
