import { useMutation } from '@tanstack/react-query'
import ImageDriver from 'services/image'
import { CapturedFile } from '../types'

const postScreenshots = async (data: CapturedFile[]): Promise<string[]> =>
  await Promise.all(
    data.map(async (screenShot) => {
      try {
        const imageId = await ImageDriver.persist(screenShot.file, {
          principalOwner: {
            id: screenShot.occurrenceId,
            name: 'Occurrence',
          },
          owners: [
            {
              id: screenShot.sid,
              name: 'SID',
            },
          ],
        })
        return imageId
      } catch (error) {
        throw new Error()
      }
    }),
  )

export const usePostScreenshots = () =>
  useMutation({
    mutationFn: async (data: CapturedFile[]): Promise<string[]> =>
      await postScreenshots(data),
  })
