import { useQuery } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { ImageResponse } from './types'

export const getImageFile = async (images?: ImageResponse[]) => {
  const imagesWithUrl: ImageResponse[] = []

  await Promise.all(
    (images || []).map(async (image) => {
      const response = await HttpClient.get<Blob>(
        buildUrl({ route: endpoints.getFile, params: { imageId: image.id } }),
        {
          responseType: 'blob',
        },
      )

      const url = URL.createObjectURL(response.data)

      imagesWithUrl.push({ ...image, url })
    }),
  )

  return imagesWithUrl
}

export const useGetImageFile = (images?: ImageResponse[]) => {
  return useQuery({
    queryKey: ['get-image-file', images],
    queryFn: async () => {
      const response = await getImageFile(images)

      return response
    },
    enabled: !!images?.length,
  })
}
