import { FC } from 'react'
import ReactDOM from 'react-dom'
import { useFormContext } from 'react-hook-form'

import { Button, Input } from 'components'

import { ReactComponent as CloseButton } from 'assets/svg/close.svg'

import { SearchContact } from '../../schemas/searchContact'

import styles from './styles.module.scss'
import { formatPhone } from 'utilities/masks'
import { PhoneType } from 'services/contact/types'

interface Filter {
  onApplyFilters: () => void
  onClose: () => void
  isVisible: boolean
}

const Filters: FC<Filter> = ({ isVisible, onClose, onApplyFilters }) => {
  const { setValue, watch, reset, register } = useFormContext<SearchContact>()

  return ReactDOM.createPortal(
    <div
      className={`${styles.container} ${isVisible ? styles.show : styles.hide}`}
    >
      <div className={styles.innerWrapper}>
        <div className={styles.header}>
          <span>Filtros</span>
          <CloseButton
            height={16}
            width={16}
            onClick={onClose}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.contentWrapper}>
          <Input
            label="Telefone"
            {...register('phone')}
            maxLength={15}
            value={watch('phone') || ''}
            onChange={(event) => {
              const value = event.target.value

              setValue(
                'phone',
                formatPhone(
                  value.length < 15 ? PhoneType.Comercial : PhoneType.CellPhone,
                  value,
                ),
                { shouldValidate: true, shouldDirty: true },
              )
            }}
          />
          <Input
            id="email"
            label="E-mail"
            type="text"
            value={watch('email') || ''}
            {...register('email')}
            onChange={(event) => {
              setValue('email', event.target.value, {
                shouldValidate: true,
              })
            }}
          />
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              reset({})
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            width="162px"
            onClick={onApplyFilters}
          />
        </div>
      </div>
    </div>,
    document.getElementById('main-container') || document.body,
  )
}

export default Filters
