import { AttendanceSentenceResponse } from 'services/occurrence/types'
import { InterventionTypeName } from 'services/attendance/types'
import { endpoints } from 'services/occurrence/endpoints'
import { useQuery } from '@tanstack/react-query'
import HttpClient from 'services/httpClient'
import buildUrl from 'utilities/buildUrl'
import { Result } from 'services/types'

interface AttendanceSentenceProps {
  interventionType: InterventionTypeName
  occurrenceTypeId?: string
  serviceTypeId?: string
  name?: string
}

const fetchAttendanceSentence = async ({
  interventionType,
  occurrenceTypeId,
  serviceTypeId,
  name,
}: AttendanceSentenceProps) => {
  const response = await HttpClient.get<Result<AttendanceSentenceResponse>>(
    buildUrl({
      route: endpoints.querySentence,
      queryParams: { interventionType, occurrenceTypeId, serviceTypeId, name },
    }),
  )
  return response.data
}

export const useGetAttendanceSentence = ({
  interventionType,
  occurrenceTypeId,
  serviceTypeId,
  name,
}: AttendanceSentenceProps) =>
  useQuery({
    queryKey: [
      'attendancePolicySentence',
      interventionType,
      occurrenceTypeId,
      name,
    ],
    queryFn: async () =>
      await fetchAttendanceSentence({
        interventionType,
        occurrenceTypeId,
        serviceTypeId,
        name,
      }),
    enabled: !!occurrenceTypeId,
  })
