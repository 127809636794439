import { UserFormData } from 'domains/user/types'
import { FC, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { ReactComponent as Exclamation } from 'assets/svg/exclamation.svg'

import { RadioButton } from 'components/RadioButton/RadioButton'
import { Input, Instruction, RadioGroup, Tooltip } from 'components'
import { PhoneType } from 'services/contact/types'
import { formatPhone } from 'utilities/masks'

import styles from './styles.module.scss'
import { useToggle } from 'shared/hooks'
import { POSITION } from 'shared/hooks/useElementPosition/types'
import { maskedDate } from 'utilities/date'

interface AccessInfoProps {
  readonly?: boolean
}

export const AccessInfo: FC<AccessInfoProps> = ({ readonly }) => {
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<UserFormData>()

  const tooltip = useToggle()
  const iconRef = useRef<HTMLDivElement>(null)

  const userStatusOptions = ['Ativo', 'Inativo']

  const handlePasswordPattern = (password?: string): string[] => {
    if (!password) return Array(5).fill(false)

    const patterns = [/^.{8,}$/, /\d/, /[A-Z]/, /[a-z]/, /[@*!%;:.]/]

    return patterns.map((regex) =>
      regex.test(password) ? styles.success : styles.fail,
    )
  }

  const renderPasswordPattern = () => {
    const password = watch('currentPassword')
    if (!password) return null

    const validations = handlePasswordPattern(password)
    const liItems = [
      '• Mínimo de 8 caracteres',
      '• Mínimo 1 número',
      '• Mínimo 1 letra maiúscula',
      '• Mínimo 1 letra minúscula',
      '• Mínimo 1 caractere especial (@*!%;:.)',
    ]

    return liItems.map((item, index) => (
      <li key={index} className={validations[index]}>
        {item}
      </li>
    ))
  }

  return (
    <div className={styles.fields}>
      <div className={styles.sectionTitle}>
        <h3>Informações de acesso</h3>
        <small>Dados de contato e acesso do usuário.</small>
      </div>
      <div>
        {watch('id') ? (
          <div className={styles.userActiveWrapper}>
            <div className={styles.wrapperRadio}>
              <RadioGroup title="Situação">
                {userStatusOptions.map((option, key) => (
                  <RadioButton
                    {...register('active')}
                    checked={watch('active') === option}
                    onChange={(e) =>
                      setValue('active', e.target.value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    value={option}
                    key={key}
                  />
                ))}
              </RadioGroup>
            </div>
            <div className={styles.activeDateTime}>
              <span>{`${watch('active')}`} desde</span>
              {maskedDate(watch('activeDateTime'))}
            </div>
          </div>
        ) : (
          <Instruction
            text="Deve ser informado um login ou um e-mail válido para o usuário."
            className={styles.customInstruction}
          />
        )}
      </div>

      <Input
        autoComplete="off"
        className={styles.inputName}
        disabled={readonly || !!watch('currentEmail')}
        label="Usuário"
        placeholder="Usuário"
        {...register('username')}
        onChange={(e) =>
          setValue('username', e.target.value, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
        errorMessage={errors?.username?.message}
      />

      <Input
        disabled={readonly || !!watch('username')}
        label="Email"
        placeholder="Email"
        type="email"
        {...register('currentEmail')}
        onChange={(e) =>
          setValue('currentEmail', e.target.value, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
        errorMessage={errors?.currentEmail?.message}
        onBlur={() => {
          setValue('username', '', {
            shouldDirty: true,
            shouldValidate: true,
          })

          setValue('currentPassword', undefined, {
            shouldDirty: true,
            shouldValidate: true,
          })

          setValue('confirmPassword', undefined, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }}
        autoComplete="off"
      />

      <div className={styles.inlineFields}>
        <div className={styles.passwordWrapper}>
          <div className={styles.iconTooltip}>
            <div
              ref={iconRef}
              data-testid="icon-with-tooltip"
              onMouseEnter={tooltip.show}
              onMouseLeave={tooltip.hide}
              className={styles.container}
            >
              <Exclamation />
            </div>
            {!!watch('currentPassword')?.length && (
              <Tooltip
                parentRef={iconRef}
                positionAbove={POSITION.TOP}
                type="informative"
                isVisible={tooltip.isVisible}
              >
                <ul className={styles.passwordTooltip}>
                  <ul className={styles.passwordTooltip}>
                    {renderPasswordPattern()}
                  </ul>
                </ul>
              </Tooltip>
            )}
          </div>

          <Input
            disabled={readonly || !!watch('currentEmail')}
            className={styles.inputPassword}
            type="text"
            label="Senha"
            {...register('currentPassword')}
            onBlur={() => tooltip.hide()}
            password
            onChange={(event) => {
              tooltip.show()
              setValue(
                'currentPassword',
                event.target.value.replace(/[.\-/]/g, ''),
                {
                  shouldValidate: true,
                },
              )
            }}
            errorMessage={errors?.currentPassword?.message}
            autoComplete="off"
          />
        </div>

        <Input
          disabled={readonly || !!watch('currentEmail')}
          type="text"
          label="Confirme a senha"
          password
          {...register('confirmPassword')}
          onChange={(event) => {
            setValue(
              'confirmPassword',
              event.target.value.replace(/[.\-/]/g, ''),
              {
                shouldValidate: true,
              },
            )
          }}
          errorMessage={errors?.confirmPassword?.message}
          autoComplete="off"
        />
      </div>

      <Input
        disabled={readonly}
        type="text"
        label="Telefone"
        maxLength={15}
        placeholder="(00) 0000-0000"
        {...register('phone')}
        onChange={(event) => {
          const value = event.target.value.replace(/\D/g, '')
          setValue('phone', formatPhone(PhoneType.CellPhone, value), {
            shouldValidate: true,
            shouldDirty: true,
          })
        }}
        autoComplete="off"
      />
    </div>
  )
}
