import { DeviceType, PartitionDevice } from 'services/partition/types'
import { dateToMilliseconds } from 'utilities/date'
import { sortDevices } from '../sort/sortDevices'
import { ImageResponse } from 'services/image/types'

export function getDevicesImages(devices: PartitionDevice[]): ImageResponse[] {
  const sortedDevices = sortDevices(devices)
  const localImages: ImageResponse[] = []

  sortedDevices.forEach((device) => {
    device.installationImage &&
      localImages.push({
        id: device.installationImage.id,
        creation: dateToMilliseconds(device.installationImage.dateTime) || 0,
        title: device.name,
        labels: [],
        hidden: false,
        owners: [],
        url: '',
      })

    device.type === DeviceType.PassiveInfraPhotoRedSensor &&
      device.quickViewImage?.id &&
      localImages.push({
        id: device.quickViewImage.id,
        creation: dateToMilliseconds(device.quickViewImage.dateTime) || 0,
        title: device.name,
        labels: [],
        hidden: false,
        owners: [],
        url: '',
      })
  })

  return localImages
}
