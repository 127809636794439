import { TacticalStatus } from 'services/displacementMap/types'

type StatusType = {
  label: string
  value: TacticalStatus
}

export const statusOptions: StatusType[] = [
  {
    label: 'Sem comunicação',
    value: 'WITHOUT_COMMUNICATION',
  },
  {
    label: 'Ligada',
    value: 'COMMUNICATION_ON',
  },
  {
    label: 'Desligada',
    value: 'COMMUNICATION_OFF',
  },
  {
    label: 'Em movimento',
    value: 'DISPLACEMENT',
  },
]

export const occurrenceTypesOptions = [
  'Alarme',
  'Socorro',
  'Falha',
  'Finalizado',
  'Iminência',
  'Emergência',
  'Comercial',
  'Manual',
  'Periódica',
]
