import { City } from 'services/address/city/types'
import { State } from 'services/address/state'
import { HolidayCoverageType } from 'services/holiday/types'

export const coverageHoliday = {
  NATIONAL: 'NATIONAL',
  REGIONAL: 'REGIONAL',
}

export type CoverageHoliday = keyof typeof coverageHoliday

export const COVERAGE_HOLIDAY_EN_PT = {
  NATIONAL: 'Nacional',
  REGIONAL: 'Regional',
}

export const typeHoliday = {
  FIXED: 'Fixo',
  VARIABLE: 'Móvel',
}

export type TypeHoliday = keyof typeof typeHoliday

export const months = {
  Janeiro: 1,
  Fevereiro: 2,
  Março: 3,
  Abril: 4,
  Maio: 5,
  Junho: 6,
  Julho: 7,
  Agosto: 8,
  Setembro: 9,
  Outubro: 10,
  Novembro: 11,
  Dezembro: 12,
}

export type Months = keyof typeof months

export type holidaySelectors = {
  coverage?: {
    state: State | null
    city: City | null
  }
}

export type HolidayRouteStateProps = {
  holidayId?: string
}

export type HolidayFilter = {
  name?: string
  coverageType?: HolidayCoverageType
  repeatable?: boolean
  createdFrom?: number
  createdTo?: number
}
