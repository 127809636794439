import { Button, Modal, Combobox, ComboboxItem, Textarea } from 'components'
import { Partitions } from 'services/serviceOrder'
import { useEffect, useState } from 'react'
import styles from './styles.module.scss'

interface SecretsModalProps {
  onClose: () => void
  selectedPartition?: Partitions
  partitions: Partitions[]
}

export function SecretsModal({
  onClose,
  selectedPartition,
  partitions,
}: SecretsModalProps): JSX.Element {
  const [activePartition, setActivePartition] = useState<Partitions>()

  useEffect(() => {
    if (selectedPartition) {
      setActivePartition(selectedPartition)
    }
  }, [selectedPartition])

  return (
    <Modal title="Palavras chave" isVisible onClose={onClose} size="lg">
      <div className={styles.secretsModal}>
        <Combobox
          label="Partição"
          value={activePartition?.name}
          items={partitions?.map((partition) => ({
            label: 'name',
            value: partition,
          }))}
          getSelected={(selected) => {
            const partition = selected as unknown as ComboboxItem<Partitions>
            setActivePartition(partition.value)
          }}
        />
        <div>
          <h2 className={styles.secretsTitle}>Senhas</h2>
          <span className={styles.secretsLabel}>
            Palavras-chave do cliente definidas por partição
          </span>
        </div>
        <div className={styles.secretsInputs}>
          <Textarea
            disabled
            label="Senha Interna"
            className={styles.secretInput}
            value={activePartition?.securityQuestion || ''}
          />
          <Textarea
            disabled
            label="Senha Cliente"
            className={styles.secretInput}
            value={activePartition?.securityAnswer || ''}
          />
          <Textarea
            disabled
            label="Senha coação"
            className={styles.secretInput}
            value={activePartition?.securityCoercionAnswer || ''}
          />
        </div>
      </div>
      <Modal.Footer className={styles.footer}>
        <Button
          width="154px"
          type="secondary"
          onClick={onClose}
          buttonTitle={'Voltar'}
        />
      </Modal.Footer>
    </Modal>
  )
}
