import { useQuery } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import buildUrl from '../utils/buildUrl'
import { OccurrenceOnAttendanceHistory } from './types'

export const getOccurrenceHistory = async (occurrenceId = '') => {
  const result = await HttpClient.get<OccurrenceOnAttendanceHistory>(
    buildUrl({
      route: endpoints.history,
      params: { occurrenceId },
    }),
  )

  return result.data
}

export const useGetOccurrenceHistory = (occurrenceId = '') => {
  return useQuery({
    queryKey: ['occurrence-history', occurrenceId],
    queryFn: async () => await getOccurrenceHistory(occurrenceId),

    enabled: !!occurrenceId,
    refetchInterval: 10000,
  })
}
