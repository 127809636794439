import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  ButtonV2,
  ComboBoxV3,
  FormItem,
  FormLabel,
  FormMessage,
  Icon,
  ModalV2,
  TextareaV2,
} from 'components'
import {
  useCreateIntervention,
  useDefaultMode,
  useMaintenanceMode,
} from 'services'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'
import { fetchServiceOrderLabel } from 'services/serviceOrderLabel'
import { useToast, useUserInfo } from 'shared/hooks'

import styles from './styles.module.scss'
import { useRef } from 'react'

interface FormProps {
  issue: { id: string; description: string }
  resolution: { id: string; description: string }
  note: string
}

const schema = Joi.object({
  issue: Joi.object({
    id: Joi.string(),
    description: Joi.string(),
  }).required(),
  resolution: Joi.object({
    id: Joi.string(),
    description: Joi.string(),
  }).required(),
  note: Joi.string().required(),
})

const Finish = () => {
  const navigate = useNavigate()
  const { addToast } = useToast()
  const { userInfo } = useUserInfo()
  const closeRef = useRef<HTMLButtonElement>(null)
  const { serviceOrder } = useServiceOrderDetails()

  const {
    register,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<FormProps>({
    resolver: joiResolver(schema),
  })

  const { issue, resolution } = watch()

  const { mutate: mutateDefaultCommand, isPending: defaultIsPending } =
    useDefaultMode(serviceOrder?.centralId)

  const { mutate: mutateMaintenanceCommand, isPending: maintenanceIsPending } =
    useMaintenanceMode(serviceOrder?.centralId)

  const { mutate: mutateIntervention, isPending } = useCreateIntervention(
    serviceOrder?.id,
  )

  const handleGetIssueOptions = async (name?: string, offset = 0) => {
    const response = await fetchServiceOrderLabel({
      description: name,
      offset,
      type: 'ISSUE',
    })

    return response
  }

  const handleGetResolutionOptions = async (name?: string, offset = 0) => {
    const response = await fetchServiceOrderLabel({
      description: name,
      offset,
      type: 'RESOLUTION',
    })

    return response
  }

  const handleMutateIntervention = () =>
    mutateIntervention(
      {
        typeName: 'STATUS_CHANGE',
        userId: userInfo.id,
        note: {
          note: watch('note'),
          hidden: false,
          userId: userInfo.id,
        },
        attributes: {
          status: 'IN_VALIDATION',
          technicianId: serviceOrder?.technician?.id || userInfo.id,
          labelIds: [issue.id, resolution.id],
        },
      },
      {
        onSuccess: () => {
          addToast({
            message: 'Ordem de serviço concluída com sucesso.',
            type: 'success',
          })

          navigate('/so/search?page=1')
        },
        onError: () => {
          addToast({
            message: 'Erro ao concluir ordem de serviço. Tente novamente.',
            error: true,
            type: 'alert',
          })

          if (serviceOrder?.centralId) {
            mutateMaintenanceCommand({ userId: userInfo.id })
          }
        },
      },
    )

  const handleSubmit = () => {
    if (serviceOrder?.centralId) {
      mutateDefaultCommand(
        { userId: userInfo.id },
        {
          onSuccess: handleMutateIntervention,
          onError: () =>
            addToast({
              message:
                'Erro ao definir o status da central como manutenção. Tente novamente.',
              error: true,
              type: 'alert',
            }),
        },
      )
    } else {
      handleMutateIntervention()
    }
  }

  return (
    <ModalV2.Content className={styles.container}>
      <ModalV2.Title>
        <Icon name="warning" color="yellow" width={20} />
        Deseja concluir a ordem de serviço e enviá-la para validação?
      </ModalV2.Title>
      <form>
        <FormItem>
          <FormLabel>Problema encontrado</FormLabel>
          <ComboBoxV3.Root
            findOptions={handleGetIssueOptions}
            valueKey="description"
          >
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field value={issue?.description} />
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map(({ id, description }) => (
                    <ComboBoxV3.Option
                      key={id}
                      onClick={() => {
                        setValue(
                          'issue',
                          { id, description },
                          { shouldValidate: true },
                        )
                      }}
                    >
                      {description}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
        </FormItem>
        <FormItem>
          <FormLabel>Solução do problema</FormLabel>
          <ComboBoxV3.Root
            findOptions={handleGetResolutionOptions}
            valueKey="description"
          >
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field value={resolution?.description} />
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map(({ id, description }) => (
                    <ComboBoxV3.Option
                      key={id}
                      onClick={() => {
                        setValue(
                          'resolution',
                          { id, description },
                          { shouldValidate: true },
                        )
                      }}
                    >
                      {description}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
        </FormItem>
        <FormItem>
          <FormLabel>Observação</FormLabel>
          <TextareaV2
            {...register('note')}
            maxLength={5000}
            rows={5}
            placeholder="Descreva o serviço encontrado"
          />
          <FormMessage>
            {5000 - (watch('note')?.length || 0)} caracteres restantes
          </FormMessage>
        </FormItem>

        <ModalV2.Footer>
          <ModalV2.Close asChild>
            <ButtonV2 appearance="tertiary" ref={closeRef}>
              Cancelar
            </ButtonV2>
          </ModalV2.Close>
          <ButtonV2
            onClick={handleSubmit}
            disabled={
              !isValid || isPending || defaultIsPending || maintenanceIsPending
            }
          >
            Concluir
          </ButtonV2>
        </ModalV2.Footer>
      </form>
    </ModalV2.Content>
  )
}

export default Finish
