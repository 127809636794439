import { dayOfWork } from 'services/types'

export const dayOfWorkOptions = {
  WORKDAYS: 'WORKDAYS',
  ...dayOfWork,
}

export type DayOfWorkOptions = keyof typeof dayOfWorkOptions

export const translatedDayOfWork: Record<DayOfWorkOptions, string> = {
  WORKDAYS: 'Dias úteis',
  MONDAY: 'Segunda-feira',
  TUESDAY: 'Terça-feira',
  WEDNESDAY: 'Quarta-feira',
  THURSDAY: 'Quinta-feira',
  FRIDAY: 'Sexta-feira',
  SATURDAY: 'Sábado',
  SUNDAY: 'Domingo',
  HOLIDAY: 'Considerar feriados',
}

export const translatedScaleConfig: Record<DayOfWorkOptions, string> = {
  WORKDAYS: 'Dias úteis',
  MONDAY: 'Segunda',
  TUESDAY: 'Terça',
  WEDNESDAY: 'Quarta',
  THURSDAY: 'Quinta',
  FRIDAY: 'Sexta',
  SATURDAY: 'Sábado',
  SUNDAY: 'Domingo',
  HOLIDAY: 'Feriados',
}

export const dayOfWorkKeys: Record<string, DayOfWorkOptions> = {
  [translatedDayOfWork.WORKDAYS]: 'WORKDAYS',
  [translatedDayOfWork.MONDAY]: 'MONDAY',
  [translatedDayOfWork.TUESDAY]: 'TUESDAY',
  [translatedDayOfWork.WEDNESDAY]: 'WEDNESDAY',
  [translatedDayOfWork.THURSDAY]: 'THURSDAY',
  [translatedDayOfWork.FRIDAY]: 'FRIDAY',
  [translatedDayOfWork.SATURDAY]: 'SATURDAY',
  [translatedDayOfWork.SUNDAY]: 'SUNDAY',
  [translatedDayOfWork.HOLIDAY]: 'HOLIDAY',
}

export const workingDaysOfTheWeek: DayOfWorkOptions[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
]

export const translatedTolerance: Record<string, string> = {
  15: '15 minutos',
  30: '30 Minutos',
  45: '45 Minutos',
}

export const convertTolerance: Record<string, number> = {
  '15 minutos': 15,
  '30 Minutos': 30,
  '45 Minutos': 45,
}

export const dayOfWorkIndexPosition: Record<DayOfWorkOptions, number> = {
  WORKDAYS: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7,
  SUNDAY: 8,
  HOLIDAY: 9,
}
