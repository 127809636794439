import { PartitionUpdatePayload } from '../types'
import HttpClient from 'services/httpClient'
import { useMutation } from '@tanstack/react-query'
import buildUrl from 'utilities/buildUrl'
import { endpoints } from '../endpoints'
import { AxiosResponse } from 'axios'

type UpdatePartitionContactsPayload = {
  partitionId: string
  partition: PartitionUpdatePayload
}

async function updatePartition(
  payload: UpdatePartitionContactsPayload,
): Promise<AxiosResponse> {
  return await HttpClient.put(
    buildUrl({
      route: endpoints.update,
      params: { id: payload.partitionId },
    }),
    payload.partition,
  )
}

export const usePutPartitionContacts = () =>
  useMutation({
    mutationFn: async (payload: UpdatePartitionContactsPayload) => {
      await updatePartition(payload)
    },
  })
