import * as Joi from '@hapi/joi'
import { UserFormData } from 'domains/user/types'
import { PermissionGroupsQueryResponse, UserCoverages } from 'services/auth'
import { Coverage } from 'services/coverages'
import { StringSchemeErrorsEnum } from 'utilities/validation'

export const coverageSchemaMap: Joi.SchemaMap<Coverage> = {
  id: Joi.string().uuid().required(),
  name: Joi.string().required(),
  coverageType: Joi.string().required(),
  stateIds: Joi.array().items(Joi.string().uuid()).optional(),
  cityIds: Joi.array().items(Joi.string().uuid()).optional(),
  districtIds: Joi.array().items(Joi.string().uuid()).optional(),
  accountTagIds: Joi.array().items(Joi.string().uuid()).optional(),
  accountIds: Joi.array().items(Joi.string().uuid()).optional(),
  occurrenceCategories: Joi.array().items(Joi.string()),
  serviceOrderTypes: Joi.array().items(Joi.string()),
  totalUsers: Joi.number().allow(null).optional(),
  createdAt: Joi.number().required(),
  updatedAt: Joi.number().optional(),
  serviceTypes: Joi.array().items(
    Joi.object({
      id: Joi.string().required(),
      name: Joi.string().required(),
    })
      .allow(null, '')
      .optional(),
  ),
}

const commonSchema = Joi.object({
  id: Joi.string().uuid().required(),
  name: Joi.string().required(),
})

const passwordPattern =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@*!%;:.]).{8,}$/

const validate = (value: UserFormData, helpers: Joi.CustomHelpers) => {
  if (!value.currentEmail && !value.username) {
    const errors = helpers.error('any.required')

    return errors
  }

  if (value.currentPassword) {
    const validPassword = new RegExp(passwordPattern).test(
      value?.currentPassword || '',
    )

    if (!validPassword) {
      return helpers.error(StringSchemeErrorsEnum.PATTERN_BASE)
    }
  }

  if (value.loginWithEmail && !!value.username) {
    if (!value.currentPassword)
      return helpers.error(StringSchemeErrorsEnum.REQUIRED)
  }

  if (value.fillPassword && !!value.username) {
    if (!value.currentPassword)
      return helpers.error(StringSchemeErrorsEnum.REQUIRED)
  }

  return value
}

export const userSchema = Joi.object<UserFormData>({
  id: Joi.string().uuid().optional(),
  name: Joi.string()
    .messages({
      [StringSchemeErrorsEnum.EMPTY]: 'O nome precisa ser preenchido',
    })
    .required(),
  document: Joi.string()
    .min(11)
    .allow('', null)
    .messages({
      [StringSchemeErrorsEnum.EMPTY]: 'O documento precisa ser preenchido',
      [StringSchemeErrorsEnum.MIN]:
        'O documento precisa ter no mínimo 11 caracteres',
    })
    .optional(),
  avatarId: Joi.string().uuid().optional(),
  avatarFile: Joi.object().optional(),
  active: Joi.string().optional(),
  currentEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .allow('', null)
    .messages({
      [StringSchemeErrorsEnum.EMAIL]:
        'Um email válido segue o formato: nome@domínio.tld',
    })
    .optional(),
  phone: Joi.string().allow('', null).optional(),
  username: Joi.string()
    .allow('', null)
    .regex(/^\S+(@\S+)?$/)
    .messages({
      [StringSchemeErrorsEnum.PATTERN_BASE]: 'Formato de usuário inválido',
    }),
  fillPassword: Joi.boolean().optional(),
  loginWithEmail: Joi.boolean().optional(),
  currentPassword: Joi.string().allow('').optional(),
  confirmPassword: Joi.string()
    .allow('')
    .optional()
    .when('currentPassword', {
      is: Joi.exist().not(''),
      then: Joi.string()
        .not(null, '')
        .valid(Joi.ref('currentPassword'))
        .messages({
          'any.only': 'A confirmação de senha não confere',
        })
        .required(),
      otherwise: Joi.string().allow('').optional(),
    })
    .messages({
      [StringSchemeErrorsEnum.INVALID]:
        'A senha deve ser informada quando o usuário é preenchido',
    }),

  phoneExtension: Joi.string().allow('').allow(null).optional(),
  permissionGroups: Joi.array()
    .items(
      Joi.object<PermissionGroupsQueryResponse>({
        id: Joi.string().uuid().required(),
        name: Joi.string().required(),
      }),
    )
    .min(1)
    .required(),
  tags: Joi.array().items(Joi.string()).optional(),
  userCoverages: Joi.object<UserCoverages>({
    primary: Joi.array()
      .items({ ...coverageSchemaMap })
      .optional(),
    secondary: Joi.array()
      .items({ ...coverageSchemaMap })
      .optional(),
  }).optional(),
  state: commonSchema.allow(null).optional(),
  city: commonSchema.allow(null).optional(),
  district: commonSchema.allow(null).optional(),

  postalCode: Joi.string().allow('', null).optional(),
  address: Joi.string().allow('', null).max(45).optional(),
  number: Joi.string().allow('', null).optional(),
  adjunct: Joi.string().allow('', null).optional(),
  reference: Joi.string().allow('', null).max(60).optional(),
  activeDateTime: Joi.number().allow('', null).optional(),
}).custom(validate)
