import { DeviceType } from 'services/partition/types'

import { IconWithTooltip } from 'components'
import { getDeviceInfo } from 'utilities/devices'

import { ReactComponent as ExclamationOrange } from 'assets/svg/exclamationOrange.svg'

import styles from './DeviceUninstalledCard.module.scss'

type DeviceUninstalledCardProps = {
  type: string
  name: string
  notVinculated: boolean
}

export const DeviceUninstalledCard = ({
  type,
  name,
  notVinculated,
}: DeviceUninstalledCardProps) => (
  <div className={styles.device}>
    <img
      className={styles.deviceThumbnail}
      src={getDeviceInfo((type === '6' ? '99' : type) as DeviceType)?.image}
      alt={name}
    />
    <div>
      <p>{name}</p>
      <small>
        {getDeviceInfo((type === '6' ? '99' : type) as DeviceType)?.type}{' '}
        {notVinculated && (
          <IconWithTooltip
            Icon={ExclamationOrange}
            text="Equipamento não vinculado à instalação"
          />
        )}
      </small>
    </div>
  </div>
)
