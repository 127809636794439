import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { ButtonV2, Checkbox, Datepicker, ModalV2, Textarea } from 'components'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'

import styles from './EndOccurrence.module.scss'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useCreateInterventation } from 'services/displacementMap'
import { useDebounce, useToast, useUserInfo } from 'shared/hooks'
import { useDisplacementModal, useMap } from '../../../../contexts'
import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import {
  EVENT_CODES_CAN_DISABLE_HABIT_DEVIATION,
  handleHoursAndMinutes,
} from 'domains/occurrence/screens/Attendance/containers/Modals/components/FinishOccurrence/utils'
import {
  useDisableHabitDeviation,
  useGetOccurrenceHistory,
  useGetOccurrenceOnAttendance,
} from 'services/attendance'
import { InterventionTag } from 'domains/occurrence/data/interventionTag'
import { useGetAttendanceSentence } from 'shared/hooks/services/attendanceSentence/useGetAttendanceSentence'
import { AttendanceSentenceResponse } from 'services/occurrence/types'

type FormType = {
  note: string
  tags: { name: string }[]
  hour: string
  date: number
  postponeUntil: boolean
}

const schema = Joi.object<FormType>({
  note: Joi.string().required(),
  tags: Joi.array()
    .items(Joi.object({ name: Joi.string().required() }))
    .required(),
  postponeUntil: Joi.string().optional(),
  hour: Joi.when('postponeUntil', {
    is: 'postponeUntil',
    then: Joi.string().required(),
    otherwise: Joi.string().optional(),
  }),
  date: Joi.when('postponeUntil', {
    is: 'postponeUntil',
    then: Joi.number().required(),
    otherwise: Joi.number().optional(),
  }),
})

export const EndOccurrence = () => {
  const [sentenceName, setSentenceName] = useState('')
  const [isError, setIsError] = useState(false)
  const { selectedRoute } = useMap()
  const { userInfo } = useUserInfo()
  const { handleCloseModal } = useDisplacementModal()

  const { mutate } = useCreateInterventation(selectedRoute.occurrence?.id)
  const { data: occurrence } = useGetOccurrenceOnAttendance(
    selectedRoute.occurrence?.id,
  )
  const { mutate: disableHabitDeviation, isPending: disabledHabitIsLoading } =
    useDisableHabitDeviation()

  const { addToast } = useToast()

  const {
    setValue,
    handleSubmit,
    register,
    watch,
    formState: { isValid },
  } = useForm<FormType>({
    defaultValues: {
      note: '',
      tags: [],
    },
    mode: 'onChange',
    resolver: joiResolver(schema),
  })

  const note = watch('note')

  const handleChangeScheduleDateInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        setValue('date', convertStringToDate(value).getTime(), {
          shouldValidate: true,
        })
      }

      return value
    },
    [setValue],
  )

  const { data: occurrenceHistory } = useGetOccurrenceHistory(
    selectedRoute.occurrence?.id,
  )

  const canDisableHabitDeviation = useMemo(() => {
    const test = occurrenceHistory?.events?.some((event) =>
      EVENT_CODES_CAN_DISABLE_HABIT_DEVIATION.includes(event.details.code),
    )

    return test
  }, [history]) // eslint-disable-line

  const handleFinishOccurrence = useCallback(
    (data: FormType) => {
      const userId = userInfo.id

      if (userId) {
        mutate(
          {
            typeName: 'STATE_CHANGE',
            attributes: {
              stateName: 'FINISHED',
            },
            ...data,
            tags: [...data.tags, { name: InterventionTag.FINALIZADA }],
            note: data.note,
            userId,
            title: 'Finalização de ocorrência',
          },
          {
            onError: () => setIsError(true),
            onSuccess: handleCloseModal,
          },
        )
      }
    },
    [handleCloseModal, mutate],
  )

  const onSubmit = useCallback(
    async (data: FormType) => {
      if (data.postponeUntil) {
        const partitions = occurrence?.partitions.filter(
          (partition) => partition.isInOccurrence,
        )

        if (partitions) {
          const date = new Date(data.date)
          const [hour, minutes] = data.hour.split(':').map(Number)

          date.setHours(hour)
          date.setMinutes(minutes)

          await Promise.all(
            partitions?.map((partition) => {
              disableHabitDeviation({
                partitionId: partition.id,
                scheduledDate: date.getTime(),
              })
            }),
          )
            .then(() => handleFinishOccurrence(data))
            .catch(() => {
              addToast({
                message: 'Erro ao adiar atendimento',
                type: 'alert',
              })
            })
        }
      } else handleFinishOccurrence(data)
    },
    [addToast, disableHabitDeviation, handleFinishOccurrence, occurrence],
  )

  const handleFilter = useDebounce(setSentenceName)

  const { data: attendanceSentence } = useGetAttendanceSentence({
    interventionType: 'STATE_CHANGE',
    serviceTypeId: occurrence?.occurrence.type.id,
    occurrenceTypeId: occurrence?.patrimony.serviceTypeId,
    name: sentenceName,
  })

  return (
    <ModalV2.Root isOpen onClose={handleCloseModal}>
      <ModalV2.Content className={styles.content} size={isError ? 'sm' : 'md'}>
        <ModalV2.Title>
          {isError
            ? 'Não é possível finalizar este atendimento'
            : 'Finalizar atendimento de ocorrência'}
        </ModalV2.Title>
        {isError ? (
          <>
            <p>
              Existem logs não preenchidos nesta ocorrência. Para finalizá-la,
              registre a tratativa efetivada em todas as intervenções realizadas
              durante o atendimento.
            </p>

            <ButtonV2 onClick={handleCloseModal} appearance="primary">
              Voltar
            </ButtonV2>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Combobox
              label={{
                text: 'Tratativa',
              }}
              onSearch={handleFilter}
              placeholder="Selecione uma opção de tratativa"
              items={parseDataToComboboxV2(
                attendanceSentence?.data || [],
                'name',
              )}
              {...register('tags')}
              onChange={(value) => {
                const selected =
                  value as unknown as ComboboxItem<AttendanceSentenceResponse>

                setValue('note', note + selected?.value.sentence, {
                  shouldValidate: true,
                })

                setValue('tags', selected?.value.tags, {
                  shouldValidate: true,
                })
              }}
            />
            <div className={styles.textareaContainer}>
              <Textarea
                rows={5}
                label="Observações"
                maxLength={255}
                value={note}
                {...register('note')}
                className={styles.textArea}
                onChange={(event) =>
                  setValue('note', event.target.value, { shouldValidate: true })
                }
              />
              {canDisableHabitDeviation && (
                <div className={styles.habitDeviation}>
                  <Checkbox
                    id="postponeUntil"
                    label="Adiar atendimento até"
                    {...register('postponeUntil')}
                    checked={!!watch('postponeUntil')}
                    onChange={(event) =>
                      setValue('postponeUntil', event.target.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                  <Datepicker
                    id="date"
                    {...register('date')}
                    disabled={!watch('postponeUntil')}
                    initialValueInput={
                      watch('date')
                        ? convertDateToString(new Date(watch('date')))
                        : undefined
                    }
                    onChangeInput={handleChangeScheduleDateInput}
                    initialDate={
                      watch('date') ? new Date(watch('date')) : undefined
                    }
                    onChangeDate={(date) =>
                      setValue('date', date?.getTime(), {
                        shouldValidate: true,
                      })
                    }
                  />
                  <span>às</span>
                  <Combobox
                    id="hour-input"
                    placeholder="--:--"
                    {...register('hour')}
                    value={watch('hour')}
                    items={handleHoursAndMinutes()}
                    disabled={!watch('postponeUntil')}
                    onChange={(value) =>
                      setValue('hour', value, { shouldValidate: true })
                    }
                  />
                </div>
              )}
            </div>
            <ModalV2.Footer>
              <ModalV2.Close asChild>
                <ButtonV2 appearance="secondary">Cancelar</ButtonV2>
              </ModalV2.Close>
              <ButtonV2
                disabled={!isValid || disabledHabitIsLoading}
                type="submit"
              >
                Salvar
              </ButtonV2>
            </ModalV2.Footer>
          </form>
        )}
      </ModalV2.Content>
    </ModalV2.Root>
  )
}
