import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import './Slider.scss'

export interface SliderProps {
  value: number
  setValue: Dispatch<SetStateAction<number>>
  inputMinValue: number
  inputMaxValue: number
}

export const Slider = (props: SliderProps) => {
  const { value, setValue, inputMinValue, inputMaxValue } = props

  return (
    <div className="slider-container">
      <div
        className="decrease-btn"
        onClick={() => setValue((value) => value - 0.1)}
      >
        -
      </div>

      <input
        type="range"
        value={value}
        min={inputMinValue}
        max={inputMaxValue}
        step={0.1}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(Number(e.target.value))
        }
        aria-label="slider-input"
      />
      <div
        className="increase-btn"
        onClick={() => setValue((value) => value + 0.1)}
      >
        +
      </div>
    </div>
  )
}

export default Slider
