import {
  HistoryIntervention,
  InterventionTypeName,
} from 'services/attendance/types'

export const isInterventionExpandable = (intervention: HistoryIntervention) => {
  const stateNameKeys: Record<InterventionTypeName, boolean> = {
    AUTOMATIC: true,
    PHONE_CALL: true,
    SEND_IMAGE: false,
    SEND_MESSAGE: true,
    MANUAL_RECORD: true,
    SEND_CHECKLIST: true,
    IMAGE_VERIFICATION: true,
    CONFIRM_INTRUSION: false,
    REQUIRE_PHONE_CALL: false,
    SEND_ALERT: Boolean(
      [
        'TRAVEL_WITHOUT_CHANGES',
        'COORDINATES_UPDATE_REQUEST',
        'SUPPORT_REQUEST',
      ].includes(intervention.attributes?.alertType),
    ),
    STATE_CHANGE: !!intervention.notes.length,
    REFUSE_PHONE_CALL_REQUIREMENT: !!intervention.notes.length,
  }

  return stateNameKeys[intervention.typeName]
}
