import { useQuery } from '@tanstack/react-query'
import { DeviceDriver } from 'services/device'

const fetchEquipments = async (partitionId: string, centralId: string) => {
  const response = await DeviceDriver.queryEquipments({
    centralId,
    partitionId,
    sort: 'code',
    sortType: 'ASC',
  })

  return response
}

export function useGetEquipments(
  partitionId: string,
  centralId: string,
  patrimonyId: string,
  enabled: boolean,
) {
  return useQuery({
    queryKey: ['equipments', patrimonyId, centralId, partitionId],
    queryFn: async () => await fetchEquipments(partitionId, centralId),
    enabled,
  })
}
