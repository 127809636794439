import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { AccordionType } from 'domains/attendancePolicy/types'

import { useForm, useFormContext } from 'react-hook-form'
import {
  AggregatedBranch,
  AggregatedRule,
  BranchOperation,
} from 'services/attendancePolicy/types'
import { joiResolver } from '@hookform/resolvers/joi'
import { branchesSchema } from 'domains/attendancePolicy/schema'

import { conditionOptions } from 'domains/attendancePolicy/data/condition'

import { ReactComponent as ButtonBack } from 'assets/svg/leftArrow.svg'
import { Button, ButtonGroup } from 'components'
import {
  AccordionGroupForm,
  AccordionForm,
} from 'domains/attendancePolicy/components/CondititonForms'

import styles from './ConditionFormGroup.module.scss'

interface ConditionFormGroupProps {
  onGoBack: () => void
}

export const ConditionFormGroup = ({ onGoBack }: ConditionFormGroupProps) => {
  const { setValue: setRuleForm, watch: watchRuleForm } =
    useFormContext<AggregatedRule>()

  const form = useForm<{ branches: AggregatedBranch[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(branchesSchema),
    defaultValues: {
      branches: watchRuleForm('branches') || [],
    },
  })

  const { register, watch, setValue, formState, trigger } = form

  const branches = watch('branches')

  const [selectedBranch, setSelectedBranch] = useState<AggregatedBranch>()

  const handleGoBackButton = () => {
    return onGoBack?.()
  }

  const handleDeleteGroup = useCallback(
    (groupIndex: number) => {
      const newBranches = branches.filter(
        (branch) => branch.index !== groupIndex,
      )

      setValue(
        'branches',
        newBranches.map((item, index) => ({ ...item, index: index + 1 })),
        { shouldValidate: true },
      )
    },
    [branches, setValue],
  )

  useEffect(() => {
    register('branches')
  }, [register])

  useLayoutEffect(() => {
    if (!branches.length) {
      const newBranch: AggregatedBranch = {
        index: 1,
        conditions: [],
        branchOperation: BranchOperation.AND,
        conditionsOperation: BranchOperation.AND,
      }

      setValue('branches', [newBranch])
      setSelectedBranch(newBranch)
    }
  }, [setValue, trigger])

  useEffect(() => {
    trigger()
  }, [selectedBranch, trigger])

  return (
    <>
      <Button
        buttonTitle={
          selectedBranch ? 'Voltar ao grupo' : 'Voltar para automação'
        }
        type="tertiary"
        icon={ButtonBack}
        className={styles.buttonBack}
        onClick={() => {
          if (!selectedBranch) {
            handleGoBackButton()
          } else {
            setSelectedBranch(undefined)
          }
        }}
      />

      <div className={styles.header}>
        <span>
          {selectedBranch
            ? 'Adicionar condição'
            : 'Adicionar grupo de condições'}
        </span>
        <p>
          Critério que precisa ser atendido para executar uma determinada ação.
          Este é um item opcional
        </p>
      </div>

      {!selectedBranch && branches.length > 1 && (
        <div className={styles.buttonGroupWrapper}>
          <ButtonGroup
            leftButton={{
              id: 'only-condition',
              onClick: () => {
                setValue(
                  'branches',
                  branches.map((branch) => ({
                    ...branch,
                    branchOperation: BranchOperation.OR,
                  })),
                )
              },
              title: 'Apenas uma',
              selected: branches[0].branchOperation === BranchOperation.OR,
            }}
            rightButton={{
              id: 'all-conditions',
              onClick: () => {
                setValue(
                  'branches',
                  branches.map((branch) => ({
                    ...branch,
                    branchOperation: BranchOperation.AND,
                  })),
                )
              },
              title: 'Todas abaixo',
              selected: branches[0].branchOperation === BranchOperation.AND,
            }}
          />
        </div>
      )}

      <div className={styles.container}>
        {!selectedBranch && (
          <AccordionGroupForm
            branches={branches}
            onDelete={handleDeleteGroup}
            onEdit={(branch) => setSelectedBranch(branch)}
            onAdd={() => {
              const newBranch: AggregatedBranch = {
                index: branches.length + 1,
                conditions: [],
                branchOperation:
                  branches?.[0]?.branchOperation || BranchOperation.OR,
                conditionsOperation: BranchOperation.AND,
              }

              setValue('branches', [...branches, newBranch], {
                shouldValidate: true,
              })

              setSelectedBranch(newBranch)
            }}
          />
        )}

        {selectedBranch && (
          <AccordionForm
            type={AccordionType.Condition}
            ruleOptions={conditionOptions}
            branch={selectedBranch}
            onSubmit={(conditions, conditionOperation, shouldAddNewGroup) => {
              const newBranches = watch('branches').map((branch) => {
                if (branch.index === selectedBranch.index) {
                  const updatedBranch = {
                    ...branch,
                    conditions,
                    conditionsOperation: conditionOperation,
                  }

                  return updatedBranch
                }
                return branch
              })

              if (shouldAddNewGroup) {
                const newBranch: AggregatedBranch = {
                  index: newBranches.length + 1,
                  conditions: [],
                  branchOperation:
                    branches?.[0]?.branchOperation || BranchOperation.OR,
                  conditionsOperation: BranchOperation.AND,
                }

                setValue('branches', [...newBranches, newBranch], {
                  shouldValidate: true,
                })

                setSelectedBranch(newBranch)
              } else {
                if (newBranches.length < 2) {
                  setRuleForm('branches', newBranches, {
                    shouldValidate: true,
                  })

                  onGoBack?.()
                  return
                }

                setValue('branches', newBranches, { shouldValidate: true })

                setSelectedBranch(undefined)
              }
            }}
          />
        )}
      </div>
      <div className={styles.footer}>
        {!selectedBranch && (
          <Button
            buttonTitle="Salvar grupo de condições"
            onClick={() => {
              setRuleForm('branches', watch('branches'), {
                shouldValidate: true,
              })
              onGoBack?.()
            }}
            disabled={!formState.isValid}
            type="secondary"
            width="212px"
          />
        )}
      </div>
    </>
  )
}
