import {
  ButtonV2,
  ErrorState,
  Icon,
  Info,
  ProtectedImage,
  Tag,
} from 'components'
import { useGetAccount } from 'services/serviceOrderV2'
import {
  useModal,
  useServiceOrderDetails,
} from 'domains/serviceOrder/screens/Details/context'

import Loading from './Loading'
import styles from './styles.module.scss'
import { ContactItem, History } from './components'
import { handleRenderState } from 'utilities/handleRenderState'

import { useCallback } from 'react'
import { usePersistentTab } from 'domains/occurrence/hooks'
import { CustomerPaths } from 'routes/constants/paths/customer'

const Details = () => {
  const { handleOpenModal, handleCloseModal } = useModal()
  const { serviceOrder } = useServiceOrderDetails()
  const { data, isLoading, isError } = useGetAccount(serviceOrder?.accountId)

  const address = data?.patrimony?.address

  const { replaceUrl } = usePersistentTab('service-order-account-tab')

  const handleSeeMoreAccount = useCallback(() => {
    localStorage.setItem('accountId', data?.account.id || '')
    localStorage.setItem('patrimonyId', data?.patrimony.id || '')

    replaceUrl(`${CustomerPaths.Account}`)
  }, [replaceUrl, data])

  return (
    <div className={styles.account}>
      {data?.patrimony.image ? (
        <ProtectedImage
          imageId={data.patrimony.image}
          height={160}
          onClick={() =>
            handleOpenModal({
              type: 'GALLERY',
              data: {
                imageId: String(data?.patrimony.image),
                onClose: handleCloseModal,
                images: [
                  {
                    id: String(data?.patrimony.image),
                    url: '',
                    creation: new Date().getTime(),
                    hidden: false,
                    owners: [],
                  },
                ],
              },
            })
          }
        />
      ) : (
        <div className={styles.emptyImage}>
          <Icon name="photo-off" width={48} color="element-extralight" />
        </div>
      )}

      <div className={styles.content}>
        {
          {
            loading: <Loading />,
            error: <ErrorState />,
            view: (
              <>
                <section>
                  <h3>Cliente</h3>
                  <Info.Root>
                    <Info.Title>
                      Conta
                      <ButtonV2
                        appearance="tertiary"
                        size="md"
                        onClick={() => handleSeeMoreAccount()}
                      >
                        <Icon name="open" />
                        Ver Conta
                      </ButtonV2>
                    </Info.Title>
                    <Info.Content>
                      {data?.account.code} - {data?.account.name}
                      {data?.account.serviceType && (
                        <Tag color="blue">{data?.account.serviceType.name}</Tag>
                      )}
                    </Info.Content>
                  </Info.Root>

                  <Info.Root>
                    <Info.Title>Patrimônio</Info.Title>
                    <Info.Content>{data?.patrimony.name}</Info.Content>
                  </Info.Root>

                  <Info.Root>
                    <Info.Title>Endereço</Info.Title>
                    <Info.Content>
                      {`${address?.address}, ${address?.number}, ${address?.district}, ${address?.city}/${address?.acronym}`}
                    </Info.Content>
                  </Info.Root>
                </section>
                <section>
                  <h3>Contatos</h3>
                  <ul className={styles.contacts}>
                    {data?.contacts.map((contact) => (
                      <ContactItem key={contact.id} contact={contact} />
                    ))}
                  </ul>
                </section>
              </>
            ),
          }[handleRenderState({ data, isLoading, isError })]
        }
        <History />
      </div>
    </div>
  )
}

export default Details
