import { Button, Modal } from 'components'

export type AttendanceProfileDeleteModalProps = {
  isVisible: boolean
  onClose: () => void
  onClick: () => void
  profileName: string
  vinculatedUsers?: number
}

export const AttendanceProfileDeleteModal = ({
  isVisible,
  onClose,
  onClick,
  profileName,
  vinculatedUsers,
}: AttendanceProfileDeleteModalProps) => {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      size={'sm'}
      simple
      title={'Exclusão de perfil de atendimento'}
    >
      <div>
        <p>
          Você tem certeza que deseja remover o perfil de <br />
          atendimento {profileName}?
          {vinculatedUsers
            ? ` Existem ${vinculatedUsers} usuários vinculados a ele no momento.`
            : null}
        </p>
      </div>
      <Modal.Footer>
        <Button
          buttonTitle="Cancelar"
          onClick={onClose}
          type="secondary"
          width="172px"
        />
        <Button
          buttonTitle="Excluir"
          onClick={onClick}
          type="cancel-primary"
          width="172px"
        />
      </Modal.Footer>
    </Modal>
  )
}
