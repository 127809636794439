export const formatGenericPhone = (value: string): string => {
  const numbers = value.replace(/\D/g, '')
  const RESIDENTIAL_REGEX = /(\d{2})(\d{4})(\d)/
  const CELLPHONE_REGEX = /(\d{2})(\d{5})(\d)/

  if (numbers.length <= 6) {
    return numbers.replace(/(\d{2})(\d)/, '($1) $2')
  }

  return numbers.replace(
    numbers.length > 10 ? CELLPHONE_REGEX : RESIDENTIAL_REGEX,
    '($1) $2-$3',
  )
}
