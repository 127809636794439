import React, { useCallback, useRef, useState } from 'react'

import { Button, Input, Table, TableRef } from 'components'

import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'
import { ReactComponent as InspectIcon } from 'assets/svg/listInspectIcon.svg'
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg'
import { ReactComponent as EmptyStateSearchIcon } from 'assets/svg/emptyStateSearchIcon.svg'
import { ReactComponent as EmptyStateNoResultIcon } from 'assets/svg/search.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'

import styles from './Vehicles.module.scss'
import { CellProps, Column } from 'react-table'
import {
  VehicleAggregatedQueryResponse,
  VehicleQuery,
  VEHICLE_CATEGORY_ENUM,
  VEHICLE_CATEGORY_ENUM_EN_PT,
  VEHICLE_IGNITION_STATUS_ENUM,
  VEHICLE_TYPE_ENUM,
  VehicleDriver,
} from 'services/vehicle'

import { formatLicensePlate } from '../../utilities/masks'

import { maskedDateTime } from 'utilities/date'

import { useToggle } from 'shared/hooks'

import { Pagination, Result } from 'services/types'
import { useNavigate } from 'react-router'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { vehicleQuerySchema } from '../../schemas'
import { VehicleDelete, VehicleDetails } from '../../components'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'

const emptyVehicle: VehicleAggregatedQueryResponse = {
  id: '',
  description: '',
  model: '',
  modelYear: 0,
  productionYear: 0,
  licensePlate: '',
  tracker: { model: '', serial: 0, name: '' },
  vehicleCategory: VEHICLE_CATEGORY_ENUM.CAR,
  vehicleType: VEHICLE_TYPE_ENUM.TECHNICAL,
  active: true,
  createdAt: 0,
  updatedAt: 0,
  latitude: 0,
  longitude: 0,
  lastMovement: 0,
  lastCommunication: 0,
  speed: 0,
  driver: { id: '' },
  status: '',
  ignitionStatus: VEHICLE_IGNITION_STATUS_ENUM.OFF,
  coverages: [],
}

const columns: Column<VehicleAggregatedQueryResponse>[] = [
  {
    id: 'licensePlate',
    Header: 'Placa',
    accessor: (vehicle) => {
      return `${formatLicensePlate(vehicle.licensePlate)}`
    },
  },
  {
    id: 'vehicleCategory',
    Header: 'Veículo',
    accessor: 'vehicleCategory',

    // eslint-disable-next-line react/display-name
    Cell: (cell: CellProps<VehicleAggregatedQueryResponse>) => (
      <>{VEHICLE_CATEGORY_ENUM_EN_PT[cell.value as VEHICLE_CATEGORY_ENUM]}</>
    ),
  },
  { id: 'model', Header: 'Modelo', accessor: 'model' },
  {
    id: 'year',
    Header: 'Ano',
    accessor: (vehicle) => `${vehicle.productionYear} - ${vehicle.modelYear}`,
  },
  {
    id: 'operator',
    Header: 'Usuário logado',
    accessor: (vehicle) => vehicle.driver.name || '-',
  },
  {
    id: 'createdAt',
    Header: 'Cadastrado em',
    accessor: (vehicle) => maskedDateTime(vehicle.createdAt),
  },
]

export const Vehicles: React.FC = () => {
  const [emptyStateProps, setEmptyStateProps] = useState({
    title: 'Sem resultados',
    message: 'Nenhum registro foi encontrado nesta seção',
    Icon: EmptyStateSearchIcon,
  })
  const [selectedVehicle, setSelectedVehicle] =
    useState<VehicleAggregatedQueryResponse>(emptyVehicle)
  const [resetPagination, setResetPagination] = useState<boolean>(false)

  const vehicleDetailsComponent = useToggle()
  const vehicleDeleteComponent = useToggle()

  const navigate = useNavigate()

  const tableRef = useRef<TableRef>(null)

  const { getFilters, setFilters } =
    usePersistentFilters<VehicleQuery>('vehicle')

  const form = useForm<VehicleQuery>({
    resolver: joiResolver(vehicleQuerySchema),
    defaultValues: getFilters(),
  })

  const { handleSubmit, watch } = form

  const licencePlate = watch('licensePlate')

  const fetchVehicles = useCallback(
    async (pagination: Pagination) => {
      let result: Result<VehicleAggregatedQueryResponse> = {
        data: [],
        totalElements: 0,
      }

      try {
        const vehicles = await VehicleDriver.query({
          ...(licencePlate && { licensePlate: licencePlate }),
          ...pagination,
        })

        result = {
          data: vehicles.data,
          totalElements: vehicles.totalElements,
        }
        if (!result.data.length) {
          setEmptyStateProps({
            title: 'Sem resultados',
            message:
              'Nenhum registro foi encontrado utilizando os filtros selecionados.',
            Icon: EmptyStateNoResultIcon,
          })
        }
      } finally {
        setResetPagination(false)
      }
      return result
    },
    [licencePlate],
  )

  return (
    <>
      <VehicleDetails
        isVisible={vehicleDetailsComponent.isVisible}
        onClose={vehicleDetailsComponent.hide}
        vehicle={selectedVehicle}
      />

      <VehicleDelete
        isVisible={vehicleDeleteComponent.isVisible}
        onClose={() => {
          vehicleDeleteComponent.hide()
          tableRef.current?.handleFetchPage()
        }}
        vehicle={selectedVehicle}
      />

      <FormProvider {...form}>
        <div className={styles.container}>
          <span className={styles.title}>Viaturas</span>
          <div className={styles.divider} />
          <form
            className={styles.inputsWrapper}
            onSubmit={handleSubmit(() => {
              setFilters(watch())
              setResetPagination(true)
              tableRef.current?.handleFetchPage()
            })}
          >
            <div className={styles.innerWrapper}>
              <div className={styles.filtersButtons}>
                <Input
                  id="number"
                  label="Placa da viatura"
                  placeholder="Digite a placa da viatura"
                  autoComplete="off"
                  maxLength={7}
                  {...form.register('licensePlate')}
                />
                <Button
                  buttonTitle="Pesquisar"
                  type="primary"
                  width="108px"
                  htmlType="submit"
                />
                <Button
                  buttonTitle="Nova viatura"
                  type="secondary"
                  icon={PlusIcon}
                  width="145px"
                  onClick={() => navigate(`/config/vehicle/new`)}
                />
              </div>
            </div>
          </form>
          <Table
            innerRef={tableRef}
            columns={columns}
            fetcher={fetchVehicles}
            resetPagination={resetPagination}
            paginated
            actions={[
              {
                label: 'Visualizar',
                Icon: InspectIcon,
                handler: (item) => {
                  setSelectedVehicle(item)
                  vehicleDetailsComponent.show()
                },
              },
              {
                label: 'Editar',
                Icon: EditIcon,
                handler: (item) => navigate(`/config/vehicle/edit/${item.id}`),
              },
              {
                label: 'Excluir',
                Icon: TrashIcon,
                handler: (item) => {
                  setSelectedVehicle(item)
                  vehicleDeleteComponent.show()
                },
              },
            ]}
            emptyState={emptyStateProps}
          />
        </div>
      </FormProvider>
    </>
  )
}
