import { Input } from 'components'
import { ReactComponent as IconPaint } from 'assets/svg/paint.svg'
import styles from './ColorPicker.module.scss'

type ColorPickerProps = {
  onSelectValue: (color: string) => void
  value?: string
}

export const ColorPicker = ({ value, onSelectValue }: ColorPickerProps) => {
  return (
    <div className={styles.container}>
      <Input
        label="Cor"
        placeholder="#000000"
        value={value}
        className={styles.inputHex}
      />
      <div className={styles.inputColor}>
        <input
          type="color"
          className={styles.inputColor}
          onChange={(color) => onSelectValue(color.target.value)}
        />
        <IconPaint />
      </div>
    </div>
  )
}
