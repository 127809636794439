import { ChangeEvent, FC, useMemo, useState, useRef } from 'react'

import styles from './styles.module.scss'
import { maskedDateTimeHour } from 'utilities/date'
import { CamState } from '../../types'

type PlayerTimelineProps = {
  handleSeek: (e: ChangeEvent<HTMLInputElement>) => void
  duration: number
  currentTime: number
  occurrenceCreatedAt: number
  camState: CamState
}

const PlayerTimeline: FC<PlayerTimelineProps> = ({
  handleSeek,
  duration,
  currentTime,
  occurrenceCreatedAt,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const formatTime = (timeInSeconds: number) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) {
      return '00:00:00'
    }

    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
    const seconds = Math.floor(timeInSeconds % 60)

    const pad = (num: number) => (num < 10 ? '0' + num : num)

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
  }

  const initialHour = useMemo(() => {
    const currentTimeMillis = new Date().getTime()
    const videoDurationMillis = duration * 1000
    const startTimeMillis = currentTimeMillis - videoDurationMillis

    return maskedDateTimeHour(startTimeMillis)
  }, [duration])

  const currentHour = useMemo(() => {
    return formatTime(currentTime)
  }, [currentTime])

  const occurrenceInSeconds = useMemo(() => {
    const occurrenceInMillis = new Date(occurrenceCreatedAt).getTime()
    const videoStartTime = new Date().getTime() - duration * 1000

    const occurrenceTimeFromStartInSeconds =
      (occurrenceInMillis - videoStartTime) / 1000

    return occurrenceTimeFromStartInSeconds
  }, [occurrenceCreatedAt, duration])

  const occurrenceMarkerPosition = useMemo(() => {
    if (
      duration > 0 &&
      occurrenceInSeconds >= 0 &&
      occurrenceInSeconds <= duration
    ) {
      return (occurrenceInSeconds / duration) * 100
    }
    return 0
  }, [occurrenceInSeconds, duration])

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging && inputRef.current) {
      const boundingRect = inputRef.current.getBoundingClientRect()
      const maxX = boundingRect.width
      const relativeX = Math.min(
        Math.max(0, e.clientX - boundingRect.left),
        maxX,
      )
      const newTime = (relativeX / maxX) * duration

      handleSeek({ target: { value: String(newTime) } })
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseDown = () => {
    setIsDragging(true)
  }

  const handleDoubleClick = () => {
    setIsDragging(true)
  }

  return (
    <div
      className={[styles.container, isDragging && styles.dragging].join(' ')}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseUp}
    >
      <div className={styles.timelineWrapper}>
        <input
          ref={inputRef}
          className={`${styles.timeline} ${isDragging ? styles.dragging : ''}`}
          type="range"
          min={0}
          max={duration}
          value={currentTime}
          onChange={handleSeek}
          onMouseDown={handleMouseDown}
          onDoubleClick={handleDoubleClick}
        />
        {occurrenceMarkerPosition > 0 && (
          <div
            className={styles.occurrenceMarkerWrapper}
            style={{
              left: `calc(${occurrenceMarkerPosition}% + 5px)`,
            }}
          >
            <div className={styles.occurrenceMarkerTime}>
              {maskedDateTimeHour(occurrenceCreatedAt)}
            </div>

            <div className={styles.occurrenceMarker} />
          </div>
        )}
      </div>

      <div className={[styles.timeWrapper, styles.hourWrapper].join(' ')}>
        <span className={styles.initialTime}>{initialHour}</span>
        <span className={styles.currentTime}>{currentHour}</span>
        <span className={styles.finalTime}>Ao vivo</span>
      </div>
    </div>
  )
}

export default PlayerTimeline
