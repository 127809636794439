import { ComponentProps, ReactNode, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import styles from './styles.module.scss'
import { dateNow, formatDecimal } from 'utilities/date'

export interface PaginationProps extends ComponentProps<'div'> {
  totalElements?: number
  recordsPerPage?: number
}

const handleVisiblePageNumbers = (
  totalPages: number,
  maxVisiblePages: number,
  currentPage: number,
) => {
  const calcVisiblePages = Math.floor(maxVisiblePages / 2)

  let lastVisiblePage = maxVisiblePages

  if (currentPage + calcVisiblePages >= totalPages) {
    lastVisiblePage = totalPages
  } else if (currentPage > calcVisiblePages) {
    lastVisiblePage = currentPage + calcVisiblePages
  }

  const firstVisiblePage = lastVisiblePage - maxVisiblePages + 1

  return { firstVisiblePage, lastVisiblePage }
}
export const Pagination = ({
  totalElements = 0,
  recordsPerPage = 15,
  className,
  ...props
}: PaginationProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = Number(searchParams.get('page')) || 1

  const totalPages = Math.ceil(totalElements / recordsPerPage)

  const renderPage = () => {
    const result: ReactNode[] = []
    const maxVisiblePages = totalPages < 5 ? totalPages : 5

    const { firstVisiblePage, lastVisiblePage } = handleVisiblePageNumbers(
      totalPages,
      maxVisiblePages,
      currentPage,
    )

    for (let index = firstVisiblePage; index <= lastVisiblePage; index++) {
      result.push(
        <button
          key={index}
          data-testid="pagination-page"
          onClick={() => handleChangePage(index)}
          className={[
            styles.paginationNumberButton,
            currentPage === index ? styles.paginationActive : '',
          ].join(' ')}
        >
          {index}
        </button>,
      )
    }
    return result
  }

  const handleChangePage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: String(page),
    })
  }

  const paginationInfo = useMemo(() => {
    const from = (currentPage - 1) * recordsPerPage + 1
    const finalIndex = from + recordsPerPage - 1
    const to = finalIndex < totalElements ? finalIndex : totalElements

    return {
      from: formatDecimal(from),
      to: formatDecimal(to),
      total: totalElements.toFixed(0),
    }
  }, [currentPage, totalElements, recordsPerPage])

  return (
    <div className={[styles.container, className].join(' ')} {...props}>
      <div className={styles.pageInfo}>
        <span>
          Mostrando resultados {totalElements ? paginationInfo.from : 0} -{' '}
          {paginationInfo.to} de {paginationInfo.total}
        </span>
        <span>Consulta realizada em {dateNow()}</span>
      </div>
      {!!totalElements && (
        <div className={styles.paginationWrapper}>
          <button
            className={styles.paginationTextButton}
            id="first-button"
            data-testid="first-button"
            onClick={() => handleChangePage(1)}
            disabled={currentPage === 1}
          >
            Primeira
          </button>
          <button
            data-testid={styles.previousButton}
            className={styles.previousButton}
            disabled={currentPage === 1}
            onClick={() => handleChangePage(currentPage - 1)}
          >
            <svg
              className={styles.previousIcon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 5.857 9.919"
            >
              <path
                d="M453.825,748.967a.966.966,0,0,1-.69-.291,1,1,0,0,1,0-1.4l3.215-3.266-3.215-3.266a1,1,0,0,1,0-1.4.965.965,0,0,1,1.38,0l3.9,3.968a1,1,0,0,1,0,1.4l-3.9,3.968A.966.966,0,0,1,453.825,748.967Z"
                transform="translate(-452.849 -739.048)"
              />
            </svg>
          </button>
          {renderPage()}
          <button
            data-testid="next-button"
            className={styles.nextButton}
            disabled={currentPage === totalPages}
            onClick={() => handleChangePage(currentPage + 1)}
          >
            <svg
              className={styles.nextIcon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 5.857 9.919"
            >
              <path
                d="M453.825,748.967a.966.966,0,0,1-.69-.291,1,1,0,0,1,0-1.4l3.215-3.266-3.215-3.266a1,1,0,0,1,0-1.4.965.965,0,0,1,1.38,0l3.9,3.968a1,1,0,0,1,0,1.4l-3.9,3.968A.966.966,0,0,1,453.825,748.967Z"
                transform="translate(-452.849 -739.048)"
              />
            </svg>
          </button>
          <button
            className={styles.paginationTextButton}
            id="last-button"
            onClick={() => handleChangePage(totalPages)}
            disabled={currentPage === totalPages}
          >
            Última
          </button>
        </div>
      )}
    </div>
  )
}

export default Pagination
