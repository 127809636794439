const MAX_LENGTH_MAC_IMEI = 45
const MAX_LENGTH_MAC = 12
const MAX_LENGTH_MAC_WITH_MASK = 17

export function formatMACORIMEI(text: string): string {
  const cleanedText = text.replace(/[^a-fA-F0-9]/g, '')

  if (cleanedText.length > 1 && cleanedText.length <= 12) {
    let formattedMac = ''
    for (let i = 0; i < cleanedText.length; i++) {
      if (i > 0 && i % 2 === 0 && i < MAX_LENGTH_MAC) {
        formattedMac += ':'
      }
      formattedMac += cleanedText[i]
    }
    return formattedMac.slice(0, MAX_LENGTH_MAC_WITH_MASK)
  }

  return cleanedText.slice(0, MAX_LENGTH_MAC_IMEI)
}
