import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import HttpClient from '../httpClient'
import {
  MarkerType,
  Tactical,
  QuickAlertResponse,
  FetchTacticals,
  StartDutyParams,
  updateVehicleRequest,
  OccurrenceDetails,
  Intervention,
  QuickAlertPayload,
  AgentDuty,
  OccurrenceResponse,
  TacticalDetails,
  AgentDutyRecords,
  AgentDutyRecordsPayload,
  QueryVehicleRequest,
} from './types'
import { UserQuery, UserQueryResult } from 'services/auth'
import { Result } from 'services/types'
import { VehicleAggregatedQueryResponse } from 'services/vehicle'
import { endpoints } from './endpoints'
import buildUrl from 'utilities/buildUrl'
import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { AxiosResponse } from 'axios'
import { PauseTacticalDuty } from 'domains/occurrence/screens/DisplacementMap/containers/ModalsContainer/components/PauseDuty/PauseDuty'
import { ResumeDutyTactical } from 'domains/occurrence/screens/DisplacementMap/containers/ModalsContainer/components/ResumeDuty/ResumeDuty'
import { useUserInfo } from 'shared/hooks'

const fetchQuickAlerts = async (userId: string | null) => {
  const response = await HttpClient.get<{
    data: QuickAlertResponse[]
    totalElements: number
  }>('/occurrence/quickAlert/getByUserCoverage', {
    params: { userId },
  })

  return response.data
}

const useGetOccurrence = (occurrenceId?: string) =>
  useQuery({
    queryKey: ['get-occurrence', occurrenceId],
    queryFn: async () => {
      if (occurrenceId) {
        const response = await HttpClient.get<OccurrenceDetails>(
          `/occurrence/${occurrenceId}/details`,
        )

        return response.data
      }
    },
    refetchInterval: 10000,
    enabled: !!occurrenceId,
  })

const useGetOccurrencesByUserCoverageType = (userId: string | null) =>
  useQuery({
    queryKey: ['get-occurrence-coverage'],
    queryFn: async () => {
      const query = {
        userId,
        coverageTypes: [
          'TACTICAL_DISPLACEMENT_OPERATOR',
          'OCCURRENCE_ATTENDANT',
          'OCCURRENCE_MANAGEMENT',
        ].join(','),
        stateNames: [
          'TRAVEL_WAITING',
          'TRAVEL_SENT',
          'TRAVEL_VIEWED',
          'TRAVEL_STARTED',
          'TRAVEL_FINISHED',
        ].join(','),
      }

      if (userId) {
        const response = await HttpClient.get<{ data: OccurrenceResponse[] }>(
          `/occurrence/getByUserCoverageForMap`,
          {
            params: query,
          },
        )

        return response.data.data
      }
    },

    refetchInterval: 10000,
    enabled: !!userId,
  })

const useCreateInterventation = (occurrenceId?: string | null) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['create-interventation', occurrenceId],
    mutationFn: async (intervention: Intervention) => {
      if (occurrenceId) {
        return HttpClient.post(
          `/occurrence/${occurrenceId}/intervention`,
          intervention,
        )
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-occurrence', occurrenceId],
      })
      queryClient.invalidateQueries({ queryKey: ['get-occurrence-coverage'] })
    },
  })
}

const useGetAgentDutyRecords = (agentDutyId?: string) =>
  useQuery({
    queryKey: ['get-agent-duty-records', agentDutyId],
    queryFn: async () => {
      if (agentDutyId) {
        const response = await HttpClient.get<AgentDutyRecords[]>(
          `/agentDuty/${agentDutyId}/records`,
        )

        return response.data
      }
    },
    enabled: !!agentDutyId,
  })

const useCreateAgentDutyRecords = (agentDutyId?: string | undefined) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['create-agent-duty-records'],
    mutationFn: async (payload: AgentDutyRecordsPayload) => {
      if (agentDutyId) {
        const response = await HttpClient.post<AgentDutyRecordsPayload>(
          `/agentDuty/${agentDutyId}/records`,
          [payload],
        )

        return response.data
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-agent-duty-records', agentDutyId],
      })
    },
  })
}

const useFetchQuickAlerts = (userId: string | null) =>
  useQuery({
    queryKey: ['fetch-quick-alerts'],
    queryFn: async () => {
      if (userId) {
        const response = await fetchQuickAlerts(userId)

        return response.data
      }
    },
    enabled: !!userId,

    refetchInterval: 10000,
  })

const useUpdateQuickAlert = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['update-quick-alert'],
    mutationFn: async ({ userId, id }: QuickAlertPayload) => {
      const response = await HttpClient.put<QuickAlertResponse>(
        `/occurrence/quickAlert/${id}`,
        { userId },
      )

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetch-quick-alerts'] })
    },
  })
}

const useCloseQuickAlert = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['close-quick-alert'],
    mutationFn: async (quickAlertId: string) => {
      const response = await HttpClient.post<FetchTacticals>(
        `/occurrence/quickAlert/${quickAlertId}/finish`,
      )

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetch-quick-alerts'] })
    },
  })
}

const useGetTacticalDetails = (tacticalId = '') =>
  useQuery({
    queryKey: ['get-tactical', tacticalId],
    queryFn: async () => {
      const response = await HttpClient.get<TacticalDetails>(
        `/agentDuty/${tacticalId}/details`,
      )

      return response.data
    },
    enabled: !!tacticalId,
    refetchInterval: 10000,
  })

export const fetchTacticals = async (userId: string) => {
  const response = await HttpClient.get<{
    data: AgentDuty[]
    totalElements: number
  }>(`/agentDuty/getByUserCoverage`, { params: { userId } })

  return response.data
}

const useFetchTacticals = (
  userId: string | null,
  refetchInterval?: number,
  enabled?: boolean,
) =>
  useQuery({
    queryKey: ['fetch-tactical'],
    queryFn: async () => {
      const response = await HttpClient.get<{
        data: AgentDuty[]
        totalElements: number
      }>(`/agentDuty/getByUserCoverage`, { params: { userId } })

      return response.data.data
    },

    refetchInterval,
    enabled,
  })

const useFetchServiceProfiles = () =>
  useQuery({
    queryKey: ['fetch-service-profile'],
    queryFn: async () => {
      const response = await HttpClient.get<{ name: string }[]>(
        '/fetch-service-profile',
      )

      return response.data.map((item) => item.name)
    },
  })

const useInfiniteFetchUsers = (filters?: UserQuery) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['fetch-users', filters],
    initialPageParam: 0,
    queryFn: async ({ pageParam }) => {
      const response: AxiosResponse<Result<UserQueryResult>> =
        await HttpClient.get('/auth/user/query', {
          params: {
            coverageType: 'TACTICAL_AGENT',
            recordsPerPage: 15,
            offset: pageParam,
            ...filters,
          },
        })
      return response.data
    },
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<UserQueryResult>(lastPage, allPages),
    refetchOnWindowFocus: false,
  })

  return { ...infiniteQuery, data: flattenPages(infiniteQuery.data) }
}

const useFetchCars = (params?: QueryVehicleRequest) =>
  useQuery({
    queryKey: ['fetch-cars', params],
    queryFn: async () => {
      const response = await HttpClient.get<
        Result<VehicleAggregatedQueryResponse>
      >('/vehicle/query', { params })

      return response.data
    },
  })

const useStartTacticalDuty = () =>
  useMutation({
    mutationKey: ['start-tactical-duty'],
    mutationFn: async (payload: StartDutyParams) => {
      const response = await HttpClient.post<Tactical & MarkerType>(
        `/agentDuty/${payload.userId}/start`,
        {
          vehicleId: payload.vehicleId,
          allowMultipleLinks: payload.allowMultipleLinks,
          allowOccurrenceDiscovery: payload.allowOccurrenceDiscovery,
        },
      )

      return response.data
    },
  })

const usePauseTacticalDuty = (agentDutyId?: string) => {
  const { userInfo } = useUserInfo()

  return useMutation({
    mutationKey: ['pause-tactical-duty'],
    mutationFn: async (payload: PauseTacticalDuty) => {
      const userId = userInfo.id

      if (agentDutyId && userId) {
        const response = await HttpClient.post<PauseTacticalDuty>(
          buildUrl({
            route: endpoints.pause,
            params: { agentDutyId },
          }),
          { note: payload.note, userId },
        )
        return response.data
      }
    },
  })
}

const useResumeTacticalDuty = (agentDutyId?: string) =>
  useMutation({
    mutationKey: ['resume-tactical-duty'],
    mutationFn: async (payload: ResumeDutyTactical) => {
      if (agentDutyId) {
        const response = await HttpClient.post<ResumeDutyTactical>(
          buildUrl({
            route: endpoints.resume,
            params: { agentDutyId },
          }),
          payload,
        )
        return response.data
      }
    },
  })

const useTerminaDuty = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['terminate-duty'],
    mutationFn: async (tacticalId?: string) => {
      if (tacticalId) {
        const response = await HttpClient.post<{ tactical: string }>(
          `/agentDuty/${tacticalId}/finish`,
        )

        return response.data
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get-occurrence-coverage'] })
      queryClient.invalidateQueries({ queryKey: ['fetch-tactical'] })
    },
  })
}

const useUpdateVehicle = (agentDutyId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['terminate-duty'],
    mutationFn: async ({ vehicleId }: updateVehicleRequest) => {
      const response = await HttpClient.post<{ tactical: FetchTacticals }>(
        `/agentDuty/${agentDutyId}/updateVehicle`,
        { vehicleId },
      )

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetch-tactical'] })
      queryClient.invalidateQueries({ queryKey: ['fetch-cars'] })
      queryClient.invalidateQueries({ queryKey: ['get-tactical', agentDutyId] })
    },
  })
}

export {
  fetchQuickAlerts,
  useUpdateQuickAlert,
  useCloseQuickAlert,
  useFetchCars,
  useUpdateVehicle,
  useTerminaDuty,
  useGetOccurrence,
  useGetTacticalDetails,
  useFetchTacticals,
  useStartTacticalDuty,
  useGetAgentDutyRecords,
  useCreateAgentDutyRecords,
  useFetchQuickAlerts,
  useFetchServiceProfiles,
  useCreateInterventation,
  useGetOccurrencesByUserCoverageType,
  useInfiniteFetchUsers,
  usePauseTacticalDuty,
  useResumeTacticalDuty,
}
