import { useCallback } from 'react'

import {
  ProtectedImage,
  ErrorState,
  ButtonV2,
  Icon,
  Info,
  Tag,
} from 'components'
import { formatPhone } from 'utilities/masks'
import { usePersistentTab } from 'domains/occurrence/hooks'
import { CustomerPaths } from 'routes/constants/paths/customer'
import { handleRenderState } from 'utilities/handleRenderState'
import { contactTypes, useGetAccount } from 'services/serviceOrderV2'

import Loading from './Loading'
import EmptyState from './EmptyState'
import styles from './styles.module.scss'

type CustomerDetailsProps = {
  accountId?: string
}

const CustomerDetails = ({ accountId }: CustomerDetailsProps) => {
  const { replaceUrl } = usePersistentTab('view-service-orders')
  const { data, isLoading, isError } = useGetAccount(accountId)

  const handleSeeMoreAccount = useCallback(() => {
    localStorage.setItem('accountId', data?.account.id || '')
    localStorage.setItem('patrimonyId', data?.patrimony.id || '')

    replaceUrl(`${CustomerPaths.Account}`)
  }, [replaceUrl, data])

  return (
    <>
      {
        {
          view: !!data && (
            <div className={styles.customer}>
              {data?.patrimony.image ? (
                <ProtectedImage imageId={data.patrimony.image} height={160} />
              ) : (
                <div className={styles.emptyImage}>
                  <Icon
                    name="photo-off"
                    width={48}
                    color="element-extralight"
                  />
                </div>
              )}
              <div className={styles.content}>
                <section>
                  <h3>Cliente</h3>
                  <Info.Root>
                    <Info.Title>
                      Conta
                      <ButtonV2
                        size="md"
                        appearance="tertiary"
                        onClick={handleSeeMoreAccount}
                      >
                        <Icon name="open" />
                        Ver Conta
                      </ButtonV2>
                    </Info.Title>
                    <Info.Content>
                      {`${data.account.code} - ${data.account.name}`}
                      {data.account.serviceType?.name && (
                        <Tag color="blue">{data.account.serviceType.name}</Tag>
                      )}
                    </Info.Content>
                  </Info.Root>

                  <Info.Root>
                    <Info.Title>Patrimônio</Info.Title>
                    <Info.Content>{data.patrimony.name}</Info.Content>
                  </Info.Root>

                  <Info.Root>
                    <Info.Title>Endereço</Info.Title>
                    <Info.Content>
                      {`${data.patrimony.address?.address}, ${data.patrimony.address?.number}, ${data.patrimony.address?.district}, ${data.patrimony.address?.city}/${data.patrimony.address?.acronym}`}
                    </Info.Content>
                  </Info.Root>
                </section>
                {!!data.contacts.length && (
                  <section>
                    <h3>Contatos</h3>
                    <ul className={styles.contacts}>
                      {data.contacts.map((contact) => {
                        return (
                          <li key={contact.id}>
                            <div>
                              <span>{contact.name}</span>
                              <span>
                                {contactTypes[contact?.type]}
                                {contact.hasRemoteControl && (
                                  <Icon name="control" width={10} />
                                )}
                              </span>
                            </div>
                            <span className={styles.phone}>
                              <Icon name="phone" />
                              {formatPhone(
                                contact.phone?.type,
                                `${contact.phone?.provinceCode}${contact.phone?.number}`,
                              )}
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  </section>
                )}
              </div>
            </div>
          ),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyState />,
        }[
          handleRenderState({
            data,
            isError,
            isLoading,
            isEmpty: !data || !accountId,
          })
        ]
      }
    </>
  )
}

export default CustomerDetails
