import { formatDecimal } from '../date'

export const isValidDate = (value: string): boolean => {
  const dateRegex =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  if (value.length > 9) {
    if (dateRegex.test(value)) {
      return true
    } else {
      return false
    }
  }
  return true
}

export const convertStringToDate = (value: string): Date => {
  const day = value.slice(0, 2)
  const month = value.slice(3, 5)
  const year = value.slice(6, 10)
  return new Date(`${year}/${month}/${day}`)
}

export const convertDateToString = (value: Date): string => {
  const day = value.getDate()
  const month = value.getMonth() + 1
  const year = value.getFullYear()
  return `${formatDecimal(day)}/${formatDecimal(month)}/${year}`
}
