import Joi from '@hapi/joi'

import { AggregatedAccount } from 'services/account/types'
import {
  OccurrenceTypeName,
  OccurrenceTypeQueryResponse,
} from 'services/occurrence/types'
import { accountSchema } from 'shared/schemas'
import { occurrenceTypeSchema } from 'shared/schemas/occurrence/occurrenceSchema'

export const operatorSchema = Joi.object<AggregatedAccount>({
  id: Joi.string().uuid(),
  aggregatedAccountName: Joi.string(),
})

export type OccurrenceFilters = {
  number?: string
  account?: Pick<AggregatedAccount, 'id' | 'aggregatedAccountName'>
  occurrenceType?: OccurrenceTypeQueryResponse & {
    originName: OccurrenceTypeName
  }
}

export const occurrenceQuerySchema = Joi.object<OccurrenceFilters>({
  number: Joi.number().optional().allow('', null),
  account: accountSchema.optional(),
  occurrenceType: occurrenceTypeSchema.optional(),
})
