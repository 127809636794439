import React, { useCallback } from 'react'
import { Button, Modal } from '../../../../components/index'

import { useToast } from '../../../../shared/hooks'
import { useRemovePatrol } from 'services/patrol'

export interface DeletePatrolProps {
  isVisible: boolean
  onClose(): void
  patrolId: string
}

const DeletePatrolModal: React.FC<DeletePatrolProps> = (
  props: DeletePatrolProps,
) => {
  const { isVisible, onClose, patrolId } = props
  const { addToast } = useToast()

  const { mutate: deletePatrol } = useRemovePatrol()

  const handleDeletePatrol = useCallback(() => {
    deletePatrol(patrolId, {
      onSuccess: () => {
        addToast({
          type: 'success',
          message: 'Ocorrência periódica removida com sucesso.',
        })
        onClose()
      },
      onError: () => {
        addToast({
          type: 'alert',
          message: 'Erro ao remover ocorrência periódica. Tente novamente.',
        })
        onClose()
      },
    })
  }, [deletePatrol, patrolId, addToast, onClose])

  return (
    <Modal
      simple
      title={'Exclusão de ocorrência periódica'}
      isVisible={isVisible}
      onClose={onClose}
    >
      <p>Tem certeza que deseja excluir esta ocorrência periódica?</p>
      <Modal.Footer>
        <Button
          width="172px"
          buttonTitle="Excluir"
          id="delete-button"
          type="cancel-primary"
          onClick={() => handleDeletePatrol()}
        />
        <Button
          width="172px"
          buttonTitle="Cancelar"
          id="cancel-delete-button"
          type="secondary"
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default DeletePatrolModal
