import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { useInfiniteQuery } from '@tanstack/react-query'

import { Result } from 'services/types'
import { ManufacturerOutput, ManufacturerQuery } from './types'
import HttpClient from '../httpClient'

const getManufacturers = async (
  offset = 0,
  query?: ManufacturerQuery,
  filter?: { name?: string },
) => {
  const response = await HttpClient.get<Result<ManufacturerOutput>>(
    '/manufacturer/query',
    {
      params: {
        recordsPerPage: 15,
        offset,
        ...(filter && filter),
        ...query,
      },
    },
  )
  return response.data
}

export function useInfiniteManufacturers(
  filter?: { name?: string },
  query?: ManufacturerQuery,
) {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['manufacturers', filter],
    queryFn: ({ pageParam }) => getManufacturers(pageParam, query, filter),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<ManufacturerOutput>(lastPage, allPages),
  })

  return { ...infiniteQuery, data: flattenPages(infiniteQuery.data) }
}
