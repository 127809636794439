import { useLayoutEffect, useMemo, useState } from 'react'

import { ReactComponent as Pen } from 'assets/svg/listEditIcon.svg'
import { ReactComponent as Trash } from 'assets/svg/trash.svg'
import { ReactComponent as Option } from 'assets/svg/option.svg'
import { ReactComponent as Arrow } from 'assets/svg/arrow.svg'

import { ReactComponent as PlusSignIcon } from 'assets/svg/plusSign.svg'

import { Coverage } from 'domains/attendancePolicy/types'

import { Button } from 'components'
import { Droplist } from 'components/Droplist/Droplist'

import { CoverageGrouper } from 'services/attendancePolicy/types'
import { CoverageType } from 'domains/attendancePolicy/components/CoverageForm/utilities/coverages'

import styles from './CoverageList.module.scss'
import { CoverageItem } from 'domains/attendancePolicy/components/CoverageList/CoverageItem/CoverageItem'

interface CoverageListProps {
  coverageGrouper?: CoverageGrouper
  onAddNewCoverage?: () => void
  onDeleteAll?: () => void
  onDelete?: (coverage: Coverage) => void
  onInspectAccount?: (coverage: Coverage) => void
}

export const CoverageList = (props: CoverageListProps) => {
  const {
    onAddNewCoverage,
    coverageGrouper,
    onDeleteAll,
    onDelete,
    onInspectAccount,
  } = props

  const [coverages, setCoverages] = useState<Coverage[]>([])

  const [open, setOpen] = useState(true)

  const hasItems = useMemo(() => coverages.length > 0, [coverages])

  useLayoutEffect(() => {
    if (coverageGrouper) {
      const normalizedCustomer: Coverage[] = coverageGrouper.customers.map(
        (customer) => ({
          id: customer.id,
          name: customer.name || '',
          type: CoverageType.Customer,
          precedence: {
            customer: {
              id: customer.id,
              name: customer.name || '',
            },
          },
        }),
      )

      const normalizedAccountsCoverage: Coverage[] =
        coverageGrouper?.accounts.map((account) => ({
          id: account.id,
          name: account.aggregatedAccountName || '',
          type: CoverageType.Account,
          precedence: {
            customer: {
              id: account.customer?.id,
              name: account.customer?.name,
            },
            patrimony: {
              id: account.patrimony?.id,
              name: account.patrimony?.name,
            },
            account: {
              id: account.id,
              name: account.aggregatedAccountName,
            },
          },
        }))

      setCoverages([...normalizedCustomer, ...normalizedAccountsCoverage])
    }
  }, [coverageGrouper])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.listHead}>
          <div
            onClick={() => {
              setOpen(!open)
            }}
            className={`${styles.text} ${hasItems ? styles.titleArrow : ''}`}
          >
            <h1>Abrangências</h1>

            {hasItems && (
              <>
                {!open && <div className={styles.tags}>{coverages.length}</div>}

                <Arrow
                  className={[styles.arrow, open && styles.upsideArrow].join(
                    ' ',
                  )}
                  data-testid="arrow-icon"
                />
              </>
            )}
          </div>
          {hasItems && onAddNewCoverage ? (
            <div>
              <Droplist
                trigger={
                  <div className={styles.options}>
                    <Option />
                  </div>
                }
              >
                <button
                  className={styles.dropListItem}
                  onClick={onAddNewCoverage}
                >
                  <Pen />
                  Editar
                </button>

                <button className={styles.dropListItem} onClick={onDeleteAll}>
                  <Trash />
                  Excluir tudo
                </button>
              </Droplist>
            </div>
          ) : null}
          {!hasItems && Boolean(onAddNewCoverage) ? (
            <Button
              buttonTitle="Adicionar abrangência"
              type="tertiary"
              icon={PlusSignIcon}
              onClick={onAddNewCoverage}
            />
          ) : null}
        </div>

        {hasItems && open
          ? coverages.map((coverage) => (
              <CoverageItem
                key={coverage.id}
                coverage={coverage}
                onAddNewCoverage={onAddNewCoverage}
                onDelete={onDelete}
                onInspectAccount={onInspectAccount}
              />
            ))
          : null}
      </div>
    </>
  )
}
