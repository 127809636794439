import Axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import buildUrl from '../utils/buildUrl'
import { endpoints } from './endpoints'

interface Call {
  phoneNumber: string
  phoneExtension: string
}

const handleCall = async ({ phoneNumber, phoneExtension }: Call) => {
  const queryParams = {
    ramalDestino: phoneNumber,
    ramaOrigem: phoneExtension,
  }

  try {
    await Axios.get(buildUrl({ route: endpoints.call, queryParams }))
  } catch (error) {
    console.error('Unable to call', error)
  }
}

export const useMakeCall = () => {
  return useMutation({
    mutationFn: async (data: Call) => await handleCall(data),
  })
}
