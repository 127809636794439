import { useCallback, useEffect } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { Button, Input, Loader, Modal } from 'components'
import { usePostAndHandleWifiConfigurationCommand } from '../../hooks/services/commands'
import { useForm } from 'react-hook-form'
import { WifiConfigurationCommandPayload } from 'services/command/types'
import { ConnectionIPMode } from 'services/serviceOrder'

import styles from './CentralWifiConfiguration.module.scss'
import { centralWifiConfigurationSchema } from 'domains/customer/screens/Equipments/schemas/central/wifiSchema'

type CentralWifiConfigurationProps = {
  isVisible: boolean
  onClose: () => void
  central: {
    id: string
    wifiIpMode: ConnectionIPMode
  }
}

export const CentralWifiConfiguration = ({
  isVisible,
  onClose,
  central,
}: CentralWifiConfigurationProps) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    formState,
  } = useForm<WifiConfigurationCommandPayload>({
    resolver: joiResolver(centralWifiConfigurationSchema),
    mode: 'onChange',
    defaultValues: {
      wifiIpMode:
        central.wifiIpMode === ConnectionIPMode.Estático
          ? ConnectionIPMode.Estático
          : ConnectionIPMode.DHCP,
      wifiSsid: '',
      wifiPassword: '',
    },
  })

  const {
    mutateWifiConfigurationCommand,
    wifiConfigurationCommandMutationStatus,
  } = usePostAndHandleWifiConfigurationCommand(central.id, getValues())

  const onSave = useCallback(() => {
    mutateWifiConfigurationCommand()
    onClose()
  }, [mutateWifiConfigurationCommand, onClose])

  useEffect(() => {
    register('wifiIpMode')
  }, [register])

  return (
    <>
      <div>
        <Loader
          isVisible={wifiConfigurationCommandMutationStatus === 'pending'}
        />
        <Modal
          title="Alterar Wi-Fi"
          isVisible={isVisible}
          size="sm"
          onClose={onClose}
        >
          <form className={styles.inputsWrapper}>
            <Input
              id="wifiSsid"
              label="Nome da Rede Wi-Fi"
              {...register('wifiSsid')}
              autoComplete="off"
              errorMessage={errors.wifiSsid?.message}
            />

            <Input
              id="wifiPassword"
              label="Senha"
              {...register('wifiPassword')}
              autoComplete="off"
              password
              errorMessage={errors.wifiPassword?.message}
            />
          </form>

          <Modal.Footer>
            <Button
              width="172px"
              buttonTitle="Voltar"
              type="secondary"
              onClick={onClose}
            />
            <Button
              disabled={!formState.isDirty || !formState.isValid}
              width="172px"
              buttonTitle="Salvar"
              type="primary"
              onClick={handleSubmit(onSave)}
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
