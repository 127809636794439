import { useEffect, useMemo } from 'react'
import {
  Button,
  Combobox,
  ComboboxItem,
  Loader,
  Modal,
  ProtectedButton,
} from 'components'
import { usePatchAndHandleUpdateDevice } from 'domains/customer/screens/Equipments/hooks/services/device'
import {
  updateRemoteControlContactSchema,
  UpdateRemoteControlContactSchema,
} from 'domains/customer/screens/Equipments/schemas/devices/updateRemoteControlContact'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useGetContacts } from 'shared/hooks/services/contact'
import { ContactQueryResponse } from 'services/contact/types'
import { UbideskPermissions } from 'routes/types'

type RemoteControlContactProps = {
  isVisible: boolean
  centralId: string
  remoteControl: {
    id: string
    partitionId: string
    contact?: {
      id: string
      name: string
    }
  }
  onClose: () => void
}

export const RemoteControlContact = ({
  isVisible,
  centralId,
  remoteControl,
  onClose,
}: RemoteControlContactProps) => {
  const { setValue, register, handleSubmit, formState } =
    useForm<UpdateRemoteControlContactSchema>({
      mode: 'onChange',
      resolver: joiResolver(updateRemoteControlContactSchema),
    })

  const { mutateUpdateDevice, updateDeviceMutationStatus } =
    usePatchAndHandleUpdateDevice({
      success: 'Contato alterado com sucesso.',
      error: 'Erro ao alterar contato. Tente novamente.',
    })

  const { contacts, isFetchingContacts } = useGetContacts(
    {
      customerId: localStorage.getItem('customerId') || '',
      partitionId: remoteControl.partitionId,
    },
    isVisible,
  )

  const comboboxContacts = useMemo(() => {
    if (!contacts) return []

    const formattedContacts: ComboboxItem<ContactQueryResponse>[] =
      contacts.map((contact) => ({
        label: 'name',
        value: contact,
      }))

    return formattedContacts
  }, [contacts])

  const remoteControlContact = useMemo(() => {
    if (!comboboxContacts.length) return undefined
    if (!remoteControl.contact) return undefined

    const contact = comboboxContacts.find(
      (contact) => contact.value.id === remoteControl.contact?.id,
    )
    return contact
  }, [comboboxContacts, remoteControl.contact])

  const handleGetSelectedContact = (
    selected:
      | string
      | ComboboxItem<ContactQueryResponse>
      | (string | ComboboxItem<ContactQueryResponse>)[]
      | undefined,
  ) => {
    const contact = selected as ComboboxItem<ContactQueryResponse>

    if (!contact) return

    setValue('contactId', contact.value.id, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const onSubmit = (form: UpdateRemoteControlContactSchema) => {
    mutateUpdateDevice(
      {
        centralId,
        device: {
          id: remoteControl.id,
          contactId: form.contactId,
        },
      },
      {
        onSuccess: () => onClose(),
      },
    )
  }

  useEffect(() => {
    register('contactId')
  }, [register])

  return (
    <div>
      <Loader
        isVisible={
          isFetchingContacts || updateDeviceMutationStatus === 'pending'
        }
      />
      <Modal title="Alterar contato" isVisible={isVisible} onClose={onClose}>
        <Combobox
          label={'Contato'}
          value={remoteControlContact}
          items={comboboxContacts}
          getSelected={handleGetSelectedContact}
        />
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Voltar"
            type="secondary"
            onClick={onClose}
          />
          <ProtectedButton
            permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isDirty || !formState.isValid}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
