import { NewAttendancePolicyCard } from 'domains/attendancePolicy/components/NewAttendancePolicyCard/NewAttendancePolicyCard'

import { ReactComponent as Trigger } from 'domains/attendancePolicy/assets/svg/trigger.svg'
import { ReactComponent as Condition } from 'domains/attendancePolicy/assets/svg/condition.svg'
import { ReactComponent as Action } from 'domains/attendancePolicy/assets/svg/action.svg'

import { useFormContext } from 'react-hook-form'
import { AggregatedRule } from 'services/attendancePolicy/types'
import { AutomationFormKeys } from 'domains/attendancePolicy/components/RuleForm/types'

import styles from './AutomationMenuOptions.module.scss'

interface AutomationMenuOptions {
  onSelect: (automationOption: AutomationFormKeys) => void
}

export const AutomationMenuOptions = ({ onSelect }: AutomationMenuOptions) => {
  const { setValue: setRuleForm } = useFormContext<AggregatedRule>()

  return (
    <div>
      <div className={styles.titleWrapper}>
        <h1>Nova automação</h1>
        <p>
          Automatize fluxos de ocorrências utilizando um conjunto de instruções
          que especificam as condições e ações que devem ser executadas a partir
          de um determinado evento
        </p>
      </div>

      <div className={styles.cardsWrapper}>
        <NewAttendancePolicyCard
          Icon={Trigger}
          text="Evento único que tem por objetivo iniciar a execução de uma automação"
          title="Gatilho"
          button={{
            text: 'Novo gatilho',
            onClick: () => {
              setRuleForm('trigger', null)

              onSelect('TRIGGER')
            },
          }}
        />

        <NewAttendancePolicyCard
          Icon={Condition}
          text="Critério que precisa ser atendido para executar uma determinada ação"
          title="Condição (opcional)"
          button={{
            text: 'Nova condição ou grupo',
            onClick: () => {
              setRuleForm('branches', [])

              onSelect('CONDITION')
            },
          }}
        />

        <NewAttendancePolicyCard
          Icon={Action}
          text="Realiza modificações em uma ocorrência após a execução de um gatilho"
          title="Ação"
          button={{
            text: 'Nova ação ou grupo',
            onClick: () => {
              setRuleForm('actions', [])

              onSelect('ACTION')
            },
          }}
        />
      </div>
    </div>
  )
}
