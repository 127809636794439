import { useQuery } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'
import { Result } from 'services/types'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { ImageResponse, Query } from './types'

interface UseGetImageProps {
  query: Query
  enabled?: boolean
}

export const useGetImage = ({ query, enabled }: UseGetImageProps) => {
  return useQuery({
    queryKey: ['get-image', query],
    queryFn: async () => {
      const response = await HttpClient.get<Result<ImageResponse>>(
        buildUrl({ route: endpoints.get, queryParams: query }),
      )

      return response.data
    },
    enabled,
  })
}
