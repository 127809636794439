import React from 'react'
import { Button, Modal } from 'components'

import { AggregatedTechnicianSchedulePause } from 'services/technicianSchedule/types'

import styles from './styles.module.scss'
import { maskedDateTime } from 'utilities/date'

export type ViewDetailsModalProps = {
  data: AggregatedTechnicianSchedulePause
  isVisible: boolean
  onClose: () => void
}

export const ViewDetailsModal: React.FC<ViewDetailsModalProps> = ({
  data,
  isVisible,
  onClose,
}: ViewDetailsModalProps) => {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      size={'md'}
      simple
      title="Informações sobre o bloqueio de agenda"
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <label className={styles.label}>Técnico</label>
            <p className={styles.infoText}>{data.technician.name}</p>
          </div>
          <div className={styles.rowItem}>
            <label className={styles.label}>Motivo</label>
            <p className={styles.infoText}>{data.reason}</p>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowItem}>
            <label className={styles.label}>Início do afastamento</label>
            <p className={styles.infoText}>{maskedDateTime(data.startDate)}</p>
          </div>
          <div className={styles.rowItem}>
            <label className={styles.label}>Retorno do afastamento</label>
            <p className={styles.infoText}>{maskedDateTime(data.startDate)}</p>
          </div>
        </div>

        <div>
          <label className={styles.observationsLabel}>Observações</label>
          <textarea
            rows={8}
            className={styles.textArea}
            value={data.note || ''}
            readOnly
          />
        </div>
      </div>
      <Modal.Footer className={styles.footer}>
        <Button
          buttonTitle="Voltar"
          onClick={onClose}
          type="secondary"
          width="172px"
        />
      </Modal.Footer>
    </Modal>
  )
}
