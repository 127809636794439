import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useUserInfo } from 'shared/hooks'
import { Intervention, useUpdateNote } from 'services/serviceOrderV2'

import Field, { Form } from '../../../Field'
import UserVerification from '../UserVerification'

interface EditProps {
  images?: string[]
  intervention: Intervention
  onCancel: () => void
}

const Edit = ({ images, intervention, onCancel }: EditProps) => {
  const {
    userInfo: { id: userId },
  } = useUserInfo()

  const { serviceOrderId } = useParams()

  const [formValues, setFormValues] = useState<Form | null>(null)
  const note = intervention.notes?.[0]

  const { mutate } = useUpdateNote(
    serviceOrderId,
    intervention.id,
    intervention.notes?.[0]?.id,
  )

  const handleSubmit = (authorizedBy: string) => {
    if (formValues) {
      const tags = formValues.tags?.map(({ name }) => ({
        name,
      }))

      mutate(
        {
          userId,
          authorizedBy,
          updatedBy: userId,
          note: formValues.note,
          hidden: formValues.hidden,
          ...(tags?.length && {
            tags,
          }),
        },
        {
          onSuccess: () => {
            onCancel()
            setFormValues(null)
          },
        },
      )
    }
  }

  return (
    <>
      <Field
        onCancel={onCancel}
        visibility={
          intervention.notes
            ?.slice(1, intervention.notes.length)
            ?.some((note) => !note.hidden)
            ? 'public'
            : undefined
        }
        defaultValues={{
          images,
          tags: note?.tags,
          note: note?.note || intervention.title || '',
          hidden:
            intervention.typeName === 'MANUAL_RECORD' ? !!note?.hidden : false,
        }}
        onSubmit={setFormValues}
      />

      <UserVerification
        isOpen={!!formValues}
        onSubmit={handleSubmit}
        onCancel={() => setFormValues(null)}
      />
    </>
  )
}

export default Edit
