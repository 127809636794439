import * as Joi from '@hapi/joi'

export type UpdateRemoteControlContactSchema = {
  contactId: string
}

export const updateRemoteControlContactSchema =
  Joi.object<UpdateRemoteControlContactSchema>({
    contactId: Joi.string().uuid().required(),
  })
