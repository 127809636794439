import { Evaluation } from 'services/serviceOrder'

export const evaluationLabel: Record<Evaluation, string> = {
  HAS: 'Possui Tag',
  HASNT: 'Não possui tag',
}

export const evaluationEventLabel: Record<Evaluation, string> = {
  HAS: 'Possui eventos do tipo',
  HASNT: 'Não possui eventos do tipo',
}

export const evaluationOSLabel: Record<Evaluation, string> = {
  HAS: 'Possui OS do tipo',
  HASNT: 'Não possui OS do tipo',
}

export const evaluationOption: { label: string; value: Evaluation }[] = [
  { label: 'Possui', value: 'HAS' },
  { label: 'Não possui', value: 'HASNT' },
]
