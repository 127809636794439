import { ServiceOrderLabelQuery, ServiceOrderLabelOutput } from './types'
import HttpClient from '../httpClient'
import { serviceOrderLabelEndpoints } from './endpoints'
import { Result } from 'services/types'
import { useInfiniteQuery } from '@tanstack/react-query'
import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { useMemo } from 'react'

export const fetchServiceOrderLabel = async (query: ServiceOrderLabelQuery) => {
  const response = await HttpClient.get<Result<ServiceOrderLabelOutput>>(
    serviceOrderLabelEndpoints.query,
    { params: query },
  )
  return response.data
}

export const useInfiniteServiceOrderLabel = (
  query: ServiceOrderLabelQuery,
  enabled = true,
) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['serviceOrderLabel', query],
    queryFn: ({ pageParam }) =>
      fetchServiceOrderLabel({
        ...query,
        offset: Number(pageParam) || 0,
        recordsPerPage: 15,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<ServiceOrderLabelOutput>(lastPage, allPages),
    enabled,

    refetchInterval: false,
  })

  const serviceOrderLabels = useMemo(
    () => flattenPages(infiniteQuery?.data),
    [infiniteQuery],
  )

  return { ...infiniteQuery, serviceOrderLabels }
}
