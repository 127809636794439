import { useState, useRef, useCallback } from 'react'

type UseTooltipVisibilityReturn = {
  ref: React.RefObject<HTMLTableCellElement>
  isTooltipVisible: boolean
  handleMouseEnter: () => void
  handleMouseLeave: () => void
}

export const useTooltipVisibility = (): UseTooltipVisibilityReturn => {
  const [isTooltipVisible, setTooltipVisible] = useState(false)
  const ref = useRef<HTMLTableCellElement>(null)

  const handleMouseEnter = useCallback(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
      setTooltipVisible(true)
    }
  }, [])

  const handleMouseLeave = useCallback(() => {
    setTooltipVisible(false)
  }, [])

  return { ref, isTooltipVisible, handleMouseEnter, handleMouseLeave }
}
