export const handleUrlToFile = async (
  url: string,
  filename: string,
  mimeType: string,
) => {
  const response = await fetch(url)
  const blob = await response.blob()

  return new File([blob], filename, { type: mimeType })
}

export const handleUrlsToFile = async (urls: string[]) => {
  const files = await Promise.all(
    urls.map(async (url, index) => {
      const filename = `image-${index + 1}.jpg`
      const mimeType = 'image/jpeg'

      return await handleUrlToFile(url, filename, mimeType)
    }),
  )
  return files
}
