import { FC, useState, ReactNode, useContext, createContext } from 'react'

import { PartitionOnAttendance } from 'services/attendance/types'

interface Provider {
  children: ReactNode
}

export interface Filter {
  partition: PartitionOnAttendance
}

interface FilterContextProps {
  filter: Filter | null
  handleFilter: (data: Filter) => void
  handleClearFilter: () => void
}

const defaultValue: FilterContextProps = {
  filter: null,
  handleFilter: () => null,
  handleClearFilter: () => null,
}

export const FilterContext = createContext<FilterContextProps>(defaultValue)

export function useFilter(): FilterContextProps {
  const context = useContext(FilterContext)

  if (!context)
    throw new Error(
      'You need to use AttendanceFilterContext within a AttendanceFilterProvider',
    )

  return context
}

export const FilterProvider: FC<Provider> = ({ children }) => {
  const [filter, setFilter] = useState<Filter | null>(null)

  const handleFilter = (data: Filter) => {
    setFilter(data)
  }

  const handleClearFilter = () => {
    setFilter(null)
  }

  return (
    <FilterContext.Provider
      value={{
        filter,
        handleFilter,
        handleClearFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
