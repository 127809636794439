import React from 'react'
import { Button, Modal } from 'components'

export type DeleteModalProps = {
  isVisible: boolean
  onClose: () => void
  onSubmit: () => void
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  isVisible,
  onClose,
  onSubmit,
}: DeleteModalProps) => {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      size={'sm'}
      simple
      title={'Excluir bloqueio'}
    >
      <div>
        <p>Você tem certeza que deseja remover este bloqueio de agenda?</p>
      </div>
      <Modal.Footer>
        <Button
          buttonTitle="Cancelar"
          onClick={onClose}
          type="secondary"
          width="172px"
        />
        <Button
          buttonTitle="Excluir"
          onClick={onSubmit}
          type="cancel-primary"
          width="172px"
        />
      </Modal.Footer>
    </Modal>
  )
}
