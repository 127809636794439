import { useQuery } from '@tanstack/react-query'
import {
  ServiceOrderDriver,
  ServiceOrderValidationInfo,
} from 'services/serviceOrder'

const fetchSOInfo = async (id: string) => {
  return await ServiceOrderDriver.getValidationInfo(id)
}

const POOLING_INTERVAL = 10000

export const useGetServiceOrderInfo = (
  id?: string,
  enabled: boolean,
  shouldPooling: boolean,
) => {
  return useQuery<ServiceOrderValidationInfo>({
    queryKey: ['service-order-info', id],
    queryFn: () => fetchSOInfo(id),
    enabled,
    refetchInterval: shouldPooling ? POOLING_INTERVAL : false,
    refetchOnWindowFocus: false,
  })
}
