import { DetectionName } from 'services/image/types'
import { FormattedDetectLabelInstance } from './types'

const getLabel = (count: number, singular: string, plural: string): string =>
  `${count} ${count > 1 ? plural : singular}`

export const getDetectionsLabel = (
  instances: FormattedDetectLabelInstance[],
): string => {
  const detectionCounts = instances.reduce(
    (acc, instance) => {
      if (instance.label === DetectionName.PERSON) acc.personCount++
      if (instance.label === DetectionName.PET) acc.petCount++
      return acc
    },
    { personCount: 0, petCount: 0 },
  )

  const labels = {
    person: getLabel(detectionCounts.personCount, 'pessoa', 'pessoas'),
    pet: getLabel(detectionCounts.petCount, 'pet', 'pets'),
  }

  const result = [
    detectionCounts.personCount && labels.person,
    detectionCounts.petCount && labels.pet,
  ]
    .filter(Boolean)
    .join(' e ')

  return result || ''
}
