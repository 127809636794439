import Joi from '@hapi/joi'
import { City } from 'services/address/city/types'

export const citySchema = Joi.object<City>({
  id: Joi.string().uuid().allow('', null),
  stateId: Joi.string().uuid().allow('', null),
  name: Joi.string().allow('', null),
  policeNumber: Joi.number().allow('', null),
  createdAt: Joi.number().allow('', null),
  updatedAt: Joi.number().allow('', null),
}).unknown(true)
