import { InfiniteData } from '@tanstack/react-query'
import { Result } from 'services/types'

export function flattenPages<T>(inifiteData?: InfiniteData<Result<T>>): T[] {
  if (!inifiteData?.pages?.length) return []

  return inifiteData.pages
    .map((page) => page.data)
    .reduce((acc, cur) => [...acc, ...cur])
}
