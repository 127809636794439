import { Central, DeviceRemoved } from 'services/serviceOrder/types'
import { DeviceUninstalledCard } from 'domains/serviceOrders/components/DeviceUninstalledCard/DeviceUninstalledCard'

import styles from './UninstalledEquipmentsStatus.module.scss'
import { EmptyState } from 'components'
import { DeviceType } from 'services/partition/types'

interface UninstalledEquipmentsStatusProps {
  devicesRemoved?: DeviceRemoved[]
  centralInfo?: Central
}

export const UninstalledEquipmentsStatus = ({
  devicesRemoved,
  centralInfo,
}: UninstalledEquipmentsStatusProps): JSX.Element => {
  const devicesNotRemoved = centralInfo?.partitions.flatMap((partition) =>
    partition.devices.filter(
      (device) =>
        !devicesRemoved?.some(
          (removedDevice) => removedDevice.id === device.id,
        ),
    ),
  )

  return (
    <div className={styles.container}>
      <section className={styles.devicesWrapper}>
        {!!devicesNotRemoved?.length && (
          <>
            <h1>Equipamentos não retirados</h1>
            <div className={styles.devices}>
              {devicesNotRemoved.map((device) => (
                <DeviceUninstalledCard
                  key={device.id}
                  name={
                    device.type === DeviceType.RemoteControl
                      ? device.contact?.name || 'Controle remoto'
                      : device.name
                  }
                  type={device.type}
                  notVinculated={!device.id}
                />
              ))}
            </div>
          </>
        )}
        {devicesRemoved && (
          <>
            <h1>Equipamentos retirados com sucesso</h1>
            <div className={styles.devices}>
              {devicesRemoved.map((device) => (
                <DeviceUninstalledCard
                  key={device.id}
                  name={device.name}
                  type={device.deviceTypeCode}
                  notVinculated={!device.id}
                />
              ))}
            </div>
          </>
        )}
        {!devicesRemoved && !centralInfo && (
          <div className={styles.emptyEquipments}>
            <EmptyState type="EmptyEquipment" />
          </div>
        )}
      </section>
    </div>
  )
}
