import { Skeleton } from 'components'

const Loading = () => {
  return (
    <>
      {Array.from({ length: 8 }).map((_, index) => (
        <Skeleton key={index} height={43} />
      ))}
    </>
  )
}

export default Loading
