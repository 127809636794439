import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
  FC,
} from 'react'

import { ModalData, Modals } from '../types'

interface Provider {
  children: ReactNode
}

interface DisplacementModalsContextProps {
  modal: Modals | null
  data?: ModalData | null
  handleCloseModal: () => void
  handleOpenModal: (type: Modals, data?: ModalData) => void
}

const defaultValue: DisplacementModalsContextProps = {
  modal: null,
  data: null,
  handleOpenModal: () => null,
  handleCloseModal: () => null,
}

export const DisplacementModalsContext =
  createContext<DisplacementModalsContextProps>(defaultValue)

export function useDisplacementModal(): DisplacementModalsContextProps {
  const context = useContext(DisplacementModalsContext)

  if (!context)
    throw new Error(
      'You need to use DisplacementModalsContext within a DisplacementModalsProvider',
    )

  return context
}

export const DisplacementModalsProvider: FC<Provider> = ({ children }) => {
  const [modal, setModal] = useState<Modals | null>(null)
  const [data, setData] = useState<ModalData | null>(null)

  const handleOpenModal = useCallback((modal: Modals, data?: ModalData) => {
    setModal(modal)
    data && setData(data)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModal(null)
    setData(null)
  }, [])

  return (
    <DisplacementModalsContext.Provider
      value={{
        modal,
        data,
        handleOpenModal,
        handleCloseModal,
      }}
    >
      {children}
    </DisplacementModalsContext.Provider>
  )
}
