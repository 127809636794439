import { AxiosInstance, AxiosResponse } from 'axios'
import HttpClient from '../httpClient'

import { Result } from 'services/types'
import {
  CoverageQueryResponse,
  QueryCoverageRequest,
  CoverageInputRequest,
  CoverageTechnician,
  CoverageFindResponse,
} from './types'
import { CoverageAccount, CoverageCity, CoverageDistrict } from '.'
import { CityQuery } from 'services/address/city/types'
import { DistrictQuery } from 'services/address/district/types'
import { AccountQuery } from 'services/account/types'
import { UserQuery } from 'services/auth'

export interface CoverageDriver {
  create(coverage: CoverageInputRequest): Promise<void>
  update(id: string, vehicle: CoverageInputRequest): Promise<void>
  findById(id: string): Promise<CoverageFindResponse>
  query(query: QueryCoverageRequest): Promise<Result<CoverageQueryResponse>>
  delete(coverageId: string): Promise<AxiosResponse<void>>
  queryCities(query: CityQuery): Promise<Result<CoverageCity>>
  queryDistricts(query: DistrictQuery): Promise<Result<CoverageDistrict>>
  queryAccounts(query: AccountQuery): Promise<Result<CoverageAccount>>
  queryTechnicians(query: UserQuery): Promise<Result<CoverageTechnician>>
}

export class CoverageDriverImpl implements CoverageDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async create(coverage: CoverageInputRequest): Promise<void> {
    return this.httpClient.post('/coverage/create', coverage)
  }

  public async update(
    id: string,
    coverage: CoverageInputRequest,
  ): Promise<void> {
    return this.httpClient.put(`/coverage/${id}`, coverage)
  }

  public async findById(id: string): Promise<CoverageFindResponse> {
    return this.httpClient
      .get<CoverageFindResponse>(`/coverage/${id}`)
      .then((res) => res.data)
  }

  public async query(
    query?: QueryCoverageRequest,
  ): Promise<Result<CoverageQueryResponse>> {
    const result = await this.httpClient.get<Result<CoverageQueryResponse>>(
      `/coverage/query`,
      {
        params: query,
      },
    )

    return result.data
  }

  public async delete(coverageId: string): Promise<AxiosResponse<void>> {
    return this.httpClient.delete(`/coverage/${coverageId}`)
  }

  public async queryCities(query: CityQuery): Promise<Result<CoverageCity>> {
    const result = await this.httpClient.get<Result<CoverageCity>>(
      `/coverage/aggregation/cities/query`,
      {
        params: query,
      },
    )

    return result.data
  }

  public async queryDistricts(
    query: DistrictQuery,
  ): Promise<Result<CoverageDistrict>> {
    const result = await this.httpClient.get<Result<CoverageDistrict>>(
      `/coverage/aggregation/districts/query`,
      {
        params: query,
      },
    )

    return result.data
  }

  public async queryAccounts(
    query: AccountQuery,
  ): Promise<Result<CoverageAccount>> {
    const result = await this.httpClient.get<Result<CoverageAccount>>(
      `/coverage/aggregation/accounts/query`,
      {
        params: query,
      },
    )

    return result.data
  }

  public async queryTechnicians(
    query: UserQuery,
  ): Promise<Result<CoverageTechnician>> {
    return this.httpClient
      .get<Result<CoverageTechnician>>(
        `/coverage/aggregation/technicians/query`,
        {
          params: query,
        },
      )
      .then((res) => res.data)
  }
}

export const CoverageDriver = new CoverageDriverImpl()
