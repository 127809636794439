import { ComponentProps, useRef } from 'react'
import styles from './styles.module.scss'

const Group = ({ onClick, className, ...props }: ComponentProps<'div'>) => {
  const groupRef = useRef<HTMLDivElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = groupRef.current
    const formElement = target?.querySelector('input, select, textarea')

    if (formElement) {
      onClick?.(event)
      formElement?.click?.()
    }
  }

  return (
    <div
      {...props}
      ref={groupRef}
      onClick={handleClick}
      className={[styles.group, className].join(' ')}
    />
  )
}

export default Group
