export interface MaskArgs {
  regex: RegExp
  mask: string
}
export function createMasker(args: MaskArgs[]) {
  return (value: string): string => {
    let maskedValue: string = value
    for (let index = 0; index < args.length; index += 1) {
      const arg = args[index]
      maskedValue = maskedValue.replace(arg.regex, arg.mask)
    }
    return maskedValue
  }
}
