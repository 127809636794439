import { useState } from 'react'

interface UsePersistentTabReturn {
  externalWindow: Window | undefined
  replaceUrl(url: string): void
}

export function usePersistentTab(windowName: string): UsePersistentTabReturn {
  const [externalWindow, setExternalWindow] = useState<Window>()
  const [latestUrl, setLatestUrl] = useState<string>()

  const replaceUrl = (url: string) => {
    if (externalWindow && externalWindow.opener) {
      if (latestUrl !== url) {
        externalWindow.location.replace(url)
        setLatestUrl(url)
      }

      externalWindow.focus()
    } else {
      const newWindow = window.open(url, windowName)
      if (newWindow) {
        setLatestUrl(url)
        setExternalWindow(newWindow)
      }
    }
  }

  return {
    externalWindow,
    replaceUrl,
  }
}
