import { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import {
  ServiceOrderDriver,
  ServiceOrderAggregatedResponse,
  ServiceOrderWithStatusUpdateRequest,
} from 'services/serviceOrder'
import { Loader } from 'components'
import { useToast } from 'shared/hooks'
import { ServiceOrderForm } from '../../components/ServiceOrderForm/ServiceOrderForm'

export const EditServiceOrder = () => {
  const { addToast } = useToast()

  const location = useLocation()
  const navigate = useNavigate()

  const { serviceOrderId } = useParams()

  const [serviceOrder, setServiceOrder] =
    useState<ServiceOrderAggregatedResponse>()
  const [loading, setLoading] = useState(false)

  const fetchServiceOrder = useCallback(async () => {
    try {
      setLoading(true)
      setServiceOrder(await ServiceOrderDriver.findById(serviceOrderId))
      setLoading(false)
    } catch {
      addToast({
        type: 'alert',
        message:
          'Não foi possível recuperar as informações da ordem de serviço. Tente novamente',
      })
      history.back()
    }
  }, [addToast, history, serviceOrderId])

  useEffect(() => {
    fetchServiceOrder()
  }, [serviceOrderId, addToast, history, fetchServiceOrder])

  const onSave = useCallback(
    async (data: ServiceOrderWithStatusUpdateRequest) => {
      try {
        await ServiceOrderDriver.updateServiceOrder(serviceOrderId, {
          accountId: data.accountId,
          contact: data.contact,
          userId: data.userId,
          scheduleDate: data.scheduleDate,
          schedulePeriod: data.schedulePeriod,
          technicianId: data.technicianId,
          serviceOrderType: data.serviceOrderType,
          note: data.note,
          tags: data.tags?.map((tag) => ({ name: tag.name })),
          labels: data.labels.map((label) => ({
            ...label,
            active: true,
          })),
          status: data.status,
        })

        fetchServiceOrder()
        addToast({ message: 'Ordem de serviço editada com sucesso.' })
        if (location.state?.shouldReturnToSOInfo) {
          return navigate(`/so/info/${serviceOrderId}`)
        }

        navigate(-1)
      } catch {
        addToast({
          type: 'alert',
          message:
            'Não foi possível editar a ordem de serviço. Tente novamente',
        })
      }
    },
    [addToast, fetchServiceOrder, history, serviceOrderId],
  )

  if (loading) {
    return <Loader isVisible />
  }

  return (
    <ServiceOrderForm
      title="Edição da ordem de serviço"
      serviceOrder={serviceOrder}
      onSave={onSave}
    />
  )
}
