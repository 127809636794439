import { Breadcrumbs } from 'components'
import { useServiceOrderDetails } from '../../context'
import {
  serviceOrderStatuses,
  serviceOrderTypes,
} from 'services/serviceOrderV2'

const Header = () => {
  const { serviceOrder } = useServiceOrderDetails()

  return (
    <Breadcrumbs
      items={[
        { title: 'Home', href: '/' },
        {
          title: 'Ordem de serviço',
          href: '/so/search',
        },
        {
          title: serviceOrder?.status
            ? serviceOrderStatuses[serviceOrder.status]
            : '',
          href: '#',
        },
        {
          title: serviceOrder?.serviceOrderType
            ? `${serviceOrderTypes[serviceOrder.serviceOrderType]} #${serviceOrder?.number}`
            : '',
          href: '#',
        },
      ]}
    />
  )
}

export default Header
