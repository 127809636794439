import { Skeleton } from 'components'

import styles from './styles.module.scss'

const Loading = () => (
  <>
    {Array.from({ length: 8 }).map((_, index) => (
      <li key={index} className={styles.loading}>
        <div>
          <Skeleton height={14} width={52} />
          <Skeleton height={14} width={254} />
          <Skeleton height={14} width={135} />
        </div>

        <Skeleton height={64} />
        <Skeleton height={16} />
        <Skeleton height={16} />
      </li>
    ))}
  </>
)

export default Loading
