import { ReactComponent as OpenedStatusIcon } from 'assets/svg/serviceOrderOpenedStatusIcon.svg'
import { ReactComponent as InProgressStatusIcon } from 'assets/svg/serviceOrderInProgressStatusIcon.svg'
import { ReactComponent as PausedStatusIcon } from 'assets/svg/serviceOrderPausedStatusIcon.svg'
import { ReactComponent as FinishedStatusIcon } from 'assets/svg/serviceOrderFinishedStatusIcon.svg'
import { ReactComponent as CancelledStatusIcon } from 'assets/svg/serviceOrderCancelledStatusIcon.svg'
import { SERVICE_ORDER_STATUS_ENUM } from 'services/serviceOrder'
import { ToastType } from 'components/Toast/types'
import { FunctionComponent, SVGProps } from 'react'

interface ServiceOrderStatusData {
  status: string
  StatusIcon: FunctionComponent<SVGProps<SVGSVGElement>>
}

export const getServiceOrderStatusData = (
  status: string,
): ServiceOrderStatusData => {
  let serviceOrderStatus: ServiceOrderStatusData = {
    status: 'Indefinido',
    StatusIcon: CancelledStatusIcon,
  }
  switch (status) {
    case SERVICE_ORDER_STATUS_ENUM.OPEN:
      serviceOrderStatus = {
        status: 'Aberta',
        StatusIcon: OpenedStatusIcon,
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS:
      serviceOrderStatus = {
        status: 'Em execução',
        StatusIcon: InProgressStatusIcon,
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.PAUSED:
      serviceOrderStatus = {
        status: 'Em pausa',
        StatusIcon: PausedStatusIcon,
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.FINISHED:
      serviceOrderStatus = {
        status: 'Finalizada',
        StatusIcon: FinishedStatusIcon,
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.CANCELED:
      serviceOrderStatus = {
        status: 'Cancelada',
        StatusIcon: CancelledStatusIcon,
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.SCHEDULED:
      serviceOrderStatus = {
        status: 'Agendada',
        StatusIcon: OpenedStatusIcon,
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.RESCHEDULED:
      serviceOrderStatus = {
        status: 'Reagendada',
        StatusIcon: OpenedStatusIcon,
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION:
      serviceOrderStatus = {
        status: 'Em validação',
        StatusIcon: OpenedStatusIcon,
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.REPROVED:
      serviceOrderStatus = {
        status: 'Reprovada',
        StatusIcon: OpenedStatusIcon,
      }
  }
  return serviceOrderStatus
}

export const getToastSuccessMessage = (status: SERVICE_ORDER_STATUS_ENUM) => {
  let toastMessage: { type: ToastType; message: string } = {
    type: 'success',
    message: '',
  }

  switch (status) {
    case SERVICE_ORDER_STATUS_ENUM.REPROVED:
      toastMessage = {
        type: 'success',
        message: 'Ordem de Serviço validada com sucesso.',
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION:
      toastMessage = {
        type: 'success',
        message: 'Ordem de Serviço concluída com sucesso.',
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.FINISHED:
      toastMessage = {
        type: 'success',
        message: 'Ordem de Serviço validada com sucesso.',
      }
      break
  }

  return toastMessage
}

export const getToastFailMessage = (status: SERVICE_ORDER_STATUS_ENUM) => {
  let toastMessage: { type: ToastType; message: string } = {
    type: 'success',
    message: '',
  }

  switch (status) {
    case SERVICE_ORDER_STATUS_ENUM.REPROVED:
      toastMessage = {
        type: 'alert',
        message: 'Erro ao validar Ordem de Serviço. Tente novamente.',
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION:
      toastMessage = {
        type: 'alert',
        message: 'Erro ao concluir Ordem de Serviço. Tente novamente.',
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.FINISHED:
      toastMessage = {
        type: 'alert',
        message: 'Erro ao validar Ordem de Serviço. Tente novamente.',
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.PAUSED:
      toastMessage = {
        type: 'alert',
        message: 'Erro ao pausar Ordem de Serviço. Tente novamente.',
      }
      break
    case SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS:
      toastMessage = {
        type: 'alert',
        message: 'Erro ao executar Ordem de Serviço. Tente novamente.',
      }
      break
  }

  return toastMessage
}

export const isServiceOrderSchedulable = (
  status: SERVICE_ORDER_STATUS_ENUM,
): boolean => {
  const allowedStatuses = [SERVICE_ORDER_STATUS_ENUM.OPEN]
  return allowedStatuses.includes(status)
}

export const isServiceOrderReschedulable = (
  status: SERVICE_ORDER_STATUS_ENUM,
): boolean => {
  const allowedStatuses = [
    SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
    SERVICE_ORDER_STATUS_ENUM.RESCHEDULED,
    SERVICE_ORDER_STATUS_ENUM.PAUSED,
    SERVICE_ORDER_STATUS_ENUM.REPROVED,
  ]
  return allowedStatuses.includes(status)
}

export const shouldShowScheduleOrReschedule = (
  status: SERVICE_ORDER_STATUS_ENUM,
): boolean => {
  const allowedStatuses = [
    SERVICE_ORDER_STATUS_ENUM.OPEN,
    SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
    SERVICE_ORDER_STATUS_ENUM.RESCHEDULED,
    SERVICE_ORDER_STATUS_ENUM.PAUSED,
    SERVICE_ORDER_STATUS_ENUM.REPROVED,
  ]
  return allowedStatuses.includes(status)
}

export const isServiceOrderCancelable = (
  status: SERVICE_ORDER_STATUS_ENUM,
): boolean => {
  const allowedStatuses = [
    SERVICE_ORDER_STATUS_ENUM.OPEN,
    SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
    SERVICE_ORDER_STATUS_ENUM.RESCHEDULED,
    SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
    SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION,
    SERVICE_ORDER_STATUS_ENUM.REPROVED,
    SERVICE_ORDER_STATUS_ENUM.PAUSED,
  ]
  return allowedStatuses.includes(status)
}

export const isServiceOrderVisible = (
  status: SERVICE_ORDER_STATUS_ENUM,
): boolean => {
  const allowedStatuses = [SERVICE_ORDER_STATUS_ENUM.OPEN]
  return allowedStatuses.includes(status)
}
