import styles from './NewAttendancePolicyCard.module.scss'
import { FunctionComponent, SVGProps } from 'react'
import { Button, Icon as IconComponent } from 'components'
interface Button {
  text: string
  onClick: () => void
}

export interface NewAttendancePolicyCardProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  title: string
  text: string
  button?: Button
}

export const NewAttendancePolicyCard = (
  props: NewAttendancePolicyCardProps,
) => {
  const { Icon, title, text, button } = props

  return (
    <button
      className={styles.container}
      type="button"
      onClick={button?.onClick}
    >
      <Icon />
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{text}</p>
      {button && (
        <Button type="tertiary" buttonTitle={button.text}>
          <IconComponent name="add" />
        </Button>
      )}
    </button>
  )
}
