import { OccurrenceTypes } from 'services/attendance/types'

export const occurrenceTypesLabel: Record<OccurrenceTypes, string> = {
  ALARM: 'Alarme',
  RESCUE: 'Socorro',
  FAILURE: 'Falha',
  FINISHED: 'Finalizado',
  IMMINENT: 'Iminência',
  EMERGENCY: 'Emergência',
  COMMERCIAL: 'Comercial',
  MANUAL: 'Manual',
  SCHEDULED: 'Periódica',
}

export const colors: Record<OccurrenceTypes, string> = {
  ALARM: '#da291c',
  RESCUE: '#000000',
  FAILURE: '#f3ae3d',
  IMMINENT: '#dc7135',
  FINISHED: '#32af04',
  EMERGENCY: '#7a57ba',
  COMMERCIAL: '#009F87',
  SCHEDULED: '#b20c5c',
  MANUAL: '#553400',
}
