import { Button, Modal } from 'components'
import styles from './ExecuteServiceOrderModal.module.scss'

export type ExecuteServiceOrderModalProps = {
  isVisible: boolean
  onClose: () => void
  onClick: () => void
}

export const ExecuteServiceOrderModal = ({
  isVisible,
  onClose,
  onClick,
}: ExecuteServiceOrderModalProps) => {
  return (
    <Modal
      title="Deseja iniciar a execução da ordem de serviço?"
      isVisible={isVisible}
      className={styles.info}
      simple
      onClose={onClose}
    >
      <p>{`Você será direcionado para a área do cliente.`}</p>
      <Modal.Footer>
        <Button
          width="172px"
          buttonTitle="Cancelar"
          type="secondary"
          onClick={onClose}
        />
        <Button
          width="172px"
          buttonTitle="Executar"
          type="primary"
          onClick={onClick}
        />
      </Modal.Footer>
    </Modal>
  )
}
