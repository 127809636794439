export const serviceOrderType = {
  INSTALL: 'INSTALL',
  MAINTENANCE: 'MAINTENANCE',
  REMOTE_MAINTENANCE: 'REMOTE_MAINTENANCE',
  UNINSTALL: 'UNINSTALL',
}

export type SERVICE_ORDER_TYPE = keyof typeof serviceOrderType

export const serviceOrderTypeLabel: Record<SERVICE_ORDER_TYPE, string> = {
  INSTALL: 'Instalação',
  MAINTENANCE: 'Manutenção',
  REMOTE_MAINTENANCE: 'Manutenção remota',
  UNINSTALL: 'Desinstalação',
}

export const convertedServiceOrderTypeLabel: Record<
  string,
  SERVICE_ORDER_TYPE
> = {
  [serviceOrderTypeLabel.INSTALL]: 'INSTALL',
  [serviceOrderTypeLabel.MAINTENANCE]: 'MAINTENANCE',
  [serviceOrderTypeLabel.REMOTE_MAINTENANCE]: 'REMOTE_MAINTENANCE',
  [serviceOrderTypeLabel.UNINSTALL]: 'UNINSTALL',
}

export const serviceOrderStatus = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  FINISHED: 'FINISHED',
  CANCELED: 'CANCELED',
  SCHEDULED: 'SCHEDULED',
  IN_VALIDATION: 'IN_VALIDATION',
  DISAPPROVED: 'DISAPPROVED',
  RESCHEDULED: 'RESCHEDULED',
}

export type SERVICE_ORDER_STATUS = keyof typeof serviceOrderStatus

export const serviceOrderStatusLabel: Record<SERVICE_ORDER_STATUS, string> = {
  OPEN: 'Aberta',
  SCHEDULED: 'Agendada',
  IN_PROGRESS: 'Em andamento',
  PAUSED: 'Pausada',
  IN_VALIDATION: 'Em validação',
  DISAPPROVED: 'Reprovada',
  FINISHED: 'Finalizada',
  CANCELED: 'Cancelada',
  RESCHEDULED: 'Reagendada',
}

export const convertedServiceOrderStatusLabel: Record<
  string,
  SERVICE_ORDER_STATUS
> = {
  [serviceOrderStatusLabel.OPEN]: 'OPEN',
  [serviceOrderStatusLabel.SCHEDULED]: 'SCHEDULED',
  [serviceOrderStatusLabel.IN_PROGRESS]: 'IN_PROGRESS',
  [serviceOrderStatusLabel.PAUSED]: 'PAUSED',
  [serviceOrderStatusLabel.IN_VALIDATION]: 'IN_VALIDATION',
  [serviceOrderStatusLabel.DISAPPROVED]: 'DISAPPROVED',
  [serviceOrderStatusLabel.FINISHED]: 'FINISHED',
  [serviceOrderStatusLabel.CANCELED]: 'CANCELED',
  [serviceOrderStatusLabel.RESCHEDULED]: 'RESCHEDULED',
}
