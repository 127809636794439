import { useMutation } from '@tanstack/react-query'
import CommandService from 'services/command'
import { AnnulDeviceCommand } from 'services/command/types'

async function sentAnnulDevice(payload: AnnulDeviceCommand) {
  const { centralId, ...rest } = payload
  await CommandService.annulDevice(centralId, rest)
}

export const usePostAnnulDeviceCommand = () => {
  const {
    mutate: mutateAnnulDeviceCommand,
    status: annulDeviceCommandMutationStatus,
  } = useMutation({
    mutationFn: (payload: AnnulDeviceCommand) => sentAnnulDevice(payload),
  })

  return {
    mutateAnnulDeviceCommand,
    annulDeviceCommandMutationStatus,
  }
}
