import {
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  SVGProps,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { v4 as uuid } from 'uuid'

import { Pagination, Result } from 'services/types'

import { useOnClickOutside } from 'shared/hooks/useOnClickOutside'

import { ReactComponent as Arrow } from '../../assets/svg/arrow.svg'
import { ReactComponent as Search } from '../../assets/svg/comboSearch.svg'
import { ReactComponent as ErrorIcon } from '../../assets/svg/error.svg'
import { MultiSelectTags } from './components/MultiSelectTags'

import styles from './Combobox.module.scss'
import { ComboboxItemComponent } from './components/ComboboxItemComponent'
import { getItemValue, Item } from './utilities'

export type ComboboxItem<T> = {
  label: keyof T
  value: T
}

type ComboboxProps<T> = {
  value?: (string | ComboboxItem<T>) | (string | ComboboxItem<T>)[] | undefined
  isDisabled?: (item: string | ComboboxItem<T>) => boolean
  itemLabel?: string | ((item: ComboboxItem<T> | string) => string)
  multiple?: boolean
  searchable?: boolean
  Icon?: ReactElement<SVGProps<SVGSVGElement>>
  label?: string
  labelHint?: ReactNode
  items?: (string | ComboboxItem<T>)[]
  disabled?: boolean
  errorMessage?: string
  fetcher?: (
    pagination: Pagination,
    filter?: string,
  ) => Promise<Result<ComboboxItem<T>>>
  recordsPerPage?: number
  getSelected?: (
    selected:
      | (string | ComboboxItem<T>)
      | (string | ComboboxItem<T>)[]
      | undefined,
  ) => void
  id?: string
  className?: string
  placeholder?: string
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  inputMasker?: (value: string) => string
}

export const Combobox = forwardRef<HTMLInputElement, ComboboxProps<Item>>(
  (props, _ref) => {
    const {
      items,
      multiple,
      isDisabled,
      itemLabel,
      searchable = false,
      Icon,
      label,
      labelHint,
      fetcher,
      recordsPerPage = 15,
      disabled = false,
      getSelected,
      errorMessage,
      value,
      id,
      className,
      placeholder,
      inputProps,
      inputMasker,
    } = props

    const [open, setOpen] = useState(false)
    const [shouldLoadMore, setShouldLoadMore] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [selected, setSelected] = useState<
      string | ComboboxItem<Item> | undefined
    >()
    const [multiSelected, setMultiSelected] = useState<
      (string | ComboboxItem<Item>)[]
    >([])
    const [searchFilter, setSearchFilter] = useState('')
    const [data, setData] = useState<(string | ComboboxItem<Item>)[]>(
      items || [],
    )

    useEffect(() => {
      if (items) setData(items)
    }, [items])

    useEffect(() => {
      if (Array.isArray(value)) {
        setMultiSelected(value)
      } else {
        setSelected(value)
      }
    }, [value])

    // #UTILITIES
    const onSelectItem = (item: string | ComboboxItem<Item>) => {
      if (multiple) {
        const newMultipleSelected = [...multiSelected, item]
        setMultiSelected(newMultipleSelected)
        getSelected && getSelected(newMultipleSelected)
      } else {
        setSelected(item)
        getSelected && getSelected(item)
      }
      setSearchFilter('')
    }

    function isComboboxItem(
      item: string | ComboboxItem<Item> | undefined,
    ): item is ComboboxItem<Item> {
      return (item as ComboboxItem<Item>)?.value?.id !== undefined
    }

    const isItemEquals = (
      value: string | ComboboxItem<Item>,
      other?: string | ComboboxItem<Item>,
    ) => {
      if (isComboboxItem(value) && isComboboxItem(other)) {
        return value.value.id === other.value.id
      }
      return value === other
    }

    const skipSelectedValue = (item: string | ComboboxItem<Item>) => {
      if (multiple) {
        return multiSelected.some((multiItem) => isItemEquals(item, multiItem))
      }

      return isItemEquals(item, selected)
    }

    // #FILTERING
    const listRef = useRef<HTMLUListElement>(null)
    const inputSearchRef = useRef<HTMLInputElement>(null)
    const searchWaitTime = 800

    const handleDataFiltering = useCallback(() => {
      const listItems = listRef.current?.childNodes
      listItems?.forEach((_item, index) => {
        const li = listRef.current?.children.item(index) as HTMLLIElement
        if (
          li.textContent &&
          li.textContent.toUpperCase().indexOf(searchFilter?.toUpperCase()) > -1
        ) {
          li.classList.remove(styles.displayNone)
        } else {
          li.classList.add(styles.displayNone)
        }
      })
    }, [searchFilter])

    useEffect(() => {
      let timer: NodeJS.Timeout
      if (listRef.current) {
        if (!fetcher) {
          handleDataFiltering()
        } else {
          currentPage.current = 0
          setShouldLoadMore(true)
          timer = setTimeout(() => {
            handleFetch(true, true)
          }, searchWaitTime)
        }
      }

      return () => {
        clearTimeout(timer)
      }
    }, [searchFilter, handleDataFiltering, fetcher]) // eslint-disable-line react-hooks/exhaustive-deps

    // #CLICKING OUTSIDE
    const comboboxRef = useRef<HTMLDivElement>(null)

    useOnClickOutside(
      comboboxRef,
      (e) => {
        if (!multiple) {
          setOpen(false)
          return
        }

        const isComboboxMultipleItem = (e.target as Element).getAttribute(
          'data-combobox-item',
        )

        if (!isComboboxMultipleItem) {
          setOpen(false)
        }
      },
      true,
    )

    // #SCROLLING CONTENT
    const loading = useRef(false)
    const currentPage = useRef(0)
    const contentRef = useRef<HTMLDivElement>(null)

    const handleFetch = useCallback(
      async (scrollToTop = false, resetData = false) => {
        if (!fetcher || loading.current) return

        loading.current = true
        setHasError(false)

        try {
          const res = await fetcher(
            {
              recordsPerPage,
              offset: currentPage.current * recordsPerPage,
            },
            searchFilter,
          )

          if (scrollToTop) contentRef.current?.scroll({ top: 0 })
          const newData = resetData ? res.data : [...data, ...res.data]

          setData(newData)
          setShouldLoadMore(res.totalElements > newData.length)
          currentPage.current += 1
        } catch {
          setHasError(true)
        } finally {
          loading.current = false
        }
      },
      [fetcher, searchFilter, recordsPerPage, data],
    )

    useLayoutEffect(() => {
      const currentContentRef = contentRef.current
      const handleScroll = () => {
        if (!currentContentRef || !shouldLoadMore || !data.length) return
        if (
          currentContentRef.scrollTop + currentContentRef.clientHeight >=
          currentContentRef.scrollHeight - 5
        ) {
          handleFetch()
        }
      }

      currentContentRef?.addEventListener('scroll', handleScroll)

      return () => {
        currentContentRef?.removeEventListener('scroll', handleScroll)
      }
    }, [handleFetch, shouldLoadMore, data])

    // OFFSCREEN CONTENT
    const [offScreen, setOffScreen] = useState(false)

    useLayoutEffect(() => {
      if (!open || !contentRef.current) {
        return
      }

      // Set the content to the original position to retrieve the correct rect
      contentRef.current.style.top = 'auto'
      const rect = contentRef.current.getBoundingClientRect()
      contentRef.current.style.top = ''

      const threshold = 3
      setOffScreen(rect.top + rect.height + threshold > window.innerHeight)
    }, [open, contentRef, data])

    return (
      <div
        className={[
          styles.container,
          disabled && styles.containerDisabled,
          className,
        ]
          .filter(Boolean)
          .join(' ')}
      >
        {label && (
          <div className={styles.labelWrapper}>
            <label htmlFor={id} className={styles.label}>
              {label}
            </label>
            {labelHint && labelHint}
          </div>
        )}
        <div
          className={[
            styles.combobox,
            open && styles.comboboxOpened,
            multiple && styles.comboboxMultiple,
            multiple && multiSelected.length && styles.comboboxMultipleWithItem,
            Icon && styles.comboboxViewSelector,
            Icon && open && styles.comboboxViewSelectorOpened,
            searchable && styles.comboboxSearchable,
            disabled && styles.disabled,
            errorMessage && styles.error,
          ]
            .filter(Boolean)
            .join(' ')}
          ref={comboboxRef}
        >
          {Icon && <Icon className={styles.icon} role="img" />}
          {searchable && (
            <Search
              className={styles.searchIcon}
              role="img"
              onClick={() => {
                inputSearchRef.current?.focus()
                setOpen(true)
              }}
            />
          )}
          {multiple ? (
            <MultiSelectTags
              items={multiSelected}
              placeholder={placeholder}
              onRemoveItem={(itemToBeDeleted) => {
                const newMultiSelected = multiSelected.filter(
                  (item) => item !== itemToBeDeleted,
                )
                setMultiSelected(newMultiSelected)
                getSelected && getSelected(newMultiSelected)
              }}
            />
          ) : (
            <input
              id={id}
              role="combobox"
              aria-controls={`${id || 'combobox'}-items`}
              aria-expanded={open}
              autoComplete="off"
              ref={inputSearchRef}
              disabled={disabled}
              className={[styles.input, searchable && styles.searchableInput]
                .filter(Boolean)
                .join(' ')}
              type="text"
              value={
                String(getItemValue(selected || '') || '') ||
                (inputMasker ? inputMasker(searchFilter) : searchFilter)
              }
              readOnly={!searchable}
              onChange={(event) => {
                if (selected) setSelected(undefined)
                if (!event.target.value) {
                  setSelected(undefined)
                  getSelected && getSelected(undefined)
                }

                setSearchFilter(event.target?.value)
              }}
              onClick={() => {
                setOpen((prev) => searchable || !prev)
              }}
              placeholder={placeholder}
              {...inputProps}
            />
          )}
          <Arrow
            className={[
              styles.arrow,
              multiple && styles.arrowMultiple,
              open && styles.upsideArrow,
              Icon && styles.viewSelectorArrow,
            ]
              .filter(Boolean)
              .join(' ')}
            onClick={() => {
              setOpen((prev) => !prev)
            }}
            data-testid="arrow-icon"
          />
        </div>
        {errorMessage && (
          <div className={styles.errorWrapper}>
            <ErrorIcon className={styles.errorIcon} />
            <p
              role="textbox"
              aria-label="error-message-text"
              className={styles.errorText}
            >
              {errorMessage}
            </p>
          </div>
        )}
        <div
          ref={contentRef}
          id={`${id || 'combobox'}-items`}
          data-testid="content-wrapper"
          className={[
            styles.content,
            open && styles.visible,
            offScreen && styles.offScreen,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          <ul ref={listRef}>
            {data?.map((item, index) => {
              const skipItem = skipSelectedValue(item)
              if (!skipItem) {
                const itemDisabled = isDisabled ? isDisabled(item) : false
                return (
                  <ComboboxItemComponent
                    item={item}
                    itemId={uuid()}
                    itemDisabled={itemDisabled}
                    onSelectItem={onSelectItem}
                    itemLabel={itemLabel}
                    key={index}
                  />
                )
              }
            })}
            {!data.length && !shouldLoadMore && !hasError && (
              <p className={styles.noDataPlaceholder}>
                Não foram encontrados itens.
              </p>
            )}
            {!data.length && hasError && (
              <p className={styles.errorPlaceholder}>
                Erro ao carregar as opções.
              </p>
            )}
            {fetcher && shouldLoadMore && !hasError && (
              <div className={styles.loader} />
            )}
          </ul>
        </div>
      </div>
    )
  },
)

Combobox.displayName = 'Combobox'
