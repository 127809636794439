import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'

import { ReactComponent as ControlIcon } from 'assets/svg/control.svg'

import { useUserInfo } from 'shared/hooks'
import { Button, Icon, Loader, Modal, Textarea } from 'components'
import {
  useGetOccurrenceOnAttendance,
  useMakeCall,
  usePostNote,
  usePostIntervention,
} from 'services/attendance'
import {
  AutomaticRuleData,
  InterventionTag,
  RouteParams,
} from 'domains/occurrence/screens/Attendance/types'

import {
  ContactType,
  PartitionOnAttendance,
  Phone,
  PhoneCallTarget,
} from 'services/attendance/types'
import {
  useFilter,
  useModal,
} from 'domains/occurrence/screens/Attendance/context'
import {
  getInterventionTitle,
  interventionTagLabel,
} from 'domains/occurrence/screens/Attendance/utils'

import styles from './styles.module.scss'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'

type StateRender = 'JUSTIFY_ACTION' | 'QUESTION' | 'ACTION_PLAN'
type CallTypes = PhoneCallTarget

interface FormProps {
  note: string
}

type PhoneCall = {
  phone?: Phone
  contact?: {
    id: string
    name: string
  }
}

const schema = Joi.object<FormProps>({
  note: Joi.string().required(),
})

const handleTag = (type: CallTypes) => {
  const options: Record<CallTypes, InterventionTag> = {
    CENTRAL: 'SAFE_CALL',
    POLICE: 'POLICE_CALL',
    PATRIMONY: 'SAFE_CALL',
    TACTICAL_AGENT: 'SAFE_CALL',
    ACTION_PLAN: 'CONTACT_CALL',
  }

  return options[type]
}

const handlePhoneType = (type: ContactType) => {
  const options: Record<ContactType, string> = {
    ADMIN: 'Administrador',
    CONTACT: 'Contato',
    GUEST: 'Convidado',
  }

  return options[type]
}

const AutomaticRule: FC<AutomaticRuleData> = ({ intervention, type }) => {
  const { userInfo } = useUserInfo()
  const { mutate: call } = useMakeCall()
  const { filter } = useFilter()
  const { occurrenceId } = useParams<RouteParams>()
  const { handleCloseModal } = useModal()
  const { mutate: postNote } = usePostNote(occurrenceId, intervention.id)
  const { mutate: postIntervention, isPending: postInterventionLoading } =
    usePostIntervention(occurrenceId)
  const { data, isLoading, isError } =
    useGetOccurrenceOnAttendance(occurrenceId)

  const [searchValue, setSearchValue] = useState('')
  const [selectedPartition, setSelectedPartition] =
    useState<PartitionOnAttendance>()
  const [stateRender, setStateRender] = useState<StateRender>(
    type === 'ACTION_PLAN' ? 'ACTION_PLAN' : 'QUESTION',
  )

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schema),
  })

  const comboBoxItems = useMemo(
    () =>
      parseDataToComboboxV2(
        data?.partitions?.filter((partition) =>
          partition.name.toLowerCase().includes(searchValue.toLowerCase()),
        ) || [],
        'name',
      ),
    [data, searchValue],
  )

  const actionPlan = useMemo(() => {
    const currentPartition = data?.partitions.filter(
      (partition) => partition.id === selectedPartition?.id,
    )

    return currentPartition?.[0]?.actionPlan
  }, [data, selectedPartition])

  const handlePhoneCall = useCallback(
    ({ phone: actionPlanPhone, contact }: PhoneCall) => {
      const phones = data?.phones
      const phoneOptions: Record<CallTypes, Phone | undefined> = {
        POLICE: phones?.police,
        CENTRAL: phones?.module,
        PATRIMONY: phones?.patrimony,
        TACTICAL_AGENT: phones?.agent,
        ACTION_PLAN: actionPlanPhone,
      }

      const tag = handleTag(type)
      const phone = phoneOptions[type]
      const userId = userInfo.id

      if (userId && userInfo) {
        const tags = []

        tags.push({
          name: interventionTagLabel[tag],
        })

        const phoneFormatted = `${phone?.provinceCode || ''}${
          phone?.phone || '000'
        }`

        postIntervention(
          {
            userId,
            typeName: 'PHONE_CALL',
            attributes: {
              status: 'SUCCESS',
              number: phoneFormatted,
              contactName: contact?.name,
              requirePhoneCallInterventionId: intervention.id,
            },
            title: getInterventionTitle(tag),
            tags,
          },
          {
            onSuccess: () => {
              handleCloseModal()
              call({
                phoneNumber: phoneFormatted,
                phoneExtension: userInfo?.phoneExtension,
              })
            },
          },
        )
      } else {
        handleCloseModal()
      }
    },
    [
      type,
      userInfo,
      data,
      postIntervention,
      intervention,
      handleCloseModal,
      call,
    ],
  )

  const handleJustifyAction = useCallback(
    (data: FormProps) => {
      const userId = userInfo.id

      if (userId) {
        postIntervention(
          {
            userId,
            note: data.note,
            ...(intervention.tags && {
              tags: intervention.tags,
            }),
            typeName: 'REFUSE_PHONE_CALL_REQUIREMENT',
            attributes: {
              requirePhoneCallInterventionId: intervention.id,
              status: 'FAILURE',
            },
            title: `Ligação automática para ${handleTitle(type)} recusada`,
          },
          { onSuccess: handleCloseModal },
        )
      }
    },
    [handleCloseModal, intervention, postIntervention, type],
  )

  const handleTitle = (type: CallTypes) => {
    const titles: Record<CallTypes, string> = {
      POLICE: 'Ligar para a Polícia',
      CENTRAL: 'Ligar para a Central',
      PATRIMONY: 'Ligar para o Patrimônio',
      TACTICAL_AGENT: 'Ligar para o Tático',
      ACTION_PLAN: 'Selecione um contato',
    }

    return titles[type]
  }

  const handleClose = useCallback(() => {
    const userId = userInfo.id

    if (userId) {
      postNote(
        {
          userId,
          attributes: {
            status: 'FAILURE',
            requirePhoneCallInterventionId: intervention.id,
            phoneCallTarget: intervention.attributes.phoneCallTarget,
          },
          tags: [{ name: 'adiouARegra' }],
          note: 'Atenção! Existe uma ligação pendente para o local desta ocorrência.',
        },
        {
          onSuccess: handleCloseModal,
        },
      )
    }
  }, [handleCloseModal, intervention, postNote])

  useEffect(() => {
    setSelectedPartition(data?.partitions[0])
  }, [data, filter])

  return (
    <Modal
      isVisible
      onClose={handleClose}
      title={
        stateRender === 'JUSTIFY_ACTION' ? 'Justificar ação' : handleTitle(type)
      }
      size="sm"
      simple
    >
      {
        {
          QUESTION: (
            <>
              <p>{intervention.notes[0]?.note}</p>
              <Modal.Footer>
                <Button
                  width="186px"
                  buttonTitle="Não ligar"
                  onClick={() => setStateRender('JUSTIFY_ACTION')}
                  type="secondary"
                />
                <Button
                  width="186px"
                  type="primary"
                  buttonTitle="Ligar"
                  disabled={postInterventionLoading}
                  onClick={() => handlePhoneCall({})}
                />
              </Modal.Footer>
            </>
          ),
          JUSTIFY_ACTION: (
            <form
              onSubmit={handleSubmit(handleJustifyAction)}
              className={styles.form}
            >
              <Textarea
                rows={5}
                label="Justificativa"
                {...register('note')}
                onChange={(event) =>
                  setValue('note', event.target.value, {
                    shouldValidate: true,
                  })
                }
              />
              <Modal.Footer>
                <Button
                  width="186px"
                  buttonTitle="Voltar"
                  onClick={() => setStateRender('QUESTION')}
                  type="secondary"
                />
                <Button
                  width="186px"
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid || postInterventionLoading}
                  buttonTitle="Salvar"
                />
              </Modal.Footer>
            </form>
          ),
          ACTION_PLAN: (
            <div className={styles.container}>
              <Combobox
                label={{
                  text: 'Partição',
                }}
                isError={isError}
                items={comboBoxItems}
                isLoading={isLoading}
                value={selectedPartition?.name || ''}
                onSearch={setSearchValue}
                onChange={(selected) => {
                  const selectedPartition =
                    selected as ComboboxItem<PartitionOnAttendance>

                  setSelectedPartition(selectedPartition.value)
                }}
              />
              <div className={styles.content}>
                <span>Plano de ação</span>
                {isLoading ? (
                  <Loader isVisible />
                ) : (
                  <ul>
                    {actionPlan?.map((actionPlan) => (
                      <li key={actionPlan.id}>
                        <div>
                          <span>{actionPlan.name}</span>
                          <span>
                            {handlePhoneType(actionPlan.type)}
                            {actionPlan.hasRemoteControl && <ControlIcon />}
                          </span>
                        </div>
                        <span
                          className={styles.phone}
                          onClick={() =>
                            handlePhoneCall({
                              phone: {
                                phone: String(actionPlan.phone.number),
                                provinceCode: String(
                                  actionPlan.phone.provinceCode,
                                ),
                              },
                              contact: {
                                id: actionPlan.id,
                                name: actionPlan.name,
                              },
                            })
                          }
                        >
                          <Icon name="phone" />
                          {`(${
                            actionPlan.phone.provinceCode
                          }) ${actionPlan.phone.number
                            .toString()
                            .substring(0, 4)}.${actionPlan.phone.number
                            .toString()
                            .substring(4, 8)}`}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <Button
                type="secondary"
                buttonTitle="Cancelar"
                width="172px"
                onClick={() => setStateRender('JUSTIFY_ACTION')}
              />
            </div>
          ),
        }[stateRender]
      }
    </Modal>
  )
}

export default AutomaticRule
