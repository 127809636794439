import {
  ComponentProps,
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'
import { ServiceOrder } from 'services/serviceOrderV2'

interface ServiceOrderDetails {
  serviceOrder: ServiceOrder | undefined
  setServiceOrder: Dispatch<SetStateAction<ServiceOrder | undefined>>
}

export const ServiceOrderDetailsContext = createContext<ServiceOrderDetails>({
  serviceOrder: undefined,
  setServiceOrder: () => null,
})

export const useServiceOrderDetails = () => {
  const context = useContext(ServiceOrderDetailsContext)

  if (!context) {
    throw new Error(
      'useServiceOrderDetails must be used within a ServiceOrderDetailsProvider',
    )
  }

  return context
}

export const ServiceOrderDetailsProvider = (props: ComponentProps<'div'>) => {
  const [serviceOrder, setServiceOrder] = useState<ServiceOrder | undefined>(
    undefined,
  )

  return (
    <ServiceOrderDetailsContext.Provider
      value={{ serviceOrder, setServiceOrder }}
      {...props}
    />
  )
}
