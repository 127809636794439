import {
  ReactElement,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Button, Datepicker, Input, RadioGroup } from 'components'

import styles from './styles.module.scss'

import { RadioButton } from 'components/RadioButton/RadioButton'
import { convertDateToString } from 'utilities/datepicker'

import { ReactComponent as PlusSign } from 'assets/svg/plusSign.svg'
import { ReactComponent as RemoveIcon } from 'assets/svg/remove.svg'

type DateData = {
  date?: number
  time?: string
}

export interface DateConfigProps {
  label: string
  onChange: (data: DateData) => void
  date?: number
  time?: string
  disableButtons?: Array<'immediate' | 'from' | 'date' | 'hourButton'>
}

const DateConfig = forwardRef<HTMLInputElement, DateConfigProps>(
  ({ label, date, time, onChange, disableButtons = [] }, ref) => {
    const [visibleTimeInput, setVisibleTimeInput] = useState(false)
    const [immediate, setImmediate] = useState(false)

    const getButtonContent = useCallback(
      (key: 'addHours' | 'removeHours'): ReactElement => {
        const buttonProps = {
          onClick: () => {
            setVisibleTimeInput((prev) => !prev)

            onChange({ date, time: '' })
          },
          className: styles.button,
          disabled: disableButtons.includes('hourButton'),
        }

        const props = {
          addHours: (
            <Button
              buttonTitle="Definir horário (opcional)"
              icon={PlusSign}
              type="tertiary"
              {...buttonProps}
            />
          ),
          removeHours: (
            <Button
              buttonTitle="Remover horário"
              icon={RemoveIcon}
              type="tertiary"
              {...buttonProps}
            />
          ),
        }

        return props[key]
      },
      [],
    )

    useEffect(() => {
      setVisibleTimeInput(!!time)
    }, [time]) //eslint-disable-line

    return (
      <>
        <div className={styles.dateConfigs}>
          <div>
            <RadioGroup
              title={label}
              flexDirection="column"
              customStyle={styles.radioGroup}
            >
              <RadioButton
                value="Imediato"
                onChange={() => {
                  setImmediate(true)

                  onChange({
                    date: new Date().getTime(),
                    time: '',
                  })
                }}
                checked={immediate}
                name={`${label}-immediate`}
                key={`${label}-immediate`}
                disabled={disableButtons.includes('immediate')}
              />

              <RadioButton
                value="A partir de"
                onChange={() => {
                  setImmediate(false)

                  onChange({
                    date: new Date().getTime(),
                    time: '',
                  })
                }}
                checked={!immediate}
                name={`${label}-from`}
                key={`${label}-from`}
                disabled={disableButtons.includes('from')}
              />
            </RadioGroup>
          </div>
          <div className={styles.dateInputs}>
            <div className={styles.datePicker}>
              <Datepicker
                ref={ref}
                id="date"
                initialValueInput={
                  date && !immediate
                    ? convertDateToString(new Date(date))
                    : undefined
                }
                initialDate={new Date()}
                onChangeDate={(date) => {
                  onChange({
                    date: date?.getTime() || 0,
                    time: '',
                  })
                }}
                selectEnd
                disabled={immediate || disableButtons.includes('date')}
              />
            </div>

            {visibleTimeInput && (
              <div className={styles.fieldsInput}>
                <span className={styles.dateLabel}>às</span>
                <Input
                  id={`${label}-time`}
                  name={`${label}-time`}
                  wrapperClassName={styles.hoursInput}
                  type="time"
                  autoComplete="off"
                  value={time || ''}
                  onChange={(event) =>
                    onChange({
                      date,
                      time: event.target.value,
                    })
                  }
                  placeholder="hh:mm"
                  disabled={disableButtons.includes('date')}
                />
              </div>
            )}
          </div>
          {getButtonContent(visibleTimeInput ? 'removeHours' : 'addHours')}
        </div>
      </>
    )
  },
)

DateConfig.displayName = 'DateConfig'

export default DateConfig
