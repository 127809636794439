import { Button, Modal } from 'components'
import { useModal } from 'domains/occurrence/screens/Attendance/context'
import { FC } from 'react'

const InterventionWithoutNote: FC = () => {
  const { handleCloseModal } = useModal()

  return (
    <Modal
      isVisible
      title="Não é possível finalizar este atendimento."
      size="sm"
      simple
    >
      <p>
        Existem logs não preenhcidos nesta ocorrência. Para finalizá-la,
        registre a tratativa efetivada em todas as intervenções realizadas
        durante o atendimento.
      </p>
      <Modal.Footer>
        <Button
          width="172px"
          buttonTitle="Voltar"
          onClick={handleCloseModal}
          type="primary"
        />
      </Modal.Footer>
    </Modal>
  )
}

export default InterventionWithoutNote
