import { useMutation } from '@tanstack/react-query'
import CommandService from 'services/command'
import { CommandPayload } from 'services/command/types'

async function sendInfoRequest(centralId: string, payload: CommandPayload) {
  await CommandService.infoRequest(centralId, payload)
}

export const usePostInfoRequestCommand = (
  centralId: string,
  payload: CommandPayload,
) => {
  const { mutate: mutateInfoRequest, status: infoRequestStatus } = useMutation({
    mutationFn: () => sendInfoRequest(centralId, payload),
  })

  return {
    mutateInfoRequest,
    infoRequestStatus,
  }
}
