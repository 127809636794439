import { Paging } from 'services/types'

export declare type ServiceTypeQuery = {
  name?: string
} & Partial<Paging>

export enum TimeUnit {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export type ServiceType = {
  id: string
  name: string
  travelLimitEnabled: boolean
  travelLimitQuantity?: number
  travelLimitIntervalValue?: number
  travelLimitIntervalUnit?: TimeUnit
  sensorTriggersEnabled: boolean
  sensorTriggersQuantity?: number
  sensorTriggersIntervalValue?: number
  sensorTriggersIntervalUnit?: TimeUnit
  createdAt?: number
  updatedAt?: number
}

export enum ServiceTypes {
  Alarme365 = 'Alarme 365',
}
