import React, { useState } from 'react'
import styles from './Filters.module.scss'

import { ReactComponent as CloseButton } from 'assets/svg/close.svg'
import { Button } from 'components'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { AggregatedAccount } from 'services/account/types'
import { useFormContext } from 'react-hook-form'
import { OccurrenceFilters } from 'domains/occurrence/schemas/searchOccurrenceSchema'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'

import { OccurrenceTypeQueryResponse } from 'services/occurrence/types'
import { useGetOccurrenceType } from 'shared/hooks/services/occurrence/useGetOccurrenceType'
import { useFilterAccounts } from 'shared/hooks/accounts/useGetAccounts'

export type FilterComponentProps = {
  isVisible: boolean
  onClose: () => void
  onReset: () => void
  onSubmit: () => void
}

export const Filters: React.FC<FilterComponentProps> = ({
  isVisible,
  onClose,
  onReset,
  onSubmit,
}: FilterComponentProps) => {
  const { watch, setValue, register, reset } =
    useFormContext<OccurrenceFilters>()

  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const [searchAccount, setSearchAccount] = useState('')
  const [searchOccurrenceType, setSearchOccurrenceType] = useState('')

  const {
    occurrenceTypes,
    isError: occurrenceTypeError,
    isFetching: occurrenceTypeFetching,
  } = useGetOccurrenceType(searchOccurrenceType)

  const {
    data: accountsOptions,
    isFetching: isFetchingAccounts,
    isError: isErrorAccounts,
  } = useFilterAccounts({ code: searchAccount })

  return (
    <div
      className={[styles.container, isVisible ? styles.show : styles.hide]
        .filter(Boolean)
        .join(' ')}
      data-testid="filters-component"
    >
      <div className={styles.innerWrapper}>
        <div className={styles.header}>
          <span>Filtros</span>
          <CloseButton
            height={16}
            width={16}
            onClick={onClose}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.contentWrapper}>
          <Combobox
            id="account"
            label={{ text: 'Conta' }}
            {...register('account')}
            value={watch('account')?.aggregatedAccountName || undefined}
            items={parseDataToComboboxV2(
              accountsOptions?.data || [],
              'aggregatedAccountName',
            )}
            isError={isErrorAccounts}
            onChange={(selected) => {
              const selectedAccount =
                selected as unknown as ComboboxItem<AggregatedAccount>

              setValue(
                'account',
                {
                  id: selectedAccount?.value.id,
                  aggregatedAccountName:
                    selectedAccount.value.aggregatedAccountName,
                },
                {
                  shouldValidate: true,
                },
              )
            }}
            isLoading={isFetchingAccounts}
            onSearch={setSearchAccount}
          />

          <Combobox
            id="occurrence-type-input"
            label={{ text: 'Tipo de ocorrência' }}
            {...register('occurrenceType')}
            value={watch('occurrenceType')?.name || undefined}
            items={parseDataToComboboxV2(occurrenceTypes || [], 'name')}
            onChange={(selected) => {
              const selectedOccurrenceType =
                selected as unknown as ComboboxItem<OccurrenceTypeQueryResponse>
              setValue('occurrenceType', selectedOccurrenceType?.value, {
                shouldValidate: true,
              })
            }}
            isError={occurrenceTypeError}
            isLoading={occurrenceTypeFetching}
            onSearch={setSearchOccurrenceType}
          />
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              reset({
                number: '',
                account: undefined,
                occurrenceType: undefined,
              })
              onReset()
              clearAllLocalStorageFilters()
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            width="162px"
            htmlType="submit"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  )
}
