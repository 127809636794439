import { AlertTypes, OccurrenceTypes } from 'services/displacementMap/types'
import { ImageResponse } from 'services/image/types'

const modals = {
  OPTIONS: 'OPTIONS',
  FILTERS: 'FILTERS',
  CHANGE_TACTICAL: 'CHANGE_TACTICAL',
  CHANGE_DISPLACEMENT_STEP: 'CHANGE_DISPLACEMENT_STEP',
  START_DUTY: 'START_DUTY',
  QUICK_ALERT: 'QUICK_ALERT',
  TERMINATE_DUTY: 'TERMINATE_DUTY',
  GALLERY: 'GALLERY',
  CHANGE_CAR: 'CHANGE_CAR',
  END_OCCURRENCE: 'END_OCCURRENCE',
  PUT_UNDER_OBSERVATION: 'PUT_UNDER_OBSERVATION',
  UPDATE_COORDINATES: 'UPDATE_COORDINATES',
  LOG_HISTORY: 'LOG_HISTORY',
  PAUSE_DUTY: 'PAUSE_DUTY',
  RESUME_ERROR_DUTY: 'RESUME_ERROR_DUTY',
  RESUME_SUCCESS_DUTY: 'RESUME_SUCCESS_DUTY',
  TACTICAL: 'TACTICAL',
  CHANGE_STATE: 'CHANGE_STATE',
}

export type Modals = keyof typeof modals
export type ModalData = {
  imageId: string
  images: ImageResponse[]
  onClose: () => void
  onSubmit: () => void
}
export type DisplacementStep =
  | 'TRAVEL_SENT'
  | 'TRAVEL_VIEWED'
  | 'TRAVEL_STARTED'
  | 'TRAVEL_FINISHED'

export const occurrenceTypesLabel: Record<OccurrenceTypes, string> = {
  ALARM: 'Alarme',
  RESCUE: 'Socorro',
  FAILURE: 'Falha',
  FINISHED: 'Finalizado',
  IMMINENT: 'Iminência',
  EMERGENCY: 'Emergência',
  COMMERCIAL: 'Comercial',
  MANUAL: 'Manual',
  SCHEDULED: 'Periódica',
}

export const alertTypesLabel: Record<AlertTypes, string> = {
  COORDINATES_UPDATE_REQUEST: 'Alteração de coordenadas',
  CHECKLIST_SENT: 'Checklist inconsistente',
  TRAVEL_WITHOUT_CHANGES: 'Ocorrência sem evolução',
  SUPPORT_REQUEST: 'Contato do tático',
}
