import { useCallback } from 'react'

import { Button, Modal } from 'components'
import { BackDrop } from '../../../../components'

import styles from './ChangeDisplacementStep.module.scss'
import { handleSteps } from './utils'
import { useFetchTacticals } from 'services/displacementMap'
import { DisplacementStep } from '../../../../types'
import usePostIntervention from 'shared/hooks/services/occurrence/usePostIntervention'
import { useQueryClient } from '@tanstack/react-query'
import { useToast, useUserInfo } from 'shared/hooks'
import {
  useDisplacementStep,
  useMap,
  useDisplacementModal,
} from '../../../../contexts'

const handleTitles = (step: DisplacementStep, agentName: string) => {
  const steps: Record<DisplacementStep, string> = {
    TRAVEL_VIEWED: `Solicitação visualizada pelo agente tático ${
      agentName || ''
    }`,
    TRAVEL_STARTED: `Agente tático ${agentName || ''} em trânsito`,
    TRAVEL_SENT: 'Chegada no local',
    TRAVEL_FINISHED: 'Chegada no local',
  }

  return steps[step]
}

const tags: Record<DisplacementStep, string> = {
  TRAVEL_SENT: 'deslocamentoEnviado',
  TRAVEL_VIEWED: 'deslocamentoVisualizado',
  TRAVEL_STARTED: 'deslocamentoIniciado',
  TRAVEL_FINISHED: 'deslocamentoNoLocal',
}

export const ChangeDisplacementStep = () => {
  const { addToast } = useToast()
  const queryClient = useQueryClient()
  const { userInfo } = useUserInfo()
  const userId = userInfo.id
  const { selectedRoute } = useMap()
  const { displacementStep } = useDisplacementStep()
  const { handleCloseModal } = useDisplacementModal()

  const { mutateCreateIntervention } = usePostIntervention({
    occurrenceId: selectedRoute.occurrence?.id,
    onSuccessCallback: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-tactical', 'fetch-occurrences'],
      })
    },
  })

  const { data: agentDuty } = useFetchTacticals(userId, 10000)

  const currentTactical = agentDuty?.find(
    (tactical) => selectedRoute.tactical?.id === tactical.id,
  )

  const handleSubmit = useCallback(() => {
    if (userId && currentTactical) {
      mutateCreateIntervention(
        {
          title: handleTitles(displacementStep, currentTactical?.agentName),
          userId,
          tags: [{ name: tags[displacementStep] }],
          typeName: 'STATE_CHANGE',
          attributes: {
            stateName: displacementStep,
            agent: {
              id: currentTactical.agentId,
              name: currentTactical.agentName,
            },
            vehicle: {
              id: currentTactical.vehicle.id,
              licensePlate: currentTactical.vehicle.licensePlate,
            },
            agentCoordinates: currentTactical.position,
          },
        },
        {
          onSuccess: handleCloseModal,
          onError: () =>
            addToast({
              message: 'Erro ao realizar intervenção',
              type: 'alert',
            }),
        },
      )
    }
  }, [
    userId,
    mutateCreateIntervention,
    displacementStep,
    currentTactical,
    handleCloseModal,
    addToast,
  ])

  return (
    <BackDrop onClose={handleCloseModal}>
      <Modal
        simple
        title="Alterar etapa de deslocamento"
        isVisible
        className={styles.container}
        onClose={handleCloseModal}
      >
        <p>
          Você tem certeza que deseja definir a etapa de deslocamento desta
          ocorrência para {handleSteps(displacementStep)}?
        </p>
        <Modal.Footer>
          <Button
            type="secondary"
            buttonTitle="Cancelar"
            onClick={handleCloseModal}
            width="172px"
          />
          <Button
            type="primary"
            buttonTitle="Continuar"
            onClick={handleSubmit}
            width="172px"
          />
        </Modal.Footer>
      </Modal>
    </BackDrop>
  )
}
