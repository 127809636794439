import { Fragment, ReactNode, RefObject, useRef } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import styles from './Tooltip.module.scss'
import { useElementPosition } from 'shared/hooks/useElementPosition'
import { POSITION } from 'shared/hooks/useElementPosition/types'

type tooltipType = 'explanatory' | 'informative' | 'warning'

export interface TooltipProps {
  type: tooltipType
  isVisible: boolean
  children: ReactNode
  positionOnEdge?: boolean
  hasArrow?: boolean
  onMouseIn?(): void
  onMouseOut?(): void
  parentRef: RefObject<HTMLElement>
  positionAbove?: POSITION
}

export const Tooltip = (props: TooltipProps) => {
  const {
    children,
    hasArrow = true,
    type,
    isVisible,
    positionOnEdge = false,
    onMouseIn,
    onMouseOut,
    parentRef,
    positionAbove,
  } = props

  const tooltipRef = useRef<HTMLDivElement>(null)

  const { top, left, position } = useElementPosition({
    enabled: isVisible,
    actionTriggerRef: parentRef,
    positionOnEdge,
    elementRef: tooltipRef,
    positionAbove,
  })

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Fragment>
          <CSSTransition
            nodeRef={tooltipRef}
            aria-label="tooltip"
            in={isVisible}
            timeout={{
              enter: 200,
              exit: 100,
            }}
            classNames="fade"
            unmountOnExit
          >
            <div
              ref={tooltipRef}
              style={{
                top,
                left,
              }}
              onMouseEnter={onMouseIn}
              onMouseLeave={onMouseOut}
              className={[styles.tooltip, styles[position]].join(' ')}
            >
              <div
                className={[
                  styles.tooltipContent,
                  !!hasArrow && styles.arrow,
                  styles[type],
                ].join(' ')}
              >
                {children}
              </div>
            </div>
          </CSSTransition>
        </Fragment>,
        document.body,
      )}
    </Fragment>
  )
}

export default Tooltip
