import { ReactComponent as EmptyBattery } from 'utilities/central/svg/battery/emptyBattery.svg'
import { ReactComponent as OneThirdBattery } from 'utilities/central/svg/battery/oneThirdBattery.svg'
import { ReactComponent as TwoThirdBattery } from 'utilities/central/svg/battery/twoThirdBattery.svg'
import { ReactComponent as FullBattery } from 'utilities/central/svg/battery/fullBattery.svg'
import { ReactComponent as ConnectedBattery } from 'utilities/central/svg/battery/connectedBattery.svg'

export const getBatteryIcon = (
  level: number,
  isConnected?: boolean,
): JSX.Element => {
  if (isConnected) return <ConnectedBattery aria-label="connected-battery" />

  if (level < 25) return <EmptyBattery aria-label="empty-battery" />

  if (level < 50) return <OneThirdBattery aria-label="oneThird-battery" />

  if (level < 75) return <TwoThirdBattery aria-label="twoThird-battery" />

  return <FullBattery aria-label="full-battery" />
}
