import { useMemo } from 'react'

import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg'

import styles from './Actions.module.scss'
import { useFilters, useMap, useDisplacementModal } from '../../contexts'
import { useFetchQuickAlerts } from 'services/displacementMap'
import { Icon } from 'components'
import { useUserInfo } from 'shared/hooks'

export const Actions = () => {
  const { userInfo } = useUserInfo()

  const userId = userInfo.id
  const { handleUnfocusedRoute, handleCentralization, selectedRoute } = useMap()
  const { filters } = useFilters()
  const { data: quickAlerts } = useFetchQuickAlerts(userId)
  const { modal, handleOpenModal } = useDisplacementModal()

  const quickAlertOnAttendant = useMemo(
    () => quickAlerts?.find((alert) => alert.user.id === userId)?.title,
    [quickAlerts, userId],
  )

  const handleFilterCount = () => {
    let count = 0

    for (const item in filters) {
      const key = item as keyof typeof filters

      if (typeof filters[key] === 'string' && !!filters[key]) {
        count++
      } else {
        count += filters[key]?.length ?? 0
      }
    }

    return count || ''
  }

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        <button
          type="button"
          className={styles.alertButton}
          onClick={() => {
            handleUnfocusedRoute('occurrence')
            handleUnfocusedRoute('tactical')
            handleOpenModal('QUICK_ALERT')
          }}
        >
          {quickAlertOnAttendant || quickAlerts?.length}
          <Icon name="warning" />
        </button>
        <button
          type="button"
          disabled={!!modal}
          onClick={() => handleOpenModal('FILTERS')}
        >
          {handleFilterCount()}
          <FilterIcon />
        </button>
        <button
          type="button"
          disabled={!!modal}
          onClick={() => handleOpenModal('OPTIONS')}
        >
          <Icon name="menu-kebab" />
        </button>
      </div>
      <div className={styles.centralization}>
        <button
          type="button"
          onClick={handleCentralization}
          disabled={
            selectedRoute.occurrence?.focused || selectedRoute.tactical?.focused
          }
        >
          <Icon name="fotossensor" width={20} height={20} />
        </button>
      </div>
    </div>
  )
}
