import { forwardRef } from 'react'
import styles from './RadioButton.module.scss'

interface RadioButtonProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  checked: boolean
  value: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  id?: string
}

export const RadioButton = forwardRef<HTMLButtonElement, RadioButtonProps>(
  ({ checked, value, onChange, name, id, disabled }, _ref) => {
    return (
      <div className={styles.inputContainer}>
        <label
          className={[
            styles.text,
            styles.label,
            disabled && styles.disabled,
          ].join(' ')}
        >
          <input
            id={id}
            type="radio"
            name={name}
            value={value}
            className={styles.input}
            onChange={onChange}
            checked={checked}
            disabled={disabled}
          />
          {value}
        </label>
      </div>
    )
  },
)

RadioButton.displayName = 'RadioButton'
