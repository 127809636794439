import {
  FC,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  Fragment,
  ReactNode,
} from 'react'
import Pagination from 'components/Pagination'
import { Pagination as IPagination, Result } from 'services/types'
import { useTable, Column } from 'react-table'

import { ReactComponent as MenuIcon } from '../../assets/svg/menuIcon.svg'
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg'

import classes from './Table.module.scss'
import { useToggle, useTooltipVisibility } from '../../shared/hooks'
import { dateNow, formatDecimal } from 'utilities/date'
import { RowAction } from './components/RowActions'
import { Droplist } from './components/Droplist'
import { useQueryParams } from '../../routes/hooks/useQueryParams'
import Tooltip from 'components/Tooltip/Tooltip'
import Loader from './components/Loader'

type TableEmptyStateProps = {
  title: string
  message: string
  Icon: FC
}

function TableEmptyState({ Icon, message, title }: TableEmptyStateProps) {
  return (
    <tr className={classes.emptyStateRow}>
      <td>
        <div className={classes.emptyStateWrapper}>
          <div className={classes.emptyStateIconWrapper}>
            <Icon />
          </div>
          <h1 className={classes.emptyStateTitle}>{title}</h1>
          <p className={classes.emptyStateMessage}>{message}</p>
        </div>
      </td>
    </tr>
  )
}

function Actions<T extends { id: string }>({
  actions,
  row,
  onActionClick,
}: {
  actions: RowAction<T>[]
  row: T
  onActionClick?: (id: string) => void
}) {
  const dropList = useToggle()
  const [actionPosition, setActionPosition] = useState({
    bottom: 0,
    right: 0,
    top: 0,
  })

  const visibleActions = actions.filter((action) => {
    const { isVisible } = action
    return isVisible ? isVisible(row) : true
  })

  const visibleActionsIcon = () => {
    if (Object.keys(visibleActions).length < 1) {
      return null
    }

    if (Object.keys(visibleActions).length === 1) {
      return (
        <RowAction
          item={row}
          actions={visibleActions}
          hasMoreThanOneAction={false}
        />
      )
    }
    if (Object.keys(visibleActions).length > 1) {
      return (
        <>
          <button
            id={row.id}
            data-testid="list-view-more-button"
            onClick={(e) => {
              const { bottom, top, right } =
                e.currentTarget.getBoundingClientRect()
              setActionPosition({ bottom, top, right })
              if (onActionClick) {
                onActionClick(row.id)
              }
              dropList.show()
            }}
            className={[
              classes.rowSideButton,
              classes.viewMoreButton,
              dropList.isVisible && classes.disabledButton,
            ].join(' ')}
          >
            <MenuIcon />
          </button>
          <Droplist
            item={row}
            isVisible={dropList.isVisible}
            onClose={dropList.hide}
            actions={visibleActions}
            actionPosition={actionPosition}
          />
        </>
      )
    }
  }

  return (
    <>
      <td className={classes.actionButtonsTableData}>{visibleActionsIcon()}</td>
    </>
  )
}

export interface TableRef {
  handleFetchPage(page?: number): void
}

interface TableProps<T extends { id: string }> {
  columns: Column<T>[]
  fetcher: (pagination: IPagination) => Promise<Result<T>>
  paginated?: boolean
  recordsPerPage?: number
  initialPage?: number
  actions?: ((item: T) => RowAction<T>[]) | RowAction<T>[]
  isRowDisabled?: (item: T) => boolean
  innerRef?: Ref<TableRef>
  emptyState?: TableEmptyStateProps
  resetPagination: boolean
  onActionClick?: (id: string) => void
  section?: {
    key: keyof T
    render: (item: T) => React.ReactNode
  }
}

const DefaultEmptyStateProps: TableEmptyStateProps = {
  Icon: SearchIcon,
  title: 'Sem resultados',
  message: 'Nenhum registro foi encontrado nesta seção.',
}

const TableCellContentWrapper: FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const { ref, isTooltipVisible, handleMouseEnter, handleMouseLeave } =
    useTooltipVisibility()

  return (
    <>
      <td
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </td>

      {isTooltipVisible && (
        <Tooltip
          type="informative"
          isVisible={isTooltipVisible}
          parentRef={ref}
        >
          {children}
        </Tooltip>
      )}
    </>
  )
}

export function Table<T extends { id: string }>({
  columns,
  fetcher,
  recordsPerPage = 15,
  initialPage = 1,
  paginated,
  actions,
  isRowDisabled,
  innerRef,
  emptyState = DefaultEmptyStateProps,
  resetPagination,
  section,
  onActionClick,
}: TableProps<T>): JSX.Element {
  const [data, setData] = useState<T[]>([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [currentPage, setCurrentPage] = useState(initialPage)
  const [searchedDate, setSearchedDate] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>()

  const listRef = useRef<HTMLTableSectionElement>(null)

  const paginationInfo = useMemo(() => {
    const from = (currentPage - 1) * recordsPerPage + 1
    const finalIndex = from + recordsPerPage - 1
    const to = finalIndex < totalElements ? finalIndex : totalElements

    return {
      from: formatDecimal(from),
      to: formatDecimal(to),
      total: formatDecimal(totalElements),
    }
  }, [currentPage, totalElements, recordsPerPage])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>({
      columns,
      data,
    })

  const handleFetchPage = useCallback(
    (page: number = currentPage) => {
      setIsLoading(true)
      setCurrentPage(resetPagination ? 1 : page)
      fetcher(
        resetPagination
          ? {
              recordsPerPage,
              offset: 0,
            }
          : {
              recordsPerPage,
              offset: (page - 1) * recordsPerPage,
            },
      )
        .then((res) => {
          setTotalPages(Math.ceil(res.totalElements / recordsPerPage))
          setTotalElements(res.totalElements)
          setData(res.data)
          setSearchedDate(dateNow())
          listRef?.current?.scroll?.(0, 0)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [fetcher, recordsPerPage, currentPage, resetPagination],
  )

  useImperativeHandle(innerRef, () => ({ handleFetchPage }))

  useQueryParams(handleFetchPage)

  return (
    <div className={classes.wrapper}>
      <div className={classes.scroll}>
        <table
          className={classes.table}
          {...getTableProps()}
          data-testid="react-table"
        >
          <thead className={classes.header}>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
                {!!actions && <th className={classes.actionButtonsTableData} />}
              </tr>
            ))}
          </thead>

          <tbody ref={listRef} {...getTableBodyProps()}>
            {isLoading ? (
              <tr className={classes.loadingRow}>
                <td>
                  <div className={classes.loaderComponent}>
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {rows.map((row, index) => {
                  prepareRow(row)

                  let shouldRenderSection = false
                  if (section) {
                    const key = section.key

                    const previousRow = index ? rows[index - 1] : undefined

                    const previousGroup = previousRow?.original[key]
                    const currentGroup = row.original[key]

                    shouldRenderSection = currentGroup !== previousGroup
                  }

                  return (
                    <Fragment key={row.id}>
                      {shouldRenderSection && (
                        <tr className={classes.row}>
                          <td colSpan={columns.length + (actions ? 1 : 0)}>
                            {section?.render(row.original)}
                          </td>
                        </tr>
                      )}
                      <tr
                        data-testid="react-table-row"
                        className={[
                          classes.row,
                          classes.spacing,
                          isRowDisabled?.(row.original)
                            ? classes.inactiveRow
                            : '',
                        ].join(' ')}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => (
                          <TableCellContentWrapper key={cell.column.id}>
                            {cell.render('Cell')}
                          </TableCellContentWrapper>
                        ))}
                        {actions && (
                          <Actions
                            actions={
                              typeof actions === 'function'
                                ? actions(row.original)
                                : actions
                            }
                            row={row.original}
                            onActionClick={onActionClick}
                          />
                        )}
                      </tr>
                    </Fragment>
                  )
                })}
                {!data.length && <TableEmptyState {...emptyState} />}
              </>
            )}
          </tbody>
        </table>
      </div>

      {(paginated || !!section) && data.length ? (
        <div
          className={[
            classes.containerFooter,
            !!section && classes.flexStart,
          ].join(' ')}
        >
          {paginated && (
            <>
              <div className={classes.footerPart}>
                <p className={classes.tableInfos}>
                  Mostrando resultados {paginationInfo.from}-{paginationInfo.to}{' '}
                  de {paginationInfo.total}
                </p>
                <p
                  className={[classes.tableInfos, classes.tableSmall].join(' ')}
                >
                  Consulta realizada em {searchedDate}
                </p>
              </div>
              <div
                className={[classes.footerPart, classes.footerPartCenter].join(
                  ' ',
                )}
              >
                <Pagination totalPages={totalPages} />
              </div>
              <div className={classes.footerPart} />
            </>
          )}

          {!!section && (
            <>
              <div className={classes.footerPart}>
                <p className={classes.tableInfos}>
                  {paginationInfo.total} registros encontrados
                </p>
                <p
                  className={[classes.tableInfos, classes.tableSmall].join(' ')}
                >
                  Consulta realizada em {searchedDate}
                </p>
              </div>
              <div className={classes.footerPart} />
            </>
          )}
        </div>
      ) : null}
    </div>
  )
}
