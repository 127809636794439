import React from 'react'
import { ReactComponent as WiredConnetion } from 'assets/svg/wiredConnetion.svg'
import { ReactComponent as GPRSConnection } from 'assets/svg/gprsConnection.svg'
import { ReactComponent as Wifi } from 'assets/svg/smallWifi.svg'
import { ReactComponent as PowerStatusIcon } from 'assets/svg/powerStatus.svg'
import { ReactComponent as Gear } from 'assets/svg/gear.svg'
import { ReactComponent as PictureIcon } from 'assets/svg/picture.svg'
import { ReactComponent as EmptyStateImage } from 'assets/svg/emptyStateImage.svg'
import { ReactComponent as AvailableIcon } from 'assets/svg/exclamationMessageBlue.svg'

import {
  ConnectionIPMode,
  PowerStatus,
  CHANGE_TYPE_EN_PT,
  Central,
  EquipmentsModel,
} from 'services/serviceOrder/types'

import { Button, Icon, Popover, ProtectedImage } from 'components'

import styles from './CentralInformation.module.scss'
import ImageUploader from './components/ImageUploader'
import { useToggle } from 'shared/hooks'
import { ServiceOrderImagePayload } from 'domains/serviceOrders/types'
import { ConnectionRichTooltip } from 'domains/serviceOrders/components'

export type CentralInformationProps = {
  central: Central
  model?: EquipmentsModel
  onPressGallery?: () => void
  serviceOrder: ServiceOrderImagePayload
}

export function CentralInformation({
  central,
  model,
  onPressGallery,
  serviceOrder,
}: CentralInformationProps): JSX.Element {
  const getSignalForceClassName = (signalForce: number): string => {
    let className = ''
    if (signalForce > 0 && signalForce <= 40) {
      className = styles.connectionStatusWeak
    }
    if (signalForce > 40 && signalForce <= 60) {
      className = styles.connectionStatusMedium
    }
    if (signalForce > 60 && signalForce <= 100) {
      className = styles.connectionStatusHigh
    }
    return className
  }

  const toggleImageUploader = useToggle()

  return (
    <>
      <ImageUploader
        isVisible={toggleImageUploader.isVisible}
        onClose={() => toggleImageUploader.hide()}
        serviceOrder={serviceOrder}
      />
      <div className={styles.centralHeader}>
        <h1 className={styles.title}>Dados da central</h1>
        {central.changeType && (
          <div
            className={[
              styles.tag,
              styles[`${central.changeType.toLowerCase()}Tag`],
            ].join(' ')}
          >
            {CHANGE_TYPE_EN_PT[central.changeType]}
          </div>
        )}
        {Boolean(central.eventCount) && (
          <div className={styles.eventCount}>
            {central.eventCount}
            <AvailableIcon className={styles.eventCountIcon} />
          </div>
        )}
      </div>
      <section className={styles.sectionImage}>
        {central.centralImage ? (
          <ProtectedImage
            className={styles.centralImage}
            imageId={central.centralImage.id}
            alt="central"
          />
        ) : (
          <EmptyStateImage className={styles.centralImage} />
        )}

        <button
          className={styles.hoverImage}
          onClick={onPressGallery}
          disabled={!central.centralImage}
          aria-label="button-modal"
        />
      </section>

      {onPressGallery && (
        <div className={styles.photoButtonsWrapper}>
          <Button
            width="100px"
            icon={PictureIcon}
            buttonTitle="Galeria"
            type="tertiary"
            onClick={onPressGallery}
          />
          <Button
            width="100px"
            buttonTitle="Adicionar foto"
            type="tertiary"
            className={styles.iconButton}
            onClick={() => toggleImageUploader.show()}
          >
            <Icon name="gallery-add" />
          </Button>
        </div>
      )}
      <section className={styles.infoItems}>
        <div
          className={[
            styles.wrapper,
            onPressGallery && styles.withGalleryIcon,
          ].join(' ')}
        >
          <div className={styles.item} data-testid="ethernet">
            <WiredConnetion className={styles.icons} />
            <div className={styles.itemInfo}>
              <small>Rede com fio</small>
              <span>
                {central.ethernet.connectionStatus
                  ? `${
                      central.ethernet.ipMode === ConnectionIPMode.DHCP
                        ? 'DHCP'
                        : 'Estático'
                    } ${central.ethernet.ip}`
                  : 'Desconectada'}
              </span>
            </div>
            <div
              className={
                central.ethernet.connectionStatus
                  ? styles.connectionStatusOn
                  : ''
              }
              data-testid="ethernet-connection-force"
            />
          </div>

          <div className={styles.item} data-testid="wifi">
            <Wifi className={styles.icons} />
            <div className={styles.itemInfo}>
              <small>Wi-fi</small>
              <span>
                {central.wifi.connectionStatus
                  ? `${
                      central.wifi.ipMode === ConnectionIPMode.DHCP
                        ? 'DHCP'
                        : 'Estático'
                    } ${central.wifi.ip}`
                  : 'Desconectado'}
              </span>
            </div>

            <div
              className={getSignalForceClassName(central.wifi.signal)}
              data-testid="wifi-connection-force"
            />
          </div>

          <Popover.Root>
            <Popover.Trigger asChild>
              <div className={styles.item} data-testid="gprs">
                <GPRSConnection className={styles.icons} />
                <div className={styles.itemInfo}>
                  <small>3G/4G</small>
                  <span
                    className={
                      central.gprs.connectionStatus
                        ? styles.gprsTelecomOn
                        : styles.gprsTelecomOff
                    }
                    title={central.gprs.iccid}
                  >
                    {central.gprs.connectionStatus
                      ? central.gprs.telecom
                      : 'Sem sinal'}
                  </span>
                </div>
                {central.gprs.connectionStatus ? (
                  <div
                    className={getSignalForceClassName(central.gprs.signal)}
                  />
                ) : null}
              </div>
            </Popover.Trigger>
            <Popover.Content position="top">
              <ConnectionRichTooltip
                ICCID={central.gprs.iccid}
                phone={central.gprs.moduleNumber}
              />
            </Popover.Content>
          </Popover.Root>

          <div className={styles.item}>
            <PowerStatusIcon className={styles.icons} />
            <div className={styles.itemInfo}>
              <small>Energia</small>
              <span
                className={
                  central.powerStatus === PowerStatus.ON
                    ? styles.energyPowerStatusOn
                    : styles.energyPowerStatusOff
                }
                data-testid="power-status-connection-force"
              >
                {central.powerStatus === PowerStatus.ON
                  ? 'Conectada'
                  : 'Desconectada'}
              </span>
            </div>
          </div>

          <div className={styles.item}>
            <Gear className={styles.icons} />
            <div className={styles.itemInfo}>
              {model ? (
                <>
                  <small>Modelo</small>
                  <span>{model.name}</span>
                </>
              ) : (
                <>
                  <small>Firmware</small>
                  <span>{`Versão ${central.firmwareVersionName}`}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
