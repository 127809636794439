import { ReactComponent as BlockedIcon } from 'assets/svg/blocked.svg'
import { ReactComponent as shockSensorIcon } from 'assets/svg/shockSensor.svg'

import { Button, ProtectedButton } from 'components'

import { EquipmentPhoto } from 'domains/customer/screens/Equipments/components/EquipmentPhoto/EquipmentPhoto'
import { DeviceEnabled } from 'domains/customer/screens/Equipments/components/DeviceEnabled/DeviceEnabled'

import styles from './ReedSwitchCard.module.scss'
import { DeviceUpdate } from 'domains/customer/screens/Equipments/components/DeviceUpdate/DeviceUpdate'
import { useToggle } from 'shared/hooks'
import { DeviceShockSensor } from 'domains/customer/screens/Equipments/components/DeviceShockSensor/DeviceShockSensor'
import { DeviceCardFooter } from 'domains/customer/screens/Equipments/components/DeviceCardFooter/DeviceCardFooter'
import { CentralFragment } from 'domains/customer/screens/Equipments/types'
import { DeviceCardHeader } from 'domains/customer/screens/Equipments/components/DeviceCardHeader/DeviceCardHeader'
import { UbideskPermissions } from 'routes/types'
import { CENTRAL_MODE } from 'services/central/types'
import { PartitionDeviceAggregatedResponse } from 'services/device/types'

interface ReedSwitchCardProps {
  reedswitch: PartitionDeviceAggregatedResponse
  central: CentralFragment
}

export const ReedSwitchCard = (props: ReedSwitchCardProps) => {
  const { reedswitch, central } = props

  const editModal = useToggle()
  const deviceAnnulModal = useToggle()
  const shockConfigModal = useToggle()

  return (
    <div className={styles.cardContainer} aria-label="equipment-card-details">
      <DeviceShockSensor
        isVisible={shockConfigModal.isVisible}
        reedswitch={reedswitch}
        centralId={central.id}
        onClose={shockConfigModal.hide}
      />
      <DeviceUpdate
        device={{
          id: reedswitch.id,
          name: reedswitch.name,
          partitions: reedswitch.partitions,
        }}
        central={central}
        onClose={editModal.hide}
        isVisible={editModal.isVisible}
      />
      <DeviceEnabled
        isVisible={deviceAnnulModal.isVisible}
        onClose={deviceAnnulModal.hide}
        device={{
          id: reedswitch.id,
          hiddenZone: reedswitch.hiddenZone,
          hiddenZoneTo: reedswitch.hiddenZoneTo,
          hasCommandSupport: reedswitch.hasCommandSupport,
        }}
        centralId={central.id}
      />
      <div className={styles.header}>
        <DeviceCardHeader
          deviceId={reedswitch.id}
          hiddenZone={reedswitch.hiddenZone}
          hiddenZoneTo={reedswitch.hiddenZoneTo}
          tamper={reedswitch.isTamperOpened}
          type={reedswitch.typeCode}
          title={reedswitch.name}
          hasCommandSupport={reedswitch.hasCommandSupport}
          onClick={editModal.show}
        />
        <div className={styles.cardButtonOptions}>
          <ProtectedButton
            permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
            id="block-equipment-button"
            buttonTitle={
              reedswitch.hiddenZone || reedswitch.hiddenZoneTo
                ? 'Reativar equipamento'
                : 'Anular equipamento'
            }
            type="secondary"
            icon={BlockedIcon}
            width="184px"
            onClick={deviceAnnulModal.show}
          />
          <Button
            id="adjust-shock-sensor-button"
            buttonTitle="Ajustar sensor shock"
            type="secondary"
            className={styles.iconButton}
            icon={shockSensorIcon}
            width="184px"
            onClick={shockConfigModal.show}
            disabled={central.mode !== CENTRAL_MODE.MAINTENANCE}
          />
        </div>
      </div>
      <EquipmentPhoto
        imageId={reedswitch.installationImageId}
        alt="sensor de abertura"
      />
      <div className={styles.footer}>
        <DeviceCardFooter device={reedswitch} />
      </div>
    </div>
  )
}
