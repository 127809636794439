import { FC, Fragment, useState } from 'react'

import { HistoryIntervention } from 'services/attendance/types'
import { ProtectedImage } from 'components'
import { maskedDateTime } from 'utilities/date'
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow.svg'

import { Form } from './components'
import styles from './styles.module.scss'
import { handleInterventionIcon, handleStatusIcon } from './utils'
import { isInterventionExpandable } from 'domains/occurrence/screens/Attendance/utils'

interface InterventionCardProps {
  intervention: HistoryIntervention
  onOpenGallery: (imageId: string) => void
}

const InterventionCard: FC<InterventionCardProps> = ({
  intervention,
  onOpenGallery,
}) => {
  const [expanded, setExpanded] = useState(false)

  const isExpandable = isInterventionExpandable(intervention)

  const image: string | undefined =
    intervention.attributes?.imageId || intervention.attributes?.imageIds?.[0]

  const mustHaveForm =
    intervention.typeName !== 'STATE_CHANGE' &&
    intervention.typeName !== 'REFUSE_PHONE_CALL_REQUIREMENT'

  const handleExpansion = () => {
    setExpanded((old) => !old)
  }

  return (
    <li className={styles.container}>
      <div onClick={() => isExpandable && handleExpansion()}>
        <div className={styles[intervention.attributes?.status]}>
          {handleInterventionIcon(intervention)}
          <div>
            <h4>
              {intervention.title} {handleStatusIcon(intervention)}
            </h4>
            <span>
              <strong>{intervention.user?.name}</strong>
              {maskedDateTime(intervention.createdAt)}
            </span>
          </div>
        </div>
        <div className={styles.iconBox}>
          {image && (
            <ProtectedImage
              imageId={image}
              onClick={() => onOpenGallery(image)}
            />
          )}
          {isExpandable && (
            <button type="button" className={styles.arrowDown}>
              <ArrowDownIcon />
            </button>
          )}
        </div>
      </div>
      {expanded && (
        <div className={styles.expanded}>
          <ul className={styles.notes}>
            {intervention.notes
              .sort((a, b) => a.createdAt - b.createdAt)
              .map((note) => (
                <li
                  key={note.createdAt}
                  className={[
                    (note.note.length > 450 ||
                      Array.from(note.note).find((note) => note === '\n')) &&
                      styles.notesExpanded,
                  ]
                    .filter(Boolean)
                    .join('')}
                >
                  <span className={styles.title}>
                    <strong>{note.user.name}</strong>
                    <span>{maskedDateTime(note.createdAt)}</span>
                  </span>
                  <p className={styles.note}>
                    {note.note.split(/(\n)/g).map((part, index) =>
                      part === '\n' ? (
                        <Fragment key={index}>
                          {part}
                          <br />
                        </Fragment>
                      ) : (
                        part
                      ),
                    )}
                  </p>
                </li>
              ))}
          </ul>
          {mustHaveForm && <Form intervention={intervention} />}
        </div>
      )}
    </li>
  )
}

export default InterventionCard
