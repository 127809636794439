import {
  Children,
  ReactElement,
  cloneElement,
  useCallback,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'

import { useOnClickOutside } from 'shared/hooks/useOnClickOutside'

import styles from './DropDown.module.scss'
import { useElementPosition } from 'shared/hooks/useElementPosition'

type DropDownProps = {
  children: ReactElement | ReactElement[]
  trigger: ReactElement
}

export const DropDown = ({ children, trigger }: DropDownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const parentRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)

  const { top, left } = useElementPosition({
    enabled: isDropdownOpen,
    positionOnEdge: true,
    actionTriggerRef: parentRef,
    elementRef: dropDownRef,
  })

  const handleOpen = useCallback(() => {
    setIsDropdownOpen((state) => !state)
  }, [])

  useOnClickOutside(dropDownRef, handleOpen)

  return (
    <div>
      <div ref={parentRef}>
        {cloneElement(trigger, {
          onClick: (e: MouseEvent) => {
            handleOpen()
            e.stopPropagation()
          },
        })}
      </div>
      {ReactDOM.createPortal(
        <>
          {isDropdownOpen && (
            <div
              aria-label="actions-container"
              style={{
                top,
                left,
              }}
              className={styles.dropDown}
              ref={dropDownRef}
            >
              <ul>
                {Children.map(children, (child, index) => (
                  <li className={styles.dropDownItem} key={index}>
                    {cloneElement(child)}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>,
        document.body,
      )}
    </div>
  )
}
