import { ComponentProps, forwardRef } from 'react'
import styles from './styles.module.scss'

interface TextareaProps extends ComponentProps<'textarea'> {
  label?: string
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, className, ...props }, ref) => (
    <div className={[styles.container, className].join(' ')}>
      {label && <label>{label}</label>}
      <textarea {...props} ref={ref} />
    </div>
  ),
)

Textarea.displayName = 'Textarea'

export default Textarea
