import { AxiosInstance } from 'axios'
import HttpClient from '../httpClient'

import { Result } from '../types'

import {
  PatrimonyFragment,
  PatrimonyWithAccount,
  PartitionListResponse,
  PatrimonyWithAccountRequest,
  PatrimonyQuery,
  UpdatePatrimony,
} from './types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
export interface PatrimonyApiClient {
  findPatrimony(patrimonyId: string): Promise<PatrimonyWithAccount>
  queryPatrimoniesFragmentList(
    customerId: string,
    query?: PatrimonyQuery,
  ): Promise<Result<PatrimonyFragment>>
  updatePatrimony(
    patrimonyId: string,
    patrimony: PatrimonyWithAccountRequest | undefined,
  ): Promise<void>
  getPartitionsList(patrimonyId: string): Promise<Result<PartitionListResponse>>
}

class PatrimonyApiClientImpl implements PatrimonyApiClient {
  public constructor(private readonly httClient: AxiosInstance = HttpClient) {}

  async findPatrimony(patrimonyId: string): Promise<PatrimonyWithAccount> {
    const response = await this.httClient.get<PatrimonyWithAccount>(
      `/aggregation/patrimonyWithAccount/${patrimonyId}`,
    )
    return response.data
  }

  async queryPatrimoniesFragmentList(
    customerId: string,
    query?: PatrimonyQuery,
  ): Promise<Result<PatrimonyFragment>> {
    const response = await this.httClient.get<Result<PatrimonyFragment>>(
      `/aggregation/customer/${customerId}/patrimonyWithAccount/list`,
      {
        params: query,
      },
    )
    return response.data
  }

  async updatePatrimony(
    patrimonyId: string,
    patrimony: PatrimonyWithAccountRequest | undefined,
  ): Promise<void> {
    return await this.httClient.put(
      `/aggregation/patrimonyWithAccount/${patrimonyId}`,
      patrimony,
    )
  }

  async getPartitionsList(
    patrimonyId: string,
  ): Promise<Result<PartitionListResponse>> {
    const result = await this.httClient.get<Result<PartitionListResponse>>(
      `/patrimony/${patrimonyId}/partition/list`,
    )
    return result.data
  }
}

export const useUpdateCoordinates = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      patrimonyId,
      ...payload
    }: Partial<UpdatePatrimony>) => {
      if (patrimonyId) {
        const response = await HttpClient.put<UpdatePatrimony>(
          `/patrimony/${patrimonyId}/updateCoordinates`,
          { ...payload },
        )

        return response.data
      }
    },
    mutationKey: ['update-patrimony'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetch-occurrence'] })
    },
  })
}

export const PatrimonyDriver = new PatrimonyApiClientImpl()
