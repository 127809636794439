import dayjs from 'dayjs'
import { SERVICE_ORDER_SCHEDULE_PERIOD_ENUM } from 'services/serviceOrder'

enum DAY_SHIFT_PT_BR {
  'MORNING' = 'Manhã',
  'AFTERNOON' = 'Tarde',
  'BUSINESS_HOURS' = 'Horário comercial',
}

export const formatServiceOrderScheduleDate = ({
  scheduleDate,
  schedulePeriod,
}: {
  scheduleDate: number
  schedulePeriod: SERVICE_ORDER_SCHEDULE_PERIOD_ENUM | undefined
}): string => {
  const date = dayjs(scheduleDate)

  if (schedulePeriod) {
    return `${date.format('DD/MM/YY')} - ${DAY_SHIFT_PT_BR[schedulePeriod]}`
  }

  return `${date.format('DD/MM/YY')} às ${date.format('HH:mm')}`
}
