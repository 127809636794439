import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useToast, useUserInfo } from 'shared/hooks'

import { usePostConfigureSirenCommand } from 'shared/hooks/services/commands/usePostConfigureSirenCommand'
import { UpdateSirenVolumeSchema } from 'domains/customer/screens/Equipments/schemas/devices/updateSirenVolume'

export const usePostAndHandleConfigureSirenCommand = (
  centralId: string,
  payload: UpdateSirenVolumeSchema,
) => {
  const { addToast } = useToast()
  const queryClient = useQueryClient()
  const { userInfo } = useUserInfo()

  const { mutateConfigureSirenCommand, configureSirenCommandMutationStatus } =
    usePostConfigureSirenCommand(centralId, {
      ...payload,
      userId: userInfo.id,
    })

  useEffect(() => {
    if (configureSirenCommandMutationStatus === 'success') {
      addToast({
        message: 'Volume ajustado com sucesso.',
      })

      queryClient.invalidateQueries({ queryKey: ['equipments'] })
    }

    if (configureSirenCommandMutationStatus === 'error') {
      addToast({
        message: 'Erro ao ajustar volume. Tente novamente.',
        type: 'alert',
      })
    }
  }, [addToast, queryClient, configureSirenCommandMutationStatus])

  return {
    mutateConfigureSirenCommand,
    configureSirenCommandMutationStatus,
  }
}
