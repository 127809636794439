import { ChangeEvent, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { useFormContext } from 'react-hook-form'
import { ToggleHook } from 'shared/hooks'
import { Button, Combobox, ComboboxItem, Datepicker } from 'components'
import { ReactComponent as CloseButton } from 'assets/svg/close.svg'
import {
  EventFormType,
  EventHistoryFiltersSchema,
} from 'domains/customer/schemas/event/searchEventSchema'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import { Pagination, Result } from 'services/types'
import EventDriver from 'services/event'
import {
  OccurrenceTypeName,
  OccurrenceTypeQueryResponse,
} from 'services/occurrence/types'
import { parseDataToCombobox } from 'utilities/combobox'

import styles from './EventHistoryFilters.module.scss'
import {
  DeviceTypeCodeComboboxItem,
  equipmentOptions,
} from 'utilities/occurrence/data'

import OccurrenceService from 'services/occurrence'
import { OccurrenceTypeTitle } from 'domains/occurrence/data/occurrence'

interface EventHistoryFiltersProps extends ToggleHook {
  onApplyFilters: () => void
}

export const EventHistoryFilters = ({
  isVisible,
  hide,
  onApplyFilters,
}: EventHistoryFiltersProps) => {
  const { setValue, watch, reset } = useFormContext<EventHistoryFiltersSchema>()

  const event = watch('event')
  const type = watch('type')
  const equipment = watch('equipment')
  const createdFrom = watch('createdFrom')
  const createdTo = watch('createdTo')

  const handleChangeDateInput = useCallback(
    (formKey: string, event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        const date = convertStringToDate(value)
        setValue(formKey, date.getTime())
        return value
      } else {
        return value
      }
    },
    [setValue],
  )

  const handleFetchEventTypes = useCallback(
    async (pagination: Pagination, name?: string) => {
      const events = await EventDriver.queryEventTypes({
        ...(name && { name }),
        ...pagination,
      })

      const normalizedEvents: Result<EventFormType> = {
        data: events.data.map((event) => ({
          id: event.id,
          code: event.name,
          name: event.aggregatedCodeDescription,
        })),
        totalElements: events.totalElements,
      }

      return parseDataToCombobox<EventFormType>(normalizedEvents, 'name')
    },
    [],
  )

  const handleFetchOccurrenceType = useCallback(
    async (pagination: Pagination) => {
      const response = await OccurrenceService.queryOccurrenceType({
        ...pagination,
      })

      const normalizedOccurrenceTypeName = response.data.map(
        (occurrenceType) => {
          return {
            ...occurrenceType,
            name: OccurrenceTypeTitle[
              occurrenceType.name as OccurrenceTypeName
            ],
          }
        },
      )
      return parseDataToCombobox(
        {
          data: normalizedOccurrenceTypeName,
          totalElements: normalizedOccurrenceTypeName.length,
        },
        'name',
      )
    },
    [],
  )

  return ReactDOM.createPortal(
    <div
      className={[styles.container, isVisible ? styles.show : styles.hide]
        .filter(Boolean)
        .join(' ')}
      data-testid="filters-component"
    >
      <div className={styles.innerWrapper}>
        <div className={styles.header}>
          <span>Filtros</span>
          <CloseButton
            height={16}
            width={16}
            onClick={hide}
            className={styles.closeIcon}
            data-testid="filter-close-button"
          />
        </div>
        <div className={styles.contentWrapper}>
          <Combobox
            id="event-input"
            label="Evento"
            fetcher={handleFetchEventTypes}
            searchable
            value={
              event
                ? {
                    label: 'name',
                    value: {
                      id: event.id,
                      code: event.code,
                      name: event.name,
                    },
                  }
                : undefined
            }
            getSelected={(item) => {
              const selectedEvent = item as ComboboxItem<EventFormType>
              setValue('event', selectedEvent?.value)
            }}
          />
          <Combobox
            id="type-input"
            label="Tipo"
            fetcher={handleFetchOccurrenceType}
            value={
              type
                ? {
                    label: 'name',
                    value: type,
                  }
                : undefined
            }
            searchable
            getSelected={(item) => {
              const selectedOccurrenceType =
                item as ComboboxItem<OccurrenceTypeQueryResponse>
              setValue('type', selectedOccurrenceType?.value, {
                shouldValidate: true,
              })
            }}
          />
          <Combobox
            id="equipment-input"
            label="Equipamento"
            value={
              equipment
                ? {
                    label: 'name',
                    value: {
                      id: equipment.id,
                      code: equipment.code,
                      name: equipment.name,
                    },
                  }
                : undefined
            }
            items={equipmentOptions}
            searchable
            getSelected={(item) => {
              const selectedEquipment =
                item as ComboboxItem<DeviceTypeCodeComboboxItem>
              setValue('equipment', selectedEquipment?.value)
            }}
          />
          <div
            className={styles.datePickerWrapper}
            data-testid="create-date-filter-wrapper"
          >
            <p className={styles.sectionLabel}>Cadastro</p>
            <div className={styles.datePickerInnerWrapper}>
              <span className={styles.dateLabel}>De</span>
              <Datepicker
                id="createdFrom"
                initialValueInput={
                  createdFrom
                    ? convertDateToString(new Date(createdFrom))
                    : undefined
                }
                initialDate={createdFrom ? new Date(createdFrom) : undefined}
                onChangeDate={(date) =>
                  setValue('createdFrom', date?.getTime())
                }
                onChangeInput={(input) =>
                  handleChangeDateInput('createdFrom', input)
                }
              />
              <span className={styles.dateLabel}>até</span>
              <Datepicker
                id="createdTo"
                selectEnd
                disabled={!createdFrom}
                initialDate={createdFrom ? new Date(createdFrom) : undefined}
                initialValueInput={
                  createdTo
                    ? convertDateToString(new Date(createdTo))
                    : undefined
                }
                onChangeDate={(date) => setValue('createdTo', date?.getTime())}
                onChangeInput={(input) =>
                  handleChangeDateInput('createdTo', input)
                }
                errorMessage={
                  createdFrom && !createdTo ? 'Campo obrigatório.' : ''
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              reset({})
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            width="162px"
            onClick={onApplyFilters}
          />
        </div>
      </div>
    </div>,
    document.getElementById('main-container') || document.body,
  )
}
