import { ButtonGroup } from 'components'
import {
  ScaleWithDayWork,
  getRangeBetweenTimes,
  modifyTimeByMinutes,
} from '../../../../'

import { Combobox } from 'components/ComboboxV2/Combobox'

import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { ReactComponent as MinusIcon } from 'assets/svg/minus.svg'

import { PartitionStatus } from 'services/patrimony/types'

import { ScalePeriod } from 'services/officeHours/types'

import {
  DayOfWorkOptions,
  translatedScaleConfig,
} from 'domains/customer/utilities/dayOfWork'

import styles from './ScaleTimeConfig.module.scss'

export interface ScaleTimeConfigProps {
  dayOfWork: DayOfWorkOptions
  periods: ScalePeriod[]
  onChangeScale: (scales: ScaleWithDayWork) => void
}

export const ScaleTimeConfig = (props: ScaleTimeConfigProps) => {
  const { dayOfWork, periods, onChangeScale } = props

  return (
    <div className={styles.container} data-testid="scaleconfig">
      <div className={styles.dayWrapper}>
        <span>{`${translatedScaleConfig[dayOfWork]}`}</span>
      </div>

      <div className={styles.periodsWrapper}>
        {periods
          .sort(
            (a, b) =>
              new Date(`1970-01-01T${a.startTime}:00`).getTime() -
              new Date(`1970-01-01T${b.startTime}:00`).getTime(),
          )
          .map((period, index) => {
            const partitionArmed =
              periods[index].partitionStatus === PartitionStatus.ARMED

            const canNotAddNewPeriod = periods.some(
              (period) => !period.endTime || period.endTime === '23:59',
            )

            return (
              <div key={index} className={styles.periodContainer}>
                <div className={styles.customButtonGroupWrapper}>
                  <ButtonGroup
                    leftButton={{
                      id: 'client-button',
                      onClick: () => {
                        const updatedPeriod = [...periods]

                        updatedPeriod[index] = {
                          ...updatedPeriod[index],
                          partitionStatus: PartitionStatus.ARMED,
                        }

                        onChangeScale({
                          dayOfWork,
                          periods: updatedPeriod,
                        })
                      },
                      title: 'Armado',
                      selected: partitionArmed,
                    }}
                    rightButton={{
                      id: 'demo-button',
                      onClick: () => {
                        const updatedPeriod = [...periods]

                        updatedPeriod[index] = {
                          ...updatedPeriod[index],
                          partitionStatus: PartitionStatus.DISARMED,
                        }

                        onChangeScale({
                          dayOfWork,
                          periods: updatedPeriod,
                        })
                      },
                      title: 'Desarmado',
                      selected: !partitionArmed,
                    }}
                  />
                </div>
                <div className={[styles.hourWrapper].join(' ')}>
                  <span>De</span>
                  <Combobox
                    id={`start-time-${index}${dayOfWork}`}
                    placeholder="00:00"
                    className={styles.customCombobox}
                    items={getRangeBetweenTimes({
                      startTime: modifyTimeByMinutes(
                        periods[index - 1]?.endTime,
                      ),
                      endTime: modifyTimeByMinutes(period?.endTime),
                      startOffset: 0,
                    })}
                    value={period.startTime}
                    onChange={(value) => {
                      const selectedValue = value as string

                      const updatedPeriod = [...periods]

                      updatedPeriod[index] = {
                        ...period,
                        startTime: selectedValue,
                      }

                      onChangeScale({
                        dayOfWork,
                        periods: updatedPeriod,
                      })
                    }}
                  />

                  <span>às</span>
                  <Combobox
                    id={`end-time-${index}${dayOfWork}`}
                    placeholder="00:00"
                    className={styles.customCombobox}
                    items={getRangeBetweenTimes({
                      startTime: period.startTime,
                      endTime: periods[index + 1]?.startTime,
                      startOffset: 1,
                    })}
                    value={
                      period.endTime
                        ? modifyTimeByMinutes(period.endTime, +1)
                        : ''
                    }
                    disabled={!period.startTime}
                    onChange={(value) => {
                      const selectedValue = value as string

                      const updatedPeriod = [...periods]

                      updatedPeriod[index] = {
                        ...period,
                        endTime: modifyTimeByMinutes(selectedValue, -1),
                      }

                      onChangeScale({
                        dayOfWork,
                        periods: updatedPeriod,
                      })
                    }}
                  />

                  {index === 0 ? (
                    <button
                      type="button"
                      data-testid="add-period"
                      className={canNotAddNewPeriod ? styles.disabled : ''}
                      disabled={canNotAddNewPeriod}
                      onClick={() => {
                        onChangeScale({
                          dayOfWork,
                          periods: [
                            ...periods,
                            {
                              startTime: '',
                              endTime: '',
                              partitionStatus: period.partitionStatus,
                            },
                          ],
                        })
                      }}
                    >
                      <PlusIcon />
                    </button>
                  ) : (
                    <button
                      type="button"
                      data-testid="remove-period"
                      onClick={() => {
                        onChangeScale({
                          dayOfWork,
                          periods: periods.filter(
                            (_period, periodIndex) => periodIndex !== index,
                          ),
                        })
                      }}
                    >
                      <MinusIcon />
                    </button>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
