import Joi from '@hapi/joi'

import {
  PatrolAccount,
  PatrolFormData,
  Reason,
  Schedules,
} from 'domains/customer/screens/Patrol/components/PatrolForm/types'

import { dayOfWorkOptions } from 'domains/customer/utilities/dayOfWork'

const commonSchema = Joi.object({
  id: Joi.string().required(),
  name: Joi.string().required(),
})

const accountSchema = Joi.object<PatrolAccount>({
  id: Joi.string().required(),
  aggregatedAccountName: Joi.string().optional(),
  serviceTypeName: Joi.string().optional(),
  tags: Joi.array().items(Joi.string()).optional(),
  customer: commonSchema.optional(),
  patrimony: commonSchema.optional(),
})

const reasonSchema = Joi.object<Reason>({
  id: Joi.string().optional().allow('', null),
  message: Joi.string().allow('', null).optional().allow('', null),
  tags: Joi.array().items(Joi.string()),
})

const periodsSchema = Joi.object({
  hours: Joi.string().required(),
  minutes: Joi.string().required(),
})

const schedulesSchema = Joi.object<Schedules>({
  dayOfWork: Joi.string()
    .valid(...Object.values(dayOfWorkOptions))
    .required(),
  periods: Joi.array().items(periodsSchema).required(),
})

export const patrolSchema = Joi.object<PatrolFormData>({
  id: Joi.string().optional().allow('', null),
  active: Joi.boolean().optional().allow('', null),
  name: Joi.string().required(),
  account: accountSchema.required(),
  reason: reasonSchema,
  note: Joi.string().optional().allow('', null),
  startDate: Joi.number().required(),
  startHours: Joi.string().required(),
  endDate: Joi.number().optional().allow('', null),
  endHours: Joi.string().optional().allow('', null),
  schedules: Joi.array().items(schedulesSchema).min(1),
})
