import { useMutation } from '@tanstack/react-query'

import HttpClient from 'services/httpClient'
import { ServiceOrder, ServiceOrderPayload } from 'services/serviceOrderV2'

import { endpoints } from './endpoints'

function createServiceOrder(data: ServiceOrderPayload): Promise<ServiceOrder> {
  return HttpClient.post(endpoints.create, {
    note: data.note,
    userId: data.userId,
    status: data.status,
    contact: data.contact,
    accountId: data.account.id,
    serviceOrderType: data.type,
    scheduleDate: data.scheduleDate,
    technicianId: data.technician?.id,
    schedulePeriod: data.schedulePeriod,
    tags: data.tags?.map((tag) => ({ name: tag.name })),
    labels: data.labels?.map((label) => ({
      ...label,
      active: true,
      createdAt: undefined,
      updatedAt: undefined,
    })),
  })
}

export const usePostServiceOrder = () =>
  useMutation({
    mutationFn: async (data: ServiceOrderPayload) => {
      await createServiceOrder(data)
    },
  })
