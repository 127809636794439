import { useQuery } from '@tanstack/react-query'
import { AttendancePolicyDriver } from 'services/attendancePolicy'

const fetchAttendPolicy = async (attendancePolicyId: string) => {
  return AttendancePolicyDriver.findById(attendancePolicyId)
}

export const useGetAttendancePolicy = (
  attendancePolicyId: string,
  enabled?: boolean,
) => {
  const { data: attendancePolicy, isFetching } = useQuery({
    queryKey: ['attendancePolicy', attendancePolicyId],
    queryFn: () => fetchAttendPolicy(attendancePolicyId),
    enabled,
  })

  return {
    attendancePolicy,
    isFetching,
  }
}
