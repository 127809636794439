import {
  DevicesInformation,
  CentralInformation,
} from 'domains/serviceOrders/screens/ServiceOrderInfo/components'
import { PartitionDevice } from 'services/partition/types'
import { Central, EquipmentsModel } from 'services/serviceOrder'
import { ServiceOrderImagePayload } from 'domains/serviceOrders/types'

import styles from './styles.module.scss'

interface EquipmentsInfoProps {
  centralInfo: Central
  model?: EquipmentsModel
  onPressGallery?: () => void
  serviceOrder: ServiceOrderImagePayload
  handleSelect?: (device: PartitionDevice) => void
}

const EquipmentsInfo = ({
  handleSelect,
  centralInfo,
  onPressGallery,
  model,
  serviceOrder,
}: EquipmentsInfoProps) => (
  <div className={styles.wrapperEquipmentsValidation}>
    <section className={styles.centralInfo}>
      <CentralInformation
        central={centralInfo}
        model={model}
        onPressGallery={onPressGallery}
        serviceOrder={serviceOrder}
      />
    </section>
    <section className={styles.devicesInfo}>
      <DevicesInformation
        partitions={centralInfo.partitions}
        handleSelect={handleSelect}
      />
    </section>
  </div>
)

export default EquipmentsInfo
