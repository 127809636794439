import { useCallback, useState } from 'react'

import { Button, Modal } from 'components'
import { BackDrop } from '../../../../components'

import styles from './ChangeDisplacementStep.module.scss'
import { useCreateInterventation } from 'services/displacementMap'
import { useMap, useDisplacementModal } from '../../../../contexts'
import { InterventionTag } from 'domains/occurrence/data/interventionTag'
import { useUserInfo } from 'shared/hooks'

export const PutUnderObservation = () => {
  const { selectedRoute } = useMap()
  const { userInfo } = useUserInfo()
  const { handleCloseModal } = useDisplacementModal()
  const [isError, setIsError] = useState(false)
  const { mutate } = useCreateInterventation(selectedRoute.occurrence?.id)

  const handleSubmit = useCallback(() => {
    const userId = userInfo.id

    if (userId) {
      mutate(
        {
          title: 'Mudança de status para “Observação”',
          userId,
          tags: [{ name: InterventionTag.QUEUED }],
          typeName: 'STATE_CHANGE',
          attributes: {
            stateName: 'QUEUED',
          },
        },
        {
          onError: () => setIsError(true),
          onSuccess: handleCloseModal,
        },
      )
    }
  }, [handleCloseModal, mutate])

  return (
    <BackDrop onClose={handleCloseModal}>
      <Modal
        simple
        title="Colocar em observação"
        isVisible
        className={styles.container}
        onClose={handleCloseModal}
      >
        {isError ? (
          <>
            <p>Não foi possível colocar a ocorrência em observação</p>
            <Modal.Footer>
              <Button
                type="primary"
                buttonTitle="Voltar"
                onClick={handleCloseModal}
              />
            </Modal.Footer>
          </>
        ) : (
          <>
            <p>
              Você tem certeza que deseja adiar o atendimento desta ocorrência e
              colocá-la em observação
            </p>
            <Modal.Footer>
              <Button
                type="secondary"
                buttonTitle="Cancelar"
                onClick={handleCloseModal}
              />
              <Button
                type="primary"
                buttonTitle="Continuar"
                onClick={handleSubmit}
              />
            </Modal.Footer>
          </>
        )}
      </Modal>
    </BackDrop>
  )
}
