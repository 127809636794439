import { Schedule } from 'services/patrol/types'
import { DayOfWork } from 'services/types'

export const groupIntoBusinessDays = (scales: Schedule[]): Schedule[] => {
  const WORKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
  const NON_WORKDAYS = ['SUNDAY', 'SATURDAY', 'HOLIDAY']

  const workScales = scales.filter((scale) =>
    WORKDAYS.includes(scale.dayOfWork),
  )

  let allScalesAreTheSame = false
  if (workScales.length === WORKDAYS.length) {
    allScalesAreTheSame = workScales.every((scale) =>
      scale.hours
        ?.sort(
          (a, b) =>
            new Date(`1970-01-01T${a}`).getTime() -
            new Date(`1970-01-01T${b}`).getTime(),
        )
        .every((period, index) => {
          const basePeriod = workScales[0].hours[index]

          return period === basePeriod
        }),
    )
  }

  let groupedScalesToBusinessDays: Schedule
  if (allScalesAreTheSame) {
    groupedScalesToBusinessDays = {
      dayOfWork: 'WORKDAYS' as DayOfWork,
      hours: workScales[0].hours,
    }

    return [
      ...scales.filter((scale) => NON_WORKDAYS.includes(scale.dayOfWork)),
      groupedScalesToBusinessDays,
    ]
  }

  return [...scales.filter((scale) => scale)]
}
