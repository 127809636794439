import Joi from '@hapi/joi'

type Fragment = {
  id: string
  name: string
}

type Partition = {
  id: string
  code: string
}

const commonSchema = Joi.object<Fragment>({
  id: Joi.string().required(),
  name: Joi.string().required(),
})

const partitionSchema = Joi.object<Partition>({
  id: Joi.string().required(),
  code: Joi.string().required(),
})

export interface OccurrenceFiltersSchema {
  account: Fragment
  partition: Partition
  occurrenceType: Fragment
  createdFrom: number
  createdTo: number
}

export const occurrenceFiltersSchema = Joi.object<OccurrenceFiltersSchema>({
  account: commonSchema.optional(),
  partition: partitionSchema.optional(),
  occurrenceType: commonSchema.optional(),
  createdFrom: Joi.number().optional(),
  createdTo: Joi.number().optional(),
})
