import { useRef, SVGProps, FunctionComponent, FC } from 'react'
import { Tooltip } from 'components'
import { useToggle } from 'shared/hooks'

import styles from './IconWithTooltip.module.scss'

type IconWithTooltipProps = {
  text: string
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
}

export const IconWithTooltip: FC<IconWithTooltipProps> = ({ text, Icon }) => {
  const tooltip = useToggle()
  const iconRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div
        ref={iconRef}
        data-testid="icon-with-tooltip"
        onMouseEnter={tooltip.show}
        onMouseLeave={tooltip.hide}
        className={styles.container}
      >
        <Icon />
      </div>
      <Tooltip
        parentRef={iconRef}
        type="informative"
        isVisible={tooltip.isVisible}
      >
        {text}
      </Tooltip>
    </>
  )
}
