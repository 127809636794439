import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormContext, FieldError, Controller } from 'react-hook-form'

import {
  Vehicle,
  VEHICLE_CATEGORY_ENUM,
  VehicleAggregatedQueryResponse,
} from 'services/vehicle'
import { TrackerModelDriver, TrackerModel } from 'services/trackerModel'
import { Pagination, Result } from 'services/types'
import { Input, Button, Combobox, ComboboxItem } from 'components'
import { parseDataToCombobox } from 'utilities/combobox'
import { formatLicensePlate } from '../../utilities/masks'

import styles from './VehicleForm.module.scss'
import { ColorPicker } from '../ColorPicker/ColorPicker'

import { ReactComponent as OccurrenceMap } from '../../../../assets/svg/map.svg'
import { VehicleFormExitModal } from '../VehicleFormExitModal/VehicleFormExitModal'
import { useToggle } from 'shared/hooks'

type VehicleFormProps = {
  onSubmit: (vehicle: Vehicle) => void
  vehicle?: VehicleAggregatedQueryResponse
}

const parseNumber = (value: string) => {
  const number = value ? parseInt(value, 10) : undefined
  return Number.isNaN(number) ? undefined : number
}

export const VehicleForm = ({ onSubmit, vehicle }: VehicleFormProps) => {
  const navigate = useNavigate()
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext<Vehicle>()
  const [selectedTrackerModel, setSelectedTrackerModel] =
    useState<Pick<TrackerModel, 'id' | 'name'>>()

  const toggleModal = useToggle()

  const handleFetchTrackerModels = useCallback(
    async (pagination: Pagination, name?: string) => {
      const response: Result<Pick<TrackerModel, 'id' | 'name'>> =
        await TrackerModelDriver.query({
          ...(name && { name }),
          ...pagination,
        })
      return parseDataToCombobox(response, 'name')
    },
    [],
  )

  const onBeforeSubmit = useCallback(
    (data: Vehicle) => {
      if (!data.tracker?.model) {
        delete data.tracker
      }
      onSubmit(data)
    },
    [onSubmit],
  )

  useEffect(() => {
    register('licensePlate')
    register('vehicleCategory')
    register('model')
    register('productionYear')
    register('modelYear')
    register('tracker.model')
    register('tracker.serial')
    register('iconColor')
  }, [register])

  const tracker = watch('tracker')
  const vehicleColor = watch('iconColor')

  const tacticalIconRef = useRef<HTMLElement | null>(null)
  const occurrenceIconRef = useRef<HTMLElement | null>(null)

  const handleIconColor = useCallback(() => {
    tacticalIconRef.current?.setAttribute('fill', vehicleColor)
    occurrenceIconRef.current?.setAttribute('fill', vehicleColor)
  }, [vehicleColor])

  useEffect(() => {
    tacticalIconRef.current = document.querySelector(
      '[data-identification="tactical"]',
    )
    occurrenceIconRef.current = document.querySelector(
      '[data-identification="occurrence"]',
    )
  }, [vehicleColor])

  useEffect(() => handleIconColor(), [vehicleColor, handleIconColor])

  useEffect(() => {
    if (vehicle) {
      setValue('licensePlate', vehicle.licensePlate, {
        shouldValidate: true,
      })
      setValue('vehicleCategory', vehicle.vehicleCategory, {
        shouldValidate: true,
      })
      setValue('model', vehicle.model, {
        shouldValidate: true,
      })
      setValue('productionYear', vehicle.productionYear, {
        shouldValidate: true,
      })
      setValue('modelYear', vehicle.modelYear, {
        shouldValidate: true,
      })

      if (vehicle.iconColor) {
        setValue('iconColor', vehicle.iconColor || '')
      }

      if (vehicle.tracker?.model) {
        setValue(
          'tracker',
          {
            model: vehicle.tracker.model,
            serial: vehicle.tracker.serial,
          },
          {
            shouldDirty: true,
          },
        )

        setSelectedTrackerModel({
          id: vehicle.tracker.model,
          name: vehicle.tracker.name || '',
        })
      }
    }
  }, [vehicle, setValue])

  return (
    <div className={styles.container}>
      <VehicleFormExitModal
        isVisible={toggleModal.isVisible}
        onClick={() => navigate(-1)}
        onClose={() => toggleModal.hide()}
      />
      <div className={styles.separator} />
      <form onSubmit={handleSubmit(onSubmit)} aria-label="vehicle-form">
        <div className={styles.formWrapper}>
          <Controller
            name="licensePlate"
            control={control}
            defaultValue=""
            render={({ field: { onBlur, onChange, ref, name, value } }) => (
              <Input
                label="Placa"
                placeholder="XXX-0000"
                id="license-plate"
                name={name}
                autoComplete="off"
                maxLength={8}
                ref={ref}
                value={formatLicensePlate(value)}
                onChange={(e) => {
                  onChange(e.target.value.replace('-', '').toUpperCase())
                }}
                onBlur={onBlur}
                errorMessage={(errors.licensePlate as FieldError)?.message}
              />
            )}
          />
          <div>
            <span className={styles.vehicleCategoryTitle}>Categoria</span>
            <div className={styles.radiosContainer}>
              <div className={styles.radioWrapper}>
                <input
                  id="vehicle-category-car"
                  type="radio"
                  value={VEHICLE_CATEGORY_ENUM.CAR}
                  {...register('vehicleCategory')}
                />
                <label htmlFor="vehicle-category-car">Carro</label>
              </div>
              <div className={styles.radioWrapper}>
                <input
                  id="vehicle-category-motorcycle"
                  type="radio"
                  value={VEHICLE_CATEGORY_ENUM.MOTORCYCLE}
                  {...register('vehicleCategory')}
                />
                <label htmlFor="vehicle-category-motorcycle">Moto</label>
              </div>
            </div>
          </div>
          <Input
            label="Modelo"
            placeholder="Modelo do veículo"
            id="model"
            autoComplete="off"
            maxLength={120}
            {...register('model')}
            errorMessage={(errors.model as FieldError)?.message}
          />
          <div className={styles.inputYearsWrapper}>
            <Controller
              name="productionYear"
              control={control}
              render={({ field: { onBlur, onChange, ref, name, value } }) => (
                <Input
                  label="Ano de fabricação"
                  placeholder="0000"
                  id="production-year"
                  name={name}
                  autoComplete="off"
                  maxLength={4}
                  ref={ref}
                  value={value ? String(value) : ''}
                  onChange={(e) => {
                    onChange(parseNumber(e.target.value))
                  }}
                  onBlur={onBlur}
                  errorMessage={(errors.productionYear as FieldError)?.message}
                />
              )}
            />
            <Controller
              name="modelYear"
              control={control}
              render={({ field: { onBlur, onChange, ref, name, value } }) => (
                <Input
                  label="Ano do modelo"
                  placeholder="0000"
                  id="model-year"
                  name={name}
                  autoComplete="off"
                  maxLength={4}
                  ref={ref}
                  value={value ? String(value) : ''}
                  onChange={(e) => {
                    onChange(parseNumber(e.target.value))
                  }}
                  onBlur={onBlur}
                  errorMessage={(errors.modelYear as FieldError)?.message}
                />
              )}
            />
          </div>
          <Combobox
            label="Rastreador"
            id="tracker-model"
            searchable
            fetcher={handleFetchTrackerModels}
            value={
              selectedTrackerModel
                ? {
                    label: 'name',
                    value: selectedTrackerModel,
                  }
                : undefined
            }
            getSelected={(selected) => {
              const trackerModel = selected as ComboboxItem<
                Pick<TrackerModel, 'id' | 'name'>
              >
              setValue('tracker.model', trackerModel.value.id)
              setSelectedTrackerModel(trackerModel.value)
            }}
            errorMessage={(errors.tracker?.model as FieldError)?.message}
          />
          <Controller
            name="tracker.serial"
            control={control}
            render={({ field: { onBlur, onChange, ref, name, value } }) => (
              <Input
                label="Número de série"
                placeholder="Número de série"
                id="tracker-serial"
                name={name}
                autoComplete="off"
                maxLength={11}
                disabled={!tracker?.model}
                ref={ref}
                value={value ? String(value) : ''}
                onChange={(e) => {
                  onChange(parseNumber(e.target.value))
                }}
                onBlur={onBlur}
                errorMessage={(errors.tracker?.serial as FieldError)?.message}
              />
            )}
          />
          <section className={styles.vehicleColor}>
            <ColorPicker
              onSelectValue={(color) =>
                setValue('iconColor', color, {
                  shouldValidate: true,
                })
              }
              value={vehicleColor}
            />
            {vehicleColor && (
              <div className={styles.visualization}>
                <span>Visualização</span>
                <OccurrenceMap className={styles.map} />
              </div>
            )}
          </section>
        </div>
      </form>
      <div className={styles.footer}>
        <Button
          disabled={!isValid}
          buttonTitle="Salvar"
          type="primary"
          width="172px"
          onClick={handleSubmit(onBeforeSubmit)}
        />
        <Button
          buttonTitle="Voltar"
          onClick={() => toggleModal.show()}
          type="secondary"
          width="172px"
        />
      </div>
    </div>
  )
}
