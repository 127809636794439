import {
  Button,
  Combobox,
  ComboboxItem,
  Input,
  Instruction,
  Tooltip,
  ViewEntities,
} from 'components'

import { Combobox as ComboboxV2 } from 'components/ComboboxV2/Combobox'

import {
  getCoverageOptions,
  getCoverageInstruction,
  COVERAGE_OPTIONS_PT_EN,
  COVERAGE_OPTIONS_EN_PT,
  COVERAGE_OPTIONS,
} from 'domains/attendanceProfile/utilities/coverages'

import { useCallback, useEffect, useRef, useState } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'
import { AccountTag } from 'services/account/types'
import {
  CoverageDriver,
  CoverageCity,
  CoverageDistrict,
  CoverageAccount,
  CoverageInputRequest,
  ATTENDANCE_ROLE_EN_PT,
  ATTENDANCE_ROLE_PT_EN,
  ATTENDANCE_ROLE,
} from 'services/coverages'
import {
  SERVICE_ORDER_TYPE_ENUM,
  SERVICE_ORDER_TYPE_EN_PT,
  SERVICE_ORDER_TYPE_PT_EN,
} from 'services/serviceOrder'
import { Pagination } from 'services/types'
import { parseDataToCombobox, parseDataToComboboxV2 } from 'utilities/combobox'
import StateDriver, { State } from 'services/address/state'

import { ReactComponent as PlusSignIcon } from 'assets/svg/plusSign.svg'

import { Sections, ViewEntityContent } from 'components/ViewEntities/types'
import {
  OCCURRENCE_CATEGORY,
  OCCURRENCE_CATEGORY_EN_PT,
  OCCURRENCE_CATEGORY_PT_EN,
} from 'services/occurrence/types'

import { CoverageSelectors } from 'domains/attendanceProfile/components/AttendanceProfileForm/types'
import { CoverageType } from 'shared/types/coverages'
import { useGetAccountTags, useToggle } from 'shared/hooks'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { ServiceType } from 'services/serviceType/types'

import styles from './AttendanceProfileForm.module.scss'
import { useGetServiceType } from 'shared/hooks/services/serviceType/useGetServiceType'

type AttendanceProfileFormProps = {
  attendanceProfile?: {
    name: string
    coverageType: string
    coverages: ViewEntityContent[]
    serviceTypes?: {
      id: string
      name: string
    }[]
  }
  onSubmit: (coverage: CoverageInputRequest) => void
  onGoBack: () => void
  readonly?: boolean
}

const emptyEntity: CoverageSelectors = {
  account: null,
  occurrenceCategory: null,
  state: null,
  city: null,
  district: null,
  serviceOrderType: null,
  tag: null,
}

export const AttendanceProfileForm = ({
  onGoBack,
  onSubmit,
  attendanceProfile,
  readonly,
}: AttendanceProfileFormProps) => {
  const [coverageOption, setCoverageOption] =
    useState<COVERAGE_OPTIONS | null>()

  const [selectedSelector, setSelectedSelector] =
    useState<CoverageSelectors>(emptyEntity)

  const [selectorList, setSelectorList] = useState<Sections>(
    new Map<string, ViewEntityContent[]>(),
  )

  const [accountTagFilter, setAccountTagFilter] = useState('')

  const coverageTypetooltip = useToggle()
  const coverageTypeRef = useRef<SVGSVGElement>(null)

  const {
    register,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext<CoverageInputRequest>()

  const coverageType = watch('coverageType')
  const accountIds = watch('accountIds')
  const accountTagIds = watch('accountTagIds')
  const stateIds = watch('stateIds')
  const cityIds = watch('cityIds')
  const districtIds = watch('districtIds')
  const occurrenceCategories = watch('occurrenceCategories')
  const serviceOrderTypes = watch('serviceOrderTypes')
  const attendanceProfileServiceTypes = watch('serviceTypes')

  const handleFetchAccounts = useCallback(
    async (pagination: Pagination, code?: string) => {
      const response = await CoverageDriver.queryAccounts({
        ...(code && { code }),
        ...pagination,
        active: true,
      })

      return parseDataToCombobox(response, 'aggregatedAccountName')
    },
    [],
  )

  const {
    accountTags,
    isError: isErrorAccountTags,
    isFetching: isFetchingAccountTags,
    fetchNextTagsPage,
  } = useGetAccountTags(
    accountTagFilter,
    coverageOption === COVERAGE_OPTIONS.TAGS,
  )

  const [serviceTypeFilter, setServiceTypeFilter] = useState('')

  const {
    serviceTypes,
    isFetching: isFetchingServiceTypes,
    fetchNextServiceTypesPage,
  } = useGetServiceType(serviceTypeFilter)

  const handleFetchStates = useCallback(
    async (pagination: Pagination, name?: string) => {
      const response = await StateDriver.queryStates({
        ...(name && { name }),
        ...pagination,
      })

      return parseDataToCombobox(response, 'name')
    },
    [],
  )

  const handleFetchCities = useCallback(
    async (pagination: Pagination, name?: string) => {
      const response = await CoverageDriver.queryCities({
        ...(name && { name }),
        ...pagination,
        ...(selectedSelector.state && { stateId: selectedSelector.state.id }),
      })

      return parseDataToCombobox(response, 'name')
    },
    [selectedSelector.state],
  )

  const handleFetchDistricts = useCallback(
    async (pagination: Pagination, name?: string) => {
      const response = await CoverageDriver.queryDistricts({
        ...(name && { name }),
        ...(selectedSelector.city && { cityId: selectedSelector.city.id }),
        ...pagination,
      })

      return parseDataToCombobox(response, 'name')
    },
    [selectedSelector],
  )

  const addEntity = useCallback(() => {
    const {
      account,
      occurrenceCategory,
      city,
      district,
      serviceOrderType,
      tag,
      state,
    } = selectedSelector

    const currentEntity = new Map(selectorList)

    if (account) {
      currentEntity.set(account?.id, [
        {
          id: account.id,
          title: account.aggregatedAccountName,
          subtitle: CoverageType.ACCOUNT,
        },
      ])

      setValue('accountIds', [...(accountIds || []), account.id], {
        shouldValidate: true,
      })
    }

    if (tag) {
      currentEntity.set(tag.id, [
        {
          id: tag.id,
          title: tag.name,
          subtitle: CoverageType.TAG,
        },
      ])

      setValue('accountTagIds', [...(accountTagIds || []), tag.id], {
        shouldValidate: true,
      })
    }

    if (serviceOrderType) {
      currentEntity.set(String(serviceOrderType), [
        {
          id: String(serviceOrderType),
          title:
            SERVICE_ORDER_TYPE_EN_PT[
              serviceOrderType as unknown as SERVICE_ORDER_TYPE_ENUM
            ],
          subtitle: CoverageType.SERVICE_ORDER_TYPE,
        },
      ])

      setValue(
        'serviceOrderTypes',
        [...(serviceOrderTypes || []), serviceOrderType],
        { shouldValidate: true },
      )
    }

    if (occurrenceCategory) {
      currentEntity.set(String(occurrenceCategory), [
        {
          id: String(occurrenceCategory),
          title:
            OCCURRENCE_CATEGORY_EN_PT[
              occurrenceCategory as unknown as OCCURRENCE_CATEGORY
            ],
          subtitle: CoverageType.OCCURRENCE_CATEGORY,
        },
      ])

      setValue(
        'occurrenceCategories',
        [...(occurrenceCategories || []), occurrenceCategory],
        { shouldValidate: true },
      )
    }

    if (state && !city) {
      currentEntity.set(state.id, [
        {
          id: state.id,
          title: state.name,
          subtitle: CoverageType.STATE,
        },
      ])

      setValue('stateIds', [...(stateIds || []), state.id], {
        shouldValidate: true,
      })
    }

    if (city && state && !district) {
      currentEntity.set(city.id, [
        {
          id: city.id,
          title: `${city.name} - ${state.acronym}`,
          subtitle: CoverageType.CITY,
        },
      ])

      setValue('cityIds', [...(cityIds || []), city.id], {
        shouldValidate: true,
      })
    } else if (district && city && state) {
      currentEntity.set(district.id, [
        {
          id: district.id,
          title: `${district.name}, ${city.name} - ${state.acronym}`,
          subtitle: CoverageType.DISTRICT,
        },
      ])

      setValue('districtIds', [...(districtIds || []), district.id], {
        shouldValidate: true,
      })
    }

    setSelectorList(currentEntity)
  }, [
    accountIds,
    accountTagIds,
    cityIds,
    districtIds,
    selectorList,
    occurrenceCategories,
    selectedSelector,
    serviceOrderTypes,
    setValue,
    stateIds,
  ])

  const removeEntity = useCallback(
    (entity: ViewEntityContent) => {
      const currentEntity = new Map(selectorList)
      currentEntity.delete(entity.id)

      const { occurrenceCategory, serviceOrderType } = selectedSelector
      switch (entity.subtitle) {
        case CoverageType.ACCOUNT:
          if (!accountIds) return
          setValue(
            'accountIds',
            accountIds?.filter((id) => id !== entity.id),
            { shouldValidate: true },
          )
          break
        case CoverageType.CITY:
          if (!cityIds) return
          setValue(
            'cityIds',
            cityIds?.filter((id) => id !== entity.id),
            { shouldValidate: true },
          )
          break
        case CoverageType.STATE:
          if (!stateIds) return
          setValue(
            'stateIds',
            stateIds?.filter((id) => id !== entity.id),
            { shouldValidate: true },
          )
          break
        case CoverageType.DISTRICT:
          if (!districtIds) return
          setValue(
            'districtIds',
            districtIds?.filter((id) => id !== entity.id),
            { shouldValidate: true },
          )
          break
        case CoverageType.TAG:
          if (!accountTagIds) return
          setValue(
            'accountTagIds',
            accountTagIds?.filter((id) => id !== entity.id),
            { shouldValidate: true },
          )
          break
        case CoverageType.SERVICE_ORDER_TYPE:
          if (!serviceOrderTypes) return
          setValue(
            'serviceOrderTypes',
            serviceOrderTypes.filter(
              (type) =>
                type !== entity.id ||
                (serviceOrderType as unknown as SERVICE_ORDER_TYPE_ENUM),
            ),
            { shouldValidate: true },
          )
          break
        case CoverageType.OCCURRENCE_CATEGORY:
          if (!occurrenceCategories) return
          setValue(
            'occurrenceCategories',
            occurrenceCategories?.filter(
              (category) => category !== entity.id || occurrenceCategory,
            ),
            { shouldValidate: true },
          )
          break
      }

      setSelectorList(currentEntity)
    },
    [
      selectorList,
      selectedSelector,
      accountIds,
      setValue,
      cityIds,
      stateIds,
      districtIds,
      accountTagIds,
      serviceOrderTypes,
      occurrenceCategories,
    ],
  )

  useEffect(() => {
    register('name')
    register('coverageType')
    register('stateIds')
    register('cityIds')
    register('districtIds')
    register('accountIds')
    register('accountTagIds')
    register('occurrenceCategories')
    register('serviceOrderTypes')
    register('serviceTypeIds')
  }, [register])

  useEffect(() => {
    if (!attendanceProfile) return
    const currentEntity = new Map(selectorList)

    setValue('name', attendanceProfile.name, { shouldValidate: true })
    setValue(
      'coverageType',
      attendanceProfile.coverageType as ATTENDANCE_ROLE,
      {
        shouldValidate: true,
      },
    )
    if (attendanceProfile?.serviceTypes?.length) {
      setValue('serviceTypes', attendanceProfile.serviceTypes, {
        shouldValidate: true,
      })
    }

    attendanceProfile?.coverages.map((coverage) => {
      currentEntity.set(coverage.id, [
        {
          id: coverage.id,
          title: coverage.title,
          subtitle: coverage.subtitle,
        },
      ])

      const tagIds = attendanceProfile.coverages
        .filter((coverage) => coverage.subtitle === CoverageType.TAG)
        .map((tagCoverage) => tagCoverage.id)

      setValue('accountTagIds', tagIds, { shouldValidate: true })

      const prevValue: string[] = getValues(coverage?.formKey || '')
      setValue(coverage?.formKey || '', [...(prevValue || []), coverage.id], {
        shouldValidate: true,
      })
    })

    setSelectorList(currentEntity)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.separator} />
      <div
        className={[
          coverageOption === COVERAGE_OPTIONS.REGIONS && styles.changedLayout,
          styles.formWrapper,
        ].join(' ')}
      >
        <section>
          <Input
            label="Nome"
            placeholder="Nome do Perfil"
            id="name"
            autoComplete="off"
            {...register('name')}
            errorMessage={(errors.name as FieldError)?.message}
            maxLength={60}
            disabled={readonly}
          />

          <ComboboxV2
            id="service-type"
            label={{ text: 'Tipo de serviço' }}
            placeholder="Selecione o tipo"
            searchable
            multiple
            value={
              attendanceProfileServiceTypes
                ? attendanceProfileServiceTypes?.map((serviceType) => ({
                    label: 'name',
                    value: serviceType,
                  }))
                : []
            }
            isLoading={isFetchingServiceTypes}
            onEndReached={fetchNextServiceTypesPage}
            items={parseDataToComboboxV2(serviceTypes || [], 'name')}
            onChange={(selected) => {
              const serviceTypes =
                selected as unknown as ComboboxItem<ServiceType>[]

              setValue(
                'serviceTypes',
                serviceTypes?.map((serviceType) => ({
                  id: serviceType.value.id,
                  name: serviceType.value.name,
                })),
                {
                  shouldValidate: true,
                },
              )
            }}
            onSearch={(search: string) => setServiceTypeFilter(search)}
            disabled={readonly}
          />

          <ComboboxV2
            id="coverage-role"
            label={{ text: 'Função' }}
            placeholder="Selecione a função"
            value={ATTENDANCE_ROLE_EN_PT[coverageType] || ''}
            items={[...Object.values(ATTENDANCE_ROLE_EN_PT)].sort((a, b) =>
              a.localeCompare(b),
            )}
            onChange={(selected) => {
              const coverageType = selected as ATTENDANCE_ROLE_EN_PT
              setValue('coverageType', ATTENDANCE_ROLE_PT_EN[coverageType], {
                shouldValidate: true,
              })

              if (selectorList.size > 0) {
                selectorList.clear()
                reset({
                  coverageType: ATTENDANCE_ROLE_PT_EN[
                    coverageType
                  ] as unknown as ATTENDANCE_ROLE,
                })
                setCoverageOption(null)
                setSelectedSelector(emptyEntity)
              }
            }}
            disabled={readonly}
          />

          {coverageType && (
            <>
              <div className={styles.instructionWrapper}>
                <Instruction text={getCoverageInstruction(coverageType)} />
              </div>

              <Combobox
                id="coverage-option"
                label="Tipo de abrangência"
                labelHint={
                  <>
                    <InfoIcon
                      ref={coverageTypeRef}
                      className={styles.coverageLabelHint}
                      onMouseEnter={coverageTypetooltip.show}
                      onMouseLeave={coverageTypetooltip.hide}
                    />
                    <Tooltip
                      parentRef={coverageTypeRef}
                      type="informative"
                      isVisible={coverageTypetooltip.isVisible}
                    >
                      <p>
                        Os perfis com abrangência por Cliente são exclusivos e
                        não permitem a inclusão de abrangências por Região ou
                        Tag e vice-versa. No entanto, ainda é possível adicionar
                        tipo de Ordens de Serviço ou Categoria de Ocorrência.
                      </p>
                    </Tooltip>
                  </>
                }
                items={getCoverageOptions(coverageType, selectorList)}
                getSelected={(selected) => {
                  const coverageOption = selected as COVERAGE_OPTIONS_EN_PT

                  setCoverageOption(
                    COVERAGE_OPTIONS_PT_EN[
                      coverageOption
                    ] as unknown as COVERAGE_OPTIONS,
                  )
                }}
                disabled={readonly}
              />
            </>
          )}

          {coverageOption === COVERAGE_OPTIONS.TAGS && (
            <ComboboxV2
              id="tag"
              label={{
                text: 'Tag',
              }}
              value={
                selectedSelector.tag
                  ? {
                      label: 'name',
                      value: selectedSelector.tag,
                    }
                  : undefined
              }
              onSearch={(text) => setAccountTagFilter(text)}
              items={parseDataToComboboxV2(accountTags || [], 'name')}
              onChange={(selected) => {
                const tag = selected as ComboboxItem<AccountTag>
                setSelectedSelector({
                  tag: tag?.value,
                })
              }}
              disabled={readonly}
              onEndReached={fetchNextTagsPage}
              isError={isErrorAccountTags}
              isLoading={isFetchingAccountTags}
            />
          )}

          {coverageOption === COVERAGE_OPTIONS.SERVICE_ORDER_TYPES && (
            <Combobox
              id="service-order-type"
              label="Tipo de Ordem de Serviço"
              items={[...Object.values(SERVICE_ORDER_TYPE_EN_PT)]}
              getSelected={(selected) => {
                const type = selected as SERVICE_ORDER_TYPE_EN_PT

                setSelectedSelector({
                  serviceOrderType: SERVICE_ORDER_TYPE_PT_EN[type],
                })
              }}
              disabled={readonly}
            />
          )}

          {coverageOption === COVERAGE_OPTIONS.OCCURRENCE_CATEGORY && (
            <Combobox
              id="occurrence-category"
              label="Categoria de ocorrência"
              items={[...Object.values(OCCURRENCE_CATEGORY_EN_PT)]}
              getSelected={(selected) => {
                const category = selected as OCCURRENCE_CATEGORY_EN_PT

                setSelectedSelector({
                  occurrenceCategory: OCCURRENCE_CATEGORY_PT_EN[category],
                })
              }}
              disabled={readonly}
            />
          )}

          {coverageOption === COVERAGE_OPTIONS.ACCOUNTS && (
            <Combobox
              id="account"
              label="Conta"
              searchable
              fetcher={handleFetchAccounts}
              getSelected={(selected) => {
                const account = selected as ComboboxItem<CoverageAccount>

                setSelectedSelector({
                  account: account?.value,
                })
              }}
              disabled={readonly}
            />
          )}

          {coverageOption === COVERAGE_OPTIONS.REGIONS && (
            <>
              <Combobox
                label="Estado"
                id="state"
                searchable
                fetcher={handleFetchStates}
                value={
                  selectedSelector.state
                    ? { label: 'name', value: selectedSelector.state }
                    : undefined
                }
                getSelected={(selected) => {
                  const state = selected as ComboboxItem<State>
                  setSelectedSelector({
                    state: state?.value,
                    city: null,
                  })
                }}
                disabled={readonly}
              />

              <Combobox
                label="Cidade"
                id="city"
                searchable
                fetcher={handleFetchCities}
                disabled={!selectedSelector.state || readonly}
                value={
                  selectedSelector.city
                    ? { label: 'name', value: selectedSelector.city }
                    : undefined
                }
                getSelected={(selected) => {
                  const city = selected as ComboboxItem<CoverageCity>
                  setSelectedSelector((prev) => ({
                    ...prev,
                    city: city?.value,
                    district: null,
                  }))
                }}
              />

              <Combobox
                label="Bairro"
                id="district"
                searchable
                fetcher={handleFetchDistricts}
                value={
                  selectedSelector.district
                    ? {
                        label: 'name',
                        value: selectedSelector.district,
                      }
                    : undefined
                }
                getSelected={(selected) => {
                  const district = selected as ComboboxItem<CoverageDistrict>
                  setSelectedSelector((prev) => ({
                    ...prev,
                    district: district?.value,
                  }))
                }}
                disabled={!selectedSelector.city || readonly}
              />
            </>
          )}

          {coverageType && (
            <>
              <div className={styles.addCoverage}>
                <Button
                  buttonTitle="Adicionar abrangências"
                  type="tertiary"
                  className={styles.entityButton}
                  icon={PlusSignIcon}
                  onClick={addEntity}
                  disabled={readonly}
                />
              </div>
            </>
          )}
        </section>

        <section>
          {selectorList.size > 0 && (
            <div className={styles.coverageWrapper}>
              <span>Lista de abrangências</span>
              <div
                aria-label="coverages-wrapper"
                className={styles.coverageList}
              >
                <ViewEntities
                  onRemove={
                    !readonly ? (entity) => removeEntity(entity) : undefined
                  }
                  filterable={false}
                  sections={selectorList}
                />
              </div>
            </div>
          )}
        </section>
      </div>
      <div className={styles.footer}>
        <Button
          buttonTitle="Voltar"
          onClick={onGoBack}
          type="secondary"
          width="172px"
        />
        <Button
          buttonTitle="Salvar"
          type="primary"
          width="172px"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        />
      </div>
    </div>
  )
}
