import { useMutation } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { usePostIntervention } from './intervention'
import { CreateSendImagesInterventionPayload } from './types'
import { useUserInfo } from 'shared/hooks'

let imageIds: string[] = []

const persistImages = async (payload: CreateSendImagesInterventionPayload) => {
  for await (const image of payload.images) {
    try {
      const formPayload = {
        ...(payload.principalOwner && {
          principalOwner: payload.principalOwner,
        }),
        ...(payload.owners && { owners: payload.owners }),
      }

      const formData = new FormData()

      formData.append('imageFile', image, image.name)
      formData.append('payload', JSON.stringify(formPayload))

      const response = await HttpClient.post(endpoints.addImage, formData, {
        headers: {
          accept: 'application/json',
          'Content-Type': `multipart/form-data;`,
        },
      })

      imageIds.push(String(response.data))
    } catch (error) {
      throw new Error('Error adding image on attendance occurrence screen')
    }
  }

  return payload
}

export const useSendImage = (occurrenceId = '') => {
  const { mutate } = usePostIntervention(occurrenceId)
  const { userInfo } = useUserInfo()

  return useMutation({
    mutationFn: async (payload: CreateSendImagesInterventionPayload) =>
      await persistImages(payload),

    onSuccess: (_data, variables) => {
      const userId = userInfo.id

      if (userId) {
        mutate({
          userId,
          title: 'Fotos do local adicionadas remotamente',
          tags: [{ name: 'SEND_IMAGE' }],
          note: variables.note,
          typeName: 'SEND_IMAGE',
          attributes: {
            imageIds,
          },
        })
      }

      imageIds = []
    },
  })
}
