import { useEffect, useRef, useState, DragEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { ButtonV2, Icon, ModalV2 } from 'components'

import styles from './styles.module.scss'

const AttachModal = () => {
  const closeRef = useRef<HTMLButtonElement>(null)
  const { watch, setValue } = useFormContext()
  const { images: formImages } = watch()

  const [images, setImages] = useState<File[]>(formImages)

  const handleRemoveImage = (indexToRemove: number) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove),
    )
  }

  const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()

    setImages((prev) => [
      ...prev,
      ...Array.from(event.dataTransfer.files || []),
    ])
  }

  const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
  }

  useEffect(() => {
    setImages(formImages)
  }, [setImages, formImages])

  return (
    <>
      <ModalV2.Title>Anexar arquivo </ModalV2.Title>
      <div className={styles.container}>
        <label onDrop={handleDrop} onDragOver={handleDragOver}>
          <Icon name="gallery-add" width={42} height={42} />
          <input
            multiple
            type="file"
            accept="image/jpeg, image/png"
            onChange={(event) => {
              if (event.target?.files?.length) {
                setImages((prev) => [
                  ...prev,
                  ...Array.from(event.target.files || []),
                ])
              }
            }}
          />
          <span>
            <strong>Selecione</strong> ou arraste os documentos para adicionar à
            galeria da OS
          </span>
        </label>
        <div>
          <span>Arquivos enviados</span>
          <ul className={styles.imagesList}>
            {images?.map((image, index) => (
              <li key={index}>
                <img alt="" src={URL.createObjectURL(image)} />
                <div>
                  <div>
                    <span>{image.name}</span>
                    <ButtonV2
                      size="md"
                      appearance="tertiary"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <Icon name="remove" />
                      Remover
                    </ButtonV2>
                  </div>
                  <div />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ModalV2.Footer>
        <ModalV2.Close>
          <ButtonV2 appearance="tertiary" ref={closeRef}>
            Cancelar
          </ButtonV2>
        </ModalV2.Close>
        <ButtonV2
          onClick={() => {
            if (closeRef.current) {
              closeRef.current.click()
            }

            setValue('images', images, { shouldValidate: true })
          }}
        >
          Salvar
        </ButtonV2>
      </ModalV2.Footer>
    </>
  )
}

export default AttachModal
