import { ScheduleType } from './types'

export const otherContact = {
  id: '',
  name: 'Outro',
  number: 0,
  countryCode: 0,
  provinceCode: 0,
}

export function checkScheduleOverlap(
  blockStartDate: Date,
  blockEndDate: Date,
  scheduleDate: Date,
  scheduleType: ScheduleType,
  chosenStartTime: Date,
  chosenEndTime: Date,
): boolean {
  const isBusinessOrDayShiftOverlap =
    (scheduleType === 'BUSINESS_HOURS' || scheduleType === 'DAY_SHIFT') &&
    (blockStartDate.toDateString() === scheduleDate.toDateString() ||
      blockEndDate.toDateString() === scheduleDate.toDateString() ||
      (blockStartDate <= scheduleDate && blockEndDate >= scheduleDate))

  const isFixedScheduleOverlap =
    scheduleType === 'FIXED_SCHEDULE' &&
    ((chosenStartTime >= blockStartDate && chosenStartTime < blockEndDate) ||
      (chosenEndTime > blockStartDate && chosenEndTime <= blockEndDate) ||
      (chosenStartTime <= blockStartDate && chosenEndTime >= blockEndDate))

  return isBusinessOrDayShiftOverlap || isFixedScheduleOverlap
}
