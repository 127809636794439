import { useState, useRef, useCallback } from 'react'
import { useToggle } from 'shared/hooks'

interface UseConfirmCloseProps {
  onClose: () => void
  shouldConfirm: boolean
}

export const useConfirmClose = ({
  onClose,
  shouldConfirm,
}: UseConfirmCloseProps) => {
  const [triedToClose, setTriedToClose] = useState(false)
  const tooltip = useToggle()
  const tooltipRef = useRef<HTMLDivElement>(null)

  const handleClose = useCallback(() => {
    if (shouldConfirm && !triedToClose) {
      setTriedToClose(true)
      tooltip.show()
    } else {
      onClose()
      setTriedToClose(false)
      tooltip.hide()
    }
  }, [shouldConfirm, triedToClose, onClose, tooltip])

  const resetCloseAttempt = useCallback(() => {
    setTriedToClose(false)
    tooltip.hide()
  }, [tooltip])

  return {
    triedToClose,
    tooltipRef,
    tooltip,
    handleClose,
    resetCloseAttempt,
  }
}
