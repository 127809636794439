export enum CoverageType {
  ACCOUNT = 'Conta',
  TAG = 'Tag',
  SERVICE_ORDER_TYPE = 'Tipo de ordem de serviço',
  OCCURRENCE_CATEGORY = 'Categoria de ocorrência',
  DISTRICT = 'Bairro',
  CITY = 'Cidade',
  STATE = 'Estado',
}

export type Coordinates = {
  latitude: number
  longitude: number
}
