import { FC, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'

import { Button, Textarea } from 'components'
import { usePostNote } from 'services/attendance'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { HistoryIntervention } from 'services/attendance/types'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { RouteParams } from 'domains/occurrence/screens/Attendance/types'
import { interventionPredefinedComments } from 'domains/occurrence/screens/Attendance/utils'

import { schema, handleInterventionTag, interventionStatusLabel } from './utils'
import styles from './styles.module.scss'
import { ComboBoxType, FormPayload } from './types'
import { useUserInfo } from 'shared/hooks'

interface FormProps {
  intervention: HistoryIntervention
}

const Form: FC<FormProps> = ({ intervention }) => {
  const { userInfo } = useUserInfo()
  const { occurrenceId } = useParams<RouteParams>()

  const { mutate, isPending } = usePostNote(occurrenceId, intervention.id)

  const {
    watch,
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormPayload>({
    mode: 'onChange',
    resolver: joiResolver(schema),
  })

  const tag = useMemo(
    () => handleInterventionTag(intervention.tags),
    [intervention],
  )

  const handleSelectedComment = useCallback(
    (comment?: ComboboxItem<ComboBoxType>) => {
      register('tags')
      register('attributes')

      if (comment) {
        setValue('tags', comment.value.tags, { shouldValidate: true })
        setValue('note', comment.value.message, { shouldValidate: true })
        setValue('predefinedNote', comment.value.message, {
          shouldValidate: true,
        })

        if (
          ['PHONE_CALL', 'SEND_MESSAGE', 'IMAGE_VERIFICATION'].includes(
            intervention.typeName,
          )
        ) {
          setValue(
            'attributes',
            {
              status: comment.value.status,
            },
            { shouldValidate: true },
          )
        }
      }
    },
    [intervention, register, setValue],
  )

  const handleClearAttributes = useCallback(
    (interventionAttributes: HistoryIntervention) => {
      const attributesWithoutNull = {}

      if (interventionAttributes) {
        Object.keys(interventionAttributes).forEach((key) => {
          type Key = keyof HistoryIntervention

          if (interventionAttributes[key as Key]) {
            attributesWithoutNull[key as Key] =
              interventionAttributes[key as Key]
          }
        })
      }

      return attributesWithoutNull
    },
    [],
  )

  const onSubmit = useCallback(
    (data: FormPayload) => {
      const userId = userInfo.id

      const currentAttributes = handleClearAttributes(intervention.attributes)

      if (userId) {
        mutate(
          {
            userId,
            note: data.note,
            tags: data.tags || [],
            ...(data.attributes && {
              attributes: {
                ...currentAttributes,
                ...data.attributes,
              },
            }),
          },
          {
            onSuccess: () => {
              reset()
            },
          },
        )
      }
    },
    [mutate, reset, handleClearAttributes, intervention],
  )

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <Textarea
        rows={5}
        maxLength={5000}
        {...register('note')}
        value={watch('note') || ''}
        className={styles.textArea}
        onChange={(event) =>
          setValue('note', event.target.value, { shouldValidate: true })
        }
      />
      <div>
        <Combobox
          searchable
          placeholder="Selecione uma tratativa"
          items={parseDataToComboboxV2(
            interventionPredefinedComments[tag],
            'message',
          )}
          {...register('predefinedNote')}
          value={watch('predefinedNote')}
          itemLabel={(item) => {
            const predefinedComment =
              item as unknown as ComboboxItem<ComboBoxType>
            const status = predefinedComment.value.status

            return status ? interventionStatusLabel[status] : ''
          }}
          onChange={(item) =>
            handleSelectedComment(item as unknown as ComboboxItem<ComboBoxType>)
          }
        />
        <Button
          width="172px"
          type="primary"
          htmlType="submit"
          disabled={!isValid || isPending}
          buttonTitle="SALVAR LOG"
        />
      </div>
    </form>
  )
}

export default Form
