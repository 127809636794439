import { useMutation } from '@tanstack/react-query'
import CommandService from 'services/command'
import { MaintenanceModePayload } from 'services/command/types'

async function setDefaultModeCommand(
  centralId: string,
  payload: MaintenanceModePayload,
) {
  await CommandService.setDefaultMode(centralId, payload)
}

export const usePostSetDefaultMaintenanceModeCommand = (
  centralId: string,
  payload: MaintenanceModePayload,
) => {
  const {
    mutate: mutateSetDefaultModeMaintenanceCommand,
    status: statusSetDefaultModeMaintenanceCommandMutation,
  } = useMutation({
    mutationFn: () => setDefaultModeCommand(centralId, payload),
  })

  return {
    mutateSetDefaultModeMaintenanceCommand,
    statusSetDefaultModeMaintenanceCommandMutation,
  }
}
