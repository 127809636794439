import { OccurrenceQuery } from 'services/occurrence/types'
import OccurrenceDriver from 'services/occurrence'
import { useQuery } from '@tanstack/react-query'

async function fetchAllOccurrences(query?: OccurrenceQuery) {
  const occurrences = await OccurrenceDriver.findAllOccurrences({ ...query })

  return occurrences
}

export const useGetAllOccurrences = (
  userSubmitCount: number,
  query?: OccurrenceQuery,
  isEnabled?: boolean,
) => {
  const {
    data: occurrences,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['occurrences', userSubmitCount],
    queryFn: async () =>
      await fetchAllOccurrences({
        stateNames: [
          'AVAILABLE',
          'IN_PROGRESS',
          'TRAVEL_WAITING',
          'TRAVEL_SENT',
          'TRAVEL_STARTED',
          'TRAVEL_FINISHED',
          'QUEUED',
        ],
        ...query,
      }),
    enabled: isEnabled,
    refetchInterval: 10 * 1000,
    refetchIntervalInBackground: true,
  })

  return { occurrences, isLoading, refetch }
}
