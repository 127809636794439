import { useQuery } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'
import { Result } from 'services/types'
import { PartitionResponse } from 'services/partition/types'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { OccurrenceQuery, QueryOccurrence } from './types'

export type Query = {
  page?: number
  size?: number
  accountId?: string
  fields?: (keyof OccurrenceQuery)[]
  partition?: (keyof PartitionResponse)[]
}

export const useQueryOccurrence = ({
  accountId,
  partition,
  page = 1,
  size = 15,
  fields,
}: Query) => {
  const currentPage = page - 1

  return useQuery({
    queryKey: [
      'query-occurrence',
      accountId,
      currentPage,
      size,
      fields,
      partition,
    ],
    queryFn: async () => {
      const response = await HttpClient.get<Result<QueryOccurrence>>(
        buildUrl({
          route: endpoints.query,
          queryParams: {
            accountId,
            partition,
            fields,
            page: currentPage,
            size,
          },
        }),
      )

      return response.data
    },
  })
}
