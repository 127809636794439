import { AccountDetails, SODetails, TimeLine, Modals } from './containers'
import { ModalsProvider } from '../../context'
import styles from './styles.module.scss'

const Details = () => {
  return (
    <ModalsProvider>
      <div className={styles.container}>
        <Modals />
        <AccountDetails />
        <div>
          <SODetails />
          <hr className={styles.divider} />
          <TimeLine />
        </div>
      </div>
    </ModalsProvider>
  )
}

export default Details
