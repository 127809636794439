import { USER_STATUS, UserStatus } from 'domains/user/components/Filters/types'

const userStatus: Record<string, UserStatus> = {
  [USER_STATUS.ACTIVE]: {
    label: USER_STATUS.ACTIVE,
    value: true,
  },
  [USER_STATUS.INACTIVE]: {
    label: USER_STATUS.INACTIVE,
    value: false,
  },
  [USER_STATUS.ALL]: {
    label: USER_STATUS.ALL,
    value: undefined,
  },
}

export const getUserStatus = (status: string): UserStatus => userStatus[status]
