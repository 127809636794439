import { OccurrenceTravel } from 'services/occurrence/types'

export const handleOccurrenceStateLabel = (
  status: OccurrenceTravel,
  success: boolean,
): string => {
  const occurrenceStateLabel = {
    TRAVEL_WAITING: '',
    TRAVEL_SENT: success ? 'Deslocamento visualizado' : ' Deslocamento enviado',
    TRAVEL_VIEWED: success
      ? 'Trânsito iniciado'
      : 'Aguardando iniciar trânsito',
    TRAVEL_STARTED: success
      ? ' Tático no local'
      : ' Aguardando chegar ao local',
    TRAVEL_FINISHED: success ? 'Finalizada' : 'Aguardando finalização',
    FINISHED: '',
  }

  return occurrenceStateLabel[status]
}
