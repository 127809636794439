import { CentralAggregatedResponse } from 'services/central/types'
import { ContactResponse } from 'services/contact/types'
import {
  ConfigurationProfileResponse,
  DeviceType,
} from 'services/partition/types'
import { Paging, QueryRange, Sort } from 'services/types'

export type UpdateDeviceRequest = {
  name?: string
  partitions?: Array<string>
  contactId?: string
}

export enum SENSOR_TYPE {
  PRINCIPAL = 'PRINCIPAL',
  TAMPER = 'TAMPER',
  ANTI_SABOTAGE = 'ANTI SABOTAGE',
  SHOCK = 'SHOCK',
  DRY_CONTACT = 'DRY CONTACT',
  CENTRAL = 'CENTRAL',
}

export enum SENSOR_TYPE_EN_PT {
  'PRINCIPAL' = 'Principal',
  'TAMPER' = 'Tamper',
  'ANTI_SABOTAGE' = 'Anti Sabotagem',
  'SHOCK' = 'Shock',
  'DRY_CONTACT' = 'Contato Seco',
  'CENTRAL' = 'Central',
}

export enum SENSOR_TYPE_PT_EN {
  'Principal' = 'PRINCIPAL',
  'Tamper' = 'TAMPER',
  'Anti Sabotagem' = 'ANTI_SABOTAGE',
  'Shock' = 'SHOCK',
  'Contato Seco' = 'DRY_CONTACT',
  'Central' = 'CENTRAL',
}

export type DeviceTagQuery = {
  name?: string
  sort?: string
  sortType?: string
} & Paging

export type DeviceTagResponse = {
  id: string
  name: string
  createdAt: number
  updatedAt: number
}

export type DeviceTypeFragment = {
  code: string
  name: string
  description: string
}
export type DeviceTypeResponse = {
  id: string
} & DeviceTypeFragment

export type DeviceTypeQuery = {
  code?: string
  name?: string
  description?: string
} & Partial<Paging> &
  Partial<Sort>

export interface InfiniteDevicesQuery {
  name?: string
  code?: string
}

export interface DevicesOutput {
  id: string
  code: string
  name: string
  description: string
}

type TagFragment = {
  id: string
  name: string
}

export interface DevicePayload {
  code: string
  name: string
  centralId: string
  modelId: string
  contactId: string
  partitions: string[]
  deviceTypeId: string
  deviceTypeCode: string
  deviceStatus: boolean
  tags?: TagFragment[]
}

export interface DeviceResponse {
  id: string
  centralId: string
  tags: { name: string }[]
  deviceTypeId: string
  deviceTypeCode: string
  deviceStatus: boolean
  deviceCommunicationStatus?: boolean
  code: string
  modelId?: string
  externalId?: string
  name: string
  contactId?: string
  partitions?: string[]
  combinations?: string[]
}

export interface DevicePutPayload {
  data: DevicePayload
  deviceId: string
}

export interface DeviceDetailsResponse {
  id: string
  account: {
    id: string
    name: string
  }
  device: {
    id: string
    code: string
    name: string
  }
  manufacturer: {
    id: string
    name: string
  }
  model: {
    id: string
    name: string
  }
  tags: TagFragment[]
  name: string
  code: string
  centralId: string
  partitions: {
    id: string
    name: string
    code: string
  }[]
  image?: string
  contact: {
    id: string
    name: string
  }
}

export type TagsPayload = {
  offset: number
  name?: string
}

export type TagsResponse = {
  id: string
  name: string
  createdAt: number
  updatedAt: number
}

export type AggregatedDevicePartition = { id: string; name: string }

export type PartitionDeviceAggregatedResponse = {
  id: string
  name: string
  code: string
  hiddenZone: boolean
  hiddenZoneTo?: number
  isTamperOpened: boolean
  sirenVolume: number
  lastConnectionTimestamp: number
  installationImageId: string
  status: boolean
  typeCode: DeviceType
  typeId: string
  accelerometerEnabled: boolean
  hasCommandSupport: boolean
  model?: {
    id: string
    name: string
  }
  manufacturer?: {
    id: string
    name: string
  }
  profiles: {
    camera?: ConfigurationProfileResponse
    pir?: ConfigurationProfileResponse
    shock?: ConfigurationProfileResponse
  }
  communication: {
    status: boolean
    level: number
    rssi: number
  }
  battery: {
    isLow: boolean
    level: number
    voltage: number
  }
  versions: {
    firmware: string
    hardware: string
  }
  contact?: Pick<ContactResponse, 'id' | 'name'>
  partitions: AggregatedDevicePartition[]
  deviceType?: {
    id: string
    name: string
  }
}

export type EquipmentResponse = {
  central: CentralAggregatedResponse
  devices: PartitionDeviceAggregatedResponse[]
}

export type EquipmentsQuery = {
  centralId?: string
  partitionId?: string
} & Partial<Paging> &
  Partial<Sort> &
  Partial<QueryRange>
