import { UpdateDeviceRequest } from 'services/device/types'

import { DeviceDriver } from 'services/device'
import { useMutation } from '@tanstack/react-query'

export type UpdateDeviceMutationRequest = {
  centralId: string
  device: {
    id: string
    name?: string
    partitions?: Array<string>
    contactId?: string
  }
}

async function update(
  deviceId: string,
  centralId: string,
  payload: UpdateDeviceRequest,
) {
  await DeviceDriver.update(deviceId, centralId, payload)
}

export const usePatchUpdateDevice = () => {
  const { mutate: mutateUpdateDevice, status: updateDeviceMutationStatus } =
    useMutation({
      mutationFn: async (payload: UpdateDeviceMutationRequest) => {
        const { id, ...rest } = payload.device
        await update(id, payload.centralId, rest)
      },
    })

  return { mutateUpdateDevice, updateDeviceMutationStatus }
}
