import { useQuery } from '@tanstack/react-query'

import { Result } from 'services/types'
import buildUrl from 'utilities/buildUrl'
import { UserResponse } from 'services/auth'
import { AccountResponse } from 'services/account/types'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { SOQuery, QuerySO } from './types'

export type Query = {
  page?: number
  accountIds?: (string | undefined)[]
  recordsPerPage?: number
  fields?: (keyof SOQuery)[]
  technician?: (keyof UserResponse)[]
  account?: (keyof AccountResponse)[]
}

export const useQuerySO = ({
  accountIds,
  technician,
  account,
  page = 1,
  recordsPerPage = 15,
  fields,
}: Query) => {
  const offset = (page - 1) * recordsPerPage

  return useQuery({
    queryKey: [
      'query-so',
      accountIds,
      offset,
      recordsPerPage,
      technician,
      fields,
      account,
    ],
    queryFn: async () => {
      const response = await HttpClient.get<Result<QuerySO>>(
        buildUrl({
          route: endpoints.query,
          queryParams: {
            accountIds,
            ...(account && { account }),
            ...(technician && { technician }),
            fields,
            offset,
            recordsPerPage,
          },
        }),
      )

      return response.data
    },
  })
}
