import { InterventionTag } from '../types'

export const getInterventionTitle = (
  tag: InterventionTag,
  contactName = '',
) => {
  const messages: Record<InterventionTag, string> = {
    SAFE_CALL: 'Ligação para',
    POLICE_CALL: 'Ligação para a polícia',
    SEND_MESSAGE: `Ligação para contato ${contactName}`,
    CONTACT_CALL: `Ligação para contato ${contactName}`,
  }

  return messages[tag] ?? 'Ligação para módulo'
}
