import { getDeviceInfo } from 'utilities/devices'
import { DeviceType, PartitionDevice } from 'services/partition/types'
import { CHANGE_TYPE_EN_PT, Partitions } from 'services/serviceOrder'

import { ReactComponent as DeviceFullBattery } from 'assets/svg/deviceFullBattery.svg'
import { ReactComponent as DeviceLowBattery } from 'assets/svg/deviceLowBattery.svg'
import { ReactComponent as AvailableIcon } from 'assets/svg/exclamationMessageBlue.svg'
import { ReactComponent as Share } from 'assets/svg/share.svg'

import { getCommunicationLevelIcon } from 'domains/customer/screens/Equipments/utilities/icons'

import styles from './DeviceInformationCard.module.scss'

interface DeviceInformationCard {
  device: PartitionDevice
  partitions: Partitions[]
  onClick: (device: PartitionDevice) => void
}

export const DeviceInformationCard = ({
  device,
  onClick,
  partitions,
}: DeviceInformationCard) => {
  const hasCommandSuport = !device.model?.name

  const isDeviceShared = (deviceId: string) => {
    const filteredPartitions = partitions.filter((partition) => {
      return partition.devices.find((device) => device.id === deviceId)
    })
    return filteredPartitions.length > 1
  }

  const deviceImageRender = getDeviceInfo(device.type)?.image

  return (
    <div
      key={device.id}
      className={[
        device.lowBattery || device.signal === 0
          ? styles.deviceWithConnectionFail
          : styles.device,
        device.changeType && styles.deviceWithTag,
      ].join(' ')}
      data-testid="device"
      onClick={() => onClick(device)}
    >
      {deviceImageRender}
      <div className={styles.deviceInfo}>
        <div className={styles.tags}>
          {!!device.eventCount && (
            <div className={styles.eventCount}>
              {device.eventCount}
              <AvailableIcon className={styles.eventCountIcon} />
            </div>
          )}
          {device.changeType && (
            <div
              className={[
                styles.tag,
                styles[`${device.changeType.toLowerCase()}Tag`],
              ].join(' ')}
            >
              {CHANGE_TYPE_EN_PT[device.changeType]}
            </div>
          )}
        </div>
        <p>
          {device.type === DeviceType.RemoteControl && device.contact
            ? device.contact.name
            : device.name}
        </p>
        <small>
          {getDeviceInfo(device.type)?.type} {device.model?.name}
        </small>
        {device.type !== DeviceType.RemoteControl && hasCommandSuport && (
          <div className={styles.deviceStatus}>
            <div className={styles.batteryIcon}>
              {device.lowBattery ? (
                <DeviceLowBattery aria-label="device-low-battery-icon" />
              ) : (
                <DeviceFullBattery aria-label="device-full-battery-icon" />
              )}
            </div>
            <div className={styles.wifiIcon}>
              {getCommunicationLevelIcon(device.signal || 0, device.status)}
            </div>
            <div className={styles.shareIcon}>
              {isDeviceShared(device.id) && <Share aria-label="share-icon" />}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
