import { useState } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  Button,
  ButtonV2,
  Icon,
  Modal,
  ModalV2,
  Popover,
  Toggle,
} from 'components'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'

import {
  useFetchCars,
  useFetchTacticals,
  useInfiniteFetchUsers,
  useStartTacticalDuty,
} from 'services/displacementMap'

import styles from './StartTacticalDuty.module.scss'
import { MarkerType, Tactical } from 'services/displacementMap/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { VehicleAggregatedQueryResponse } from 'services/vehicle'
import { UserQueryResult } from 'services/auth'
import { useMap, useDisplacementModal } from '../../../../contexts'
import { useDebounce, useUserInfo } from 'shared/hooks'
import joinClassNames from 'utilities/joinClassNames'

interface StartDuty {
  vehicleId: string
  userId: string
  allowMultipleLinks?: boolean
  allowOccurrenceDiscovery?: boolean
}

export const schema = Joi.object<StartDuty>({
  vehicleId: Joi.string().required(),
  userId: Joi.string().required(),
  allowMultipleLinks: Joi.boolean().optional(),
  allowOccurrenceDiscovery: Joi.boolean().optional(),
})

type RequestFeedback = null | {
  title: string
  body: string
  tacticalCreated?: Tactical & MarkerType
}

export const StartTacticalDuty = () => {
  const [requestFeedback, setRequestFeedback] = useState<RequestFeedback>(null)
  const { handleRoute } = useMap()
  const { userInfo } = useUserInfo()
  const { handleCloseModal } = useDisplacementModal()

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isValid },
    register,
  } = useForm<StartDuty>({
    mode: 'all',
    defaultValues: {
      allowOccurrenceDiscovery: true,
    },
    resolver: joiResolver(schema),
  })

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

  const [filters, setFilters] = useState({
    licensePlate: '',
    user: '',
  })

  const { mutate } = useStartTacticalDuty()
  const {
    data: users,
    isFetching: loadingDrivers,
    fetchNextPage: fetchNextPageUsers,
  } = useInfiniteFetchUsers({ ...(filters.user && { name: filters.user }) })

  const { data: carsOptions, isLoading: loadingCars } = useFetchCars({
    ...(filters.licensePlate && { licensePlate: filters.licensePlate }),
  })
  const { data: agentDuty } = useFetchTacticals(userInfo.id)

  const carsOnDuty = carsOptions?.data.filter((car) => car.driver.id)
  const agentsOnVehicle = agentDuty?.map(
    (agent) => agent.vehicle.id && agent.agentId,
  )

  const onSubmit = (data: StartDuty) => {
    const userName = users?.find((user) => user.id === data.userId)?.name || ''

    mutate(data, {
      onSuccess: (success) => {
        setRequestFeedback({
          title: 'Plantão tático iniciado com sucesso',
          body: `${userName} está apto a atender ocorrências enviadas para deslocamento.`,
          tacticalCreated: success,
        })
      },
      onError: () => {
        setRequestFeedback({
          title: 'Erro ao iniciar plantão tático',
          body: `Não foi possível iniciar o plantão do agente ${userName} Tente novamente.`,
        })
      },
    })
  }

  const handleFilter = useDebounce((newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }))
  }, 700)

  const advancedOptionsChanged =
    !watch('allowOccurrenceDiscovery') || watch('allowMultipleLinks')

  return (
    <ModalV2.Root isOpen onClose={handleCloseModal}>
      <ModalV2.Content className={styles.container}>
        <ModalV2.Title>
          {requestFeedback?.title ?? 'Iniciar plantão tático remotamente'}
        </ModalV2.Title>

        {requestFeedback ? (
          <section className={styles.errorBody}>
            <p>{requestFeedback.body}</p>
            <Modal.Footer>
              <Button
                type="secondary"
                buttonTitle="Fechar"
                onClick={() => {
                  handleCloseModal()
                  requestFeedback.tacticalCreated &&
                    handleRoute({
                      marker: requestFeedback.tacticalCreated,
                      type: 'tactical',
                    })
                }}
              />
            </Modal.Footer>
          </section>
        ) : (
          <form
            onSubmit={handleSubmit((data) => {
              if (!advancedOptionsChanged) {
                onSubmit(data)
              }
            })}
          >
            <Combobox
              label={{ text: 'Tático' }}
              items={parseDataToComboboxV2(users ?? [], 'name')}
              isLoading={loadingDrivers}
              onEndReached={fetchNextPageUsers}
              {...register('userId')}
              onChange={(selected) => {
                const agent = selected as ComboboxItem<UserQueryResult>
                setValue('userId', agent.value.id, { shouldValidate: true })
              }}
              onSearch={(search) => handleFilter({ user: search })}
              isDisabled={(item) => {
                const { value } = item as ComboboxItem<UserQueryResult>
                return agentsOnVehicle?.includes(value.id) ?? false
              }}
            />
            <Combobox
              label={{ text: 'Viatura' }}
              items={parseDataToComboboxV2(
                carsOptions?.data || [],
                'licensePlate',
              )}
              onSearch={(search) => handleFilter({ licensePlate: search })}
              isLoading={loadingCars}
              {...register('vehicleId')}
              onChange={(selected) => {
                const vehicle =
                  selected as ComboboxItem<VehicleAggregatedQueryResponse>
                setValue('vehicleId', vehicle.value.id, {
                  shouldValidate: true,
                })
              }}
              isDisabled={(item) => {
                const { value } =
                  item as ComboboxItem<VehicleAggregatedQueryResponse>
                return carsOnDuty?.includes(value) ?? false
              }}
            />
            <ButtonV2
              appearance="tertiary"
              onClick={() => setShowAdvancedOptions((old) => !old)}
            >
              <Icon
                height={6}
                name="arrow-down"
                className={joinClassNames(
                  styles.arrow,
                  showAdvancedOptions && styles.rotate,
                )}
              />
              Opções avançadas
            </ButtonV2>
            <div
              className={joinClassNames(
                styles.showAdvancedOptions,
                showAdvancedOptions && styles.visible,
              )}
            >
              <div>
                <Toggle
                  {...register('allowOccurrenceDiscovery')}
                  onChange={(event) =>
                    setValue('allowOccurrenceDiscovery', event.target.checked)
                  }
                />
                Pode ser atribuído a ocorrências de forma automática
              </div>

              <div>
                <Toggle
                  {...register('allowMultipleLinks')}
                  onChange={(event) =>
                    setValue('allowMultipleLinks', event.target.checked)
                  }
                />
                Pode realizar atendimentos simultâneos
              </div>
            </div>

            <ModalV2.Footer>
              <ModalV2.Close asChild>
                <ButtonV2 appearance="tertiary" onClick={handleCloseModal}>
                  Cancelar
                </ButtonV2>
              </ModalV2.Close>

              <Popover.Root>
                <Popover.Trigger asChild>
                  <ButtonV2
                    type="submit"
                    appearance="primary"
                    disabled={!isValid}
                  >
                    Confirmar
                  </ButtonV2>
                </Popover.Trigger>
                {advancedOptionsChanged && (
                  <Popover.Content position="bottom" className={styles.popover}>
                    <span>
                      <Icon name="warning" />
                      Confirmar início de plantão
                    </span>
                    <p>
                      {`Tem certeza que deseja iniciar o plantão tático de “
                      ${users.filter((user) => user.id === watch('userId'))?.[0]?.name}” com esta configuração?`}
                    </p>

                    <div>
                      <Popover.Close asChild>
                        <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
                      </Popover.Close>
                      <ButtonV2
                        appearance="primary"
                        onClick={() => onSubmit(watch())}
                      >
                        Confirmar
                      </ButtonV2>
                    </div>
                  </Popover.Content>
                )}
              </Popover.Root>
            </ModalV2.Footer>
          </form>
        )}
      </ModalV2.Content>
    </ModalV2.Root>
  )
}
