import { FC } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Separator } from '../../assets/svg/breadcrumbSeparator.svg'

import styles from './Breadcrumbs.module.scss'

export type BreadcrumbsItem = {
  title: string
  href: string
}

export type BreadcrumbsProps = {
  items: BreadcrumbsItem[]
}

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const items = [...props.items]
  const lastItem = items.pop()

  return (
    <div className={styles.wrapper}>
      {items.map((breadcrumb, index) => {
        return (
          <div key={index}>
            <Link className={styles.link} to={breadcrumb.href}>
              {breadcrumb.title}
            </Link>
            <Separator className={styles.separator} />
          </div>
        )
      })}
      <div className={[styles.link, styles.lastItem].join(' ')}>
        {lastItem?.title.toUpperCase()}
      </div>
    </div>
  )
}
