import { ConditionOption, ConditionRuleOption } from '../types'
import { ReactComponent as OccurrenceState } from 'domains/attendancePolicy/assets/svg/occurenceState.svg'
import { ReactComponent as EventMitigation } from 'domains/attendancePolicy/assets/svg/eventMitigation.svg'
import { ReactComponent as Tags } from 'domains/attendancePolicy/assets/svg/tags.svg'
import { ReactComponent as ServiceOrder } from 'domains/attendancePolicy/assets/svg/serviceOrder.svg'
import { ReactComponent as EventType } from 'domains/attendancePolicy/assets/svg/eventTypes.svg'
import { ReactComponent as DislocationLimit } from 'domains/attendancePolicy/assets/svg/dislocationLimit.svg'
import { ReactComponent as Padlock } from 'domains/attendancePolicy/assets/svg/padlock.svg'
import { ReactComponent as Bond } from 'domains/attendancePolicy/assets/svg/bond.svg'
import { ReactComponent as Shield } from 'domains/attendancePolicy/assets/svg/shield.svg'
import { ReactComponent as OccurrenceFinishType } from 'domains/attendancePolicy/assets/svg/occurrenceFinish.svg'
import { ReactComponent as Detection } from 'domains/attendancePolicy/assets/svg/detection.svg'

export const conditionOptions: ConditionRuleOption[] = [
  {
    id: '1',
    title: 'Estado da ocorrência',
    description:
      'Analisa a etapa do fluxo de atendimento em que uma ocorrência está',
    icon: OccurrenceState,
    formType: ConditionOption.OCCURRENCE_STATE_NAMES,
  },
  {
    id: '2',
    title: 'Mitigação de eventos',
    description:
      'Verifica se existem eventos anteriores atenuados por novos acontecimentos',
    icon: EventMitigation,
    formType: ConditionOption.OCCURRENCE_EVENTS_MITIGATION,
  },
  {
    id: '3',
    title: 'Tags',
    description:
      'Examina os marcadores adicionados que identificam ou categorizam a ocorrência',
    icon: Tags,
    formType: ConditionOption.TAGS,
  },
  {
    id: '4',
    title: 'Ordens de serviço',
    description:
      'Verifica a existência de Ordens de Serviço abertas com características definidas',
    icon: ServiceOrder,
    formType: ConditionOption.SERVICE_ORDER,
  },
  {
    id: '5',
    title: 'Tipos de evento',
    description: 'Examina os tipos de eventos pertencentes a uma ocorrência',
    icon: EventType,
    formType: ConditionOption.OCCURRENCE_HAS_EVENTS,
  },
  {
    id: '6',
    title: 'Limite de deslocamento',
    description:
      'Analisa se a conta atingiu o limite de deslocamentos permitidos',
    icon: DislocationLimit,
    formType: ConditionOption.ACCOUNT_TRAVEL_LIMIT,
  },
  {
    id: '7',
    title: 'Status da partição',
    description:
      'Confere o status das partições do patrimônio durante a ocorrência',
    icon: Padlock,
    formType: ConditionOption.CENTRAL_PARTITIONS_STATUS,
  },
  {
    id: '8',
    title: 'Tipo de serviço',
    description:
      'Examina se o patrimônio da ocorrência possui o tipo de serviço informado',
    icon: Shield,
    formType: ConditionOption.SERVICE_TYPE,
  },
  {
    id: '9',
    title: 'Número de dispositivos',
    description:
      'Contabiliza o número de dispositivos atrelados à central da ocorrência',
    icon: Bond,
    formType: ConditionOption.DEVICE_QUANTITY,
  },
  {
    id: '10',
    title: 'Tipo de finalização',
    description: 'Verifica a maneira na qual a ocorrência foi finalizada',
    icon: OccurrenceFinishType,
    formType: ConditionOption.OCCURRENCE_FINISHED_MANUALLY,
  },
  {
    id: '11',
    title: 'Detecção em imagem',
    description:
      'Verifica se existe indivíduos ou pets na imagem recebida pelo evento',
    icon: Detection,
    formType: ConditionOption.EVENT_IMAGE_DETECTIONS,
  },
]
