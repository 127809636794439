import { useMemo } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useToast } from 'shared/hooks'

import { Loader } from 'components'
import { AttendPolicyFormGroup } from 'domains/attendancePolicy/components'
import { usePutAttendancePolicy } from 'shared/hooks/services/attendancePolicy/usePutAttendancePolicy'
import { AttendancePolicyPayload } from 'services/attendancePolicy/types'
import { useGetAttendancePolicy } from 'shared/hooks/services/attendancePolicy/useGetAttendancePolicy'

export const UpdateAttendancePolicy = () => {
  const { addToast } = useToast()

  const location = useLocation()
  const navigate = useNavigate()

  const memorizedAttendancePolicyId = useMemo(() => {
    if (!location.state) return undefined
    return location.state.attendancePolicyId
  }, [location])

  const { attendancePolicy, isFetching } = useGetAttendancePolicy(
    memorizedAttendancePolicyId || '',
    Boolean(memorizedAttendancePolicyId),
  )

  const { mutateUpdateAttendancePolicy, statusUpdateAttendancePolicy } =
    usePutAttendancePolicy()

  const onSubmit = (id: string, payload: AttendancePolicyPayload) => {
    mutateUpdateAttendancePolicy(
      { id, payload },
      {
        onSuccess: () => {
          addToast({ message: 'Regra editada com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          addToast({
            message: 'Erro ao editar regra. Tente novamente.',
            type: 'alert',
          })
        },
      },
    )
  }

  if (
    !attendancePolicy ||
    isFetching ||
    statusUpdateAttendancePolicy === 'pending'
  )
    return <Loader isVisible />

  return (
    <AttendPolicyFormGroup
      attendancePolicy={attendancePolicy}
      onSubmit={(dataForm) => onSubmit(attendancePolicy.id, dataForm)}
    />
  )
}
