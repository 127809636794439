import { ComponentProps } from 'react'

import joinClassNames from 'utilities/joinClassNames'

import styles from './styles.module.scss'

const Root = ({ className, ...props }: ComponentProps<'div'>) => (
  <div className={joinClassNames(styles.root, className)} {...props} />
)

const Title = ({ className, ...props }: ComponentProps<'h4'>) => (
  <h4 className={joinClassNames(styles.title, className)} {...props} />
)

const Content = ({ className, ...props }: ComponentProps<'span'>) => (
  <span className={joinClassNames(styles.content, className)} {...props} />
)

const Info = {
  Root,
  Title,
  Content,
}

export default Info
