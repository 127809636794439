import { useQuery } from '@tanstack/react-query'
import HttpClient from 'services/httpClient'
import { endpoints } from './endpoints'
import { Address } from 'services/address/types'

const POSTAL_CODE_FULL_LENGTH = 8

const fetchAddress = async (postalCode: string) => {
  return await HttpClient.get<Address>(endpoints.getAddress, {
    params: {
      postalCode,
    },
  })
}

export const useGetAddress = (postalCode: string) =>
  useQuery({
    queryKey: ['postalCode', postalCode],
    queryFn: async () => (await fetchAddress(postalCode)).data,
    enabled: postalCode?.length === POSTAL_CODE_FULL_LENGTH,
  })
