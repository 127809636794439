import HttpClient from '../httpClient'
import {
  AllowForcedArm,
  CommandPayload,
  WriteCommandPayload,
  MaintenanceModePayload,
  ConfigureSafeCallPayload,
  WifiConfigurationCommandPayload,
  AnnulDeviceCommandPayload,
  ConfigureDoorCommandPayload,
  ConfigureSirenCommandPayload,
  ConfigurePhotoSensorCommandPayload,
} from './types'

export interface CommandDriver {
  allowForcedArm(centralId: string, payload: AllowForcedArm): Promise<void>
  infoRequest(centralId: string, payload: CommandPayload): Promise<void>
  write(centralId: string, payload: WriteCommandPayload): Promise<void>
  reboot(centralId: string, payload: CommandPayload): Promise<void>
  setDefaultMode(
    centralId: string,
    payload: MaintenanceModePayload,
  ): Promise<void>
  setMaintenanceMode(
    centralId: string,
    payload: MaintenanceModePayload,
  ): Promise<void>
  configureSafeCall(
    centralId: string,
    payload: ConfigureSafeCallPayload,
  ): Promise<void>
  wifiConfiguration(
    centralId: string,
    payload: WifiConfigurationCommandPayload,
  ): Promise<void>
  annulDevice(
    centralId: string,
    payload: AnnulDeviceCommandPayload,
  ): Promise<void>
  configureDoor(
    centralId: string,
    payload: ConfigureDoorCommandPayload,
  ): Promise<void>
  configureSiren(
    centralId: string,
    payload: ConfigureSirenCommandPayload,
  ): Promise<void>
  configurePhotosensor(
    centralId: string,
    payload: ConfigurePhotoSensorCommandPayload,
  ): Promise<void>
}

class CommandDriverImpl implements CommandDriver {
  constructor(private readonly httpClient = HttpClient) {}

  public async allowForcedArm(
    centralId: string,
    payload: AllowForcedArm,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/allowForcedArm`,
      { ...payload },
    )
  }

  public async infoRequest(
    centralId: string,
    payload: CommandPayload,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/infoRequest`,
      { ...payload },
    )
  }

  public async write(
    centralId: string,
    payload: WriteCommandPayload,
  ): Promise<void> {
    return await this.httpClient.post(`/commands/central/${centralId}/write`, {
      ...payload,
    })
  }

  public async reboot(
    centralId: string,
    payload: CommandPayload,
  ): Promise<void> {
    return await this.httpClient.post(`/commands/central/${centralId}/reboot`, {
      ...payload,
    })
  }

  public async setDefaultMode(
    centralId: string,
    payload: MaintenanceModePayload,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/defaultMode`,
      { ...payload },
    )
  }

  public async setMaintenanceMode(
    centralId: string,
    payload: MaintenanceModePayload,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/maintenanceMode`,
      { ...payload },
    )
  }

  public async configureSafeCall(
    centralId: string,
    payload: ConfigureSafeCallPayload,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/configureSafeCall`,
      {
        ...payload,
      },
    )
  }

  public async wifiConfiguration(
    centralId: string,
    payload: WifiConfigurationCommandPayload,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/configureWifi`,
      {
        ...payload,
      },
    )
  }

  public async annulDevice(
    centralId: string,
    payload: AnnulDeviceCommandPayload,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/annulDevice`,
      { ...payload },
    )
  }

  public async configureDoor(
    centralId: string,
    payload: ConfigureDoorCommandPayload,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/configureDoor`,
      {
        ...payload,
      },
    )
  }

  public async configureSiren(
    centralId: string,
    payload: ConfigureSirenCommandPayload,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/configureSiren`,
      {
        ...payload,
      },
    )
  }

  public async configurePhotosensor(
    centralId: string,
    payload: ConfigurePhotoSensorCommandPayload,
  ): Promise<void> {
    return await this.httpClient.post(
      `/commands/central/${centralId}/configurePhotoSensor`,
      {
        ...payload,
      },
    )
  }
}

export default new CommandDriverImpl()
