import {
  ServiceOrderHistory,
  AccountFragment,
  CustomerFragment,
  SERVICE_ORDER_STATUS_ENUM,
  TechnicianFragment,
  SERVICE_ORDER_TYPE_ENUM,
  ServiceOrderContact,
  TagInput,
  SchedulePeriod,
} from '../../services/serviceOrder'
import { Timestamps } from '../../services/types'
import { ServiceOrderLabelInput } from 'services/serviceOrderLabel/types'

export type ServiceOrderCancel = {
  password: string
  notes: string
}

export type ServiceOrderToEdit = {
  technicianId?: string
  status?: SERVICE_ORDER_STATUS_ENUM
  id?: string
  account?: string
  number?: number
}

export type ServiceOrderAggregatedQuery = {
  id: string
  number: number
  account: AccountFragment
  customer: CustomerFragment
  technician: TechnicianFragment
  status: SERVICE_ORDER_STATUS_ENUM
  history: ServiceOrderHistory[]
} & Timestamps

export type ServiceOrderPayload = {
  id: string
  userId: string
  number: number
  customer: {
    id: string
    name: string
  }
  account: {
    id: string
    code: string
  }
  type?: SERVICE_ORDER_TYPE_ENUM
  contact?: ServiceOrderContact
  technician?: TechnicianFragment
  note: string
  status: SERVICE_ORDER_STATUS_ENUM
  scheduleDate?: number
  schedulePeriod?: SchedulePeriod
  tags?: TagInput[]
  labels: ServiceOrderLabelInput[]
}

export type ServiceOrderSchedule = Partial<ServiceOrderPayload>

export type ServiceOrderTypeArray = {
  id: string
  type: string
}

export type ServiceOrderStatusArray = {
  id: string
  status: string
}

export enum SERVICE_ORDER_STATUS_HEADER {
  'SYSTEM_BEFORE_SO' = 'SYSTEM_BEFORE_SO',
  'SYSTEM_AFTER_SO' = 'SYSTEM_AFTER_SO',
  'EVENTS' = 'EVENTS',
}

export enum SERVICE_ORDER_STATUS_HEADER_EN_PT {
  'SYSTEM_BEFORE_SO' = 'Sistema antes da OS',
  'SYSTEM_AFTER_SO' = 'Sistema após a OS',
  'EVENTS' = 'Eventos',
}

export type ServiceOrderImagePayload = {
  serviceOrderId: string
  customerId: string
  accountId: string
}
