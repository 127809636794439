import { AxiosInstance, AxiosResponse } from 'axios'
import HttpClient from '../httpClient'
import {
  AggregatedAttendPolicyResponse,
  AttendancePolicyQueryRequest,
  AttendancePolicyQueryResponse,
  AttendancePolicyPayload,
} from 'services/attendancePolicy/types'
import { Result } from 'services/types'

export interface AttendancePolicyDriver {
  findById(id: string): Promise<AggregatedAttendPolicyResponse>
  query(
    query: AttendancePolicyQueryRequest,
  ): Promise<Result<AttendancePolicyQueryResponse>>
  create(attendPolicy: AttendancePolicyPayload): Promise<void>
  update(id: string, attendancePolicy: AttendancePolicyPayload): Promise<void>
  delete(attendancePolicyId: string): Promise<AxiosResponse<void>>
}

export class AttendancePolicyDriverImpl implements AttendancePolicyDriver {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async findById(id: string): Promise<AggregatedAttendPolicyResponse> {
    return this.httpClient
      .get<AggregatedAttendPolicyResponse>(`/attendancePolicy/${id}/find`)
      .then((res) => res.data)
  }

  public async create(attendPolicy: AttendancePolicyPayload): Promise<void> {
    return this.httpClient.post('/attendancePolicy', attendPolicy)
  }

  public async query(
    query: AttendancePolicyQueryRequest,
  ): Promise<Result<AttendancePolicyQueryResponse>> {
    const result = await this.httpClient.get<
      Result<AttendancePolicyQueryResponse>
    >('/attendancePolicies', { params: query })

    return result.data
  }

  public async post(
    query: AttendancePolicyQueryRequest,
  ): Promise<Result<AttendancePolicyQueryResponse>> {
    const result = await this.httpClient.get<
      Result<AttendancePolicyQueryResponse>
    >('/attendancePolicies', { params: query })

    return result.data
  }

  public async update(
    id: string,
    attendPolicy: AttendancePolicyPayload,
  ): Promise<void> {
    return this.httpClient.put(`/attendancePolicy/${id}`, attendPolicy)
  }

  public async delete(
    attendancePolicyId: string,
  ): Promise<AxiosResponse<void>> {
    const result = await this.httpClient.delete(
      `/attendancePolicy/${attendancePolicyId}`,
    )
    return result
  }
}

export const AttendancePolicyDriver = new AttendancePolicyDriverImpl()
