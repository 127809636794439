import { Tooltip } from 'components'

import styles from './AttendanceProfileTooltip.module.scss'
import { UserCoverageFragment } from 'services/auth'
import { RefObject } from 'react'

type AttendanceProfileTooltipProps = {
  parentRef: RefObject<HTMLElement>
  coverages: {
    primary: UserCoverageFragment[]
    secondary: UserCoverageFragment[]
  }
  isVisible: boolean
}

export const AttendanceProfileTooltip = ({
  parentRef,
  coverages,
  isVisible,
}: AttendanceProfileTooltipProps) => {
  return (
    <Tooltip parentRef={parentRef} type="informative" isVisible={isVisible}>
      <div className={styles.tooltipWrapper}>
        <div className={styles.contentWrapper} aria-label="primary-coverage">
          <span>Perfis:</span>
          <div>
            {coverages.primary.map((coverage) => (
              <small className={styles.item} key={coverage.id}>
                {coverage.name}
              </small>
            ))}
          </div>
        </div>
        <div className={styles.contentWrapper} aria-label="transboard-coverage">
          {coverages.secondary.length ? (
            <>
              <span>Perfis de cobertura:</span>
              <div>
                {coverages.secondary.map((coverage) => (
                  <small className={styles.item} key={coverage.id}>
                    {coverage.name}
                  </small>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Tooltip>
  )
}
