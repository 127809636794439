import React, { useEffect, useRef } from 'react'
import { EventApi } from '@fullcalendar/core'

import { formatter, getSchedulePeriod, getServiceOrderStatus } from './utils'

import styles from './styles.module.scss'
import { useToggle } from 'shared/hooks'
import { SERVICE_ORDER_STATUS_ENUM } from 'services/serviceOrder'
import { Popover } from 'components'
import { RichTooltip } from './components'

type ServiceOrderStatusCardProps = {
  event: EventApi
}

const ServiceOrderStatusCard: React.FC<ServiceOrderStatusCardProps> = ({
  event,
}) => {
  const { status, number, schedulePeriod, customer, accountCode } =
    event.extendedProps

  const startDate = formatter.format(event.start?.getTime())
  const endDate = formatter.format(event.end?.getTime())

  const tooltipRef = useRef<HTMLDivElement>(null)
  const tooltip = useToggle()

  const openStatus = status === SERVICE_ORDER_STATUS_ENUM.OPEN

  useEffect(() => {
    if (openStatus) {
      tooltip.show()
    }
  }, [openStatus, tooltip])

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div className={styles.container}>
          <article
            className={`${styles[status]} ${
              !schedulePeriod && styles.editing
            } ${openStatus && styles.open}`}
            ref={tooltipRef}
          >
            <header>
              <span>{getServiceOrderStatus(status)}</span>
              {number && <span className={styles.osNumber}>{number}</span>}
            </header>
            <section>
              <h2>{`${accountCode} - ${customer}`}</h2>
              {schedulePeriod ? (
                <p>{getSchedulePeriod(schedulePeriod)}</p>
              ) : (
                <p>
                  {startDate}-{endDate}
                </p>
              )}
            </section>
          </article>
        </div>
      </Popover.Trigger>
      <Popover.Content>
        <RichTooltip serviceOrderId={event.id} />
      </Popover.Content>
    </Popover.Root>
  )
}

export default ServiceOrderStatusCard
