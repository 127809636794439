import { PatrimonyWithAccount } from 'services/patrimony/types'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import buildUrl from 'services/utils/buildUrl'
import HttpClient from 'services/httpClient'
import { endpoints } from './endpoints'

export async function findPatrimony(
  patrimonyId = '',
): Promise<PatrimonyWithAccount> {
  const response = await HttpClient.get(
    buildUrl({ route: endpoints.findPatrimony, params: { patrimonyId } }),
  )
  return response.data
}

export const useGetPatrimony = (
  patrimonyId?: string,
): UseQueryResult<PatrimonyWithAccount, unknown> =>
  useQuery({
    queryKey: ['get-patrimony', patrimonyId],
    queryFn: async () => await findPatrimony(patrimonyId),
    enabled: !!patrimonyId,

    refetchOnMount: false,
  })
