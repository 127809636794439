import { ReactElement } from 'react'

import { dateNow } from 'utilities/date'
import { Icon, SOTag, Tag } from 'components'
import {
  Intervention,
  ServiceOrderStatus,
  schedulePeriods,
} from 'services/serviceOrderV2'

export const handleTag = (intervention: Intervention) => {
  switch (intervention.typeName) {
    case 'STATUS_CHANGE':
      return <SOTag status={intervention.attributes.status} size="md" />
    case 'SO_CREATED':
      return <SOTag status="OPEN" size="md" />
    case 'SO_CHANGE':
      return (
        <Tag color="outlined" size="md">
          <Icon name="edit" width={10} color="element" />
          Edição
        </Tag>
      )
    case 'MANUAL_RECORD': {
      const isHidden = intervention.notes?.[0].hidden

      return (
        <Tag color="outlined" size="md">
          <Icon name={isHidden ? 'hide' : 'view'} width={10} color="element" />
          {isHidden ? 'Nota interna' : 'Nota pública'}
        </Tag>
      )
    }
    case 'PHONE_CALL':
      return (
        <Tag color="outlined" size="md">
          <Icon name="phone" width={10} color="element" />
          Ligação
        </Tag>
      )
    case 'SEND_MESSAGE':
      return (
        <Tag color="outlined" size="md">
          <Icon name="whatsapp" width={10} color="element" />
          Whatsapp
        </Tag>
      )
  }
}

export const handleDownload = async (images: { id: string; url: string }[]) => {
  for (const image of images) {
    const response = await fetch(image.url)
    const blob = await response.blob()
    const link = document.createElement('a')

    link.href = URL.createObjectURL(blob)
    link.download = `image-${image.id}.jpg`
    link.click()

    URL.revokeObjectURL(link.href)
  }
}

export const handleContent = (intervention: Intervention) => {
  if (intervention.typeName === 'SO_CREATED') {
    return (
      <>
        <span>
          <strong>Técnico: </strong>
          {intervention.attributes.technician?.name || '--'}
        </span>
        <span>
          <strong>Solicitante: </strong>
          {intervention.attributes.labels?.filter(
            (label) => label.type === 'ORIGIN',
          )?.[0]?.description || '--'}
        </span>
        <span>
          <strong>Motivo: </strong>
          {intervention.attributes.labels?.[0]?.description || '--'}
        </span>
      </>
    )
  }

  if (intervention.typeName === 'STATUS_CHANGE') {
    const statuses: Record<ServiceOrderStatus, ReactElement> = {
      OPEN: (
        <>
          <span>
            <strong>Técnico: </strong>
            {intervention.attributes.technician?.name || '--'}
          </span>
          <span>
            <strong>Solicitante: </strong>
            {intervention.attributes.labels?.filter(
              (label) => label.type === 'ORIGIN',
            )?.[0]?.description || '--'}
          </span>
          <span>
            <strong>Motivo: </strong>
            {intervention.attributes.labels?.filter(
              (label) => label.type === 'REASON',
            )?.[0]?.description || '--'}
          </span>
        </>
      ),
      PAUSED: (
        <>
          <span>
            <strong>Motivo: </strong>
            {intervention.attributes.labels?.[0]?.description || '--'}
          </span>
        </>
      ),
      SCHEDULED: (
        <>
          <span>
            <strong>Data: </strong>
            {dateNow(intervention.attributes.scheduleDate) || '--'}
          </span>
          <span>
            <strong>Período do agendamento: </strong>
            {schedulePeriods[intervention.attributes.schedulePeriod] || '--'}
          </span>
          <span>
            <strong>Técnico: </strong>
            {intervention.attributes.technician?.name || '--'}
          </span>
        </>
      ),
      IN_VALIDATION: (
        <>
          <span>
            <strong>Problema: </strong>
            {intervention.attributes.labels?.filter(
              (label) => label.type === 'ISSUE',
            )?.[0]?.description || '--'}
          </span>
          <span>
            <strong>Solução: </strong>
            {intervention.attributes.labels?.filter(
              (label) => label.type === 'RESOLUTION',
            )?.[0]?.description || '--'}
          </span>
        </>
      ),
      RESCHEDULED: (
        <>
          <span>
            <strong>Data: </strong>
            {dateNow(intervention.attributes.scheduleDate) || '--'}
          </span>
          <span>
            <strong>Período do agendamento: </strong>
            {schedulePeriods[intervention.attributes.schedulePeriod] || '--'}
          </span>
          <span>
            <strong>Técnico: </strong>
            {intervention.attributes.technician?.name || '--'}
          </span>
        </>
      ),
      FINISHED: <></>,
      CANCELED: <></>,
      DISAPPROVED: <></>,
      IN_PROGRESS: <></>,
    }

    return statuses[intervention.attributes.status]
  }

  return <></>
}
