import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'

import { ServiceOrderForm } from '../../components'
import { useToast, useUserInfo } from 'shared/hooks'
import { Loader } from 'components'

import {
  ServiceOrderPayload,
  useGetSO,
  usePutServiceOrder,
} from 'services/serviceOrderV2'
import styles from './styles.module.scss'

const Update = () => {
  const { serviceOrderId } = useParams()
  const { addToast } = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const { userInfo } = useUserInfo()
  const { mutate } = usePutServiceOrder(serviceOrderId)
  const { data: serviceOrder } = useGetSO(serviceOrderId)

  const onSubmit = useCallback(
    (data: ServiceOrderPayload) => {
      if (serviceOrderId) {
        mutate(
          { ...data, id: serviceOrderId, userId: userInfo?.id },
          {
            onSuccess: () => {
              addToast({ message: 'Ordem de serviço editada com sucesso.' })

              if (location.state?.shouldReturnToSOInfo) {
                return navigate(`/soV2/${data.id}`)
              }

              navigate(-1)
            },
            onError: () => {
              addToast({
                type: 'alert',
                message:
                  'Não foi possível editar a ordem de serviço. Tente novamente',
              })
            },
          },
        )
      }
    },
    [addToast, location, navigate],
  )

  if (!serviceOrder) return <Loader isVisible />

  return (
    <main className={styles.main}>
      <div className={styles.title}>
        <span>Editar ordem de serviço</span>
        <hr />
      </div>

      <ServiceOrderForm onSubmit={onSubmit} serviceOrder={serviceOrder} />
    </main>
  )
}

export default Update
