export enum Tags {
  RONDA_DESLOCAMENTO = 'rondaDeslocamento',
  RONDA_VIRTUAL = 'rondaVirtual',
  RONDA_PROGRAMADA_PAGA = 'rondaProgramadaPaga',
}

export const predefinedOptions = [
  {
    message: 'Ronda programada',
    tags: [Tags.RONDA_DESLOCAMENTO],
  },
  {
    message: 'Ronda virtual',
    tags: [Tags.RONDA_VIRTUAL],
  },
  {
    message: 'Ronda programada paga',
    tags: [Tags.RONDA_PROGRAMADA_PAGA],
  },
]

export function calcTimestamp(data: number, hora: string) {
  const [hours, minutes] = hora.split(':')

  const timestamp = new Date(data).setHours(Number(hours), Number(minutes))

  return timestamp
}

export type PatrolStatusLabel = 'Ativo' | 'Inativo'

type PatrolActive = {
  value: boolean | undefined
  label: PatrolStatusLabel
}

const Status: Record<PatrolStatusLabel, PatrolActive> = {
  Ativo: {
    label: 'Ativo',
    value: true,
  },

  Inativo: {
    label: 'Inativo',
    value: false,
  },
}

export const handlePatrolStatusLabel = (
  status: PatrolStatusLabel,
): PatrolActive => Status[status]
