import { OccurrenceTypes } from 'services/displacementMap/types'

export const lineIcon = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 2,
}

export const traceOptions = {
  draggable: false,
  editable: false,
  clickable: false,
  strokeOpacity: 0,
  icons: [
    {
      icon: lineIcon,
      offset: '0',
      repeat: '10px',
    },
  ],
}

export const mapProps = {
  zoom: 14,
  mapContainerStyle: {
    width: 'calc(100vw - 60px)',
    height: '100vh',
  },
}

export const defaultMap = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        weight: 1,
      },
    ],
  },
]

export const darkMap = [
  {
    stylers: [
      {
        saturation: -30,
      },
    ],
  },
  {
    elementType: 'geometry',
    stylers: [
      {
        saturation: -70,
      },
      {
        lightness: -60,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -60,
      },
      {
        lightness: -50,
      },
    ],
  },
  {
    elementType: 'labels.text',
    stylers: [
      {
        saturation: -60,
      },
      {
        lightness: -55,
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        saturation: -30,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        saturation: -30,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        weight: 1,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        lightness: 10,
      },
    ],
  },
]

export const colors: Record<OccurrenceTypes, string> = {
  ALARM: '#da291c',
  RESCUE: '#000000',
  EMERGENCY: '#7a57ba',
  IMMINENT: '#dc7135',
  FAILURE: '#f3ae3d',
  COMMERCIAL: '#009F87',
  FINISHED: '#32af04',
  SCHEDULED: '#e4228b',
  MANUAL: '#88714C',
}
