import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useToast, useUserInfo } from 'shared/hooks'
import { usePostWifiConfigurationCommand } from 'shared/hooks/services/commands'
import { WifiConfigurationCommandPayload } from 'services/command/types'

export const usePostAndHandleWifiConfigurationCommand = (
  centralId: string,
  payload: WifiConfigurationCommandPayload,
) => {
  const { addToast } = useToast()
  const queryClient = useQueryClient()
  const { userInfo } = useUserInfo()
  const {
    mutateWifiConfigurationCommand,
    wifiConfigurationCommandMutationStatus,
  } = usePostWifiConfigurationCommand(centralId, {
    ...payload,
    userId: userInfo.id,
  })

  useEffect(() => {
    if (wifiConfigurationCommandMutationStatus === 'success') {
      addToast({
        message: 'Wi-Fi alterada com sucesso.',
      })

      queryClient.invalidateQueries({ queryKey: ['equipments'] })
    }

    if (wifiConfigurationCommandMutationStatus === 'error') {
      addToast({
        message: 'Erro ao alterar Wi-Fi. Tente novamente.',
        type: 'alert',
      })
    }
  }, [addToast, queryClient, wifiConfigurationCommandMutationStatus])

  return {
    mutateWifiConfigurationCommand,
    wifiConfigurationCommandMutationStatus,
  }
}
