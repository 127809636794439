import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'
import { ReactComponent as CopyIcon } from 'assets/svg/copy.svg'

import { Button, Icon, Popover } from 'components'

import { useMakeCall } from 'services/attendance'
import { phoneMasker } from 'utilities/masks'
import { useUserInfo } from 'shared/hooks'

import styles from './styles.module.scss'

interface ConnectionRichTooltipProps {
  phone: string
  ICCID: string
}

const ConnectionRichTooltip = ({
  phone,
  ICCID,
}: ConnectionRichTooltipProps) => {
  const { mutate: call } = useMakeCall()
  const { userInfo } = useUserInfo()
  return (
    <div className={styles.container}>
      <Popover.Close>
        <CloseIcon />
      </Popover.Close>
      <>
        <section>
          <div className={styles.item}>
            <Icon name="phone" width={10} />
            <Button
              type="tertiary"
              buttonTitle="Ligar"
              onClick={() =>
                call({
                  phoneNumber: phone,
                  phoneExtension: userInfo?.phoneExtension,
                })
              }
            />
          </div>
          <div className={styles.item}>
            <p>{phoneMasker(phone)}</p>
            <Button
              type="tertiary"
              icon={CopyIcon}
              onClick={() => navigator.clipboard.writeText(phone)}
            />
          </div>
          <div className={styles.item}>
            <p>{ICCID}</p>
            <Button
              type="tertiary"
              icon={CopyIcon}
              onClick={() => navigator.clipboard.writeText(ICCID)}
            />
          </div>
          <Button type="tertiary" />
        </section>
      </>
    </div>
  )
}

export default ConnectionRichTooltip
