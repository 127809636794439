import { useQuery } from '@tanstack/react-query'
import { DeviceDriver } from 'services/device'
import { ConfigurationProfileResponse } from 'services/partition/types'

type UseGetDeviceTypeProfiles = {
  deviceProfiles: ConfigurationProfileResponse[] | undefined
  isFetchingDeviceProfiles: boolean
}

async function getProfiles(deviceTypeCode: string) {
  const result = await DeviceDriver.getProfiles(deviceTypeCode)

  return result.data
}

export const useGetDeviceTypeProfiles = (
  deviceTypeCode: string,
  enabled: boolean,
): UseGetDeviceTypeProfiles => {
  const { data: deviceProfiles, isFetching: isFetchingDeviceProfiles } =
    useQuery({
      queryKey: ['device-profiles', deviceTypeCode],
      queryFn: () => getProfiles(deviceTypeCode),
      enabled,
    })

  return { deviceProfiles, isFetchingDeviceProfiles }
}
