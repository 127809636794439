import {
  JuridicalPersonConstants,
  NaturalPersonConstants,
  LegalEntityConstants,
} from '../../types'

export const validateNaturalPersonDocumentNumber = (
  document: string,
): boolean => {
  const documentWithoutMask = document.replace(/\D/g, '')
  if (documentWithoutMask.length < NaturalPersonConstants.documentLenght) {
    return false
  }

  const baseDocumentForFirstDigit = cutAndReverseDocument(
    documentWithoutMask,
    NaturalPersonConstants.firstDigitIndex,
  )
  const baseDocumentForSecondDigit = cutAndReverseDocument(
    documentWithoutMask,
    NaturalPersonConstants.secondDigitIndex,
  )

  const documentInputedDigit = documentWithoutMask.substring(9, 11)

  const verifiedDigit =
    getVerifiedDigit(
      baseDocumentForFirstDigit,
      NaturalPersonConstants.personType,
    ).toString() +
    getVerifiedDigit(
      baseDocumentForSecondDigit,
      NaturalPersonConstants.personType,
    ).toString()

  return verifiedDigit === documentInputedDigit
}

export const validateJuridicalPersonDocumentNumber = (
  document: string,
): boolean => {
  const documentWithoutMask = document.replace(/\D/g, '')

  if (documentWithoutMask.length < JuridicalPersonConstants.documentLength) {
    return false
  }

  const baseDocumentForFirstDigit = cutAndReverseDocument(
    documentWithoutMask,
    JuridicalPersonConstants.firstDigitIndex,
  )
  const baseDocumentForSecondDigit = cutAndReverseDocument(
    documentWithoutMask,
    JuridicalPersonConstants.secondDigitIndex,
  )

  const documentInputedDigit = documentWithoutMask.substring(12, 14)

  const verifiedDigit =
    getVerifiedDigit(
      baseDocumentForFirstDigit,
      JuridicalPersonConstants.personType,
    ).toString() +
    getVerifiedDigit(
      baseDocumentForSecondDigit,
      JuridicalPersonConstants.personType,
    ).toString()

  return verifiedDigit === documentInputedDigit
}

const getVerifiedDigit = (document: string, personType: string): number => {
  const multipliedNumbersOfDocument = []
  const minMultiplicator =
    personType === JuridicalPersonConstants.personType
      ? JuridicalPersonConstants.minMultiplicator
      : NaturalPersonConstants.minMultiplicator

  let actualMultiplicator = -1
  for (let i = 0; i < document.length; i++) {
    if (actualMultiplicator < minMultiplicator) {
      actualMultiplicator = LegalEntityConstants.maxMultiplicator
    }
    multipliedNumbersOfDocument.push(+document.charAt(i) * actualMultiplicator)
    actualMultiplicator--
  }

  const digit = multipliedNumbersOfDocument.reduce((acc, cur) => (acc += cur))
  return digit % LegalEntityConstants.module === 10
    ? 0
    : digit % LegalEntityConstants.module
}

const cutAndReverseDocument = (document: string, baseIndex: number): string => {
  return document.substring(0, baseIndex).split('').reverse().join('')
}
