import { DeviceType } from 'services/partition/types'
import { getDeviceInfo } from 'utilities/devices'
import { getDeviceSensorName } from '../../domains/occurrence/utilities/device'

export const getEventDevice = (
  deviceType?: DeviceType,
  commandId?: string,
): string | undefined => {
  if (commandId) return 'App'
  if (deviceType) return getDeviceInfo(deviceType)?.type
  return 'Central'
}

export const getEventDescription = (
  code: string,
  name: string,
  deviceType?: DeviceType,
  sensorType?: number,
): string => {
  const sensorName = getDeviceSensorName(deviceType, sensorType)

  return `${code} - ${name}${sensorName ? ' ' + sensorName : ''}`
}
