import { InterventionTag, OccurrenceTypes } from '../types'

export const occurrenceTypesLabel: Record<OccurrenceTypes, string> = {
  ALARM: 'Alarme',
  RESCUE: 'Socorro',
  FAILURE: 'Falha',
  MANUAL: 'Manual',
  SCHEDULED: 'Periódica',
  FINISHED: 'Finalizado',
  IMMINENT: 'Iminência',
  EMERGENCY: 'Emergência',
  COMMERCIAL: 'Comercial',
}

export const interventionTagLabel: Record<InterventionTag, string> = {
  SAFE_CALL: 'safecall',
  CONTACT_CALL: 'ligacaoContato',
  SEND_MESSAGE: 'envioMensagem',
  POLICE_CALL: 'ligacaoPolicia',
}
