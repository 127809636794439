import { Sections, ViewEntityContent } from 'components/ViewEntities/types'
import { ATTENDANCE_ROLE, CoverageFindResponse } from 'services/coverages'
import { OCCURRENCE_CATEGORY_EN_PT } from 'services/occurrence/types'
import { SERVICE_ORDER_TYPE_EN_PT } from 'services/serviceOrder'
import { CoverageType } from 'shared/types/coverages'

enum CoverageTypeInstruction {
  SERVICE_ORDER_ATTENDANT = 'Atendentes de Ordens de Serviço são usuários que realizam atendimentos remotos de OS e agendamentos de visitas técnicas.',
  TECHNICAL_DISPLACEMENT_OPERATOR = 'Operadores de deslocamento técnico são usuários responsáveis por coordenar as rotas dos agentes no atendimento de Ordens de Serviço (OS).',
  TECHNICAL_AGENT = 'Agentes técnicos são usuários que realizam atendimentos de OS e manutenção de dispositivos.',
  OCCURRENCE_ATTENDANT = 'Atendentes de Ocorrências são usuários que fazem o atendimento remoto dos eventos e ocorrências.',
  TACTICAL_DISPLACEMENT_OPERATOR = 'Operadores de deslocamento tático são usuários responsáveis por coordenar as rotas dos agentes no atendimento de ocorrências.',
  TACTICAL_AGENT = 'Agentes táticos são usuários que realizam rondas e atendem ocorrências de alarme.',
  OCCURRENCE_MANAGEMENT = 'Gestores de ocorrências são usuários que não fazem atendimento remoto de ocorrências, apesar de poder visualizá-las.',
}

export const getCoverageInstruction = (
  coverageType: ATTENDANCE_ROLE,
): string => {
  switch (coverageType) {
    case ATTENDANCE_ROLE.SERVICE_ORDER_ATTENDANT:
      return CoverageTypeInstruction.SERVICE_ORDER_ATTENDANT

    case ATTENDANCE_ROLE.TECHNICAL_DISPLACEMENT_OPERATOR:
      return CoverageTypeInstruction.TECHNICAL_DISPLACEMENT_OPERATOR

    case ATTENDANCE_ROLE.TECHNICAL_AGENT:
      return CoverageTypeInstruction.TECHNICAL_AGENT

    case ATTENDANCE_ROLE.OCCURRENCE_ATTENDANT:
      return CoverageTypeInstruction.OCCURRENCE_ATTENDANT

    case ATTENDANCE_ROLE.TACTICAL_DISPLACEMENT_OPERATOR:
      return CoverageTypeInstruction.TACTICAL_DISPLACEMENT_OPERATOR

    case ATTENDANCE_ROLE.TACTICAL_AGENT:
      return CoverageTypeInstruction.TACTICAL_AGENT

    case ATTENDANCE_ROLE.OCCURRENCE_MANAGEMENT:
      return CoverageTypeInstruction.OCCURRENCE_MANAGEMENT

    default:
      return ''
  }
}

export enum COVERAGE_OPTIONS {
  REGIONS = 'REGIONS',
  ACCOUNTS = 'ACCOUNTS',
  TAGS = 'TAGS',
  SERVICE_ORDER_TYPES = 'SERVICE_ORDER_TYPES',
  OCCURRENCE_CATEGORY = 'OCCURRENCE_CATEGORY',
}

export enum COVERAGE_OPTIONS_PT_EN {
  'Regiões' = COVERAGE_OPTIONS.REGIONS,
  'Contas' = COVERAGE_OPTIONS.ACCOUNTS,
  'Tags de conta' = COVERAGE_OPTIONS.TAGS,
  'Tipos de Ordem de Serviço' = COVERAGE_OPTIONS.SERVICE_ORDER_TYPES,
  'Categorias de ocorrência' = COVERAGE_OPTIONS.OCCURRENCE_CATEGORY,
}

export enum COVERAGE_OPTIONS_EN_PT {
  REGIONS = 'Regiões',
  ACCOUNTS = 'Contas',
  TAGS = 'Tags de conta',
  SERVICE_ORDER_TYPES = 'Tipos de Ordem de Serviço',
  OCCURRENCE_CATEGORY = 'Categorias de ocorrência',
}

type CoverageOptions = {
  [key in COVERAGE_OPTIONS]: {
    name: string
    belongsTo: ATTENDANCE_ROLE[]
    coverageTypes: CoverageType[]
  }
}

const allCoverageTypes = Object.values(ATTENDANCE_ROLE).map((type) => type)

const relevantCoverageOptions: CoverageOptions = {
  [COVERAGE_OPTIONS.REGIONS]: {
    name: COVERAGE_OPTIONS_EN_PT.REGIONS,
    belongsTo: allCoverageTypes,
    coverageTypes: [
      CoverageType.DISTRICT,
      CoverageType.CITY,
      CoverageType.STATE,
    ],
  },
  [COVERAGE_OPTIONS.ACCOUNTS]: {
    name: COVERAGE_OPTIONS_EN_PT.ACCOUNTS,
    belongsTo: allCoverageTypes,
    coverageTypes: [CoverageType.ACCOUNT],
  },
  [COVERAGE_OPTIONS.TAGS]: {
    name: COVERAGE_OPTIONS_EN_PT.TAGS,
    belongsTo: allCoverageTypes,
    coverageTypes: [CoverageType.TAG],
  },
  [COVERAGE_OPTIONS.SERVICE_ORDER_TYPES]: {
    name: COVERAGE_OPTIONS_EN_PT.SERVICE_ORDER_TYPES,
    belongsTo: [
      ATTENDANCE_ROLE.SERVICE_ORDER_ATTENDANT,
      ATTENDANCE_ROLE.TECHNICAL_AGENT,
      ATTENDANCE_ROLE.TECHNICAL_DISPLACEMENT_OPERATOR,
    ],
    coverageTypes: [CoverageType.SERVICE_ORDER_TYPE],
  },
  [COVERAGE_OPTIONS.OCCURRENCE_CATEGORY]: {
    name: COVERAGE_OPTIONS_EN_PT.OCCURRENCE_CATEGORY,
    belongsTo: [
      ATTENDANCE_ROLE.TACTICAL_AGENT,
      ATTENDANCE_ROLE.TACTICAL_DISPLACEMENT_OPERATOR,
      ATTENDANCE_ROLE.OCCURRENCE_ATTENDANT,
      ATTENDANCE_ROLE.OCCURRENCE_MANAGEMENT,
    ],
    coverageTypes: [CoverageType.OCCURRENCE_CATEGORY],
  },
}

export const getCoverageOptions = (
  coverageType: ATTENDANCE_ROLE,
  entityList: Sections,
): string[] => {
  const items: ViewEntityContent[] = []
  const keys = Array.from(entityList.keys())

  let relevantCoverage: string[] = []

  for (const key of keys) {
    const entities = entityList.get(key) || []
    items.push(...entities)
  }

  relevantCoverage = Object.values(relevantCoverageOptions)
    .filter((option) => option.belongsTo.includes(coverageType))
    .map((item) => item.name)

  if (items.length) {
    const hasAccountCoverageType = items.some(
      (item) => item.subtitle === CoverageType.ACCOUNT,
    )
    const hasRegionCoverageType = items.some((item) =>
      relevantCoverageOptions.REGIONS.coverageTypes.includes(
        item.subtitle as CoverageType,
      ),
    )
    const hasTagCoverageType = items.some(
      (item) => item.subtitle === CoverageType.TAG,
    )

    if (hasAccountCoverageType)
      return relevantCoverage.filter(
        (item) =>
          item !== COVERAGE_OPTIONS_EN_PT.TAGS &&
          item !== COVERAGE_OPTIONS_EN_PT.REGIONS,
      )
    if (hasRegionCoverageType || hasTagCoverageType)
      return relevantCoverage.filter(
        (item) => item !== COVERAGE_OPTIONS_EN_PT.ACCOUNTS,
      )
  }
  return relevantCoverage
}

export const parseCoverageInfoToEntityFormat = (
  coverage: CoverageFindResponse,
): ViewEntityContent[] => {
  const entities: ViewEntityContent[] = []

  if (coverage?.accountTags) {
    coverage?.accountTags?.map((accountTag) => {
      return entities.push({
        id: accountTag.id,
        title: accountTag.name,
        subtitle: CoverageType.TAG,
        formKey: 'accountTagIds',
      })
    })
  }

  if (coverage?.accounts) {
    coverage?.accounts?.map((account) => {
      return entities.push({
        id: account.id,
        title: account.name,
        subtitle: CoverageType.ACCOUNT,
        formKey: 'accountIds',
      })
    })
  }

  if (coverage?.cities) {
    coverage?.cities?.map((city) => {
      return entities.push({
        id: city.id,
        title: city.aggregatedName,
        subtitle: CoverageType.CITY,
        formKey: 'cityIds',
      })
    })
  }

  if (coverage?.states) {
    coverage?.states?.map((state) => {
      return entities.push({
        id: state.id,
        title: state.name,
        subtitle: CoverageType.STATE,
        formKey: 'stateIds',
      })
    })
  }

  if (coverage?.districts) {
    coverage?.districts?.map((district) => {
      return entities.push({
        id: district.id,
        title: district.aggregatedName,
        subtitle: CoverageType.DISTRICT,
        formKey: 'districtIds',
      })
    })
  }

  if (coverage?.occurrenceCategories) {
    coverage?.occurrenceCategories?.map((occurrenceCategory) => {
      return entities.push({
        id: occurrenceCategory,
        title: OCCURRENCE_CATEGORY_EN_PT[occurrenceCategory],
        subtitle: CoverageType.OCCURRENCE_CATEGORY,
        formKey: 'occurrenceCategories',
      })
    })
  }

  if (coverage?.serviceOrderTypes) {
    coverage?.serviceOrderTypes?.map((serviceOrderType) => {
      return entities.push({
        id: serviceOrderType,
        title: SERVICE_ORDER_TYPE_EN_PT[serviceOrderType],
        subtitle: CoverageType.SERVICE_ORDER_TYPE,
        formKey: 'serviceOrderTypes',
      })
    })
  }

  return entities
}
