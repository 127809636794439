import { FC, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from '@hapi/joi'

import {
  useGetTacticalDetails,
  usePauseTacticalDuty,
} from 'services/displacementMap'
import { Button, Modal } from 'components'

import { useDisplacementModal, useMap } from '../../../../contexts'
import { BackDrop } from '../../../../components'

import styles from './PauseDuty.module.scss'

export type PauseTacticalDuty = {
  note: string
}

type RequestFeedback = {
  title: string
  success: boolean
  body: string
}

export const PauseDuty: FC = () => {
  const { handleCloseModal } = useDisplacementModal()
  const [requestFeedback, setRequestFeedback] =
    useState<RequestFeedback | null>(null)
  const { selectedRoute } = useMap()
  const { mutate } = usePauseTacticalDuty(selectedRoute.tactical?.id)

  const { data: tactical } = useGetTacticalDetails(selectedRoute.tactical?.id)

  const {
    handleSubmit,
    formState: { isValid },
    setValue,
    register,
  } = useForm<PauseTacticalDuty>({
    mode: 'onChange',
    resolver: joiResolver(Joi.object({ note: Joi.string().required() })),
  })

  const onSubmit = (data: PauseTacticalDuty) => {
    mutate(data, {
      onSuccess: () => {
        setRequestFeedback({
          title: 'Plantão tático pausado com sucesso',
          body: `${
            tactical?.agent.name || ''
          } deixará de atender ocorrências enviadas para deslocamento.`,
          success: true,
        })
      },
      onError: () => {
        setRequestFeedback({
          title: 'Erro ao pausar plantão tático',
          body: `Não foi possível pausar o plantão do agente ${
            tactical?.agent.name || ''
          }. Tente novamente.`,
          success: false,
        })
      },
    })
  }

  const handleTitle = useMemo(() => {
    if (requestFeedback) {
      return requestFeedback.success
        ? 'Plantão tático pausado com sucesso'
        : 'Erro ao pausar plantão tático'
    }
    return 'Pausar plantão'
  }, [requestFeedback])

  return (
    <BackDrop onClose={handleCloseModal}>
      <Modal
        isVisible
        onClose={handleCloseModal}
        size={requestFeedback ? 'sm' : 'md'}
        simple
        title={handleTitle}
      >
        {requestFeedback ? (
          <section className={styles.feedBackBody}>
            <p className={styles.feedbackText}>{requestFeedback.body}</p>
            <Modal.Footer>
              <Button
                width="172px"
                type="secondary"
                buttonTitle="Fechar"
                onClick={handleCloseModal}
              />
            </Modal.Footer>
          </section>
        ) : (
          <form>
            <h2 className={styles.boxTitle}>Justificativa</h2>
            <textarea
              className={styles.textArea}
              id="service-order-note"
              {...register('note')}
              rows={6}
              maxLength={5000}
              onChange={(e) =>
                setValue('note', e.target.value, {
                  shouldValidate: true,
                })
              }
            />
            <Modal.Footer className={styles.buttonsFooter}>
              <Button
                buttonTitle="Voltar"
                onClick={handleCloseModal}
                type="secondary"
                width="172px"
              />
              <Button
                buttonTitle="Salvar"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
                type="primary"
                width="172px"
              />
            </Modal.Footer>
          </form>
        )}
      </Modal>
    </BackDrop>
  )
}
