import { ComboboxItem } from 'components'
import { Result } from 'services/types'

export function parseDataToCombobox<T>(
  response: Result<T>,
  label: keyof T,
): Result<ComboboxItem<T>> {
  const comboboxItems: ComboboxItem<T>[] = response.data.map((item) => ({
    label,
    value: item,
  }))
  return { data: comboboxItems, totalElements: response.totalElements }
}

export function parseDataToComboboxV2<T>(
  response: T[],
  label: keyof T,
): ComboboxItem<T>[] {
  const comboboxItems: ComboboxItem<T>[] = response.map((item) => ({
    label,
    value: item,
  }))

  return comboboxItems
}
