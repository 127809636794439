import { Input } from 'components'
import { TriggerOptionForm } from 'domains/attendancePolicy/components/RuleForm/types'
import { useEffect } from 'react'

import styles from './Time.module.scss'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { TIME_UNIT_EN_PT } from 'domains/attendancePolicy/types'
import { useForm } from 'react-hook-form'
import { TimeUnit } from 'services/serviceType/types'
import {
  OccurrenceStateName,
  convertConditionOccurrenceState,
  triggerOccurrenceStateName,
  translatedOccurrenceState,
  convertTriggerOccurrenceState,
} from 'domains/attendancePolicy/components/RuleForm/data/occurrenceStateName'
import { convertTimeUnitMap } from 'domains/attendancePolicy/components/RuleForm/utilities'
import { TriggerType } from 'services/attendancePolicy/types'

export const Time = ({ onChange, data }: TriggerOptionForm) => {
  const { parameters } = data

  const form = useForm<{
    timeUnit: TimeUnit
    timeInterval: number
    occurrenceState: OccurrenceStateName
  }>({
    defaultValues: {
      timeUnit: parameters?.timeUnit,
      timeInterval: parameters?.timeInterval,
      occurrenceState: parameters?.occurrenceState,
    },
  })

  const { register, watch, setValue } = form

  const { timeUnit, occurrenceState, timeInterval } = watch()

  useEffect(() => {
    register('timeUnit')
    register('timeInterval')
    register('occurrenceState')
  }, [register])

  return (
    <div className={styles.timeFormWrapper}>
      <div className={styles.triggerTime}>
        <Input
          type="number"
          wrapperClassName={styles.triggerTimeInput}
          value={String(parameters?.timeInterval) || undefined}
          min={0}
          onChange={(input) => {
            setValue('timeInterval', Number(input.target.value))

            onChange({
              type: TriggerType.TIME_BASED,
              parameters: {
                timeUnit: timeUnit || undefined,
                timeInterval: Number(input.target.value),
                occurrenceState,
              },
            })
          }}
          label="Tempo"
        />
        <Combobox
          label={{ text: 'Unidade' }}
          items={Object.values(TIME_UNIT_EN_PT)}
          value={
            (parameters?.timeUnit && TIME_UNIT_EN_PT[parameters.timeUnit]) ||
            undefined
          }
          onChange={(selectedUnit) => {
            const timeUnit = selectedUnit as TIME_UNIT_EN_PT

            setValue('timeUnit', convertTimeUnitMap[timeUnit])

            onChange({
              type: TriggerType.TIME_BASED,
              parameters: {
                timeInterval,
                timeUnit: convertTimeUnitMap[timeUnit],
                occurrenceState,
              },
            })
          }}
          className={styles.triggerUnit}
        />
      </div>

      <div aria-label="occurrence-state-form">
        <Combobox
          value={
            parameters?.occurrenceState
              ? translatedOccurrenceState[parameters?.occurrenceState]
              : undefined
          }
          label={{
            text: 'Estado da ocorrência',
          }}
          items={Object.values(triggerOccurrenceStateName).map(
            (stateName) => translatedOccurrenceState[stateName],
          )}
          onChange={(selected) => {
            const selectedOccurrenceState = selected as OccurrenceStateName

            setValue(
              'occurrenceState',
              convertTriggerOccurrenceState[selectedOccurrenceState],
            )

            onChange({
              type: TriggerType.TIME_BASED,
              parameters: {
                timeInterval,
                timeUnit: timeUnit || undefined,
                occurrenceState:
                  convertConditionOccurrenceState[selectedOccurrenceState],
              },
            })
          }}
          placeholder="Selecione um ou mais estados"
        />
      </div>
    </div>
  )
}
