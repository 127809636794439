export const serviceOrderStatus = {
  OPEN: 'Aberta',
  SCHEDULED: 'Agendada',
  RESCHEDULED: 'Reagendada',
  IN_PROGRESS: 'Em andamento',
  PAUSED: 'Pausada',
  IN_VALIDATION: 'Em validação',
  DISAPPROVED: 'Reprovada',
  FINISHED: 'Finalizada',
  CANCELED: 'Cancelada',
}

type ServiceOrderStatus = keyof typeof serviceOrderStatus

export const getSchedulePeriod = (
  schedulePeriod: string,
): string | undefined => {
  switch (schedulePeriod) {
    case 'BUSINESS_HOURS':
      return 'Horário comercial'
    case 'MORNING':
      return 'Manhã'
    case 'AFTERNOON':
      return 'Tarde'
    default:
      return undefined
  }
}

export const formatter = Intl.DateTimeFormat('pt-BR', {
  hour: '2-digit',
  minute: '2-digit',
})

export const getServiceOrderStatus = (status: ServiceOrderStatus): string =>
  serviceOrderStatus[status] || status
