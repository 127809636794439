import { useMemo } from 'react'
import { useUserInfo } from 'shared/hooks'

enum VALID_ATTENDANCE_PROFILE {
  SERVICE_ORDER_ATTENDANT = 'SERVICE_ORDER_ATTENDANT',
  OCCURRENCE_ATTENDANT = 'OCCURRENCE_ATTENDANT',
}

type useHandleUpdateUserAndCheckOccurrenceAttendantReturn = {
  isQualifiedAttendant: boolean
}

export const useHandleUpdateUserAndCheckOccurrenceAttendant =
  (): useHandleUpdateUserAndCheckOccurrenceAttendantReturn => {
    const { userInfo } = useUserInfo()

    const isQualifiedAttendant = useMemo(() => {
      if (
        !userInfo ||
        !userInfo.userCoverages?.primary ||
        !userInfo.userCoverages?.secondary
      )
        return false
      const isAnyPrimaryCoverageValid = userInfo.userCoverages.primary.some(
        (coverage) =>
          Object.values(VALID_ATTENDANCE_PROFILE).includes(
            coverage.coverageType as unknown as VALID_ATTENDANCE_PROFILE,
          ),
      )
      const isAnySecondaryCoverageValid = userInfo.userCoverages.secondary.some(
        (coverage) =>
          Object.values(VALID_ATTENDANCE_PROFILE).includes(
            coverage.coverageType as unknown as VALID_ATTENDANCE_PROFILE,
          ),
      )
      return isAnyPrimaryCoverageValid || isAnySecondaryCoverageValid
    }, [userInfo])

    return {
      isQualifiedAttendant,
    }
  }
