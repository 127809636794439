import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'shared/hooks'
import { usePatchUpdateDevice } from 'shared/hooks/services/device'

const defaultMessages = {
  success: 'Equipamento editado com sucesso.',
  error: 'Erro ao alterar um ou mais dados. Tente novamente.',
}

export const usePatchAndHandleUpdateDevice = (messages = defaultMessages) => {
  const { addToast } = useToast()
  const queryClient = useQueryClient()

  const { mutateUpdateDevice, updateDeviceMutationStatus } =
    usePatchUpdateDevice()

  useEffect(() => {
    if (updateDeviceMutationStatus === 'success') {
      addToast({
        message: messages.success,
      })

      queryClient.invalidateQueries({ queryKey: ['equipments'] })
    }

    if (updateDeviceMutationStatus === 'error') {
      addToast({
        message: messages.error,
        type: 'alert',
      })
    }
  }, [addToast, queryClient, updateDeviceMutationStatus])

  return {
    mutateUpdateDevice,
    updateDeviceMutationStatus,
  }
}
