import { useRef, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import hintPath from 'assets/images/hint.png'

import './Modal.scss'
import Tooltip from 'components/Tooltip/Tooltip'
import { useToggle } from 'shared/hooks'

export interface FooterProps {
  className?: string
  children: ReactNode
}

export const Footer = (props: FooterProps) => {
  const { children, className = '' } = props
  const footer = useRef<HTMLDivElement>(null)

  const getChildrensLength = (children: ReactNode[]) => {
    return Array.from(children).length
  }

  return (
    <div
      className={[
        'modal-footer',
        getChildrensLength(children as ReactNode[]) ? '--multiple' : '',
        className,
      ].join(' ')}
      ref={footer}
    >
      {children}
    </div>
  )
}

export type ModalSizes = 'sm' | 'md' | 'lg' | 'xl'
export interface ModalProps {
  id?: string
  title: string
  isVisible: boolean
  size?: ModalSizes
  simple?: boolean
  className?: string
  onClose?: () => void
  hint?: string
  children: ReactNode
}
export const Modal = (props: ModalProps) => {
  const {
    children,
    title,
    onClose,
    isVisible,
    size = 'sm',
    simple,
    className,
    hint,
  } = props

  const tooltip = useToggle()
  const imageRef = useRef<HTMLImageElement>(null)

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {isVisible && <div className="modal-overlay" />}
          <CSSTransition
            in={isVisible}
            timeout={{
              enter: 300,
              exit: 100,
            }}
            classNames="fade"
            unmountOnExit
          >
            <div className={['modal-wrapper', className].join(' ')}>
              <div
                data-testid="modal-card"
                className={['modal-card', `modal-${size}`].join(' ')}
              >
                <button
                  onClick={onClose}
                  aria-label="close"
                  className="modal-close-button"
                  id="button-close"
                >
                  {onClose && (
                    <svg
                      className="modal-close-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.84"
                      height="9.919"
                      viewBox="0 0 9.84 9.919"
                    >
                      <path
                        id="prefix__mini_x_2"
                        d="M6.311 4.96l3.24-3.266a1 1 0 0 0 0-1.4.978.978 0 0 0-1.391 0L4.92 3.557 1.68.291a.978.978 0 0 0-1.391 0 1 1 0 0 0 0 1.4l3.24 3.269L.288 8.226a1 1 0 0 0 0 1.4.978.978 0 0 0 1.391 0L4.92 6.362l3.24 3.267a.978.978 0 0 0 1.391 0 1 1 0 0 0 0-1.4z"
                      />
                    </svg>
                  )}
                </button>
                <div
                  className="modal-title-wrapper"
                  style={{ justifyContent: simple ? 'center' : 'left' }}
                >
                  <h1 className="modal-title">{title}</h1>
                  {hint && (
                    <>
                      <img
                        alt="hint-icon"
                        ref={imageRef}
                        aria-label="modal-hint-icon"
                        className="modal-hint"
                        onMouseEnter={tooltip.show}
                        onMouseLeave={tooltip.hide}
                        src={hintPath}
                      />
                      <Tooltip
                        parentRef={imageRef}
                        type="informative"
                        isVisible={tooltip.isVisible}
                      >
                        <p>{hint}</p>
                      </Tooltip>
                    </>
                  )}
                </div>
                <div
                  className={simple ? 'simple-modal-body' : 'modal-body'}
                  id="modal-body"
                >
                  {children}
                </div>
              </div>
            </div>
          </CSSTransition>
        </>,
        document.body,
      )}
    </>
  )
}

Modal.Footer = Footer
